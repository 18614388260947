import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import routes from '../../../routes';
import {
	CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS,
	CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS,
	CUSTOM_SPACE_FOREGROUND_POSITIONS,
	CUSTOM_SPACE_LIGHT_SECTION_IMAGE_POSITIONS,
	CUSTOM_SPACE_LIGHT_SECTION_VIDEO_POSITIONS
} from '../../../constants/space';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { setSpaceProStyle } from '../../../services/space';
import UploadDragger from '../../../components/commons/UploadDragger';
import { GOOGLE_FONT_KEY } from '../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LOGO_MAX_HEIGHT,
	IMAGE_LOGO_MAX_WIDTH,
	IMAGE_LOGO_MIN_HEIGHT,
	IMAGE_LOGO_MIN_WIDTH
} from '../../../constants/file';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import ColorPickerAndInput from '../../../components/commons/ColorPickerAndInput';
import header_layout1 from '../../../../src/assets/images/header_layout1.png';
import header_layout2 from '../../../../src/assets/images/header_layout2.png';
import './CustomizeProSpace.scss';
import { hideTooltip, showTooltip } from '../../../utils/input';
import FontsDropdownWithSearch from '../../../components/commons/FontsDropdownWithSearch';
import { getSitewideLink } from '../../../utils/sitewideLinks';

const CustomizeProSpace = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [alert, setAlert] = useState(null);
	const [logoImage, setLogoImage] = useState(null);
	const [logoImageBlobPreview, setLogoImageBlobPreview] = useState(null);
	const [logoImageError, setLogoImageError] = useState(null);
	const [lightBackgroundImage, setLightBackgroundImage] = useState(null);
	const [lightBackgroundImageBlobPreview, setLightBackgroundImageBlobPreview] = useState(null);
	const [lightBackgroundVideo, setLightBackgroundVideo] = useState(null);
	const [lightBackgroundVideoBlobPreview, setLightBackgroundVideoBlobPreview] = useState(null);
	const [darkBackgroundImage, setDarkBackgroundImage] = useState(null);
	const [darkBackgroundImageBlobPreview, setDarkBackgroundImageBlobPreview] = useState(null);
	const [darkBackgroundVideo, setDarkBackgroundVideo] = useState(null);
	const [darkBackgroundVideoBlobPreview, setDarkBackgroundVideoBlobPreview] = useState(null);
	const [headerBackgroundImage, setHeaderBackgroundImage] = useState(null);
	const [headerBackgroundImageBlobPreview, setHeaderBackgroundImageBlobPreview] = useState(null);
	const [headerBackgroundVideo, setHeaderBackgroundVideo] = useState(null);
	const [headerBackgroundVideoBlobPreview, setHeaderBackgroundVideoBlobPreview] = useState(null);
	const [headerForegroundImage, setHeaderForegroundImage] = useState(null);
	const [headerForegroundImageBlobPreview, setHeaderForegroundImageBlobPreview] = useState(null);
	const [selectedLightImagePosition, setSelectedLightImagePosition] = useState('cover');
	const [selectedLightVideoPosition, setSelectedLightVideoPosition] = useState('cover');
	const [selectedDarkImagePosition, setSelectedDarkImagePosition] = useState('cover');
	const [selectedDarkVideoPosition, setSelectedDarkVideoPosition] = useState('cover');
	const [selectedForegroundPosition, setSelectedForegroundPosition] = useState('cover');
	const [foregroundText, setForegroundText] = useState('');
	const [headerLayout, setHeaderLayout] = useState('standard');
	const [hasGradientChecked, setHasGradientChecked] = useState(false);
	const [logoImageIsRemoved, setLogoImageIsRemoved] = useState(false);
	const [lightBackgroundImageIsRemoved, setLightBackgroundImageIsRemoved] = useState(false);
	const [lightBackgroundVideoIsRemoved, setLightBackgroundVideoIsRemoved] = useState(false);
	const [darkBackgroundImageIsRemoved, setDarkBackgroundImageIsRemoved] = useState(false);
	const [darkBackgroundVideoIsRemoved, setDarkBackgroundVideoIsRemoved] = useState(false);
	const [headerBackgroundImageIsRemoved, setHeaderBackgroundImageIsRemoved] = useState(false);
	const [headerBackgroundVideoIsRemoved, setHeaderBackgroundVideoIsRemoved] = useState(false);
	const [headerForegroundImageIsRemoved, setheaderForegroundImageIsRemoved] = useState(false);

	//use state for color picker
	const [color1, setColor1] = useState('#FB03B9');
	const [color2, setColor2] = useState('#FFFFFF');
	const [color3, setColor3] = useState('#0C0C0C');
	const [color4, setColor4] = useState('#FFFFFF');
	const [color5, setColor5] = useState('#FFFFFF');
	const [color6, setColor6] = useState('#0C0C0C');

	//use state for google fonts
	const [font1, setFont1] = useState('Noto Sans');
	const [font2, setFont2] = useState('Noto Sans');

	const foregroundTextRef = useRef(null);

	const handleLogoImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setLogoImageIsRemoved(true);
		} else {
			setLogoImageIsRemoved(false);
		}

		setLogoImage(file);
		setLogoImageBlobPreview(blobPreview);
	};

	const handleLightBackgroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setLightBackgroundImageIsRemoved(true);
		} else {
			setLightBackgroundImageIsRemoved(false);
		}

		setLightBackgroundImage(file);
		setLightBackgroundImageBlobPreview(blobPreview);
	};

	const handleLightBackgroundVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setLightBackgroundVideoIsRemoved(true);
		} else {
			setLightBackgroundVideoIsRemoved(false);
		}

		setLightBackgroundVideo(file);
		setLightBackgroundVideoBlobPreview(blobPreview);
	};

	const handleDarkBackgroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setDarkBackgroundImageIsRemoved(true);
		} else {
			setDarkBackgroundImageIsRemoved(false);
		}

		setDarkBackgroundImage(file);
		setDarkBackgroundImageBlobPreview(blobPreview);
	};

	const handleDarkBackgroundVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setDarkBackgroundVideoIsRemoved(true);
		} else {
			setDarkBackgroundVideoIsRemoved(false);
		}

		setDarkBackgroundVideo(file);
		setDarkBackgroundVideoBlobPreview(blobPreview);
	};

	const handleHeaderBackgroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setHeaderBackgroundImageIsRemoved(true);
		} else {
			setHeaderBackgroundImageIsRemoved(false);
		}

		setHeaderBackgroundImage(file);
		setHeaderBackgroundImageBlobPreview(blobPreview);
	};

	const handleHeaderBackgroundVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setHeaderBackgroundVideoIsRemoved(true);
		} else {
			setHeaderBackgroundVideoIsRemoved(false);
		}

		setHeaderBackgroundVideo(file);
		setHeaderBackgroundVideoBlobPreview(blobPreview);
	};

	const handleHeaderForegroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setheaderForegroundImageIsRemoved(true);
		} else {
			setheaderForegroundImageIsRemoved(false);
		}

		setHeaderForegroundImage(file);
		setHeaderForegroundImageBlobPreview(blobPreview);
	};

	const submitStyles = useCallback(
		async (isSaveAndGoBack) => {
			setLogoImageError(null);

			let hasError = false;

			if (!logoImageBlobPreview) {
				setLogoImageError('Pro Space Logo is required.');
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = {
				space_id: spaceInfo.id,
				user_id: authUserInfo.id,
				logo: logoImage,
				key_color: color1,
				has_gradient: hasGradientChecked,
				gradient_color: color2,
				title_font: font1,
				body_font: font2,
				light_text_color: color3,
				light_background_color: color4,
				light_background_image: lightBackgroundImage,
				light_background_video: lightBackgroundVideo,
				light_image_position: selectedLightImagePosition,
				light_video_position: selectedLightVideoPosition,
				dark_text_color: color5,
				dark_background_color: color6,
				dark_background_image: darkBackgroundImage,
				dark_background_video: darkBackgroundVideo,
				dark_image_position: selectedDarkImagePosition,
				dark_video_position: selectedDarkVideoPosition,
				header_layout: headerLayout,
				header_background_image: headerBackgroundImage,
				header_background_video: headerBackgroundVideo,
				header_foreground_image: headerForegroundImage,
				foreground_text: foregroundText,
				foreground_position: selectedForegroundPosition
			};

			if (logoImageIsRemoved) {
				data.logo_is_empty = true;
			}

			if (lightBackgroundImageIsRemoved) {
				data.light_background_image_is_empty = true;
			}

			if (lightBackgroundVideoIsRemoved) {
				data.light_background_video_is_empty = true;
			}

			if (darkBackgroundImageIsRemoved) {
				data.dark_background_image_is_empty = true;
			}

			if (darkBackgroundVideoIsRemoved) {
				data.dark_background_video_is_empty = true;
			}

			if (headerBackgroundImageIsRemoved) {
				data.header_background_image_is_empty = true;
			}

			if (headerBackgroundVideoIsRemoved) {
				data.header_background_video_is_empty = true;
			}

			if (headerForegroundImageIsRemoved) {
				data.header_foreground_image_is_empty = true;
			}

			try {
				setSubmitted(true);
				const response = await setSpaceProStyle(data);
				if (response && response.result) {
					dispatch(getSpaceInfo(space_username));

					if (isSaveAndGoBack) {
						const url = routes.createCustomizeProNav();
						navigate(url.replace(':space_username', space_username));
					} else {
						const url = routes.createSpaceUpgradePlan();
						navigate(url.replace(':space_username', space_username));
					}
				} else {
					setAlert({
						variant: 'danger',
						message: response.message
					});
					window.scrollTo(0, 0);
				}
				setSubmitted(false);
			} catch (error) {
				console.log(error);
			}
		},
		[
			space_username,
			authUserInfo,
			spaceInfo,
			logoImage,
			logoImageBlobPreview,
			color1,
			color2,
			font1,
			font2,
			color3,
			color4,
			lightBackgroundImage,
			lightBackgroundVideo,
			selectedLightImagePosition,
			selectedLightVideoPosition,
			color5,
			color6,
			darkBackgroundImage,
			darkBackgroundVideo,
			selectedDarkImagePosition,
			selectedDarkVideoPosition,
			headerLayout,
			headerBackgroundImage,
			headerBackgroundVideo,
			headerForegroundImage,
			foregroundText,
			selectedForegroundPosition,
			hasGradientChecked,
			logoImageIsRemoved,
			lightBackgroundImageIsRemoved,
			lightBackgroundVideoIsRemoved,
			darkBackgroundImageIsRemoved,
			darkBackgroundVideoIsRemoved,
			headerBackgroundImageIsRemoved,
			headerBackgroundVideoIsRemoved,
			headerForegroundImageIsRemoved
		]
	);

	const setFormSpaceData = useCallback(
		async (createdSpace) => {
			if (createdSpace) {
				if (createdSpace.plan === 'pro') {
					// set space data to forms here
					if (createdSpace.pro_style) {
						const pro_style = createdSpace.pro_style;
						setColor1(pro_style.key_color);
						setHasGradientChecked(pro_style.has_gradient);
						setColor2(pro_style.gradient_color);
						setColor3(pro_style.light_text_color);
						setColor4(pro_style.light_background_color);
						setSelectedLightImagePosition(pro_style.light_image_position);
						setSelectedLightVideoPosition(pro_style.light_video_position);
						setColor5(pro_style.dark_text_color);
						setColor6(pro_style.dark_background_color);
						setSelectedDarkImagePosition(pro_style.dark_image_position);
						setSelectedDarkVideoPosition(pro_style.dark_video_position);
						setHeaderLayout(pro_style.header_layout);
						setForegroundText(pro_style.foreground_text);
						setSelectedForegroundPosition(pro_style.foreground_position);

						if (pro_style.logo) {
							if (!logoImageIsRemoved) {
								setLogoImageBlobPreview(pro_style.logo);
							}
						}

						if (pro_style.light_background_image) {
							if (!lightBackgroundImageIsRemoved) {
								setLightBackgroundImageBlobPreview(
									pro_style.light_background_image
								);
							}
						}

						if (pro_style.light_background_video) {
							if (!lightBackgroundVideoIsRemoved) {
								setLightBackgroundVideoBlobPreview(
									pro_style.light_background_video
								);
							}
						}

						if (pro_style.dark_background_image) {
							if (!darkBackgroundImageIsRemoved) {
								setDarkBackgroundImageBlobPreview(pro_style.dark_background_image);
							}
						}

						if (pro_style.dark_background_video) {
							if (!darkBackgroundVideoIsRemoved) {
								setDarkBackgroundVideoBlobPreview(pro_style.dark_background_video);
							}
						}

						if (pro_style.header_background_image) {
							if (!headerBackgroundImageIsRemoved) {
								setHeaderBackgroundImageBlobPreview(
									pro_style.header_background_image
								);
							}
						}

						if (pro_style.header_background_video) {
							if (!headerBackgroundVideoIsRemoved) {
								setHeaderBackgroundVideoBlobPreview(
									pro_style.header_background_video
								);
							}
						}

						if (pro_style.header_foreground_image) {
							if (!headerForegroundImageIsRemoved) {
								setHeaderForegroundImageBlobPreview(
									pro_style.header_foreground_image
								);
							}
						}

						// added delay on setting font value to allow api fetch to finish
						setTimeout(() => {
							setFont1(pro_style.title_font);
							setFont2(pro_style.body_font);
						}, 200);
					}
				} else {
					const url = routes.createSpaceLaunch();
					navigate(url.replace(':space_username', space_username));
				}
			}
		},
		[
			logoImageIsRemoved,
			lightBackgroundImageIsRemoved,
			lightBackgroundVideoIsRemoved,
			darkBackgroundImageIsRemoved,
			darkBackgroundVideoIsRemoved,
			headerBackgroundImageIsRemoved,
			headerBackgroundVideoIsRemoved,
			headerForegroundImageIsRemoved
		]
	);

	const tooltipFocused = (input) => {
		if (input === 'foreground_text') {
			foregroundTextRef.current.focus();
		}
	};

	const onChangeHasGradient = (e) => {
		setHasGradientChecked(e.target.checked);
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad, logoImageError]);

	return (
		<>
			<main id="member-profile" className="flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12 md:col-span-6 flex items-center">
							<h1 className="mb-4 mt-8 header-large">Customize Your Pro Space</h1>
						</div>
						<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">
							<p className="body-txtitalic--large ">Step 1 of 2</p>
						</div>
						<div className="col-span-12">
							<p className="body-text--reg">
								Your Space has upgraded to a{' '}
								{/*<a className="gradient-color-txt">Pro Space</a>*/}
								{getSitewideLink('pro_space_upgrade')}! You’ve unlocked full
								customization for your branding, layout, and style, as well as more
								advanced features. To get started, set up your customization
								settings below. Also more custom features are launching in March and
								April with our Beta rollout.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 mt-8 items-center">
						<div className="grid grid-cols-1 sm:grid-cols-12  !h-[40px] !rounded-none">
							<div className="sm:col-span-8 my-auto !order-last sm:!order-first">
								<h5 className="leading-tight header-medium">Logo &amp; Key Color</h5>
							</div>
							<div className="sm:col-span-4 my-auto !order-first sm:!order-last flex justify-center items-center">
								{/*<button type="submit" className="btn btn-lg btn-secondary">*/}
								{/*	View Guide*/}
								{/*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
								{/*</button>*/}
								{getSitewideLink(
									'view_guide_customize_pro',
									'btn btn-lg btn-secondary'
								)}
							</div>
						</div>
						<div className="col-span-12">
							<p className="body-text--small mt-8">
								Your Space has upgraded to a Pro Space! You’ve unlocked full
								customization for your branding, layout, and style, as well as more
								advanced features. To get started, set up your customization
								settings below. Also more custom features are launching in March and
								April with our Beta rollout.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
						<div className="md:col-span-4">
							<div className="upload-rec">
								<UploadDragger
									passedError={logoImageError}
									cropperAspectRatio={1 / 1}
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={logoImageBlobPreview}
									onUploadFileChange={handleLogoImageUploadFileChange}
									minHeight={IMAGE_LOGO_MIN_HEIGHT}
									minWidth={IMAGE_LOGO_MIN_WIDTH}
									maxHeight={IMAGE_LOGO_MAX_HEIGHT}
									maxWidth={IMAGE_LOGO_MAX_WIDTH}
									uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
									disableCropper={true}
									imagePreviewCustomClassName={'centered-image-preview'}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Pro Space Logo File*
							</p>
							<p className="text-center body-txtitalic--smallest">
								SVG preferred, or 64px height or better PNG, GIF, or JPG.
							</p>
						</div>
						<div className="md:col-span-4">
							<div className="md:hidden md:col-span-4">
								<p className="body-txtitalic--smallest color-reg m-0">
									Select your Key Color by entering a color hex code or using the
									selector by clicking the color square.
								</p>
							</div>
							<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
								<label className="body-text--small w-full gradient-color-txt ms-4">
									Key Color
								</label>
								<ColorPickerAndInput
									color={color1}
									onColorChange={(value) => setColor1(value)}
								></ColorPickerAndInput>
							</div>
							<div className="input-group-com !rounded-none h-[40px] flex items-center  has-gradient-group">
								<Checkbox
									checked={hasGradientChecked}
									className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
									onChange={(value) => onChangeHasGradient(value)}
								></Checkbox>
								<label className="has-gradient-label pt-[12px]">Has Gradient</label>
							</div>
							<div className="md:hidden md:col-span-4">
								<p className="body-txtitalic--smallest color-reg">
									OPTIONAL FOR GRADIENT ONLY. If you would like your Key Color to be a
									gradient, your first color will be the Start Color and this color
									will be the End Color of the gradient.
								</p>
							</div>
							<div
								className={
									'input-group-com flex !rounded-none h-[40px] items-center justify-between ' +
									(!hasGradientChecked ? 'disable-picker' : '')
								}
							>
								<label className="body-text--small w-full gradient-color-txt ms-4">
									Gradient Color
								</label>
								<ColorPickerAndInput
									className={!hasGradientChecked ? 'disable-picker' : ''}
									color={color2}
									onColorChange={(value) => setColor2(value)}
								></ColorPickerAndInput>
							</div>
						</div>
						<div className="hidden md:block md:col-span-4">
							<p className="body-txtitalic--smallest color-reg m-0">
								Select your Key Color by entering a color hex code or using the
								selector by clicking the color square.
							</p>
							<p className="body-txtitalic--smallest color-reg mt-8">
								OPTIONAL FOR GRADIENT ONLY. If you would like your Key Color to be a
								gradient, your first color will be the Start Color and this color
								will be the End Color of the gradient.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 mt-8 items-center">
						<div className="col-span-1">
							<h5 className="leading-tight header-medium">Fonts</h5>
						</div>
						<div className="col-span-1">
							<p className="body-text--small mt-8">
								Your Space has upgraded to a Pro Space! You’ve unlocked full
								customization for your branding, layout, and style, as well as more
								advanced features. To get started, set up your customization
								settings below. Also more custom features are launching in March and
								April with our Beta rollout.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
						<div className="md:col-span-4 !order-2 md:!order-1">
							<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
								<label className="body-text--small w-1/4 gradient-color-txt ms-4">
									Title Font*
								</label>
								
								<FontsDropdownWithSearch
									componentId="titleFont"
									selectedValue={font1}
									onDropdownChange={setFont1}
								></FontsDropdownWithSearch>
							</div>
						</div>
						
						<div className="md:col-span-4 !order-3 md:!order-2">
							<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
								<label className="body-text--small w-1/4 gradient-color-txt ms-4">
									Body Font*
								</label>
								<FontsDropdownWithSearch
									componentId="bodyFont"
									selectedValue={font2}
									onDropdownChange={setFont2}
								></FontsDropdownWithSearch>
							</div>
						</div>
						<div className="md:col-span-4 !order-1 md:!order-3">
							<p className="body-txtitalic--smallest color-reg m-0">
								Some fonts may have different sizing, so be sure to review how they
								look on your Space before launching.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 mt-8 items-center">
						<div className="col-span-1">
							<h5 className="leading-tight header-medium">Section Colors & Backgrounds</h5>
						</div>
						<div className="col-span-1 mt-8">
							<p className="body-text--small">
								Your Pro Space has different sections which use your Key Color with
								a Text Color and a Background Color. These have light and dark
								styles and you can select your Text Color and Background Color, plus
								an optional Background Image or Video for each. When selecting your
								Backgrounds, be sure that your Key Color will still stand out. Any
								Background Images or Video which will show on top of Background
								Colors.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 sm:grid-cols-12 mt-8 items-center">
						<div className="sm:col-span-8 !order-last sm:!order-first">
							<h5 className="header-small">Light Section Text & Background Colors</h5>
						</div>
						<div className="sm:col-span-4 !order-first sm:!order-last">
							<p className="body-txtitalic--smallest color-reg">
								Your Light Section should have a dark Text Color on a light
								Background Color.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
						
						<div className="md:col-span-4 !order-2 md:!order-1 rounded-none">
							<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
								<label className="body-text--small w-full gradient-color-txt ms-4">
									Text Color
								</label>
								<ColorPickerAndInput
									color={color3}
									onColorChange={(value) => setColor3(value)}
								></ColorPickerAndInput>
							</div>
						</div>
						<div className="md:col-span-4 !order-3 md:!order-2">
							<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between overflow-hidden text-ellipsis">
								<label className="body-text--small w-full gradient-color-txt ms-4 ">
									Background
									
									Color
								</label>
								<ColorPickerAndInput
									color={color4}
									onColorChange={(value) => setColor4(value)}
								></ColorPickerAndInput>
							</div>
						</div>
						<div className="md:col-span-4 !order-1 md:!order-3">
							<p className="body-txtitalic--smallest color-reg m-0">
								Default Light Section Text Color is #0C0C0C for dark grey text on a
								#FFFFFF white Background Color.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:hidden">
							<p className="body-txtitalic--smallest color-reg m-0">
								The optional Background Image (up to 8MB) for your Section will
								display over the Background Color and transparency is supported.
							</p>
						</div>
						<div className="md:col-span-4">
							<div className="upload-rec">
								<UploadDragger
									cropperAspectRatio={16 / 9}
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={lightBackgroundImageBlobPreview}
									onUploadFileChange={handleLightBackgroundImageUploadFileChange}
									minHeight={IMAGE_LARGE_MIN_HEIGHT}
									minWidth={IMAGE_LARGE_MIN_WIDTH}
									maxHeight={IMAGE_LARGE_MAX_HEIGHT}
									maxWidth={IMAGE_LARGE_MAX_WIDTH}
									uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Background Image
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 3840px square or wide. JPEG or PNG.
							</p>
						</div>
						<div className="md:hidden">
							<p className="body-txtitalic--smallest color-reg mt-8">
								The optional Background Video (up to 16MB) for your Section will
								display over the Background Color instead of the Background Image on
								limited supported devices.
							</p>
						</div>
						<div className="md:col-span-4">
							<div className="upload-rec">
								<UploadDragger
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={lightBackgroundVideoBlobPreview}
									onUploadFileChange={handleLightBackgroundVideoUploadFileChange}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Background Video
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 1920px/1080px, square or wide. MOV or MP4.
							</p>
						</div>
						<div className="hidden md:block md:col-span-4">
							<p className="body-txtitalic--smallest color-reg m-0">
								The optional Background Image (up to 8MB) for your Section will
								display over the Background Color and transparency is supported.
							</p>
							<p className="body-txtitalic--smallest color-reg mt-8">
								The optional Background Video (up to 16MB) for your Section will
								display over the Background Color instead of the Background Image on
								limited supported devices.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
						<div className="md:col-span-4 my-auto !order-last md:!order-first">
							<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Image Position
							</label>
							<div className="relative rounded-none ">
								<DropdownWithItems
									customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
									
									title="Image Position"
									selectedValue={selectedLightImagePosition}
									listItems={CUSTOM_SPACE_LIGHT_SECTION_IMAGE_POSITIONS}
									onDropdownChange={(value) =>
									setSelectedLightImagePosition(value)
								}
								/>
							</div>
							
						</div>
					
						<div className="md:col-span-4 !order-3 md:!order-2">
							<div className="md:col-span-4 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Video Position
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										
										title="Video Position"
										selectedValue={selectedLightVideoPosition}
										listItems={CUSTOM_SPACE_LIGHT_SECTION_VIDEO_POSITIONS}
										onDropdownChange={(value) =>
											setSelectedLightVideoPosition(value)
										}
									/>
								</div>
								
							</div>
							
						</div>
						<div className="md:col-span-4 !order-1 md:!order-3">
							<p className="body-txtitalic--smallest color-reg m-0">
								You can choose how your Background Image or Video will display. Fill
								is the default and is scaled to the page size, or choose Top,
								Center, or Bottom with no scaling.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 sm:grid-cols-12 mt-8 items-center">
						<div className="sm:col-span-8 !order-last sm:!order-first">
							<h5 className="header-small">Dark Section Text & Background Colors</h5>
						</div>
						<div className="sm:col-span-4 !order-first sm:!order-last">
							<p className="body-txtitalic--smallest color-reg">
								Your Dark Section should have a light Text Color on a dark
								Background Color.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
						<div className="md:col-span-4 !order-2 md:!order-1">
							<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between">
								<label className="body-text--small w-full gradient-color-txt ms-4">
									Text Color
								</label>
								<ColorPickerAndInput
									color={color5}
									onColorChange={(value) => setColor5(value)}
								></ColorPickerAndInput>
							</div>
						</div>
						<div className="md:col-span-4 !order-3 md:!order-2">
							<div className="input-group-com !rounded-none h-[40px] flex items-center justify-between overflow-hidden text-ellipsis">
								<label className="body-text--small w-full gradient-color-txt ms-4">
									Background
									<br />
									Color
								</label>
								<ColorPickerAndInput
									color={color6}
									onColorChange={(value) => setColor6(value)}
								></ColorPickerAndInput>
							</div>
						</div>
						<div className="md:col-span-4 !order-1 md:!order-3">
							<p className="body-txtitalic--smallest color-reg m-0">
								Default Dark Section Text Color is #FFFFFF” white text on a #0C0C0C
								dark grey Background Color.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8">
						<div className="md:hidden">
							<p className="body-txtitalic--smallest color-reg m-0">
								The optional Background Image (up to 8MB) for your Section will
								display over the Background Color and transparency is supported.
							</p>
						</div>
						<div className="md:col-span-4">
							<div className="upload-rec">
								<UploadDragger
									cropperAspectRatio={16 / 9}
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={darkBackgroundImageBlobPreview}
									onUploadFileChange={handleDarkBackgroundImageUploadFileChange}
									minHeight={IMAGE_LARGE_MIN_HEIGHT}
									minWidth={IMAGE_LARGE_MIN_WIDTH}
									maxHeight={IMAGE_LARGE_MAX_HEIGHT}
									maxWidth={IMAGE_LARGE_MAX_WIDTH}
									uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Background Image
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 3840px square or wide. JPEG or PNG.
							</p>
						</div>
						<div className="md:hidden">
							<p className="body-txtitalic--smallest color-reg mt-8">
								The optional Background Video (up to 16MB) for your Section will
								display over the Background Color instead of the Background Image on
								limited supported devices.
							</p>
						</div>
						<div className="md:col-span-4">
							<div className="upload-rec">
								<UploadDragger
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={darkBackgroundVideoBlobPreview}
									onUploadFileChange={handleDarkBackgroundVideoUploadFileChange}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Background Video
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 1920px/1080px, square or wide. MOV or MP4.
							</p>
						</div>
						<div className="hidden md:grid md:col-span-4">
							<p className="body-txtitalic--smallest color-reg m-0">
								The optional Background Image (up to 8MB) for your Section will
								display over the Background Color and transparency is supported.
							</p>
							<p className="body-txtitalic--smallest color-reg mt-8">
								The optional Background Video (up to 16MB) for your Section will
								display over the Background Color instead of the Background Image on
								limited supported devices.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
						<div className="md:col-span-4 !order-3 md:!order-2">
							<div className="md:col-span-4 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Image Position
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										
										title="Image Position"
										selectedValue={selectedDarkImagePosition}
										listItems={CUSTOM_SPACE_DARK_SECTION_IMAGE_POSITIONS}
										onDropdownChange={(value) =>
											setSelectedDarkImagePosition(value)
										}
									/>
								</div>
								
							</div>
							
						</div>
						<div className="md:col-span-4 !order-3 md:!order-2">
							<div className="md:col-span-4 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Video Position
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										
										title="Video Position"
										selectedValue={selectedDarkVideoPosition}
										listItems={CUSTOM_SPACE_DARK_SECTION_VIDEO_POSITIONS}
										onDropdownChange={(value) =>
											setSelectedDarkVideoPosition(value)
										}
									/>
								</div>
								
							</div>
							
						</div>
						
						
						<div className="md:col-span-4 !order-1 md:!order-3">
							<p className="body-txtitalic--smallest color-reg m-0">
								You can choose how your Background Image or Video will display. Fill
								is the default and scales to the page size, or choose Top, Center,
								or Bottom with no scaling.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 mt-8 items-center">
						<div className="col-span-1">
							<h5 className="leading-tight header-medium">Homepage Header Layout</h5>
						</div>
						<div className="col-span-1 mt-8">
							<p className="body-text--small">
								Pro Spaces have their own Homepage Header with custom branding and
								navigation, versus Basic Spaces which use the Commonspace Header.
								You can choose from the Standard or the Split Pro Header for your
								homepage. The Standard Pro Header brings the focus to the homepage
								content, versus the Split Pro Header which focuses on the branding
								and requires a background image that won’t blend in with the logo
								and buttons. Images below for example only.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8 items-center">
						<div className="md:col-span-6">
							<img className="img-fluid mx-auto" src={header_layout1} />
						</div>
						<div className="md:hidden">
							<Button
								className={
									'btn btn-lg ' +
									(headerLayout === 'standard' ? 'btn-primary' : 'btn-secondary')
								}
								onClick={() => setHeaderLayout('standard')}
							>
								Select Standard Header
							</Button>
						</div>
						<div className="md:col-span-6 !mt-8 md:!mt-0">
							<img className="img-fluid mx-auto" src={header_layout2} />
						</div>
						<div className="md:hidden">
							<Button
								className={
									'btn btn-lg ' +
									(headerLayout === 'split' ? 'btn-primary' : 'btn-secondary')
								}
								onClick={() => setHeaderLayout('split')}
							>
								Select Split Header
							</Button>
						</div>
					</div>

					<div className="hidden md:grid grid-cols-2  gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="col-span-1">
							<Button
								className={
									'btn btn-lg ' +
									(headerLayout === 'standard' ? 'btn-primary' : 'btn-secondary')
								}
								onClick={() => setHeaderLayout('standard')}
							>
								Select Standard Header
							</Button>
						</div>
						<div className="col-span-1">
							<Button
								className={
									'btn btn-lg ' +
									(headerLayout === 'split' ? 'btn-primary' : 'btn-secondary')
								}
								onClick={() => setHeaderLayout('split')}
							>
								Select Split Header
							</Button>
						</div>
					</div>
					<div className="grid grid-cols-1 mt-8 items-center">
						<div className="col-span-1">
							<h5 className="leading-tight header-medium">
								Homepage Header Background & Foreground
							</h5>
						</div>
						<div className="col-span-1 mt-8">
							<p className="body-text--small">
								Your Homepage Header can have a custom Background and Foreground.
								The default is to use your Space Profile Background but you can
								optionally choose a different Image or Video below. In addition, you
								can choose an optional Foreground Image or Text which will display
								over the Background on your Homepage only. In the example in the
								section above, the Background is the sky image with the head and the
								Foreground is the DRMVRSE logo.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:hidden">
							<p className="body-txtitalic--smallest color-reg m-0">
								The optional Header Background Image (up to 8MB) will replace your
								default Space Profile Background for the Homepage only.
							</p>
						</div>
						<div className="md:col-span-4">
							<div className="upload-rec">
								<UploadDragger
									cropperAspectRatio={16 / 9}
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={headerBackgroundImageBlobPreview}
									onUploadFileChange={handleHeaderBackgroundImageUploadFileChange}
									minHeight={IMAGE_LARGE_MIN_HEIGHT}
									minWidth={IMAGE_LARGE_MIN_WIDTH}
									maxHeight={IMAGE_LARGE_MAX_HEIGHT}
									maxWidth={IMAGE_LARGE_MAX_WIDTH}
									uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Background Image
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 3840px square or wide. JPEG or PNG.
							</p>
						</div>
						<div className="md:hidden">
							<p className="body-txtitalic--smallest color-reg mt-8">
								The optional Header Background Video (up to 16MB) will replace the
								Header Background Image on limited supported devices.
							</p>
						</div>
						<div className="md:col-span-4">
							<div className="upload-rec">
								<UploadDragger
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={headerBackgroundVideoBlobPreview}
									onUploadFileChange={handleHeaderBackgroundVideoUploadFileChange}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Background Video
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 1920px/1080px, square or wide. MOV or MP4.
							</p>
						</div>
						<div className="hidden md:grid md:col-span-4">
							<p className="body-txtitalic--smallest color-reg m-0">
								The optional Header Background Image (up to 8MB) will replace your
								default Space Profile Background for the Homepage only.
							</p>
							<p className="body-txtitalic--smallest color-reg mt-8">
								The optional Header Background Video (up to 16MB) will replace the
								Header Background Image on limited supported devices.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
						<div className="col-span-12 md:hidden">
							<p className="body-txtitalic--smallest color-reg m-0">
								The optional Header Foreground Image (up to 8MB) will display over
								the Header Background for the Homepage only.
							</p>
						</div>
						<div className="col-span-12 md:col-span-4">
							<div className="upload-rec">
								<UploadDragger
									cropperAspectRatio={16 / 9}
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={headerForegroundImageBlobPreview}
									onUploadFileChange={handleHeaderForegroundImageUploadFileChange}
									minHeight={IMAGE_LARGE_MIN_HEIGHT}
									minWidth={IMAGE_LARGE_MIN_WIDTH}
									maxHeight={IMAGE_LARGE_MAX_HEIGHT}
									maxWidth={IMAGE_LARGE_MAX_WIDTH}
									uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Foreground Image
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 3840px square or wide. JPEG or PNG.
							</p>
						</div>
						<div className="col-span-12 md:hidden">
							<p className="body-txtitalic--smallest color-reg mt-8">
								The Foreground Image can be positioned as a scaled Fill or as a Top,
								Center (Default), or Bottom position with no scaling. The Optional
								Foreground Text will replace the Foreground Image in the middle
								center if added.
							</p>
						</div>
						<div className="col-span-12 md:col-span-4 ">
							<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Foreground Text*
							</label>
							<div className="relative rounded-none ">
								
								<input
									onFocus={hideTooltip}
									onBlur={showTooltip}
									type="text"
									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
									placeholder="&nbsp;"
									onChange={(e) => setForegroundText(e.target.value)}
									value={foregroundText}
									ref={foregroundTextRef}
									maxLength={128}
									/>
							</div>
							<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Foreground Position*
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										
										title="Foreground Position"
										selectedValue={selectedForegroundPosition}
										listItems={CUSTOM_SPACE_FOREGROUND_POSITIONS}
										onDropdownChange={(value) =>
											setSelectedForegroundPosition(value)
										}
									/>
								</div>
								
						</div>
						
						<div className="hidden md:grid md:col-span-4">
							<p className="body-txtitalic--smallest color-reg m-0">
								The optional Header Foreground Image (up to 8MB) will display over
								the Header Background for the Homepage only.
							</p>
							<p className="body-txtitalic--smallest color-reg mt-8">
								The Foreground Image can be positioned as a scaled Fill or as a Top,
								Center (Default), or Bottom position with no scaling. The Optional
								Foreground Text will replace the Foreground Image in the middle
								center if added.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1  gap-y-8 md:gap-y-0 md:gap-8 md:grid-cols-12 mt-8">
						<div className="md:col-span-8">
							<Button
								onClick={() => submitStyles(true)}
								className="btn btn-lg btn-primary"
								htmlType="submit"
								disabled={submitted}
							>
								{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
								Save & Continue
							</Button>
						</div>
						<div className="md:col-span-4 flex items-end">
							<Button
								onClick={() => submitStyles(false)}
								className="btn btn-lg btn-secondary"
								htmlType="submit"
								disabled={submitted}
							>
								{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
								Save & Go Back
							</Button>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(CustomizeProSpace);
