import { memo, useCallback, useEffect, useState } from 'react';

import './Home.scss';
import { getAuthUserInfo } from '../../states/general/actions';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsLoggedIn, removeAccessToken } from '../../utils/common';
import { getLocalStorage, setLocalStorage } from '../../utils/storage';
import {CREATE_SPACE_UID, REDIRECT_CHECK_HOSTNAME, REDIRECT_LANDING_PAGE, STORAGE_KEYS} from '../../constants/common';
import { refresh_token } from '../../services/auth';
import { useSearchParams } from 'react-router-dom';
import routes from '../../routes';

const Home = () => {
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	let isLoggedIn = checkIsLoggedIn();
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [tokenRequestSuccess, setTokenRequestSuccess] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);

	const requestRefreshToken = async () => {
		const refreshToken = JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN));

		try {
			const result = await refresh_token({ refresh: JSON.parse(refreshToken) });
			setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, result.access);
			setTokenRequestSuccess(true);
		} catch (e) {
			removeAccessToken();
		}
	};

	const handleUserLoginRedirects = useCallback(async () => {
		if (isLoggedIn === true) {
			if (isInitialLoad) {
				setIsInitialLoad(false);
				dispatch(getAuthUserInfo());
			}
		} else if (isLoggedIn === 'request-refresh') {
			if (!tokenRequestSuccess) {
				requestRefreshToken().then((res) => {});
			}
		} else {
			if ((window.location.hostname === REDIRECT_CHECK_HOSTNAME) || (window.location.hostname === ('www.' + REDIRECT_CHECK_HOSTNAME))) {
				window.location.href = REDIRECT_LANDING_PAGE;
			} else {
				window.location.replace(routes.login());
			}
		}
	}, [tokenRequestSuccess, isLoggedIn, isInitialLoad]);

	useEffect(() => {
		handleUserLoginRedirects().then((r) => {});

		if (loading && loading === 'done' && authUserInfo) {
			window.location.replace(routes.dashboard());
		}

		document.title = 'Commonspace';
	}, [tokenRequestSuccess, isLoggedIn, isInitialLoad, authUserInfo]);

	return (
		<main id="home" className="flex">
			<div className="container"></div>
		</main>
	);
};

export default memo(Home);
