import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import moment from 'moment/moment';
import TextareaAutosize from 'react-textarea-autosize';
import Pagination from 'react-bootstrap/Pagination';

import routes from '../../../../routes';
import {
	createSpaceModelComment,
	getSpaceCategories,
	getSpaceModelComments,
	getSpaceUpdateData
} from '../../../../services/space';
import { displayDataContent } from '../../../../utils/spaceUpdateDataView';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import CommentRow from '../../../../components/commons/CommentRow';
import './UpdatesContent.scss';

const UpdatesContent = (parentProps) => {
	const { updatesViewingUserIsMember } = parentProps;
	const { space_username, update_id } = useParams();
	const [commentForm] = Form.useForm();
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [updateData, setUpdateData] = useState(null);
	const [categories, setCategories] = useState(null);
	const [previousLocation, setPreviousLocation] = useState(false);
	const [comments, setComments] = useState(null);
	const [commentToReply, setCommentToReply] = useState(null);
	const [commentsPagination, setCommentsPagination] = useState([]);
	const [commentsCurrentPage, setCommentsCurrentPage] = useState(1);
	const [commentsFetching, setCommentsFetching] = useState(false);
	const [disableCommentButton, setDisableCommentButton] = useState(true);

	const buildPagination = (current_page, max_page) => {
		const items = [];
		if (max_page) {
			for (let number = 1; number <= max_page; number++) {
				items.push(
					<Pagination.Item key={number} active={number === current_page}>
						{number}
					</Pagination.Item>
				);
			}
		}

		setCommentsPagination(items);
	};

	const handleOnClickPagination = useCallback(
		async (event) => {
			const itemClicked = event.target.text;
			if (itemClicked) {
				const number = parseInt(itemClicked);
				setCommentsCurrentPage(number);
				getModelComments(number).then();
			}
		},
		[spaceInfo, update_id]
	);

	const getUpdateDataRequest = useCallback(
		async (update_id) => {
			const data = {
				update_id: update_id
			};

			if (authUserInfo) {
				data['viewing_user_id'] = authUserInfo.id;
			}

			if (spaceInfo) {
				data['viewing_space_id'] = spaceInfo.id;
			}

			const response = await getSpaceUpdateData(data);

			if (response && response.result) {
				const spaceUpdateData = response.space_update;

				if (spaceInfo) {
					if (spaceInfo.id === spaceUpdateData.space_id) {
						setUpdateData(spaceUpdateData);

						let urlTitle = spaceUpdateData.title;
						if (urlTitle) {
							urlTitle = urlTitle.toLowerCase();
							urlTitle = urlTitle.replaceAll(' ', '-');
							const url =
								'/@' +
								spaceInfo.username +
								'/update/' +
								spaceUpdateData.id +
								'/' +
								urlTitle;
							window.history.replaceState(null, null, url);
						}

						getModelComments().then();
					}
				}
			} else {
				if (authUserInfo) {
					navigate(routes.dashboard());
				} else {
					navigate(routes.login());
				}
			}
		},
		[space_username, authUserInfo, spaceInfo, currentLocation]
	);

	const getSpaceCategoriesRequest = useCallback(async () => {
		await getSpaceCategories({ space_id: spaceInfo.id, with_updates_usage_count: true }).then(
			(response) => {
				if (response && response.result) {
					setCategories(response.categories);
				}
			}
		);
	}, [spaceInfo]);

	const navigateToViewUpdate = useCallback(async (link) => {
		navigate(link);
	}, []);

	const CategoryLinkRow = (data) => {
		return (
			<>
				<Link to={'#'}>
					{data.name}
					<span>{data.usage_count}</span>
				</Link>
			</>
		);
	};

	const getAuthorDisplay = (author, data) => {
		if (author) {
			if (author === 'me') {
				return 'by ' + (data.username ? '@' + data.username : data.full_name);
			} else {
				return 'by ' + (data.username ? '@' + data.username : space_username);
			}
		}
		return 'by ' + space_username;
	};

	const getModelComments = useCallback(
		async (pageNumber = null) => {
			setCommentsFetching(true);
			await getSpaceModelComments({
				space_id: spaceInfo.id,
				model: 'update',
				model_id: update_id,
				list_count: 5,
				page: pageNumber ? pageNumber : commentsCurrentPage
			}).then((response) => {
				if (response && response.result) {
					setComments(response.data);
					buildPagination(parseInt(response.page), parseInt(response.page_range));
				}
			});
			setCommentsFetching(false);
		},
		[spaceInfo, update_id, commentsCurrentPage]
	);

	const handleUpdateCommentIdToReply = (id) => {
		setCommentToReply(id);
	};

	const handleFetchUpdatedComments = () => {
		getModelComments().then();
	};

	const commentKeypress = (event) => {
		if (!event.shiftKey && event.key === 'Enter') {
			event.preventDefault();
			commentForm.submit();
		}
	};

	const handleChanges = useCallback(() => {
			setDisableCommentButton(commentForm.getFieldValue('comment').trim() === '');
		},
		[disableCommentButton]
	);

	const handleSubmit = useCallback(
		async (values) => {
			let okToSubmit = (commentForm.getFieldValue('comment') !== undefined && commentForm.getFieldValue('comment').trim() !== '');

			if (okToSubmit) {
				setSubmitted(true);

				const data = {
					space_id: spaceInfo.id,
					model: 'update',
					model_id: update_id,
					comment: values.comment
				};

				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}

				const response = await createSpaceModelComment(data);
				if (response && response.result) {
					getModelComments().then();
					if (commentForm != null) {
						commentForm.setFieldValue('comment','');
					}
				}

				setSubmitted(false);
				setDisableCommentButton(true);
			}
		},
		[authUserInfo, spaceInfo, update_id]
	);

	const updatesContent = () => {
		return (
			<>
				<div className="update-media">
					{displayDataContent(
						updateData,
						updatesViewingUserIsMember,
						'cover'
					)}
				</div>
				<div className="body-text--reg color-reg mt-5">
					{updateData.content && (
						<>{parse(updateData.content.toString())}</>
					)}
				</div>
				{updateData.comments_availability === 'enabled' && (
					<>
						<h4 className="header-small mt-5">Comments</h4>
						<Form
							form={commentForm}
							name="commentForm"
							id="commentForm"
							onFinish={handleSubmit}
							onChange={handleChanges}
							validateMessages={VALIDATE_MESSAGES}
						>
							<div className="comment-input-container gradient-border-box w-full flex items-center justify-between mt-5">
								<Form.Item
									label="Comment"
									name="comment"
									className="col-1 mb-0 hidden-label"
									// validateTrigger="onBlur"
									// rules={[{ required: true }]}
								>
									<TextareaAutosize
										placeholder="Write a comment"
										className="input-md body-text--small color-reg bring-front p-2 mt-1 ml-1"
										onKeyPress={commentKeypress}
									/>
								</Form.Item>

								<Form.Item className="col-2 mb-0">
									<Button
										className={disableCommentButton ? "body-text--reg comment-disable me-4" : "body-text--reg gradient-color-txt me-4"}
										htmlType="submit"
										disabled={submitted || disableCommentButton}
									>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin me-1 comment-spinner"></i>
										)}
										Comment
									</Button>
								</Form.Item>
							</div>
						</Form>
						<div className="col-span-12 mb-2">
							<Comments/>
						</div>
						{commentsPagination.length > 1 && (
							<>
								<div className="grid grid-cols-12 mt-5">
									<Pagination
										size="sm"
										onClick={(event) =>
											handleOnClickPagination(event)
										}
									>
										{commentsPagination}
									</Pagination>
								</div>
							</>
						)}
					</>
				)}
			</>
		)
	}

	const Comments = useCallback(() => {
		return (
			<>
				{comments && (
					<>
						{comments.map((comment, commentIndex) => (
							<div key={commentIndex}>
								<CommentRow
									authUserInfo={authUserInfo}
									spaceInfo={spaceInfo}
									modelId={update_id}
									comment={comment}
									commentIdToReply={commentToReply}
									updateCommentIdToReply={handleUpdateCommentIdToReply}
									commentLevel={1}
									fetchUpdatedComments={handleFetchUpdatedComments}
								/>
								{comment.replies && (
									<>
										{comment.replies.map((reply, replyIndex) => (
											<div key={replyIndex}>
												<CommentRow
													authUserInfo={authUserInfo}
													spaceInfo={spaceInfo}
													modelId={update_id}
													comment={reply}
													commentIdToReply={commentToReply}
													updateCommentIdToReply={
														handleUpdateCommentIdToReply
													}
													commentLevel={2}
													fetchUpdatedComments={
														handleFetchUpdatedComments
													}
												/>
												{reply.sub_replies && (
													<>
														{reply.sub_replies.map(
															(subReply, subReplyIndex) => (
																<div key={subReplyIndex}>
																	<CommentRow
																		authUserInfo={authUserInfo}
																		spaceInfo={spaceInfo}
																		modelId={update_id}
																		comment={subReply}
																		commentIdToReply={
																			commentToReply
																		}
																		updateCommentIdToReply={
																			handleUpdateCommentIdToReply
																		}
																		commentLevel={3}
																		fetchUpdatedComments={
																			handleFetchUpdatedComments
																		}
																		parentReplyId={reply.id}
																	></CommentRow>
																</div>
															)
														)}
													</>
												)}
											</div>
										))}
									</>
								)}
							</div>
						))}
					</>
				)}
			</>
		);
	}, [comments, authUserInfo, spaceInfo, update_id, commentToReply]);

	useEffect(() => {
		if (
			spaceInfoLoading &&
			spaceInfoLoading === 'done' &&
			spaceInfo &&
			spaceInfo.id &&
			initialLoad
		) {
			getUpdateDataRequest(update_id).then(() => {});
			getSpaceCategoriesRequest().then((r) => {});
			setInitialLoad(false);
		}

		if (previousLocation !== currentLocation.pathname) {
			setInitialLoad(true);
			setPreviousLocation(currentLocation.pathname);
		}
	}, [
		currentLocation.pathname,
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		update_id,
		initialLoad,
		categories,
		previousLocation,
		commentToReply
	]);

	return (
		<>
			<div className="container updates-content">
				<div className="grid  mt-5">
					{updateData && (
						<>
							<div className="grid grid-cols-12 gap-4">
								<div className="xsm:col-span-12 lg:col-span-9">
									<h1 className="header-large m-0">
										{displayDataContent(
											updateData,
											updatesViewingUserIsMember,
											'title'
										)}
									</h1>
									<p className="body-text--small color-light mb-5">
										{updateData.publish_date ? (
											<>
												{moment(updateData.publish_date).format('LL')}{' '}
												{getAuthorDisplay(
													updateData.author,
													updateData.author_data
												)}
											</>
										) : (
											<>
												{moment(updateData.created_on).format('LL')}{' '}
												{getAuthorDisplay(
													updateData.author,
													updateData.author_data
												)}
											</>
										)}
									</p>

									<div className="lg:inline xsm:hidden p-0">
										{updatesContent()}
									</div>

								</div>

								<div className="xsm:col-span-12 lg:col-span-3">
									<div className="input-group-com gradient-border-box input-with-btn">
										<input
											className="input-md color-light ps-4 body-txtitalic--smallest bring-front"
											type="text"
											placeholder="Search Updates"
										/>
										<button className="btn btn-secondary btn-md w-1/2">
											Search
										</button>
									</div>

									<ul className="category-list mt-5 pt-5">
										{categories && (
											<>
												{categories.map((data, index) => (
													<li
														key={index}
														onClick={() =>
															navigateToViewUpdate(
																'/' +
																space_username +
																'/update?category=' +
																data.slug
															)
														}
													>
														{CategoryLinkRow(data)}
													</li>
												))}
											</>
										)}
									</ul>
									<ul className="tag-list mt-5">
										{updateData.tags && (
											<>
												{updateData.tags_arr.map((data, index) => (
													<li key={index}>
														<Link>{data}</Link>
													</li>
												))}
											</>
										)}
									</ul>
								</div>

							</div>

							<div className="lg:hidden md:flex">
								<div className="grid grid-cols-12 gap-4">
									<div className="xsm:col-span-12 lg:col-span-9">
										{updatesContent()}
									</div>
								</div>
							</div>

						</>
					)}
				</div>
			</div>
		</>
	);
};

export default memo(UpdatesContent);
