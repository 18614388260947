import {useCallback, useEffect, useRef, useState} from 'react';
import { Routes, Route, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';

import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';
import routes from '../../routes';
import SignUp from '../../pages/SignUp';
import Login from '../../pages/Login';
import ForgotPassword from '../../pages/ForgotPassword';
import Logout from '../../pages/Logout';
import EmailVerification from '../../pages/Register/EmailVerification';
import Register from '../../pages/Register';
import RegisterMemberProfile from '../../pages/Register/MemberProfile';
import Home from '../../pages/Home';
import Dashboard from '../../pages/Dashboard';
import Spaces from '../../pages/Spaces';
import Collections from '../../pages/Collections';
import Profile from '../../pages/Profile';
import Settings from '../../pages/Settings';
import UserView from '../../pages/Profile/UserView';
import SpaceGettingStarted from '../../pages/SpaceCreate/GettingStarted';
import SpaceCreate from '../../pages/SpaceCreate';
import SpaceCreateAddDetails from '../../pages/SpaceCreate/AddDetails';
import SpaceCreateAddMembership from '../../pages/SpaceCreate/AddMembership';
import SpaceCreateAddCommunity from '../../pages/SpaceCreate/AddCommunity';
import SpaceCreateAddTraits from '../../pages/SpaceCreate/AddTraits';
import SpaceCreateAddBenefit from '../../pages/SpaceCreate/AddBenefit';
import SpaceCreateBasicCompleteView from '../../pages/SpaceCreate/BasicCompleteView';
import CreateFirstItem from '../../pages/SpaceCreate/CreateFirstItem';
import PostUpdate from '../../pages/SpaceCreate/PostUpdate';
import CommunityFeatures from '../../pages/SpaceCreate/CommunityFeatures';
import InviteTeam from '../../pages/SpaceCreate/InviteTeam';
import UpgradePlan from '../../pages/SpaceCreate/UpgradePlan';
import CustomizeProSpace from '../../pages/SpaceCreate/CustomizeProSpace';
import CustomizeProNav from '../../pages/SpaceCreate/CustomizeProNav';
import SpaceLaunch from '../../pages/SpaceCreate/SpaceLaunch';
import LaunchChecklist from '../../pages/SpaceCreate/LaunchCheckList';
import AddBusinessDetails from '../../pages/SpaceCreate/AddBusinessDetails';
import AddPages from '../../pages/SpaceCreate/AddPages';
import AddPaymentMethod from '../../pages/SpaceCreate/AddPaymentMethod';
import AddBillingInformation from '../../pages/SpaceCreate/AddBillingInformation';
import PreviewAndLaunch from '../../pages/SpaceCreate/PreviewAndLaunch';
import MembershipClaimWalletConnected from '../../pages/SpaceClaimMembership/MembershipClaimWalletConnected';
import MembershipErrorWalletConnect from '../../pages/SpaceClaimMembership/MembershipErrorWalletConnect';
import MembershipActivateWalletConnect from '../../pages/SpaceClaimMembership/MembershipActivateWalletConnect';
import ItemClaimWalletConnected from '../../pages/SpaceClaimItem/ItemClaimWalletConnected';
import ItemErrorWalletConnect from '../../pages/SpaceClaimItem/ItemErrorWalletConnect';
import ItemActivateWalletConnect from '../../pages/SpaceClaimItem/ItemActivateWalletConnect';
import Lab from '../../pages/Labolatory';
import SpaceDashboard from '../../pages/SpaceDashboard';
import HeaderPro from '../../pages/SpaceView/SpaceViewPro/HeaderPro';
import FooterPro from '../../pages/SpaceView/SpaceViewPro/FooterPro';
import FooterProV2 from '../../pages/SpaceView/SpaceViewPro/FooterProV2/FooterProV2';
import FooterProMobile from '../../pages/SpaceView/SpaceViewPro/FooterProMobile'
import FooterV2 from '../../components/layouts/FooterV2/FooterV2';

import { checkIsLoggedIn, removeAccessToken, useScript } from '../../utils/common';
import { getAuthUserInfo } from '../../states/general/actions';
import {
	STORAGE_KEYS,
	RECAPTCHA_ENTERPRISE_SITE_KEY,
	CREATE_SPACE_UID, REDIRECT_CHECK_HOSTNAME, REDIRECT_LANDING_PAGE
} from '../../constants/common';
import { getLocalStorage, setLocalStorage } from '../../utils/storage';
import { refresh_token } from '../../services/auth';
import { notLoggedInAvailableRoutes } from '../../constants/not_logged_in_routes';
import { classLarge, classMedium, classSmall, setScreenSize } from '../../states/classSize';
import { getSitewideLinks } from '../../services/general';
import { checkPagePermissionRedirect } from '../../utils/redirects';

import './AppLayout.scss';
import MembershipPaymentMethod from '../../pages/SpaceBuyMembership/MembershipPaymentMethod';
import SpaceBuyMembership from '../../pages/SpaceBuyMembership';
import MembershipPaymentCompleted from '../../pages/SpaceBuyMembership/MembershipPaymentCompleted';
import ItemPaymentMethod from '../../pages/SpaceBuyItem/ItemPaymentMethod';
import ItemPaymentCompleted from '../../pages/SpaceBuyItem/ItemPaymentCompleted';
import FooterMobile from '../../components/layouts/FooterMobile';
import Channel from '../../pages/Channel';
import {setIsFooterInViewPort} from "../../states/footerInViewSlice";
import useIsInViewport from "../../utils/checkPageScrollPosition";
import TermsOfUse from "../../pages/TermsOfUse";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import CommunityGuidelines from "../../pages/CommunityGuidelines";

const AppLayout = () => {
	const captchableRoutes = [routes.signUp(), routes.login(), routes.emailVerification()];
	const [searchParams] = useSearchParams();
	const currentLocation = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let isLoggedIn = checkIsLoggedIn();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const { size } = useSelector((state) => state.classSize);
	const { displayPageContentLoaderStatus } = useSelector(
		(state) => state.displayPageContentLoader
	);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [tokenRequestSuccess, setTokenRequestSuccess] = useState(false);
	const [isViewSpacePage, setIsViewSpacePage] = useState(null);
	const [viewUsername, setViewUsername] = useState(null);
	const [verifyEmailSpaceUsername, setVerifyEmailSpaceUsername] = useState(null);
	const [verifyEmailSpaceMembershipId, setVerifyEmailSpaceMembershipId] = useState(null);
	const [verifyEmailSpaceItemId, setVerifyEmailSpaceItemId] = useState(null);
	const [activeTab, setActiveTab] = useState('home');
	const [windowSize, setWindowSize] = useState(null);
	const [windowResizeTimer, setWindowResizeTimer] = useState(null);
	const [sitewideLinksFetched, setSitewideLinksFetched] = useState(false);
	const [spaceViewData, setSpaceViewData] = useState(null);
	const [spaceViewCheckerDone, setSpaceViewCheckerDone] = useState(false);
	const [claimType, setClaimType] = useState(null);
	const [loginRedirectCheckerTimeout, setLoginRedirectCheckerTimeout] = useState(null);
	const [collectionCheckerTimeout, setCollectionCheckerTimeout] = useState(null);

	const winLocPath = window.location.pathname;
	
	if (captchableRoutes.includes(winLocPath)) {
		// useScript(
		// 	'https://www.google.com/recaptcha/enterprise.js?render=' + RECAPTCHA_ENTERPRISE_SITE_KEY
		// );
	}

	useScript('https://apis.google.com/js/api.js');

	const getSitewideLinksRequest = useCallback(async () => {
		if (!sitewideLinksFetched) {
			try {
				const response = await getSitewideLinks();
				// console.log(response)
				if (response && response.result) {
					const existing_sitewide_links = getLocalStorage('sitewide_links');
					setLocalStorage('sitewide_links', JSON.stringify(response.links));

					if (!existing_sitewide_links) {
						// reload the page first to allow sitewide links to get data from localstorage
						window.location.reload(false);
					}
				} else {
					setLocalStorage('sitewide_links', []);
				}
				setSitewideLinksFetched(true);
			} catch (e) {
				setLocalStorage('sitewide_links', []);
				setSitewideLinksFetched(true);
			}
		}
	}, [sitewideLinksFetched]);

	const requestRefreshToken = async () => {
		const refreshToken = JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN));

		try {
			const result = await refresh_token({ refresh: JSON.parse(refreshToken) });
			setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, result.access);
			setTokenRequestSuccess(true);
		} catch (e) {
			removeAccessToken();
		}
	};

	useEffect(() => {
	}, [isViewSpacePage])

	const handleUserLoginRedirects = (currPath) => {
		if (isLoggedIn === true) {
			if (isInitialLoad) {
				dispatch(getAuthUserInfo());
				setIsInitialLoad(false);
			}
		} else if (isLoggedIn === 'request-refresh') {
			if (!tokenRequestSuccess) {
				requestRefreshToken().then((res) => {});
			}
		} else {
			if ((window.location.hostname === REDIRECT_CHECK_HOSTNAME) || (window.location.hostname === ('www.' + REDIRECT_CHECK_HOSTNAME))) {
				const currPath = currentLocation.pathname;
				if (currPath === routes.signUp()) {
					window.location.href = REDIRECT_LANDING_PAGE;
				}
			}

			if (!notLoggedInAvailableRoutes.includes(currPath)) {
				if (currPath && currPath.startsWith('/@') && currPath.length > 3) {
					// allow view
				} else {
					if (currPath !== routes.login()) {
						clearTimeout(loginRedirectCheckerTimeout);
						setLoginRedirectCheckerTimeout(null);

						setLoginRedirectCheckerTimeout(
							setTimeout(() => {
								if (currPath !== routes.app()) {
									window.location.replace(routes.login());
								}
							}, 200)
						);
					}
				}
			}
		}
	};

	const collectionTypeChecker = (currPath) => {
		if (currPath && currPath.startsWith('/@') && currPath.length > 3) {
			let username = currPath.replace('/@', '');
			if (username.includes('/')) {
				if (username.includes('membership/claim')) {
					setActiveTab('claim_membership');
					setClaimType('membership');
				}
				if (username.includes('membership/buy')) {
					setActiveTab('buy_membership');
					setClaimType('membership_buy');
				}
				if (username.includes('item/buy')) {
					setActiveTab('buy_item');
					setClaimType('item_buy');
				}
				if (username.includes('item/claim')) {
					setActiveTab('claim_item');
					setClaimType('item');
				}
			}
		}
	};

	const handleSpaceViewChecker = (currPath) => {
		if (currPath && currPath.startsWith('/@') && currPath.length > 3) {
			let username = currPath.replace('/@', '');
			if (username.includes('/')) {
				setClaimType(null);
				if (username.includes('membership/claim')) {
					setActiveTab('claim_membership');
					setClaimType('membership');
				} else if (username.includes('membership/buy')) {
					setActiveTab('buy_membership');
					setClaimType('membership_buy');
				} else if (username.includes('item/buy')) {
					setActiveTab('buy_item');
					setClaimType('item_buy');
				} else if (username.includes('item/claim')) {
					setActiveTab('claim_item');
					setClaimType('item');
				} else if (username.includes('update')) {
					const exp = username.split('/');
					if (
						(exp.length === 3 || exp.length === 4) &&
						exp[1] === 'update' &&
						!isNaN(exp[2])
					) {
						setActiveTab('update_content');
					} else {
						setActiveTab('updates');
					}
				} else if (username.includes('benefit')) {
					setActiveTab('benefits');
				} else if (username.includes('member')) {
					setActiveTab('members');
				} else if (username.includes('support')) {
					setActiveTab('support');
				} else if (username.includes('about')) {
					setActiveTab('about');
				} else if (username.includes('spaces')) {
					setActiveTab('spaces');
				} else if (username.includes('activities')) {
					setActiveTab('activity');
				} else if (username.includes('collections')) {
					setActiveTab('collections');
				} else if (username.includes('following')) {
					setActiveTab('following');
				} else if (username.includes('followers')) {
					setActiveTab('followers');
				} else if (username.includes('faq')) {
					setActiveTab('faq');
				} else if (username.includes('terms-services')) {
					setActiveTab('terms-services');
				} else if (username.includes('privacy-policy')) {
					setActiveTab('privacy-policy');
				} else if (username.includes('about#contact')) {
					setActiveTab('about#contact');
				}
			}
		}
	};

	const setUserName = (currPath) => {
		if (currPath && currPath.startsWith('/@') && currPath.length > 3) {
			let username = currPath.replace('/@', '');
			// if (username.includes('about')) {
			// 	setActiveTab('about');
			// }
			if (username.includes('/')) {
				const usernameExp = username.split('/');
				
				username = usernameExp[0] ? usernameExp[0] : '';
				if(usernameExp[1] !== 'channel'){
					setIsViewSpacePage(false);
				}
				
			} else {
				setIsViewSpacePage(true);
			}

			
			setViewUsername(username);
		}
	};

	function handleResize() {
		if (windowResizeTimer) {
			clearTimeout(windowResizeTimer);
			setWindowResizeTimer(null);
		}
		setWindowResizeTimer(
			setTimeout(() => {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight
				});
			}, 100)
		);
	}

	const handleWindowResponsiveness = (windowSize) => {
		dispatch(setScreenSize(windowSize.width));
		if (windowSize.width < 960) {
			dispatch(classMedium());
		} else if (windowSize.width < 1000) {
			dispatch(classMedium());
		} else if (windowSize.width < 1500) {
			dispatch(classMedium());
		} else if (windowSize.width < 2000) {
			dispatch(classLarge());
		}
	};

	const handleSpaceViewData = useCallback(
		async (data) => {
			setSpaceViewData(data);
		},
		[viewUsername]
	);

	const handleGlobalActiveChange = useCallback(
		async (tab) => {
			setActiveTab(tab);
		},
		[activeTab]
	);

	const productionLoadComponentsWithBlankView = () => {
		const currPath = currentLocation.pathname;
		console.log(window.location.hostname);
		console.log('www.' + REDIRECT_CHECK_HOSTNAME);
		console.log(currPath);
		console.log(currentLocation.pathname);
		if ((window.location.hostname === REDIRECT_CHECK_HOSTNAME) || (window.location.hostname === ('www.' + REDIRECT_CHECK_HOSTNAME))) {
			if (currPath === routes.signUp()) {
				return true;
			}
		}

		return false;
	};

	useEffect(() => {
		// required - reset data on each page redirect
		setViewUsername(null);
		setSpaceViewData(null);
	}, [currentLocation.pathname]);


	useEffect(() => {
		getSitewideLinksRequest();

		const currPath = currentLocation.pathname;
		if (currPath !== '/') {
			const urlLastChar = currPath.charAt(currPath.length - 1);
			if (urlLastChar === '/') {
				const cleanUrl = currPath.slice(0, -1);
				window.location.replace(cleanUrl);
			}
		}

		handleUserLoginRedirects(currPath);

		if (!spaceViewCheckerDone) {
			setSpaceViewCheckerDone(true);
			handleSpaceViewChecker(currPath);
		} else {
			clearTimeout(collectionCheckerTimeout);
			setCollectionCheckerTimeout(null);

			const winLocPath = window.location.pathname;
			setCollectionCheckerTimeout(
				setTimeout(() => {
					collectionTypeChecker(winLocPath, activeTab);
				})
			);
		}

		setUserName(currPath);

		if (
			currPath.includes('membership/wallet-set-password') ||
			currPath.includes('membership/member-profile') ||
			currPath.includes('membership/activate') ||
			currPath.includes('membership/buy/payment-completed') ||
			currPath.includes('membership/claim')
		) {
			const pathArr = currPath.match(/^\/([^/]*)\//);
			if (pathArr && pathArr.length > 0 && pathArr[1]) {
				const id = searchParams.get('id');
				if (id) {
					const username = currPath.match(/^\/([^/]*)\//)[1];
					const space_username = username.replace('@', '');
					setVerifyEmailSpaceUsername(space_username);
					setVerifyEmailSpaceMembershipId(parseInt(id));
				}
			}
		}

		if (
			currPath.includes('item/wallet-set-password') ||
			currPath.includes('item/member-profile') ||
			currPath.includes('item/activate') ||
			currPath.includes('item/buy/payment-completed') ||
			currPath.includes('item/claim')
		) {
			const pathArr = currPath.match(/^\/([^/]*)\//);
			if (pathArr && pathArr.length > 0 && pathArr[1]) {
				const id = searchParams.get('id');
				if (id) {
					const username = currPath.match(/^\/([^/]*)\//)[1];
					const space_username = username.replace('@', '');
					setVerifyEmailSpaceUsername(space_username);
					setVerifyEmailSpaceItemId(parseInt(id));
				}
			}
		}

		if (currentLocation.pathname) {
			// window.scrollTo(0, 0);
		}

		if (spaceInfoLoading && (spaceInfoLoading === 'done' || spaceInfoLoading === 'failed')) {
			const space = spaceViewData ? spaceViewData : spaceInfo;
			checkPagePermissionRedirect(currentLocation.pathname, authUserInfo, space);
		}

	}, [
		currentLocation.pathname,
		verifyEmailSpaceUsername,
		viewUsername,
		tokenRequestSuccess,
		isLoggedIn,
		isInitialLoad,
		spaceInfo,
		spaceInfoLoading,
		authUserInfo,
		spaceViewData,
		spaceViewCheckerDone,
		activeTab,
		verifyEmailSpaceMembershipId,
		displayPageContentLoaderStatus
	]);


	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (!windowSize) {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		} else {
			handleWindowResponsiveness(windowSize);
		}
	}, [windowSize]);

	return (
		<Layout>
			{window.location.pathname === routes.app() ? (
				<Routes>
					<Route exact path={routes.app()} element={<Home />} />
				</Routes>
			) : (
				<>
					{productionLoadComponentsWithBlankView() === true ? (
						<>
							<Routes>
								<Route exact path={routes.login()} element={<Home />} />
								<Route exact path={routes.forgotPassword()} element={<Home />} />
								<Route exact path={routes.signUp()} element={<Home />} />
							</Routes>
						</>
					) : (
						<>
							{sitewideLinksFetched && (
								<div className="content-body ">
									{spaceViewData && spaceViewData.plan === 'pro' ? (
										<HeaderPro
											spaceData={spaceViewData}
											onSelectedTabChange={handleGlobalActiveChange}
											activeTab={activeTab}
										/>
									) : (
										<Header spaceData={spaceViewData} />
									)}
									<div
										id="page-content-loading-overlay"
										className={displayPageContentLoaderStatus ? 'display' : ''}
									>
										<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
									</div>
									<div
										className={
											displayPageContentLoaderStatus
												? 'hide-page-content max-[768px]:min-h-screen  '
												: 'flex flex-col min-h-[86vh] md:min-h-[50vh]  main-background'
												// ? 'hide-page-content max-[768px]:min-h-screen'
												// : 'flex flex-col min-h-screen md:min-h-[54vh]'
										}
									>
										<Routes>
											<Route exact path={routes.app()} element={<Home />} />
											<Route
												exact
												path={routes.dashboard()}
												element={<Dashboard />}
											/>

											{/*Space Dashboard routes*/}
											<Route
												exact
												path={routes.spaceDashboard()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceActivity()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceMembers()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.manageMember()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceUpdates()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.createUpdate()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.editUpdate()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceBenefits()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceItems()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.manageItem()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.itemType()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.createItemCommunity()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.createItemNFT()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.editItem()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.addItemMonthBenefit()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.editItemMonthBenefit()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceMemberships()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.manageMembership()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.membershipType()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.createMembershipCommunity()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.createMembershipNFT()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.editMembership()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.addMemMonthBenefit()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.editMemMonthBenefit()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceTransactions()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceManageSpace()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.spaceManageProfile()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.spaceManageHomePage()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.spaceManageInfoPages()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.spaceManageCustomizeSpace()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.spaceManageCustomizeNav()}
												element={<SpaceDashboard />}
											/>
											<Route
												exact
												path={routes.spaceManageChannels()}
												element={<SpaceDashboard />}
											/>

											<Route
												exact
												path={routes.spaceSettings()}
												element={<SpaceDashboard />}
											/>
											{/*End Space Dashboard routes*/}

											<Route
												exact
												path={routes.spaces()}
												element={<Spaces />}
											/>
											<Route
												exact
												path={routes.manageSpaceMembership()}
												element={<Spaces />}
											/>
											<Route
												exact
												path={routes.manageSpaceMembershipExpired()}
												element={<Spaces />}
											/>
											<Route
												exact
												path={routes.collections()}
												element={<Collections />}
											/>
											<Route
												exact
												path={routes.viewCollection()}
												element={<Collections />}
											/>
											<Route
												exact
												path={routes.viewCollectionItem()}
												element={<Collections />}
											/>
											<Route
												exact
												path={routes.profile()}
												element={<Profile />}
											/>
											<Route
												exact
												path={routes.settings()}
												element={<Settings />}
											/>
											<Route
												exact
												path={routes.signUp()}
												element={<SignUp />}
											/>
											<Route
												exact
												path={routes.login()}
												element={<Login />}
											/>
											<Route
												exact
												path={routes.forgotPassword()}
												element={<ForgotPassword />}
											/>
											<Route
												exact
												path={routes.logout()}
												element={<Logout />}
											/>
											<Route
												exact
												path={routes.alchemyLabolatory()}
												element={<Lab />}
											/>
											<Route
												exact
												path={routes.emailVerification()}
												element={<EmailVerification />}
											/>
											<Route
												exact
												path={routes.registerMemberProfile()}
												element={<RegisterMemberProfile />}
											/>
											
											<Route
												exact
												path={routes.spaceMembershipRegisterMemberProfile()}
												element={
													<RegisterMemberProfile
														verifyEmailSpaceUsername={
															verifyEmailSpaceUsername
														}
														verifyEmailSpaceMembershipId={
															verifyEmailSpaceMembershipId
														}
													/>
												}
											/>
											<Route
												exact
												path={routes.spaceItemRegisterMemberProfile()}
												element={
													<RegisterMemberProfile
														verifyEmailSpaceUsername={
															verifyEmailSpaceUsername
														}
														verifyEmailSpaceItemId={
															verifyEmailSpaceItemId
														}
													/>
												}
											/>

											<Route
												exact
												path={routes.walletSetAccount()}
												element={<Register />}
											/>

											<Route
												exact
												path={routes.walletSetAccountEmailSent()}
												element={<SignUp />}
											/>
											<Route
												exact
												path={routes.spaceMembershipWalletSetAccountEmailSent()}
												element={<SignUp />}
											/>
											<Route
												exact
												path={routes.spaceItemWalletSetAccountEmailSent()}
												element={<SignUp />}
											/>

											<Route
												exact
												path={routes.connectWallet()}
												element={<Register />}
											/>
											<Route
												exact
												path={routes.emailVerificationWalletSetPassword()}
												element={<EmailVerification />}
											/>
											<Route
												exact
												path={routes.spaceMembershipEmailVerificationWalletSetPassword()}
												element={
													<EmailVerification
														verifyEmailSpaceUsername={
															verifyEmailSpaceUsername
														}
														verifyEmailSpaceMembershipId={
															verifyEmailSpaceMembershipId
														}
													/>
												}
											/>
											<Route
												exact
												path={routes.spaceItemEmailVerificationWalletSetPassword()}
												element={
													<EmailVerification
														verifyEmailSpaceUsername={
															verifyEmailSpaceUsername
														}
														verifyEmailSpaceItemId={
															verifyEmailSpaceItemId
														}
													/>
												}
											/>

											{/*Profile sub-pages*/}
											<Route
												exact
												path={routes.profileEdit()}
												element={<Profile />}
											/>
											<Route
												exact
												path={routes.profileView()}
												element={<Profile />}
											/>
											<Route
												exact
												path={routes.profileEditAboutSection()}
												element={<Profile />}
											/>
											<Route
												exact
												path={routes.profileManageFollowers()}
												element={<Profile />}
											/>
											<Route
												exact
												path={routes.profileManageContact()}
												element={<Profile />}
											/>
											<Route
												exact
												path={routes.profileManageVisibility()}
												element={<Profile />}
											/>

											{/*Account Settings sub-pages*/}
											<Route
												exact
												path={routes.settings2FA()}
												element={<Settings />}
											/>
											<Route
												exact
												path={routes.settingsNotificationPreferences()}
												element={<Settings />}
											/>
											<Route
												exact
												path={routes.settingsPaymentMethods()}
												element={<Settings />}
											/>
											<Route
												exact
												path={routes.settingsMagicWallet()}
												element={<Settings />}
											/>
											<Route
												exact
												path={routes.settingsTransactions()}
												element={<Settings />}
											/>

											{isViewSpacePage ? (
												<Route
													path={routes.viewUserSpace() + viewUsername}
													element={
														<UserView
															globalActiveTab={activeTab}
															spaceViewData={handleSpaceViewData}
															updateGlobalActiveTab={
																handleGlobalActiveChange
															}
														/>
													}
												/>
											) : (
												<>
													{claimType && (
														<>
															{claimType === 'membership' && (
																<Route
																	exact
																	path={routes.spaceMembersProductsMembership()}
																	element={
																		<UserView
																			globalActiveTab={
																				activeTab
																			}
																			spaceViewData={
																				handleSpaceViewData
																			}
																			updateGlobalActiveTab={
																				handleGlobalActiveChange
																			}
																		/>
																	}
																/>
															)}
															{claimType === 'membership_buy' && (
																<Route
																	exact
																	path={routes.spaceMembershipBuy()}
																	element={
																		<UserView
																			globalActiveTab={
																				activeTab
																			}
																			spaceViewData={
																				handleSpaceViewData
																			}
																			updateGlobalActiveTab={
																				handleGlobalActiveChange
																			}
																		/>
																	}
																/>
															)}
															{claimType === 'item' && (
																<Route
																	exact
																	path={routes.spaceMembersProductsItem()}
																	element={
																		<UserView
																			globalActiveTab={
																				activeTab
																			}
																			spaceViewData={
																				handleSpaceViewData
																			}
																			updateGlobalActiveTab={
																				handleGlobalActiveChange
																			}
																		/>
																	}
																/>
															)}
															{claimType === 'item_buy' && (
																<Route
																	exact
																	path={routes.spaceItemBuy()}
																	element={
																		<UserView
																			globalActiveTab={
																				activeTab
																			}
																			spaceViewData={
																				handleSpaceViewData
																			}
																			updateGlobalActiveTab={
																				handleGlobalActiveChange
																			}
																		/>
																	}
																/>
															)}
														</>
													)}
												</>
											)}

											{/* Space Tabs */}
											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/update'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/benefit'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/member'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/support'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												path={
													routes.viewUserSpace() + viewUsername + '/about'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												path={routes.spaceViewSpaceUpdate()}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												path={routes.spaceViewSpaceUpdateWithTitle()}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>
											{/* <Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/channel'
												}
												element={
													<UserView
														globalActiveTab={}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/> */}

											{/* <Route
												exact
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/channel'
												}
												element={
												<Channel spaceViewData={handleSpaceViewData}/>
											}
											/> */}

											{/* Profile Space Tabs */}
											{/* <Route
					path={routes.viewUserSpace() + viewUsername + '/about_me'}
					element={<UserView globalActiveTab={activeTab} updateGlobalActiveTab={handleGlobalActiveChange} />}
				/> */}

											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/spaces'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>
											

											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/collections'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/activities'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/following'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>
											<Route
												path={
													routes.viewUserSpace() +
													viewUsername +
													'/followers'
												}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>
											{/* End of Profile Space Tabs */}

											<Route
												exact
												path={routes.createSpace()}
												element={<SpaceCreate />}
											/>
											<Route
												exact
												path={routes.updateSpace()}
												element={<SpaceCreate />}
											/>
											<Route
												exact
												path={routes.createSpaceAddDetailsPage()}
												element={<SpaceCreateAddDetails />}
											/>
											<Route
												exact
												path={routes.createSpaceAddMembershipPage()}
												element={<SpaceCreateAddMembership />}
											/>
											<Route
												exact
												path={routes.createSpaceAddCommunityPage()}
												element={<SpaceCreateAddCommunity />}
											/>
											<Route
												exact
												path={routes.createSpaceAddMembershipTraitsPage()}
												element={<SpaceCreateAddTraits />}
											/>
											<Route
												exact
												path={routes.createSpaceAddMembershipBenefitPage()}
												element={<SpaceCreateAddBenefit />}
											/>
											<Route
												exact
												path={routes.createSpaceBasicCompletePage()}
												element={<SpaceCreateBasicCompleteView />}
											/>
											<Route
												exact
												path={routes.createSpaceAddFirstItemPage()}
												element={<CreateFirstItem />}
											/>
											<Route
												exact
												path={routes.createSpaceGettingStarted()}
												element={<SpaceGettingStarted />}
											/>
											<Route
												exact
												path={routes.createSpacePostUpdate()}
												element={<PostUpdate />}
											/>
											<Route
												exact
												path={routes.createCommunityFeatures()}
												element={<CommunityFeatures />}
											/>
											<Route
												exact
												path={routes.createSpaceInviteTeam()}
												element={<InviteTeam />}
											/>
											<Route
												exact
												path={routes.createSpaceUpgradePlan()}
												element={<UpgradePlan />}
											/>
											<Route
												exact
												path={routes.createCustomizeProSpace()}
												element={<CustomizeProSpace />}
											/>
											<Route
												exact
												path={routes.createCustomizeProNav()}
												element={<CustomizeProNav />}
											/>
											<Route
												exact
												path={routes.createSpaceLaunch()}
												element={<SpaceLaunch />}
											/>
											<Route
												exact
												path={routes.createSpaceLaunchCheckList()}
												element={<LaunchChecklist />}
											/>
											<Route
												exact
												path={routes.createSpaceAddBusiness()}
												element={<AddBusinessDetails />}
											/>
											<Route
												exact
												path={routes.createSpaceAddPages()}
												element={<AddPages />}
											/>
											<Route
												exact
												path={routes.createSpacePayment()}
												element={<AddPaymentMethod />}
											/>
											<Route
												exact
												path={routes.createSpaceBilling()}
												element={<AddBillingInformation />}
											/>
											<Route
												exact
												path={routes.createSpacePrevLaunch()}
												element={<PreviewAndLaunch />}
											/>
											<Route
												exact
												path={routes.spaceMembershipWalletConnect()}
												element={<MembershipClaimWalletConnected />}
											/>
											<Route
												exact
												path={routes.spaceMembershipErrorConnect()}
												element={<MembershipErrorWalletConnect />}
											/>
											<Route
												exact
												path={routes.spaceMembershipActivate()}
												element={
													<MembershipActivateWalletConnect
														verifyEmailSpaceUsername={
															verifyEmailSpaceUsername
														}
														verifyEmailSpaceMembershipId={
															verifyEmailSpaceMembershipId
														}
													/>
												}
											/>

											<Route
												exact
												path={routes.spaceItemWalletConnect()}
												element={<ItemClaimWalletConnected />}
											/>
											<Route
												exact
												path={routes.spaceItemErrorConnect()}
												element={<ItemErrorWalletConnect />}
											/>
											<Route
												exact
												path={routes.spaceItemActivate()}
												element={
													<ItemActivateWalletConnect
														verifyEmailSpaceUsername={
															verifyEmailSpaceUsername
														}
														verifyEmailSpaceItemId={
															verifyEmailSpaceItemId
														}
													/>
												}
											/>

											<Route
												exact
												path={routes.spaceItemPaymentMethod()}
												element={<ItemPaymentMethod />}
											/>

											<Route
												exact
												path={routes.spaceItemPaymentCompleted()}
												element={<ItemPaymentCompleted />}
											/>

											<Route
												exact
												path={routes.spaceMembershipPaymentMethod()}
												element={<MembershipPaymentMethod />}
											/>

											<Route
												exact
												path={routes.spaceMembershipPaymentCompleted()}
												element={<MembershipPaymentCompleted />}
											/>

											<Route
												exact
												path={routes.spaceFAQ()}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												exact
												path={routes.spaceTOS()}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												exact
												path={routes.spacePrivacyPolicy()}
												element={
													<UserView
														globalActiveTab={activeTab}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>
											<Route
												exact
												path={routes.channel()}
												element={
													<UserView
														globalActiveTab={'channel'}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>

											<Route
												exact
												path={routes.channelGuid()}
												element={
													<UserView
														globalActiveTab={'channel'}
														spaceViewData={handleSpaceViewData}
														updateGlobalActiveTab={
															handleGlobalActiveChange
														}
													/>
												}
											/>
											<Route
												exact
												path={routes.termsOfUse()}
												element={<TermsOfUse />}
											/>
											<Route
												exact
												path={routes.privacyPolicy()}
												element={<PrivacyPolicy />}
											/>
											<Route
												exact
												path={routes.communityGuidelines()}
												element={<CommunityGuidelines />}
											/>
										</Routes>
									</div>
									{spaceViewData && spaceViewData.plan === 'pro' ? (
										
										<section className='max-[767px]:sticky max-[767px]:bottom-0 !z-[999]'>
											<section className="block md:hidden">
												<FooterProMobile
													spaceData={spaceViewData}
													onSelectedTabChange={handleGlobalActiveChange}
													activeTab={activeTab}
												/>
											</section>
											<section className="hidden md:block">
												<FooterProV2
													spaceData={spaceViewData}
													onSelectedTabChange={handleGlobalActiveChange}
													activeTab={activeTab}
												/>
											</section>
										</section>
									) : !displayPageContentLoaderStatus && ( 
										<section className=' max-[767px]:sticky max-[767px]:bottom-0 !z-[999]'>
											<section className="block md:hidden">
												<FooterMobile
													spaceData={spaceViewData}
												/>
											</section>
											<section className="hidden md:block">
												<FooterV2
													spaceData={spaceViewData}
												/>
											</section>
										</section>
										
									) }
								</div>
							)}
						</>
					)}
				</>
			)}
		</Layout>
	);
};

export default AppLayout;
