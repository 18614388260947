import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { getExplicitContent } from '../../../../services/space';

ReactModal.setAppElement('#root');

const ExplicitContent = (props) => {
	const { modalOpen, setModalOpen, onSelectedItems, selectedItems, isExplicitContent } = props;
	const [initialLoad, setInitialLoad] = useState(true);
	const [explicitContent, setExplicitContent] = useState('');
	const [selectedExplicitIds, setSelectedExplicitIds] = useState([]);
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32
		},
		overlay: { background: 'rgba(0, 0, 0, 0.6)' }
	};

	const setSelected = (e) => {
		if (e.target.className === 'selected') {
			e.target.classList.remove('selected');
			selectedExplicitIds.splice(selectedExplicitIds.indexOf(e.target.dataset.id), 1);
		} else {
			e.target.classList.add('selected');
			selectedExplicitIds.push(Number(e.target.dataset.id));
		}
	};

	const saveExplicitSettings = () => {
		onSelectedItems(selectedExplicitIds);
		setModalOpen(false);
	};

	const populateExplicitContent = async () => {
		try {
			const response = await getExplicitContent();
			if (response && response.result) {
				setExplicitContent(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		setSelectedExplicitIds(selectedItems);
		if (initialLoad) {
			populateExplicitContent().then((r) => {});
			setInitialLoad(false);
		}
	}, [initialLoad, selectedItems]);
	// console.log(explicitContent)
	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={customStyles}
		>
			<button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
				<i className="fa-solid fa-xmark"></i>
			</button>
			<h3 className="header-medium mt-2">
				Explicit {isExplicitContent ? 'Content' : 'Access'} Settings
			</h3>
			<p className="body-text--small mt-5">
				Please select the types of Explicit or Sensitive Content that may be visible.
				Depending on the type of content, viewers may get a warning prior to viewing.
			</p>
			<ul className="explicit-list mt-2">
				{Object.values(explicitContent).map((Item, Key) => (
					<li key={Key}>
						<Link
							className={selectedItems ? selectedItems.includes(Item.id) ? 'selected' : '' : ''}
							data-id={Item.id}
							onClick={setSelected}
						>
							{Item.content}
						</Link>
					</li>
				))}
			</ul>
			<Button className="btn btn-md btn-primary mb-2 " onClick={saveExplicitSettings}>
				<span className='break-all text-sm sm:text-base'>Save Explicit {isExplicitContent ? 'Content' : 'Access'} Settings</span>
			</Button>
		</ReactModal>
	);
};

export default ExplicitContent;
