import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import routes from '../../../routes';

import space_ex1 from '../../../../src/assets/images/space_ex1.png';
import space_ex2 from '../../../../src/assets/images/space_ex2.png';
import space_ex3 from '../../../../src/assets/images/space_ex3.png';
import space_ex4 from '../../../../src/assets/images/space_ex4.png';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {getSitewideLink} from "../../../utils/sitewideLinks";

const SpaceCreateBasicCompleteView = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const continueNextPage = () => {
		const url = routes.createSpaceAddFirstItemPage();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main className="dashboard-wrapper flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
						<div className="col-span-12">
							<h1 className="header-large">Basic Space Complete!</h1>
						</div>
						<div className="col-span-12  grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5">
							<div className="md:col-span-7 grid max-[768px]:order-last">
								<p className="body-text--reg">
									Congrats! Your{' '}
									{/*<Link to="#" className="gradient-color-txt">*/}
									{/*	Basic Space*/}
									{/*</Link>*/}
									{getSitewideLink('basic_space_complete')}
									{' '}is created and ready to finalize and launch! Before you do, we
									recommend you add some additional content and configure your
									community features first. You can also choose to add your own domain
									and custom branding by upgrading to a{' '}
									{/*<Link to="#" className="gradient-color-txt">*/}
									{/*	Pro Space*/}
									{/*</Link>*/}
									{getSitewideLink('pro_space')}
									{' '}as well. You can preview your Space then check out the optional
									steps below!
								</p>
								<Link
									to={routes.viewUserSpace() + space_username}
									target="_blank"
									className="btn btn-lg btn-secondary mt-5"
								>
									Preview Your Space{' '}
									<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
								</Link>
								<h3 className="header-medium mt-5">Recommended Steps</h3>
								<p className="body-text--reg">
									The following optional steps guide you through adding additional
									content and customizing your Space:{' '}
								</p>
								<ul className="mt-5">
									<li className="body-text--reg">
										{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Pro Create Your First Item*/}
										{/*</Link>*/}
										{getSitewideLink('pro_create_item', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Offer a free or paid digital Item that gives Members additional
										Benefits or upgraded access.
									</li>
									<li className="body-text--reg">
										{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Post Your First Update*/}
										{/*</Link>*/}
										{getSitewideLink('post_update_basic_complete', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Welcome visitors with a public post or make a Members Only post
										to guide your first Members.
									</li>
									<li className="body-text--reg">
										{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Enable Community Features*/}
										{/*</Link>*/}
										{getSitewideLink('enable_community_features_basic_complete', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Set up and customize your Community Channels, Points System, and
										Leaderboard.
									</li>
									<li className="body-text--reg">
										{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Upgrade to Pro*/}
										{/*</Link>*/}
										{getSitewideLink('upgrade_pro_basic_complete', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Connect your domain, get custom branding, and other Pro features
										by upgrading your Space.
									</li>
									<li className="body-text--reg">
										{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Invite Your Team*/}
										{/*</Link>*/}
										{getSitewideLink('invite_team_basic_complete', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Give your team special access to your Space to manage, moderate,
										and post.
									</li>
								</ul>
								<p className="body-text--reg mt-5">
									Each additional step can be skipped and you can always access them
									later from your Dashboard.
								</p>
								<Button
									htmlType="submit"
									className="btn btn-lg btn-primary mt-5"
									onClick={continueNextPage}
								>
									Continue
								</Button>
								<p className="body-text--reg mt-5">
									Need help?{' '}
									{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
									{/*	Get support*/}
									{/*</Link>*/}
									{getSitewideLink('get_support', 'gradient-color-txt body-txtitalic--reg')}
								</p>
							</div>
							<div className="md:col-span-5 max-[768px]:order-first">
								<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 gap-2  md:gap-8">
									<div className="col-span-12 md:col-span-6">
										<img className="img-fluid mx-auto" src={space_ex1} />
									</div>
									<div className="col-span-12 md:col-span-6 text-end">
										<img className="img-fluid mx-auto" src={space_ex2} />
										<img className="img-fluid mt-5 mx-auto" src={space_ex3} />
									</div>
								</div>
								<div className="grid grid-cols-12  gap-y-8 md:gap-y-0  gap-2   md:gap-8">
									<div className="col-span-12">
										<img className="img-fluid mt-5 mx-auto" src={space_ex4} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(SpaceCreateBasicCompleteView);
