import { memo, useEffect, useCallback, useState, useRef } from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import TextareaAutosize from 'react-textarea-autosize';

import { VALIDATE_MESSAGES } from '../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE
} from '../../../constants/file';
import { createSpaceAddDetails } from '../../../services/space';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import { LINKS_VISIBILITY_LIST_WITH_DEFAULT, MEMBERSHIP_TYPES } from '../../../constants/space';
import routes from '../../../routes';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import UploadDragger from '../../../components/commons/UploadDragger';
import Links from './Links';
import FormFieldStacked from '../../../components/commons/FormFieldStacked';

import './../SpaceCreate.scss';

const linkObject = {
	id: null,
	title: null,
	description: null,
	image: null,
	url: null
};

const SpaceCreateAddDetails = () => {
	const currentLocation = useLocation();
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createSpaceAddDetailsForm] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [linksAlert, setLinksAlert] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [welcomeTitle, setWelcomeTitle] = useState(null);
	const [welcomeMessage, setWelcomeMessage] = useState(null);
	const [welcomeYoutubeVideo, setWelcomeYoutubeVideo] = useState(null);
	const [welcomeImageError, setWelcomeImageError] = useState(null);
	const [welcomeImage, setWelcomeImage] = useState(null);
	const [welcomeImageBlobPreview, setWelcomeImageBlobPreview] = useState(null);
	const [welcomeVideo, setWelcomeVideo] = useState(null);
	const [welcomeVideoBlobPreview, setWelcomeVideoBlobPreview] = useState(null);
	const [newsletterChecked, setNewsletterChecked] = useState(false);
	const [linksTitleFirst, setLinksTitleFirst] = useState(null);
	const [linksTitleSecond, setLinksTitleSecond] = useState(null);
	const [selectedLinksVisibility, setSelectedLinksVisibility] = useState('default');
	const [linksArr, setLinksArr] = useState([]);
	const [linksArrLength, setLinksArrLength] = useState(null);
	const [removedLinksIdArr, setRemovedLinksIdArr] = useState([]);
	const [addLinkDisabled, setAddLinkDisabled] = useState(false);
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const welcomeTitleRef = useRef(null);
	const welcomeMessageRef = useRef(null);
	const welcomeYoutubeVideoRef = useRef(null);
	const linksTitleFirstRef = useRef(null);
	const linksTitleSecondRef = useRef(null);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [saveAndGoBackTriggered, setSaveAndGoBackTriggered] = useState(false);
	const [resetLinkPreviews, setResetLinkPreviews] = useState(false);

	// TODO: change this implementation to be dynamic
	const [addLinkLinkImage1, setAddLinkLinkImage1] = useState(null);
	const [addLinkLinkImage2, setAddLinkLinkImage2] = useState(null);
	const [addLinkLinkImage3, setAddLinkLinkImage3] = useState(null);
	const [addLinkLinkVideo1, setAddLinkLinkVideo1] = useState(null);
	const [addLinkLinkVideo2, setAddLinkLinkVideo2] = useState(null);
	const [addLinkLinkVideo3, setAddLinkLinkVideo3] = useState(null);
	const [link1ImageIsRemoved, setLink1ImageIsRemoved] = useState(false);
	const [link1VideoIsRemoved, setLink1VideoIsRemoved] = useState(false);
	const [link2ImageIsRemoved, setLink2ImageIsRemoved] = useState(false);
	const [link2VideoIsRemoved, setLink2VideoIsRemoved] = useState(false);
	const [link3ImageIsRemoved, setLink3ImageIsRemoved] = useState(false);
	const [link3VideoIsRemoved, setLink3VideoIsRemoved] = useState(false);

	/**
	 * Form 'createSpaceAddDetailsForm' onBlur event trigger
	 *
	 * @type {(function(): Promise<void>)|*}
	 */
	const handleFormOnBlur = useCallback(async () => {
		await createSpaceAddDetailsForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	/**
	 * Form 'createSpaceAddDetailsForm' onChange event trigger
	 *
	 * @type {(function(): Promise<void>)|*}
	 */
	const handleFormChanges = useCallback(async () => {
		setWelcomeTitle(createSpaceAddDetailsForm.getFieldValue('welcome_title'));
		setWelcomeMessage(createSpaceAddDetailsForm.getFieldValue('welcome_message'));
		setWelcomeYoutubeVideo(createSpaceAddDetailsForm.getFieldValue('welcome_youtube_video'));
		setLinksTitleFirst(createSpaceAddDetailsForm.getFieldValue('links_title_first'));
		setLinksTitleSecond(createSpaceAddDetailsForm.getFieldValue('links_title_second'));

		await createSpaceAddDetailsForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	/**
	 * Form 'createSpaceAddDetailsForm' submit and POST request
	 *
	 * @type {(function(*): Promise<void>)|*}
	 */
	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setLinksAlert(null);
			setDisplayDropDownError(true);
			setWelcomeImageError(null);

			let hasError = false;

			if (!welcomeImageBlobPreview) {
				setWelcomeImageError('Welcome Image is required.');
				hasError = true;
			}

			if (selectedLinksVisibility === 'default') {
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = {};
			data.welcome_title = values.welcome_title;
			data.welcome_message = values.welcome_message;
			data.welcome_youtube_url = values.welcome_youtube_video;
			data.has_newsletters_signup = newsletterChecked;
			data.links_visibility = selectedLinksVisibility;
			data.links_section_first_title = values.links_title_first;
			data.links_section_second_title = values.links_title_second;

			if (welcomeImage) {
				data.welcome_image = welcomeImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (welcomeVideo) {
				data.welcome_video = welcomeVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			const links = [];
			if (
				values.add_link_link_title_0 &&
				values.add_link_link_description_0 &&
				values.add_link_link_url_0
			) {
				const arr = {
					title: values.add_link_link_title_0,
					description: values.add_link_link_description_0,
					url: values.add_link_link_url_0
				};

				if (link1ImageIsRemoved) {
					data.link1_image_is_empty = true;
				} else {
					if (addLinkLinkImage1) {
						data.link1_image = addLinkLinkImage1;
					}
				}

				if (link1VideoIsRemoved) {
					data.link1_video_is_empty = true;
				} else {
					if (addLinkLinkVideo1) {
						data.link1_video = addLinkLinkVideo1;
					}
				}

				if (linksArr[0] && linksArr[0]['id']) {
					arr['id'] = linksArr[0]['id'];
				}
				links.push(arr);
			}
			if (
				values.add_link_link_title_1 &&
				values.add_link_link_description_1 &&
				values.add_link_link_url_1
			) {
				const arr = {
					title: values.add_link_link_title_1,
					description: values.add_link_link_description_1,
					url: values.add_link_link_url_1
				};

				if (link2ImageIsRemoved) {
					data.link2_image_is_empty = true;
				} else {
					if (addLinkLinkImage2) {
						data.link2_image = addLinkLinkImage2;
					}
				}

				if (link2VideoIsRemoved) {
					data.link2_video_is_empty = true;
				} else {
					if (addLinkLinkVideo2) {
						data.link2_video = addLinkLinkVideo2;
					}
				}

				if (linksArr[1] && linksArr[1]['id']) {
					arr['id'] = linksArr[1]['id'];
				}
				links.push(arr);
			}
			if (
				values.add_link_link_title_2 &&
				values.add_link_link_description_2 &&
				values.add_link_link_url_2
			) {
				const arr = {
					title: values.add_link_link_title_2,
					description: values.add_link_link_description_2,
					url: values.add_link_link_url_2
				};

				if (link3ImageIsRemoved) {
					data.link3_image_is_empty = true;
				} else {
					if (addLinkLinkImage3) {
						data.link3_image = addLinkLinkImage3;
					}
				}

				if (link3VideoIsRemoved) {
					data.link3_video_is_empty = true;
				} else {
					if (addLinkLinkVideo3) {
						data.link3_video = addLinkLinkVideo3;
					}
				}

				if (linksArr[2] && linksArr[2]['id']) {
					arr['id'] = linksArr[2]['id'];
				}
				links.push(arr);
			}

			if (links && links.length > 3) {
				setLinksAlert({
					variant: 'danger',
					message: 'Exceeds maximum 3 links.'
				});
				return;
			}

			data.links = JSON.stringify(links);

			if (removedLinksIdArr && removedLinksIdArr.length > 0) {
				data.removed_links = JSON.stringify(removedLinksIdArr);
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					setSubmitted(true);
					const response = await createSpaceAddDetails(data);
					if (response && response.result) {
						setPhotoIsRemoved(false);
						setVideoIsRemoved(false);
						setLink1ImageIsRemoved(false);
						setLink1VideoIsRemoved(false);
						setLink2ImageIsRemoved(false);
						setLink2VideoIsRemoved(false);
						setLink3ImageIsRemoved(false);
						setLink3VideoIsRemoved(false);
						setRemovedLinksIdArr([]);
						dispatch(getSpaceInfo(space_username));

						setTimeout(() => {
							if (saveAndGoBackTriggered) {
								const url = routes.updateSpace();
								navigate(url.replace(':space_username', space_username));
							} else {
								let url = routes.createSpaceAddMembershipPage();
								if (
									spaceInfo &&
									spaceInfo.memberships &&
									spaceInfo.memberships.length > 0
								) {
									const firstMembership = spaceInfo.memberships[0];
									if (firstMembership.type === MEMBERSHIP_TYPES.COMMUNITY) {
										url = routes.createSpaceAddCommunityPage();
									}
								}
								navigate(url.replace(':space_username', space_username));
							}
						}, 1000);
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						window.scrollTo(0, 0);
					}
					setSubmitted(false);
					setDisplayDropDownError(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			space_username,
			authUserInfo,
			spaceInfo,
			welcomeTitle,
			welcomeMessage,
			welcomeYoutubeVideo,
			welcomeImage,
			welcomeVideo,
			newsletterChecked,
			linksTitleFirst,
			linksTitleSecond,
			selectedLinksVisibility,
			addLinkLinkImage1,
			addLinkLinkImage2,
			addLinkLinkImage3,
			addLinkLinkVideo1,
			addLinkLinkVideo2,
			addLinkLinkVideo3,
			photoIsRemoved,
			videoIsRemoved,
			welcomeImageBlobPreview,
			link1ImageIsRemoved,
			link1VideoIsRemoved,
			link2ImageIsRemoved,
			link2VideoIsRemoved,
			link3ImageIsRemoved,
			link3VideoIsRemoved,
			removedLinksIdArr,
			saveAndGoBackTriggered
		]
	);

	/**
	 * UploadDragger file change for Welcome Image
	 *
	 * @param file
	 * @param blobPreview
	 */
	const handleWelcomeImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setWelcomeImage(file);
		setWelcomeImageBlobPreview(blobPreview);

		createSpaceAddDetailsForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	/**
	 * UploadDragger file change for Welcome Video
	 *
	 * @param file
	 * @param blobPreview
	 */
	const handleWelcomeVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
		}

		setWelcomeVideo(file);
		setWelcomeVideoBlobPreview(blobPreview);

		createSpaceAddDetailsForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	/**
	 * Newsletter Checkbox event trigger handler
	 *
	 * @param e
	 */
	const onChangeNewsletter = (e) => {
		setNewsletterChecked(e.target.checked);
	};

	/**
	 * Function to insert new empty link form group
	 *
	 * @type {(function(): void)|*}
	 */
	const addLink = useCallback(() => {
		let currArr = linksArr;

		if (linksArr && linksArr.length >= 3) {
			setAddLinkDisabled(true);
			return;
		}

		if (!currArr) {
			currArr = [];
		}

		currArr.push(linkObject);

		if (currArr.length <= 2) {
			setAddLinkDisabled(false);
			setLinksArr(currArr);
		} else {
			setAddLinkDisabled(true);
		}

		setLinksArrLength(currArr.length);

		currArr.forEach((data, key) => {
			setLinkFormValues(data, key).then((r) => {});
		});

		setResetLinkPreviews(false);
	}, [linksArr]);

	/**
	 * Function to remove selected link and reorder links
	 *
	 * @type {(function(*): void)|*}
	 */
	const handleUpdateLinkToRemove = useCallback(
		(passedData) => {
			const currArr = linksArr;
			const currRemovedLinksIdArr = removedLinksIdArr;
			const newArr = [];

			setLinksArr([]);

			currArr.forEach((data, key) => {
				createSpaceAddDetailsForm.setFieldValue('add_link_link_title_' + key.toString());
				createSpaceAddDetailsForm.setFieldValue(
					'add_link_link_description_' + key.toString()
				);
				createSpaceAddDetailsForm.setFieldValue('add_link_link_url_' + key.toString());

				if (key === passedData.index) {
					if (passedData.id) {
						currRemovedLinksIdArr.push(passedData.id);
						setRemovedLinksIdArr(currRemovedLinksIdArr);
					}
				} else {
					newArr.push(data);
				}
			});

			if (newArr.length > 0) {
				newArr.forEach((data, key) => {
					setLinkFormValues(data, key).then((r) => {});
				});
				setLinksArr(newArr);
				setLinksArrLength(newArr.length);
			} else {
				setLinksArr(null);
				setLinksArrLength(0);
			}

			setAddLinkDisabled(false);
			setResetLinkPreviews(true);
		},
		[linksArr, removedLinksIdArr]
	);

	/**
	 * Function to handle data changes from <Links> component
	 *
	 * @type {(function(*, *, *): Promise<void>)|*}
	 */
	const handleUpdateData = useCallback(
		async (data, field, key) => {
			if (field === 'image') {
				if (data) {
					if (key === 0) {
						setLink1ImageIsRemoved(false);
						setAddLinkLinkImage1(data);
					} else if (key === 1) {
						setLink2ImageIsRemoved(false);
						setAddLinkLinkImage2(data);
					} else {
						setLink3ImageIsRemoved(false);
						setAddLinkLinkImage3(data);
					}
				} else {
					if (key === 0) {
						setLink1ImageIsRemoved(true);
					} else if (key === 1) {
						setLink2ImageIsRemoved(true);
					} else {
						setLink3ImageIsRemoved(true);
					}
				}
			} else if (field === 'video') {
				if (data) {
					if (key === 0) {
						setLink1VideoIsRemoved(false);
						setAddLinkLinkVideo1(data);
					} else if (key === 1) {
						setLink2VideoIsRemoved(false);
						setAddLinkLinkVideo2(data);
					} else {
						setLink3VideoIsRemoved(false);
						setAddLinkLinkVideo3(data);
					}
				} else {
					if (key === 0) {
						setLink1VideoIsRemoved(true);
					} else if (key === 1) {
						setLink2VideoIsRemoved(true);
					} else {
						setLink3VideoIsRemoved(true);
					}
				}
			} else {
				const currArr = JSON.parse(JSON.stringify(linksArr));
				if (currArr[key]) {
					currArr[key][field] = data;
					setLinksArr(currArr);
					setLinksArrLength(currArr.length);
				}
			}
		},
		[linksArr]
	);

	/**
	 * Set values for links in 'createSpaceAddDetailsForm' form
	 *
	 * @param data
	 * @param index
	 * @returns {Promise<void>}
	 */
	const setLinkFormValues = async (data, index) => {
		if (index === 0) {
			createSpaceAddDetailsForm.setFieldsValue({
				add_link_link_title_0: data.title,
				add_link_link_description_0: data.description,
				add_link_link_url_0: data.url
			});
			if (data.image) {
				setAddLinkLinkImage1(data.image);
			}
			if (data.video) {
				setAddLinkLinkVideo1(data.video);
			}
		}
		if (index === 1) {
			createSpaceAddDetailsForm.setFieldsValue({
				add_link_link_title_1: data.title,
				add_link_link_description_1: data.description,
				add_link_link_url_1: data.url
			});
			if (data.image) {
				setAddLinkLinkImage2(data.image);
			}
			if (data.video) {
				setAddLinkLinkVideo2(data.video);
			}
		}
		if (index === 2) {
			createSpaceAddDetailsForm.setFieldsValue({
				add_link_link_title_2: data.title,
				add_link_link_description_2: data.description,
				add_link_link_url_2: data.url
			});
			if (data.image) {
				setAddLinkLinkImage3(data.image);
			}
			if (data.video) {
				setAddLinkLinkVideo3(data.video);
			}
		}

		setTimeout(() => {
			createSpaceAddDetailsForm
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		}, 500);
	};

	/**
	 * Set 'createSpaceAddDetailsForm' form values
	 *
	 * @type {(function(*): Promise<void>)|*}
	 */
	const setFormSpaceData = useCallback(
		async (createdSpace) => {
			let textAreaWelcomeMessage = createdSpace.welcome_message
				? createdSpace.welcome_message
				: '';
			createSpaceAddDetailsForm.setFieldsValue({
				welcome_title: createSpaceAddDetailsForm.getFieldValue('welcome_title') ? createSpaceAddDetailsForm.getFieldValue('welcome_title') : createdSpace.welcome_title,
				welcome_message: createSpaceAddDetailsForm.getFieldValue('welcome_message') ? createSpaceAddDetailsForm.getFieldValue('welcome_message') : textAreaWelcomeMessage,
				welcome_youtube_video: createSpaceAddDetailsForm.getFieldValue('welcome_youtube_video') ? createSpaceAddDetailsForm.getFieldValue('welcome_youtube_video') : createdSpace.welcome_youtube_url,
				links_title_first: createdSpace.link_title_first,
				links_title_second: createdSpace.link_title_second
			});
			setWelcomeTitle(createdSpace.welcome_title);
			setWelcomeMessage(textAreaWelcomeMessage);
			setWelcomeYoutubeVideo(createdSpace.welcome_youtube_url);
			setNewsletterChecked(createdSpace.has_newsletters_signup);
			setLinksTitleFirst(createdSpace.link_title_first);
			setLinksTitleSecond(createdSpace.link_title_second);
			setSelectedLinksVisibility(createdSpace.links_visibility);
			if (createdSpace.welcome_photo) {
				if (!photoIsRemoved) {
					setWelcomeImageBlobPreview(createdSpace.welcome_photo);
				}
			}
			if (createdSpace.welcome_video) {
				if (!videoIsRemoved) {
					setWelcomeVideoBlobPreview(createdSpace.welcome_video);
				}
			}

			if (createdSpace.hasOwnProperty('links') && createdSpace.links.length > 0) {
				const arr = [];
				createdSpace.links.forEach((item, index) => {
					if (index <= 2) {
						arr.push({
							id: item.id,
							title: item.title,
							description: item.description,
							image: item.photo,
							video: item.video,
							url: item.url
						});
					}
				});

				setLinksArr(arr);
				setLinksArrLength(arr.length);

				if (createdSpace.links && createdSpace.links.length > 0) {
					createdSpace.links.forEach((item, index) => {
						setLinkFormValues(item, index);
					});
				}
			}

			setTimeout(() => {
				createSpaceAddDetailsForm
					.validateFields()
					.then(() => {
						setFormHasError(false);
					})
					.catch((errors) => {
						setFormHasError(true);
					});
			}, 200);
		},
		[photoIsRemoved, videoIsRemoved]
	);

	/**
	 * Dropdown selected value change handler for Links Visibility
	 *
	 * @type {(function(*): Promise<void>)|*}
	 */
	const handleLinksVisibilityChange = useCallback(async (value) => {
		setSelectedLinksVisibility(value);
	}, []);

	/**
	 * Submit form button and redirect to next page
	 */
	const submitFormAndContinue = () => {
		setSaveAndGoBackTriggered(false);
		createSpaceAddDetailsForm.submit();
	};

	/**
	 * Submit form button and redirect to previous page
	 */
	const submitFormAndGoBack = () => {
		setSaveAndGoBackTriggered(true);
		createSpaceAddDetailsForm.submit();
	};

	useEffect(() => {
		if (linksArr && linksArr.length >= 3) {
			setAddLinkDisabled(true);
		}

		// linksArrLength is needed here to detect changes on linksArr
	}, [currentLocation, linksArr, linksArrLength, resetLinkPreviews]);

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo);
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				addLink();
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad, welcomeImageError]);

	return (
		<>
			<main className="dashboard-wrapper flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12 md:gap-8">
						<div className="col-span-12 md:col-span-6 flex items-center">
							<h1 className="mb-4 mt-8 header-large">Add Sections to Your Space</h1>
						</div>
						<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">
							<p className="font-['din2014Bold'] text-2xl">Step 2 of 5</p>
						</div>
						<div className="col-span-12">
							<h5 className="body-text--reg">
								{/*<a className="gradient-color-txt">Basic Spaces</a> */}
								{getSitewideLink('basic_spaces')} have standard features such as
								Memberships, Updates, Benefits, and a Community Group. You can also
								customize your Welcome Message and Links.{' '}
								{/*<a className="gradient-color-txt">Pro Spaces</a>*/}
								{getSitewideLink('pro_spaces')} have many more advanced features
								like a custom domain, full branding, Items, Leaderboards, Mods, team
								verification, and more. We also offer{' '}
								{/*<a className="gradient-color-txt">Enterprise Spaces</a>*/}
								{getSitewideLink('enterprise_spaces')} for large organizations. All
								Spaces support free and paid Memberships.
							</h5>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-8 md:grid-cols-12 mt-8">
						<div className="md:col-span-8 flex justify-between my-auto !order-last md:!order-first">
							<h5 className="header-medium">Customize Your Welcome Section</h5>
							<span className="text-xl italic leading-7">Required</span>
						</div>
						<div className="md:col-span-4 my-auto !order-first md:!order-last flex justify-center items-center">
							{/*<button type="submit" className="btn btn-lg btn-secondary">*/}
							{/*	View Guide*/}
							{/*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
							{/*</button>*/}
							{getSitewideLink(
								'view_guide_add_section_space',
								'btn btn-lg btn-secondary'
							)}
						</div>
					</div>

					<Form
						form={createSpaceAddDetailsForm}
						name="createSpaceAddDetailsForm"
						id="createSpaceAddDetailsForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						initialValues={{
							remember: true,
						  }}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocus(false);
								handleFormOnBlur();
							}, 200);
						}}
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<>
							{alert && (
								<div className="grid grid-cols-12">
									<div className="col-span-12 md:col-span-8">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								</div>
							)}
						</>
						<div className="grid grid-cols-1 md:gap-8 mt-8 mb-8 md:grid-cols-12">
							<div className="md:col-span-8 col-span-12 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Welcome Title*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
											label="Welcome Title"
											name="welcome_title"
											className="mb-0 hidden-label"
											rules={[
												{ required: true },
												{
													max: 32,
													message: 'Must not exceed 32 characters.'
												}
											]}
										>
									
											<FormFieldStacked
											formType='input'
											type="text"											
											placeholder="Up to 32 characters, including spaces."
											disabled={submitted}></FormFieldStacked>
										</Form.Item>
								</div>
								
							</div>
							
							<div className="col-span-12 md:col-span-4 !order-first my-auto md:!order-last flex items-end	">
								<p className="body-txtitalic--smallest color-reg">
									This is first section on your Space's homepage. It should be a
									short welcome or introductory title.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 md:gap-8 mb-8  md:grid-cols-12">
						<div className="md:col-span-8 my-auto !order-last md:!order-first mb-8">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Welcome Message*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
											label="Welcome Message"
											name="welcome_message"
											className="mb-0 hidden-label"
											rules={[
												{ required: true },
												{
													max: 255,
													message: 'Must not exceed 255 characters.'
												}
											]}
										>
											<FormFieldStacked
											formType='textarea'
											type="text"			
											maxLength={255}										
											placeholder="Up to 255 characters, including spaces."
											disabled={submitted}></FormFieldStacked>
										
										</Form.Item>
								</div>
								
							</div>
							
							<div className="col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Add on intro to your Space and encourage visitors to join your
									community.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 gap-8 mt-12 md:grid-cols-12">
							<div className="md:hidden">
								<p className="body-txtitalic--smallest color-reg">
									Your Welcome Image (up to 8MB) for your Space is displayed next
									to the Welcome Title and Message on your homepage.
								</p>
							</div>
							<div className="my-auto md:col-span-4">
								<div className="upload-rec">
									<UploadDragger
										passedError={welcomeImageError}
										cropperAspectRatio={4 / 3}
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={IMAGE_FILETYPES.toString()}
										uploadedFilePreview={welcomeImageBlobPreview}
										onUploadFileChange={handleWelcomeImageUploadFileChange}
										minHeight={IMAGE_LARGE_MIN_HEIGHT}
										minWidth={IMAGE_LARGE_MIN_WIDTH}
										maxHeight={IMAGE_LARGE_MAX_HEIGHT}
										maxWidth={IMAGE_LARGE_MAX_WIDTH}
										uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
										disableCropper={true}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Welcome Image*
								</p>
								<p className="text-center body-txtitalic--smallest">Up to 1920px wide. JPEG, PNG, or GIF</p>
							</div>
							<div className="md:hidden">
								<p className="mt-8 body-txtitalic--smallest color-reg">
									You can also upload an optional short Welcome Video (up to 16MB)
									to be used instead of the image.
								</p>
							</div>
							<div className="my-auto md:col-span-4">
								<div className="upload-rec">
									<UploadDragger
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={VIDEO_FILETYPES.toString()}
										uploadedFilePreview={welcomeVideoBlobPreview}
										onUploadFileChange={handleWelcomeVideoUploadFileChange}
										minHeight={VIDEO_STANDARD_MIN_HEIGHT}
										minWidth={VIDEO_STANDARD_MIN_WIDTH}
										maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
										maxWidth={VIDEO_STANDARD_MAX_WIDTH}
										uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Welcome Video
								</p>
								<p className="text-center body-txtitalic--smallest">
									1920px/1080px wide. MOV or MP4
								</p>
							</div>
							<div className="col-span-4 hidden md:block">
								<p className="body-txtitalic--smallest color-reg">
									Your Welcome Image (up to 8MB) for your Space is displayed next
									to the Welcome Title and Message on your homepage.
								</p>
								<p className="mt-8 body-txtitalic--smallest color-reg">
									You can also upload an optional short Welcome Video (up to 16MB)
									to be used instead of the image.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 md:gap-8 mt-8 md:grid-cols-12">
							<div className="col-span-12 md:col-span-8 my-auto -top-4 !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Welcome Video Youtube Hosted
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Welcome Video"
										name="welcome_youtube_video"
										className="mb-0 hidden-label"
									>
										<FormFieldStacked
											formType='input'
											type="text"											
											placeholder="Add a Youtube Video Link i.e. youtube.com/watch?v=XYZ123AB"
											disabled={submitted}
											ref={welcomeYoutubeVideoRef}></FormFieldStacked>
										
										</Form.Item>
								</div>
								
							</div>
							
							
							
							<div className="col-span-4 !order-first my-auto md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									If you want to have a longer Welcome Video, you can use a
									Youtube-hosted video instead. This requires that the video
									settings on Youtube allow embedding.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 md:gap-8 mt-8 md:grid-cols-12">
							<div className="col-span-12 md:col-span-8 my-auto !order-last md:!order-first">
								<div className="form-check flex items-center">
									<Checkbox
											checked={newsletterChecked}
											className="inline-flex form-check-input items-center mr-8 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={onChangeNewsletter}
									></Checkbox>
									<label className="form-check-label body-text--small gradient-color-txt">
										
										Collect Opt-In Emails for Newsletter Signup
									</label>
								</div>
							</div>
							<div className="col-span-4 !order-first my-auto md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									A Newsletter Signup form for opt-in email collection can be
									added and will display on your Space for Visitors who are not
									signed into an account. You will be able to export this and use
									your preferred newsletter app to send emails.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 md:gap-8 mt-8 md:grid-cols-12">
							<div className="col-span-12 md:col-span-8 !order-last md:!order-first flex items-center justify-between">
								<h3 className="header-medium">Customize Your Links Section</h3>
								<span className="body-text--reg">Optional</span>
							</div>
							<div className="col-span-4 !order-first my-auto md:!order-last flex items-center">
								<p className="body-txtitalic--smallest color-reg m-0">
									A Links Section can be used to direct people to anything you
									choose. This can be a post, a website, or any other URL. Social
									Links are already shown in a different area.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 md:gap-8 mt-8 md:grid-cols-12">
							<div className="col-span-12 md:col-span-8 my-auto !order-last md:!order-first">
								<DropdownWithItems
									title="Links Visibility"
									customButtonClass="btn-lg btn-secondary"
									dropDownClass="dropdown-bg"
									displayError={
										displayDropDownError &&
										selectedLinksVisibility === 'default'
									}
									icon="fa-light fa-eye"
									selectedValue={selectedLinksVisibility}
									listItems={LINKS_VISIBILITY_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => handleLinksVisibilityChange(value)}
								/>
							</div>
							<div className="col-span-4 !order-first my-auto md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Set the visibility of your Links Section. If you do not have any
									links added, your Links Section will not be visible, even if you
									have set it show here.
								</p>
							</div>
						</div>

						{selectedLinksVisibility === 'show' && (
							<>
								<div className="grid grid-cols-1 gap-8 md:grid-cols-12 mt-8">
									<div className="col-span-4 my-auto !order-2 md:!order-1">
										<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
											Section Title 1st Half
												
										</label>
										<div className="relative rounded-none ">
											<Form.Item
													label="Links Title"
													name="links_title_first"
													className="mb-0 hidden-label"
												>
											
												<FormFieldStacked
												formType='input'
												type="text"											
												placeholder="Featured"
												disabled={submitted}
												ref={linksTitleFirstRef}
												
												></FormFieldStacked>
											</Form.Item>
										</div>
										
									</div>
									<div className="col-span-4 my-auto !order-2 md:!order-1">
										<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
											Section Title 2nd Half
											
										</label>
										<div className="relative rounded-none ">
											<Form.Item
													label="Links Title"
													name="links_title_second"
													className="mb-0 hidden-label"
												>
												
												<FormFieldStacked
												formType='input'
												type="text"											
												placeholder="Links"
												ref={linksTitleSecondRef}
												disabled={submitted}
												></FormFieldStacked>
											</Form.Item>
										</div>
										
									</div>
									
									<div className="col-span-4 flex align-items-end my-auto !order-1 md:!order-3">
										<p className="body-txtitalic--smallest color-reg">
											Section Titles are split into 2 parts of up to 16
											characters each. The 1st part is a standard color, and
											the 2nd part is a highlight color. The default is
											“Featured Links”
										</p>
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 mt-8 gap-y-8 md:gap-y-0 md:gap-8">
									<div className="col-span-8 flex items-center justify-between !order-last md:!order-first">
										<h5 className="header-small">Add a Link</h5>
									</div>
									<div className="col-span-4 flex items-center !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											You can add up to 3 links on a Basic Space. Additional
											links can be added later if you upgrade.
										</p>
									</div>
								</div>

								{linksArr &&
									linksArr.map((data, index) => (
										<Links
											data={data}
											key={index}
											index={index}
											submitted={submitted}
											updateData={handleUpdateData}
											form={createSpaceAddDetailsForm}
											updateLinkToRemove={handleUpdateLinkToRemove}
											resetLinkPreviews={resetLinkPreviews}
										></Links>
									))}

								<div className="grid grid-cols-12 gap-8">
									<div className="col-span-12">
										{linksAlert && (
											<div className="grid grid-cols-12 gap-8">
												<div className="col-span-8">
													<Alert
														key={linksAlert.variant}
														variant={linksAlert.variant}
														className={
															'custom-alert ' +
															(linksAlert.variant === 'danger'
																? 'custom-alert-danger'
																: 'custom-alert-success')
														}
													>
														{linksAlert.message}
													</Alert>
												</div>
											</div>
										)}
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
									<div className="md:col-span-8 my-auto !order-last md:!order-first">
										<Button
											className="btn btn-secondary btn-lg"
											onClick={addLink}
											disabled={addLinkDisabled}
										>
											<i className="fa-solid fa-link"></i> Add Another Link
										</Button>
									</div>
									<div className="md:col-span-4 flex align-items-end !order-first my-auto md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											Click this button to show more Add Link fields.
										</p>
									</div>
								</div>
							</>
						)}

						<div className="grid grid-cols-1 gap-8 md:gap-8 mt-8 md:grid-cols-12">
							<div className="!mb-0 md:col-span-8">
								<Form.Item>
									<Button
										className="btn btn-lg btn-primary"
										disabled={submitted || formHasError}
										onClick={() => {
											submitFormAndContinue();
										}}
									>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										Save Sections & Continue
									</Button>
								</Form.Item>
							</div>
							<div className="md:col-span-4">
								<Button
									className="btn btn-lg btn-warning bg-none"
									disabled={submitted || formHasError}
									onClick={() => {
										submitFormAndGoBack();
									}}
								>
									Save & Go Back
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</main>
		</>
	);
};

export default memo(SpaceCreateAddDetails);
