import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSpaceInformation } from '../../../services/space';
import parse from 'html-react-parser';
import SpaceContact from '../CommonLayouts/SpaceContact/SpaceContact';
import { Button } from 'antd';
import routes from "../../../routes";
import {useLocation} from "react-router-dom";

const SpaceInformationPages = (props) => {
	const { hash } = useLocation();
	const { spaceData, isPro, activeTab } = props;
	const { authUserInfo, loading } = useSelector((state) => state.general);
	// const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [spaceInformation, setSpaceInformation] = useState();
	const [spaceFAQ, setSpaceFAQ] = useState();
	const [isInformationLoading, setInformationLoading] = useState(true);
	const [showFAQ, setShowFAQ] = useState(true);

	const fetchSpaceInformation = useCallback(
		async (activeTab) => {
			try {
				if (spaceData.id) {
					let page_title = 'About';
					if (activeTab === 'support' || activeTab === 'faq') {
						page_title = 'Get Support';
					// } else if (activeTab === 'faq') {
					// 	page_title = 'Frequently Asked Questions';
					} else if (activeTab === 'terms-services') {
						page_title = 'Terms of Service';
					} else if (activeTab === 'privacy-policy') {
						page_title = 'Privacy Policy';
					}

					// console.log(activeTab);

					const slug = (page_title + spaceData.id).toLowerCase().replace(/\s/g, '');
					const data = {
						space_id: spaceData.id,
						page_slug: slug
					};
					// console.log(data);
					const response = await getSpaceInformation(data);
					if (response && response.result) {
						//console.log(response);

						if (response.space_information) {
							setSpaceInformation(response.space_information);
							if (activeTab === 'support' || activeTab === 'faq') {
								await fetchFAQ();
							}
						}
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo]
	);

	const redirectToPages = (url) => {
		if (url.includes('http')){
			window.location.href = url;
		} else {
			window.location.href = routes.viewUserSpace() + spaceData?.username + url;
		}
	}

	const redirectToSection = (anchor) => {
		setTimeout(function () {
			const element = document.getElementById(anchor.replace('#',''));
			element?.scrollIntoView({ behavior: 'smooth' });
		}, 800);
	}

	const fetchFAQ = useCallback(
		async () => {
			try {
				if (spaceData.id) {
					const slug = ('Frequently Asked Questions' + spaceData.id).toLowerCase().replace(/\s/g, '');
					const data = {
						space_id: spaceData.id,
						page_slug: slug
					};
					// console.log(data);
					const response = await getSpaceInformation(data);
					if (response && response.result) {
						//console.log(response);

						if (response.space_information) {
							setSpaceFAQ(response.space_information);
						}
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo]
	);

	useEffect(() => {
		// console.log('agi');
		if (isInformationLoading) {
			// console.log('agi2');
			fetchSpaceInformation(activeTab);
			setInformationLoading(false);
		}
		redirectToSection(hash);
	}, [hash, isInformationLoading, spaceInformation, spaceFAQ]);

	return (
		<>
			<div id={isPro ? 'space-support' : 'space-support-basic'} >
				<div className={'header-large p-3 '}>
					{(activeTab === 'about' || activeTab === 'about#contact') && (
						<>
						<h3 className="header-large">
							<span>About <i className={'fa-solid fa-solar-system'} /> {spaceData.name}</span>
						</h3>
						
						
						</>
					)}
					{/*{activeTab === 'support' && (*/}
					{/*	<h3 className="header-large">*/}
					{/*		<span>Get Support</span>*/}
					{/*	</h3>*/}
					{/*)}*/}
					{/*{activeTab === 'faq' && (*/}
					{/*	<h3 className="header-large">*/}
					{/*		<span>Frequently Asked Questions</span>*/}
					{/*	</h3>*/}
					{/*)}*/}
					{activeTab === 'terms-services' && (
						<h3 className="header-large">
							<span><i className={'fa-solid fa-solar-system'} /> {spaceData.name} Terms of Service
							</span>
						</h3>
					)}
					{activeTab === 'privacy-policy' && (
						<h3 className="header-large">
							<span><i className={'fa-solid fa-solar-system'} /> {spaceData.name} Privacy Policy</span>
						</h3>
					)}
					{
						spaceData && spaceData.about && activeTab === 'about' && (
							<div className='text-lg mt-8'> 
								<span>{parse(spaceData.about)}</span>
							</div>
							
						)
					}
				</div>
					
				{(activeTab !== 'support' && activeTab !== 'faq') && spaceInformation && spaceInformation.page_content && (
					<div className={'mt-5 mb-5 p-3 ' + (isPro ? 'info-pages-body' : '')}>
						{/* Rich Text  */}
						{spaceInformation && parse(spaceInformation.page_content)}
					</div>
				)}

				{(activeTab === 'support' || activeTab === 'faq') && (
					<>
						<div className={'header-large p-3 '}>
							<h3 className="header-large">
								<span>Get Support</span>
							</h3>
						</div>
						<div className={'mt-5 mb-5 p-3 ' + (isPro ? 'info-pages-body' : '')}>
							{/* Rich Text  */}
							{spaceInformation && parse(spaceInformation.page_content)}
						</div>
						<div className={'p-3'}>
							<div className={'grid lg:grid-cols-3 xsm:grid-cols-1 gap-5'}>
								<div className={'text-center '+ (spaceFAQ?.page_content ? 'support-cards' : 'support-cards-disable')}
									 onClick={(event) => redirectToPages('/support#faq')}
								>
									<div className="flex flex-column items-center">
										<div>
											<i className="fa-solid fa-comments-question-check"></i>
										</div>
										<span>Frequently Asked Questions</span>
									</div>
								</div>

								<div className={'support-cards text-center'}
									 onClick={(event) => redirectToPages('/about#contacts')}
								>
									<div className="flex flex-column items-center">
										<div>
											<i className="fa-solid fa-envelope"></i>
										</div>
										<span>Contact &nbsp;
											<i className={'fa-solid fa-solar-system'} />
											&nbsp;{spaceData ? spaceData.name : ''}</span>

									</div>
								</div>
								<div className={'support-cards text-center'}
									 onClick={(event) => redirectToPages(isPro ? '/about#contacts' : 'https://help.common.space/')}
								>
									<div className="flex flex-column items-center">
										<div>
											<i className="fa-solid fa-life-ring"></i>
										</div>
										<span>Get Technical Support</span>
									</div>
								</div>
							</div>
						</div>
						{( spaceFAQ?.page_content &&
							<>
								<section id="faq">
									<>
										<div className={'header-large p-3 '}>
											<h3 className="header-large mt-5 flex items-center">
												<span>Frequently Asked Questions</span>
											</h3>
										</div>
										<div className={'mt-5 mb-5 p-3 ' + (isPro ? 'info-pages-body' : '')}>
											{/* Rich Text  */}
											{spaceFAQ && parse(spaceFAQ.page_content)}
										</div>
									</>
								</section>
							</>

						)}
							<div className={'header-large p-3'}>
								<div className={(isPro ? 'info-pages-body' : '')}>
									<h4>
										Legal
									</h4>
								</div>

								<div className={'mt-5'}>
									<Button className={'btn btn-secondary btn-md'}
											onClick={(event) => redirectToPages('/terms-services')}
									>
										View Terms of Service for &nbsp;
										<i className={'fa-solid fa-solar-system'} />
										&nbsp;{spaceData ? spaceData.name : ''}
									</Button>
									<Button className={'btn btn-secondary btn-md mt-5'}
											onClick={(event) => redirectToPages('/privacy-policy')}
									>
										View Privacy Policy for &nbsp;
										<i className={'fa-solid fa-solar-system'} />
										&nbsp;{spaceData ? spaceData.name : ''}
									</Button>
								</div>
							</div>
					</>
				)}

				{/*{activeTab === 'support' && (*/}
				{/*	<div className={'row p-3'}>*/}
				{/*		<div className={'col-span-12 flex items-center justify-between'}>*/}
				{/*			<div className={'col-4 support-cards text-center'}>*/}
				{/*				<div className="flex flex-column items-center">*/}
				{/*					<div>*/}
				{/*						<i className="fa-solid fa-comments-question-check"></i>*/}
				{/*					</div>*/}
				{/*					<span>Frequently Asked Questions</span>*/}
				{/*				</div>*/}
				{/*			</div>*/}

				{/*			<div className={'col-4 support-cards text-center'}*/}
				{/*				 onClick={(event) => getContact()}*/}
				{/*			>*/}
				{/*				<div className="flex flex-column items-center">*/}
				{/*					<div>*/}
				{/*						<i className="fa-solid fa-envelope"></i>*/}
				{/*					</div>*/}
				{/*					<span>Contact &nbsp;*/}
				{/*						<i className={'fa-solid fa-solar-system'} />*/}
				{/*						&nbsp;{spaceData ? spaceData.name : ''}</span>*/}

				{/*				</div>*/}
				{/*			</div>*/}
				{/*			<div className={'col-4 support-cards text-center'}*/}
				{/*				 onClick={(event) => getTechnicalSupport()}*/}
				{/*			>*/}
				{/*				<div className="flex flex-column items-center">*/}
				{/*					<div>*/}
				{/*						<i className="fa-solid fa-life-ring"></i>*/}
				{/*					</div>*/}
				{/*					<span>Get Technical Support</span>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*)}*/}

				{/*{activeTab === 'faq' && (*/}
				{/*	<>*/}
				{/*		<div className={'row col-span-12 mt-5 ' + (isPro ? 'info-pages-body' : '')}>*/}
				{/*			<h4>*/}
				{/*				Legal*/}
				{/*			</h4>*/}
				{/*		</div>*/}

				{/*		<div className={'row col-span-12 mt-5'}>*/}
				{/*			<Button className={'btn btn-secondary btn-md'}>*/}
				{/*				View Terms of Service for &nbsp;*/}
				{/*				<i className={'fa-solid fa-solar-system'} />*/}
				{/*				&nbsp;{spaceData ? spaceData.name : ''}*/}
				{/*			</Button>*/}
				{/*			<Button className={'btn btn-secondary btn-md mt-5'}>*/}
				{/*				View Privacy Policy for &nbsp;*/}
				{/*				<i className={'fa-solid fa-solar-system'} />*/}
				{/*				&nbsp;{spaceData ? spaceData.name : ''}*/}
				{/*			</Button>*/}
				{/*		</div>*/}
				{/*	</>*/}
				{/*)}*/}

				{/* {(activeTab === 'about' ||
					activeTab === 'about#contact' ||
					activeTab === 'terms-services' ||
					activeTab === 'privacy-policy') && (
						<section id="contacts">
							<SpaceContact space_info_slug={'about' + spaceData.id} spaceData={spaceData} />
						</section>
				)} */}
			</div>
		</>
	);
};
export default memo(SpaceInformationPages);
