import React, {memo, useCallback, useEffect, useState} from "react";
import {Button, Checkbox, DatePicker, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {checkMembershipItemStatus, createJoinMembershipItem, createPaymentMethodAndPay, createStripeItemOnetimePayment, createStripePaymentIntent} from "../../../services/payment_method";
import {getCode} from "country-list";
import {getAuthUserInfo} from "../../../states/general/actions";
import {createStripeCustomerSessionPaymentIntent, getSpaceItemData} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import routes from "../../../routes";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {MEMBERSHIP_TYPES, SELECT_PAYMENT_METHOD, SELECT_ADD_NEW_CARD} from "../../../constants/space";
import DropdownWithCards from "../../../components/commons/DropdownWithCards";
import DropdownWithCountries from "../../../components/commons/DropdownWithCountryRegion/DropdownWithCountries";
import DropdownWithRegions from "../../../components/commons/DropdownWithCountryRegion/DropdownWithRegions";
import {autoRenewParser, DateDayCondition, getButtonPaymentText, getExpirationDate, getExpirationMonthlyStart, getIncludedMonths, getMembershipItemPrice, membershipItemImage, Recurring} from "../../../components/commons/helpers/MembershipItemHelpers";
import '../../SpaceBuyMembership/SpaceBuyMembership.scss'
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import FormFieldStacked from "../../../components/commons/FormFieldStacked";

const ItemPaymentMethod = () => {
    const [itemPaymentMethodForm] = Form.useForm();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [searchParams, setSearchParams] = useSearchParams();
    const itemId = searchParams.get('id');
    // const [selectedCard, setSelectedCard] = useState('default');
    // const [isAddCard, setAddCard] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const [agreementChecked1, setAgreementChecked1] = useState(false);
    const [agreementChecked2, setAgreementChecked2] = useState(false);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [alert, setAlert] = useState(null);
    const currentLocation = useLocation();
    const [isItemLoaded, setItemLoaded] = useState(false);
    const [itemDetails, setItemDetails] = useState(null);
    const [isPaid, setIsPaid] = useState(false);
    const [loadItemOnce, setLoadItemOnce] = useState(false);
    const [expirationDate, setExpirationDate] = useState('No Expiration');
    const [expirationDateDeductOneDay, setExpirationDateDeductOneDay] = useState('');
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
    const [spaceName, setSpaceName] = useState(null);
    const [isSubscription, setSubscription] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [displayDropDownError, setDisplayDropDownError] = useState(false);
    const [addCardForm] = Form.useForm();
    const [submittedAddCardForm, setSubmittedAddCardForm] = useState(false);
    const [showAddCardForm, setShowAddCardForm] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('default');
    const [autoMonthlyPrice, setAutoMonthlyPrice] = useState([{}]);
    const [selectedMonthlyPrice, setSelectedMonthlyPrice] = useState(Recurring.autoRenewOff);
    const [isParseOnlyOnce, setParseOnlyOnce] = useState(false);
    const [hidePaymentMethod, setHidePaymentMethod] = useState(false);
    const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);

    let spaceUsername = null;
    const path = currentLocation.pathname;
    if (path && path.startsWith('/@') && path.length > 3) {
        const clean_path = path.replace('/@', '');
        const path_exp = clean_path.split('/');
        spaceUsername = path_exp[0];
    }

    const handleFormChanges = useCallback(async () => {
        await itemPaymentMethodForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleFormOnBlur = useCallback(async () => {
        await itemPaymentMethodForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const onChangeAgreement1 = (e) => {
        setAgreementChecked1(e.target.checked);
    };

    const onChangeAgreement2 = (e) => {
        setAgreementChecked2(e.target.checked);
    };

    const handleSubmit = useCallback(async (values) => {
        setSubmitted(true);

        if (!authUserInfo) {
            setSubmitted(false);
            return;
        }

        if (!spaceInfo) {
            setSubmitted(false);
            return;
        }

        if (!itemDetails) {
            setSubmitted(false);
            return;
        }

        const data = values;
        data.item_id = itemId;
        data.space_id = spaceInfo.id;
        data.user_id = authUserInfo.id;
        data.payment_type = 'free';
        data.is_renew = false;
        data.space_owner_id = spaceInfo.owner;
        data.is_subscription_enable = itemDetails.enable_expiration_and_subscription;
        // if (itemDetails.enable_expiration_and_subscription && itemDetails.first_benefit) {
        //     data.amount = parseFloat(itemDetails.first_benefit.monthly_price);
        //     data.currency = itemDetails.first_benefit.currency.toLowerCase();
        //     data.included_months = itemDetails.first_benefit.included_months;
        // } else {
        data.amount = parseFloat(itemDetails.price);
        data.currency = itemDetails.currency.toLowerCase();
        // }

        if (hidePaymentMethod) {
            const response = await createJoinMembershipItem(data);
            if (response && response.result) {
                setSubmitted(false);
                await proceedPaymentCompleted();
            } else {
                setSubmitted(false);
            }
        } else {
            try {
                setDisplayDropDownError(true);
                const response = await createStripeItemOnetimePayment(data);
                if (response && response.payout) {
                    if (response.payout.url && response.payout.status === 'open') {
                        window.location.href = response.payout.url;
                    } else if (response.payout.status === 'complete') {
                        console.log(response.payout)
                        let url = routes.spaceItemPaymentCompleted();
                        const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
                        navigate(fin_url);
                    }
                }

                setSubmitted(false);

                // if (selectedCard.value !== 'default' && selectedCard.value !== 'add') {
                //     data.item_id = itemId;
                //     data.space_id = spaceInfo.id;
                //     data.user_id = authUserInfo.id;
                //     // data.payment_type = 'free';
                //     data.is_renew = false;
                //
                //     data.space_owner_id = spaceInfo.owner;
                //     data.payment_name = 'test paymnet';
                //
                //     const response = await createStripeItemOnetimePayment(data);
                //     window.location.href = response.payout.url;
                //     setSubmitted(false);
                //
                //     // const response = await createStripeCustomerSessionPaymentIntent(data);
                //     // window.open(response.payout.url, '_blank', 'noreferrer');
                //
                //     // data.user_id = authUserInfo.id;
                //     // data.stripe_id = selectedCard.cardObject.id;
                //
                //     // window.open(response.payout.url, '_blank', 'noreferrer');
                //     // if (response && response.result) {
                //     //     setSubmitted(false);
                //     //     await proceedPaymentCompleted();
                //     // } else {
                //     //     setSubmitted(false);
                //     // }
                // } else {
                //     if (!selectedCountry || selectedCountry === '') {
                //         setSubmitted(false);
                //         return;
                //     }
                //
                //     //TODO: change package this is only a temporary fix
                //     let countryCode = getCode(selectedCountry);
                //     if (!countryCode) {
                //         if (selectedCountry === 'United States') {
                //             countryCode = getCode('United States of America');
                //         }
                //     }
                //
                //     data.country = countryCode;
                //     data.state = selectedRegion;
                //     data.expiry_mm = values.expiry.format('MM');
                //     data.expiry_yy = values.expiry.format('YYYY');
                //
                //     delete data.expiry;
                //
                //     if (authUserInfo) {
                //         setSubmittedAddCardForm(true);
                //         data.user_id = authUserInfo.id;
                //
                //         const response = await createPaymentMethodAndPay(data);
                //         if (response && response.result) {
                //             setShowAddCardForm(false);
                //             dispatch(getAuthUserInfo());
                //             setSubmitted(false);
                //             await proceedPaymentCompleted();
                //         } else {
                //             setSubmitted(false);
                //         }
                //         setSubmittedAddCardForm(false);
                //     } else {
                //         setSubmitted(false);
                //     }
                // }
            } catch (error) {
                setSubmitted(false);
                console.log(error);
            }
        }

        },
        [
            authUserInfo,
            selectedCountry,
            selectedRegion,
            // selectedCard,
            itemDetails,
            hidePaymentMethod,
            spaceInfo
        ]
    );

    const checkStatus = useCallback(async (space_id, item_id) => {
        if (!item_id) return;
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await checkMembershipItemStatus(data);
        if (response && response.result) {
            const transaction = response.stripe_transaction;
            const sessionStatus = response.session_status;
            if (transaction) {
                if (transaction.status === 'inactive') {
                    if (sessionStatus === 'complete') {
                        let url = routes.spaceItemPaymentCompleted();
                        const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + item_id;
                        navigate(fin_url);
                    }
                } else if (transaction.status === 'active' || transaction.status === 'expired') {
                    let url = routes.spaceItemBuy();
                    const fin_url =
                        url.replace(':space_username', '@' + spaceUsername) + '?id=' + item_id;
                    navigate(fin_url);
                }
            }
            setCheckStatusLoaded(true);
        }
    },[authUserInfo])

    const getItem = useCallback(async (space_id, item_id) => {
        if (!item_id) {
            return;
        }

        const data = {
            space_id: space_id,
            item_id: item_id
        }

        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }

        const response = await getSpaceItemData(data);
        if (response && response.result) {
            const itemData = response.item;
            setIsPaid(itemData.is_paid);
            setItemDetails(itemData);
            setItemLoaded(true);
            if (itemData.photo || itemData.video) {
                if (itemData.photo) {
                    const bg = {
                        backgroundImage: 'url(' + itemData.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (itemData.video) {
                    const bg = {
                        backgroundImage: 'none',
                    };
                    setCollectionAssetBGImage(bg)
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, []);

    const proceedPaymentCompleted = useCallback(async () => {
        // const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
        if (spaceUsername) {
            let url = routes.spaceItemPaymentCompleted();
            //redirect to wallet connect for item when the collection is NFT and no subscription
            // if (itemDetails.collection_type === 'membership') {
            //     if (isSubscription) {
            //         const isSubscription = itemDetails.enable_expiration_and_subscription;
            //         // if (!isSpaceMember) {
            //         if (itemDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
            //             url = routes.spaceItemWalletConnect();
            //         }
            //         // }
            //
            //     } else {
            //         if (isSpaceMember) {
            //             // Membership Ready
            //             //to
            //             //Wallet Connect
            //         } else {
            //             // EXISTING USER/NEW TO SPACE CLAIM NOW FLOW where User
            //             // must own an existing NFT to claim Membership/Item NO SUBSCRIPTION
            //             if (itemDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
            //                 url = routes.spaceItemWalletConnect();
            //             }
            //         }
            //     }
            // }
            const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
            navigate(fin_url);
        }
    }, [spaceInfo, itemDetails, isSubscription, spaceUsername, itemId]);


    useEffect(() => {
        if (itemDetails == null) {
            setItemLoaded(false);
        }
        if (authUserInfo===null) {
            dispatch(getAuthUserInfo());
        }

        if (spaceUsername && !isItemLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getItem(spaceInfo.id, itemId).then((r) => {
                    });
                    setSpaceName(spaceInfo.name);
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(spaceUsername));
                }
                if (spaceInfoLoading === 'failed') {
                    navigate(routes.createSpace());
                }
            }
        }

        if (itemDetails != null) {
            setHidePaymentMethod(!isSubscription && (itemDetails.price == null || (parseFloat(itemDetails.price) === 0)));
            if (!isParseOnlyOnce) {
                setParseOnlyOnce(true);
                setAutoMonthlyPrice(autoRenewParser(itemDetails));
            }

            setSubscription(itemDetails.enable_expiration_and_subscription);
            if (autoMonthlyPrice.length > 0 && itemDetails.enable_expiration_and_subscription && itemDetails.first_benefit) {
                const included_months = itemDetails.first_benefit.included_months;
                const date_created = itemDetails.first_benefit.created_on;
                const selectedMonths = autoMonthlyPrice.filter(monthly => monthly.value === selectedMonthlyPrice);
                let redemptionCount = included_months;
                if (selectedMonths.length > 0 ) {
                    redemptionCount = included_months + selectedMonths[0].months
                }
                setExpirationDate(getExpirationDate(date_created, included_months,DateDayCondition.default));
                setExpirationDateDeductOneDay(getExpirationDate(date_created, redemptionCount,DateDayCondition.deductOneDay));
            }

            if (authUserInfo != null && !loadItemOnce) {
                setLoadItemOnce(true);
                checkStatus(spaceInfo.id, itemId).then(r =>{});
                getItem(spaceInfo.id, itemId).then((r) => {});
            }
        }
        // setAddCard(selectedCard.value === 'add');

        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);
    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        spaceUsername,
        itemId,
        itemDetails,
        // selectedCard,
        hidePaymentMethod,
        isPaid,
        isCheckStatusLoaded,
        selectedMonthlyPrice
    ])

    useEffect(() => {},[
        expirationDateDeductOneDay,
        expirationDate,
        isSubscription,
        autoMonthlyPrice
    ]);

    return (
        <>
            <main id="membership-buy" className="flex">
                {!spaceInfo || !authUserInfo || !isCheckStatusLoaded ? (
                    <div className="loading-items flex justify-center w-full items-center mt-8">
                        <i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
                    </div>
                ) : (
                    <>
                        {/*<div className="page-left-image">*/}
                        {/*    <div*/}
                        {/*        className="asset-preview"*/}
                        {/*        style={collectionAssetBGImage}*/}
                        {/*    >*/}
                        {/*        {collectionAssetType &&*/}
                        {/*            collectionAssetType === 'video' && (*/}
                        {/*                <video*/}
                        {/*                    autoPlay*/}
                        {/*                    muted*/}
                        {/*                    loop*/}
                        {/*                    className="membership-video"*/}
                        {/*                >*/}
                        {/*                    <source*/}
                        {/*                        src={itemDetails.video}*/}
                        {/*                        type="video/mp4"*/}
                        {/*                    />*/}
                        {/*                </video>*/}
                        {/*            )}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
                            <div className="grid margin-right-0">
                                <div className="grid grid-cols-2 gap-y-8 gap-y-0 md:gap-8 pr-0">
                                    <div className="col-span-2 grid grid-cols-2 ps-0 gap-y-8 md:gap-y-0 md:gap-8">
                                        <div className="col-span-2">
                                            <h1 className="mb-4 mt-5 header-large">
                                                {itemDetails != null && itemDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (
                                                    <>
                                                        Wallet Connected! <br/>
                                                        Review Item Details.
                                                    </>
                                                ) : (
                                                    <>
                                                        Review Item Details & <br/>
                                                        { hidePaymentMethod ?'& join the community.':' Select Payment Method' }
                                                    </>
                                                )}
                                            </h1>
                                            <p className="body-text--reg">
                                                To { hidePaymentMethod ?'get':'purchase' } this item and join the community,
                                                complete the following information:
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-2 grid lg:grid-cols-2 md:grid-cols-1 gap-y-8 md:gap-y-0 md:gap-8">

                                        <div className="lg:hidden md:flex">
                                            {membershipItemImage(collectionAssetType, itemDetails)}
                                        </div>

                                        <div className="lg:col-span-1">
                                            <h3 className="mb-4 mt-5 header-medium">
                                                {itemDetails ? itemDetails.name : ''}
                                            </h3>
                                            <p className="body-text--reg">
                                                Your item for {' '}
                                                <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                </a>
                                                {' '}includes access, updates, benefits, and more.
                                                Items can be a one-time or monthly renewable cost.
                                                Review and confirm your Item details below.
                                            </p>
                                            <Form
                                                form={itemPaymentMethodForm}
                                                name="itemPaymentMethod"
                                                id="itemPaymentMethod"
                                                validateMessages={VALIDATE_MESSAGES}
                                                onFocus={() => {
                                                    setFormIsOnFocus(true);
                                                    setAlert(null);
                                                }}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        setFormIsOnFocus(false);
                                                        handleFormOnBlur();
                                                    }, 200);
                                                }}
                                                autoComplete="off"
                                                onChange={handleFormChanges}
                                                onFinish={handleSubmit}
                                                className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
                                            >

                                                <div className="grid grid-cols-12 mt-5">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            Membership Price
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        {itemDetails != null &&
                                                            <p className="m-0 body-text--small gradient-color-txt text-center">
                                                                {getMembershipItemPrice(isSubscription, itemDetails.type, itemDetails)}
                                                            </p>
                                                        }
                                                    </div>
                                                </div>

                                                { itemDetails != null && (
                                                    <>
                                                        { isSubscription &&
                                                            <div className="grid grid-cols-12 mt-5">
                                                                <div className="col-span-4">
                                                                    <p className="m-0 body-text--small gradient-color-txt">
                                                                        Included Months
                                                                    </p>
                                                                </div>
                                                                <div className="col-span-8">
                                                                    <p className="m-0 body-text--small gradient-color-txt text-center">
                                                                        {getIncludedMonths(itemDetails.first_benefit.included_months)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }

                                                        {expirationDateDeductOneDay &&
                                                            <div className="grid grid-cols-12 mt-5">
                                                                <div className="col-span-4">
                                                                    <p className="m-0 body-text--small gradient-color-txt">
                                                                        Expiration Date
                                                                    </p>
                                                                </div>
                                                                <div className="col-span-8">
                                                                    <p className="m-0 body-text--small gradient-color-txt text-center">
                                                                        {expirationDateDeductOneDay}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }

                                                        { isSubscription &&
                                                            <div className="grid grid-cols-12 mt-5 flex items-center">
                                                                <div className="col-span-4">
                                                                    <p className="m-0 body-text--small gradient-color-txt">
                                                                        Renewal
                                                                    </p>
                                                                </div>
                                                                <div className="col-span-8">
                                                                    <div className="input-group-com">
                                                                        <DropdownWithItems
                                                                            dropDownClass="w-full"
                                                                            customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                            title="Credit Card"
                                                                            selectedValue={selectedMonthlyPrice}
                                                                            listItems={autoMonthlyPrice}
                                                                            onDropdownChange={(value) =>
                                                                                setSelectedMonthlyPrice(value)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                )}

                                                {/*If not subscription and price is zero or free*/}
                                                {/*{ !hidePaymentMethod &&*/}
                                                {/*    <>*/}
                                                {/*        <h3 className="mb-4 mt-5 header-medium">*/}
                                                {/*            Payment Method*/}
                                                {/*        </h3>*/}
                                                {/*        <p className="body-text--reg">*/}
                                                {/*            All major debit cards, credit cards, Apple Pay, Google Pay,*/}
                                                {/*            and wallets are accepted. Your full card number is never*/}
                                                {/*            accessible and only the last 4 digits are used for identification.{' '}*/}
                                                {/*            /!*<a href="" className="gradient-color-txt">*!/*/}
                                                {/*            /!*    All payments are processed by Stripe using AES-256 Encryption for your*!/*/}
                                                {/*            /!*    security.*!/*/}
                                                {/*            /!*</a>*!/*/}
                                                {/*            {getSitewideLink('all_payments_stripe')}.*/}
                                                {/*        </p>*/}
                                                {/*        <div className="grid grid-cols-12 mt-5 glex items-center">*/}
                                                {/*            <div className="col-span-4">*/}
                                                {/*                <p className="m-0 body-text--small gradient-color-txt">*/}
                                                {/*                    Payment Method*/}
                                                {/*                </p>*/}
                                                {/*            </div>*/}
                                                {/*            <div className="col-span-8">*/}
                                                {/*                <div className="">*/}
                                                {/*                    <DropdownWithItems*/}
                                                {/*                        dropDownClass="w-full"*/}
                                                {/*                        customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"*/}
                                                {/*                        selectedValue={paymentMethod}*/}
                                                {/*                        listItems={SELECT_PAYMENT_METHOD}*/}
                                                {/*                        onDropdownChange={(value) =>*/}
                                                {/*                            setPaymentMethod(value)*/}
                                                {/*                        }*/}
                                                {/*                    />*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*        {paymentMethod === 'card' && (*/}
                                                {/*            <>*/}
                                                {/*                <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                    <div className="col-span-4">*/}
                                                {/*                        <p className="m-0 body-text--small gradient-color-txt">*/}
                                                {/*                            Select Card*/}
                                                {/*                        </p>*/}
                                                {/*                    </div>*/}
                                                {/*                    <div className="col-span-8">*/}
                                                {/*                        <div className="">*/}
                                                {/*                            <DropdownWithCards*/}
                                                {/*                                dropDownClass="w-full"*/}
                                                {/*                                customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"*/}
                                                {/*                                selectedValue={selectedCard}*/}
                                                {/*                                listItems={authUserInfo.payment_methods}*/}
                                                {/*                                defaultOption={SELECT_ADD_NEW_CARD}*/}
                                                {/*                                onDropdownChange={(value) =>*/}
                                                {/*                                    setSelectedCard(value)*/}
                                                {/*                                }*/}
                                                {/*                            />*/}
                                                {/*                        </div>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}

                                                {/*                /!* Show this form when adding a new card *!/*/}
                                                {/*                /!* TODO-MJ: Create a component for the add card form *!/*/}
                                                {/*                {isAddCard &&*/}
                                                {/*                    <>*/}
                                                {/*                        <div*/}
                                                {/*                            className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Card Number</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div*/}
                                                {/*                                className="col-span-8 relative rounded-none ">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label="Card Number"*/}
                                                {/*                                    name="card_number"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                   <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='15 or 16 digits'*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}


                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Expiry & CVV/CVC</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div*/}
                                                {/*                                className="col-span-8 grid grid-cols-8 gap-4">*/}
                                                {/*                                <div*/}
                                                {/*                                    className="col-span-4 relative rounded-none ">*/}
                                                {/*                                    <Form.Item*/}
                                                {/*                                        label='Expiry'*/}
                                                {/*                                        name="expiry"*/}
                                                {/*                                        className="mb-0 hidden-label"*/}
                                                {/*                                        validateTrigger="onBlur"*/}
                                                {/*                                        rules={[*/}
                                                {/*                                            {*/}
                                                {/*                                                type: 'object',*/}
                                                {/*                                                required: true*/}
                                                {/*                                            }*/}
                                                {/*                                        ]}*/}
                                                {/*                                    >*/}
                                                {/*                                        <DatePicker*/}
                                                {/*                                            className="body-text--small !bg-[#ffffff80] !text-lg h-[40px] w-full"*/}
                                                {/*                                            picker="month"*/}
                                                {/*                                            format="MM/YY"*/}
                                                {/*                                            placeholder="MM/YY"*/}
                                                {/*                                            disabled={submittedAddCardForm}*/}
                                                {/*                                        />*/}
                                                {/*                                    </Form.Item>*/}
                                                {/*                                </div>*/}
                                                {/*                                <div*/}
                                                {/*                                    className="col-span-4 relative rounded-none">*/}
                                                {/*                                    <Form.Item*/}
                                                {/*                                        label='CVV/CVC'*/}
                                                {/*                                        name="cvc"*/}
                                                {/*                                        className="mb-0 hidden-label"*/}
                                                {/*                                        validateTrigger="onBlur"*/}
                                                {/*                                        rules={[{required: true}]}*/}
                                                {/*                                    >*/}
                                                {/*                                        <FormFieldStacked*/}
                                                {/*                                        formType='input'*/}
                                                {/*                                        type="number"*/}
                                                {/*                                        disabled={submittedAddCardForm}*/}
                                                {/*                                        placeholder='3 or 4 digits'>											*/}
                                                {/*                                        </FormFieldStacked>*/}
                                                {/*                                    </Form.Item>*/}
                                                {/*                                </div>*/}

                                                {/*                            </div>*/}
                                                {/*                        </div>*/}


                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Full Name</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='Full Name'*/}
                                                {/*                                    name="full_name"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                   <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='As shown on credit card'*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Country</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                            <DropdownWithCountries*/}
                                                {/*                                title="Country"*/}
                                                {/*                                displayError={*/}
                                                {/*                                    displayDropDownError &&*/}
                                                {/*                                    (!selectedCountry || selectedCountry === '')*/}
                                                {/*                                }*/}
                                                {/*                                value={selectedCountry}*/}
                                                {/*                                onDropdownChange={setSelectedCountry}*/}
                                                {/*                            ></DropdownWithCountries>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Street Address</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='Street Address'*/}
                                                {/*                                    name="street_address"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                formType='input'*/}
                                                {/*                                type="text"	*/}
                                                {/*                                disabled={submittedAddCardForm}*/}
                                                {/*                                placeholder='Number and Street'*/}
                                                {/*                                ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">APT/STE/BLDG</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='Address'*/}
                                                {/*                                    name="address"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    // rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='OPTIONAL'*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">City</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='City'*/}
                                                {/*                                    name="city"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        */}
                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">State & ZIP</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 grid grid-cols-8 gap-4">*/}
                                                {/*                                <div className="col-span-4 relative rounded-none">*/}
                                                {/*                                <DropdownWithRegions*/}
                                                {/*                                    title="State"*/}
                                                {/*                                    displayError={*/}
                                                {/*                                        displayDropDownError &&*/}
                                                {/*                                        (!selectedRegion || selectedRegion === '')*/}
                                                {/*                                    }*/}
                                                {/*                                    country={selectedCountry}*/}
                                                {/*                                    value={selectedRegion}*/}
                                                {/*                                    onDropdownChange={setSelectedRegion}*/}
                                                {/*                                ></DropdownWithRegions>*/}
                                                {/*                                </div>*/}

                                                {/*                                <div className="col-span-4 relative rounded-none pt-2">*/}
                                                {/*                                    <Form.Item*/}
                                                {/*                                        label='Zip'*/}
                                                {/*                                        name="zip"*/}
                                                {/*                                        className="mb-0 hidden-label"*/}
                                                {/*                                        validateTrigger="onBlur"*/}
                                                {/*                                        rules={[{required: true}]}*/}
                                                {/*                                    >*/}
                                                {/*                                        <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="number"*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='5 digits'>											*/}
                                                {/*                                    </FormFieldStacked>*/}
                                                {/*                                    </Form.Item>*/}
                                                {/*                                </div>*/}
                                                {/*                            </div>*/}

                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Phone Number</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='Phone Number'*/}
                                                {/*                                    name="phone_number"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='10 digits'*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-8 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <p className="m-0 body-text--small gradient-color-txt">*/}
                                                {/*                                    Promo Code*/}
                                                {/*                                </p>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    name="promo_code"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    // rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}

                                                {/*                            </div>*/}
                                                {/*                        </div>*/}
                                                {/*                    </>*/}
                                                {/*                }*/}
                                                {/*            </>*/}
                                                {/*        )}*/}
                                                {/*    </>*/}
                                                {/*}*/}

                                                <h3 className="mb-4 mt-5 header-medium">
                                                    Review & Confirm
                                                </h3>

                                                <p className="body-text--reg text-center">
                                                    By clicking “Purchase Item” below, you are authorizing
                                                    {' '}
                                                    <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                        <br/><i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                    </a>{' '}
                                                    to charge your Payment Method for:
                                                </p>

                                                {!isSubscription && itemDetails != null &&
                                                    <p className="body-text--big">
                                                        ${(itemDetails.price != null ? parseFloat(itemDetails.price).toFixed(2) : 0) + ' ' + (itemDetails.currency != null ? itemDetails.currency : 'USD') } total
                                                    </p>
                                                }

                                                {isSubscription && itemDetails != null &&
                                                    <>
                                                        <p className="body-text--big">
                                                            ${(itemDetails.price != null ? parseFloat(itemDetails.price) : 0).toFixed(2) + ' ' + (itemDetails.currency != null ? itemDetails.currency : 'USD') } total
                                                            + <br/>
                                                        </p>
                                                        <p className="body-text--big">
                                                            {getExpirationMonthlyStart(selectedMonthlyPrice, autoMonthlyPrice, itemDetails.first_benefit.currency, expirationDate, itemDetails.first_benefit.included_months)}
                                                        </p>
                                                    </>
                                                }


                                                <div className="form-check flex items-center mt-3">
                                                    <label
                                                        className="form-check-label flex items-center">
                                                             <Checkbox
                                                                checked={agreementChecked1}
                                                                className="inline-flex  items-center mr-8 scale-150 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                onChange={onChangeAgreement1}
                                                            ></Checkbox>
                                                        <label className="form-check-label body-text--small">
                                                           
                                                            <p className="body-txtitalic--small m-0">
                                                                By joining
                                                                {' '}<i className="fa-light fa-solar-system"></i>
                                                                {' '}{spaceInfo ? spaceInfo.name : ''}
                                                                {' '}, you agree to their {' '}
                                                                <a className="gradient-color-txt"
                                                                   href={routes.viewUserSpace() + spaceInfo?.username + '/terms-services'}
                                                                   target="_blank"
                                                                >
                                                                    Terms of Service
                                                                </a>
                                                                {' '}and{' '}
                                                                <a className="gradient-color-txt"
                                                                   href={routes.viewUserSpace() + spaceInfo?.username + '/privacy-policy'}
                                                                   target="_blank"
                                                                >
                                                                    Privacy Policy
                                                                </a>
                                                            </p>
                                                        </label>
                                                    </label>
                                                </div>
                                                <div className="form-check mt-3">
                                                    <div  className="form-check-label flex items-center">
                                                            <Checkbox
                                                                checked={agreementChecked2}
                                                                className="inline-flex scale-150 items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                onChange={onChangeAgreement2}
                                                            ></Checkbox>
                                                        <label className="form-check-label body-text--small">
                                                         
                                                            <p className="body-txtitalic--small m-0">
                                                                By continuing, you agree to the Commonspace
                                                                {' '}
                                                                {getSitewideLink('terms_of_service_space_create')}
                                                                {' '},
                                                                {getSitewideLink('privacy_policy_space_create')}
                                                                ,{' '}and{' '}
                                                                {getSitewideLink('community_guidelines_space_create')}
                                                            </p>
                                                        </label>
                                                        </div>
                                                </div>
                                                {hidePaymentMethod && itemDetails != null &&
                                                    <div className="input-group-com mt-5 mb-8">
                                                        <Form.Item className="mb-0">
                                                            <Button
                                                                className="btn btn-primary btn-lg"
                                                                htmlType="submit"
                                                                disabled={!agreementChecked1 || !agreementChecked2}
                                                            >
                                                                {submitted && (
                                                                    <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                )}
                                                                {getButtonPaymentText(isSubscription, itemDetails.type, selectedMonthlyPrice)}
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                }

                                                {!hidePaymentMethod && itemDetails != null &&
                                                    <div className="input-group-com mt-5 mb-14">
                                                        <Form.Item className="mb-0">
                                                            <Button
                                                                className="btn btn-primary btn-lg"
                                                                htmlType="submit"
                                                                disabled={
                                                                    // !isAddCard && (
                                                                    //     selectedCard.value === 'default' ||
                                                                    //     selectedCard.value === 'add' ||
                                                                    //     submitted ||
                                                                    //     !agreementChecked1 ||
                                                                    //     !agreementChecked2) ||
                                                                    // isAddCard && (
                                                                        submitted ||
                                                                        formHasError ||
                                                                        !agreementChecked1 ||
                                                                        !agreementChecked2
                                                                    // )
                                                                }
                                                            >
                                                                {submitted && (
                                                                    <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                )}
                                                                {getButtonPaymentText(isSubscription, itemDetails.type, selectedMonthlyPrice)}
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                }

                                            </Form>
                                        </div>
                                        <div className="lg:inline xsm:hidden p-0">
                                            {membershipItemImage(collectionAssetType, itemDetails)}
                                        </div>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    {/*    <div className="page-left-image">*/}
                    {/*    <div*/}
                    {/*        className="asset-preview"*/}
                    {/*        style={collectionAssetBGImage}*/}
                    {/*    >*/}
                    {/*        {collectionAssetType &&*/}
                    {/*            collectionAssetType === 'video' && (*/}
                    {/*                <video*/}
                    {/*                    autoPlay*/}
                    {/*                    muted*/}
                    {/*                    loop*/}
                    {/*                    className="membership-video"*/}
                    {/*                >*/}
                    {/*                    <source*/}
                    {/*                        src={itemDetails.video}*/}
                    {/*                        type="video/mp4"*/}
                    {/*                    />*/}
                    {/*                </video>*/}
                    {/*            )}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                        {/*<div className="container">*/}
                        {/*    <div className="row justify-content-start">*/}
                        {/*        <>*/}
                        {/*            <div className="col-6">*/}
                        {/*                <h1 className="mb-4 mt-5 header-large">*/}
                        {/*                    {itemDetails != null && itemDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (*/}
                        {/*                        <>*/}
                        {/*                            Wallet Connected! <br/>*/}
                        {/*                            Review Item Details.*/}
                        {/*                        </>*/}
                        {/*                    ) : (*/}
                        {/*                        <>*/}
                        {/*                            Review Item Details & <br/>*/}
                        {/*                            { hidePaymentMethod ?'& join the community.':' Select Payment Method' }*/}
                        {/*                        </>*/}
                        {/*                    )}*/}
                        {/*                </h1>*/}
                        {/*                <p className="body-text--reg">*/}
                        {/*                    To { hidePaymentMethod ?'get':'purchase' } this item and join the community,*/}
                        {/*                    complete the following information:*/}
                        {/*                </p>*/}
                        {/*                <h3 className="mb-4 mt-5 header-medium">*/}
                        {/*                    {itemDetails ? itemDetails.name : ''}*/}
                        {/*                </h3>*/}
                        {/*                <p className="body-text--reg">*/}
                        {/*                    Your item for {' '}*/}
                        {/*                    <a href="" className="gradient-color-txt">*/}
                        {/*                        <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}*/}
                        {/*                    </a>*/}
                        {/*                    {' '}includes access, updates, benefits, and more.*/}
                        {/*                    Items can be a one-time or monthly renewable cost.*/}
                        {/*                    Review and confirm your Item details below.*/}
                        {/*                </p>*/}
                        {/*                <Form*/}
                        {/*                    form={itemPaymentMethodForm}*/}
                        {/*                    name="itemPaymentMethod"*/}
                        {/*                    id="itemPaymentMethod"*/}
                        {/*                    validateMessages={VALIDATE_MESSAGES}*/}
                        {/*                    onFocus={() => {*/}
                        {/*                        setFormIsOnFocus(true);*/}
                        {/*                        setAlert(null);*/}
                        {/*                    }}*/}
                        {/*                    onBlur={() => {*/}
                        {/*                        setTimeout(() => {*/}
                        {/*                            setFormIsOnFocus(false);*/}
                        {/*                            handleFormOnBlur();*/}
                        {/*                        }, 200);*/}
                        {/*                    }}*/}
                        {/*                    autoComplete="off"*/}
                        {/*                    onChange={handleFormChanges}*/}
                        {/*                    onFinish={handleSubmit}*/}
                        {/*                    className={formIsOnFocus ? 'hide-antd-error-messages' : ''}*/}
                        {/*                >*/}

                        {/*                    <div className="row my-5 flex items-center">*/}
                        {/*                        <div className="col-4">*/}
                        {/*                            <p className="m-0 body-text--small gradient-color-txt">*/}
                        {/*                                Membership Price*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="col-8">*/}
                        {/*                            {itemDetails != null &&*/}
                        {/*                                <p className="m-0 body-text--small gradient-color-txt text-center">*/}
                        {/*                                    {getMembershipItemPrice(isSubscription, itemDetails.type, itemDetails)}*/}
                        {/*                                </p>*/}
                        {/*                            }*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    { itemDetails != null && (*/}
                        {/*                        <>*/}
                        {/*                            { isSubscription &&*/}
                        {/*                                <div className="row my-5 flex items-center">*/}
                        {/*                                    <div className="col-4">*/}
                        {/*                                        <p className="m-0 body-text--small gradient-color-txt">*/}
                        {/*                                            Included Months*/}
                        {/*                                        </p>*/}
                        {/*                                    </div>*/}
                        {/*                                    <div className="col-8">*/}
                        {/*                                        <p className="m-0 body-text--small gradient-color-txt text-center">*/}
                        {/*                                            {getIncludedMonths(itemDetails.first_benefit.included_months)}*/}
                        {/*                                        </p>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            }*/}

                        {/*                            <div className="row my-5 flex items-center">*/}
                        {/*                                <div className="col-4">*/}
                        {/*                                    <p className="m-0 body-text--small gradient-color-txt">*/}
                        {/*                                        Expiration Date*/}
                        {/*                                    </p>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="col-8">*/}
                        {/*                                    <p className="m-0 body-text--small gradient-color-txt text-center">*/}
                        {/*                                        {expirationDate}*/}
                        {/*                                    </p>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}

                        {/*                            { isSubscription &&*/}
                        {/*                                <div className="row my-5 flex items-center">*/}
                        {/*                                    <div className="col-4">*/}
                        {/*                                        <p className="m-0 body-text--small gradient-color-txt">*/}
                        {/*                                            Auto-Renew*/}
                        {/*                                        </p>*/}
                        {/*                                    </div>*/}
                        {/*                                    <div className="col-8">*/}
                        {/*                                        <div className="input-group-com">*/}
                        {/*                                            <DropdownWithItems*/}
                        {/*                                                dropDownClass="w-full"*/}
                        {/*                                                customButtonClass="btn-lg btn-secondary dropdown-primary"*/}
                        {/*                                                title="Credit Card"*/}
                        {/*                                                selectedValue={selectedMonthlyPrice}*/}
                        {/*                                                listItems={autoMonthlyPrice}*/}
                        {/*                                                onDropdownChange={(value) =>*/}
                        {/*                                                    setSelectedMonthlyPrice(value)*/}
                        {/*                                                }*/}
                        {/*                                            />*/}
                        {/*                                        </div>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            }*/}
                        {/*                        </>*/}
                        {/*                    )}*/}

                        {/*                    /!*If not subscription and price is zero or free*!/*/}
                        {/*                    { !hidePaymentMethod &&*/}
                        {/*                        <>*/}
                        {/*                            <h3 className="mb-4 mt-5 header-medium">*/}
                        {/*                                Payment Method*/}
                        {/*                            </h3>*/}
                        {/*                            <p className="body-text--reg">*/}
                        {/*                                All major debit cards, credit cards, Apple Pay, Google Pay,*/}
                        {/*                                and wallets are accepted. Your full card number is never*/}
                        {/*                                accessible and only the last 4 digits are used for identification.{' '}*/}
                        {/*                                <a href="" className="gradient-color-txt">*/}
                        {/*                                    All payments are processed by Stripe using AES-256 Encryption for your*/}
                        {/*                                    security.*/}
                        {/*                                </a>*/}
                        {/*                            </p>*/}
                        {/*                            <div className="row my-5 flex items-center">*/}
                        {/*                                <div className="col-4">*/}
                        {/*                                    <p className="m-0 body-text--small gradient-color-txt">*/}
                        {/*                                        Payment Method*/}
                        {/*                                    </p>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="col-8">*/}
                        {/*                                    <div className="input-group-com">*/}
                        {/*                                        <DropdownWithItems*/}
                        {/*                                            dropDownClass="w-full"*/}
                        {/*                                            customButtonClass="btn-lg btn-secondary dropdown-primary"*/}
                        {/*                                            selectedValue={paymentMethod}*/}
                        {/*                                            listItems={SELECT_PAYMENT_METHOD}*/}
                        {/*                                            onDropdownChange={(value) =>*/}
                        {/*                                                setPaymentMethod(value)*/}
                        {/*                                            }*/}
                        {/*                                        />*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                            {paymentMethod === 'card' && (*/}
                        {/*                                <>*/}
                        {/*                                    <div className="row my-5 flex items-center">*/}
                        {/*                                        <div className="col-4">*/}
                        {/*                                            <p className="m-0 body-text--small gradient-color-txt">*/}
                        {/*                                                Select Card*/}
                        {/*                                            </p>*/}
                        {/*                                        </div>*/}
                        {/*                                        <div className="col-8">*/}
                        {/*                                            <div className="input-group-com">*/}
                        {/*                                                <DropdownWithCards*/}
                        {/*                                                    dropDownClass="w-full"*/}
                        {/*                                                    customButtonClass="btn-lg btn-secondary dropdown-primary"*/}
                        {/*                                                    selectedValue={selectedCard.value}*/}
                        {/*                                                    listItems={authUserInfo}*/}
                        {/*                                                    icon={'card-ico fa fa-brands fa-cc-visa'}*/}
                        {/*                                                    onDropdownChange={(value) =>*/}
                        {/*                                                        setSelectedCard(value)*/}
                        {/*                                                    }*/}
                        {/*                                                />*/}
                        {/*                                            </div>*/}
                        {/*                                        </div>*/}
                        {/*                                    </div>*/}

                        {/*                                    /!* Show this form when adding a new card *!/*/}
                        {/*                                    /!* TODO-MJ: Create a component for the add card form *!/*/}
                        {/*                                    {isAddCard &&*/}
                        {/*                                        <>*/}
                        {/*                                            <div*/}
                        {/*                                                className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">Card Number</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div*/}
                        {/*                                                    className="col-8 input-group-com input-border-bottom ">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label="Card Number"*/}
                        {/*                                                        name="card_number"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="input-lg body-text--small text-center"*/}
                        {/*                                                            placeholder="15 or 16 digits "*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}


                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">Expiry & CVV/CVC</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div*/}
                        {/*                                                    className="col-4 input-group-com input-border-bottom max-width">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label='Expiry'*/}
                        {/*                                                        name="expiry"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        rules={[*/}
                        {/*                                                            {*/}
                        {/*                                                                type: 'object',*/}
                        {/*                                                                required: true*/}
                        {/*                                                            }*/}
                        {/*                                                        ]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <DatePicker*/}
                        {/*                                                            // onFocus={hideTooltip}*/}
                        {/*                                                            // onBlur={showTooltip}*/}
                        {/*                                                            className="input-lg input-birthdate body-text--small"*/}
                        {/*                                                            picker="month"*/}
                        {/*                                                            format="MM/YY"*/}
                        {/*                                                            placeholder=""*/}
                        {/*                                                            // disabled={submittedAddCardForm}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div*/}
                        {/*                                                    className="col-4 input-group-com input-border-bottom max-width">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label='CVV/CVC'*/}
                        {/*                                                        name="cvc"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="input-lg text-center"*/}
                        {/*                                                            placeholder="3 or 4 digits"*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}


                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">Full Name</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div className="col-8 input-group-com input-border-bottom">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label='Full Name'*/}
                        {/*                                                        name="full_name"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="input-lg text-center"*/}
                        {/*                                                            placeholder="As shown on credit card"*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}

                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">Country</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div className="col-8 input-group-com input-border-bottom">*/}
                        {/*                                                    <DropdownWithCountries*/}
                        {/*                                                        title="Country"*/}
                        {/*                                                        displayError={*/}
                        {/*                                                            displayDropDownError &&*/}
                        {/*                                                            (!selectedCountry || selectedCountry === '')*/}
                        {/*                                                        }*/}
                        {/*                                                        value={selectedCountry}*/}
                        {/*                                                        onDropdownChange={setSelectedCountry}*/}
                        {/*                                                    />*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}

                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">Street Address</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div className="col-8 input-group-com input-border-bottom">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label='Street Address'*/}
                        {/*                                                        name="street_address"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="input-lg text-center"*/}
                        {/*                                                            placeholder="Number and Street"*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}

                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">APT/STE/BLDG</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div className="col-8 input-group-com input-border-bottom">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label='Address'*/}
                        {/*                                                        name="address"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        // rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="input-lg text-center"*/}
                        {/*                                                            placeholder="Optional"*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}

                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">City</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div className="col-8 input-group-com input-border-bottom">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label='City'*/}
                        {/*                                                        name="city"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="input-lg text-center"*/}
                        {/*                                                            placeholder=""*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}


                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">State & ZIP</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div*/}
                        {/*                                                    className="col-4 input-group-com input-border-bottom max-width">*/}
                        {/*                                                    <DropdownWithRegions*/}
                        {/*                                                        title="State"*/}
                        {/*                                                        displayError={*/}
                        {/*                                                            displayDropDownError &&*/}
                        {/*                                                            (!selectedRegion || selectedRegion === '')*/}
                        {/*                                                        }*/}
                        {/*                                                        country={selectedCountry}*/}
                        {/*                                                        value={selectedRegion}*/}
                        {/*                                                        onDropdownChange={setSelectedRegion}*/}
                        {/*                                                    ></DropdownWithRegions>*/}
                        {/*                                                </div>*/}

                        {/*                                                <div*/}
                        {/*                                                    className="col-4 input-group-com input-border-bottom max-width">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label='Zip'*/}
                        {/*                                                        name="zip"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="input-lg text-center"*/}
                        {/*                                                            placeholder="5 digit ZIP"*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}

                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <label className="body-text--small">Phone Number</label>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div className="col-8 input-group-com input-border-bottom">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        label='Phone Number'*/}
                        {/*                                                        name="phone_number"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="input-lg text-center"*/}
                        {/*                                                            placeholder="10 digits"*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}

                        {/*                                            <div className="row my-5 flex items-center">*/}
                        {/*                                                <div className="col-4">*/}
                        {/*                                                    <p className="m-0 body-text--small gradient-color-txt">*/}
                        {/*                                                        Promo Code*/}
                        {/*                                                    </p>*/}
                        {/*                                                </div>*/}
                        {/*                                                <div className="col-8 input-group-com">*/}
                        {/*                                                    <Form.Item*/}
                        {/*                                                        name="promo_code"*/}
                        {/*                                                        className="mb-0 hidden-label"*/}
                        {/*                                                        validateTrigger="onBlur"*/}
                        {/*                                                        // rules={[{required: true}]}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Input*/}
                        {/*                                                            className="btn-lg btn-secondary dropdown-primary text-center"*/}
                        {/*                                                            placeholder="Optional"*/}
                        {/*                                                            // disabled={submitted}*/}
                        {/*                                                        />*/}
                        {/*                                                    </Form.Item>*/}

                        {/*                                                </div>*/}
                        {/*                                            </div>*/}
                        {/*                                        </>*/}
                        {/*                                    }*/}
                        {/*                                </>*/}
                        {/*                            )}*/}
                        {/*                        </>*/}
                        {/*                    }*/}

                        {/*                    <h3 className="mb-4 mt-5 header-medium">*/}
                        {/*                        Review & Confirm*/}
                        {/*                    </h3>*/}

                        {/*                    <p className="body-text--reg">*/}
                        {/*                        By clicking “Purchase Item” below, you are authorizing*/}
                        {/*                        {' '}*/}
                        {/*                        <a href="" className="gradient-color-txt">*/}
                        {/*                            <br/><i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}*/}
                        {/*                        </a>{' '}*/}
                        {/*                        to charge your Payment Method for:*/}
                        {/*                    </p>*/}

                        {/*                    {!isSubscription && itemDetails != null &&*/}
                        {/*                        <h3>*/}
                        {/*                            ${(itemDetails.price != null ? parseFloat(itemDetails.price) : 0) + ' ' + (itemDetails.currency != null ? itemDetails.currency : 'USD') } total*/}
                        {/*                        </h3>*/}
                        {/*                    }*/}

                        {/*                    {isSubscription && itemDetails != null &&*/}
                        {/*                        <>*/}
                        {/*                            <h3>*/}
                        {/*                                ${(itemDetails.price != null ? parseFloat(itemDetails.price) : 0) + ' ' + (itemDetails.currency != null ? itemDetails.currency : 'USD') } total*/}
                        {/*                                + <br/>*/}
                        {/*                            </h3>*/}
                        {/*                            <h5>*/}
                        {/*                                {getExpirationMonthlyStart(selectedMonthlyPrice, autoMonthlyPrice, itemDetails.first_benefit.currency, expirationDateDeductOneDay)}*/}
                        {/*                            </h5>*/}
                        {/*                        </>*/}
                        {/*                    }*/}


                        {/*                    <div className="form-check">*/}
                        {/*                        <label*/}
                        {/*                            className="form-check-label flex items-center justify-center">*/}
                        {/*                            <label className="form-check-label body-text--small">*/}
                        {/*                                <Checkbox*/}
                        {/*                                    checked={agreementChecked1}*/}
                        {/*                                    className="form-check-input"*/}
                        {/*                                    onChange={onChangeAgreement1}*/}
                        {/*                                ></Checkbox>*/}
                        {/*                                <p className="body-txtitalic--small m-0">*/}
                        {/*                                    By joining {' '}{spaceInfo ? spaceInfo.name : ''}*/}
                        {/*                                    {' '}, you agree to their {' '}*/}
                        {/*                                    <a className="gradient-color-txt">*/}
                        {/*                                        Terms of Service*/}
                        {/*                                    </a>*/}
                        {/*                                    {' '}and{' '}*/}
                        {/*                                    <a className="gradient-color-txt">*/}
                        {/*                                        Privacy Policy*/}
                        {/*                                    </a>*/}
                        {/*                                </p>*/}
                        {/*                            </label>*/}
                        {/*                        </label>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="form-check">*/}
                        {/*                        <label className="form-check-label flex items-center ">*/}
                        {/*                            <label className="form-check-label body-text--small">*/}
                        {/*                                <Checkbox*/}
                        {/*                                    checked={agreementChecked2}*/}
                        {/*                                    className="form-check-input"*/}
                        {/*                                    onChange={onChangeAgreement2}*/}
                        {/*                                ></Checkbox>*/}
                        {/*                                <p className="body-txtitalic--small m-0">*/}
                        {/*                                    By continuing, you agree to the Commonspace*/}
                        {/*                                    {' '}*/}
                        {/*                                    <a className="gradient-color-txt">*/}
                        {/*                                        Terms of Service*/}
                        {/*                                    </a>{' '}*/}
                        {/*                                    ,*/}
                        {/*                                    <a className="gradient-color-txt">*/}
                        {/*                                        Privacy Policy*/}
                        {/*                                    </a>*/}
                        {/*                                    ,{' '}and{' '}*/}
                        {/*                                    <a className="gradient-color-txt">*/}
                        {/*                                        Community Guidelines.*/}
                        {/*                                    </a>*/}
                        {/*                                </p>*/}
                        {/*                            </label>*/}
                        {/*                        </label>*/}
                        {/*                    </div>*/}
                        {/*                    {hidePaymentMethod && itemDetails != null &&*/}
                        {/*                        <div className="input-group-com my-5">*/}
                        {/*                            <Form.Item className="mb-0">*/}
                        {/*                                <Button*/}
                        {/*                                    className="btn btn-primary btn-lg"*/}
                        {/*                                    htmlType="submit"*/}
                        {/*                                    disabled={!agreementChecked1 || !agreementChecked2}*/}
                        {/*                                >*/}
                        {/*                                    {submitted && (*/}
                        {/*                                        <i className="fa-solid fa-spinner fa-spin me-3"></i>*/}
                        {/*                                    )}*/}
                        {/*                                    {getButtonPaymentText(isSubscription, itemDetails.type, selectedMonthlyPrice)}*/}
                        {/*                                </Button>*/}
                        {/*                            </Form.Item>*/}
                        {/*                        </div>*/}
                        {/*                    }*/}

                        {/*                    {!hidePaymentMethod && itemDetails != null &&*/}
                        {/*                        <div className="input-group-com my-5">*/}
                        {/*                            <Form.Item className="mb-0">*/}
                        {/*                                <Button*/}
                        {/*                                    className="btn btn-primary btn-lg"*/}
                        {/*                                    htmlType="submit"*/}
                        {/*                                    disabled={*/}
                        {/*                                        !isAddCard && (*/}
                        {/*                                            selectedCard.value === 'default' ||*/}
                        {/*                                            selectedCard.value === 'add' ||*/}
                        {/*                                            submitted ||*/}
                        {/*                                            !agreementChecked1 ||*/}
                        {/*                                            !agreementChecked2) ||*/}
                        {/*                                        isAddCard && (*/}
                        {/*                                            submitted ||*/}
                        {/*                                            formHasError ||*/}
                        {/*                                            !agreementChecked1 ||*/}
                        {/*                                            !agreementChecked2)*/}
                        {/*                                    }*/}
                        {/*                                >*/}
                        {/*                                    {submitted && (*/}
                        {/*                                        <i className="fa-solid fa-spinner fa-spin me-3"></i>*/}
                        {/*                                    )}*/}
                        {/*                                    {getButtonPaymentText(isSubscription, itemDetails.type, selectedMonthlyPrice)}*/}
                        {/*                                </Button>*/}
                        {/*                            </Form.Item>*/}
                        {/*                        </div>*/}
                        {/*                    }*/}

                        {/*                </Form>*/}
                        {/*            </div>*/}
                        {/*        </>*/}
                        {/*        <div className="col-6">*/}
                        {/*            <div className="left-image-holder"></div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </>
                    )
                }
            </main>
        </>
    );
}
export default memo(ItemPaymentMethod);