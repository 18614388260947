import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import { OAuthExtension } from '@magic-ext/oauth';
import Alert from 'react-bootstrap/Alert';
import Web3 from 'web3';
import { getAuthUserInfo } from '../../states/general/actions';

import {
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_KEY,
	MAGIC_OAUTH_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../constants/common';
import { setAccessToken } from '../../utils/common';
import { checkMfaEnabled, checkSmsEnabled, requestSmsOtp, dualCheck2FA } from '../../services/user';
import { login, magic_login } from '../../services/auth';
import routes from '../../routes';

import './Login.scss';
import default_bg_img from '../../assets/images/dj_party.png';
import dj_bg_img from '../../assets/images/dj-party-v2.png';


const LoginForm = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [isMagicLink, setIsMagicLink] = useState(false);
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [isLoginBtn, setLoginBtn] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [hasMfaEnabled, setHasMfaEnabled] = useState(false);
	const { size } = useSelector((state) => state.classSize);
	const [margin, setMargin] = useState();
	const [hasEmailSupplied, setHasEmailSupplied] = useState(false);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new OAuthExtension()]
	});
	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);

	const handleFormOnBlur = useCallback(async () => {
		setAlert(null);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setAlert(null);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			try {
				setLoginBtn(true);
				console.log(hasMfaEnabled);
				if (hasMfaEnabled) {
					console.log('MFA Enabled');
					values.user_id = authUserInfo.id;
					await handleOtp(values);
				} else {
					console.log('MFA Not Enabled');
					await loginUser(values);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[hasMfaEnabled]
	);

	const supplyEmail =  () => {
		const fields = form.getFieldsValue()
		if(!formHasError){
			setHasEmailSupplied(true);
			form.resetFields();
			form.setFieldsValue({ 'email': String(fields.email) })	
		}
	}
	const checkHas2FA = async () => {
		try {
			let values = {
				user_id: authUserInfo.id
			};
			const mfa_async = checkMfaEnabled(values);
			const sms_async = checkSmsEnabled(values);
			const mfa_res = await mfa_async;
			const sms_res = await sms_async;
			if (mfa_res.result || sms_res.result) {
				setHasMfaEnabled(true);
			} else {
				setHasMfaEnabled(false);
				window.location.replace(routes.dashboard());
			}
			console.log(mfa_res.message);
			console.log(sms_res.message);
		} catch (error) {
			console.log(error);
		}
	};

	const requestSMS = async () => {
		let values = {
			user_id: authUserInfo.id
		};
		const result = await requestSmsOtp(values);
		if (result.result) {
			setAlert({
				variant: 'success',
				message: 'OTP sent to your number.'
			});
		} else {
			setAlert({
				variant: 'danger',
				message: 'OTP send failed.'
			});
		}
	};

	const handleOtp = async (values) => {
		try {
			// let values = {
			//     user_id: authUserInfo.id
			// };
			let result = await dualCheck2FA(values);
			if (result.result) {
				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Log in successful!'
				});
				setLoginBtn(false);
				window.location.replace(routes.dashboard());
			} else {
				setAlert({
					variant: 'danger',
					message: 'OTP Code is incorrect.'
				});
				setLoginBtn(false);
			}
		} catch (error) {
			setLoginBtn(false);
			console.log(error);
		}
	};

	// Web 3 Login
	const web3Login = async () => {
		setSubmitted(true);
		setIsMagicLink(true);

		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});

		web3.eth
			.getAccounts()
			.then((accounts) => {
				loginMagic({ user_public_address: accounts?.[0] });
			})
			.catch((error) => {
				setSubmitted(false);
				setIsMagicLink(false);
			});
	};

	const loginUser = async (values) => {
		const result = await login(values);
		await processLogin(result);
	};

	const loginMagic = async (values) => {
		const result = await magic_login(values);
		await processLogin(result);
	};

	const processLogin = async (result) => {
		// console.log(result);
		if (result) {
			if (result.result) {
				if (result.access_token) {
					setAccessToken(result);
				}
				dispatch(getAuthUserInfo());
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'User not found.Please try again.'
					});
				}
			}
		}
		setLoginBtn(false);
		setSubmitted(false);
		setIsMagicLink(false);
	};

	useEffect(() => {
		document.title = 'Commonspace Login';

		setMargin(() => {
			if (size !== 'lg') {
				return '4';
			} else {
				return '5';
			}
		});
		if (loading && loading === 'done' && authUserInfo) {
			// window.location.replace(routes.dashboard());
			setSubmitted(false);
			checkHas2FA();
		}
	}, [authUserInfo, loading, hasMfaEnabled, isMagicLink, size, margin]);

	return (
		<div className=" my-16 h-full items-center">
			<div className='grid grid-cols-12 gap-4'>
				<div className='col-span-12 lg:col-span-6  lg:ml-10 '>
					<div className=' h-full flex justify-center lg:justify-end'>
						<div className='w-full px-10 md:px-0 md:w-[664px] flex items-center'>
							<div className='content  '>
							<div className="grid grid-cols-1 font-['din2014Bold'] mb-2">
							{hasMfaEnabled && (
								<h1 className="header-large">
									Secure Authentication Enabled
								</h1>
							)}
							{!hasMfaEnabled && !hasEmailSupplied &&  (
								<h1 className={'header-large'}>Login</h1>
							)}
			 				{!hasMfaEnabled && hasEmailSupplied &&(
								<h1 className={'header-large mt-[64px] mb-2'}>Login with Email</h1>
							)}
							</div>
							<div className='grid grid-cols-1'>
							{hasMfaEnabled && (
								<h5>
									Enter the 2FA code associated with your account from your app. This
									may be from Google Authenticator, Authy, or whichever app you set
									up.
								</h5>
							)}
							{!hasMfaEnabled && !hasEmailSupplied && (
								<span className='text-xl font-[notoSansRegular] mb-8'>
									Enter your email/username or use your wallet to login. If you have 2 Factor Authentication enabled, be sure to have your method available.
								</span>
							)}
							{!hasMfaEnabled && hasEmailSupplied && (
								<span className='text-xl font-[notoSansRegular] mb-8' >
									Enter your password associated with your email. If you have 2 Factor Authentication enabled, be sure to have your method available.
								</span>
							)}
							</div>
							<div className='grid grid-cols-1'>
								<Form
								form={form}
								name="loginForm"
								validateMessages={VALIDATE_MESSAGES}
								onFocus={() => {
									setFormIsOnFocus(true);
								}}
								onBlur={() => {
									setFormIsOnFocus(false);
									handleFormOnBlur();
								}}
								autoComplete="off"
								onChange={handleFormChanges}
								onFinish={handleSubmit}
								className={formIsOnFocus ? 'hide-antd-error-messages z-0' : 'otp relative z-0'}
								>
								<>
									{alert && (
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									)}
								</>
								{!hasMfaEnabled && (
									// <div className={'input-group-com input-border-radius mt-5' + (alert ? 'mt-4' : 'mt-5')}></div>
									<div className={'input-group-com login-group-com mb-8'}>
										{/* <label className="text-label body-text--small">
											Enter email
										</label> */}
										<Form.Item
											label="Email or Username"
											name="email"
											className="mb-0 hidden-label z-0"
											validateTrigger="onBlur"
											rules={[{ required: true, type: 'email' }]}
										>
											<Input
												className={'w-[380px] py-1.5 px-3 input-' + size}
												placeholder="Enter your email or username"
												disabled={submitted}
											/>
										</Form.Item>
										{!hasEmailSupplied && (
										<Form.Item className="mb-0 hidden lg:block flex !justify-end !w-[200px]">
											<Button
												className={'btn text-white border-filled font-[notoSansSemiBold] w-[200px] z-0 btn-' + size}
												onClick={() => supplyEmail()}
												disabled={submitted || formHasError}
											>
												{isLoginBtn && (
													<i className="fa-solid fa-spinner fa-spin mr-2"></i>
												)}
												Login
											</Button>		
										</Form.Item>
										)}
									</div>
								)}
								{!hasMfaEnabled && hasEmailSupplied && (
									<>
									<div
										className={
											'input-group-com login-group-com input-with-btn  mb-8'
											
										}
									>
										{/* <label className="text-label body-text--small">Password</label> */}
										<Form.Item
											label="Password"
											name="password"
											className="mb-0 hidden-label z-0"
											validateTrigger="onBlur"
											rules={[{ required: true }]}
										>
											<Input
												type="password"
												className={'w-[380px] py-1.5 px-3 input-' + size}
												placeholder="Password"
												disabled={submitted}
											/>
										</Form.Item>
										<Form.Item className="mb-0 hidden lg:block flex !justify-end !w-[200px]">
											<Button
												className={'btn text-white  border-filled font-[notoSansSemiBold] w-[200px]  btn-' + size}
												htmlType="submit"
												disabled={submitted || formHasError}
											>
												{isLoginBtn && (
													<i className="fa-solid fa-spinner fa-spin z-0 mr-2"></i>
												)}
												Login
											</Button>		
										</Form.Item>
									</div>
									<Form.Item className="mb-8 block lg:hidden">
											<Button
												className={'btn border-filled btn-' + size}
												htmlType="submit"
												disabled={submitted || formHasError}
											>
												{isLoginBtn && (
													<i className="fa-solid fa-spinner fa-spin z-0 mr-2"></i>
												)}
												Login
											</Button>		
										</Form.Item>
									</>
									
								)}
								{hasMfaEnabled && (
									<div
										className={
											'input-group-com input-with-btn mt-8 mb-8'
										}
									>
										<Form.Item
											label="OTP"
											name="otp"
											className="mb-0 hidden-label z-0"
											rules={[{ required: true }]}
										>
											<Input
												type="number"
												className={'py-1.5 px-3 input-' + size}
												placeholder="Enter 2FA App Code"
												disabled={submitted}
											/>
										</Form.Item>
										{/* <Form.Item className="mb-0">
											<Button
												className="btn btn-primary btn-lg"
												htmlType="submit"
												disabled={submitted}
											>
												Confirm & Login
											</Button>
										</Form.Item> */}
									</div>
								)}
								{hasMfaEnabled && (
									<Button
										className={'btn border-filled btn-' + size}
										htmlType="submit"
										disabled={submitted}
									>
										{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
										Confirm & Login
									</Button>
								)}
								{!hasMfaEnabled && !hasEmailSupplied && (
									<Form.Item className="block lg:hidden mb-8">
									<Button
										className={'btn text-white border-filled font-[notoSansSemiBold] btn-' + size}
										onClick={() => supplyEmail()}
										htmlType="submit"
										disabled={submitted || formHasError}
									>
										{isLoginBtn && (
											<i className="fa-solid fa-spinner fa-spin  mr-2"></i>
										)}
										Login
									</Button>		
									</Form.Item>
								)}
								{!hasMfaEnabled && (
									<Button
										className={'btn mb-8 border-filled !hidden btn-' + size}
										onClick={web3Login}
										htmlType="submit"
										disabled={submitted}
									>
										<i
											className={
												isMagicLink
													? 'fa-solid fa-spinner fa-spin mr-2'
													: 'fa-solid fa-wallet mr-2'
											}
										></i>
										Login with wallet
									</Button>
								)}
								
								</Form>
							</div>
							<div className='grid grid-cols-1'>
							{!hasMfaEnabled && (
							<>
								<h5 className={'fst-italic text-lg font-[notoSansRegular] '}>
									Forgot your password?
									<Link to={routes.forgotPassword()} className="gradient-color-txt ms-1">
										Click here.
									</Link>
								</h5>
							</>
							)}
							{hasMfaEnabled && (
								<h5 className="mt-5 fst-italic">
									Can't access your 2FA app?
									<Link className="gradient-color-txt ms-1" onClick={requestSMS}>
										Request SMS code.
									</Link>
								</h5>
							)}
							</div>
						</div>
							
						</div>
					</div>
				</div>
				<div className='col-span-6 hidden lg:grid pl-[116px]   h-full'>
					
						<img className='w-full rounded-l-3xl  object-cover max-h-[488px] min-h-[365px]' src={dj_bg_img} />
				</div>
			</div>
		</div>		
	);
};

const Login = () => {
	return <LoginForm />;
};
export default memo(Login);
