import {forwardRef, memo, useEffect, useRef, useState} from 'react';
import co_logo from '../../../assets/images/co_logo.svg';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import DropdownWithItems from '../../commons/DropdownWithItems';
import { LANGUAGE, LOCATION } from '../../../constants/profile';
import useIsInViewport from "../../../utils/checkPageScrollPosition";
import {setIsFooterInViewPort} from "../../../states/footerInViewSlice";
import {useDispatch} from "react-redux";

const Footerv2 = (props) => {
	const ref = useRef(null);
	const isInViewport = useIsInViewport(ref);
	const dispatch = useDispatch();


	useEffect(() => {
		dispatch(setIsFooterInViewPort(isInViewport));
	}, [isInViewport]);

    const languageList = LANGUAGE;
	const locationList = LOCATION;
    const [selectedLanguage, setSelectedLanguage] = useState('English');
	const [selectedLocation, setSelectedLocation] = useState('United States');
    return (
		<footer ref={ref} className="bg-color-key ">
            <div className="container px-10 mx-auto grid grid-cols-12 gap-4 lg:gap-6">
                <div className="col-span-6 xl:col-span-4 mt-16">
                    <div className="flex items-end mb-4">
                        <img
                            className=" h-10  pt-2 pr-3.5"
                            src={co_logo}
                            alt="commonspace footer logo"
                        />
                    </div>
                    <div className="min-h-10 text-base font-secondary font-[notoSansMedium] text-color-bg font-normal leading-6 mb-4">
                            <span className='body-text--small color-light'>Powering communities around the world for creators, brands, and businesses.{' '} 
                                {getSitewideLink(
                                    'footer_learn_more',
                                    'gradient-color-txt body-txtitalic--small'
                                )}
                            </span>
                
                    </div>
                    <div className=" h-10 	mb-8">
						<div className="flex flex-wrap grid grid-cols-8 gap-6 social-links list-unstyled">
							<div className='text-center'>
							{getSitewideLink('footer_twitter_icon', "social-links list-unstyled", true)}
							</div>
							<div  className='text-center'>
							{getSitewideLink('footer_instagram_icon', "social-links list-unstyled", true)}
							</div>
							<div  className='text-center'>
							{getSitewideLink('footer_facebook_icon', "social-links list-unstyled", true)}
							</div>
							<div className='text-center' >
							{getSitewideLink('footer_youtube_icon', "social-links list-unstyled", true)}
							</div>
							<div className='text-center' >
							{getSitewideLink('footer_tiktok_icon', "social-links list-unstyled", true)}
							</div>
							<div className='text-center' >
							{getSitewideLink('footer_discord_icon', "social-links list-unstyled", true)}
							</div>
						</div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 social-links list-unstyled xl:h-[40px] mb-8">
						<div className='col-span-6'>
							<DropdownWithItems
										title="Location"
										customButtonClass="btn-md btn-secondary w-full xl:w-[200px] "
										icon="fa-regular fa-globe"
										selectedValue={selectedLocation}
										listItems={locationList}
										onDropdownChange={setSelectedLocation}
									/>
						</div>
						<div className='col-span-6'>
							<DropdownWithItems
										title="Language"
										customButtonClass="btn-md btn-secondary w-full xl:w-[200px]"
										icon="fa-regular fa-language"
										selectedValue={selectedLanguage}
										listItems={languageList}
										onDropdownChange={setSelectedLanguage}
									/>
						</div>
                    </div>
                    <div className="h-10 flex items-start">
						<p className="!pt-0 text-base font-secondary font-[notoSansMedium] text-color-key-alt leading-7">
							&copy; 2023 commonspace. All Rights Reserved.
						</p>
					</div>
                </div>
                <div className="col-span-2 min-w-max relative col-start-7 mt-16 mb-8 text-ellipsis overflow-hidden">
					<span className='h-10 flex items-center mb-4'>
						<h4 className="list-heading ">Account</h4>
					</span>
					
					<ul className="footer-links list-unstyled body-text--small !text-lg font-[notoSansRegular] ">
							<li>
								{/*<Link to="#">How It Works</Link>*/}
								{getSitewideLink('footer_account_how_it_works', ' ')}
							</li>
							<li className=''>
								{/*<Link to="#">Communities</Link>*/}
								{getSitewideLink('footer_account_communities', ' ')}
							</li>
							<li>
								{/*<Link to="#">Memberships</Link>*/}
								{getSitewideLink('footer_account_memberships', ' ')}
							</li>
							<li>
								{/*<Link to="#">FAQ</Link>*/}
								{getSitewideLink('footer_account_FAQ', ' ')}
							</li>
							<li>
								{/*<Link to="#">Privacy Policy</Link>*/}
								{getSitewideLink('footer_account_privacy_policy', ' ')}
							</li>
							
						</ul>
				</div>
				<div className="col-span-2 col-start-9 mt-16 mb-8 min-w-max">
					<span className='h-10 flex items-center mb-4'>
						<h4 className="list-heading ">Resources</h4>
					</span>
					<ul className="footer-links list-unstyled body-text--small !text-lg font-[notoSansRegular]">
							<li>
								{/*<Link to="#">Creators</Link>*/}
								{getSitewideLink('footer_resources_creators', ' ')}
							</li>
							<li>
								{/*<Link to="#">Brands</Link>*/}
								{getSitewideLink('footer_resources_brands', ' ')}
							</li>
							<li>
								{/*<Link to="#">Collabs</Link>*/}
								{getSitewideLink('footer_resources_collabs', ' ')}
							</li>
							<li>
								{/*<Link to="#">Fans</Link>*/}
								{getSitewideLink('footer_resources_fans', ' ')}
							</li>
							<li>
								{/*<Link to="#">Commerce</Link>*/}
								{getSitewideLink('footer_resources_commerce', ' ')}
							</li>
							
						</ul>
				</div>
				<div className="col-span-2 col-start-11 mt-16 mb-8 min-w-max">
					<span className='h-10 flex items-center mb-4'>
						<h4 className="list-heading ">Support</h4>
					</span>
					<ul className="footer-links list-unstyled body-text--small font-[notoSansRegular] !text-lg color-light">
							<li>
								Help Center
							</li>
							<li>
								Guides
							</li>
							<li>
								Chat
							</li>
							<li>
								Contact
							</li>
							<li>
								Terms
							</li>
							
						</ul>	
				</div>
            </div>
        </footer>
    )
}

export default memo(Footerv2);