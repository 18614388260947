import { memo, useEffect } from 'react';

import routes from '../../routes';
import { removeAccessToken } from '../../utils/common';

const Logout = () => {
	removeAccessToken('user');

	useEffect(() => {
		document.title = 'Commonspace Logout';

		window.location.replace(routes.login());
	}, []);

	return (
		<main id="dashboard" className="flex">
			<div className="container mx-auto min-h-screen md:min-h-[540px]">
				<div className="grid grid-cols-12 justify-start"></div>
			</div>
		</main>
	);
};

export default memo(Logout);
