import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Pagination from 'react-bootstrap/Pagination';

import { getAuthUserInfo } from '../../../../states/general/actions';
import { getFollowersList, getFollowingsList, getMutualsList } from '../../../../services/follow';
import { followUser, unfollowUser } from '../../../../services/follow';
import Card from './Card';
import List from './List';

import './ManageFollowers.scss';

const ManageContact = () => {
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [initialLoad, setInitialLoad] = useState(true);
	const [listView, setListView] = useState('cards');
	const [activeTab, setActiveTab] = useState(null);
	const [followersList, setFollowersList] = useState(null);
	const [followingsList, setFollowingsList] = useState(null);
	const [mutualList, setMutualList] = useState(null);
	const [followersPagination, setFollowersPagination] = useState([]);
	const [followingsPagination, setFollowingsPagination] = useState([]);
	const [mutualsPagination, setMutualsPagination] = useState([]);
	const [followersFetching, setFollowersFetching] = useState(false);
	const [followingsFetching, setFollowingsFetching] = useState(false);
	const [mutualsFetching, setMutualsFetching] = useState(false);

	const [followersCurrentPage, setFollowersCurrentPage] = useState(1);
	const [followingsCurrentPage, setFollowingsCurrentPage] = useState(1);
	const [mutualsCurrentPage, setMutualsCurrentPage] = useState(1);

	const buildPagination = (type, current_page, max_page) => {
		const items = [];
		if (max_page) {
			for (let number = 1; number <= max_page; number++) {
				items.push(
					<Pagination.Item key={number} active={number === current_page}>
						{number}
					</Pagination.Item>
				);
			}
		}

		if (type === 'followers') {
			setFollowersPagination(items);
		} else if (type === 'followings') {
			setFollowingsPagination(items);
		} else {
			setMutualsPagination(items);
		}
	};

	const handleOnClickPagination = useCallback(
		async (event, type) => {
			const itemClicked = event.target.text;
			if (itemClicked) {
				const number = parseInt(itemClicked);
				if (type === 'followers') {
					setFollowersCurrentPage(number);
					await getFollowersRequest(number);
				} else if (type === 'followings') {
					setFollowingsCurrentPage(number);
					await getFollowingsRequest(number);
				} else {
					setMutualsCurrentPage(number);
					await getMutualsRequest(number);
				}
			}
		},
		[authUserInfo]
	);

	const getFollowersRequest = useCallback(
		async (pageNumber = null) => {
			try {
				if (authUserInfo) {
					const data = {
						user_id: authUserInfo.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};

					setFollowersFetching(true);

					const response = await getFollowersList(data);
					if (response && response.result) {
						setFollowersList(response.data);
						buildPagination(
							'followers',
							parseInt(response.page),
							parseInt(response.page_range)
						);
					}
					setFollowersFetching(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo]
	);

	const getFollowingsRequest = useCallback(
		async (pageNumber = null) => {
			try {
				if (authUserInfo) {
					const data = {
						user_id: authUserInfo.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};

					setFollowingsFetching(true);

					const response = await getFollowingsList(data);
					if (response && response.result) {
						setFollowingsList(response.data);
						buildPagination(
							'followings',
							parseInt(response.page),
							parseInt(response.page_range)
						);
					}
					setFollowingsFetching(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo]
	);

	const getMutualsRequest = useCallback(
		async (pageNumber = null) => {
			try {
				if (authUserInfo) {
					const data = {
						user_id: authUserInfo.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};

					setMutualsFetching(true);

					const response = await getMutualsList(data);
					if (response && response.result) {
						setMutualList(response.data);
						buildPagination(
							'mutuals',
							parseInt(response.page),
							parseInt(response.page_range)
						);
					}
					setMutualsFetching(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo]
	);

	const setSelectedList = async (list) => {
		if (list === 'followers') {
			await getFollowersRequest();
		}
		if (list === 'followings') {
			await getFollowingsRequest();
		}
		if (list === 'mutuals') {
			await getMutualsRequest();
		}
		setActiveTab(list);
	};

	const handleFollowUnfollow = async (list, event, id) => {
		const data = {
			user_id: authUserInfo.id,
			follow_id: id
		};

		if (event === 'follow') {
			await followUser(data);
		} else {
			await unfollowUser(data);
		}

		dispatch(getAuthUserInfo());

		if (list === 'followers') {
			await getFollowersRequest(followersCurrentPage);
		}
		if (list === 'followings') {
			await getFollowingsRequest(followingsCurrentPage);
		}
		if (list === 'mutuals') {
			await getMutualsRequest(mutualsCurrentPage);
		}
	};

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			if (!activeTab && initialLoad) {
				setSelectedList('followers');
			}
			setInitialLoad(false);
		}

		document.title = 'Commonspace Profile Manage Followers';
	}, [loading, authUserInfo, activeTab, followersList, followingsList, mutualList]);

	return (
		<>
			<div className="col-span-12">
				<div className="dashboard-wrapper mt-4 pt-4">
					<div className="flex justify-between">
						<h3 className="header-medium">Followers & Following</h3>
						<ul className="follows-card-row">
							<li>
								<Link
									to="#"
									onClick={() => {
										setListView('cards');
									}}
									className={listView === 'cards' ? 'active' : ''}
								>
									<i className="fa-light fa-grid"></i>
								</Link>
							</li>
							<li>
								<Link
									to="#"
									onClick={() => {
										setListView('list');
									}}
									className={listView === 'list' ? 'active' : ''}
								>
									<i className="fa-light fa-square-list"></i>
								</Link>
							</li>
						</ul>
					</div>
					<div className='flex justify-center md:justify-evenly'>
					<ul className="nav nav-pills mt-5 flex-col md:flex-row flex justify-evenly">
						<li className="nav-item" role="presentation">
							<Button
								className={
									activeTab === 'followers' ? 'nav-link active' : 'nav-link'
								}
								onClick={() => {
									setSelectedList('followers');
								}}
							>
								{authUserInfo ? authUserInfo.followers_count : 0} Followers
							</Button>
						</li>
						<li className="nav-item" role="presentation">
							<Button
								className={
									activeTab === 'followings' ? 'nav-link active' : 'nav-link'
								}
								onClick={() => {
									setSelectedList('followings');
								}}
							>
								{authUserInfo ? authUserInfo.followings_count : 0} Following
							</Button>
						</li>
						<li className="nav-item" role="presentation">
							<Button
								className={activeTab === 'mutuals' ? 'nav-link active' : 'nav-link'}
								onClick={() => {
									setSelectedList('mutuals');
								}}
							>
								{authUserInfo ? authUserInfo.mutuals_count : 0} Mutuals
							</Button>
						</li>
					</ul>
					</div>
				</div>
				{listView === 'cards' ? (
					<>
						<div className="card-view mt-3">
							{(followersFetching || followingsFetching || mutualsFetching) && (
								<>
									<div className="list-loader">
										<i className="fa-solid fa-spin fa-spinner"></i>
									</div>
									<div className="list-loader-overlay"></div>
								</>
							)}
							<div className="grid grid-cols-12 md:gap-8">
								{activeTab === 'followers' && followersList && (
									<>
										{followersList.map((data, index) => (
											<Card
											col="col-span-12 sm:col-span-6 lg:col-span-3"
												key={index}
												type="followers"
												data={data}
												onEventClick={handleFollowUnfollow}
												displayButtons={true}
											></Card>
										))}
									</>
								)}
								{activeTab === 'followings' && followingsList && (
									<>
										{followingsList.map((data, index) => (
											<Card
												col="col-span-12 sm:col-span-6 lg:col-span-3"
												key={index}
												type="followings"
												data={data}
												onEventClick={handleFollowUnfollow}
												displayButtons={true}
											></Card>
										))}
									</>
								)}
								{activeTab === 'mutuals' && mutualList && (
									<>
										{mutualList.map((data, index) => (
											<Card
												col="col-span-12 sm:col-span-6 lg:col-span-3"
												key={index}
												type="mutuals"
												data={data}
												onEventClick={handleFollowUnfollow}
												displayButtons={true}
											></Card>
										))}
									</>
								)}
							</div>
							<div className="grid grid-cols-12 md:gap-8 mt-4">
								{activeTab === 'followers' && followersPagination.length > 4 && (
									<Pagination
										size="sm"
										onClick={(event) =>
											handleOnClickPagination(event, 'followers')
										}
									>
										{followersPagination}
									</Pagination>
								)}
								{activeTab === 'followings' && followingsPagination.length > 4 && (
									<Pagination
										size="sm"
										onClick={(event) =>
											handleOnClickPagination(event, 'followings')
										}
									>
										{followingsPagination}
									</Pagination>
								)}
								{activeTab === 'mutuals' && mutualsPagination.length > 4 && (
									<Pagination
										size="sm"
										onClick={(event) =>
											handleOnClickPagination(event, 'mutuals')
										}
									>
										{mutualsPagination}
									</Pagination>
								)}
							</div>
						</div>
					</>
				) : (
					<>
						<div className="row-view">
							{(followersFetching || followingsFetching || mutualsFetching) && (
								<>
									<div className="list-loader">
										<i className="fa-solid fa-spin fa-spinner"></i>
									</div>
									<div className="list-loader-overlay"></div>
								</>
							)}
							<div className="grid grid-cols-1">
								{activeTab === 'followers' && followersList && (
									<>
										{followersList.map((data, index) => (
											<List
												key={index}
												type="followers"
												data={data}
												onEventClick={handleFollowUnfollow}
											></List>
										))}
										{}
									</>
								)}
								{activeTab === 'followings' && followingsList && (
									<>
										{followingsList.map((data, index) => (
											<List
												key={index}
												type="followings"
												data={data}
												onEventClick={handleFollowUnfollow}
											></List>
										))}
									</>
								)}
								{activeTab === 'mutuals' && mutualList && (
									<>
										{mutualList.map((data, index) => (
											<List
												key={index}
												type="mutuals"
												data={data}
												onEventClick={handleFollowUnfollow}
											></List>
										))}
									</>
								)}
							</div>
							<div className="grid grid-cols-1 md:gap-8 mt-4">
								{activeTab === 'followers' && followersPagination.length > 10 && (
									<Pagination
										size="sm"
										onClick={(event) =>
											handleOnClickPagination(event, 'followers')
										}
									>
										{followersPagination}
									</Pagination>
								)}
								{activeTab === 'followings' && followingsPagination.length > 10 && (
									<Pagination
										size="sm"
										onClick={(event) =>
											handleOnClickPagination(event, 'followings')
										}
									>
										{followingsPagination}
									</Pagination>
								)}
								{activeTab === 'mutuals' && mutualsPagination.length > 10 && (
									<Pagination
										size="sm"
										onClick={(event) =>
											handleOnClickPagination(event, 'mutuals')
										}
									>
										{mutualsPagination}
									</Pagination>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default memo(ManageContact);
