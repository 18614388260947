import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Form, Input, InputNumber } from 'antd';

import DropdownWithCountries from '../../../../components/commons/DropdownWithCountryRegion/DropdownWithCountries';
import DropdownWithRegions from '../../../../components/commons/DropdownWithCountryRegion/DropdownWithRegions';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { getAuthUserInfo } from '../../../../states/general/actions';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import {
	createPaymentMethod,
	removePaymentMethod,
	updatePaymentMethod
} from '../../../../services/payment_method';
import PaymentCard from '../../../Settings/SettingsContent/ManagePaymentMethods/PaymentCard';
import FormFieldStacked from '../../../../components/commons/FormFieldStacked';

import './ManagePaymentMethods.scss';
import { getCode } from 'country-list';
import Alert from 'react-bootstrap/Alert';

const ManagePaymentMethodsContent = () => {
	const dispatch = useDispatch();
	const [addCardForm] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [submittedAddCardForm, setSubmittedAddCardForm] = useState(false);
	const [showAddCardForm, setShowAddCardForm] = useState(false);
	const [cardNumber, setCardNumber] = useState(null);
	const [expiry, setExpiry] = useState(null);
	const [cvc, setCvc] = useState(null);
	const [fullName, setFullName] = useState(null);
	const [streetAddress, setStreetAddress] = useState(null);
	const [city, setCity] = useState(null);
	const [address, setAddress] = useState(null);
	const [zip, setZip] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [selectedIdToUpdate, setSelectedIdToUpdate] = useState(null);
	const [submittedUpdateCardForm, setSubmittedUpdateCardForm] = useState(false);
	const { getCode, getName } = require('country-list');
	const [alert, setAlert] = useState(null);

	const handleFormChanges = useCallback(async () => {
		setCardNumber(addCardForm.getFieldValue('card_number'));
		setExpiry(addCardForm.getFieldValue('expiry'));
		setCvc(addCardForm.getFieldValue('cvc'));
		setFullName(addCardForm.getFieldValue('full_name'));
		setStreetAddress(addCardForm.getFieldValue('street_address'));
		setCity(addCardForm.getFieldValue('city'));
		setAddress(addCardForm.getFieldValue('address'));
		setZip(addCardForm.getFieldValue('zip'));
		setPhoneNumber(addCardForm.getFieldValue('phone_number'));
	}, []);

	const submitAddCardForm = useCallback(
		async (values) => {
			try {
				const data = values;
				setDisplayDropDownError(true);

				if (!selectedCountry || selectedCountry === '') {
					return;
				}
				if (!selectedRegion || selectedRegion === '') {
					return;
				}
				//TODO: change package this is only a temporary fix
				let countryCode = getCode(selectedCountry);
				if (!countryCode) {
					if (selectedCountry === 'United States') {
						countryCode = getCode('United States of America');
					}
				}

				data.country = countryCode;
				data.state = selectedRegion;
				data.expiry_mm = values.expiry.format('MM');
				data.expiry_yy = values.expiry.format('YYYY');

				delete data.expiry;

				if (authUserInfo) {
					setSubmittedAddCardForm(true);
					data.user_id = authUserInfo.id;

					const response = await createPaymentMethod(data);
					if (response && response.result) {
						setShowAddCardForm(false);
						dispatch(getAuthUserInfo());
					}
					setSubmittedAddCardForm(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo, selectedCountry, selectedRegion]
	);

	const enableAddCardForm = useCallback(async () => {
		setShowAddCardForm(true);
	}, []);

	const cancelAddCardForm = useCallback(async () => {
		setShowAddCardForm(false);
	}, []);

	const handleRemovePaymentMethod = async (data) => {
		try {
			if (authUserInfo) {
				await removePaymentMethod(data);
				dispatch(getAuthUserInfo());
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleUpdatePaymentMethod = async (data) => {
		try {
			setAlert(null);

			if (authUserInfo) {
				const response = await updatePaymentMethod(data);
				if (response && response.result) {
					dispatch(getAuthUserInfo());
				} else {
					setAlert({
						variant: 'danger',
						message: response.message
					});
				}
				setSubmittedUpdateCardForm(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handlePaymentMethodOnClick = async (event, data) => {
		if (event === 'remove') {
			await handleRemovePaymentMethod(data);
		} else if (event === 'submit-update') {
			setSubmittedUpdateCardForm(true);
			await handleUpdatePaymentMethod(data);
		} else if (event === 'cancel-update') {
			setSelectedIdToUpdate(null);
		} else {
			setShowAddCardForm(false);
			setSelectedIdToUpdate(data.id);
		}
	};

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			if (!showAddCardForm) {
				addCardForm.setFieldsValue({
					card_number: null,
					expiry: null,
					cvc: null,
					full_name: null,
					street_address: null,
					city: null,
					address: null,
					zip: null,
					phone_number: null
				});
				setSelectedCountry('');
				setSelectedRegion('');
				setDisplayDropDownError(false);
			}
		}
	}, [loading, authUserInfo, showAddCardForm]);

	return (
		<>
			<div className="col-span-12">
				<div className="dashboard-wrapper mt-8">
					<div className="grid grid-cols-12 ">
						<div className="col-span-7">
							<h3 className="header-medium">Payment Methods</h3>
						</div>
						<div className="col-span-5">
							<p className="body-txtitalic--large text-end">
								{authUserInfo ? authUserInfo.payment_methods_count : 0} Active
							</p>
						</div>
					</div>
					<p className="pt-3 body-txtitalic--small">
						Add, update, and manage your credit cards and credit balances below. Your
						credit card information is SSL encrypted and secured by Stripe. Commonspace
						does not have access your full credit card details and we never share your
						information.{' '}
						<a className="gradient-color-txt" href="">
							Learn more about how we protect and process your payments.
						</a>{' '}
						For crypto payments,{' '}
						<a className="gradient-color-txt" href="">
							visit Account Settings &gt; Manage Wallets.
						</a>
					</p>
					<div className=" pt-4 grid grid-cols-12 gap-y-8 md:gapy-0 md:gap-8 grid-flow-row">
						<div className="col-span-12 md:col-span-6">
							<Button className="btn btn-secondary btn-md" disabled={showAddCardForm}>
								Redeem Code
							</Button>
						</div>
						<div className="col-span-12 md:col-span-6">
							<Button
								className="btn btn-primary btn-md"
								disabled={showAddCardForm}
								onClick={enableAddCardForm}
							>
								Add Credit Card
							</Button>
						</div>
					</div>
				</div>
				<div
					className={
						'p-5 rounded-lg mt-8 pt-4 ' + (!showAddCardForm ? 'hide-form' : '')
					}
				>
					<Form
						form={addCardForm}
						name="addCardForm"
						validateMessages={VALIDATE_MESSAGES}
						onChange={handleFormChanges}
						onFinish={submitAddCardForm}
					>
						<div className="grid grid-cols-12 pt-3">
							<div className="col-span-12 md:col-span-6">
								<h5 className="header-small">Add New Credit Card</h5>
							</div>
							<div className="col-span-12 md:col-span-6">
								<p className="body-txtitalic--small gradient-color-txt text-end">
									Secured by Stripe
								</p>
							</div>
						</div>
						<div className='grid grid-cols-12 gap2 md:gap-8 mt-8 items-end'>
							<div className="col-span-12 md:col-span-6 !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Card Number
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Card Number"
										name="card_number"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>										
											<FormFieldStacked
											formType='input'
											type="text"	
											disabled={submittedAddCardForm}
											placeholder='15 or 16 digits'
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-3 !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Expiry
								</label>
								<div className="relative rounded-none h-[40px] w-full text-gray-900 flex items-center ">
									<Form.Item
										label="Expiry"
										name="expiry"
										className="mb-0 hidden-label h-[40px] w-full "
										rules={[
											{
												type: 'object',
												required: true
											}
										]}
									>
										<DatePicker
											onBlur={showTooltip}
											className="body-text--small !bg-[#ffffff80] !text-lg h-[40px] w-full"
											picker="month"
											format="MM/YY"
											placeholder="MM/YY"
											disabled={submittedAddCardForm}
										/>
									</Form.Item>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-3 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								CVV/CVC
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="CVV/CVC"
										name="cvc"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>
										
										<FormFieldStacked
										formType='input'
										type="number"
										disabled={submittedAddCardForm}
										placeholder='3 or 4 digits'>											
										</FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							
						</div>
						<div className='grid grid-cols-12 gap2 md:gap-8 mt-8 items-end'>
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Full Name
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Full Name"
										name="full_name"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>
									
											<FormFieldStacked
											formType='input'
											type="text"	
											disabled={submittedAddCardForm}
											placeholder='As shown on credit card'
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Country
								</label>
								<div className="relative rounded-none border-0 ">
									<DropdownWithCountries
										title="Country"
										displayError={
											displayDropDownError &&
											(!selectedCountry || selectedCountry === '')
										}
										value={selectedCountry}
										onDropdownChange={setSelectedCountry}
									></DropdownWithCountries>
								</div>
								
							</div>	
							
						</div>
						<div className='grid grid-cols-12 gap2 md:gap-8 mt-8 items-end'>
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Street Address
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Street Address"
										name="street_address"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>
						
										<FormFieldStacked
											formType='input'
											type="text"	
											disabled={submittedAddCardForm}
											placeholder='Number and Street'
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-3 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								State
								</label>
								<div className="relative rounded-none border-0 ">
									<DropdownWithRegions
										title="State"
										displayError={
											displayDropDownError &&
											(!selectedRegion || selectedRegion === '')
										}
										country={selectedCountry}
										value={selectedRegion}
										onDropdownChange={setSelectedRegion}
									></DropdownWithRegions>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-3 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								APT/STE/BLDG
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="APT/STE/BLDG"
										name="address"
										className="mb-0 hidden-label"
									>
									
											<FormFieldStacked
											formType='input'
											type="text"	
											disabled={submittedAddCardForm}
											placeholder='OPTIONAL'
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							
						</div>
						<div className='grid grid-cols-12 gap2 md:gap-8 mt-8'>
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Zip
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Zip"
										name="zip"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>									
									<FormFieldStacked
									formType='input'
									type="number"
									disabled={submittedAddCardForm}
									placeholder='5 digits'>											
									</FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							<div className="col-span-12 md:col-span-6 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Phone
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Phone"
										name="phone_number"
										className="mb-0 hidden-label"
										rules={[
											{
												required: true
											}
										]}
									>
								
										<FormFieldStacked
										formType='input'
										type="text"	
										disabled={submittedAddCardForm}
										placeholder='10 digits'
										></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>
							
						</div>											
						<div className="pt-5 grid grid-cols-1 md:grid-cols-2 gap-y-8 md:gap-y-0 md:gap-8 grid-flow-row">
							<div className="!order-last md:!order-first">
								<Button
									className="btn btn-secondary btn-md"
									disabled={submittedAddCardForm}
									onClick={cancelAddCardForm}
								>
									{submittedAddCardForm && (
										<>
											<i className="fa-solid fa-spin fa-spinner"></i>
										</>
									)}
									Cancel
								</Button>
							</div>
							<div className="!order-first md:!order-last">
								<Form.Item>
									<Button
										className="btn btn-primary btn-md !mb-0"
										htmlType="submit"
										disabled={submittedAddCardForm}
									>
										{submittedAddCardForm && (
											<>
												<i className="fa-solid fa-spin fa-spinner"></i>
											</>
										)}
										Verify
									</Button>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
				<div className="payment-row-view">
					{authUserInfo && authUserInfo.payment_methods && (
						<>
							{authUserInfo.payment_methods.map((data, index) => (
								<PaymentCard
									key={index}
									data={data}
									updateId={selectedIdToUpdate}
									onEventClick={handlePaymentMethodOnClick}
									submittedUpdateCardForm={submittedUpdateCardForm}
									alert={alert}
								></PaymentCard>
							))}
						</>
					)}
					{/*<div className="row-view-wrapper mt-8">*/}
					{/*    <div className="row">*/}
					{/*        <div className="col flex items-center">*/}
					{/*            <i className="fa-solid fa-credit-card-blank card-ico"></i>*/}
					{/*            <label className="header-small">Commonspace Credit</label>*/}
					{/*        </div>*/}
					{/*        <div className="col flex items-center">*/}
					{/*            <p className="body-txtitalic--smallest m-0">*/}
					{/*                $100 USD available <span className="exp">Expires 01/28</span>*/}
					{/*            </p>*/}
					{/*        </div>*/}
					{/*        <div className="col flex items-centerjustify-end">*/}
					{/*            <a className="btn-row-view">*/}
					{/*                <i className="fa-regular fa-plus"></i>*/}
					{/*            </a>*/}
					{/*        </div>*/}
					{/*    </div>*/}
					{/*</div>*/}
				</div>
			</div>
		</>
	);
};

export default memo(ManagePaymentMethodsContent);
