export const CUSTOM_REGEX = {
	IDENTIFY_VALID_URL: /^(https?:\/\/)?([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}(\/\S*)?(#\S*)?$/,
    DETECT_INVALID_PROTOCOL: /^((?!https?:\/\/)[a-zA-Z0-9]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
    STRICT_EMAIL_VALIDATION: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    EXTEND_EMAIL_VALIDATION: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    USERNAME_VALIDATION: /^@((\S)+)/,
    PERCENTAGE_VALIDATION: /^(0*100{1,1}?%?)$|(^0*\d{0,2}?%?)$/,
    TWO_DECIMAL_PLACES: /([0-9]*[.]{0,1}[0-9]{0,2})/s,
    CHANNEL_NAME_VALIDATION:/^[0-9a-zA-Z_-]{0,24}$/  //alphanumeric with hyphen and underscore input with 24 max characters
};
