import {memo, useCallback, useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import {Button, Form, Input, message} from 'antd';
import {VALIDATE_MESSAGES} from '../../../../constants/common';
import {
    updateUserSettings2FASMS,
    updateUserSettings2FAGoogleCode,
    enableOtp,
    getQrString,
    verifyMfaSetup,
    getBackupCodes,
    setupSms,
    checkSmsEnabled,
    disableSms
} from '../../../../services/user';

import badge_iampass from '../../../../assets/images/badge_IAMPASS.png';
import {getAuthUserInfo} from '../../../../states/general/actions';
import routes from '../../../../routes';
import React from 'react';
import QRCode from 'react-qr-code';

const Manage2FAContent = () => {
    const [phoneSMSEditForm] = Form.useForm();
    const [googleAuthEditForm] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const [submittedPhoneSMS, setSubmittedPhoneSMS] = useState(false);
    const [smsEnabled, setSmsEnabled] = useState(false);
    const [submittedGoogleAuth, setSubmittedGoogleAuth] = useState(false);

    const [show2fa, setShow2fa] = useState(false);
    const [qrString, setQrString] = useState('https://www.common.space/');
    const [backupcode, setBackupcode] = useState(null);
    const [alert, setAlert] = useState(null);

    const authNumber1 = useRef(null);
    const authNumber2 = useRef(null);
    const authNumber3 = useRef(null);
    const authNumber4 = useRef(null);
    const authNumber5 = useRef(null);
    const authNumber6 = useRef(null);

    const enable2fa = async () => {
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await enableOtp(values);
            if (result.result) {
                setShow2fa(true);
                setQrString(result.qr_string);
                setQR();
                console.log(message);
            } else {
                console.log(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const cancel2fa = async () => {
        setShow2fa(false);
    };

    const checkSms2Fa = async () => {
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await checkSmsEnabled(values);
            if (result.result) {
                setSmsEnabled(true);
                console.log(message);
            } else {
                setSmsEnabled(false);
                console.log(message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getBackupCode = async () => {
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await getBackupCodes(values);
            if (result.result) {
                setBackupcode(result.secret_key);
                setAlert({
                    variant: 'success',
                    message: result.secret_key
                });
                console.log(message);
            } else {
                console.log(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const downloadBackupCode = async () => {
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await getBackupCodes(values);
            if (result.result) {
                setBackupcode(result.secret_key);
                const element = document.createElement('a');
                const file = new Blob([result.secret_key], {
                    type: 'text/plain'
                });
                element.href = URL.createObjectURL(file);
                element.download = 'backupcode.txt';
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
                console.log(message);
            } else {
                console.log(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setQR = async () => {
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const result = await getQrString(values);
            if (result.result) {
                setQrString(result.qr_string);
                console.log(message);
            } else {
                console.log(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submitPhoneSMSEditForm = useCallback(
        async (values) => {
            try {
                if (authUserInfo) {
                    if (!smsEnabled) {
                        setSubmittedPhoneSMS(true);
                        values.user_id = authUserInfo.id;
                        const fasms = updateUserSettings2FASMS(values);
                        const sms = setupSms(values);

                        const result = await fasms;
                        const sms_res = await sms;

                        if (result && sms_res.result) {
                            dispatch(getAuthUserInfo());
                            checkSms2Fa();
                        }
                        setSubmittedPhoneSMS(false);
                    } else {
                        setSubmittedPhoneSMS(true);
                        values.user_id = authUserInfo.id;
                        const fasms = updateUserSettings2FASMS(values);
                        const sms = disableSms(values);

                        const result = await fasms;
                        const sms_res = await sms;

                        if (result && sms_res.result) {
                            dispatch(getAuthUserInfo());
                            checkSms2Fa();
                        }
                        setSubmittedPhoneSMS(false);
                    }
                }
            } catch (error) {
                console.log(error);
                setSubmittedPhoneSMS(false);
            }
        },
        [authUserInfo, smsEnabled]
    );

    const submitGoogleAuthEditForm = useCallback(
        async (values) => {
            const data = values;
            data.code =
                values.code_num1 +
                '' +
                values.code_num2 +
                '' +
                values.code_num3 +
                '' +
                values.code_num4 +
                '' +
                values.code_num5 +
                '' +
                values.code_num6;

            data.otp = data.code;

            try {
                if (authUserInfo) {
                    setSubmittedGoogleAuth(true);
                    data.user_id = authUserInfo.id;

                    // Allow 2 async to work simultaneously
                    let check_otp = verifyMfaSetup(data);
                    let update2fa = updateUserSettings2FAGoogleCode(data);

                    // Await the return value, not the async function to prevent blocking
                    const result = await update2fa;
                    const result2 = await check_otp;

                    if (result && result2.result) {
                        dispatch(getAuthUserInfo());
                    } else {
                        // OTP not verified
                        console.log(result2.message);
                    }
                    setSubmittedGoogleAuth(false);
                }
            } catch (error) {
                console.log(error);
                setSubmittedGoogleAuth(false);
            }
        },
        [authUserInfo]
    );

    const onChangeAuthNumber = (e, field) => {
        if (/^-?\d+$/.test(e.target.value)) {
            if (field === 'code_num1') {
                authNumber2.current.focus();
            }
            if (field === 'code_num2') {
                authNumber3.current.focus();
            }
            if (field === 'code_num3') {
                authNumber4.current.focus();
            }
            if (field === 'code_num4') {
                authNumber5.current.focus();
            }
            if (field === 'code_num5') {
                authNumber6.current.focus();
            }
        } else {
            googleAuthEditForm.setFieldsValue({
                [field]: null
            });
        }
    };

    useEffect(() => {
        if (loading && loading === 'done' && authUserInfo) {
            if (!authUserInfo.auth_2fa_active) {
                navigate(routes.settings());
            } else {
                phoneSMSEditForm.setFieldsValue({
                    phone_sms: authUserInfo.phone_sms
                });
                // Don't pre-fill code
                // if (authUserInfo.auth_2fa_token && authUserInfo.auth_2fa_token.length === 6) {
                //     googleAuthEditForm.setFieldsValue({
                //         code_num1: authUserInfo.auth_2fa_token[0],
                //         code_num2: authUserInfo.auth_2fa_token[1],
                //         code_num3: authUserInfo.auth_2fa_token[2],
                //         code_num4: authUserInfo.auth_2fa_token[3],
                //         code_num5: authUserInfo.auth_2fa_token[4],
                //         code_num6: authUserInfo.auth_2fa_token[5]
                //     });
                // }
            }
        }
        setQR();
        checkSms2Fa();
        setShow2fa(false);
    }, [loading, authUserInfo]);

    return (
        <>
            <div className="col-span-12">
                <div className="dashboard-wrapper mt-4 pt-4">
                    <h3 className="header-medium">2 Factor Authentication</h3>
                    <p className="pt-3 body-text--small">
                        Adding 2 Factor Authentication (2FA) or better is strongly recommended for
                        your security. It is also required if you own or manage a Space.{' '}
                        <a className="gradient-color-txt body-txtitalic--small" href="">
                            Learn more about 2FA and how it protects your account.
                        </a>
                    </p>
                </div>
                <div className="dashboard-wrapper mt-4 pt-4">
                    <Form
                        form={phoneSMSEditForm}
                        name="phoneSMSEditForm"
                        validateMessages={VALIDATE_MESSAGES}
                        onFinish={submitPhoneSMSEditForm}
                    >
                        <div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
                            <div className="col-span-12  md:col-span-6">
                                <h5 className="header-small">SMS 2 Factor Authentication </h5>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <p className="body-text--small text-end">
                                    Use your mobile phone for basic 2FA.
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row mt-4 items-center gap-x-3">
                            <div className="col !order-last md:!order-first !mt-3 md:!mt-0">
                                <div className="input-group-com input-label-full">
                                    <label className="body-text--small ps-3 gradient-color-txt">
                                        Phone (SMS)
                                    </label>
                                    <Form.Item
                                        label="Phone Number"
                                        name="phone_sms"
                                        className="mb-0 hidden-label"
                                        rules={[{required: true}]}
                                    >
                                       
                                        <Input className="input-md" disabled={submittedPhoneSMS}/>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="flex items-center !order-first md:!order-last">
                                <p className="body-txtitalic--smallest">
                                    This is your phone number that you set up in your{' '}
                                    <a className="gradient-color-txt body-txtitalic--small" href="">
                                        Account Settings.
                                    </a>{' '}
                                    Changing it here will also change it there.
                                </p>
                            </div>
                        </div>
                        <Form.Item className="mb-0">
                            <Button
                                className="btn btn-primary btn-md my-4 mb-3"
                                htmlType="submit"
                                disabled={submittedPhoneSMS}
                            >
                                {submittedPhoneSMS && (
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                )}
                                {smsEnabled && <span>Disable SMS 2FA</span>}
                                {!smsEnabled && <span>Set up SMS 2FA</span>}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                {!show2fa && (
                    <div className="dashboard-wrapper mt-4 pt-4">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 md:col-span-6">
                                <h5 className="header-small">Google Authenticator</h5>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <p className="body-text--small text-end">
                                    Use the Google Authenticator App for 2FA.
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row mt-4 items-center gap-x-3">
                            <div className="flex !order-last md:!order-first grid grid-cols-1 xsm:grid-cols-2 grid-flow-row gap-x-1 !mt-3 md:!mt-0">
                                <button
                                    className="btn btn-secondary btn-md"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fa-brands fa-app-store-ios"></i>
                                    <span className="w-3/4">iOS</span>
                                    <i className="fa-light fa-arrow-up-right-from-square"></i>
                                </button>
                                <button
                                    className="btn btn-secondary btn-md"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fa-brands fa-google-play"></i>
                                    <span className="w-3/4">Android</span>
                                    <i className="fa-light fa-arrow-up-right-from-square"></i>
                                </button>
                            </div>
                            <div className="flex items-center !order-first md:!order-last">
                                <p className="body-txtitalic--smallest">
                                    To get started, first download the app on your phone from your
                                    app store. You can also use your preferred 2FA App if it’s
                                    compatible.
                                </p>
                            </div>
                        </div>
                        <button
                            className="btn btn-primary btn-md mt-4 mb-3"
                            type="button"
                            onClick={enable2fa}
                        >
                            Set up Google 2FA
                        </button>
                    </div>
                )}
                {show2fa && (
                    <div className="google-auth-container mt-4 pt-4">
                        <div className="grid grid-cols-12 google-auth-header">
                            <div className="col-span-12 md:col-span-6">
                                <h5 className="header-small">Google Authenticator</h5>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <p className="body-text--small text-end">Setting up Google 2FA</p>
                            </div>
                        </div>
                        <div className="google-auth-wrapper">
                            <Form
                                form={googleAuthEditForm}
                                name="googleAuthEditForm"
                                validateMessages={VALIDATE_MESSAGES}
                                onFinish={submitGoogleAuthEditForm}
                            >
                                <div className="grid grid-cols-1 grid-flow-row gap-4 md:!gap-8 md:grid-cols-12">
                                    {/*<div className="md:col-span-5 mx-auto grid grid-flow-row grid-cols-1 md:grid-cols-2">*/}
                                        {/* <img id="qr_code" alt="qr" /> */}
                                        {qrString && (
                                            <QRCode
                                                id="qr_code"
                                                alt="qr"
                                                title="GeeksForGeeks"
                                                value={qrString}
                                                style={{
                                                    height: '136',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                }}
                                                className="md:col-span-3"
                                            />
                                        )}
                                        <div className="md:col-span-3">
                                            <h6>Step 1: Scan QR Code</h6>
                                            <p>
                                                Scan the QR Code with the Google Authenticator App.
                                            </p>
                                            <small>View text code instead</small>
                                        </div>
                                    {/*</div>*/}
                                    <div className="md:col-span-4 input2fa">
                                        <h6>Step 2: Enter 2FA Code</h6>
                                        <Form.Item
                                            name="code_num1"
                                            className="mb-0 hidden-label google-auth-numbers"
                                            onChange={(e) => onChangeAuthNumber(e, 'code_num1')}
                                            rules={[{required: true, message: ''}]}
                                        >
                                            <Input
                                                disabled={submittedGoogleAuth}
                                                maxLength={1}
                                                ref={authNumber1}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="code_num2"
                                            className="mb-0 hidden-label google-auth-numbers"
                                            onChange={(e) => onChangeAuthNumber(e, 'code_num2')}
                                            rules={[{required: true, message: ''}]}
                                        >
                                            <Input
                                                disabled={submittedGoogleAuth}
                                                maxLength={1}
                                                ref={authNumber2}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="code_num3"
                                            className="mb-0 hidden-label google-auth-numbers"
                                            onChange={(e) => onChangeAuthNumber(e, 'code_num3')}
                                            rules={[{required: true, message: ''}]}
                                        >
                                            <Input
                                                disabled={submittedGoogleAuth}
                                                maxLength={1}
                                                ref={authNumber3}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="code_num4"
                                            className="mb-0 hidden-label google-auth-numbers"
                                            onChange={(e) => onChangeAuthNumber(e, 'code_num4')}
                                            rules={[{required: true, message: ''}]}
                                        >
                                            <Input
                                                disabled={submittedGoogleAuth}
                                                maxLength={1}
                                                ref={authNumber4}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="code_num5"
                                            className="mb-0 hidden-label google-auth-numbers"
                                            onChange={(e) => onChangeAuthNumber(e, 'code_num5')}
                                            rules={[{required: true, message: ''}]}
                                        >
                                            <Input
                                                disabled={submittedGoogleAuth}
                                                maxLength={1}
                                                ref={authNumber5}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="code_num6"
                                            className="mb-0 hidden-label google-auth-numbers"
                                            onChange={(e) => onChangeAuthNumber(e, 'code_num6')}
                                            rules={[{required: true, message: ''}]}
                                        >
                                            <Input
                                                disabled={submittedGoogleAuth}
                                                maxLength={1}
                                                ref={authNumber6}
                                            />
                                        </Form.Item>
                                        <small>Get help setting up 2FA</small>
                                    </div>
                                    <div className="md:col-span-2">
                                        <h6>Step 3: Save</h6>
                                        <Form.Item className="mb-0">
                                            <Button
                                                className="btn btn-secondary btn-md mt-3 mb-2 btn-2fa whitespace-pre-wrap"
                                                htmlType="submit"
                                                disabled={submittedGoogleAuth}
                                            >
                                                {submittedGoogleAuth && (
                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                )}
                                                Save & Confirm
                                            </Button>
                                        </Form.Item>
                                        <small onClick={cancel2fa}>Cancel Google 2FA Setup</small>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                )}
                <div className="dashboard-wrapper mt-4 pt-4">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 md:col-span-6">
                            <h5 className="header-small">
                                Commonspace Multifactor <img alt="badge" src={badge_iampass}/>
                            </h5>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <p className="body-text--small text-end">
                                Use Commonspace MultiFactor App by IAMPASS
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row mt-4 items-center gap-x-3">
                        <div className="flex justify-center items-center !order-last md:!order-first grid grid-cols-1 xsm:grid-cols-2 grid-flow-row gap-x-1 !mt-3 md:!mt-0">
                            <button
                                className="btn btn-secondary btn-md"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="fa-brands fa-app-store-ios"></i>
                                <span className="w-3/4">iOS</span>
                                <i className="fa-light fa-arrow-up-right-from-square"></i>
                            </button>
                            <button
                                className="btn btn-secondary btn-md"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="fa-brands fa-google-play"></i>
                                <span className="w-3/4">Android</span>
                                <i className="fa-light fa-arrow-up-right-from-square"></i>
                            </button>
                        </div>
                        <div className="flex align-center !order-first md:!order-last">
                            <p className="body-txtitalic--smallest m-0">
                                To get started, first download the app on your phone from your app
                                store. This is the most secure method.
                            </p>
                        </div>
                    </div>
                    <button className="btn btn-primary btn-md mt-4 mb-3" type="button">
                        Set up Commonspace MFA
                    </button>
                </div>
                {/*<div className="dashboard-wrapper mt-4">*/}
                {/*    <form>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col">*/}
                {/*                <h5 className="header-small">Backup Codes</h5>*/}
                {/*            </div>*/}
                {/*            <div className="col">*/}
                {/*                <p className="body-text--small text-end">Save your Backup Codes in a secure place. <a className="gradient-color-txt body-txtitalic--small" href="">Learn more.</a></p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col flex justify-center items-center my-4">*/}
                {/*                <button className="btn btn-secondary btn-md btn-disable" disabled type="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*                    <i className="fa-light fa-binary"></i><span>View</span><i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*                </button>*/}
                {/*                <button className="btn btn-secondary btn-md ms-5 btn-disable" disabled type="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*                    <i className="fa-light fa-file-binary"></i><span>Download</span><i className="fa-light fa-download"></i></i>*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*        <div className="col flex items-center">*/}
                {/*            <p className="body-txtitalic--smallest m-0">You have not generated any Backup Codes yet.</p>*/}
                {/*        </div>*/}
                {/*        <button className="btn btn-primary btn-md mt-4 mb-3 btn-warning" type="button">Set up Commonspace MFA</button>*/}
                {/*    </form>*/}
                {/*</div>*/}
                <div className="dashboard-wrapper mt-4 pt-4">
                    <div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
                        <div className="col-span-12 md:col-span-6">
                            <h5 className="header-small">Backup Codes</h5>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <p className="body-text--small text-end">
                                Save your Backup Codes in a secure place.{' '}
                                <a className="gradient-color-txt body-txtitalic--small" href="">
                                    Learn more.
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row mt-4 items-center gap-x-3">
                        <>
                            {backupcode && (
                                <Alert
                                    key={alert.variant}
                                    variant={alert.variant}
                                    className={
                                        'custom-alert ' +
                                        (alert.variant === 'danger'
                                            ? 'custom-alert-danger'
                                            : 'custom-alert-success')
                                    }
                                >
                                    {alert.message}
                                </Alert>
                            )}
                        </>
                        <div className="flex justify-center align-center !order-last md:!order-first grid grid-cols-1 xsm:grid-cols-2 grid-flow-row gap-x-1 !mt-3 md:!mt-0">
                            <button
                                className="btn btn-secondary btn-md"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={getBackupCode}
                            >
                                <i className="fa-light fa-binary"></i>
                                <span className="w-3/4">View</span>
                                <i className="fa-light fa-arrow-up-right-from-square"></i>
                            </button>
                            <button
                                className="btn btn-secondary btn-md"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={downloadBackupCode}
                            >
                                <i className="fa-light fa-file-binary"></i>
                                <span className="w-3/4">Download</span>
                                <i className="fa-light fa-download"></i>
                            </button>
                        </div>
                        <div className="flex align-center !order-first md:!order-last">
                            <p className="body-txtitalic--smallest m-0">
                                You have not generated any Backup Codes yet.
                            </p>
                        </div>
                    </div>
                    <button className="btn btn-secondary btn-md mt-4 mb-3" type="button">
                        Set up Commonspace MFA
                    </button>
                </div>
            </div>
        </>
    );
};

export default memo(Manage2FAContent);
