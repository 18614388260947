import { memo, useState, useEffect } from 'react';
import { Input} from 'antd';

const FormFieldStacked = (parentProps  ) => {
    const { type, formType, maxLength, placeholder, onChange, value } = parentProps;
	const [inputValue, setInputValue] = useState(value);
	
	useEffect(() => {
		setInputValue(value);
	})
	
	const onInputChange = e => {
		
		setInputValue(e.target.value);
		onChange(e.target.value);
	  };
	  return (
		<>
		{formType === 'input' ? (
			<input
			type={type}				
			value={inputValue} onChange={onInputChange} 
			className="block h-10 bg-[#ffffff80] w-full rounded-none border-0 py-1.5 px-3 text-gray-900 placeholder:text-lg text-lg placeholder:font-['notoSansItalic']  placeholder:text-gray-400 focus:outline-none focus:border-b focus:border-fuchsia-500"
			placeholder={placeholder}
		  />
		//   <Input 
		//   defaultValue={value}
		//   type={type}
		//   placeholder={placeholder}
		  
		//   className="block h-10 bg-[#ffffff80] w-full rounded-none border-0 py-1.5 px-3 text-gray-900 placeholder:text-lg font-['notoSansItalic']  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		//    value={inputValue} onChange={onInputChange} />
		): formType === 'textarea'?  (
			<>
			<textarea
				rows={1}
				maxLength={maxLength}
				placeholder={placeholder}
				className="block w-full rounded-none border-0 bg-[#ffffff80] py-1.5 px-3 text-gray-900 placeholder:text-lg text-lg placeholder:font-['notoSansItalic']  placeholder:text-gray-400 focus:outline-none focus:border-b focus:border-fuchsia-500"
				value={inputValue} onChange={onInputChange}
				/>
			</>
		) : (
			<></>
		)}
		</>
	  );
    
    
}

export default memo(FormFieldStacked);