import { memo, useEffect, useState, useCallback } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SpaceItem from './SpaceItem';
import routes from '../../../routes';
import { getMySpaces } from '../../../services/space';

import default_space_bg from '../../../assets/images/profile_spaces_bg.png';
import { getSitewideLink } from '../../../utils/sitewideLinks';

const SpacesContent = () => {
	const currentLocation = useLocation();
	const bgStyle = {
		backgroundImage: 'url(' + default_space_bg + ')'
	};
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [initialLoad, setInitialLoad] = useState(true);
	const [activeTab, setActiveTab] = useState('spaces');
	const [allSpaces, setAllSpaces] = useState(null);
	const [expiredSpaces, setExpiredSpaces] = useState(null);
	const [ownSpaces, setOwnSpaces] = useState(null);
	const [spacesTotalCount, setSpacesTotalCount] = useState(null);
	const [activeCount, setActiveCount] = useState(null);
	const [expiredCount, setExpiredCount] = useState(null);
	const [ownCount, setOwnCount] = useState(null);

	const renderManageSpaceUrl = (space) => {
		const url = routes.manageSpaceMembership();
		return url.replace(':space_username', space.username);
	};

	const getSpaces = useCallback(async () => {
		try {
			const params = {
				user_id: authUserInfo.id
			};

			const response = await getMySpaces(params);
			if (response && response.result) {
				setAllSpaces(response.all_spaces);
				setExpiredSpaces(response.spaces_expired_memberships);
				setOwnSpaces(response.own_spaces);

				const a = response.all_spaces ? response.all_spaces.length : 0;
				const b = response.spaces_expired_memberships ? response.spaces_expired_memberships.length : 0;
				const c = response.own_spaces ? response.own_spaces.length : 0;
				const total = a + b + c;
				setSpacesTotalCount(total);
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo]);

	useEffect(() => {
		if (authUserInfo && initialLoad) {
			getSpaces().then((r) => {});
			setInitialLoad(false);
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, currentLocation.pathname, initialLoad]);

	return (
		<>
			<div className="dashboard-wrapper ">
				<div className="grid grid-cols-12 md:gap-8">
					<div className="col-span-12">
						<h3 className="header-medium">Your Spaces</h3>
					</div>
					<div className="col-span-12">
						<p className="body-txtitalic--large text-end">
							{spacesTotalCount} Spaces
						</p>
					</div>
				</div>
				<p className="pt-3 body-txtitalic--small">
					Your Spaces are the communities that you’re a member of. You can access them and
					manage your memberships here. Each Space is operated and managed by its own
					creators and team. All content, benefits, and redemptions, can be accessed and
					managed within each Space. {getSitewideLink('learn_more_spaces')}.
					{/*<a className="gradient-color-txt" href="">*/}
					{/*	Learn more about Spaces and how they work.*/}
					{/*</a>*/}
				</p>
				<ul className="nav nav-pills mt-5 grid grid-flow-row grid-rows-3 grid-cols-1 md:grid-cols-3 md:grid-rows-1">
					<li className="nav-item !p-0 col-span-1" role="presentation">
						<Button
							className={'!w-full nav-link ' + (activeTab === 'spaces' ? 'active' : '')}
							onClick={() => {
								setActiveTab('spaces');
							}}
						>
							{allSpaces ? allSpaces.length : 0} Active
						</Button>
					</li>
					<li className="nav-item !p-0 col-span-1" role="presentation">
						<Button
							className={'!w-full nav-link ' + (activeTab === 'expired' ? 'active' : '')}
							onClick={() => {
								setActiveTab('expired');
							}}
						>
							{expiredSpaces ? expiredSpaces.length : 0} Inactive
						</Button>
					</li>
					<li className="nav-item !p-0 col-span-1" role="presentation">
						<Button
							className={'!w-full nav-link ' + (activeTab === 'manage' ? 'active' : '')}
							onClick={() => {
								setActiveTab('manage');
							}}
						>
							{ownSpaces ? ownSpaces.length : 0} Manage
						</Button>
					</li>
				</ul>
			</div>
			<div>
				{authUserInfo && (
					<>
						{activeTab === 'spaces' && (
							<>
								{allSpaces &&
									allSpaces.map((data, index) => (
										<SpaceItem
											key={index}
											data={data}
											type={'active'}
											currentUser={authUserInfo}
										></SpaceItem>
									))}
							</>
						)}
						{activeTab === 'expired' && (
							<>
								{expiredSpaces &&
									expiredSpaces.map((data, index) => (
										<SpaceItem
											key={index}
											data={data}
											type={'expired'}
											currentUser={authUserInfo}
										></SpaceItem>
									))}
							</>
						)}
						{activeTab === 'manage' && (
							<>
								<div className="dashboard-wrapper mt-4">
									<div className="grid grid-cols-12 gap-3 items-center">
										<div className="xsm:col-span-10 lg:col-span-9">
											<p className="body-text--small m-0">
												{ownSpaces ? ownSpaces.length : 0} Spaces owned,{' '}
												{ownSpaces ? ownSpaces.length : 0} Spaces managed
											</p>
										</div>
										<div className="xsm:col-span-2 lg:col-span-3">
											<Link
												className="btn btn-secondary btn-md"
												to={routes.createSpaceGettingStarted()}
											>
												<i className="xsm:hidden lg:block fa-light fa-solar-system"></i>
												<div className="xsm:hidden lg:inline w-3/4 text-center">
													<span className="w-3/4">Create New</span>
												</div>
												<i className="xsm:block lg:hidden fa-light fa-plus "></i>
												<i className="xsm:hidden lg:block fa-light fa-arrow-up-right-from-square"></i>
											</Link>
										</div>
									</div>
								</div>
								{ownSpaces &&
									ownSpaces.map((data, index) => (
										<SpaceItem
											key={index}
											data={data}
											type={'manage'}
											currentUser={authUserInfo}
										></SpaceItem>
									))}
							</>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default memo(SpacesContent);
