import { memo, useEffect } from 'react';
import routes from "../../routes";

const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = 'Commonspace Spaces';
    }, []);

    return (
        <main>
            <div className="container mx-auto min-h-screen body-text--small pt-10 pb-10">
                <p className="body-text--big font-bold mt-5 mb-5">
                    Privacy Policy
                </p>

                <p className="mt-5 mb-5">
                    <p className="body-text--small mt-5 mb-5">
                        Last updated Date: June 1, 2024.
                    </p>
                </p>

                <p>
                    At Commonspace Platforms, Inc., ("Commonspace", "we", "our" and "us") we are committed to protecting your privacy, and support a
                    general policy of openness about how we collect, use and disclose your personal information.
                </p>
                <br/>
                <p>
                    The purpose of this Privacy Policy is to inform you about Commonspace’s practices relating to the collection, use and disclosure
                    of personal information that may be provided through access to or use of our websites, including the website located at the
                    domain common.space and all underlying subdomains (collectively referred to as the "Website") as well as our services and
                    related products (collectively referred to as the "Services"), or that may otherwise be collected by us. By using our
                    Services or Website, you consent to the collection, use and disclosure of your personal information (as defined below) in
                    accordance with the following terms and conditions. Moreover, all users of our Services or Website are subject to our
                    <a className='font-bold' href={routes.termsOfUse()} target="_blank"> Terms of Service</a>.
                </p>
                <br/>
                <p>
                    This Privacy Policy also explains how you can contact us if you have a question about, want to make a change to or delete any personal information that Commonspace may be holding about you. We strongly recommend that you take the time to read this Privacy Policy and retain it for future reference.
                </p>

                <div className="flex">
                    <ol className="list-decimal ml-5 gap-y-5">
                        <li className="mt-5">
                            Personal Information
                            <p className="mt-5 -ml-5">
                                For the purpose of this Privacy Policy, "personal information" means information about an identifiable individual.
                            </p>
                        </li>
                        <li className="mt-5">
                            What Personal Information We Collect
                            <p className="mt-5 -ml-5">
                                The personal information we may collect in the course of you accessing or using the Website or the Services (including through your initial registration for an account and the subsequent administration of that account) may include, but is not limited to, the following:
                                <ol className="list-lower-alpha list-outside ml-10 mt-5">
                                    <li>
                                        Contact information (such as name, address, postal code, e-mail address and telephone number);
                                    </li>
                                    <li>
                                        Social media profile information (such as username and other profile data);
                                    </li>
                                    <li>
                                        Financial and billing information (such as credit card number and expiration date, and other financial information to facilitate payment);
                                    </li>
                                    <li>
                                        Demographic information (such as gender, age and occupation) and information about individual preferences; and
                                    </li>
                                    <li>
                                        Personal information, including the information you may transmit to us or authorize us to obtain from you or a third party in connection with the Services ("Customer Data").
                                    </li>
                                </ol>
                            </p>
                        </li>
                        <li className="mt-5">
                            How We Use Your Personal Information
                            <p className="mt-5 -ml-5">
                                We use your personal information to:
                                <ol className="list-lower-alpha list-outside ml-10 mt-5 mb-5">
                                    <li>
                                        Provide you with personalized Services and a personalized experience on our Website and interactive communication;
                                    </li>
                                    <li>
                                        Provide you with timely and reliable Services;
                                    </li>
                                    <li>
                                        Do all the things necessary to administer those Services and the Website;
                                    </li>
                                    <li>
                                        Research, develop, manage, protect and improve those Services and the Website;
                                    </li>
                                    <li>
                                        Advise you about new products and services that may be of interest to you;
                                    </li>
                                    <li>
                                        Develop and maintain our relationship with you and communicate with you;
                                    </li>
                                    <li>
                                        Bill you for our Services; and
                                    </li>
                                    <li>
                                        Collect outstanding debts.
                                    </li>
                                </ol>
                                Notwithstanding anything else in this Privacy Policy, we will not review, share, distribute, or reference any Customer Data except as provided for in the terms of use for our Website and Services, or as may be required by law. We may access, store and use Customer Data only for the purpose of providing the Services to you, including in connection with customer support matters.
                            </p>
                        </li>
                        <li className="mt-5">
                            Knowledge and Consent
                            <p className="mt-5 -ml-5">
                                Commonspace collects personal information about you, from you, or from third parties you authorize us to collect such information from, only when you voluntarily provide it or authorize us to collect it. Typically, we will seek consent for the use or disclosure of your personal information at the time of collection. In certain circumstances, consent may be sought after the information has been collected but before use (for example, when we want to use information for a purpose not previously identified).
                            </p>
                            <p className="mt-5 -ml-5">
                                The form of consent that we seek, including whether it is expressed or implied, will largely depend on the sensitivity of the personal information and the reasonable expectations of the individual in the circumstances.
                            </p>
                            <p className="mt-5 -ml-5">
                                You may withdraw consent at any time, subject to legal or contractual restrictions and reasonable notice. If you wish to withdraw your consent at any time, please contact us using the contact information provided in the "Contacting Us" section of this Privacy Policy. We will inform you of the implications of withdrawing consent.
                            </p>
                            <p className="mt-5 -ml-5">
                                We will not, as a condition of the supply of a product or service, require you to consent to the collection, use or disclosure of information beyond that required to fulfill the explicitly specified and legitimate purposes for which the information is being provided.
                            </p>
                        </li>
                        <li className="mt-5">
                            What is Our Legal Basis for Using Your Personal Information?
                            <p className="mt-5 -ml-5">
                                If you are accessing our Services in Europe or in California, then we are required to ensure that we only process your personal information where we have a legal basis to do so. When we process your personal information, we will only do so where at least one of the following applies:
                                <ol className="list-lower-alpha list-outside ml-10 mt-5 mb-5">
                                    <li>
                                        We need to use your personal information to perform our responsibilities under our contract with you (e.g. providing our Services).
                                    </li>
                                    <li>
                                        It is in our legitimate interests to use your personal information in certain circumstances. For example, it is in our legitimate interests to use your personal information in connection with our Services; to improve our Services; to ensure that our Services are secure; to, tell you about changes to our Services; to tell you about new services or products we are offering; for business reasons (for example to develop our business or if we are thinking of selling our business); to tell you about offers or promotions we are running; for operational purposes.
                                    </li>
                                    <li>
                                        Sometimes we may ask for your consent to use certain types of personal information (e.g. by asking you to tick an online box). In these circumstances, your consent will be our legal basis for processing your personal information. If you have provided consent, you may withdraw it at any time. You may do this where we give you the opportunity to do so, or by contacting us using the contact information provided in the "Contacting Us" section of this Privacy Policy. For example, we may ask for your consent to pass your personal information to third parties who may send you information about products, services or offers you may be interested in.
                                    </li>
                                </ol>
                            </p>
                        </li>
                        <li className="mt-5">
                            Use or Disclosure for Marketing Purposes
                            <p className="mt-5 -ml-5">
                                If you have not opted out of receiving marketing materials, we may also use (but will not disclose) your personal information to promote and market additional goods, services and special offers from us, our affiliates and/or our business associates, including by means of direct marketing. We may also disclose your name and mailing address to these affiliates and/or third party business associates so that they can send you promotional materials directly.
                            </p>
                            <p className="mt-5 -ml-5">
                                You can choose not to allow us to use or disclose your personal information for direct marketing purposes by contacting us as outlined below (see "Contacting Us").
                            </p>
                        </li>
                        <li className="mt-5">
                            How We Protect Your Personal Information
                            <p className="mt-5 -ml-5">
                                Commonspace makes commercially reasonable efforts to ensure that personal information collected in the course of you accessing or using the Website or the Services is protected against loss, theft and unauthorized access. This protection applies in relation to information stored in both electronic and hard copy form. Access to such personal information is restricted to selected employees or representatives.
                            </p>
                        </li>
                        <li className="mt-5">
                            We May Transfer Your Personal Data Outside the European Economic Area
                            <p className="mt-5 mb-5 -ml-5">
                                Whenever we transfer your personal information out of the EEA, we will comply with applicable data protection law. Some of the mechanisms we may choose to use when undertaking an international transfer are:
                                <ol className="list-lower-alpha list-outside ml-10 mt-5 mb-5">
                                    <li>
                                        The transfer of your personal data to a country that has officially been deemed to provide an adequate level of protection for personal data by the European Commission.
                                    </li>
                                    <li>
                                        We may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe (called the “EU Model Clauses”).
                                    </li>
                                    <li>
                                        Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. If the provider is not EU-US Privacy Shield certified, we may use the EU Model Clauses.
                                    </li>
                                </ol>
                                Many of our external third-party providers are based outside the EEA, so their processing of your personal data will involve a transfer of data outside the EEA.
                            </p>
                        </li>
                        <li className="mt-5">
                            Links to Third Party Sites
                            <p className="mt-5 -ml-5">
                                This website may offer links to other third party websites. You should be aware that operators of linked websites may also collect your personal information (including information generated through the use of cookies) when you link to their websites. Commonspace is not responsible for how such third parties collect, use or disclose your personal information, so it is important to familiarize yourself with their privacy policies before providing them with your personal information.
                            </p>
                        </li>
                        <li className="mt-5">
                            Retention and Storage of Your Personal Information
                            <p className="mt-5 -ml-5">
                                Commonspace retains your personal information for as long as necessary to fulfill the purpose(s) for which it was collected and to comply with applicable laws, and your consent to such purposes(s) remains valid after termination of our relationship with you.
                            </p>
                            <p className="mt-5 -ml-5">
                                For the purposes set out in this Privacy Policy, we or one of our affiliates or an unaffiliated service provider may process and store your personal information outside of the country in which you reside, and under the laws of those other jurisdictions, in certain circumstances courts, law enforcement agencies, regulatory agencies or security authorities in those other provinces or foreign jurisdictions may be entitled to access your personal information.
                            </p>
                        </li>
                        <li className="mt-5">
                            Changes to this Privacy Policy
                            <p className="mt-5 -ml-5">
                                Commonspace reserves the right to modify this Privacy Policy at any time without notice to reflect changes in legal or regulatory obligations or changes in the manner in which we deal with personal information. The Privacy Policy posted at any time or updated from time to time via this website shall be deemed to be the Privacy Policy then in effect.
                            </p>
                        </li>
                        <li className="mt-5">
                            Correcting or Updating Your Information
                            <p className="mt-5 -ml-5">
                                You can help us maintain the accuracy of your personal information by updating the information in your own Commonspace account or by notifying us of any such changes when you are not able to make changes yourself. You may contact Commonspace to request access to or correction or update of your personal information using the contact information provided in the "Contacting Us" section of this Privacy Policy.
                            </p>
                        </li>
                        <li className="mt-5">
                            Contacting Us
                            <p className="mt-5 -ml-5">
                                In the event that you have any questions about Commonspace’s Privacy Policy or if you need to reach Commonspace for any reason, you may contact us at <a className="text-blue-500 hover:underline" href="mailto:support@common.space">support@common.space</a>.
                            </p>
                        </li>
                        <li className="mt-5">
                            Notice for California Users
                            <p className="mt-5 mb-5 -ml-5">
                                The California Consumer Privacy Act of 2018 (“CCPA”) requires businesses like Commonspace to provide the following additional information to you:
                                <ol className="list-lower-alpha list-outside ml-10 mt-5 mb-5">
                                    <li>
                                        This Privacy Policy lists under Section 2 “What Personal Information We Collect” the personal information categories which Commonspace may collect.
                                    </li>
                                    <li>
                                        Commonspace does not sell your personal information.
                                    </li>
                                    <li>
                                        This Privacy Policy lists under Section 3 “How We Use Your Personal Information” the ways Commonspace may use your personal information.
                                    </li>
                                    <li>
                                        Do Not Track.
                                        <ol className="list-lower-roman list-outside ml-5">
                                            <li>
                                                Commonspace does not track its users over time and across third party websites to provide targeted advertising and therefore does not respond to Do Not Track (DNT) signals.
                                            </li>
                                            <li>
                                                Commonspace uses certain third party cookies in its operations, and such third parties will obtain information about the fact that a web browser visited a specific Commonspace Website from a certain IP address. Third parties cannot collect any personal information from Commonspace Website or Services unless you provide it to them directly.
                                            </li>
                                            <li>
                                                However, some third party sites do keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you. If you are visiting such sites, certain sites and web browsers enable you to set the DNT signal so that third parties (particularly advertisers) know you do not want to be tracked.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
        </main>
    );
};

export default memo(PrivacyPolicy);