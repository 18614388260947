import { memo } from 'react';
import ReactPlayer from 'react-player/lazy';
import moment from 'moment/moment';

const MemberBenefits = (parentProps) => {
	const { data, user, spaceData } = parentProps;

	let assetType = 'image';
	if (data.video) {
		assetType = 'video';
	}

	return (
		<>
			{data && data.id ? (
				// <div className="col-4">
					<div className="card-updates">
						{assetType === 'image' ? (
							<img src={data.photo} />
						) : (
							<ReactPlayer
								playing={true}
								loop={true}
								controls={false}
								muted={true}
								className="videoPreview"
								url={data.video}
							/>
						)}
						<div className="flex items-center justify-between mt-4">
							<p className="body-text--small">
								{moment(data.created_on).format('LL')}
							</p>
							{/*<p className="update-tag color-lightest">Blog</p>*/}
						</div>
						<h5 className="header-title color-reg mt-4">{data.name}</h5>
						<p className="body-text--smallest">{data.summary}</p>
						{!user ? (
							<>
								<a className="btn btn-secondary btn-md mt-5">
									Login to&nbsp;
									<i className="fa-solid fa-unlock"></i>
									&nbsp;Unlock
								</a>
							</>
						) : (
							<>
								<a className="btn btn-secondary btn-md mt-5">Read More</a>
							</>
						)}
					</div>
				// </div>
			) : (
				<>
					{spaceData && spaceData.viewing_user_is_owner && (
						<>
							{/*<div className="col-4">*/}
								<div className="card-updates space-placeholder flex items-center justify-center">
									<h6 className="header-title text-center">
										Create Custom Benefits for Members <br />
										from your Dashboard
									</h6>
								</div>
							{/*</div>*/}
						</>
					)}
				</>
			)}
		</>
	);
};

export default memo(MemberBenefits);
