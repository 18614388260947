import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpaceMembership } from '../../services/space';
import default_items_img from '../../assets/images/form_background.png';
import routes from '../../routes';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import { Button } from 'antd';
import {getCommunityBtnText, getDetailsGift, getDetailsMonthlyPrice, getDetailsPrice} from "../../components/commons/helpers/MembershipItemHelpers";
import {MembershipItemUtils} from "../../utils/MembershipItemUtils";
import {checkMembershipItemStatus} from "../../services/payment_method";

const SpaceBuyMembership = (props) => {
	const { hasGradient } = props;
	const currentLocation = useLocation();
	let space_username = null;
	const [searchParams, setSearchParams] = useSearchParams();
	const membershipId = searchParams.get('id');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [submitted, setSubmitted] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [membershipDetails, setMembershipDetails] = useState(null);
	const [isMembershipLoaded, setMembershipLoaded] = useState(false);
	const [collectionAssetType, setCollectionAssetType] = useState('image');
	const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
	const [spaceName, setSpaceName] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [status, setStatus] = useState('');

	const path = currentLocation.pathname;
	if (path && path.startsWith('/@') && path.length > 3) {
		const clean_path = path.replace('/@', '');
		const path_exp = clean_path.split('/');
		space_username = path_exp[0];
	}

	const scrollToClick = () => {
		const element = document.getElementById('details-description');
		element.scrollIntoView({ behavior: 'smooth' });
	}

	const getMembership = useCallback(
		async (space_id, membership_id) => {
			if (!membership_id) return;

			const data = {
				space_id: space_id,
				membership_id: membership_id
			}

			if (authUserInfo) {
				data['viewing_user_id'] = authUserInfo.id;
			}

			const response = await getSpaceMembership(data);
			if (response && response.result) {
				const membershipData = response.membership;
				// setIsPaid(membershipData.is_paid);
				setMembershipDetails(membershipData);
				setMembershipLoaded(true);
				if (membershipData.photo || membershipData.video) {
					if (membershipData.photo) {
						const bg = {
							backgroundImage: 'url(' + membershipData.photo + ')',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover'
						};
						setCollectionAssetBGImage(bg);
						setCollectionAssetType('image');
					}
					if (membershipData.video) {
						const bg = {
							backgroundImage: 'none'
						};
						setCollectionAssetBGImage(bg);
						setCollectionAssetType('video');
					}
				} else {
					const bg = {
						backgroundImage: 'url(' + default_items_img + ')',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					};
					setCollectionAssetBGImage(bg);
					setCollectionAssetType('image');
				}
			}
		},
		[authUserInfo]
	);

	const checkStatus = useCallback(async (space_id, membership_id) => {
		if (!membership_id) return;
		const data = {
			space_id: space_id,
			membership_id: membership_id
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response && response.result) {
			const transaction = response.stripe_transaction;
			const sessionStatus = response.session_status;
			if (transaction) {
				if (sessionStatus !== 'open') {
					setStatus(transaction.status);
				}
				setIsDisabled(transaction.status === 'active' || transaction.status === 'expired');
			}
		}
	},[authUserInfo]);

	const proceedMembershipPayment = useCallback(async () => {
		setSubmitted(true);
		if (MembershipItemUtils.isOAuth(loading, authUserInfo)){
			if (space_username) {
				let url = routes.spaceMembershipPaymentMethod();
				const fin_url =
					url.replace(':space_username', '@' + space_username) + '?id=' + membershipId;
				navigate(fin_url);
				setSubmitted(false);
			}
		} else {
			window.location.replace(routes.login());
			setSubmitted(false);
		}

	}, []);

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + space_username;
		window.location.replace(url);
	}, [space_username]);

	useEffect(() => {
		if (space_username) {
			if (spaceInfoLoading && spaceInfoLoading === 'done' && !isMembershipLoaded) {
				if (spaceInfo && spaceInfo.id) {
					getMembership(spaceInfo.id, membershipId).then((r) => {});
					setSpaceName(spaceInfo.name);
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(space_username));
				}
				if (spaceInfoLoading === 'failed') {
					navigate(routes.createSpace());
				}
			}
		}

		if (membershipDetails != null) {
			if (authUserInfo != null && !loadMembershipOnce) {
				setLoadMembershipOnce(true);
				getMembership(spaceInfo.id, membershipId).then((r) => {});
				checkStatus(spaceInfo.id, membershipId).then(r =>{} );
			}
			if (authUserInfo != null && !isDisabled) {
				if (membershipDetails.creator === authUserInfo.id) {
					setIsDisabled(true);
				}
			}
		}
	}, [
		currentLocation.pathname,
		authUserInfo,
		loading,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		membershipId,
		membershipDetails,
		isMembershipLoaded,
		loadMembershipOnce,
		isDisabled,
		status
	]);

	return (
		<>
			<div className="container join-space">
				<>
					{!membershipDetails ? (
						<div className="loading-items w-full">
							<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
						</div>
					) : (
						<>
							<div className="grid grid-cols-12 gap-4 py-5">
								<div className="col-span-12 lg:col-span-6 flex justify-center lg:items-start pt-5">
									<div className="asset-preview" style={collectionAssetBGImage}>
										{collectionAssetType && collectionAssetType === 'video' && (
											<video autoPlay muted loop className="membership-video">
												<source
													src={membershipDetails.video}
													type="video/mp4"
												/>
											</video>
										)}
									</div>
								</div>
								<div className="membership-details col-span-12 lg:col-span-6 grid items-center justify-center break-words">
									<div className="mt-4 lg:mt-0 w-full">
										<h2 className="header-large">
											{membershipDetails ? membershipDetails.name : ''}
										</h2>
									</div>
									<div className="mt-4 lg:mt-0 w-full">
										<p className="body-text--big">
											{membershipDetails ? membershipDetails.listing_text : ''}
										</p>
									</div>
									<div className="mt-4 lg:mt-0 w-full">
										<i className="body-text--big">
											{membershipDetails ? membershipDetails.summary : ''}
										</i>
									</div>

									<div className="mt-4 lg:mt-0 w-full">
										<div className="grid lg:grid-cols-6 md:grid-cols-1 gap-4 ">
											<div className="space-buy-details lg:col-span-3">
												<h5 className="header-small">{getDetailsPrice(membershipDetails)}</h5>
												<p className="body-txtitalic--big color-light">
													{getDetailsMonthlyPrice(true, membershipDetails)}
												</p>
												{membershipDetails && (
													<>
														<Button
															className="btn btn-md btn-primary"
															disabled={submitted || isDisabled}
															onClick={proceedMembershipPayment}
														>
															{submitted && (
																<i className="fa-solid fa-spinner fa-spin me-3"></i>
															)}
															{getCommunityBtnText(membershipDetails, true, false, status)}
														</Button>
													</>
												)}
											</div>
											<div className="space-buy-details lg:col-span-3">
												<h5 className="header-small">
													<i className="fa-regular fa-cubes"/>{' '}
													{getDetailsGift(true, membershipDetails)}
												</h5>
												<h5 className="header-small mt-4">
													<i className="fa-regular fa-gift"/>{' '}
													{membershipDetails.benefits_count} Benefit{membershipDetails.benefits_count > 1? 's': ''}
												</h5>
												<a className="btn btn-md btn-secondary mt-5" onClick={scrollToClick}>
													Membership Details
												</a>
											</div>
										</div>
									</div>

									<ul className="list-socials pt-4">
										{spaceInfo.social_link && (
											<li>
												<i className="fa-brands fa-twitter isDisabled"></i>
											</li>
										)}
										{spaceInfo.social_link && (
											<li>
												<i className="fa-brands fa-instagram isDisabled"></i>
											</li>
										)}
										{spaceInfo.social_link && (
											<li>
												<i className="fa-brands fa-facebook isDisabled"></i>
											</li>
										)}
										{spaceInfo.social_link && (
											<li>
												<i className="fa-brands fa-tiktok isDisabled"></i>
											</li>
										)}
										{spaceInfo.social_link && (
											<li>
												<i className="fa-brands fa-youtube isDisabled"></i>
											</li>
										)}
										{spaceInfo.social_link && (
											<li>
												<i className="fa-brands fa-linkedin isDisabled"></i>
											</li>
										)}
										{spaceInfo.social_link && (
											<li>
												<i className="fa-brands fa-discord isDisabled"></i>
											</li>
										)}
										{spaceInfo.social_link && (
											<li>
												<i className="fa-brands fa-twitch isDisabled"></i>
											</li>
										)}
										{spaceInfo.website && (
											<li>
												<a href={spaceInfo.website} target="_blank">
													<i className="fa-regular fa-globe"></i>
												</a>
											</li>
										)}
									</ul>
								</div>
							</div>
							{/*)}*/}
						</>
					)}
				</>
			</div>
			<div className="w-full join-space-details my-5 pt-5">
				<div className="container">
					{/*{!fetchingCollectionData && (*/}
					<>
						<div id="details-description">
							<p className="body-text--reg">
								{/*{collectionData && collectionData.openSea*/}
								{/*    ? collectionData.openSea.description*/}
								{/*    : ''}*/}
								{membershipDetails && membershipDetails.description &&
									membershipDetails.description
								}
							</p>
						</div>

						{ membershipDetails && membershipDetails.benefits_count > 0 &&
							<>
								<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
									<h3 className="header-medium">Benefits</h3>
									<div className="body-text--small color-reg">
										This is a membership for{' '}
										<Link
											onClick={goToSpace}
											className={
												hasGradient
													? 'gradient-color-txt'
													: 'basic-gradient-color-txt'
											}
										>
											<i className="fa-solid fa-solar-system"/> {spaceName}
										</Link>
									</div>
								</div>
								<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
									<span
										className={
											'body-text--reg ' +
											(hasGradient
												? 'gradient-color-txt'
												: 'basic-gradient-color-txt')
										}
									>
										Member Access
									</span>
									<p className="body-text--small color-reg">
										Members Only access to{' '}
										<Link
											onClick={goToSpace}
											className={
												hasGradient
													? 'gradient-color-txt'
													: 'basic-gradient-color-txt'
											}
										>
											<i className="fa-solid fa-solar-system"/> {spaceName}
										</Link>
									</p>
								</div>
								<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
									<span
										className={
											'body-text--reg ' +
											(hasGradient
												? 'gradient-color-txt'
												: 'basic-gradient-color-txt')
										}
									>
										Partner Perks
									</span>
									<p className="body-text--small color-reg">
										Partner discounts from my favorite shops and brands!
									</p>
								</div>
								<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
									<span
										className={
											'body-text--reg ' +
											(hasGradient
												? 'gradient-color-txt'
												: 'basic-gradient-color-txt')
										}
									>
										Partner Giveaways
									</span>
									<p className="body-text--small color-reg">
										Monthly giveaways from partner brands.
									</p>
								</div>
								<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
									<span
										className={
											'body-text--reg ' +
											(hasGradient
												? 'gradient-color-txt'
												: 'basic-gradient-color-txt')
										}
									>
										Community Voting
									</span>
									<p className="body-text--small color-reg">
										Vote on new upcoming video topics in the Community Channel!
									</p>
								</div>
								<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
									<span
										className={
											'body-text--reg ' +
											(hasGradient
												? 'gradient-color-txt'
												: 'basic-gradient-color-txt')
										}
									>
										1,000 Community Points
									</span>
									<p className="body-text--small color-reg">
										Add 1,000 community points to your account and use them for
										unlockable benefits and limited items!{' '}
										<span className="color-light body-txtitalic--small">
												(1 redemption)
										</span>
									</p>
								</div>
							</>
						}

						{/*<div className="row mt-5">*/}
						{/*	<div className="col-6">*/}
						{/*		<span*/}
						{/*			className={*/}
						{/*				'body-text--large ' +*/}
						{/*				(hasGradient*/}
						{/*					? 'gradient-color-txt'*/}
						{/*					: 'basic-gradient-color-txt')*/}
						{/*			}*/}
						{/*		>*/}
						{/*			Traits*/}
						{/*		</span>*/}
						{/*	</div>*/}
						{/*	<div className="col-6">*/}
						{/*		<p className="body-text--small color-reg">*/}
						{/*			This is a Membership for Related Space and platform details*/}
						{/*		</p>*/}
						{/*	</div>*/}
						{/*</div>*/}
						{/*<div className="row mt-5">*/}
						{/*	<div className="col-6">*/}
						{/*		<span*/}
						{/*			className={*/}
						{/*				'body-text--large ' +*/}
						{/*				(hasGradient*/}
						{/*					? 'gradient-color-txt'*/}
						{/*					: 'basic-gradient-color-txt')*/}
						{/*			}*/}
						{/*		>*/}
						{/*			Technical Details*/}
						{/*		</span>*/}
						{/*	</div>*/}
						{/*	<div className="col-6">*/}
						{/*		<p className="body-text--small color-reg">*/}
						{/*			Related Space and platform details*/}
						{/*		</p>*/}
						{/*	</div>*/}
						{/*</div>*/}
					</>
					{/*)}*/}
				</div>
			</div>
		</>
	);
};

export default memo(SpaceBuyMembership);
