import { getLocalStorage } from './storage';
import { Link } from 'react-router-dom';

export const getSitewideLink = (link_slug, element_class, isIcon = null) => {
	if (getLocalStorage('sitewide_links')) {
		const links = JSON.parse(getLocalStorage('sitewide_links'));

		if (links && links.length > 0 && link_slug) {
			const index = links.findIndex((x) => x.slug === link_slug);
			if (index >= 0) {
				const dataLink = links[index];
				let classNames = 'gradient-color-txt';
				if (element_class) {
					classNames = element_class;
				}

				if (isIcon) {
					return (
						<>
							{(dataLink.url) && (
								<li>
									<a
										className={classNames}
										href={dataLink.url ? dataLink.url : '#'}
										target={dataLink.open_new_tab ? '_blank' : ''}
									>
										{dataLink.label ? dataLink.label : ''}
										<i className={dataLink.icon}></i>
									</a>
								</li>
							)}
						</>
					);
				} else {
					return (
						<>
							<a
								className={classNames}
								href={dataLink.url ? dataLink.url : '#'}
								target={dataLink.open_new_tab ? '_blank' : ''}
							>
								{dataLink.label ? dataLink.label : ''}
								<i className={dataLink.icon}></i>
							</a>
						</>
					);
				}
			}
		}
	}

	return (
		<>
			<span className="gradient-color-txt">{link_slug}</span>
		</>
	);
};
