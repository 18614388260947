import { memo, useCallback, useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import Alert from 'react-bootstrap/Alert';

import {
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../constants/common';
import { setAccessToken } from '../../utils/common';
import { emailSignUp, registerMagic } from '../../services/user';
import routes from '../../routes';
import default_bg_img from '../../assets/images/dj_party.png';

import './SignUp.scss';
import { getSpaceMembership, getSpaceItemData } from '../../services/space';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import default_items_img from '../../assets/images/dj_party.png';
import dj_bg_img from '../../assets/images/dj-party-v2.png';
import { getSitewideLink } from '../../utils/sitewideLinks';

const SignUpForm = () => {
	const [form] = Form.useForm();
	const [params] = useSearchParams();
	const currentLocation = useLocation();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [isMagicLink, setIsMagicLink] = useState(false);
	const [alert, setAlert] = useState(null);
	const [successSignUp, setSuccessSignUp] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [initialLoad, setInitialLoad] = useState(true);
	const [verifyEmailSpaceUsername, setVerifyEmailSpaceUsername] = useState(null);
	const [verifyEmailSpaceMembershipId, setVerifyEmailSpaceMembershipId] = useState(null);
	const [membershipDetails, setMembershipDetails] = useState(null);
	const [verifyEmailSpaceItemId, setVerifyEmailSpaceItemId] = useState(null);
	const [itemDetails, setItemDetails] = useState(null);
	const { size } = useSelector((state) => state.classSize);
	const [margin, setMargin] = useState();

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const web3 = new Web3(magic.rpcProvider);

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setAlert(null);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(async (values) => {
		setAlert(null);

		try {
			if (isMagicLink) {
				await web3Login(values);
			} else {
				await registerUser(values);
			}
		} catch (error) {
			setAlert({ variant: 'danger', message: error.message });
		}
	}, []);

	const registerUser = async (values) => {
		setSuccessSignUp(false);
		setSubmitted(true);
		values.email = values.email.toLowerCase();
		const result = await emailSignUp(values);
		if (result) {
			if (result.result) {
				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Registered successfully!'
				});
				setSuccessSignUp(true);
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong'
					});
				}
			}
			setSubmitted(false);
		}
	};

	const registerMagicUser = async (values) => {
		setSubmitted(true);

		const result = await registerMagic(values);

		if (result) {
			if (result.result) {
				if (result.access_token) {
					setAccessToken(result);
				}

				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Registered successfully!'
				});

				window.location.replace(routes.walletSetAccount());
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong'
					});
				}
				setIsMagicLink(false);
				setSubmitted(false);
			}
		}
	};

	const getWalletInfo = (values) => {
		magic.connect.getWalletInfo().then((wallet_info) => {
			values.type = wallet_info.walletType;
			registerMagicUser(values);
		});
	};

	// Web 3 Login
	const web3Login = async () => {
		web3.eth
			.getAccounts()
			.then((accounts) => {
				magic.connect
					.requestUserInfo({ isResponseRequired: false })
					.then((user_account) => {
						let values = {
							email: user_account.email ?? 'no-email',
							user_public_address: accounts?.[0]
						};
						getWalletInfo(values);
					});
			})
			.catch((error) => {
				// setSubmitted(false);
				setIsMagicLink(false);
				// console.log(error);
			});
	};

	const magicLogin = async () => {
		// setSubmitted(true);
		setIsMagicLink(true);
		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});
		await web3Login();
	};

	const getMembership = useCallback(async (space_id, membership_id) => {
		const response = await getSpaceMembership({
			space_id: space_id,
			membership_id: membership_id
		});
		if (response && response.result) {
			const membershipData = response.membership;
			setMembershipDetails(membershipData);
		}
	}, []);

	const getItem = useCallback(async (space_id, item_id) => {
		const response = await getSpaceItemData({
			space_id: space_id,
			item_id: item_id
		});
		if (response && response.result) {
			const itemData = response.item;
			setItemDetails(itemData);
		}
	}, []);

	useEffect(() => {
		setMargin(() => {
			if (size !== 'lg') {
				return '4';
			} else {
				return '5';
			}
		});
		document.title = 'Commonspace Registration';

		if (loading && loading === 'done' && authUserInfo) {
			if (
				location.pathname === routes.walletSetAccountEmailSent() ||
				(verifyEmailSpaceUsername &&
					(verifyEmailSpaceMembershipId || verifyEmailSpaceItemId))
			) {
				setSuccessSignUp(true);
			} else {
				window.location.replace(routes.dashboard());
			}
		}

		const path = currentLocation.pathname;
		if (path.includes('membership/wallet/set-account/email-sent')) {
			const pathArr = path.match(/^\/([^/]*)\//);
			if (pathArr && pathArr.length > 0 && pathArr[1]) {
				const id = params.get('id');
				if (id) {
					const username = path.match(/^\/([^/]*)\//)[1];
					const space_username = username.replace('@', '');
					setVerifyEmailSpaceUsername(space_username);
					setVerifyEmailSpaceMembershipId(parseInt(id));

					if (initialLoad) {
						setInitialLoad(false);
						dispatch(getSpaceInfo(space_username));
					}
				}
			}
		}
		if (path.includes('item/wallet/set-account/email-sent')) {
			const pathArr = path.match(/^\/([^/]*)\//);
			if (pathArr && pathArr.length > 0 && pathArr[1]) {
				const id = params.get('id');
				if (id) {
					const username = path.match(/^\/([^/]*)\//)[1];
					const space_username = username.replace('@', '');
					setVerifyEmailSpaceUsername(space_username);
					setVerifyEmailSpaceItemId(parseInt(id));

					if (initialLoad) {
						setInitialLoad(false);
						dispatch(getSpaceInfo(space_username));
					}
				}
			}
		}

		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				if (verifyEmailSpaceMembershipId) {
					getMembership(spaceInfo.id, verifyEmailSpaceMembershipId).then((r) => {});
				}
				if (verifyEmailSpaceItemId) {
					getItem(spaceInfo.id, verifyEmailSpaceItemId).then((r) => {});
				}
			}
		}
	}, [
		currentLocation.pathname,
		loading,
		authUserInfo,
		verifyEmailSpaceUsername,
		verifyEmailSpaceMembershipId,
		verifyEmailSpaceItemId,
		spaceInfoLoading,
		spaceInfo,
		size,
		margin
	]);

	return (
		<div className=" my-16 h-full items-center">
			<div className='grid grid-cols-12 gap-4'>
				<div className='col-span-12 lg:col-span-6  lg:ml-10 '>
					<div className='h-full flex justify-center lg:justify-end'>
						<div className='w-full px-10 md:px-0 md:w-[664px] flex items-center'>
							<div className='content  '>
							<div className="grid grid-cols-1 font-['din2014Bold'] mb-2">
								<h1 className="header-large ">
								{location.pathname === routes.walletSetAccountEmailSent() ||
									(verifyEmailSpaceUsername &&
										(verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)) ? (
										<>Confirm Email</>
									) : (
										<>Sign Up</>
								)}
								</h1>
							</div>
							<div className='grid grid-cols-1'>
							<h5 className={successSignUp ? 'body-text--reg text-xl font-[notoSansRegular]' : 'body-text--reg text-xl font-[notoSansRegular]'}>
							{location.pathname === routes.walletSetAccountEmailSent() ||
							(verifyEmailSpaceUsername &&
								(verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)) ? (
								<>
									Your account has been saved and an email has been added. Please
									check your email to confirm.
								</>
							) : (
								<>
									Create your member account for free with an email or any ethereum wallet. By signing up, you agree to our <span className='gradient-color-txt'>Terms of Service</span>, <br/> <span className='gradient-color-txt'>Privacy Policy</span>, and <span className='gradient-color-txt'>Community Guidelines</span>.
								</>
							)}
							</h5>
							{successSignUp && (
								<div className="dialog-box mb-8">
									<h3 className="text-center font-[din2014Bold]">
										{location.pathname === routes.walletSetAccountEmailSent() ||
										(verifyEmailSpaceUsername &&
											(verifyEmailSpaceMembershipId ||
												verifyEmailSpaceItemId)) ? (
											<>Please check and confirm your email.</>
										) : (
											<>Thank you for signing up!</>
										)}
									</h3>
									<p className="text-center font-[notoSansMedium]">
										We’ve sent an email with a link to confirm your account. Please
										click it to continue.{' '}
										<span className="fst-italic">
											If you do not see it within 5 minutes, please check your
											spam folder.
										</span>
									</p>
								</div>
							)}
							</div>
							<div className='grid grid-cols-1'>
							{!successSignUp && (
							<Form
								form={form}
								name="signupForm"
								validateMessages={VALIDATE_MESSAGES}
								onFocus={() => {
									setFormIsOnFocus(true);
								}}
								onBlur={() => {
									setFormIsOnFocus(false);
									handleFormOnBlur();
								}}
								autoComplete="off"
								onChange={handleFormChanges}
								onFinish={handleSubmit}
								className={formIsOnFocus ? 'hide-antd-error-messages' : 'relative z-0'}
							>
								<>
									{alert && (
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									)}
								</>
								<div
									className={
										'input-group-com login-group-com input-with-btn ' +
										(alert ? 'mt-8' : 'mt-8') +
										' mb-8' 
										
									}
								>
									<Form.Item
										label="Email"
										name="email"
										className="mb-0 hidden-label email-input"
										validateTrigger="onBlur"
										rules={[{ required: true, type: 'email' }]}
									>
										<Input
											className={'w-[380px] py-1.5 px-3 input-' + size}
											disabled={submitted}
											placeholder="Enter email"
										/>
									</Form.Item>
									<Form.Item className="mb-0 hidden lg:block flex !justify-end !w-[200px]">
										<Button
											className={'btn text-white !w-[200px] border-filled btn-' + size}
											htmlType="submit"
											disabled={submitted || formHasError}
										>
											{submitted && (
												<i className="fa-solid fa-spinner fa-spin  mr-2"></i>
											)}
											Sign Up
										</Button>
									</Form.Item>
									
									
								</div>
								<Form.Item className="mb-8 block lg:hidden">
										<Button
											className={'btn text-white border-filled btn-' + size}
											htmlType="submit"
											disabled={submitted || formHasError}
										>
											{submitted && (
												<i className="fa-solid fa-spinner fa-spin  mr-2"></i>
											)}
											Sign Up
										</Button>
									</Form.Item>
							</Form>
							
						)}
							</div>
							<div className='grid grid-cols-1 !hidden'>
								<Button	className={'btn btn-secondary font-[notoSansSemiBold] mb-8 btn-' + size}>
									<i
										className={
											isMagicLink
												? 'fa-solid fa-spinner fa-spin mr-2'
												: 'fa-solid fa-wallet mr-2'
										}
									></i>
                                            Sign Up with Wallet
                                        </Button>
							</div>
							<div className='grid grid-cols-1'>
								{location.pathname === routes.walletSetAccountEmailSent() ||
								(verifyEmailSpaceUsername &&
									(verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)) ? (
									<>
										<h5 className="mt-5 fst-italic">
											Need help?
											{getSitewideLink('get_support', 'gradient-color-txt ms-1')}.
										</h5>
									</>
								) : (
									<>
										<h5 className={'fst-italic text-lg font-[notoSansRegular]'}>
											Already have an account?
											<Link to={routes.login()} className="gradient-color-txt ms-1">
												Login here.
											</Link>
										</h5>
									</>
								)}
							</div>
						</div>
							
						</div>
					</div>
				</div>
				<div className='col-span-6 hidden lg:grid pl-[116px]   h-full'>
					
						{membershipDetails && membershipDetails.video === 'video' ? (
							<video autoPlay muted loop className="membership-video">
								<source src={membershipDetails.video} type="video/mp4" />
							</video>
						) : (
							<img
								className="w-full rounded-l-3xl object-cover max-h-[488px] min-h-[365px]"
								src={membershipDetails ? membershipDetails.photo : default_bg_img}
							/>
						)}
				</div>
			</div>
		</div>		
		
	);
};

const SignUp = () => {
	return <SignUpForm />;
};
export default memo(SignUp);
