import React, { memo, useEffect, useCallback, useState } from 'react';
import { Button } from 'antd';
import {useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import routes from '../../../routes';
import { getSpaceMembership } from '../../../services/space';
import '../../SpaceBuyMembership/SpaceBuyMembership.scss'
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {checkActiveMembershipStatus, checkMembershipItemStatus, updateMembershipItemStatus} from "../../../services/payment_method";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import ActivationMembershipModal from "../../../components/commons/Modals/ActivationMembership/ActivationMembershipModal";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";

const MembershipActivateWalletConnect = (props) => {
	const { verifyEmailSpaceUsername, verifyEmailSpaceMembershipId } = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [membershipDetails, setMembershipDetails] = useState(null);
	const [isSubmitted, setSubmitted] = useState(false);
	const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);
	const [isActivationOpen, setActivationOpen] = useState(false);
	const [collectionAssetType, setCollectionAssetType] = useState('image');

	const skipActivation = useCallback(async () => {
		const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
		const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
		navigate(fin_url);
	}, [verifyEmailSpaceUsername, verifyEmailSpaceMembershipId]);

	const getMembership = useCallback(async (space_id, membership_id) => {
		const data = {
			space_id: space_id,
			membership_id: membership_id
		}
		if (authUserInfo) {
			data['viewing_user_id'] = authUserInfo.id;
		}
		const response = await getSpaceMembership(data);
		if (response && response.result) {
			const membershipData = response.membership;
			setMembershipDetails(response.membership);
			if (membershipData.photo || membershipData.video) {
				if (membershipData.photo) {
					setCollectionAssetType('image');
				}
				if (membershipData.video) {
					setCollectionAssetType('video');
				}
			} else {
				setCollectionAssetType('image');
			}
		}
	}, []);

	const checkStatus = useCallback(async (space_id, membership_id) => {
		// console.log('membership_id: '+ membership_id);
		if (!membership_id) return;
		const data = {
			space_id: space_id,
			membership_id: membership_id,
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response) {
			setCheckStatusLoaded(true);
			if (response.result) {
				setCheckStatusLoaded(true);
				const transaction = response.stripe_transaction;
				if (transaction) {
					if (transaction.status !== 'inactive') {
						const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
						const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
						navigate(fin_url);
					}
				} else {
					const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
					const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
					navigate(fin_url);
				}
			}
			setCheckStatusLoaded(true);
		}
	},[authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId])


	const activateSubscription = useCallback(async () => {
			setSubmitted(true);
			if (!verifyEmailSpaceMembershipId) return;
			const data = {
				space_id: spaceInfo.id,
				membership_id: verifyEmailSpaceMembershipId,
				status: 'active'
			}
			if (authUserInfo) {
				data['user_id'] = authUserInfo.id;
			}
			const createStripeResponse = await updateMembershipItemStatus(data);
			if (createStripeResponse) {
				if (createStripeResponse && createStripeResponse.result) {
					await redirectionPath();
				}
				setSubmitted(false);
			}
	},[authUserInfo, spaceInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId])

	const redirectionPath = useCallback(async () => {
		const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
		const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
		navigate(fin_url);
	}, [verifyEmailSpaceUsername, verifyEmailSpaceMembershipId])

	const checkActivateSubscription = useCallback(async () => {
		const data = {
			space_id: spaceInfo.id
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const checkActiveStatusResponse = await checkActiveMembershipStatus(data);
		if (checkActiveStatusResponse && checkActiveStatusResponse.result) {
			if (checkActiveStatusResponse.is_active_exist) {
				setActivationOpen(true);
			} else {
				await activateSubscription();
			}
		} else {
			await activateSubscription();
		}
	},[spaceInfo, authUserInfo])

	useEffect(() => {
		if (verifyEmailSpaceUsername) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					getMembership(spaceInfo.id, verifyEmailSpaceMembershipId).then((r) => {});
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(verifyEmailSpaceUsername));
				}
			}
		}

		if (spaceInfo && verifyEmailSpaceMembershipId && authUserInfo) {
			checkStatus(spaceInfo.id, verifyEmailSpaceMembershipId).then(r => {});
		}

		//Redirect user to logged in page if not authenticated
		MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

	}, [
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		initialLoad,
		verifyEmailSpaceUsername,
		verifyEmailSpaceMembershipId,
		isSubmitted,
		isCheckStatusLoaded
	]);

	return (
		<>
			<main id="membership-buy" className="flex">
				{!spaceInfo || !authUserInfo || !isCheckStatusLoaded ? (
					<div className="loading-items w-full items-center mt-8">
						<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
					</div>
				) : (
					<>
						<div className="margin-left-15 xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
							<div className="grid margin-right-0">
								<div className="grid grid-cols-2 pr-0 gap-4">
									<div className="lg:col-span-1 xsm:col-span-2 lg:pe-5 grid mb-5 align-left-center">
										<div className="lg:hidden md:flex">
											{membershipItemImage(collectionAssetType, membershipDetails)}
										</div>

										<div className="lg:pr-0">
											<h1 className="mb-4 header-large xsm:mt-5">Your Membership is ready!</h1>
											<p className="body-text--reg">
												You’re ready to access{' '}
												{membershipDetails ? membershipDetails.name : ''}! You can activate
												your Membership on your Account now or you can activate it later.
												You won’t get access to the Membership Benefits until you do.
											</p>
											<Button className="btn btn-primary btn-lg mt-5"
													disabled={
														isSubmitted
													}
													onClick={checkActivateSubscription}>
												{isSubmitted && (
													<i className="fa-solid fa-spinner fa-spin me-3"></i>
												)}
												Activate Membership & Access Space
											</Button>
											<Button className="btn btn-secondary btn-lg mt-5" onClick={skipActivation}>
												Skip Activation & Continue to Space
											</Button>
											<p className="body-text--reg mt-5 mb-0">
												Need help?{' '}
												{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
												{/*	Get support*/}
												{/*</Link>*/}
												{getSitewideLink('get_support', 'gradient-color-txt body-txtitalic--reg')}
											</p>
										</div>

									</div>

									<div className="lg:inline xsm:hidden p-0">
										{membershipItemImage(collectionAssetType, membershipDetails)}
									</div>
								</div>

								<div className="col-6">
									<div className="left-image-holder"></div>
								</div>
							</div>
						</div>

						{ membershipDetails &&
							<ActivationMembershipModal
								modalOpen={isActivationOpen}
								setModalOpen={setActivationOpen}
								membership={membershipDetails}
								space={spaceInfo}
								user={authUserInfo}
								activationStatus={'active'}
								isPaymentMethod={true}
								redirectionPath={redirectionPath}
							/>
						}

					</>
					)
				}
			</main>
		</>
	);
};

export default memo(MembershipActivateWalletConnect);
