import { memo, useCallback, useEffect, useState } from 'react';
import default_user_profile from '../../../assets/images/default_user_profile.png';
import moment from 'moment';
import { Button, Form } from 'antd';
import { VALIDATE_MESSAGES } from '../../../constants/common';
import TextareaAutosize from 'react-textarea-autosize';
import { createSpaceModelComment } from '../../../services/space';

const CommentRow = (props) => {
	const {
		authUserInfo,
		spaceInfo,
		modelId,
		comment,
		commentIdToReply,
		updateCommentIdToReply,
		commentLevel,
		fetchUpdatedComments,
		parentReplyId
	} = props;
	const commenter = comment ? comment.commenter : null;
	const [commentReplyForm] = Form.useForm();
	const [submitted, setSubmitted] = useState(false);
	const [disableReplyButton, setDisableReplyButton] = useState(true);

	let commentLevelClass = 'comments';
	if (commentLevel === 2) {
		commentLevelClass = 'comments-rep2';
	}
	if (commentLevel === 3) {
		commentLevelClass = 'comments-rep3';
	}

	const commentReplyKeypress = (event) => {
		if (!event.shiftKey && event.key === 'Enter') {
			event.preventDefault();
			commentReplyForm.submit();
		}
	};

	const handleChangesReply = useCallback(() => {
			setDisableReplyButton(commentReplyForm.getFieldValue('reply').trim() === '');
		},
		[disableReplyButton]
	);

	const handleSubmitReply = useCallback(
		async (values) => {
			let okToSubmit = (commentReplyForm.getFieldValue('reply') !== undefined && commentReplyForm.getFieldValue('reply').trim() !== '');

			if (okToSubmit) {
				setSubmitted(true);

				const data = {
					space_id: spaceInfo.id,
					model: 'update',
					model_id: modelId,
					comment: values.reply,
					reply_to_id: commentIdToReply
				};

				if (commentLevel === 3 && parentReplyId) {
					data['reply_to_id'] = parentReplyId;
					data['reply_to_sub_id'] = commentIdToReply;
				}

				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}

				const response = await createSpaceModelComment(data);
				if (response && response.result) {
					updateCommentIdToReply(null);
					fetchUpdatedComments();
				}

				setSubmitted(false);
				setDisableReplyButton(true);
			}
		},
		[authUserInfo, spaceInfo, modelId, commentIdToReply, parentReplyId]
	);

	useEffect(() => {}, [
		authUserInfo,
		spaceInfo,
		modelId,
		comment,
		commentIdToReply,
		updateCommentIdToReply,
		commentLevel
	]);

	return (
		<>
			{comment && (
				<>
					<div className={commentLevelClass + ' comments gradient-border-box mt-5'}>
						<p className="body-text--small color-reg m-0 comment-text">{comment.comment}</p>
						<div className="comments-img mt-2 flex flex-col sm:flex-row ">
							<div className="contents md:flex justify-start w-50 items-center">
								{comment.commenter ? (
									<>
										<img src={commenter.photo_small} />
									</>
								) : (
									<>
										<img src={default_user_profile} />
									</>
								)}
								<div className="ms-2">
									<h6 className="header-title m-0">
										{comment.commenter ? (
											<>
												{commenter.username
													? '@' + commenter.username
													: commenter.display_name
													? commenter.display_name
													: commenter.full_name}
											</>
										) : (
											<>Anonymous</>
										)}
									</h6>
									<p className="body-text--smallest color-light m-0">
										{moment(comment.created_on).fromNow()}
									</p>
								</div>
							</div>
							<Button
								className="mt-2 text-end body-text--reg gradient-color-txt w-50 reply-btn overflow-hidden"
								
							>
								<span onClick={() => {
									updateCommentIdToReply(comment.id);
								}}>
								<i className="fa-solid fa-reply me-2"></i>
								Reply
								</span>
							</Button>
						</div>
						{commentIdToReply && commentIdToReply === comment.id && (
							<>
								<Form
									form={commentReplyForm}
									name="commentReplyForm"
									id="commentReplyForm"
									onFinish={handleSubmitReply}
									onChange={handleChangesReply}
									validateMessages={VALIDATE_MESSAGES}
								>
									<div className="comment-input-container gradient-border-box w-full flex items-center justify-between mt-5">
										<Form.Item
											label="Reply"
											name="reply"
											className="col-1 mb-0 hidden-label"
											// validateTrigger="onBlur"
											// rules={[{ required: true }]}
										>
											<TextareaAutosize
												placeholder="Write a comment reply"
												className="input-md body-text--small color-reg bring-front"
												onKeyPress={commentReplyKeypress}
											/>
										</Form.Item>

										<Form.Item className="col-2 mb-0">
											<Button
												className={disableReplyButton ? "body-text--reg comment-disable me-4 submit-comment-btn" : "body-text--reg gradient-color-txt me-4 submit-comment-btn"}
												htmlType="submit"
												disabled={submitted || disableReplyButton}
											>
												{submitted && (
													<i className="fa-solid fa-spinner fa-spin me-1 comment-spinner"></i>
												)}
												Submit
											</Button>
										</Form.Item>
									</div>

								</Form>
							</>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default memo(CommentRow);
