import { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';

import { getSpaceInfo } from '../../../../states/spaceGlobal/actions';
import { getMySpaceCollections } from '../../../../services/user';
import routes from '../../../../routes';

const ViewCollection = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [activeTab, setActiveTab] = useState('all');
	const [allItemsList, setAllItemsList] = useState(null);
	const [itemsList, setItemsList] = useState(null);
	const [membershipsList, setMembershipsList] = useState(null);
	const [itemsCount, setItemsCount] = useState(0);
	const [membershipKeysCount, setMembershipKeysCount] = useState(0);
	const [allItemsCount, setAllItemsCount] = useState(0);

	const setItemBackground = (data) => {
		return {
			backgroundImage: 'url(' + data.photo + ')'
		};
	};

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + spaceInfo.username;
		window.location.replace(url);
	}, [spaceInfo]);

	const goToClaim = (data) => {
		if (data.collection_type === 'membership') {
			let url = routes.spaceMembersProductsMembership();
			window.location.href =
				url.replace(':space_username', '@' + space_username) + '?id=' + data.id;
		} else {
			console.log('go to item claim');
		}
	};

	const getCollections = useCallback(async () => {
		try {
			if (authUserInfo && spaceInfo) {
				const params = {
					user_id: authUserInfo.id,
					space_id: spaceInfo.id
				};

				const response = await getMySpaceCollections(params);
				if (response && response.result) {
					setAllItemsList(response.all_items);
					setItemsList(response.items);
					setMembershipsList(response.memberships);
					setItemsCount(response.total_items_count);
					setMembershipKeysCount(response.total_memberships_count);
					setAllItemsCount(response.total_all_items_count);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo, spaceInfo]);

	useEffect(() => {
		if (space_username) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					const backgroundImage = {
						backgroundImage: 'url(' + spaceInfo.background_photo + ')'
					};
					setBackgroundImage(backgroundImage);
					getCollections().then((r) => {});
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(space_username));
				}
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, space_username, spaceInfo, spaceInfoLoading]);

	return (
		<>
			<div className="col-items space-manage-bg mt-4" style={backgroundImage}>
				<div className="grid grid-cols-12  gap-8 items-center">
					<div className="col-span-12 md:col-span-9">
						<h3>
							<i className="fa-light fa-solar-system"></i>{' '}
							{spaceInfo ? spaceInfo.name : ''}
						</h3>
					</div>
					<div className="col-span-12 md:col-span-3">
						<Link className="btn btn-tertiary btn-md" onClick={() => goToSpace()}>
							<i className="fa-light fa-solar-system"></i>
							<span className="w-3/4 text-center">Access Space</span>
							<i className="fa-light fa-arrow-up-right-from-square"></i>
						</Link>
					</div>
				</div>
				<ul className="nav nav-pills mt-5 flex justify-evenly">
					<li className="nav-item" role="presentation">
						<Button
							className={'nav-link ' + (activeTab === 'all' ? 'active' : '')}
							onClick={() => {
								setActiveTab('all');
							}}
						>
							All Keys & Items({allItemsCount})
						</Button>
					</li>
					<li className="nav-item" role="presentation">
						<Button
							className={'nav-link ' + (activeTab === 'memberships' ? 'active' : '')}
							onClick={() => {
								setActiveTab('memberships');
							}}
						>
							Membership Keys({membershipKeysCount})
						</Button>
					</li>
					<li className="nav-item" role="presentation">
						<Button
							className={'nav-link ' + (activeTab === 'items' ? 'active' : '')}
							onClick={() => {
								setActiveTab('items');
							}}
						>
							Items({itemsCount})
						</Button>
					</li>
				</ul>
				<div className="bg-overlay"></div>
			</div>

			<div>
				{authUserInfo && (
					<>
						<div className="grid grid-cols-12 gap-8 mt-5">
							{activeTab === 'all' && (
								<>
									{spaceInfo &&
										allItemsList &&
										allItemsList.map((data, index) => (
											<div key={index} className="col-span-6 md:col-span-3">
												<Link onClick={() => goToClaim(data)}>
													<div
														className="collection-content"
														style={setItemBackground(data)}
													>
														<div className="collection-info">
															<h6 className="header-small-title text-center">
																{data.name}
															</h6>
															<p className="body-txtitalic--smallest text-center">
																{data.summary}
															</p>
														</div>
													</div>
												</Link>
											</div>
										))}
								</>
							)}
							{activeTab === 'memberships' && (
								<>
									{spaceInfo &&
										membershipsList &&
										membershipsList.map((data, index) => (
											<div key={index} className="col-span-6 md:col-span-3">
												<Link onClick={() => goToClaim(data)}>
													<div
														className="collection-content"
														style={setItemBackground(data)}
													>
														<div className="collection-info">
															<h6 className="header-small-title text-center">
																{data.name}
															</h6>
															<p className="body-txtitalic--smallest text-center">
																{data.summary}
															</p>
														</div>
													</div>
												</Link>
											</div>
										))}
								</>
							)}
							{activeTab === 'items' && (
								<>
									{spaceInfo &&
										itemsList &&
										itemsList.map((data, index) => (
											<div key={index} className="col-span-6 md:col-span-3">
												<Link onClick={() => goToClaim(data)}>
													<div
														className="collection-content"
														style={setItemBackground(data)}
													>
														<div className="collection-info">
															<h6 className="header-small-title text-center">
																{data.name}
															</h6>
															<p className="body-txtitalic--smallest text-center">
																{data.summary}
															</p>
														</div>
													</div>
												</Link>
											</div>
										))}
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default memo(ViewCollection);
