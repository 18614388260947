import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {getSitewideLink} from "../../../utils/sitewideLinks";

const SpaceCreateAddTraits = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const saveContinue = () => {
		const url = routes.createSpaceAddMembershipBenefitPage();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createSpaceAddMembershipPage();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main className="dashboard-wrapper flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12 md:col-span-6 flex items-center">
							<h1 className="mb-4 mt-5 header-large">Membership Traits</h1>
						</div>
						<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">
							<p className="body-txtitalic--large mt-5">Step 4 of 5</p>
						</div>
						<div className="col-span-12">
							<h5 className="body-text--reg">
								{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	Memberships*/}
								{/*</Link>*/}
								{getSitewideLink('memberships_create_space_4', 'gradient-color-txt body-txtitalic--reg')}
								{' '}can have optional traits assigned to them such as text properties,
								level counts, numerical stats, dates, percentages, and hidden
								content. For Memberships that are created based on an existing NFT
								Collection, Traits are automatically enabled and will be inherited
								from the traits in the existing NFT metadata.{' '}
								{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	Learn more about Traits and how to use them*/}
								{/*</Link>*/}
								{getSitewideLink('learn_more_traits', 'gradient-color-txt body-txtitalic--reg')}
								.
							</h5>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">
						<div className="col-span-12 md:col-span-8">
							<Button
								onClick={saveContinue}
								className="btn btn-lg btn-primary"
								htmlType="submit"
							>
								Save Membership & Continue
							</Button>
						</div>
						<div className="col-span-12 md:col-span-4 flex items-end">
							<Button
								onClick={saveBack}
								className="btn btn-lg btn-warning bg-none"
								htmlType="submit"
							>
								Save & Go Back
							</Button>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(SpaceCreateAddTraits);
