import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './states/store';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.js';
import './assets/fontawesome-pro/css/all.min.css';
import './scss/style.scss';
import App from './App';

const WrappedApp = () => {
	return (
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<WrappedApp />);
