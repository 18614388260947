import { memo } from 'react';

import CollectionItem from './CollectionItem';

import default_space_bg from '../../../../assets/images/profile_spaces_bg.png';
import default_space_image from '../../../../assets/images/profile_spaces_img.png';

const Collections = (props) => {
	const { collections } = props;

	const setItemBackground = (data) => {
		let defaultBg = {
			backgroundImage: 'url(' + default_space_bg + ')'
		};
		if (data) {
			if (data.background_photo) {
				defaultBg = {
					backgroundImage: 'url(' + data.background_photo + ')'
				};
			}
		}
		return defaultBg;
	};

	const compileCollectionsIntoOne = (data) => {
		const arr = [];
		if (data) {
			if (data.memberships && data.memberships.length > 0) {
				data.memberships.forEach((membership) => {
					arr.push(membership);
				});
			}
			if (data.items && data.items.length > 0) {
				data.items.forEach((item) => {
					arr.push(item);
				});
			}
		}
		return arr;
	};

	return (
		<>
			<div className="mt-4">
				{collections &&
					collections.map((space, space_index) => (
						<div key={space_index} className="mt-4">
							<div className="profile-spaces mx-3" style={setItemBackground(space)}>
								<div className="profile-spaces-content">
									<div className="grid grid-cols-12 gap-8 items-center">
										<div className="xsm:col-span-12 sm:col-span-7 md:col-span-8 lg:col-span-9 xsm:justify-center xsm:flex sm:block">
											<h3 className="header-medium ">{space.name}</h3>
										</div>
										<div className="xsm:my-3 xsm:col-span-12 sm:col-span-5 md:col-span-4 lg:col-span-3">
											{space.collections_count > 4 && (
												<a className="btn btn-tertiary btn-md">
													View all {space.collections_count} Keys &
													Items
												</a>
											)}
										</div>
									</div>
									<div className="mt-4 grid xsm:grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:gap-4 xsm:gap-7">
										{compileCollectionsIntoOne(space) &&
											compileCollectionsIntoOne(space).length > 0 &&
											compileCollectionsIntoOne(space).map(
												(data, collection_index) => (
													<CollectionItem
														space={space}
														key={collection_index}
														collection_index={collection_index}
														data={data}
													></CollectionItem>
												)
											)}
									</div>
								</div>
								<div className="bg-overlay"></div>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default memo(Collections);
