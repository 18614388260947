import React, {memo, useCallback, useEffect, useState} from "react";
import ReactModal from "react-modal";
import {Button} from "antd";
import {updateMembershipItemStatus} from "../../../../services/payment_method";


const ActivationMembershipModal = (props) => {
    const { modalOpen, setModalOpen, membership, space, user, activationStatus, isPaymentMethod = false, redirectionPath } = props;
    const [submitted, setSubmitted] = useState(false);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            minWidth: 320,
            maxWidth: 750,
            borderRadius: 32
        },
        overlay: { background: 'rgba(0, 0, 0, 0.6)' }
    };

    const activateMembership = useCallback(async () => {
        setSubmitted(true);
        if (!membership) {
            setSubmitted(false);
            return;
        }
        const data = {
            space_id: space.id,
            membership_id: membership.id,
            status: activationStatus
        }
        if (user) {
            data['user_id'] = user.id;
        }
        const createStripeResponse = await updateMembershipItemStatus(data);
        if (createStripeResponse) {
            if (createStripeResponse && createStripeResponse.result) {
                setModalOpen(false);
                if (isPaymentMethod) {
                    redirectionPath();
                } else {
                    window.location.reload();
                }
            }
            setSubmitted(false);
        } else {
            setSubmitted(false);
        }
    },[user, membership, space, activationStatus, isPaymentMethod]);


    useEffect(() => {

    }, [activationStatus,isPaymentMethod]);

    return (
        <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={customStyles}
        >
            <Button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
                <i className="fa-solid fa-xmark"></i>
            </Button>


            <div className="items-center wrapper-lr-padding">
                <h3 className="header-medium text-center mt-5">
                    { activationStatus === 'inactive' ?
                        <>
                            Deactivate {membership.name}
                        </>
                        :
                        <>
                            Activate {membership.name}
                        </>
                    }

                </h3>
                <p className="body-text--small text-center mt-5 mb-5">
                    { activationStatus === 'inactive' ?
                        <>
                            Do you want to Deactivate your Membership (which will deactivate all your Items)?
                        </>
                        :
                        <>
                            Do you want to replace your Membership?
                        </>
                    }
                </p>
            </div>

            <Button className="btn btn-md btn-primary mb-2"
                    disabled={submitted}
                    onClick={activateMembership}>
                { activationStatus === 'inactive' ?
                    <>
                        Deactivate
                    </>
                    :
                    <>
                        Activate
                    </>
                }

            </Button>
        </ReactModal>
    );
}

export default memo(ActivationMembershipModal);