import { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { shortenNumber } from '../../../../utils/number';
import routes from '../../../../routes';

const CollectionItem = (parentProps) => {
	const { data, type, urlRedirect, currentUser } = parentProps;
	const [squareCollections, setSquareCollections] = useState(null);

	const backgroundImage = {
		backgroundImage: 'url(' + data.background_photo + ')'
	};

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + data.username;
		window.location.replace(url);
	}, [data]);



	const leftCollections = [];
	const rightCollections = [];
	if (data && data.memberships && data.memberships.length > 0) {
		data.memberships.forEach((membership) => {
			leftCollections.push(membership);
		});
	}

	if (data && data.items && data.items.length > 0) {
		data.items.forEach((item) => {
			rightCollections.push(item);
		});
	}

	const membershipKeyCount = () =>{
		const membershipCount = leftCollections.length;
		if (membershipCount > 2) {
			const leftMemberships = membershipCount - 2;
			return '+ '+leftMemberships + ' '+(leftMemberships > 1 ? 'Keys' : 'Key');
		}
		return '';
	}

	const itemCount = () =>{
		const itemCount = rightCollections.length;
		if (itemCount > 7) {
			const leftItems = itemCount - 7;
			return '+ '+leftItems + ' '+(leftItems > 1 ? 'Items' : 'Item');
		}
		return '';
	}

	return (
		<>
			<div className="grid grid-cols-12  md:gap-8">
				<div className="col-span-12">
					<div className="spaces-container">
						<div className="spaces-header" style={backgroundImage}>
							<div className="grid grid-cols-12 gap-3 items-center">

								<div className="xsm:col-span-10 lg:col-span-9">
									<h5>
										<i className="fa-light fa-solar-system"></i> {data.name}
									</h5>
								</div>

								<div className="lg:hidden xsm:inline xsm:col-span-2 items-center">
									<Link
										className="btn btn-tertiary btn-md"
										onClick={() => goToSpace()}
									>
										<i className="fa-light fa-arrow-up-right-from-square"></i>
									</Link>
								</div>

								<div className="xsm:hidden lg:inline lg:col-span-3 items-center">
									<Link
										className="btn btn-tertiary btn-md"
										onClick={() => goToSpace()}
									>
										<i className="fa-light fa-solar-system"></i>
										<span className="w-3/4 text-center">Access Space</span>
										<i className="fa-light fa-arrow-up-right-from-square"></i>
									</Link>
								</div>
							</div>
							<div className="bg-overlay"></div>
						</div>

						<div className="spaces-content">
							<div className="grid grid-cols-12 md:gap-8 items-center">
								<div className="xsm:col-span-12 md:col-span-4 flex items-center">
									{leftCollections.length === 0 ? (
										<label>No NFTs</label>
									) : (
										<ul className="polygon-membership">
											{leftCollections.length > 0 &&
												leftCollections
													.slice(0, 2)
													.map((collection, index) => (
														<li key={index} >
															<Link to="#" className="square" style={{zIndex: leftCollections.length - index}}>
																<img
																	className="img-thumb-square"
																	title={collection.name}
																	src={collection.photo}
																/>
															</Link>
														</li>
													))}
										</ul>
									)}
									{leftCollections.length > 2 && (
										<Link
											to="#"
											className="gradient-color-txt body-text--smallest ms-4"
										>
											{membershipKeyCount()}
										</Link>
									)}
								</div>



								<div className="xsm:col-span-12 md:col-span-5 flex items-center">
									{rightCollections.length === 0 ? (
										<label>No NFTs</label>
									) : (
										<ul className="polygon-membership">
											{rightCollections.length > 0 &&
												rightCollections
													.slice(0, 7)
													.map((collection, index) => (
														<li key={index}>
															<Link to="#" className="hex" style={{zIndex: rightCollections.length - index}}>
																<img
																	title={collection.name}
																	src={collection.photo}
																/>
															</Link>
														</li>
													))}
										</ul>
									)}
									{rightCollections.length > 7 && (
										<Link
											to="#"
											className="gradient-color-txt body-text--smallest ms-4"
										>
											{itemCount()}
										</Link>
									)}
								</div>
								<div className="xsm:col-span-12 md:col-span-3 flex items-center">
									<Link to={urlRedirect} className="btn btn-secondary btn-md">
										View Collection
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(CollectionItem);
