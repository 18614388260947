import React, {memo, useCallback, useEffect, useState} from "react";
import {Button, Checkbox, Form} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {checkMembershipItemStatus, createJoinMembershipItem, createMembershipItemSubscription, createStripeMembershipOnetimePayment} from "../../../services/payment_method";
import {getAuthUserInfo} from "../../../states/general/actions";
import {getSpaceMembership} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import routes from "../../../routes";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {MEMBERSHIP_TYPES} from "../../../constants/space";
import {autoRenewParser, DateDayCondition, getButtonPaymentText, getExpirationDate, getExpirationDateData, getExpirationMonthlyStart, getIncludedMonths, getMembershipItemPrice, getSelectedMonth, membershipItemImage, Recurring} from "../../../components/commons/helpers/MembershipItemHelpers";
import '../SpaceBuyMembership.scss'
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import Alert from "react-bootstrap/Alert";

const MembershipPaymentMethod = () => {
    const [membershipPaymentMethodForm] = Form.useForm();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [searchParams, setSearchParams] = useSearchParams();
    const membershipId = searchParams.get('id');
    // const [selectedCard, setSelectedCard] = useState('default');
    // const [paymentMethod, setPaymentMethod] = useState('default');
    // const [isAddCard, setAddCard] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const [agreementChecked1, setAgreementChecked1] = useState(false);
    const [agreementChecked2, setAgreementChecked2] = useState(false);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [alert, setAlert] = useState(null);
    const currentLocation = useLocation();
    const [isMembershipLoaded, setMembershipLoaded] = useState(false);
    const [membershipDetails, setMembershipDetails] = useState(null);
    const [expirationDate, setExpirationDate] = useState('No Expiration');
    const [expirationDateDeductOneDay, setExpirationDateDeductOneDay] = useState('');
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
    const [spaceName, setSpaceName] = useState(null);
    const [isSubscription, setSubscription] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [displayDropDownError, setDisplayDropDownError] = useState(false);
    const [submittedAddCardForm, setSubmittedAddCardForm] = useState(false);
    const [showAddCardForm, setShowAddCardForm] = useState(false);
    const [autoMonthlyPrice, setAutoMonthlyPrice] = useState([{}]);
    const [selectedMonthlyPrice, setSelectedMonthlyPrice] = useState(Recurring.autoRenewOff);
    const [isParseOnlyOnce, setParseOnlyOnce] = useState(false);
    const [hidePaymentMethod, setHidePaymentMethod] = useState(false);
    const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);

    let spaceUsername = null;
    const path = currentLocation.pathname;
    if (path && path.startsWith('/@') && path.length > 3) {
        const clean_path = path.replace('/@', '');
        const path_exp = clean_path.split('/');
        spaceUsername = path_exp[0];
    }

    const handleFormChanges = useCallback(async () => {
        await membershipPaymentMethodForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleFormOnBlur = useCallback(async () => {
        await membershipPaymentMethodForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const onChangeAgreement1 = (e) => {
        setAgreementChecked1(e.target.checked);
    };

    const onChangeAgreement2 = (e) => {
        setAgreementChecked2(e.target.checked);
    };

    const handleSubmit = useCallback(async (values) => {
            setSubmitted(true);

            if (!authUserInfo) {
                setSubmitted(false);
                return;
            }

            if (!spaceInfo) {
                setSubmitted(false);
                return;
            }

            if (!membershipDetails) {
                setSubmitted(false);
                return;
            }

            const data = values;
            data.membership_id = membershipId;
            data.space_id = spaceInfo.id;
            data.user_id = authUserInfo.id;
            data.payment_type = 'free';
            data.space_owner_id = spaceInfo.owner;
            // const response = await createStripePaymentIntent(data);

            // if (membershipDetails != null) {
            if (hidePaymentMethod) {
                const response = await createJoinMembershipItem(data);
                if (response && response.result) {
                    setSubmitted(false);
                    await proceedPaymentCompleted();
                } else {
                    setSubmitted(false);
                }
            } else {
                try {
                    setDisplayDropDownError(true);

                    data.is_subscription_enable = membershipDetails.enable_expiration_and_subscription;
                    // data.space_id = spaceInfo.id;
                    // data.card_type = 'card';
                    // data.card_cvc = data.cvc;
                    // data.membership_id = membershipId;
                    // console.log('test')

                    if (membershipDetails.enable_expiration_and_subscription && membershipDetails.first_benefit) {
                        data.amount = (selectedMonthlyPrice === 'Auto-Renew Off' ? 0 : selectedMonthlyPrice);
                        data.is_recurring = selectedMonthlyPrice !== 'Auto-Renew Off';
                        data.membership_fee = parseFloat((membershipDetails.price === null ? 0 : membershipDetails.price));
                        data.currency = membershipDetails.first_benefit.currency.toLowerCase();
                        data.included_months = membershipDetails.first_benefit.included_months;
                        data.recurring_months = getSelectedMonth(selectedMonthlyPrice, autoMonthlyPrice);
                        data.start_date = getExpirationDateData(data.included_months, true);

                        const response = await createMembershipItemSubscription(data);
                        if (response && response.payout && response.payout.url) {
                            // setAlert({
                            //     variant: 'success',
                            //     message: response.message
                            // });
                            window.location.href = response.payout.url;
                        } else {
                            setAlert({
                                variant: 'danger',
                                message: response.message
                            });
                            setSubmitted(false);
                            setFormHasError(false);
                        }
                    } else {
                        data.amount = parseFloat(membershipDetails.price);
                        data.currency = membershipDetails.currency.toLowerCase();
                        const response = await createStripeMembershipOnetimePayment(data);
                        if (response && response.payout) {
                            if (response.payout.url && response.payout.status === 'open') {
                                window.location.href = response.payout.url;
                            } else if (response.payout.status === 'complete') {
                                let url = routes.spaceMembershipPaymentCompleted();
                                const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
                                navigate(fin_url);
                            }
                        } else {
                            setSubmitted(false);
                            setFormHasError(false);
                            setAlert({
                                variant: 'danger',
                                message: response.message
                            });
                        }
                    }


                    // if (selectedCard.value !== 'default' && selectedCard.value !== 'add') {
                    //     if (authUserInfo) {
                    //         data.user_id = authUserInfo.id;
                    //         data.stripe_id = selectedCard.cardObject.id;
                    //
                    //         const response = await createStripePaymentIntent(data);
                    //         if (response && response.result) {
                    //             setSubmitted(false);
                    //             await proceedPaymentCompleted();
                    //         } else {
                    //             setSubmitted(false);
                    //         }
                    //     } else {
                    //         setSubmitted(false);
                    //     }
                    // } else {
                    //     if (!selectedCountry || selectedCountry === '') {
                    //         setSubmitted(false);
                    //         return;
                    //     }
                    //
                    //     //TODO: change package this is only a temporary fix
                    //     let countryCode = getCode(selectedCountry);
                    //     if (!countryCode) {
                    //         if (selectedCountry === 'United States') {
                    //             countryCode = getCode('United States of America');
                    //         }
                    //     }
                    //
                    //     data.country = countryCode;
                    //     data.state = selectedRegion;
                    //     data.expiry_mm = values.expiry.format('MM');
                    //     data.expiry_yy = values.expiry.format('YYYY');
                    //
                    //     delete data.expiry;
                    //
                    //     if (authUserInfo) {
                    //         setSubmittedAddCardForm(true);
                    //         data.user_id = authUserInfo.id;
                    //
                    //         const response = await createPaymentMethodAndPay(data);
                    //         if (response && response.result) {
                    //             setShowAddCardForm(false);
                    //             dispatch(getAuthUserInfo());
                    //             setSubmitted(false);
                    //             await proceedPaymentCompleted();
                    //         } else {
                    //             setSubmitted(false);
                    //         }
                    //         setSubmittedAddCardForm(false);
                    //     } else {
                    //         setSubmitted(false);
                    //     }
                    // }
                } catch (error) {
                    setSubmitted(false);
                    console.log(error);
                }
            }
            // }
        },
        [
            authUserInfo,
            selectedCountry,
            selectedRegion,
            // selectedCard,
            membershipDetails,
            hidePaymentMethod,
            spaceInfo,
            selectedMonthlyPrice,
            autoMonthlyPrice]
    );

    const checkStatus = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await checkMembershipItemStatus(data);
        if (response && response.result) {
            const transaction = response.stripe_transaction;
            const sessionStatus = response.session_status;
            if (transaction) {
                if (transaction.status === 'inactive') {
                    if (sessionStatus === 'complete') {
                        let url = routes.spaceMembershipPaymentCompleted();
                        const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
                        navigate(fin_url);
                    }
                } else if (transaction.status === 'active' || transaction.status === 'expired') {
                    let url = routes.spaceMembershipBuy();
                    const fin_url =
                        url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
                    navigate(fin_url);
                }
            }
            setCheckStatusLoaded(true);
        }
    },[authUserInfo])

    const getMembership = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id
        }

        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }

        const response = await getSpaceMembership(data);
        if (response && response.result) {
            const membershipData = response.membership;
            setIsPaid(membershipData.is_paid);
            setMembershipDetails(membershipData);
            setMembershipLoaded(true);
            if (membershipData.photo || membershipData.video) {
                if (membershipData.photo) {
                    const bg = {
                        backgroundImage: 'url(' + membershipData.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (membershipData.video) {
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [authUserInfo]);

    const proceedPaymentCompleted = useCallback(async () => {
        // const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
        if (spaceUsername) {
            let url = routes.spaceMembershipPaymentCompleted();
            // let url = routes.spaceMembershipPaymentCompleted();
            // if (membershipDetails.collection_type === 'membership') {
            //     if (isSubscription) {
            //         // const isSubscription = membershipDetails.enable_expiration_and_subscription;
            //         // if (!isSpaceMember) {
            //             if (membershipDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
            //                 url = routes.spaceMembershipWalletConnect();
            //             }
            //         // }
            //
            //     } else {
            //         if (isSpaceMember) {
            //             // Membership Ready
            //             //to
            //             //Wallet Connect
            //         } else {
            //             // EXISTING USER/NEW TO SPACE CLAIM NOW FLOW where User
            //             // must own an existing NFT to claim Membership/Item NO SUBSCRIPTION
            //             if (membershipDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
            //                 url = routes.spaceMembershipWalletConnect();
            //             }
            //         }
            //     }
            // }
            const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
            navigate(fin_url);
        }
    }, [spaceInfo, membershipDetails, isSubscription, spaceUsername, membershipId]);


    useEffect(() => {
        if (membershipDetails == null) {
            setMembershipLoaded(false);
        }
        if (authUserInfo===null) {
            dispatch(getAuthUserInfo());
        }

        if (spaceUsername && !isMembershipLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getMembership(spaceInfo.id, membershipId).then((r) => {
                    });
                    setSpaceName(spaceInfo.name);
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(spaceUsername));
                }
                if (spaceInfoLoading === 'failed') {
                    navigate(routes.createSpace());
                }
            }
        }

        if (membershipDetails != null) {
            setHidePaymentMethod(!isSubscription && (membershipDetails.price == null || (parseFloat(membershipDetails.price) === 0)) ||
                isSubscription && selectedMonthlyPrice === Recurring.autoRenewOff && (membershipDetails.price == null || (parseFloat(membershipDetails.price) === 0)));
            if (!isParseOnlyOnce) {
                setParseOnlyOnce(true);
                setAutoMonthlyPrice(autoRenewParser(membershipDetails));
            }
            setSubscription(membershipDetails.enable_expiration_and_subscription);
            // console.log('selectedMonthlyPrice: -start: ' + selectedMonthlyPrice);
            if (autoMonthlyPrice.length > 0 && membershipDetails.enable_expiration_and_subscription && membershipDetails.first_benefit) {
                const included_months = membershipDetails.first_benefit.included_months;
                const date_created = membershipDetails.first_benefit.created_on;
                const selectedMonths = autoMonthlyPrice.filter(monthly => monthly.value === selectedMonthlyPrice);
                let redemptionCount = included_months;
                if (selectedMonths.length > 0 ) {
                    redemptionCount = included_months + selectedMonths[0].months
                }
                // console.log('selectedMonthlyPrice: -setExpirationDate: ' + selectedMonthlyPrice);
                setExpirationDate(getExpirationDate(date_created, included_months, DateDayCondition.default));
                setExpirationDateDeductOneDay(getExpirationDate(date_created, redemptionCount,DateDayCondition.deductOneDay));
            }

            if (authUserInfo != null && !loadMembershipOnce) {
                setLoadMembershipOnce(true);
                checkStatus(spaceInfo.id, membershipId).then(r =>{} );
                getMembership(spaceInfo.id, membershipId).then((r) => {});
            }
        }
        // setAddCard(selectedCard.value === 'add');

        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        spaceUsername,
        membershipId,
        membershipDetails,
        // selectedCard,
        loadMembershipOnce,
        hidePaymentMethod,
        isSubscription,
        isPaid,
        isCheckStatusLoaded,
        selectedMonthlyPrice
    ]);

    useEffect(() => {
        // console.log('expirationDate: '+expirationDate)
    },[expirationDateDeductOneDay, expirationDate, isSubscription, autoMonthlyPrice]);

    return (
        <>
            <main id="membership-buy" className="flex">
                {!spaceInfo || !authUserInfo || !isCheckStatusLoaded ? (
                    <div className="loading-items flex justify-center w-full items-center mt-8">
                        <i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
                    </div>
                ) : (
                    <>
                        {/*<div className="page-left-image">*/}
                        {/*    <div*/}
                        {/*        className="asset-preview"*/}
                        {/*        style={collectionAssetBGImage}*/}
                        {/*    >*/}
                        {/*        {collectionAssetType &&*/}
                        {/*            collectionAssetType === 'video' && (*/}
                        {/*                <video*/}
                        {/*                    autoPlay*/}
                        {/*                    muted*/}
                        {/*                    loop*/}
                        {/*                    className="membership-video"*/}
                        {/*                >*/}
                        {/*                    <source*/}
                        {/*                        src={membershipDetails.video}*/}
                        {/*                        type="video/mp4"*/}
                        {/*                    />*/}
                        {/*                </video>*/}
                        {/*            )}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="margin-left-15 xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
                            <div className="grid margin-right-0">
                                <div className="grid grid-cols-2 pr-0">
                                    <div className="col-span-2 grid grid-cols-2 ps-0 gap-4">
                                        <div className="col-span-2">
                                            <h1 className="mb-4 mt-5 header-large">
                                                {membershipDetails != null && membershipDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (
                                                    <>
                                                        Wallet Connected! <br/>
                                                        Review Membership Details.
                                                    </>
                                                ) : (
                                                    <>
                                                        Review Membership Details & <br/>
                                                        { hidePaymentMethod ?'& join the community.':' Select Payment Method' }
                                                    </>
                                                )}
                                            </h1>
                                            <p className="col-span-1 body-text--reg">
                                                To { hidePaymentMethod ?'get':'purchase' } this membership and join the community,
                                                complete the following information:
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-2 grid lg:grid-cols-2 md:grid-cols-1 xsm:gap-10 lg:gap-30 ">

                                        <div className="lg:hidden md:flex">
                                            {membershipItemImage(collectionAssetType, membershipDetails)}
                                        </div>

                                        <div className="lg:col-span-1">
                                            <h3 className="mb-4 mt-5 header-medium">
                                                {membershipDetails ? membershipDetails.name : ''}
                                            </h3>
                                            <p className="body-text--reg">
                                                Your membership for {' '}
                                                <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                </a>
                                                {' '}includes access, updates, benefits, and more.
                                                Memberships can be a one-time or monthly renewable cost.
                                                Review and confirm your Membership details below.
                                            </p>
                                            <Form
                                                form={membershipPaymentMethodForm}
                                                name="membershipPaymentMethod"
                                                id="membershipPaymentMethod"
                                                validateMessages={VALIDATE_MESSAGES}
                                                onFocus={() => {
                                                    setFormIsOnFocus(true);
                                                    setAlert(null);
                                                }}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        setFormIsOnFocus(false);
                                                        handleFormOnBlur();
                                                    }, 200);
                                                }}
                                                autoComplete="off"
                                                onChange={handleFormChanges}
                                                onFinish={handleSubmit}
                                                className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
                                            >

                                                <div className="grid grid-cols-12 mt-5">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            Membership Price
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        {membershipDetails != null &&
                                                            <p className="m-0 body-text--small gradient-color-txt text-center">
                                                                {getMembershipItemPrice(isSubscription, membershipDetails.type, membershipDetails)}
                                                            </p>
                                                        }
                                                    </div>
                                                </div>

                                                { membershipDetails != null && (
                                                    <>
                                                        { isSubscription &&
                                                            <div className="grid grid-cols-12 mt-5">
                                                                <div className="col-span-4">
                                                                    <p className="m-0 body-text--small gradient-color-txt">
                                                                        Included Months
                                                                    </p>
                                                                </div>
                                                                <div className="col-span-8">
                                                                    <p className="m-0 body-text--small gradient-color-txt text-center">
                                                                        {getIncludedMonths(membershipDetails.first_benefit.included_months)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }

                                                        {expirationDateDeductOneDay &&
                                                            <div className="grid grid-cols-12 mt-5">
                                                                <div className="col-span-4">
                                                                    <p className="m-0 body-text--small gradient-color-txt">
                                                                        Expiration Date
                                                                    </p>
                                                                </div>
                                                                <div className="col-span-8">
                                                                    <p className="m-0 body-text--small gradient-color-txt text-center">
                                                                        {/*{expirationDateDeductOneDay}*/}
                                                                        {expirationDate}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }

                                                        { isSubscription &&
                                                            <div className="grid grid-cols-12 mt-5 flex items-center">
                                                                <div className="col-span-4">
                                                                    <p className="m-0 body-text--small gradient-color-txt">
                                                                        Renewal
                                                                    </p>
                                                                </div>
                                                                <div className="col-span-8">
                                                                    <div className="input-group-com">
                                                                        <DropdownWithItems
                                                                            dropDownClass="w-full"
                                                                            customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                            title="Credit Card"
                                                                            selectedValue={selectedMonthlyPrice}
                                                                            listItems={autoMonthlyPrice}
                                                                            onDropdownChange={(value) =>
                                                                                setSelectedMonthlyPrice(value)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                )}

                                                {/*If not subscription and price is zero or free*/}
                                                {/*{ !hidePaymentMethod &&*/}
                                                {/*    <>*/}
                                                {/*        <h3 className="mb-4 mt-5 header-medium">*/}
                                                {/*            Payment Method*/}
                                                {/*        </h3>*/}
                                                {/*        <p className="body-text--reg">*/}
                                                {/*            All major debit cards, credit cards, Apple Pay, Google Pay,*/}
                                                {/*            and wallets are accepted. Your full card number is never*/}
                                                {/*            accessible and only the last 4 digits are used for identification.{' '}*/}
                                                {/*            /!*<a href="" className="gradient-color-txt">*!/*/}
                                                {/*            /!*    All payments are processed by Stripe using AES-256 Encryption for your*!/*/}
                                                {/*            /!*    security.*!/*/}
                                                {/*            /!*</a>*!/*/}
                                                {/*            {getSitewideLink('all_payments_stripe')}.*/}
                                                {/*        </p>*/}
                                                {/*        <div className="grid grid-cols-12 mt-5 glex items-center">*/}
                                                {/*            <div className="col-span-4">*/}
                                                {/*                <p className="m-0 body-text--small gradient-color-txt">*/}
                                                {/*                    Payment Method*/}
                                                {/*                </p>*/}
                                                {/*            </div>*/}
                                                {/*            <div className="col-span-8">*/}
                                                {/*                <div className="">*/}
                                                {/*                    <DropdownWithItems*/}
                                                {/*                        dropDownClass="w-full"*/}
                                                {/*                        customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"*/}
                                                {/*                        selectedValue={paymentMethod}*/}
                                                {/*                        listItems={SELECT_PAYMENT_METHOD}*/}
                                                {/*                        onDropdownChange={(value) =>*/}
                                                {/*                            setPaymentMethod(value)*/}
                                                {/*                        }*/}
                                                {/*                    />*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*        {paymentMethod === 'card' && (*/}
                                                {/*            <>*/}
                                                {/*                <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                    <div className="col-span-4">*/}
                                                {/*                        <p className="m-0 body-text--small gradient-color-txt">*/}
                                                {/*                            Select Card*/}
                                                {/*                        </p>*/}
                                                {/*                    </div>*/}
                                                {/*                    <div className="col-span-8">*/}
                                                {/*                        <div className="">*/}
                                                {/*                            <DropdownWithCards*/}
                                                {/*                                dropDownClass="w-full"*/}
                                                {/*                                customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"*/}
                                                {/*                                selectedValue={selectedCard}*/}
                                                {/*                                listItems={authUserInfo.payment_methods}*/}
                                                {/*                                onDropdownChange={(value) =>*/}
                                                {/*                                    setSelectedCard(value)*/}
                                                {/*                                }*/}
                                                {/*                            />*/}
                                                {/*                        </div>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}

                                                {/*                /!* Show this form when adding a new card *!/*/}
                                                {/*                /!* TODO-MJ: Create a component for the add card form *!/*/}
                                                {/*                {isAddCard &&*/}
                                                {/*                    <>*/}
                                                {/*                        <div*/}
                                                {/*                            className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Card Number</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div*/}
                                                {/*                                className="col-span-8 relative rounded-none ">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label="Card Number"*/}
                                                {/*                                    name="card_number"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                   <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='15 or 16 digits'*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}


                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Expiry & CVV/CVC</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div*/}
                                                {/*                                className="col-span-8 grid grid-cols-8 gap-4">*/}
                                                {/*                                <div*/}
                                                {/*                                    className="col-span-4 relative rounded-none ">*/}
                                                {/*                                    <Form.Item*/}
                                                {/*                                        label='Expiry'*/}
                                                {/*                                        name="expiry"*/}
                                                {/*                                        className="mb-0 hidden-label"*/}
                                                {/*                                        validateTrigger="onBlur"*/}
                                                {/*                                        rules={[*/}
                                                {/*                                            {*/}
                                                {/*                                                type: 'object',*/}
                                                {/*                                                required: true*/}
                                                {/*                                            }*/}
                                                {/*                                        ]}*/}
                                                {/*                                    >*/}
                                                {/*                                        <DatePicker*/}
                                                {/*                                            className="body-text--small !bg-[#ffffff80] !text-lg h-[40px] w-full"*/}
                                                {/*                                            picker="month"*/}
                                                {/*                                            format="MM/YY"*/}
                                                {/*                                            placeholder="MM/YY"*/}
                                                {/*                                            disabled={submittedAddCardForm}*/}
                                                {/*                                        />*/}
                                                {/*                                    </Form.Item>*/}
                                                {/*                                </div>*/}
                                                {/*                                <div*/}
                                                {/*                                    className="col-span-4 relative rounded-none">*/}
                                                {/*                                    <Form.Item*/}
                                                {/*                                        label='CVV/CVC'*/}
                                                {/*                                        name="cvc"*/}
                                                {/*                                        className="mb-0 hidden-label"*/}
                                                {/*                                        validateTrigger="onBlur"*/}
                                                {/*                                        rules={[{required: true}]}*/}
                                                {/*                                    >*/}
                                                {/*                                        <FormFieldStacked*/}
                                                {/*                                        formType='input'*/}
                                                {/*                                        type="number"*/}
                                                {/*                                        disabled={submittedAddCardForm}*/}
                                                {/*                                        placeholder='3 or 4 digits'>											*/}
                                                {/*                                        </FormFieldStacked>*/}
                                                {/*                                    </Form.Item>*/}
                                                {/*                                </div>*/}

                                                {/*                            </div>*/}
                                                {/*                        </div>*/}


                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Full Name</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='Full Name'*/}
                                                {/*                                    name="full_name"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                   <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='As shown on credit card'*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Country</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                            <DropdownWithCountries*/}
                                                {/*                                title="Country"*/}
                                                {/*                                displayError={*/}
                                                {/*                                    displayDropDownError &&*/}
                                                {/*                                    (!selectedCountry || selectedCountry === '')*/}
                                                {/*                                }*/}
                                                {/*                                value={selectedCountry}*/}
                                                {/*                                onDropdownChange={setSelectedCountry}*/}
                                                {/*                            ></DropdownWithCountries>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Street Address</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='Street Address'*/}
                                                {/*                                    name="street_address"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                formType='input'*/}
                                                {/*                                type="text"	*/}
                                                {/*                                disabled={submittedAddCardForm}*/}
                                                {/*                                placeholder='Number and Street'*/}
                                                {/*                                ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">APT/STE/BLDG</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='Address'*/}
                                                {/*                                    name="address"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    // rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='OPTIONAL'*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">City</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='City'*/}
                                                {/*                                    name="city"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}


                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">State & ZIP</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 grid grid-cols-8 gap-4">*/}
                                                {/*                                <div className="col-span-4 relative rounded-none">*/}
                                                {/*                                <DropdownWithRegions*/}
                                                {/*                                    title="State"*/}
                                                {/*                                    displayError={*/}
                                                {/*                                        displayDropDownError &&*/}
                                                {/*                                        (!selectedRegion || selectedRegion === '')*/}
                                                {/*                                    }*/}
                                                {/*                                    country={selectedCountry}*/}
                                                {/*                                    value={selectedRegion}*/}
                                                {/*                                    onDropdownChange={setSelectedRegion}*/}
                                                {/*                                ></DropdownWithRegions>*/}
                                                {/*                                </div>*/}

                                                {/*                                <div className="col-span-4 relative rounded-none  pt-2">*/}
                                                {/*                                    <Form.Item*/}
                                                {/*                                        label='Zip'*/}
                                                {/*                                        name="zip"*/}
                                                {/*                                        className="mb-0 hidden-label"*/}
                                                {/*                                        validateTrigger="onBlur"*/}
                                                {/*                                        rules={[{required: true}]}*/}
                                                {/*                                    >*/}
                                                {/*                                        <FormFieldStacked*/}
                                                {/*                                        formType='input'*/}
                                                {/*                                        type="number"*/}
                                                {/*                                        disabled={submittedAddCardForm}*/}
                                                {/*                                        placeholder='5 digits'>											*/}
                                                {/*                                    </FormFieldStacked>*/}
                                                {/*                                    </Form.Item>*/}
                                                {/*                                </div>*/}
                                                {/*                            </div>*/}

                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <label className="body-text--small">Phone Number</label>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    label='Phone Number'*/}
                                                {/*                                    name="phone_number"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    disabled={submittedAddCardForm}*/}
                                                {/*                                    placeholder='10 digits'*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}

                                                {/*                        <div className="grid grid-cols-12 mt-5 flex items-center">*/}
                                                {/*                            <div className="col-span-4">*/}
                                                {/*                                <p className="m-0 body-text--small gradient-color-txt">*/}
                                                {/*                                    Promo Code*/}
                                                {/*                                </p>*/}
                                                {/*                            </div>*/}
                                                {/*                            <div className="col-span-8 relative rounded-none">*/}
                                                {/*                                <Form.Item*/}
                                                {/*                                    name="promo_code"*/}
                                                {/*                                    className="mb-0 hidden-label"*/}
                                                {/*                                    validateTrigger="onBlur"*/}
                                                {/*                                    // rules={[{required: true}]}*/}
                                                {/*                                >*/}
                                                {/*                                    <FormFieldStacked*/}
                                                {/*                                    formType='input'*/}
                                                {/*                                    type="text"	*/}
                                                {/*                                    ></FormFieldStacked>*/}
                                                {/*                                </Form.Item>*/}

                                                {/*                            </div>*/}
                                                {/*                        </div>*/}
                                                {/*                    </>*/}
                                                {/*                }*/}
                                                {/*            </>*/}
                                                {/*        )}*/}
                                                {/*    </>*/}
                                                {/*}*/}

                                                <h3 className="mb-4 mt-5 header-medium">
                                                    Review & Confirm
                                                </h3>

                                                <p className="body-text--reg">
                                                    By clicking “Purchase Membership” below, you are authorizing
                                                    {' '}
                                                    <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                        <br/><i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                    </a>{' '}
                                                    to charge your Payment Method for:
                                                </p>

                                                {!isSubscription && membershipDetails != null &&
                                                    <p className="body-text--big">
                                                        ${(membershipDetails.price != null ? parseFloat(membershipDetails.price).toFixed(2) : 0) + ' ' + (membershipDetails.currency != null ? membershipDetails.currency : 'USD') } total
                                                    </p>
                                                }

                                                {isSubscription && membershipDetails != null &&
                                                    <>
                                                        <p className="body-text--big">
                                                            ${(membershipDetails.price != null ? parseFloat(membershipDetails.price).toFixed(2) : 0) + ' ' + (membershipDetails.currency != null ? membershipDetails.currency : 'USD') } total
                                                            { (selectedMonthlyPrice !== Recurring.autoRenewOff) &&
                                                                <>
                                                                    &nbsp;+<br/>
                                                                </>
                                                            }

                                                        </p>
                                                        <p className="body-text--big">
                                                            {getExpirationMonthlyStart(selectedMonthlyPrice, autoMonthlyPrice, membershipDetails.first_benefit.currency, expirationDate, membershipDetails.first_benefit.included_months)}
                                                        </p>
                                                    </>
                                                }


                                                <div className="form-check mt-3">
                                                    <label
                                                        className="form-check-label flex items-center">
                                                            <Checkbox
                                                                checked={agreementChecked1}
                                                                className="inline-flex form-check-input items-center mr-8 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                onChange={onChangeAgreement1}
                                                            ></Checkbox>
                                                        <label className="form-check-label body-text--small">
                                                            <p className="body-txtitalic--small m-0">
                                                                By joining
                                                                {' '}<i className="fa-light fa-solar-system"></i>
                                                                {' '}{spaceInfo ? spaceInfo.name : ''}
                                                                {' '}, you agree to their {' '}
                                                                <a className="gradient-color-txt"
                                                                   href={routes.viewUserSpace() + spaceInfo?.username + '/terms-services'}
                                                                   target="_blank"
                                                                >
                                                                    Terms of Service
                                                                </a>
                                                                {' '}and{' '}
                                                                <a className="gradient-color-txt"
                                                                   href={routes.viewUserSpace() + spaceInfo?.username + '/privacy-policy'}
                                                                   target="_blank"
                                                                >
                                                                    Privacy Policy
                                                                </a>
                                                            </p>
                                                        </label>
                                                    </label>
                                                </div>
                                                <div className="form-check mt-3">
                                                    <div className="form-check-label flex items-center ">
                                                        <Checkbox
                                                                    checked={agreementChecked2}
                                                                    className="inline-flex form-check-input items-center mr-8 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                    onChange={onChangeAgreement2}
                                                                ></Checkbox>
                                                        <label className="form-check-label body-text--small ">
                                                            
                                                            <p className="body-txtitalic--small m-0">
                                                                By continuing, you agree to the Commonspace
                                                                {' '}
                                                                {getSitewideLink('terms_of_service_space_create')}
                                                                {' '},
                                                                {getSitewideLink('privacy_policy_space_create')}
                                                                ,{' '}and{' '}
                                                                {getSitewideLink('community_guidelines_space_create')}
                                                            </p>
                                                        </label>
                                                    </div>
                                                </div>

                                                <>
                                                    {alert && (
                                                        <div className="col-span-12 mb-3 mt-3">
                                                            <Alert
                                                                key={alert.variant}
                                                                variant={alert.variant}
                                                                className={
                                                                    'custom-alert ' +
                                                                    (alert.variant === 'danger'
                                                                        ? 'custom-alert-danger'
                                                                        : 'custom-alert-success')
                                                                }
                                                            >
                                                                {alert.message}
                                                            </Alert>
                                                        </div>
                                                    )}
                                                </>

                                                {hidePaymentMethod && membershipDetails != null &&
                                                    <div className="input-group-com my-5">
                                                        <Form.Item className="mb-0">
                                                            <Button
                                                                className="btn btn-primary btn-lg"
                                                                htmlType="submit"
                                                                disabled={!agreementChecked1 || !agreementChecked2}
                                                            >
                                                                {submitted && (
                                                                    <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                )}
                                                                {getButtonPaymentText(isSubscription, membershipDetails.type, selectedMonthlyPrice)}
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                }

                                                {!hidePaymentMethod && membershipDetails != null &&
                                                    <div className="input-group-com my-5">
                                                        <Form.Item className="mb-0">
                                                            <Button
                                                                className="btn btn-primary btn-lg"
                                                                htmlType="submit"
                                                                disabled={
                                                                    // !isAddCard && (
                                                                    //     selectedCard.value === 'default' ||
                                                                    //     selectedCard.value === 'add' ||
                                                                    //     submitted ||
                                                                    //     !agreementChecked1 ||
                                                                    //     !agreementChecked2) ||
                                                                    // isAddCard && (
                                                                        submitted ||
                                                                        formHasError ||
                                                                        !agreementChecked1 ||
                                                                        !agreementChecked2
                                                                    // )
                                                                }
                                                            >
                                                                {submitted && (
                                                                    <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                )}
                                                                {getButtonPaymentText(isSubscription, membershipDetails.type, selectedMonthlyPrice)}
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                }

                                            </Form>
                                        </div>
                                        <div className="lg:inline xsm:hidden p-0">
                                            {membershipItemImage(collectionAssetType, membershipDetails)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <div className="left-image-holder"></div>
                                </div>
                            </div>
                        </div>
                    </>
                    )}

            </main>
        </>
    );
}

export default memo(MembershipPaymentMethod);