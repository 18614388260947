import {memo, useCallback, useEffect, useState} from 'react';
import ReactPlayer from 'react-player/lazy';
import {Button} from 'antd';
import routes from '../../../routes';
import {useNavigate} from 'react-router-dom';
import default_items_img from '../../../assets/images/form_background.png';
import {getCommunityBtnText} from "../../../components/commons/helpers/MembershipItemHelpers";
import {MEMBERSHIP_TYPES} from "../../../constants/space";

const Communities = (parentProps) => {
    const {data, user, spaceData, updateGlobalActiveTab} = parentProps;
    const navigate = useNavigate();
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
    const [loadOnce, setLoadOnce] = useState(false);
    const [isPaid, setIsPaid] = useState(false);

    useEffect(() => {
        if (!loadOnce) {
            setLoadOnce(true);
            if (data.photo || data.video) {
                if (data.photo) {
                    const bg = {
                        backgroundImage: 'url(' + data.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (data.video) {
                    const bg = {
                        backgroundImage: 'none'
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [data]);



    const goToMembership = useCallback(async () => {
        if (data.collection_type === 'membership') {
            let url = routes.spaceMembersProductsMembership();
            if (data.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
                updateGlobalActiveTab('claim_membership');
            } else {
                url = routes.spaceMembershipBuy();
                updateGlobalActiveTab('buy_membership');
            }
            const fin_url =
                url.replace(':space_username', '@' + spaceData.username) + '?id=' + data.id;
            navigate(fin_url, { replace: true });
        }
        if (data.collection_type === 'item') {
            let url = routes.spaceMembersProductsItem();
            if (data.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
                updateGlobalActiveTab('claim_item');
            } else {
                url = routes.spaceItemBuy();
                updateGlobalActiveTab('buy_item');
            }
            const fin_url =
                url.replace(':space_username', '@' + spaceData.username) + '?id=' + data.id;
            navigate(fin_url, { replace: true });
        }
    }, [data, spaceData]);

    return (
        <>
            {data && data.id ? (
                <div className="card-join-com" style={collectionAssetBGImage}>
                    {collectionAssetType && collectionAssetType === 'video' && (
                        <video autoPlay muted loop className="membership-video">
                            <source src={data.video} type="video/mp4"/>
                        </video>
                    )}
                    <div className="details">
                        <p className="body-text--small color-lightest">
                            {data.name}&nbsp;
                            <i className="fa-regular fa-key"></i>
                        </p>
                        <p className="body-txtitalic--smallest color-lightest">
                            {data.summary}
                        </p>

                        <Button
                            className={
                                'btn btn-secondary btn-md mx-auto my-0 color-lightest mt-4 mb-2'
                            }
                            disabled={isPaid}
                            onClick={goToMembership}
                        >
                            {getCommunityBtnText(data, false)}
                        </Button>
                    </div>
                    <div className="bg-overlay"></div>
                </div>
            ) : (
                <>
                    {spaceData.viewing_user_is_owner && (
                        // <div className="col-4">
                            <div className="card-join-com space-placeholder items-center">
                                <h6 className="header-title text-center">
                                    Create additional Memberships & Items <br/>
                                    from your Dashboard
                                </h6>
                            </div>
                        // </div>
                    )}
                </>
            )}
        </>
    );
};

export default memo(Communities);
