import { memo, useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import parse from 'html-react-parser';

import routes from '../../../routes';
import { PRONOUNS } from '../../../constants/profile';
import { STORAGE_KEYS } from '../../../constants/common';
import { getCookie, getLocalStorage, setCookie } from '../../../utils/storage';
import { getDataByUsername } from '../../../services/general';
import {
	getUserByUsername,
	getProfileAboutMe,
	getUserSpaces,
	getMyCollections
} from '../../../services/user';
import {
	followUser,
	getFollowersList,
	getFollowingsList,
	unfollowUser
} from '../../../services/follow';
import { getListDisplay } from '../../../utils/list';
import { shortenNumber } from '../../../utils/number';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import Spaces from './Spaces';
import Collections from './Collections';
import Activity from './Activity';
import Following from './Following';
import Followers from './Followers';
import Top8 from './Top8';
import SpaceView from './../../SpaceView';
import SpaceViewPro from './../../SpaceView/SpaceViewPro';
import SpaceOffline from './../../SpaceView/SpaceOffline';
import SpaceOfflinePro from './../../SpaceView/SpaceOffline/Pro/SpaceOfflinePro';
import SpacePasswordProtected from '../../SpaceView/SpacePasswordProtected/SpacePasswordProtected';
import SpacePasswordProtectedPro from '../../SpaceView/SpacePasswordProtected/Pro/SpacePasswordProtectedPro';
import SpaceEmailInvitesOnly from '../../SpaceView/SpaceEmailInvitesOnly/SpaceEmailInvitesOnly';
import SpaceEmailInvitesOnlyPro from '../../SpaceView/SpaceEmailInvitesOnly/Pro/SpaceEmailInvitesOnlyPro';

import profile_sample_thumb from '../../../assets/images/sample_thumb.png';
import default_profile_card_bg from '../../../assets/images/profile_header.png';

import './UserView.scss';
import Pagination from 'react-bootstrap/Pagination';
import userView from './index';
import { displayPageContentLoader, hidePageContentLoader } from '../../../states/pageContentLoader';
import { vi } from 'date-fns/locale';

const UserView = (props) => {
	const { globalActiveTab, spaceViewData, updateGlobalActiveTab } = props;
	const currentLocation = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [fetchingData, setFetchingData] = useState(false);
	const [userFound, setUserFound] = useState(false);
	const [viewUser, setViewUser] = useState(null);
	const [followingRequested, setFollowingRequested] = useState(false);
	const [backgroundBG, setBackgroundBG] = useState(null);
	const [aboutMeContent, setAboutMeContent] = useState('');
	const [fetchingAboutMe, setFetchingAboutMe] = useState(false);
	const [spaceData, setSpaceData] = useState(null);
	const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] = useState(null);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [cardBackgroundType, setCardBackgroundType] = useState(null);
	const [spaceUsername, setSpaceUsername] = useState(null);
	const { size } = useSelector((state) => state.classSize);
	const [initialLoad, setInitialLoad] = useState(true);
	const [spaceShowOfflineMode, setSpaceShowOfflineMode] = useState(false);
	const [spaceShowPasswordProtected, setSpaceShowPasswordProtected] = useState(false);
	const [spaceShowEmailInvitesOnly, setSpaceShowEmailInvitesOnly] = useState(false);
	const [initialSpaceFetchNoUser, setInitialSpaceFetchNoUser] = useState(false);
	const [passwordProtectedError, setPasswordProtectedError] = useState(false);
	const [spacesList, setSpacesList] = useState(null);
	const [spaceListLoading, setSpaceListLoading] = useState(false);
	const [followersListLoading, setFollowersListLoading] = useState(false);
	const [followingListLoading, setFollowingListLoading] = useState(false);
	const [collectionsList, setCollectionsList] = useState(null);
	const [followersList, setFollowersList] = useState(null);
	const [followingsList, setFollowingsList] = useState(null);
	const [followersCurrentPage, setFollowersCurrentPage] = useState(1);
	const [followingsCurrentPage, setFollowingsCurrentPage] = useState(1);
	const [followersPagination, setFollowersPagination] = useState([]);
	const [followingsPagination, setFollowingsPagination] = useState([]);

	const buildPagination = (type, current_page, max_page) => {
		const items = [];
		if (max_page) {
			for (let number = 1; number <= max_page; number++) {
				items.push(
					<Pagination.Item key={number} active={number === current_page}>
						{number}
					</Pagination.Item>
				);
			}
		}

		if (type === 'following') {
			setFollowingsPagination(items);
		}
		if (type === 'followers') {
			setFollowersPagination(items);
		}
	};

	const handleOnClickPagination = useCallback(
		async (event, type) => {
			const itemClicked = event.target.text;
			if (itemClicked) {
				const number = parseInt(itemClicked);
				if (type === 'followers') {
					setFollowersCurrentPage(number);
					await getFollowersRequest(number);
				}
				if (type === 'following') {
					setFollowingsCurrentPage(number);
					await getFollowingsRequest(number);
				}
			}
		},
		[viewUser]
	);

	const top8 = [
		{
			type: 'user',
			username: 'smi',
			photo: profile_sample_thumb,
			followers: 2200,
			followings: 288
		},
		{
			type: 'space',
			space_name: 'DRMVRSE',
			photo: profile_sample_thumb,
			members: 2200
		}
	];

	const isOwnProfile = (viewUser, authUserInfo) => {
		if (authUserInfo && viewUser) {
			if (authUserInfo.id === viewUser.id) {
				return true;
			}
		}
		return false;
	};

	const setSpacesCount = (viewUser) => {
		let count = viewUser.spaces_count;
		if (viewUser.spaces_count < viewUser.owned_spaces_count) {
			count = viewUser.owned_spaces_count;
		}
		return shortenNumber(count);
	};

	const setCollectionsCount = (viewUser) => {
		return shortenNumber(viewUser.collections_count);
	};

	const setUserBackground = (user) => {
		if (user.profile.background_photo || user.profile.background_video) {
			if (user.profile.background_photo) {
				const space_bg = {
					backgroundImage: 'url(' + user.profile.background_photo + ')'
				};
				setBackgroundBG(space_bg);
				setCardBackgroundType('image');
			}
			if (user.profile.background_video) {
				setProfileBackgroundVideoBlobPreview(user.profile.background_video);
				setCardBackgroundType('video');
			}
		} else {
			const space_bg = {
				backgroundImage: 'url(' + default_profile_card_bg + ')'
			};
			setBackgroundBG(space_bg);
			setCardBackgroundType('image');
		}
	};

	const displayOwnProfileButtons = useCallback(() => {
		return isOwnProfile(viewUser, authUserInfo);
	}, [viewUser, authUserInfo]);

	const handleFollowUser = useCallback(() => {
		if (authUserInfo && viewUser) {
			const data = {
				user_id: authUserInfo.id,
				follow_id: viewUser.id
			};

			setFollowingRequested(true);
			followUserRequest(data).then((r) => {});
		}
	}, [viewUser, authUserInfo]);

	const followUserRequest = async (data) => {
		try {
			let response;
			if (viewUser.is_following) {
				response = await unfollowUser(data);
			} else {
				response = await followUser(data);
			}

			if (response && response.result) {
				await getUserProfile(viewUser.profile.username);
			}
		} catch (error) {
			console.log(error);
			setFollowingRequested(false);
		}
	};

	const fetchViewData = useCallback(
		async (initialLoad, username, loggedInUser = null, view_space_password = null) => {
			if (username) {
				try {
					let ignoreSpaceNotActiveRedirect = false;
					let bypassFirst = false;
					setFetchingData(true);

					const data = {
						username: username
					};

					if (view_space_password) {
						setPasswordProtectedError(false);
						data.view_space_password = view_space_password;
					}

					if (loggedInUser) {
						data['viewing_user_id'] = loggedInUser.id;
					} else {
						if (!initialSpaceFetchNoUser) {
							setInitialSpaceFetchNoUser(true);
						} else {
							if (!userFound) {
								return;
							}
						}
					}

					const response = await getDataByUsername(data);
					setFetchingData(false);
					//dispatch(displayPageContentLoader());

					let type = 'online';
					if (!response.result) {
						if (response.space_offline) {
							type = 'offline';
							setSpaceData(response.space);
							//dispatch(hidePageContentLoader());
							setSpaceShowOfflineMode(true);
						} else if (response.email_invites_only) {
							type = 'email_invites_only';
							setSpaceData(response.space);
							//dispatch(hidePageContentLoader());
							setSpaceShowEmailInvitesOnly(true);
						} else {
							// on first load check if user token is available(user is logged in)
							// if YES and no 'viewing_user_id' is included on request payload, it means that fetch user request is still not done
							const accessToken = JSON.parse(
								JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN))
							);
							const refreshToken = JSON.parse(
								JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN))
							);

							if (response.password_protected) {
								type = 'password_protected';
								if (accessToken && refreshToken) {
									if (!loggedInUser) {
										ignoreSpaceNotActiveRedirect = true;
										bypassFirst = true;
									} else {
										ignoreSpaceNotActiveRedirect = true;
										bypassFirst = false;
									}
								} else {
									ignoreSpaceNotActiveRedirect = true;
									bypassFirst = false;
								}
							} else {
								if (accessToken && refreshToken) {
									if (!loggedInUser) {
										ignoreSpaceNotActiveRedirect = true;
										bypassFirst = true;
									}
								}
							}
						}
					} else {
						ignoreSpaceNotActiveRedirect = true;
					}

					if (loggedInUser) {
						if (type === 'email_invites_only' && initialLoad && loggedInUser) {
							console.log('rerequest');
						}
					} else {
						if (type === 'offline' || type === 'email_invites_only') {
							return;
						}
					}

					if (type === 'online') {
						setSpaceShowOfflineMode(false);
						setSpaceShowEmailInvitesOnly(false);
					}

					if (!ignoreSpaceNotActiveRedirect) {
						// navigate(routes.app());
						dispatch(hidePageContentLoader());
					} else {
						if (initialLoad) {
							if (response.user || (response.space && !response.password_protected)) {
								if (response.user) {
									setUserFound(true);
									setViewUser(response.user);
									//dispatch(hidePageContentLoader());
									setUserBackground(response.user);

									const data = {
										user_id: response.user.id
									};
									getAboutRequest(data).then((r) => {});
								} else {
									dispatch(
										getSpaceInfo(
											username,
											loggedInUser ? loggedInUser.id : null
										)
									);
									setSpaceData(response.space);
									//dispatch(hidePageContentLoader());
								}
							} else {
								if (!bypassFirst) {
									if (response.password_protected) {
										setSpaceData(response.space);
										//dispatch(hidePageContentLoader());
										setSpaceShowPasswordProtected(true);
									}
								}
							}
						} else {
							if (view_space_password) {
								if (response.space_not_found) {
									// navigate(routes.app());
									setPasswordProtectedError(true);
								} else {
									// set 30 days expiration for view space password
									const today = new Date();
									const tomorrow = new Date();
									tomorrow.setDate(today.getDate() + 30);
									setCookie('view_space_password', view_space_password, {
										expires: tomorrow
									});

									dispatch(
										getSpaceInfo(
											username,
											loggedInUser ? loggedInUser.id : null
										)
									);
									setSpaceData(response.space);
									//dispatch(hidePageContentLoader());
									setSpaceShowPasswordProtected(false);
								}
							}
						}
					}
				} catch (error) {
					console.log(error);
				}
			}
		},
		[userFound, authUserInfo, initialSpaceFetchNoUser]
	);

	const getUserProfile = async (username) => {
		try {
			const data = {
				username
			};
			if (authUserInfo) {
				data['viewing_user_id'] = authUserInfo.id;
			}

			//dispatch(hidePageContentLoader());

			await getUserByUsername(data).then((response) => {
				if (response && response.result) {
					setUserFound(true);
					setViewUser(response.user);
					setUserBackground(response.user);

					const data = {
						user_id: response.user.id
					};
					getAboutRequest(data).then((r) => {});

					//dispatch(displayPageContentLoader());
				} else {
					console.log('user not found');
				}
				setFollowingRequested(false);
			});
		} catch (error) {
			console.log(error);
		}
	};

	const getAboutRequest = async (data) => {
		try {
			setFetchingAboutMe(true);
			const result = await getProfileAboutMe(data);
			if (result && result.result) {
				if (result.about_me) {
					setAboutMeContent(result.about_me);
				}
			}
			setFetchingAboutMe(false);
		} catch (error) {
			console.log(error);
		}
	};

	const getCollectionsRequest = async (data) => {
		try {
			const response = await getMyCollections(data);
			if (response && response.result) {
				setCollectionsList(response.collections);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getSpacesRequest = async (data) => {
		try {
			setSpaceListLoading(true);
			const result = await getUserSpaces(data);
			if (result && result.result) {
				setSpaceListLoading(false);
				setSpacesList(result.spaces);
			}
		} catch (error) {
			console.log(error);
			setSpaceListLoading(false);
		}
	};

	const getFollowingsRequest = useCallback(
		async (pageNumber = null) => {
			try {
				if (viewUser) {
					const data = {
						user_id: viewUser.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};

					setFollowingListLoading(true);

					const response = await getFollowingsList(data);
					if (response && response.result) {
						setFollowingListLoading(false);
						setFollowingsList(response.data);
						buildPagination(
							'following',
							parseInt(response.page),
							parseInt(response.page_range)
						);
					}
				}
			} catch (error) {
				console.log(error);
				setFollowingListLoading(false);
			}
		},
		[viewUser]
	);

	const getFollowersRequest = useCallback(
		async (pageNumber = null) => {
			try {
				if (viewUser) {
					const data = {
						user_id: viewUser.id,
						list_count: 10,
						page: pageNumber ? pageNumber : 1
					};

					setFollowersListLoading(true);

					const response = await getFollowersList(data);
					if (response && response.result) {
						setFollowersListLoading(false);
						setFollowersList(response.data);
						buildPagination(
							'followers',
							parseInt(response.page),
							parseInt(response.page_range)
						);
					}
				}
			} catch (error) {
				console.log(error);
				setFollowersListLoading(false);
			}
		},
		[viewUser]
	);

	const handlePasswordSubmit = useCallback(
		async (data) => {
			fetchViewData(false, data.username, authUserInfo, data.password).then((r) => {});
		},
		[authUserInfo]
	);

	const handleGlobalActiveChange = useCallback((tab) => {
		updateGlobalActiveTab(tab);
	}, []);

	const checkSectionVisibility = useCallback(
		(section) => {
			if (viewUser && authUserInfo) {
				if (viewUser.id === authUserInfo.id) {
					return true;
				}
			}

			let allowDisplay = false;
			if (viewUser && viewUser.profile && viewUser.profile.visibility) {
				const profileVisibilitySettings = viewUser.profile.visibility;
				if (profileVisibilitySettings[section]) {
					const sectionVisibility = profileVisibilitySettings[section];
					if (sectionVisibility === 'followers') {
						// check if viewing user is a follower of viewed profile
						if (viewUser.is_following) {
							allowDisplay = true;
						}
					} else if (sectionVisibility === 'hidden') {
						allowDisplay = false;
					} else if (sectionVisibility === 'mutuals') {
						if (viewUser.is_mutual) {
							allowDisplay = true;
						}
					} else if (sectionVisibility === 'private') {
						if (viewUser.is_following) {
							allowDisplay = true;
						}
					} else {
						allowDisplay = true;
					}
						
					
				}
				
			}
			
			return allowDisplay;
		},
		[authUserInfo, viewUser]
	);

	const handleRefetchFollowings = useCallback(async () => {
		getFollowingsRequest(followingsCurrentPage).then((r) => {});
	}, [followingsCurrentPage]);

	const handleRefetchFollowers = useCallback(async () => {
		getFollowingsRequest(followersCurrentPage).then((r) => {});
	}, [followersCurrentPage]);

	const tabs = [
		{ id: 'about', condition: checkSectionVisibility('about_me'), active: ['home', 'about'] },
		{ id: 'spaces', condition: checkSectionVisibility('spaces_list') && viewUser && (viewUser.spaces_count > 0 || viewUser.owned_spaces_count > 0) },
		{ id: 'collections', condition: checkSectionVisibility('collections') && viewUser && viewUser.collections_count > 0 },
		{ id: 'activity', condition: checkSectionVisibility('activity') },
		{ id: 'following', condition: checkSectionVisibility('follower_lists') },
		{ id: 'followers', condition: checkSectionVisibility('follower_lists') },
	];


	useEffect(() => {
		if (viewUser || spaceData) {
			spaceViewData(spaceData);
			dispatch(hidePageContentLoader());
		} else {
			dispatch(displayPageContentLoader());
		}
	}, [spaceData, viewUser]);

	useEffect(() => {
		// const path = currentLocation.pathname;
		const path = window.location.pathname;
		let title = 'Commonspace';
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.replace('/@', '');

			if (username.includes('/')) {
				if (username.includes('membership/claim')) {
					updateGlobalActiveTab('claim_membership');
				} else if (username.includes('membership/buy')) {
					updateGlobalActiveTab('buy_membership');
				} else if (username.includes('item/buy')) {
					updateGlobalActiveTab('buy_item');
				}

				if (username.includes('item/claim')) {
					updateGlobalActiveTab('claim_item');
				}
				const usernameExp = username.split('/');
				username = usernameExp[0] ? usernameExp[0] : '';
			}


			title = 'Commonspace @' + username;
			if (!userFound) {
				const view_space_password = getCookie('view_space_password');
				if (view_space_password) {
					fetchViewData(initialLoad, username, authUserInfo, view_space_password).then(
						(r) => {}
					);
				} else {
					fetchViewData(initialLoad, username, authUserInfo).then((r) => {});
				}
			} else {
				fetchViewData(initialLoad, username, authUserInfo).then((r) => {});
			}
		}

		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}

		if (spaceUsername != null) {
			let isUserHome = false;
			if (userFound) {
				if (globalActiveTab === 'home') {
					isUserHome = true;
				}
			}

			if (isUserHome || globalActiveTab === 'about') {
				if (!path.includes('about')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/about');
				}
			} else if (globalActiveTab === 'spaces') {
				if (!path.includes('spaces')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/spaces');
				}
			} else if (globalActiveTab === 'collections') {
				if (!path.includes('collections')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/collections');
				}
			} else if (globalActiveTab === 'activity') {
				if (!path.includes('activities')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/activities');
				}
			} else if (globalActiveTab === 'following') {
				if (!path.includes('following')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/following');
				}
			} else if (globalActiveTab === 'followers') {
				if (!path.includes('followers')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/followers');
				}
			} else if (globalActiveTab === 'faq') {
				if (!path.includes('faq')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/faq');
				}
			} else if (globalActiveTab === 'terms-services') {
				if (!path.includes('terms-services')) {
					window.history.replaceState(
						null,
						null,
						'/@' + spaceUsername + '/terms-services'
					);
				}
			} else if (globalActiveTab === 'privacy-policy') {
				if (!path.includes('privacy-policy')) {
					window.history.replaceState(
						null,
						null,
						'/@' + spaceUsername + '/privacy-policy'
					);
				}
			}
		}

		if (authUserInfo && authUserInfo.profile) {
			if (spaceUsername === authUserInfo.profile.username) {
				if (authUserInfo.profile.photo_large) {
					setProfileAssetType('image');
				}
				if (authUserInfo.profile.video) {
					setProfileAssetType('video');
				}
				if (authUserInfo.profile.background_photo) {
					const space_bg = {
						backgroundImage: 'url(' + authUserInfo.profile.background_photo + ')'
					};
					setBackgroundBG(space_bg);
					setCardBackgroundType('image');
				}
				if (authUserInfo.profile.background_video) {
					setProfileBackgroundVideoBlobPreview(authUserInfo.profile.background_video);
					setCardBackgroundType('video');
				}
			}
		}

		document.title = title;

		if (viewUser) {
			if (viewUser.profile.photo_large) {
				setProfileAssetType('image');
			}
			if (viewUser.profile.video) {
				setProfileAssetType('video');
			}
			if (viewUser.profile.background_photo) {
				const space_bg = {
					backgroundImage: 'url(' + viewUser.profile.background_photo + ')'
				};
				setBackgroundBG(space_bg);
				setCardBackgroundType('image');
			}
			if (viewUser.profile.background_video) {
				setProfileBackgroundVideoBlobPreview(viewUser.profile.background_video);
				setCardBackgroundType('video');
			}


			if (globalActiveTab === 'home' || globalActiveTab === 'about') {4
				getAboutRequest({ user_id: viewUser.id }).then((r) => {});
			} else if (globalActiveTab === 'spaces') {
				let requestSpaces = false;
				if (spacesList) {
					if (spacesList.length === 0) {
						requestSpaces = true;
					}
				} else {
					requestSpaces = true;
				}

				if (requestSpaces) {
					getSpacesRequest({ user_id: viewUser.id }).then((r) => {});
				}
			} else if (globalActiveTab === 'collections') {
				let requestCollections = false;
				if (collectionsList) {
					if (collectionsList.length === 0) {
						requestCollections = true;
					}
				} else {
					requestCollections = true;
				}
				if (requestCollections) {
					getCollectionsRequest({ user_id: viewUser.id }).then((r) => {});
				}
			} else if (globalActiveTab === 'activity') {
			} else if (globalActiveTab === 'following') {
				let requestFollowings = false;
				if (followingsList) {
					if (followingsList.length === 0) {
						requestFollowings = true;
					}
				} else {
					requestFollowings = true;
				}
				if (requestFollowings) {
					getFollowingsRequest(followingsCurrentPage).then((r) => {});
				}
			} else if (globalActiveTab === 'followers') {
				let requestFollowers = false;
				if (followersList) {
					if (followersList.length === 0) {
						requestFollowers = true;
					}
				} else {
					requestFollowers = true;
				}
				if (requestFollowers) {
					getFollowersRequest(followersCurrentPage).then((r) => {});
				}
			} else {
				// others
			}
		}

	}, [
		currentLocation.pathname,
		authUserInfo,
		followingRequested,
		globalActiveTab,
		spaceUsername,
		userFound,
		initialLoad,
		followingsCurrentPage,
		followersCurrentPage,
	]);

	return (
		<>
			{spaceShowOfflineMode || spaceShowPasswordProtected || spaceShowEmailInvitesOnly ? (
				<>
					{spaceShowOfflineMode && (
						<>
							{spaceData.plan === 'pro' ? (
								<SpaceOfflinePro spaceData={spaceData}></SpaceOfflinePro>
							) : (
								<SpaceOffline spaceData={spaceData}></SpaceOffline>
							)}
						</>
					)}
					{spaceShowPasswordProtected && (
						<>
							{spaceData.plan === 'pro' ? (
								<SpacePasswordProtectedPro
									spaceData={spaceData}
									authUserInfo={authUserInfo}
									onPasswordSubmit={handlePasswordSubmit}
									displayPasswordError={passwordProtectedError}
								></SpacePasswordProtectedPro>
							) : (
								<SpacePasswordProtected
									spaceData={spaceData}
									authUserInfo={authUserInfo}
									onPasswordSubmit={handlePasswordSubmit}
									displayPasswordError={passwordProtectedError}
								></SpacePasswordProtected>
							)}
						</>
					)}
					{spaceShowEmailInvitesOnly && (
						<>
							{spaceData.plan === 'pro' ? (
								<SpaceEmailInvitesOnlyPro
									spaceData={spaceData}
								></SpaceEmailInvitesOnlyPro>
							) : (
								<SpaceEmailInvitesOnly
									spaceData={spaceData}
								></SpaceEmailInvitesOnly>
							)}
						</>
					)}
				</>
			) : (
				<>
					{spaceData ? (
						<>
						
							{spaceData.plan === 'pro' ? (
								<>
									<SpaceViewPro
										spaceData={spaceData}
										globalActiveTab={globalActiveTab}
										updateGlobalActiveTab={handleGlobalActiveChange}
									></SpaceViewPro>
								</>
							) : (
								<>
									<SpaceView
										spaceData={spaceData}
										globalActiveTab={globalActiveTab}
										updateGlobalActiveTab={handleGlobalActiveChange}
									></SpaceView>
								</>
							)}
						</>
					) : (
						<>
							<main id="profile">
								<div className="w-full profile-banner py-8 min-h-[1097px] lg:min-h-[444px] flex items-center" style={backgroundBG}>
									
									{cardBackgroundType && cardBackgroundType === 'video' && (
										<video
											autoPlay
											muted
											loop
											className="user-view-video-profile-background"
										>
											<source
												src={profileBackgroundVideoBlobPreview}
												type="video/mp4"
											/>
										</video>
									)}
									<div
										className={
											'container h-full  flex items-center grid grid-cols-12 min-[1400px]:grid-rows-5 min-[1400px]:h-[316px] gap-y-8 min-[1400px]:gap-y-0 min-[1400px]:gap-8' +
											(cardBackgroundType === 'video' ? ' z-index-ontop ' : '')
										}
									>
										{!fetchingData && userFound && (
											<>
													<div className='flex justify-center col-span-12 min-[1400px]:col-span-3 min-[1400px]:row-span-3 min-[1400px]:row-start-2   relative'>
														<div className='relative'>
															<div className='flex h-full rounded-[50%]'>
																<div className='image text-white flex justify-center items-center h-full w-full'>
																	{profileAssetType &&
																	profileAssetType === 'video' ? (
																	<div className='block '>
																		
																		<div className="user-view-video-profile-container">
																			
																			<video
																				autoPlay
																				muted
																				loop
																				className="user-view-video-profile-avatar"
																			>
																				<source
																					src={viewUser.profile.video}
																					type="video/mp4"
																				/>
																			</video>
																			
																		</div>
															
																	</div>

																) : (
																<>
																	{viewUser.profile.photo_thumb ? (
																		<>
																		<img
																			className="profile-image "
																			src={
																				viewUser.profile
																					.photo_thumb
																			}
																		/>
																		
																		</>
																	) : (
																		<div className="profile-image no-user-profile-photo flex justify-center">
																			<i className="fa-solid fa-user"></i>
																		</div>
																	)}
																	
																</>
															)}
																</div>
																<div className='absolute bottom-0 right-0 text-white'>
																		<span className='absolute bottom-6 right-6 h-16 w-16 '>
																		<i class="fas fa-badge-check text-7xl verified-badge"></i>
																		</span>
																</div>
															</div>
														</div>
														
													</div>
													<div className='col-span-12 min-[1400px]:col-span-5 min-[1400px]:row-span-3 min-[1400px]:row-start-2  min-[1400px]:h-[316px] text-white'>
														<div className="  col-span-12 min-[1400px]:col-span-6 min-[1400px]:mt-2 max-w-[100%] ">
															<h5 className="col-span-12 body-text-reg">
																@{viewUser.profile.username}
															</h5>
															<div className="row-span-1 grid grid-cols-12 gap-4 mt-2">
																{checkSectionVisibility('basics') && (
																	<div className="col-span-12">
																		<div className="block lg:hidden">
																			<h1 className="cols-span-12 header-large display-name">
																				{
																					viewUser.profile
																						.display_name
																				}
																			</h1>

																			<h1 className="cols-span-12 body-txtitalic--small">
																				{getListDisplay(
																					PRONOUNS,
																					viewUser.profile
																						.pronouns
																				)}
																			</h1>
																		</div>
																		<div className="hidden lg:block ">
																			<div className="col-span-12 header-large flex items-start">
																				<h1 className="cols-span-12 display-name flex-item-left sm:overflow-auto">
																					{
																						viewUser.profile
																							.display_name
																					}
																				</h1>
																				<h1 className="cols-span-12 body-txtitalic--small flex-item-right">
																					{getListDisplay(
																						PRONOUNS,
																						viewUser.profile
																							.pronouns
																					)}
																				</h1>
																			</div>
																		</div>

																		<div className="col-span-12 mt-4">
																			<p className="body-txtitalic--smallest">
																				{viewUser.profile.intro}
																			</p>
																		</div>
																	</div>
																)}

																{checkSectionVisibility('basics') && (
																	<div className="col-span-12 ">
																		{(viewUser.profile
																				.social_link ||
																			viewUser.profile.url) && (
																			<ul className="list-socials pt-2">
																				{viewUser.profile
																					.social_link && (
																					<li>
																						<i className="fa-brands fa-twitter isDisabled-link"></i>
																					</li>
																				)}
																				{viewUser.profile
																					.social_link && (
																					<li>
																						<i className="fa-brands fa-instagram isDisabled-link"></i>
																					</li>
																				)}
																				{viewUser.profile
																					.social_link && (
																					<li>
																						<i className="fa-brands fa-facebook isDisabled-link"></i>
																					</li>
																				)}
																				{viewUser.profile
																					.social_link && (
																					<li>
																						<i className="fa-brands fa-tiktok isDisabled-link"></i>
																					</li>
																				)}
																				{viewUser.profile
																					.social_link && (
																					<li>
																						<i className="fa-brands fa-youtube isDisabled-link"></i>
																					</li>
																				)}
																				{viewUser.profile
																					.social_link && (
																					<li>
																						<i className="fa-brands fa-linkedin isDisabled-link"></i>
																					</li>
																				)}
																				{viewUser.profile
																					.social_link && (
																					<li>
																						<i className="fa-brands fa-discord isDisabled-link"></i>
																					</li>
																				)}
																				{viewUser.profile
																					.social_link && (
																					<li>
																						<i className="fa-brands fa-twitch isDisabled-link"></i>
																					</li>
																				)}
																				{viewUser.profile
																					.url && (
																					<li>
																						<a
																							href={
																								viewUser
																									.profile
																									.url
																							}
																							target="_blank"
																						>
																							<i
																								className={
																									viewUser
																										.profile
																										.url
																										? 'fa-regular fa-globe isEnabled '
																										: 'fa-regular fa-globe isDisabled-link '
																								}
																							></i>
																						</a>
																					</li>
																				)}
																			</ul>
																		)}
																	</div>
																)}

																<div className="col-span-12 flex justify-around min-[1400px]:grid grid-cols-12 min-[1400px]:max-w-[432px] gap-y-8 md:gap-y-0 md:gap-8">
																	<div className="col-span-6 sm:col-span-3 max-w-[84px] text-center">
																		<p className="body-text--large mb-0">
																			{shortenNumber(
																				viewUser.followers_count
																			)}
																		</p>
																		<p className="body-text--smallest">
																			Followers
																		</p>
																	</div>
																	<div className="col-span-6 sm:col-span-3 max-w-[84px] text-center">
																		<p className="body-text--large mb-0">
																			{shortenNumber(
																				viewUser.followings_count
																			)}
																		</p>
																		<p className="body-text--smallest">
																			Following
																		</p>
																	</div>
																	<div className="col-span-6 sm:col-span-3 max-w-[84px] text-center">
																		<p className="body-text--large mb-0">
																			{setSpacesCount(viewUser)}
																		</p>
																		<p className="body-text--smallest">
																			Spaces
																		</p>
																	</div>
																	<div className="col-span-6 sm:col-span-3 max-w-[84px] text-center">
																		<p className="body-text--large mb-0">
																			{setCollectionsCount(
																				viewUser
																			)}
																		</p>
																		<p className="body-text--smallest">
																			Items
																		</p>
																	</div>
																</div>
															</div>
															
														</div>
													</div>
													<div className='col-span-12 min-[1400px]:col-span-4 min-[1400px]:row-span-3 min-[1400px]:row-start-2  h-[316px]'>
													{displayOwnProfileButtons() ? (
															<>
																<Link
																	to={routes.profileEdit()}
																	className={
																		'btn btn-secondary mt-4 btn-size btn-md'
																	}
																>
																	<i
																		className="fa fa-pencil me-2"
																		aria-hidden="true"
																	></i>
																	Edit Profile
																</Link>
																<Link
																	to={routes.profileManageFollowers()}
																	className={
																		'btn btn-secondary mt-4 btn-size btn-md'
																	}
																>
																	<i
																		className="fa fa-users me-2"
																		aria-hidden="true"
																	></i>
																	Manage Followers
																</Link>
																<Link
																	to={routes.profileManageVisibility()}
																	className={
																		'btn btn-secondary mt-4 btn-size btn-md'
																	}
																>
																	<i
																		className="fa fa-eye me-2"
																		aria-hidden="true"
																	></i>
																	Manage Visibility
																</Link>
																<Link
																	to={routes.profile()}
																	className={
																		'btn btn-secondary mt-4 btn-size btn-md'
																	}
																>
																	<i
																		className="fa fa-cog me-2"
																		aria-hidden="true"
																	></i>
																	Visit Dashboard
																</Link>
															</>
														) : (
															<>
																{authUserInfo && (
																	<>
																		<Button
																			className="btn btn-primary mt-4 btn-md"
																			disabled={
																				followingRequested
																			}
																			onClick={
																				handleFollowUser
																			}
																		>
																			{followingRequested && (
																				<i className="fa-solid fa-spinner fa-spin"></i>
																			)}
																			{viewUser.is_following
																				? 'Following'
																				: 'Follow'}
																		</Button>
																		<Link
																			to="#"
																			className="btn btn-secondary btn-md mt-4"
																		>
																			Contact
																		</Link>
																		<Link
																			to="#"
																			className="btn btn-secondary btn-md mt-4"
																		>
																			Add To
																		</Link>
																		<Link
																			to="#"
																			className="btn btn-secondary btn-md mt-4"
																		>
																			Share
																		</Link>
																	</>
																)}
															</>
														)}
													</div>
											</>
										)}
									</div>
									<div className="bg-overlay"></div>
								</div>
								
									<div className="w-full pb-5 min-h-[336px]">
										{!fetchingData && (<div className="container">
											{(!checkSectionVisibility('privacy')) ? (
												<div className='private-container'>
													<div className='private-text'>
														This Account is Private
													</div>
													<div>
														<span>
															Follow @{viewUser?.profile?.username}?{' '}
															<Link to={routes.login()}>
																Log in
															</Link>
															{' '}to see their profile.
														</span>
													</div>
												</div>
											) : (
												<div className="grid grid-cols-1">
													<div className="w-full">
														<ul className="nav nav-pills grid lg:gap-8 lg:grid-flow-col lg:justify-stretch mt-5" id="pills-tab" role="tablist">
															{tabs.map((tab, index) => tab.condition && (
																<li key={index} className="nav-item col-span-12 lg:col-span-2 flex justify-center max-w-[100%] lg:max-w-[200px] " role="presentation">
																	<Button
																		className={'w-full nav-link ' + ((tab.active ? tab.active.includes(globalActiveTab) : globalActiveTab === tab.id) ? 'active' : '')}
																		onClick={() => updateGlobalActiveTab(tab.id)}
																	>
																		{tab.id.charAt(0).toUpperCase() + tab.id.slice(1)}
																	</Button>
																</li>
															))}
														</ul>
													</div>

													
												</div>
											)}

											<div className="w-full">
												
												{(globalActiveTab === 'home' ||
													globalActiveTab === 'about') && (
													<div className="grid grid-cols-12 lg:gap-8 lg:grid-flow-col lg:justify-stretch mt-5">
														{checkSectionVisibility('about_me') && (
															<div className="col-span-12 lg:col-span-8">
																{/*<div className="profile-wrapper">*/}
																	<div className="grid grid-cols-12">
																		<h3 className="max-[319px]:col-span-12 col-span-6 sm:col-span-6 header-medium">
																			About Me
																		</h3>
																		<div className="max-[319px]:col-span-12 col-span-12 sm:col-span-6 flex sm:items-center justify-start sm:justify-center relative">
																			{viewUser.profile.visibility.city !== 'hidden' && (
																			<>
																			<i className="fa-regular fa-location-dot mr-2"></i>
																			<p className="body-text--reg ">
																				
																				{viewUser.profile.location}
																			</p>
																			</>
																			)}
																		</div>

																	</div>

																	<div className="col-span-12 about-me-container mt-8">
																		{fetchingAboutMe ? (
																			<>
																				<div className="loader">
																					<div className="background"></div>
																					<i className="fa-solid fa-spin fa-spinner spinner gradient-color-txt"></i>
																				</div>
																			</>
																		) : (
																			<>
																				{parse(
																					aboutMeContent.toString()
																				)}
																			</>
																		)}
																	</div>
																{/*</div>*/}
															</div>
														)}
														{checkSectionVisibility('top_8') && (
															<div className="col-span-12 md:col-span-4 hidden">
																<div className="profile-wrapper">
																	<h3 className="header-medium">
																		Top 8
																	</h3>
																	<div>
																		{top8.map(
																			(data, index) => (
																				<Top8
																					key={index}
																					data={data}
																				></Top8>
																			)
																		)}
																	</div>
															</div>
														</div>
														)}
													</div>
												)}
												{globalActiveTab === 'spaces' &&
													checkSectionVisibility('spaces_list') && (
														<>
															{spaceListLoading ? (
																<div className="loading-items">
																	<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
																</div>
															) : (
																<Spaces
																	spaces={spacesList}
																/>
															)}
														</>
													)}
					
												{globalActiveTab === 'collections' &&
													checkSectionVisibility('collections') && (
														<Collections
															collections={collectionsList}
														></Collections>
													)}
												{globalActiveTab === 'activity' &&
													checkSectionVisibility('activity') && (
														<Activity
															authUserInfo={authUserInfo}
															viewUserId={viewUser.id}
														></Activity>
													)}
												{globalActiveTab === 'following' &&
													checkSectionVisibility('follower_lists') && (
														<>
															{followingListLoading ? (
																<div className="loading-items">
																	<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
																</div>
															) : (
																<Following
																	data={followingsList}
																	authUserInfo={authUserInfo}
																	viewUserId={viewUser.id}
																	refetch={
																		handleRefetchFollowings
																	}
																></Following>
															)}
														</>
													)}
												{globalActiveTab === 'followers' &&
													checkSectionVisibility('follower_lists') && (
														<>
															{followersListLoading ? (
																<div className="loading-items">
																	<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
																</div>
															) : (
																<Followers
																	data={followersList}
																	authUserInfo={authUserInfo}
																	viewUserId={viewUser.id}
																	refetch={handleRefetchFollowers}
																></Followers>
															)}
														</>
													)}
											</div>
											<div className="mt-4">
												{globalActiveTab === 'following' &&
													followingsPagination.length > 1 && (
														<Pagination
															size="sm"
															onClick={(event) =>
																handleOnClickPagination(
																	event,
																	'following'
																)
															}
														>
															{followingsPagination}
														</Pagination>
													)}
												{globalActiveTab === 'followers' &&
													followersPagination.length > 1 && (
														<Pagination
															size="sm"
															onClick={(event) =>
																handleOnClickPagination(
																	event,
																	'followers'
																)
															}
														>
															{followersPagination}
														</Pagination>
													)}
											</div>
										</div>)}
									</div>
								
							</main>
						</>
					)}
				</>
			)}
		</>
	);
};

export default memo(UserView);
