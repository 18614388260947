import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, DatePicker, Form, Input, InputNumber } from 'antd';

import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import { Network, Alchemy } from 'alchemy-sdk';

import {
	MAGIC_CONNECT_KEY,
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../../../constants/common';

import Wallet from '../ManageWallets/MagicWallet/MagicWallet';

import './ManageMagicWallet.scss';
import { createPaymentMethod } from '../../../../services/payment_method';
import { registerUserWallet } from '../../../../services/user';
import { getAuthUserInfo } from '../../../../states/general/actions';
import { getSavedWallets } from '../../../../services/user';
import SpaceItem from '../../../Spaces/SpacesContent/SpaceItem';
import Alert from 'react-bootstrap/Alert';

const ManageMagicWallet = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [walletAccounts, setWalletAccounts] = useState(null);
	const [myWallets, setMyWallets] = useState(null);
	const [wallets, setWallets] = useState(null);
	const [emails, setEmails] = useState(null);
	const [walletData, setWalletData] = useState([]);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submittedVerification, setSubmittedVerification] = useState(false);
	const [alertBasic, setAlertBasic] = useState(false);

	const settings = {
		apiKey: ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
		network: Network.ETH_MAINNET // Replace with your network.
	};
	const alchemy = new Alchemy(settings);

	const hexToDecimal = (hex) => parseInt(hex, 16);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});

	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);

	const handleDisconnectCallback = () => {
		console.log('Disconnect callback');
		setWalletAccounts(null);
		setWallets(null);
		setEmails(null);
		setWalletData(null);
		magic.connect.disconnect().catch((e) => {});
	};

	const getMyWallets = useCallback(async () => {
		try {
			const params = {
				user_id: authUserInfo.id
			};

			const response = await getSavedWallets(params);
			if (response && response.result) {
				setWalletData(response.wallets);
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo]);

	const registerWallet = async (data) => {
		const response = await registerUserWallet(data);
		if (response && response.result) {
			let addedWallet = {};
			addedWallet.id = response.wallet;
			addedWallet.user_id = data.user_id;
			addedWallet.public_address = data.public_address;
			addedWallet.email = data.email;

			setWalletData([...walletData, addedWallet]);

			setAlertBasic({
				variant: 'success',
				message: 'Wallet Added Successfully!'
			});
		} else {
			setAlertBasic({
				variant: 'danger',
				message: 'Wallet Already Exist!'
			});
		}

		setSubmittedVerification(false);
	};

	// Web 3 Login
	const web3Login = async () => {
		setSubmittedVerification(true);
		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});

		web3.eth
			.getAccounts()
			.then((accounts) => {
				getUserInfo(accounts);
			})
			.catch((error) => {
				setSubmittedVerification(false);
			});
	};

	const getUserInfo = (accounts) => {
		magic.connect.requestUserInfo({ isResponseRequired: true }).then((user_account) => {
			getWalletInfo({ accounts: accounts, email: user_account.email });
		});
	};

	const getWalletInfo = (data) => {
		magic.connect.getWalletInfo().then((wallet_info) => {
			registerWallet({
				public_address: data.accounts?.[0],
				email: data.email,
				type: wallet_info.walletType,
				user_id: authUserInfo.id
			});
		});
	};

	const populateWallet = (accounts) => {
		// let wd = [];
		if (accounts.accounts != null && !walletData) {
			//console.log(accounts.accounts.length);
			accounts.accounts.forEach((data) => {
				// let d = { address: data, email: emails, wallet: wallets };
				// wd.push(d);
				if (!walletData) {
					const getBal = alchemy.core.getBalance(data, 'latest').then((result) => {
						//console.log('Res');
						//console.log(result);
						const hexBal = result._hex;
						const balance = hexToDecimal(hexBal);
						//console.log(hexBal);
						//console.log(balance);
						setWalletData({
							address: data,
							email: accounts.email,
							wallet: accounts.wallet,
							hexBal: hexBal,
							balance: balance
						});

						console.log('Wallet Info: ' + accounts.wallet);
					});
				}
			});
			//console.log('Wallet Data');
			//console.log(walletData);
		} else {
			console.log('Wallet Accounts null');
		}
	};

	useEffect(() => {
		// console.log('use effect');
		// if (!hasLoaded) {
		// 	console.log('Loaded');
		// 	setHasLoaded(true);
		// 	web3Login();
		// }

		if (authUserInfo && initialLoad) {
			getMyWallets().then((r) => {});
			setInitialLoad(false);
		}
	}, [authUserInfo, initialLoad, walletData]);

	return (
		<>
			<div className="">
				<div className="dashboard-wrapper mt-4">
					<div className="grid grid-cols-12 gap-8">
						<div className="col-span-6">
							<h3 className="header-medium">Manage Wallets</h3>
						</div>
						<div className="col-span-6">
							<>
								{walletData && (
									<p className="body-txtitalic--large text-end">
										{walletData.length} Connected
									</p>
								)}
							</>
						</div>
					</div>
					<p className="pt-3 body-txtitalic--small">
						Connect and manage your Wallets below. Once connected, you can use your
						Wallets to hold your Membership Keys and Items, and to pay for purchases
						using supported cryptocurrencies. Currently, we support ETH, MATIC, and USDC
						wallets only.{' '}
						<a className="gradient-color-txt" href="">
							Learn more about securely connecting and using supported Wallets.
						</a>
					</p>
					<>
						{alertBasic && (
							<Alert
								key={alertBasic.variant}
								variant={alertBasic.variant}
								className={
									'custom-alert ' +
									(alertBasic.variant === 'danger'
										? 'custom-alert-danger'
										: 'custom-alert-success')
								}
							>
								{alertBasic.message}
							</Alert>
						)}
					</>
					<Button
						className="btn btn-primary btn-md mt-5"
						disabled={submittedVerification}
						onClick={web3Login}
					>
						{submittedVerification && (
							<i className="fa-solid fa-spinner fa-spin me-3"></i>
						)}
						Connect Wallet
					</Button>
				</div>
				<div className="payment-row-view">
					<>
						{walletData &&
							walletData.map((data, index) => (
								<Wallet
									key={index}
									data={data}
									parentCallback={handleDisconnectCallback}
								></Wallet>
							))}
					</>
				</div>
			</div>
		</>
	);
};

export default memo(ManageMagicWallet);
