import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT } from '../../../../constants/user';
import { getListIcon } from '../../../../utils/list';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { updateUserNotificationPreferences } from '../../../../services/user';
import { getAuthUserInfo } from '../../../../states/general/actions';

const ManageNotificationPreferences = () => {
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [selectedNotificationsPreferences, setSelectedNotificationsPreferences] = useState({
		news_updates: 'default',
		recommendations: 'default',
		marketing: 'default',
		profile_activity: 'default',
		follower_activity: 'default',
		benefits_updates: 'default',
		comments_replies: 'default',
		event_alerts: 'default',
		account_alerts: 'default',
		spaces_alerts: 'default'
	});

	const getPreferenceIcon = (value) => {
		return getListIcon(NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT, value);
	};

	const handleChanges = useCallback(
		(field, value) => {
			setSelectedNotificationsPreferences((prev) => ({ ...prev, [field]: value }));

			if (authUserInfo && authUserInfo.profile.visibility) {
				const data = {
					user_id: authUserInfo.id,
					field: field,
					value: value
				};

				updateRequest(data).then((r) => {});
			}
		},
		[authUserInfo]
	);

	const updateRequest = async (data) => {
		try {
			const result = await updateUserNotificationPreferences(data);
			if (result && result.result) {
				dispatch(getAuthUserInfo());
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			if (authUserInfo.notification_preference) {
				setSelectedNotificationsPreferences((prev) => ({
					...prev,
					news_updates: authUserInfo.notification_preference.news_updates,
					recommendations: authUserInfo.notification_preference.recommendations,
					marketing: authUserInfo.notification_preference.marketing,
					profile_activity: authUserInfo.notification_preference.profile_activity,
					follower_activity: authUserInfo.notification_preference.follower_activity,
					benefits_updates: authUserInfo.notification_preference.benefits_updates,
					comments_replies: authUserInfo.notification_preference.comments_replies,
					event_alerts: authUserInfo.notification_preference.event_alerts,
					account_alerts: authUserInfo.notification_preference.account_alerts,
					spaces_alerts: authUserInfo.notification_preference.spaces_alerts
				}));
			}
		}
	}, [loading, authUserInfo]);

	return (
		<>
			<div className="col-span-12">
				<div className="dashboard-wrapper mt-4 pt-4">
					<h3 className="header-medium text-[34px] sm:text-4xl">Notifications & Communications</h3>
					<p className="pt-3 mb-5 body-txtitalic--small">
						Manage your notifications for important account, marketing, and profile
						updates from Commonspace, and from Spaces you’ve joined.{' '}
						<a className="gradient-color-txt" href="">
							Learn more about different types of communications.
						</a>
					</p>
					<div className="grid grid-cols-12 gapy-8 md:gap-y-0 md:gap-8 pt-3">
						<div className="col-span-12 md:col-span-6">
							<h5 className="header-small">Commonspace Email Communications</h5>
						</div>
						<div className="col-span-12 md:col-span-6">
							<p className="body-text--small text-end">
								Important Account & Security Emails are always on.
							</p>
						</div>
					</div>
					<div className=" flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								News & Updates
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								New features, platform updates, and more. These emails are
								infrequent and are generally sent 2 to 8 times per month only.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="News & Updates"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.news_updates
								)}
								selectedValue={selectedNotificationsPreferences.news_updates}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('news_updates', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Recommendations
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Recommended Spaces, Profiles, and content.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Recommendations"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.recommendations
								)}
								selectedValue={selectedNotificationsPreferences.recommendations}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) =>
									handleChanges('recommendations', value)
								}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">Marketing</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Marketing emails from Commonspace and our partners. These emails are
								infrequent and are generally sent 2 to 8 times per month only.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Marketing"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.marketing
								)}
								selectedValue={selectedNotificationsPreferences.marketing}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('marketing', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Profile Activity
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Get notified when your Profile is mentioned, receives new Badges or
								gets added to other Profiles’ Top 8.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Profile Activity"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.profile_activity
								)}
								selectedValue={selectedNotificationsPreferences.profile_activity}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) =>
									handleChanges('profile_activity', value)
								}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Follower Activity
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Follower activity to your Profile, including new follows and
								unfollows.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Follower Activity"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.follower_activity
								)}
								selectedValue={selectedNotificationsPreferences.follower_activity}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) =>
									handleChanges('follower_activity', value)
								}
							/>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-y-8 md:gap-yp-0 md:gap-8 pt-3 mt-5">
						<div className="col-span-12 md:col-span-6">
							<h5 className="header-small">Spaces Email Communications</h5>
						</div>
						<div className="col-span-12 md:col-span-6">
							<p className="body-text--small text-end">
								Important Updates from your Spaces are always on.
							</p>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Updates & Benefits
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Default setting for New Update posts and new Benefits across all
								your Spaces.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Updates & Benefits"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.benefits_updates
								)}
								selectedValue={selectedNotificationsPreferences.benefits_updates}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) =>
									handleChanges('benefits_updates', value)
								}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Comments & Replies
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Default setting for comments on your Community Posts and replies to
								your comments across all your Spaces.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Comments & Replies"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.comments_replies
								)}
								selectedValue={selectedNotificationsPreferences.comments_replies}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) =>
									handleChanges('comments_replies', value)
								}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Event Alerts
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Default setting for upcoming and live event notifications from your
								Spaces across all your Spaces.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Event Alerts"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.event_alerts
								)}
								selectedValue={selectedNotificationsPreferences.event_alerts}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('event_alerts', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-2">
						<div className="col-span-12 md:col-span-6">
							<a href="" className="btn btn-secondary btn-md mt-3">
								Reset All Custom Notifications per Space
							</a>
						</div>
						<div className="col-span-12 md:col-span-6">
							<a
								href="customnotification.html"
								className="btn btn-primary btn-md mt-3 text-center"
							>
								Customize Notifications per Space
							</a>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-y-8 md:gap-yp-0 md:gap-8 pt-3 mt-5">
						<div className="col-span-12 md:col-span-6">
							<h5 className="header-small">SMS Notifications</h5>
						</div>
						<div className="col-span-12 md:col-span-6">
							<p className="body-text--small text-end">
								Limited to Important Updates for your Account and Spaces.
							</p>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Account Alerts
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Account Alerts for Important Updates from Commonspace. This does not
								affect your 2 Factor Authentication Settings.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Account Alerts"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.account_alerts
								)}
								selectedValue={selectedNotificationsPreferences.account_alerts}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('account_alerts', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 grid-flow-row gap-x-5 md:grid-cols-10 xl:gap-x-16">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Spaces Alerts
							</label>
						</div>
						<div className="md:col-span-5">
							<p className="body-txtitalic--smallest m-0">
								Alerts for Important Updates from your Spaces.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Spaces Alerts"
								customButtonClass="btn-md btn-secondary"
								icon={getListIcon(
									NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT,
									selectedNotificationsPreferences.spaces_alerts
								)}
								selectedValue={selectedNotificationsPreferences.spaces_alerts}
								listItems={NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('spaces_alerts', value)}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(ManageNotificationPreferences);
