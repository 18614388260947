import { memo, useEffect, useCallback, useState } from 'react';
import routes from '../../../routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { Button } from 'antd';
import { getSitewideLink } from '../../../utils/sitewideLinks';

const InviteTeam = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const saveContinue = () => {
		const url = routes.createSpaceUpgradePlan();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createCommunityFeatures();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main id="member-profile" className="flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12 md:col-span-6 flex items-center">
							<h1 className="mb-4 mt-5 header-large">Invite Your Team</h1>
						</div>
						<div className="col-span-12 md:col-span-6 flex items-center justify-end">
							<p className="body-txtitalic--large mt-4">Step 4 of 5</p>
						</div>
						<div className="col-span-12">
							<p className="body-text--reg">
								As the {/*<a className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	Space Owner*/}
								{/*</a>*/}
								{getSitewideLink(
									'space_owner',
									'gradient-color-txt body-txtitalic--reg'
								)}
								, you can invite your team to help manage your Space. Each person
								can be assigned different permissions granting them Admin access or
								specific access around content, community, and commerce roles. There
								can only be a single Owner but you can transfer Ownership to another
								person at any time.{' '}
								{/*<a className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	Learn more about Space Manager Roles and Permissions.*/}
								{/*</a>*/}
								{getSitewideLink(
									'learn_more_manager_role',
									'gradient-color-txt body-txtitalic--reg'
								)}
							</p>
						</div>
					</div>
					<div className="grid mt-4">
						<div className="w-full">
							<h5 className="header-small">
								Team Management is coming soon and will be available to add to your
								Space from your Dashboard. All Space Owners will be updated by email
								when it becomes available!
							</h5>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-5">
						<div className="md:col-span-8">
							<Button
								onClick={saveContinue}
								className="btn btn-lg btn-primary"
								htmlType="submit"
							>
								Continue to Next Step
							</Button>
						</div>
						<div className="md:col-span-4 flex items-end">
							<Button
								onClick={saveBack}
								className="btn btn-lg btn-warning bg-none"
								htmlType="submit"
							>
								Go Back
							</Button>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(InviteTeam);
