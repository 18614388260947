import React, { memo, useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';

import routes from '../../../routes';
import DashboardHome from '../DashboardHome';
import Activity from '../Activity';
import Members from '../Members';
import Updates from '../Updates';
import Benefits from '../Benefits';
import Items from '../Items';
import Memberships from '../Memberships';
import Transactions from '../Transactions';
import ManageSpace from '../ManageSpace';
import Settings from '../Settings';
import ManageMember from '../Members/ManageMember';
import EditUpdate from '../Updates/EditUpdate';
import CreateUpdate from '../Updates/CreateUpdate';
import ManageItem from '../Items/ManageItem';
import CreateItemCommunity from '../Items/CreateItemCommunity';
import ManageMembership from '../Memberships/ManageMembership';
import CreateMembershipCommunity from '../Memberships/CreateMembershipCommunity';
import MembershipType from '../Memberships/MembershipType';
import ItemType from '../Items/ItemType';
import EditItemCommunity from '../Items/EditItemCommunity';
import EditItemNFT from '../Items/EditItemNFT';
import AddItemBenefit from '../Items/AddMonthlyBenefit';
import EditItemBenefit from '../Items/EditMonthlyBenefit';
import CreateItemNFT from '../Items/CreateItemNFT';
import EditMembershipCommunity from '../Memberships/EditMembershipCommunity';
import EditMembershipNFT from '../Memberships/EditMembershipNFT';
import CreateMembershipNFT from '../Memberships/CreateMembershipNFT';
import AddMemBenefit from '../Memberships/AddMonthlyBenefit';
import EditMemBenefit from '../Memberships/EditMonthlyBenefit';
import ManageProfile from '../ManageSpace/ManageProfile';
import ManageHomePage from '../ManageSpace/ManageHomePage';
import EditInfoPages from '../ManageSpace/EditInfoPages';
import ProSpaceCustomization from '../ManageSpace/ProSpaceCustomization';
import ProNavCustomization from '../ManageSpace/ProNavCustomization';

import {
	getMemberData,
	getSpaceItemData,
	getSpaceMembership,
	getMembershipBenefit,
	getSpaceUpdateData,
	getItemBenefit
} from '../../../services/space';
import { MEMBERSHIP_TYPES } from '../../../constants/space';
import { displayPageContentLoader, hidePageContentLoader } from '../../../states/pageContentLoader';
import ManageChannelsPage from "../ManageSpace/ManageChannels/ManageChannelsPage";

const DashboardContent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [selectedTab, setSelectedTab] = useState(routes.dashboard());
	const dashboardUrls = [routes.spaceDashboard()];
	const activityUrls = [routes.spaceActivity()];
	const membersUrls = [routes.spaceMembers(), routes.manageMember()];
	const updatesUrls = [routes.spaceUpdates(), routes.createUpdate(), routes.editUpdate()];
	const benefitsUrls = [routes.spaceBenefits()];
	const itemsUrls = [
		routes.spaceItems(),
		routes.manageItem(),
		routes.itemType(),
		routes.createItemCommunity(),
		routes.createItemNFT(),
		routes.editItem(),
		routes.addItemMonthBenefit(),
		routes.editItemMonthBenefit()
	];
	const membershipUrls = [
		routes.spaceMemberships(),
		routes.manageMembership(),
		routes.membershipType(),
		routes.createMembershipCommunity(),
		routes.createMembershipNFT(),
		routes.editMembership(),
		routes.addMemMonthBenefit(),
		routes.editMemMonthBenefit()
	];
	const transactionUrls = [routes.spaceTransactions()];
	const spaceUrls = [
		routes.spaceManageSpace(),
		routes.spaceManageProfile(),
		routes.spaceManageHomePage(),
		routes.spaceManageInfoPages(),
		routes.spaceManageCustomizeSpace(),
		routes.spaceManageCustomizeNav(),
		routes.spaceManageChannels()
	];

	const settingsUrls = [routes.spaceSettings()];
	const [spaceUsername, setSpaceUsername] = useState(null);
	const [spaceDashboardRoutesArr, setSpaceDashboardRoutesArr] = useState([]);
	const [toUpdateDataId, setToUpdateDataId] = useState(null);
	const [manageMemberData, setManageMemberData] = useState(null);
	const [manageUpdateData, setManageUpdateData] = useState(null);
	const [manageItemData, setManageItemData] = useState(null);
	const [manageMembershipData, setManageMembershipData] = useState(null);
	const [membershipBenefitData, setMembershipBenefitData] = useState(null);
	const [itemBenefitData, setItemBenefitData] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const { displayPageContentLoaderStatus } = useSelector(
		(state) => state.displayPageContentLoader
	);
	// const [isFormUpdated, setIsFormUpdated] = useState(false);

	const revertManageDataRoute = (route, path = null, hasId = false) => {
		const routeCopy = route;

		if (path) {
			const endPath = routeCopy.replace('/:space_username/' + path + '/', '');
			let idKey = ':id';
			if (path === 'members') {
				idKey = ':member_id';
			}
			if (path === 'updates') {
				idKey = ':update_id';
			}
			if (path === 'items') {
				idKey = ':item_id';
			}
			if (path === 'memberships') {
				idKey = ':membership_id';
			}
			if (path === 'memberships') {
				idKey = ':membership_id';
			}
			if (path === 'benefit') {
				idKey = ':benefit_id';
			}

			if (endPath.includes('/benefit/')) {
				if (endPath.includes('/benefit/add')) {
					const dataId = endPath.replace('/benefit/add', '');
					if (hasId) {
						setToUpdateDataId(parseInt(dataId));
					}
					return '/:space_username/' + path + '/' + endPath.replace(dataId, idKey);
				}
				if (endPath.includes('/benefit') && endPath.includes('/edit')) {
					const expEndPath = endPath.split('/benefit/');
					if (expEndPath.length > 1) {
						const dataId = expEndPath[1].replace('/edit', '');
						if (hasId) {
							setToUpdateDataId(parseInt(dataId));
						}
						const expEndPathRevert = endPath.split('/benefit/');
						const expEndPathRevertA = expEndPathRevert[0];
						const expEndPathRevertB = expEndPathRevert[1].replace(dataId, idKey);
						return expEndPathRevertA + '/' + path + '/' + expEndPathRevertB;
					}
				}
			} else {
				if (endPath.includes('/manage')) {
					const dataId = endPath.replace('/manage', '');
					if (hasId) {
						setToUpdateDataId(parseInt(dataId));
					}
					return '/:space_username/' + path + '/' + endPath.replace(dataId, idKey);
				}
				if (endPath.includes('/edit')) {
					const dataId = endPath.replace('/edit', '');
					if (hasId) {
						setToUpdateDataId(parseInt(dataId));
					}
					return '/:space_username/' + path + '/' + endPath.replace(dataId, idKey);
				}
			}
		}
		return routeCopy;
	};

	const setActiveLink = useCallback(
		(links, boolean = false) => {
			const route = location.pathname;
			let linkWithSpaceUsername = route.replace('@' + spaceUsername, ':space_username');

			if (linkWithSpaceUsername.includes('/:space_username/members/')) {
				linkWithSpaceUsername = revertManageDataRoute(linkWithSpaceUsername, 'members');
			}
			if (linkWithSpaceUsername.includes('/:space_username/updates/')) {
				linkWithSpaceUsername = revertManageDataRoute(linkWithSpaceUsername, 'updates');
			}
			if (linkWithSpaceUsername.includes('/:space_username/items/')) {
				if (
					linkWithSpaceUsername.includes('/benefit') &&
					linkWithSpaceUsername.includes('/edit')
				) {
					linkWithSpaceUsername = revertManageDataRoute(linkWithSpaceUsername, 'benefit');
					const expEndPath = linkWithSpaceUsername.split('/benefit/');
					if (expEndPath.length > 1) {
						const parentId = expEndPath[0].replace('/:space_username/items/', '');
						linkWithSpaceUsername = linkWithSpaceUsername.replace(parentId, ':item_id');
					}
				} else {
					linkWithSpaceUsername = revertManageDataRoute(linkWithSpaceUsername, 'items');
				}
			}
			if (linkWithSpaceUsername.includes('/:space_username/memberships/')) {
				if (
					linkWithSpaceUsername.includes('/benefit') &&
					linkWithSpaceUsername.includes('/edit')
				) {
					linkWithSpaceUsername = revertManageDataRoute(linkWithSpaceUsername, 'benefit');
					const expEndPath = linkWithSpaceUsername.split('/benefit/');
					if (expEndPath.length > 1) {
						const parentId = expEndPath[0].replace('/:space_username/memberships/', '');
						linkWithSpaceUsername = linkWithSpaceUsername.replace(
							parentId,
							':membership_id'
						);
					}
				} else {
					linkWithSpaceUsername = revertManageDataRoute(
						linkWithSpaceUsername,
						'memberships'
					);
				}
			}

			if (links.includes(linkWithSpaceUsername)) {
				if (boolean) {
					return true;
				}
				return 'active';
			}
			if (boolean) {
				return false;
			}
			return '';
		},
		[spaceUsername]
	);

	const redirectToSpaceLink = (route, spaceUsername) => {
		if (spaceUsername) {
			return route.replace(':space_username', '@' + spaceUsername);
		}
		return '';
	};

	const getMemberDataRequest = useCallback(
		async (member_id) => {
			dispatch(displayPageContentLoader());
			const response = await getMemberData({
				viewing_user_id: authUserInfo.id,
				member_id: member_id,
				is_manage_mode: true
			});
			if (response && response.result) {
				setManageMemberData(response.member);
			}
			dispatch(hidePageContentLoader());
		},
		[authUserInfo, spaceInfo]
	);

	const handleGetUpdateDataRequest = useCallback(
		async (update_id) => {
			dispatch(displayPageContentLoader());
			setManageUpdateData(null);
			const response = await getSpaceUpdateData({
				viewing_user_id: authUserInfo.id,
				update_id: update_id,
				is_manage_mode: true
			});
			if (response && response.result) {
				setManageUpdateData(response.space_update);
			}
			dispatch(hidePageContentLoader());
		},
		[authUserInfo, spaceInfo]
	);

	const getItemDataRequest = useCallback(
		async (item_id) => {
			dispatch(displayPageContentLoader());
			const response = await getSpaceItemData({
				viewing_user_id: authUserInfo.id,
				item_id: item_id,
				is_manage_mode: true,
				is_get_members: true
			});
			if (response && response.result) {
				setManageItemData(response.item);
			}
			dispatch(hidePageContentLoader());
		},
		[authUserInfo, spaceInfo]
	);

	const getMembershipDataRequest = useCallback(
		async (membership_id) => {
			dispatch(displayPageContentLoader());
			const response = await getSpaceMembership({
				viewing_user_id: authUserInfo.id,
				membership_id: membership_id,
				is_manage_mode: true,
				is_get_members: true
			});
			if (response && response.result) {
				setManageMembershipData(response.membership);
			}
			dispatch(hidePageContentLoader());
		},
		[authUserInfo, spaceInfo]
	);

	const getMembershipBenefitDataRequest = useCallback(
		async (benefit_id) => {
			dispatch(displayPageContentLoader());
			const data = {
				benefit_id: benefit_id,
				// is_manage_mode: true,
				// benefit_type: 'membership'
			}
			// if (authUserInfo){
			// 	data['viewing_user_id'] = authUserInfo.id;
			// }
			const response = await getMembershipBenefit(data);
			if (response && response.result) {
				const benefit = response.membership_benefit;
				setMembershipBenefitData(benefit);

				if (benefit) {
					getMembershipDataRequest(benefit.membership_id).then(() => {});
				}
			}
			dispatch(hidePageContentLoader());
		},
		[authUserInfo, spaceInfo]
	);

	const getItemBenefitDataRequest = useCallback(
		async (benefit_id) => {
			dispatch(displayPageContentLoader());
			const response = await getItemBenefit({
				viewing_user_id: authUserInfo.id,
				benefit_id: benefit_id,
				is_manage_mode: true,
				benefit_type: 'item'
			});
			if (response && response.result) {
				const benefit = response.item_benefit;
				setItemBenefitData(benefit);

				if (benefit) {
					getItemDataRequest(benefit.item_id).then(() => {});
				}
			}
			dispatch(hidePageContentLoader());
		},
		[authUserInfo, spaceInfo]
	);

	useEffect(() => {
		setToUpdateDataId(null);
	}, [currentLocation.pathname]);

	useEffect(() => {
		const path = currentLocation.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.replace('/@', '');
			if (username.includes('/')) {
				const usernameExp = username.split('/');
				username = usernameExp[0] ? usernameExp[0] : '';
				setSpaceUsername(username);

				const spaceDashboardRoutes = [
					routes.spaceDashboard(),
					routes.spaceActivity(),
					routes.spaceMembers(),
					routes.spaceUpdates(),
					routes.createUpdate(),
					routes.editUpdate(),
					routes.spaceBenefits(),
					routes.spaceItems(),
					routes.spaceMemberships(),
					routes.spaceTransactions(),
					routes.spaceManageSpace(),
					routes.spaceSettings(),
					routes.manageMember(),
					routes.manageItem(),
					routes.editItem(),
					routes.manageMembership(),
					routes.spaceManageCustomizeSpace(),
					routes.spaceManageCustomizeNav()
				];

				const spaceDashboardRoutesWithSpaceUsername = [];
				spaceDashboardRoutes.forEach((route) => {
					spaceDashboardRoutesWithSpaceUsername.push(
						route.replace(':space_username', '@' + username)
					);
				});

				setSpaceDashboardRoutesArr(spaceDashboardRoutesWithSpaceUsername);
			}
		}

		if (spaceUsername) {
			let revertedPathSelectedTab = null;
			let revertedPath = path.replace('@' + spaceUsername, ':space_username');

			if (revertedPath.includes('/:space_username/members/')) {
				revertedPath = revertManageDataRoute(revertedPath, 'members', true);
			}
			if (revertedPath.includes('/:space_username/updates/')) {
				revertedPath = revertManageDataRoute(revertedPath, 'updates', true);
			}
			if (revertedPath.includes('/:space_username/items/')) {
				if (revertedPath.includes('/benefit') && revertedPath.includes('/edit')) {
					revertedPath = revertManageDataRoute(revertedPath, 'benefit', true);
					const expEndPathRevert = revertedPath.split('/benefit/');
					const expEndPathRevertB = expEndPathRevert[1];
					revertedPathSelectedTab =
						'/:space_username/items/:item_id/benefit/' + expEndPathRevertB;
				} else {
					revertedPath = revertManageDataRoute(revertedPath, 'items', true);
				}
			}
			if (revertedPath.includes('/:space_username/memberships/')) {
				if (revertedPath.includes('/benefit') && revertedPath.includes('/edit')) {
					revertedPath = revertManageDataRoute(revertedPath, 'benefit', true);
					const expEndPathRevert = revertedPath.split('/benefit/');
					const expEndPathRevertB = expEndPathRevert[1];
					revertedPathSelectedTab =
						'/:space_username/memberships/:membership_id/benefit/' + expEndPathRevertB;
				} else {
					revertedPath = revertManageDataRoute(revertedPath, 'memberships', true);
				}
			}

			if (authUserInfo && toUpdateDataId) {
				if (revertedPath.includes('/:space_username/members/')) {
					dispatch(displayPageContentLoader());
					getMemberDataRequest(toUpdateDataId).then(() => {});
				}
				if (revertedPath.includes('/:space_username/updates/')) {
					dispatch(displayPageContentLoader());
					handleGetUpdateDataRequest(toUpdateDataId).then(() => {});
				}
				if (revertedPath.includes('/:space_username/items/')) {
					dispatch(displayPageContentLoader());
					if (revertedPath.includes('/benefit') && revertedPath.includes('/edit')) {
						getItemBenefitDataRequest(toUpdateDataId).then(() => {});
					} else {
						getItemDataRequest(toUpdateDataId).then(() => {});
					}
				}
				if (revertedPath.includes('/:space_username/memberships/')) {
					dispatch(displayPageContentLoader());
					if (revertedPath.includes('/benefit') && revertedPath.includes('/edit')) {
						// console.log('revertedPath: '+revertedPath);
						// console.log('toUpdateDataId: '+toUpdateDataId);
						getMembershipBenefitDataRequest(toUpdateDataId).then(() => {});
					} else {
						getMembershipDataRequest(toUpdateDataId).then(() => {});
					}
				}
			}

			if (
				spaceInfo &&
				(revertedPath === routes.spaceManageCustomizeSpace() ||
					revertedPath === routes.spaceManageCustomizeNav()) &&
				spaceInfo &&
				!spaceInfo.pro_style
			) {
				const manageSpaceUrl = redirectToSpaceLink(
					routes.spaceManageSpace(),
					spaceInfo.username
				);
				navigate(manageSpaceUrl);
			}

			if (revertedPathSelectedTab) {
				setSelectedTab(revertedPathSelectedTab);
			} else {
				setSelectedTab(revertedPath);
			}

			if (initialLoad) {
				dispatch(getSpaceInfo(spaceUsername, null, true));
			}

			setInitialLoad(false);
		}
		toUpdateDataId ? dispatch(displayPageContentLoader()) : dispatch(hidePageContentLoader());

	}, [authUserInfo, currentLocation.pathname, spaceUsername, toUpdateDataId]);

	return (
		<>
			<div className="grid grid-cols-12 gap-0 lg:gap-8">
				{displayPageContentLoaderStatus ? (
					<div className="loading-items">
						<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
					</div>
				) : (
					<div className="col-span-12">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb h-[72px] mt-8 mb-8 items-center block md:flex">
								<li className="breadcrumb-item">
									{spaceUsername && <Link to="#">@{spaceUsername}</Link>}
								</li>
								<li className="breadcrumb-item active" aria-current="page">
									{setActiveLink(dashboardUrls, true) && <Link>Dashboard</Link>}
									{setActiveLink(activityUrls, true) && <Link>Activity</Link>}
									{setActiveLink(membersUrls, true) && <Link>Members</Link>}
									{setActiveLink(updatesUrls, true) && <Link>Updates</Link>}
									{setActiveLink(benefitsUrls, true) && <Link>Benefits</Link>}
									{setActiveLink(itemsUrls, true) && <Link>Items</Link>}
									{setActiveLink(membershipUrls, true) && <Link>Memberships</Link>}
									{setActiveLink(transactionUrls, true) && <Link>Transaction</Link>}
									{setActiveLink(spaceUrls, true) && <Link>Space</Link>}
									{setActiveLink(settingsUrls, true) && <Link>Settings</Link>}
								</li>
								{selectedTab === routes.manageMembership() && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link>
											{manageMembershipData && (
												<Link to="#">
													<i className="fa-light fa-pen-to-square"/>{' '}
													{manageMembershipData.name}
												</Link>
											)}
										</Link>
									</li>
								)}
								{selectedTab === routes.manageItem() && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link>
											{manageItemData && (
												<Link to="#">
													<i className="fa-light fa-pen-to-square"/>{' '}
													{manageItemData.name}
												</Link>
											)}
										</Link>
									</li>
								)}
								{selectedTab === routes.manageMember() && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link>
											{manageMemberData && (
												<Link to="#">
													@{manageMemberData.user.profile.username}
												</Link>
											)}
										</Link>
									</li>
								)}
								{selectedTab === routes.createUpdate() && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link to={routes.createUpdate()}>
											<i className="fa-light fa-pen-to-square"></i> New Update
										</Link>
									</li>
								)}
								{selectedTab === routes.editUpdate() && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link to="#">
											<i className="fa-light fa-pen-to-square"></i> Edit Update
										</Link>
									</li>
								)}
								{(selectedTab === routes.createItemCommunity() ||
									selectedTab === routes.createItemNFT()) && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link to="#">
											<i className="fa-light fa-pen-to-square"></i> Create Item
										</Link>
									</li>
								)}
								{selectedTab === routes.editItem() && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link to="#">
											<i className="fa-light fa-pen-to-square"></i> Edit Item
										</Link>
									</li>
								)}
								{(selectedTab === routes.addItemMonthBenefit() ||
									selectedTab === routes.addMemMonthBenefit()) && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link to="#">
											<i className="fa-light fa-pen-to-square"></i> Add Benefit
										</Link>
									</li>
								)}
								{(selectedTab === routes.editItemMonthBenefit() ||
									selectedTab === routes.editMemMonthBenefit()) && (
									<li className="breadcrumb-item active" aria-current="page">
										<Link to="#">
											<i className="fa-light fa-pen-to-square"></i> Edit Benefit
										</Link>
									</li>
								)}
							</ol>
						</nav>
					</div>
				)}
			</div>
			<div className="grid grid-cols-12 gap-0 lg:gap-8">
				<div className="lg:col-span-2 hidden xl:block">
					<ul className="menu">
						{spaceUsername && (
							<>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceDashboard(),
											spaceUsername
										)}
										className={setActiveLink(dashboardUrls)}
									>
										<i className="fa-regular fa-chart-tree-map"></i>
										<span>Dashboard</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceActivity(),
											spaceUsername
										)}
										className={setActiveLink(activityUrls)}
									>
										<i className="fa-regular fa-list-ul"></i>
										<span>Activity</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceMembers(),
											spaceUsername
										)}
										className={setActiveLink(membersUrls)}
									>
										<i className="fa-regular fa-users"></i>
										<span>Members</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceUpdates(),
											spaceUsername
										)}
										className={setActiveLink(updatesUrls)}
									>
										<i className="fa-regular fa-memo"></i>
										<span>Updates</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceBenefits(),
											spaceUsername
										)}
										className={setActiveLink(benefitsUrls)}
									>
										<i className="fa-regular fa-gift"></i>
										<span>Benefits</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(routes.spaceItems(), spaceUsername)}
										className={setActiveLink(itemsUrls)}
									>
										<i className="fa-regular fa-hexagon-image"></i>
										<span>Items</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceMemberships(),
											spaceUsername
										)}
										className={setActiveLink(membershipUrls)}
									>
										<i className="fa-regular fa-id-card"></i>
										<span>Memberships</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceTransactions(),
											spaceUsername
										)}
										className={setActiveLink(transactionUrls)}
									>
										<i className="fa-regular fa-cash-register"></i>
										<span>Transactions</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceManageSpace(),
											spaceUsername
										)}
										className={setActiveLink(spaceUrls)}
									>
										<i className="fa-regular fa-solar-system"></i>
										<span>Space</span>
									</Link>
								</li>
								<li>
									<Link
										to={redirectToSpaceLink(
											routes.spaceSettings(),
											spaceUsername
										)}
										className={setActiveLink(settingsUrls)}
									>
										<i className="fa-regular fa-gear"></i>
										<span>Settings</span>
									</Link>
								</li>
							</>
						)}
					</ul>
				</div>
				<div className="col-span-12 xl:col-span-10 pb-16">
					{selectedTab === routes.spaceDashboard() && <DashboardHome />}
					{selectedTab === routes.spaceActivity() && <Activity />}

					{/*Members Tab Components*/}
					{selectedTab === routes.spaceMembers() && <Members />}
					{selectedTab === routes.manageMember() && (
						<ManageMember
							memberData={manageMemberData}
							spaceInfo={spaceInfo}
							getMemberDataRequest={getMemberDataRequest}
							toUpdateDataId={toUpdateDataId}
						/>
					)}

					{/*Updates Tab Components*/}
					{selectedTab === routes.spaceUpdates() && <Updates />}
					{selectedTab === routes.createUpdate() && <CreateUpdate />}
					{selectedTab === routes.editUpdate() && (
						<EditUpdate
							updateData={manageUpdateData}
							getUpdateDataRequest={handleGetUpdateDataRequest}
							// setIsFormUpdated={setIsFormUpdated}
							// isFormUpdated={isFormUpdated}
						/>
					)}

					{selectedTab === routes.spaceBenefits() && <Benefits />}

					{/*Items Tab Components*/}
					{selectedTab === routes.spaceItems() && <Items />}
					{selectedTab === routes.manageItem() && manageItemData && (
						<ManageItem itemData={manageItemData} spaceInfo={spaceInfo} />
					)}
					{selectedTab === routes.itemType() && <ItemType />}
					{selectedTab === routes.createItemCommunity() && <CreateItemCommunity />}
					{selectedTab === routes.createItemNFT() && <CreateItemNFT />}
					{selectedTab === routes.editItem() && manageItemData && (
						<>
							{manageItemData.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (
								<EditItemNFT
									itemData={manageItemData}
									benefitData={itemBenefitData}
								/>
							) : (
								<EditItemCommunity
									itemData={manageItemData}
									benefitData={itemBenefitData}
								/>
							)}
						</>
					)}
					{selectedTab === routes.addItemMonthBenefit() && manageItemData && (
						<AddItemBenefit
							itemData={manageItemData}
							spaceInfo={spaceInfo}
							authUserInfo={authUserInfo}
						/>
					)}
					{selectedTab === routes.editItemMonthBenefit() &&
						manageItemData &&
						itemBenefitData && (
							<EditItemBenefit
								itemData={manageItemData}
								benefitData={itemBenefitData}
								spaceInfo={spaceInfo}
								authUserInfo={authUserInfo}
							/>
						)}

					{/*Membership Tab Components*/}
					{selectedTab === routes.spaceMemberships() && <Memberships />}
					{selectedTab === routes.manageMembership() && manageMembershipData && (
						<ManageMembership
							membershipData={manageMembershipData}
							spaceInfo={spaceInfo}
						/>
					)}
					{selectedTab === routes.createMembershipCommunity() && (
						<CreateMembershipCommunity />
					)}
					{selectedTab === routes.createMembershipNFT() && <CreateMembershipNFT />}
					{selectedTab === routes.membershipType() && <MembershipType />}
					{selectedTab === routes.editMembership() && manageMembershipData && (
						<>
							{manageMembershipData.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (
								<EditMembershipNFT
									membershipData={manageMembershipData}
									benefitData={membershipBenefitData}
								/>
							) : (
								<EditMembershipCommunity
									membershipData={manageMembershipData}
									benefitData={membershipBenefitData}
								/>
							)}
						</>
					)}
					{selectedTab === routes.addMemMonthBenefit() && manageMembershipData && (
						<AddMemBenefit
							membershipData={manageMembershipData}
							spaceInfo={spaceInfo}
							authUserInfo={authUserInfo}
						/>
					)}
					{selectedTab === routes.editMemMonthBenefit() &&
						manageMembershipData &&
						membershipBenefitData && (
							<EditMemBenefit
								membershipData={manageMembershipData}
								benefitData={membershipBenefitData}
								spaceInfo={spaceInfo}
								authUserInfo={authUserInfo}
							/>
						)}

					{selectedTab === routes.spaceTransactions() && <Transactions />}
					{selectedTab === routes.spaceManageSpace() && (
						<ManageSpace spaceInfo={spaceInfo} authUserInfo={authUserInfo} />
					)}
					{selectedTab === routes.spaceManageProfile() && spaceInfo && (
						<ManageProfile spaceInfo={spaceInfo} authUserInfo={authUserInfo} />
					)}
					{selectedTab === routes.spaceManageHomePage() && spaceInfo && (
						<ManageHomePage spaceInfo={spaceInfo} authUserInfo={authUserInfo} />
					)}
					{selectedTab === routes.spaceManageInfoPages() && <EditInfoPages />}
					{selectedTab === routes.spaceManageCustomizeSpace() &&
						spaceInfo &&
						spaceInfo.pro_style && (
							<ProSpaceCustomization
								spaceInfo={spaceInfo}
								authUserInfo={authUserInfo}
							/>
						)}
					{selectedTab === routes.spaceManageCustomizeNav() &&
						spaceInfo &&
						spaceInfo.pro_style && (
							<ProNavCustomization
								spaceInfo={spaceInfo}
								authUserInfo={authUserInfo}
							/>
						)}

					{selectedTab === routes.spaceSettings() && <Settings />}

					{selectedTab === routes.spaceManageChannels() && spaceInfo && (
						<ManageChannelsPage spaceInfo={spaceInfo} authUserInfo={authUserInfo} />
					)}
				</div>
			</div>
		</>
	);
};

export default memo(DashboardContent);
