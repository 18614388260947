import {memo, useCallback, useState} from "react";
import routes from "../../../routes";
import {Button, Form, Input} from "antd";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import Alert from "react-bootstrap/Alert";
import {isAlphaNumeric} from "../../../utils/form";
import {useSelector} from "react-redux";
import {emailResetPassword} from "../../../services/user";


const ResetPassword = (props) => {
    const {email, token} = props;
    const [form] = Form.useForm();
    const [emptyPass, setEmptyPass] = useState(true);
    const [formHasError, setFormHasError] = useState(true);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const {size} = useSelector((state) => state.classSize);
    const [errorSize, setErrorSize] = useState('sm');
    const [alert, setAlert] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const handleFormChanges = useCallback(async () => {
        if (form) {
            let emptyPass = true;
            if (form.getFieldValue('password')) {
                emptyPass = false;
            }
            setEmptyPass(emptyPass);

            await form
                .validateFields()
                .then(() => {
                    setFormHasError(false);
                })
                .catch((errors) => {
                    setFormHasError(true);
                });
        }
    }, []);

    const handleFormOnBlur = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSubmit = useCallback(async (values) => {

        try {
            const data = values;
            data.email = email;
            data.token = token;
            const response = await emailResetPassword(data);
            if (response && response.result) {
                setAlert({
                    variant: 'success',
                    message: response.message
                });
                setTimeout(() => {
                    window.location.replace(routes.login());
                }, 3000);

            } else {
                setAlert({variant: 'danger', message: response.message});
            }
        } catch (e) {
            setAlert({variant: 'danger', message: 'Something went wrong'});
        }

    }, []);

    return (
        <>
            <Form
                form={form}
                name="form"
                validateMessages={VALIDATE_MESSAGES}
                onFocus={() => {
                    setFormIsOnFocus(true);
                }}
                onBlur={() => {
                    setFormIsOnFocus(false);
                    handleFormOnBlur();
                }}
                autoComplete="off"
                onChange={handleFormChanges}
                onFinish={handleSubmit}
                className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
            >
                <>
                    {alert && (
                        <div className="grid grid-cols-12 gap-8 my-2">
                            <div className="col-span-12">
                                <Alert
                                    key={alert.variant}
                                    variant={alert.variant}
                                    className={
                                        'custom-alert ' +
                                        (alert.variant === 'danger'
                                            ? 'custom-alert-danger'
                                            : 'custom-alert-success')
                                    }
                                >
                                    {alert.message}
                                </Alert>
                            </div>
                        </div>
                    )}
                </>

                <div className={'input-group-com flex items-center my-5 input-error-' + errorSize}>
                    <label className="text-label body-text--small">Create Password*</label>
                    <Form.Item
                        label="Password"
                        name="password"
                        className="mb-0 hidden-label"
                        validateTrigger="onBlur"
                        rules={[
                            {required: true},
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    setErrorSize('sm');
                                    if (value && value.length < 8) {
                                        setErrorSize('md');
                                        return Promise.reject(
                                            new Error('Password must be 8 characters long or more.')
                                        );
                                    }
                                    if (isAlphaNumeric(value)) {
                                        return Promise.resolve();
                                    }
                                    if (value && value.length > 0) {
                                        setErrorSize('lg');
                                        return Promise.reject(
                                            new Error(
                                                'Password is case sensitive, must be at least 8 characters, and include a symbol and number.'
                                            )
                                        );
                                    }
                                    return Promise.resolve();
                                }
                            })
                        ]}
                    >
                        <Input
                            type="password"
                            className={'txt-long input-' + size}
                            disabled={submitted}
                            autoComplete="new-password"
                        />
                    </Form.Item>
                    {emptyPass && (
                        <>
                            <span className="tool-tip">
                                Must be a minimum of 8 case-sensitive characters including a symbol
                                and a number.
                            </span>
                        </>
                    )}
                </div>
                <div className="input-group-com  flex items-center input-with-btn my-5">
                    <label className="text-label body-text--small">Confirm Password*</label>
                    <Form.Item
                        name="confirmpassword"
                        className="mb-0 hidden-label"
                        dependencies={['password']}
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password.'
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Password does not match.'));
                                }
                            })
                        ]}
                    >
                        <Input
                            // placeholder="Confirm Password*"
                            type="password"
                            className={'txt-long input-' + size}
                            disabled={submitted}
                        />
                    </Form.Item>
                    <Form.Item className="mb-0">
                        <Button
                            className={'btn btn-primary btn-' + size}
                            htmlType="submit"
                            disabled={submitted || formHasError}
                        >
                            {submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
                            Reset password
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default memo(ResetPassword);