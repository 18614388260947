import { memo, useEffect, useState, useCallback, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, InputNumber } from 'antd';

import routes from '../../../routes';
import { createSpaceAddBenefit } from '../../../services/space';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { VALIDATE_MESSAGES } from '../../../constants/common';
import { hideTooltip, showTooltip } from '../../../utils/input';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import {CustomValidators} from "../../../utils/CustomValidators";
import {setBenefitMonthlyPrice} from "../../../components/commons/helpers/MembershipItemHelpers";
import TextareaAutosize from "react-textarea-autosize";
import FormFieldStacked from '../../../components/commons/FormFieldStacked';

const SpaceCreateAddBenefit = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createSpaceBenefitForm] = Form.useForm();
	const [firstMembership, setFirstMembership] = useState(null);
	const [firstMembershipFirstBenefit, setFirstMembershipFirstBenefit] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [alert, setAlert] = useState(null);
	const [monthlyPrice, setMonthlyPrice] = useState(null);
	const [includedMonths, setIncludedMonths] = useState(null);
	const [title, setTitle] = useState(null);
	const [description, setDescription] = useState(null);
	const [renewalDiscount, setRenewalDiscount] = useState(null);

	const monthlyPriceRef = useRef(null);
	const includedMonthsRef = useRef(null);
	const titleRef = useRef(null);
	const descriptionRef = useRef(null);
	const renewalDiscountRef = useRef(null);

	const handleBenefitFormOnBlur = useCallback(async () => {
		await createSpaceBenefitForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleBenefitFormChanges = useCallback(async () => {
		setMonthlyPrice(createSpaceBenefitForm.getFieldValue('monthly_price'));
		setIncludedMonths(createSpaceBenefitForm.getFieldValue('included_months'));
		setTitle(createSpaceBenefitForm.getFieldValue('title'));
		setDescription(createSpaceBenefitForm.getFieldValue('description'));
		setRenewalDiscount(createSpaceBenefitForm.getFieldValue('renewal_discount'));

		await createSpaceBenefitForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	});

	const handleBenefitFormSubmit = useCallback(
		async (values) => {
			setAlert(null);

			const data = values;
			data.membership_id = firstMembership.id;
			data.currency = 'USD';

			if (firstMembershipFirstBenefit) {
				data.benefit_id = firstMembershipFirstBenefit.id;
			}

			if (firstMembership) {
				try {
					if (authUserInfo) {
						data.user_id = authUserInfo.id;
						data.space_id = spaceInfo.id;
						setSubmitted(true);
						const response = await createSpaceAddBenefit(data);
						if (response && response.result) {
							dispatch(getSpaceInfo(space_username));
							const url = routes.createSpaceBasicCompletePage();
							navigate(url.replace(':space_username', space_username));
						} else {
							setAlert({
								variant: 'danger',
								message: response.message
							});
							window.scrollTo(0, 0);
						}
						setSubmitted(false);
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				setAlert({
					variant: 'danger',
					message: 'No membership details'
				});
				window.scrollTo(0, 0);
			}
		},
		[space_username, authUserInfo, spaceInfo, firstMembership, firstMembershipFirstBenefit]
	);

	const saveAndGoBack = useCallback(async () => {
		const data = {};
		data.membership_id = firstMembership.id;

		if (firstMembership) {
			data.currency = 'USD';
			data.monthly_price = monthlyPrice;
			data.included_months = includedMonths;
			data.title = title;
			data.description = description;
			data.renewal_discount = renewalDiscount;

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					setSubmitted(true);
					const response = await createSpaceAddBenefit(data);
					if (response && response.result) {
						dispatch(getSpaceInfo(space_username));
						const url = routes.createSpaceAddMembershipTraitsPage();
						navigate(url.replace(':space_username', space_username));
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						window.scrollTo(0, 0);
					}
					setSubmitted(false);
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			setAlert({
				variant: 'danger',
				message: 'No membership details'
			});
			window.scrollTo(0, 0);
		}
	}, [
		space_username,
		authUserInfo,
		spaceInfo,
		monthlyPrice,
		includedMonths,
		title,
		description,
		renewalDiscount,
		firstMembership
	]);

	const saveContinue = () => {
		const url = routes.createSpaceBasicCompletePage();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createSpaceAddMembershipTraitsPage();
		navigate(url.replace(':space_username', space_username));
	};

	const tooltipFocused = (input) => {
		if (input === 'monthly_price') {
			monthlyPriceRef.current.focus();
		}
		if (input === 'included_months') {
			includedMonthsRef.current.focus();
		}
		if (input === 'title') {
			titleRef.current.focus();
		}
		if (input === 'description') {
			descriptionRef.current.focus();
		}
		if (input === 'renewal_discount') {
			renewalDiscountRef.current.focus();
		}
	};

	const setFormSpaceData = async (createdSpace) => {
		if (createdSpace.memberships && createdSpace.memberships.length > 0) {
			const firstMembership = createdSpace.memberships[0];
			setFirstMembership(firstMembership);

			if (firstMembership.benefits && firstMembership.benefits.length > 0) {
				const membershipFirstBenefit = firstMembership.benefits[0];
				setFirstMembershipFirstBenefit(membershipFirstBenefit);
				createSpaceBenefitForm.setFieldsValue({
					title: membershipFirstBenefit.title,
					monthly_price: membershipFirstBenefit.monthly_price,
					included_months: membershipFirstBenefit.included_months,
					description: membershipFirstBenefit.description,
					renewal_discount: membershipFirstBenefit.renewal_discount
				});
				setTitle(membershipFirstBenefit.title);
				setMonthlyPrice(membershipFirstBenefit.monthly_price);
				setIncludedMonths(membershipFirstBenefit.included_months);
				setDescription(membershipFirstBenefit.description);
				setRenewalDiscount(membershipFirstBenefit.renewal_discount);
			}

			await createSpaceBenefitForm
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		} else {
			const url = routes.createSpaceAddMembershipPage();
			navigate(url.replace(':space_username', space_username));
		}
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main className="dashboard-wrapper flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12 md:col-span-6 flex items-center">
							<h1 className="mb-4 mt-8 header-large">Create Your First Benefits</h1>
						</div>
						<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">
							<p className="body-txtitalic--large mt-8">Step 5 of 5</p>
						</div>
						<div className="col-span-12">
							<p className="body-text--reg">
								{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	Benefits*/}
								{/*</Link>*/}
								{getSitewideLink(
									'benefits_create_space_5',
									'gradient-color-txt body-txtitalic--reg'
								)}{' '}
								provide customizable access, redemptions, privileges, and perks to
								your Members. There are many types of Benefits and you can add as
								many as you like. By customizing them you can easily integrate your
								community into your business.{' '}
								{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	Learn more about Benefits and how they work*/}
								{/*</Link>*/}
								{getSitewideLink(
									'learn_more_benefits',
									'gradient-color-txt body-txtitalic--reg'
								)}
								.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="col-span-12 md:col-span-8 flex my-auto justify-between !order-last md:!order-first">
							<h3 className="header-medium">Included Benefits for Your Membership</h3>
							<span className="body-text--reg">Required</span>
						</div>
						<div className="col-span-12 md:col-span-4 my-auto !order-first md:!order-last flex justify-center items-center">
							{/*<Button className="btn btn-lg btn-secondary">*/}
							{/*	View Guide*/}
							{/*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
							{/*</Button>*/}
							{getSitewideLink('view_guide_benefit', 'btn btn-lg btn-secondary')}
						</div>
					</div>
					<div className="grid grid-cols-12 mt-8">
						<div className="col-span-12">
							<p className="body-text--small">
								You can create or add Benefits to customize this Membership, and you
								can create different Memberships with different Benefits and pricing
								to offer multiple options for people to join your community. Based
								the details that you’ve chosen for your first Membership, the
								following Included Benefits are required.{' '}
							</p>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="col-span-12 md:col-span-8 my-auto flex justify-between !order-last md:!order-first">
							<h5 className="header-small">Included Benefit</h5>
							<span className="body-text--reg">Required</span>
						</div>
						<div className="col-span-12 md:col-span-4 my-auto !order-first md:!order-last">
							<p className="body-txtitalic--smallest color-reg">
								An Included Benefit is a type of Benefit that is based on the
								details of your Membership or Item, and is required for it to
								function as created.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="col-span-12 md:col-span-8 flex my-auto !order-last md:!order-first">
							<Button className="btn btn-lg btn-warning bg-none">
								<span className="gradient-color-txt">Member Access</span>
							</Button>
						</div>
						<div className="col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
							<p className="body-txtitalic--smallest color-reg">
								The Member Access Benefit provides basic “Members Only” access to
								Updates and Members sections in your Space. This is included in
								every Membership you create and cannot be edited.
							</p>
						</div>
					</div>

					{spaceInfo &&
					firstMembership &&
					firstMembership.enable_expiration_and_subscription ? (
						<>
							<Form
								form={createSpaceBenefitForm}
								name="createSpaceBenefitForm"
								id="createSpaceBenefitForm"
								validateMessages={VALIDATE_MESSAGES}
								onFocus={() => {
									setFormIsOnFocus(true);
								}}
								onBlur={() => {
									setFormIsOnFocus(false);
									handleBenefitFormOnBlur();
								}}
								onChange={handleBenefitFormChanges}
								onFinish={handleBenefitFormSubmit}
								className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
							>
								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="col-span-12 md:col-span-8 flex my-auto justify-between !order-last md:!order-first">
										<h5 className="header-small">Included Benefit</h5>
										<span className="body-text--reg">Required</span>
									</div>
									<div className="col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											An Included Benefit is a type of Benefit that is based
											on the details of your Membership or Item, and is
											required for it to function as created.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="col-span-12 col-span-12 md:col-span-8 flex my-auto !order-last md:!order-first">
										<button type="submit" className="btn btn-lg btn-warning bg-none">
											<span className="gradient-color-txt">
												Monthly Subscription
											</span>
										</button>
									</div>
									<div className="col-span-12 col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											The Monthly Subscription Benefit is required when the
											Expiration Date option is enabled.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="col-span-12 md:col-span-8 my-auto !order-last md:!order-first">
										<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
											Title*
										</label>
										<div className="relative rounded-none ">
											<Form.Item
													label="Title"
													name="title"
													className="mb-0 hidden-label"
													validateTrigger="onBlur"
													rules={[{ required: true }]}
												>
											
												<FormFieldStacked
												formType='input'
												type="text"
												maxLength={160}
												disabled={submitted}
												placeholder="Up to 160 characters, including spaces."
												ref={titleRef}>											
												</FormFieldStacked>
										</Form.Item>
									</div>								
								</div>	
									
									<div className="col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											The Member Access Benefit provides basic “Members Only”
											access to Updates and Members sections in your Space.
											This is included in every Membership you create and
											cannot be edited.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="col-span-12 md:col-span-8 my-auto !order-last md:!order-first">
										<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
											Currency*
										</label>
										<div className="relative rounded-none ">
											
											<input
												className="block h-10 bg-[#ffffff80] w-full rounded-none border-0 py-1.5 px-3 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
												disabled
												type="text"
												value="USD"
												/>
										
										</div>	
									</div>
									<div className="col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											Your Currency options are based on the details specified
											on your Membership/Item. You can select from the same
											currency of the Membership/Item or a related
											cryptocurrency.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="col-span-12 md:col-span-8 my-auto !order-last md:!order-first">
										<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
											Monthly Price*
										</label>
										<div className="relative rounded-none ">
										<Form.Item
												label="Monthly Price"
												name="monthly_price"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												onChange={(event) => setBenefitMonthlyPrice(event, createSpaceBenefitForm, "monthly_price", 'change')}
												rules={[
													{ required: true },
													({ getFieldValue }) => ({
														validator(_, value) {
															if (
																typeof value !== 'undefined' &&
																value !== '' &&
																(isNaN(value) || value < 0)
															) {
																return Promise.reject(
																	new Error('Invalid value.')
																);
															}

															return Promise.resolve();
														}
													})
												]}
											>
											
												<FormFieldStacked
												formType='input'
												type="text"
												disabled={submitted}
												placeholder="Minimum is based on selected Currency."
												ref={monthlyPriceRef}>											
												</FormFieldStacked>
										</Form.Item>
									</div>		
									
									</div>
									<div className="col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											For traditional currencies, you can specify up to 2
											decimal places, i.e. ¥500 JPY or $5.00 USD. For
											cryptocurrencies, you can specify up to 8 decimal
											places, i.e. ethereUm 0.12345678 ETH. Minimums vary by
											Currency.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="col-span-12 md:col-span-8 my-auto !order-last md:!order-first">
										<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
										Included Months*
										</label>
										<div className="relative rounded-none ">
										<Form.Item
												label="Included Months"
												name="included_months"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
													({ getFieldValue }) => ({
														validator(_, value) {
															if (
																(typeof value !== 'undefined' &&
																	value !== '' &&
																	(isNaN(value) || value < 1)) ||
																(typeof value === 'string' &&
																	value.match(/\./g))
															) {
																return Promise.reject(
																	new Error('Invalid value.')
																);
															}

															return Promise.resolve();
														}
													})
												]}
											>									
											<FormFieldStacked
											formType='input'
											type="text"
											disabled={submitted}
											placeholder="Whole numbers only, 1 or more."
											ref={includedMonthsRef}>											
											</FormFieldStacked>
											</Form.Item>
										</div>
										
									</div>	
									
									<div className="col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											You must include at least 1 Month. Once activated, the
											subscription starts and when the Months are used up, the
											Monthly Subscription must be renewed or topped up.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="col-span-12 md:col-span-8 my-auto !order-last md:!order-first">
										<label  className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
											Description*
										</label>
										<div className="relative rounded-none ">
											<Form.Item
												label="Description"
												name="description"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[{ required: true }]}
											>
												
												<FormFieldStacked
												formType='textarea'
												type="text"
												max={160}
												disabled={submitted}
												placeholder="Up to 160 characters, including spaces."
												>											
												</FormFieldStacked>
												
											</Form.Item>
										</div>
								
									</div>
									
									<div className="col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											Share details on the value of the subscription and what Members can expect.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="col-span-12 md:col-span-8 my-auto !order-last md:!order-first">
										<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
											Renewal Discount*
										</label>
										<div className="relative rounded-none ">
										<Form.Item
												label="Renewal Discount"
												name="renewal_discount"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
													{ validator: CustomValidators.discount }
												]}
											>
												
												<FormFieldStacked
												formType='input'
												type="text"
												disabled={submitted}
												placeholder="Whole numbers only, up to 15. Saved as a percent, e.g. “15” equals 15%."
												ref={renewalDiscountRef}>											
												</FormFieldStacked>
											</Form.Item>
										</div>
										
									</div>	
									
									<div className="col-span-12 md:col-span-4 flex my-auto !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											<a className="gradient-color-txt">
												{' '}
												You can offer discounts for longer renewals.
											</a>{' '}
											The percent discount is added at each renewal option of
											3, 6, and 12 months. e.g. a 5% Renewal Discount would
											give 5% off 3 Month, 10% off 6 Month, and 15% off 12
											Month renewals.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-12 md:gap-8 mt-8">
									<div className="col-span-12 md:col-span-8 flex ">
										<Form.Item>
											<Button
												className="btn btn-lg btn-primary"
												htmlType="submit"
												disabled={submitted || formHasError}
											>
												{submitted && (
													<i className="fa-solid fa-spinner fa-spin"></i>
												)}
												Save Benefits & Continue
											</Button>
										</Form.Item>
									</div>
									<div className="col-span-12 md:col-span-4 flex ">
										<Button
											className="btn btn-lg btn-warning bg-none"
											disabled={submitted || formHasError}
											onClick={saveAndGoBack}
										>
											Save & Go Back
										</Button>
									</div>
								</div>
							</Form>
						</>
					) : (
						<>
							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="col-span-12 md:col-span-8 flex my-auto">
									<Button
										onClick={saveContinue}
										className="btn btn-lg btn-primary"
										htmlType="submit"
									>
										Save Membership & Continue
									</Button>
								</div>
								<div className="col-span-12 md:col-span-4 flex my-auto">
									<Button
										onClick={saveBack}
										className="btn btn-lg btn-warning bg-none"
										htmlType="submit"
									>
										Save & Go Back
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</main>
		</>
	);
};

export default memo(SpaceCreateAddBenefit);
