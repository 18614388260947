import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';
import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import TextareaAutosize from 'react-textarea-autosize';
import FormFieldStacked from '../../../../components/commons/FormFieldStacked';

import {
	MAGIC_OAUTH_KEY,
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_NETWORK
} from '../../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE
} from '../../../../constants/file';
import { GENDERS, PRONOUNS } from '../../../../constants/profile';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { updateProfile } from '../../../../services/user';
import { checkUsernameAvailability } from '../../../../services/general';
import { getAuthUserInfo } from '../../../../states/general/actions';
import UploadDragger from '../../../../components/commons/UploadDragger';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { shortenNumber } from '../../../../utils/number';
import { getListDisplay } from '../../../../utils/list';
import { CUSTOM_REGEX } from '../../../../constants/custom_regex';
import {
	displayPageContentLoader,
	hidePageContentLoader
} from '../../../../states/pageContentLoader';
import Alert from 'react-bootstrap/Alert';

const ProfileEdit = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const gendersList = GENDERS;
	const pronounsList = PRONOUNS;
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(false);
	const [profilePhoto, setProfilePhoto] = useState(null);
	const [profilePhotoBlobPreview, setProfilePhotoBlobPreview] = useState(null);
	const [profileVideo, setProfileVideo] = useState(null);
	const [profileVideoBlobPreview, setProfileVideoBlobPreview] = useState(null);
	const [profileBackgroundPhoto, setProfileBackgroundPhoto] = useState(null);
	const [profileBackgroundPhotoBlobPreview, setProfileBackgroundPhotoBlobPreview] =
		useState(null);
	const [profileBackgroundVideo, setProfileBackgroundVideo] = useState(null);
	const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] =
		useState(null);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [cardBackground, setCardBackground] = useState(null);
	const [cardBackgroundType, setCardBackgroundType] = useState(null);
	const [username, setUsername] = useState(null);
	const [displayName, setDisplayName] = useState(null);
	const [location, setLocation] = useState(null);
	const [bio, setBio] = useState(null);
	const [website, setWebsite] = useState(null);
	const [selectedGender, setSelectedGender] = useState('default');
	const [selectedPronouns, setSelectedPronouns] = useState('default');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [photoBGIsRemoved, setPhotoBGIsRemoved] = useState(false);
	const [videoBGIsRemoved, setVideoBGIsRemoved] = useState(false);
	const [alert, setAlert] = useState(null);
	const { size } = useSelector((state) => state.classSize);

	if (!gendersList.find((x) => x.value === 'default')) {
		gendersList.unshift({
			label: 'Gender',
			value: 'default',
			disabled: true
		});
	}

	if (!pronounsList.find((x) => x.value === 'default')) {
		pronounsList.unshift({
			label: 'Pronouns',
			value: 'default',
			disabled: true
		});
	}

	const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new OAuthExtension()]
	});

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setUsername(form.getFieldValue('username'));
		setDisplayName(form.getFieldValue('display_name'));
		setLocation(form.getFieldValue('location'));
		setBio(form.getFieldValue('bio'));
		setWebsite(form.getFieldValue('website'));

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const websiteOnBlur = (event) => {
		showTooltip(event);
		if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(event.target.value)) {
			event.target.value = 'https://' + event.target.value;
			form.setFieldValue('website', event.target.value);
		}
	};

	const handleSubmit = useCallback(
		async (values) => {
			setDisplayDropDownError(true);

			const data = values;

			//include url protocol when url protocol is missing
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.website)) {
				data.website = 'https://' + data.website;
			}

			if (selectedGender !== 'default') {
				data.gender = selectedGender;
			}
			if (selectedPronouns !== 'default') {
				data.pronouns = selectedPronouns;
			}

			if (profilePhoto) {
				data.photo = profilePhoto;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (profileVideo) {
				data.video = profileVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			if (profileBackgroundPhoto) {
				data.background_photo = profileBackgroundPhoto;
			} else {
				if (photoBGIsRemoved) {
					data.background_photo_is_empty = true;
				}
			}

			if (profileBackgroundVideo) {
				data.background_video = profileBackgroundVideo;
			} else {
				if (videoBGIsRemoved) {
					data.background_video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					setSubmitted(true);
					data.user_id = authUserInfo.id;
					const resultProfile = await updateProfile(data);
					if (resultProfile) {
						dispatch(getAuthUserInfo());
						setSubmitted(false);
						setAlert({
							variant: 'success',
							message: resultProfile.message.toString()
						});
						setTimeout(() => {
							setAlert(null);
						}, 5000);
					} else {
						setAlert({
							variant: 'danger',
							message: 'Failed to update profile'
						});
						setTimeout(() => {
							setAlert(null);
						}, 5000);
					}
				}
			} catch (error) {
				console.log(error);
				setAlert({
					variant: 'danger',
					message: 'Something went wrong when updating profile'
				});
				setTimeout(() => {
					setAlert(null);
				}, 5000);
			}
		},
		[
			authUserInfo,
			selectedGender,
			selectedPronouns,
			profilePhoto,
			profileVideo,
			profileBackgroundPhoto,
			profileBackgroundVideo,
			photoIsRemoved,
			videoIsRemoved,
			photoBGIsRemoved,
			videoBGIsRemoved,
			alert
		]
	);

	const handleProfilePhotoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setProfilePhoto(file);
		setProfilePhotoBlobPreview(blobPreview);

		form.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	const handleProfileVideoUploadFileChange = useCallback(
		async (file, blobPreview) => {
			if (!file) {
				setVideoIsRemoved(true);
			} else {
				setVideoIsRemoved(false);
			}

			if (blobPreview && profileVideoBlobPreview && blobPreview !== profileVideoBlobPreview) {
				setProfileVideo(null);
				setProfileVideoBlobPreview(null);

				setTimeout(() => {
					setProfileVideo(file);
					setProfileVideoBlobPreview(blobPreview);
				});
			} else {
				setProfileVideo(file);
				setProfileVideoBlobPreview(blobPreview);
			}

			form.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		},
		[profileVideoBlobPreview]
	);

	const handleProfileBackgroundPhotoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoBGIsRemoved(true);
		} else {
			setPhotoBGIsRemoved(false);
		}

		setProfileBackgroundPhoto(file);
		setProfileBackgroundPhotoBlobPreview(blobPreview);

		form.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	const handleProfileBackgroundVideoUploadFileChange = useCallback(
		async (file, blobPreview) => {
			if (!file) {
				setVideoBGIsRemoved(true);
			} else {
				setVideoBGIsRemoved(false);
			}

			if (
				blobPreview &&
				profileBackgroundVideoBlobPreview &&
				blobPreview !== profileBackgroundVideoBlobPreview
			) {
				setProfileBackgroundVideo(null);
				setProfileBackgroundVideoBlobPreview(null);

				setTimeout(() => {
					setProfileBackgroundVideo(file);
					setProfileBackgroundVideoBlobPreview(blobPreview);
				});
			} else {
				setProfileBackgroundVideo(file);
				setProfileBackgroundVideoBlobPreview(blobPreview);
			}

			form.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		},
		[profileBackgroundVideoBlobPreview]
	);

	const checkLoginRedir = async () => {
		const queryParameters = new URLSearchParams(window.location.search);
		const provider = queryParameters.get('provider') ?? 'No Provider';
		if (provider !== 'No Provider') {
			const result = await magicOauth.oauth.getRedirectResult();
			let profile = result.oauth.userInfo.profile ?? 'No Profile found';
			if (provider === 'tweeter' || provider === 'discord') {
				profile = result.oauth.userInfo.preferredUsername ?? 'No Profile found';
			}
			let email = authUserInfo.email ?? result.oauth.userInfo.email ?? 'No Email found';
			// console.log(profile);
			// console.log(email);

			let data = {
				email: email,
				social_type: provider,
				social_link: profile
			};
			await social_link(data);
		}
	};

	const linkGoogle = async () => {
		await magicOauth.oauth.loginWithRedirect({
			provider: 'google' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkFacebook = async () => {
		await magicOauth.oauth.loginWithRedirect({
			provider: 'facebook' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkTweeter = async () => {
		await magicOauth.oauth.loginWithRedirect({
			provider: 'tweeter' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkDiscord = async () => {
		await magicOauth.oauth.loginWithRedirect({
			provider: 'discord' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const setFormProfileData = async (data) => {
		form.setFieldsValue({
			username: data.profile.username,
			display_name: data.profile.display_name,
			location: data.profile.location,
			bio: data.profile.intro,
			website: data.profile.url
		});
		setUsername(data.profile.username);
		setDisplayName(data.profile.display_name);
		setLocation(data.profile.location);
		setBio(data.profile.intro);
		setWebsite(data.profile.url);
		if (data.profile.gender) {
			setSelectedGender(data.profile.gender);
		}
		if (data.profile.pronouns) {
			setSelectedPronouns(data.profile.pronouns);
		}
		if (data.profile.photo_large) {
			setProfileAssetType('image');
			setProfilePhotoBlobPreview(data.profile.photo_thumb);
		}
		if (data.profile.video) {
			setProfileAssetType('video');
			setProfileVideoBlobPreview(data.profile.video);
		}
		if (data.profile.background_photo) {
			setProfileBackgroundPhotoBlobPreview(data.profile.background_photo);
			const space_bg = {
				backgroundImage: 'url(' + data.profile.background_photo + ')'
			};
			setCardBackground(space_bg);
			setCardBackgroundType('image');
		}
		if (data.profile.background_video) {
			setProfileBackgroundVideoBlobPreview(data.profile.background_video);
			setCardBackgroundType('video');
		}

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	};

	const updateProfilePhotoAsset = async (photo, video, bg_photo, bg_video) => {
		if (!video && photo) {
			setProfileAssetType('image');
			setProfilePhotoBlobPreview(photo);
		}
		if (video) {
			setProfileAssetType('video');
			setProfileVideoBlobPreview(video);
		}
		if (!video && !photo) {
			setProfileAssetType('image');
			setProfilePhotoBlobPreview(null);
			setProfileVideoBlobPreview(null);
		}
		if (!bg_video && bg_photo) {
			setProfileBackgroundPhotoBlobPreview(bg_photo);
			const space_bg = {
				backgroundImage: 'url(' + bg_photo + ')'
			};
			setCardBackground(space_bg);
			setCardBackgroundType('image');
		}
		if (bg_video) {
			setProfileBackgroundVideoBlobPreview(bg_video);
			setCardBackgroundType('video');
		}
		if (!bg_video && !bg_photo) {
			setCardBackgroundType('image');
			setCardBackground(null);
			setProfileBackgroundVideoBlobPreview(null);
		}
	};

	useEffect(() => {
		if (authUserInfo) {
			dispatch(hidePageContentLoader());
		} else {
			dispatch(displayPageContentLoader());
		}
	}, [authUserInfo]);

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo && initialLoad) {
			setInitialLoad(false);
			setFormProfileData(authUserInfo).then((r) => {});
		}

		checkLoginRedir();

		if (!initialLoad) {
			updateProfilePhotoAsset(
				profilePhotoBlobPreview,
				profileVideoBlobPreview,
				profileBackgroundPhotoBlobPreview,
				profileBackgroundVideoBlobPreview
			);
		}
	}, [
		loading,
		authUserInfo,
		initialLoad,
		profilePhotoBlobPreview,
		profileVideoBlobPreview,
		profileBackgroundPhotoBlobPreview,
		profileBackgroundVideoBlobPreview
	]);

	return (
		<section className='grid grid-cols-12  gap-y-8 lg:gap-y-0 lg:gap-8'>
			<div className="col-span-12 lg:col-span-6">
				<div className="card-btn-wrapper pb-3">
					<h3 className="mb-4 mt-3 text-5xl font-['din2014Bold'] gradient-color-txt">
						Editing{' '}
						{authUserInfo &&
							authUserInfo.profile.username &&
							'@' + authUserInfo.profile.username}
					</h3>
					<div>
						{alert && (
							<Alert
								key={alert.variant}
								variant={alert.variant}
								className={
									'custom-alert ' +
									(alert.variant === 'danger'
										? 'custom-alert-danger'
										: 'custom-alert-success')
								}
							>
								{alert.message}
							</Alert>
						)}
					</div>
					<div className="grid grid-cols-12  mt-5 relative z-0">
						<div className="col-span-6">
							<div className="upload-circ">
								<UploadDragger
									cropperAspectRatio={1 / 1}
									circular="true"
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={profilePhotoBlobPreview}
									onUploadFileChange={handleProfilePhotoUploadFileChange}
									minHeight={IMAGE_STANDARD_MIN_HEIGHT}
									minWidth={IMAGE_STANDARD_MIN_WIDTH}
									maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
									maxWidth={IMAGE_STANDARD_MAX_WIDTH}
									uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt font-[notoSansRegular] text-center mt-3 mb-0">
								Profile Image
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 1920px, square preferred
								<br />
								JPEG, PNG, or GIF
							</p>
						</div>
						<div className="col-span-6">
							<div className="upload-circ">
								<UploadDragger
									circular="true"
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={profileVideoBlobPreview}
									onUploadFileChange={handleProfileVideoUploadFileChange}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Profile Video
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 1920px/1080p, square preferred
								<br />
								MOV or MP4
							</p>
						</div>
					</div>
					<div className="grid grid-cols-12  mt-5 relative z-0">
						<div className="col-span-6">
							<div className="upload-rec">
								<UploadDragger
									cropperAspectRatio={16 / 9}
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={profileBackgroundPhotoBlobPreview}
									onUploadFileChange={
										handleProfileBackgroundPhotoUploadFileChange
									}
									minHeight={IMAGE_LARGE_MIN_HEIGHT}
									minWidth={IMAGE_LARGE_MIN_WIDTH}
									maxHeight={IMAGE_LARGE_MAX_HEIGHT}
									maxWidth={IMAGE_LARGE_MAX_WIDTH}
									uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Background Image
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 3840px, square preferred
								<br />
								JPEG, PNG, or GIF
							</p>
						</div>
						<div className="col-span-6">
							<div className="upload-rec">
								<UploadDragger
									hideDragIcon="true"
									hideText="true"
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={profileBackgroundVideoBlobPreview}
									onUploadFileChange={
										handleProfileBackgroundVideoUploadFileChange
									}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<i className="fa-regular fa-plus"></i>
							</div>
							<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
								Background Video
							</p>
							<p className="text-center body-txtitalic--smallest">
								Up to 1920px/1080p, square or wide
								<br />
								MOV or MP4
							</p>
						</div>
					</div>
					<Form
						form={form}
						name="profileEditForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocus(false);
								handleFormOnBlur();
							}, 100);
						}}
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						className={formIsOnFocus ? 'hide-antd-error-messages relative z-0' : 'relative z-0'}
					>
						<div className="grid grid-cols-12 ">
							<div className="col-span-12">
							<div className="mb-8 mt-8">
								<span className="block text-base gradient-color-txt mb-2 mb-2">
									Username*
								</span>
								<div className="relative  rounded-none ">
									<Form.Item
									label="Username"
									name="username"
									className="mb-0 hidden-label font-[notoSansRegular]"
									validateTrigger="onBlur"
									rules={[
									{ required: true },
									{
										min: 2,
										message: 'Must be minimum 2 characters.'
									},
									{
										max: 32,
										message: 'Must not exceed 32 characters.'
									},
									({ getFieldValue }) => ({
										async validator(_, value) {
											// TODO: find way to trigger username API check on form submit to avoid flood requests
											// if (!formIsOnFocus && value) {
											if (value) {
												const data = {
													current_user_id: authUserInfo.id,
													username: username
												};

												let available = false;
												const response =
													await checkUsernameAvailability(data);
												if (response && response.result) {
													available = response.availability;
												}

												if (!available) {
													return Promise.reject(
														new Error('Username not available.')
													);
												}
											}

											if (
												value.match(/\ /g) ||
												/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(
													value
												)
											) {
												return Promise.reject(
													new Error('Invalid Input')
												);
											}

											return Promise.resolve();
												}
											})
										]}
									>
										
											<FormFieldStacked
											formType='input'
											type="text"
											defaultValue={username}	
											disabled={submitted}
											placeholder="2 to 32 characters. Letters, numbers, periods, and underscores only"
											></FormFieldStacked>
										</Form.Item>
									</div>									
								</div>
								
								<div className="mb-8">
									<span className="block text-base gradient-color-txt mb-2 mb-2">
										Display Name*
									</span>
									<div className="relative  rounded-none ">
										<Form.Item
												label="Display Name"
												name="display_name"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
													{
														max: 32,
														message: 'Must not exceed 32 characters.'
													}
												]}
											>
											
												<FormFieldStacked
												formType='input'
												type="text"	
												disabled={submitted}
												placeholder="Up to 32 characters."
												></FormFieldStacked>
										</Form.Item>
									</div>
										
								</div>
								<div className="mb-8">
									<span className="block text-base gradient-color-txt mb-2 mb-2">
										Location
									</span>
									<div className="relative  rounded-none ">
										<Form.Item
											label="Location"
											name="location"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{
													max: 32,
													message: 'Must not exceed 32 characters.'
												}
											]}
										>
																					
											<FormFieldStacked
											formType='input'
											type="text"	
											disabled={submitted}
											placeholder="Up to 32 characters. This displays on your Profile and is separate from your private City location."
											></FormFieldStacked>
										</Form.Item>
									</div>
									
								</div>
								<div className="mb-8">
									<span className="block text-base gradient-color-txt mb-2 mb-2">
										Bio
									</span>
									<div className="relative  rounded-none ">
										<Form.Item
											label="Bio"
											name="bio"
											className="mb-0 hidden-label "
											validateTrigger="onBlur"
											rules={[
												{
													max: 255,
													message: 'Must not exceed 255 characters.'
												}
											]}
										>
										
											<FormFieldStacked
												formType='textarea'
												type="text"	
												
												maxLength={255}
												disabled={submitted}
												placeholder="Up to 255 characters."
												></FormFieldStacked>
										</Form.Item>
									</div>
									
								</div>
								<div className="mb-8">
									<span className="block text-base gradient-color-txt mb-2 mb-2">
										Website	
									</span>
									<div className="relative  rounded-none ">
										<Form.Item
											label="Website"
											name="website"
											className="mb-0 hidden-label"
											onBlur={websiteOnBlur}
											validateTrigger="onBlur"
											rules={[
												{
													// type: 'url',
													pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
													message: 'Please Enter a valid URL'
												}
											]}
										>
									
										<FormFieldStacked
											formType='input'
											type="text"	
											disabled={submitted}
											placeholder="www.domain.com"
											></FormFieldStacked>
										</Form.Item>
									</div>
									
								</div>
										
										
								<div className=" mb-8">
									<span className="block text-base gradient-color-txt mb-2 mb-2">
										Socials
									</span>
									<ul className="text-xl disabledColor grid grid-cols-4 sm:grid-cols-8">
										<li>
											<i
												className="fa-brands fa-twitter"
											></i>
										</li>
										<li>
											<i className="fa-brands fa-instagram"></i>
										</li>
										<li>
											<i
												className="fa-brands fa-facebook"
											></i>
										</li>
										<li>
											<i className="fa-brands fa-tiktok"></i>
										</li>
										<li>
											<i
												className="fa-brands fa-youtube"
											></i>
										</li>
										<li>
											<i className="fa-brands fa-linkedin"></i>
										</li>
										<li>
											<i
												className="fa-brands fa-discord"
											></i>
										</li>
										<li>
											<i className="fa-brands fa-twitch"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="grid  grid-cols-12 gap-y-8 lg:gap-y-0 md:gap-8">
							<div className="col-span-12 lg:col-span-6">
								<DropdownWithItems
									title="Gender"
									customButtonClass="btn-md btn-secondary"
									icon="fa-regular fa-user"
									selectedValue={selectedGender}
									listItems={gendersList}
									onDropdownChange={setSelectedGender}
								/>
							</div>
							<div className="col-span-12 lg:col-span-6">
								<DropdownWithItems
									title="Pronouns"
									customButtonClass="btn-md btn-secondary"
									icon="fa-solid fa-user"
									selectedValue={selectedPronouns}
									listItems={pronounsList}
									onDropdownChange={setSelectedPronouns}
								/>
							</div>
						</div>
						<Form.Item>
							<Button
								className="btn btn-lg btn-primary mt-8"
								htmlType="submit"
								disabled={submitted || formHasError}
							>
								{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
								Save Profile
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
			<div className='col-span-12 lg:col-span-6 flex items-center'>
				<div className="profile-card-dashboard rounded-3xl h-[638px]" style={cardBackground}>
					{cardBackgroundType &&
						cardBackgroundType === 'video' &&
						profileBackgroundVideoBlobPreview && (
							<video autoPlay muted loop className="absolute w-full h-full object-cover left-0 top-0 rounded-3xl">
								<source src={profileBackgroundVideoBlobPreview} type="video/mp4" />
							</video>
						)}
					<div className="z-index-ontop">
						{profileAssetType && profileAssetType === 'video' ? (
							<div className="video-profile-background-container">
								<video autoPlay muted loop className="video-profile-avatar">
									<source src={profileVideoBlobPreview} type="video/mp4" />
								</video>
							</div>
						) : (
							<img src={profilePhotoBlobPreview} />
						)}
						<h5 className="body-text--reg mt-4">@{username ? username : 'username'}</h5>
						<h1 className="header-large pb-3">
							{displayName ? displayName : 'Display Name'}
						</h1>
						<p className="body-txtitalic--small">
							{getListDisplay(PRONOUNS, selectedPronouns)}
						</p>
						<p className="py-3 px-5 body-txtitalic--smallest">{bio ? bio : ''}</p>
						<p className="body-txtitalic--small">{location ? location : ''}</p>
						<ul className="list-socials py-2 flex justify-around">
							<li>
								{/* <a
									href="src/pages/Profile/ProfileContent/ProfileEdit/index"
									onClick={linkTweeter}
								> */}
								<i className="fa-brands fa-twitter isDisabled"></i>
								{/* </a> */}
							</li>
							{/* <li>
                            <a href="src/pages/Profile/ProfileContent/ProfileEdit/index">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                        </li> */}
							<li>
								{/* <a
									href="src/pages/Profile/ProfileContent/ProfileEdit/index"
									onClick={linkFacebook}
								> */}
								<i className="fa-brands fa-facebook isDisabled"></i>
								{/* </a> */}
							</li>
							{/* <li>
                            <a href="src/pages/Profile/ProfileContent/ProfileEdit/index">
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                        </li> */}
							<li>
								{/* <a
									href="src/pages/Profile/ProfileContent/ProfileEdit/index"
									onClick={linkGoogle}
								> */}
								<i className="fa-brands fa-youtube isDisabled"></i>
								{/* </a> */}
							</li>
							{/* <li>
                            <a href="src/pages/Profile/ProfileContent/ProfileEdit/index">
                                <i className="fa-brands fa-linkedin"></i>
                            </a>
                        </li> */}
							<li>
								{/* <a
									href="src/pages/Profile/ProfileContent/ProfileEdit/index"
									onClick={linkDiscord}
								> */}
								<i className="fa-brands fa-discord isDisabled"></i>
								{/* </a> */}
							</li>
							{/* <li>
                            <a href="src/pages/Profile/ProfileContent/ProfileEdit/index">
                                <i className="fa-brands fa-twitch"></i>
                            </a>
                        </li> */}
							<li>
								{/* <a href="src/pages/Profile/ProfileContent/ProfileEdit/index"> */}
								<i className="fa-regular fa-globe isDisabled"></i>
								{/* </a> */}
							</li>
						</ul>
						<div className="flex justify-around p-4">
							<div>
								<p className="body-text--large mb-0">
									{shortenNumber(authUserInfo ? authUserInfo.followers_count : 0)}
								</p>
								<p className="body-text--smallest">Followers</p>
							</div>
							<div>
								<p className="body-text--large mb-0">
									{shortenNumber(
										authUserInfo ? authUserInfo.followings_count : 0
									)}
								</p>
								<p className="body-text--smallest">Following</p>
							</div>
							<div>
								<p className="body-text--large mb-0">
									{shortenNumber(authUserInfo ? authUserInfo.spaces_count : 0)}
								</p>
								<p className="body-text--smallest">Spaces</p>
							</div>
							<div>
								<p className="body-text--large mb-0">
									{shortenNumber(
										authUserInfo ? authUserInfo.collections_count : 0
									)}
								</p>
								<p className="body-text--smallest">Items</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default memo(ProfileEdit);
