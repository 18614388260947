import React, {memo, useCallback, useEffect, useState, useRef } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {shortenNumber} from '../../../../utils/number';
import {getSitewideLink} from "../../../../utils/sitewideLinks";
import routes from "../../../../routes";
import default_profile_card_bg from "../../../../assets/images/profile_header.png";
import moment from "moment/moment";
import MembershipBenefits from "./MembershipBenefits";
import RenewPaymentMethod from "./RenewPaymentMethod";
import {format} from "date-fns";
import {checkMembershipItemStatus} from "../../../../services/payment_method";
import {getSpaceMembership} from "../../../../services/space";
import ActivationMembershipModal from "../../../../components/commons/Modals/ActivationMembership/ActivationMembershipModal";
import { getExpirationDate, DateDayCondition } from '../../../../components/commons/helpers/MembershipItemHelpers';
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }
const ManageSpaceMembership = (parentProps) => {
	const { user, space, membershipID } = parentProps;
	const [membershipRequesting, setMembershipRequesting] = useState(false);
	const [membership, setMembership] = useState(null);
	const [isRecurring, setRecurring] = useState(false);
	const [status, setStatus] = useState('')
	const [benefits, setBenefits] = useState(null);
	const [paymentTransaction, setPaymentTransaction] = useState(null);
	const [membershipAssetType, setMembershipAssetType] = useState('image');
	const [isOpen, setIsOpen] = useState(false);
	const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);
	const [isActivationOpen, setActivationOpen] = useState(false);
	const [activationStatus, setActivationStatus] = useState('');
	const [expirationDateDeductOneDay, setExpirationDateDeductOneDay] = useState('');
	const containerRef = useRef(null);

	const navigate = useNavigate();
	const toggleSlide = () => {
		setIsOpen(!isOpen);
	};



	  const handleClick = () => {
		const anchor = document.querySelector('#your-memberships')
		anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
	  }; 

	let space_bg = null;
	if (space) {
		if (space.background_photo || space.background_video) {
			if (space.background_photo) {
				space_bg = {
					backgroundImage: 'url(' + space.background_photo + ')'
				};
			}
		} else {
			space_bg = {
				backgroundImage: 'url(' + default_profile_card_bg + ')'
			};
		}
	}

	

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + space.username;
		window.location.replace(url);
	}, [space]);


	const getMembership = useCallback(async (space_id, membership_id) => {
		const data = {
			space_id: space_id,
			membership_id: membership_id
		}

		if (user) {
			data['viewing_user_id'] = user.id;
		}

		const response = await getSpaceMembership(data);
		if (response && response.result) {
			const membershipData = response.membership;
			setMembershipRequesting(true);
			setMembership(membershipData);
			setBenefits(response.membership_benefits);
		} else {
			const url = routes.spaceDashboard().replace(':space_username', '@' + space.username);
			window.location.replace(url);
		}
	}, []);

	// const getMemberDataRequest = useCallback(
	// 	async () => {
	// 		if (user && space && !membershipRequesting) {
	// 			await getPaymentTransaction();
	// 			setMembershipRequesting(true);
	// 			dispatch(displayPageContentLoader());
	// 			const response = await getSpaceMembership({
	// 				space_id: space.id,
	// 				membership_id: membershipID,
	// 				// viewing_user_id: user.id
	// 			});
	// 			if (response && response.result) {
	// 				setMembership(response.membership);
	// 				setBenefits(response.membership_benefits);
	// 			} else {
	// 				const url = routes.spaceDashboard().replace(':space_username', '@' + space.username);
	// 				window.location.replace(url);
	// 			}
	// 			dispatch(hidePageContentLoader());
	// 			setMembershipRequesting(false);
	// 		}
	// 	},
	// 	[user, space, membershipRequesting]
	// );

	// const BenefitRow = (data) => {
	// 	return (
	// 		<>
	// 			<div className="row items-center pt-5">
	// 				<div className="col-2">
	// 					<label className="body-text--small gradient-color-txt">
	// 						{data.title}
	// 					</label>
	// 				</div>
	// 				<div className="col-7">
	// 					<p className="body-txtitalic--smallest m-0">
	// 						{data.description}
	// 					</p>
	// 				</div>
	// 				<div className="col-3">
	// 					<Link to="#" className="btn btn-secondary btn-md">
	// 						<i className="fa-light fa-solar-system"></i>
	// 						<span className="w-3/4">Access Benefit</span>
	// 						<i className="fa-light fa-arrow-up-right-from-square"></i>
	// 					</Link>
	// 				</div>
	// 			</div>
	// 		</>
	// 	);
	// };

	const checkStatus = useCallback(async (space_id, membership_id) => {
		if (!membership_id) return;
		const data = {
			space_id: space_id,
			membership_id: membership_id
		}
		if (user) {
			data['user_id'] = user.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response && response.result) {
			const transaction = response.stripe_transaction;
			if (transaction) {
				setStatus(transaction.status)
			}
			setCheckStatusLoaded(true);
		}
	},[user])

	const activation = (status) =>{
		if (status === 'inactive') {
			setActivationStatus('inactive');
			setActivationOpen(true);
		} else if (status === 'active') {
			setActivationStatus('active');
			setActivationOpen(true);

		}
	}

	useEffect(() => {
		document.title = 'Commonspace Spaces';

		if (!membershipRequesting) {
			if (space && space.id && user && user.id) {
				checkStatus(space.id, membershipID).then(r =>{} );
				getMembership(space.id, membershipID).then((r) => {
				});
			}
		}
	}, [user, space]);

	useEffect(() => {
		if (membership) {
			if (membership.photo || membership.video) {
				if (membership.photo) {
					setMembershipAssetType('image');
				}
				if (membership.video) {
					setMembershipAssetType('video');
				}
			} else {
				setMembershipAssetType('image');
			}

			setRecurring(membership.enable_expiration_and_subscription && membership.first_benefit);
		}
	}, [membership, benefits, paymentTransaction, membershipRequesting, isRecurring, isCheckStatusLoaded, status, isActivationOpen, activationStatus]);
	const goToSpaceCollections = useCallback(async () => {
		if(space.items.length > 0){
			const collectionsViewRoute = routes.viewCollection();
			const finCollectionsViewRoute = collectionsViewRoute.replace(
				':space_username',
				space.username
			);
			console.log(finCollectionsViewRoute)
			window.location.replace(finCollectionsViewRoute);
		}
	}, [space]);

	return (
		<>
			{!space  ? (
				<div className="loading-items">
					<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
				</div>
			) : (
				<>
					<div className={` mt-4 space-manage-bg ${isOpen ? 'open' : ''}`} style={space_bg}>
						<div className="grid grid-cols-12 gap-4 flex items-center">
							<div className="col-span-12 md:col-span-3">
								<img src={space.photo_small} />
							</div>
							<div className="space-manage-content col-span-12 md:col-span-5">
								<h3 className="header-title">{space.individual_title}</h3>
								{membership &&
									<>
										<h4 className="header-title">{membership.name}</h4>
										<h5>{membership.summary}</h5>
									</>
								}
								<h4 className="header-title mt-4">Member Details</h4>
								<div className="grid grid-cols-12 gap-4 flex items-center justify-content-start">
									<div className="col-span-12 md:col-span-8">
										<span className="body-txtitalic--smallest">@{user ? user.profile.username : ''}</span>
									</div>
									<div className="col-span-12 md:col-span-4 ms-5">
										<span className="body-text--smallest">
											<i className={'me-2 ' + space.hot_icon}></i>
											<label>
												{shortenNumber(space.hot_count)} {space.hot_name}
											</label>
										</span>
										<span className="body-txtitalic--smallest">
											
											{!membershipID &&
												<>
													<i className="fa-solid fa-waveform-lines"/>
													{space.hot_ranking} Unsound
												</>
											}

										</span>
									</div>
									{membership &&  
									
									<div className="col-span-12">
										<span className="body-txtitalic--smallest m-0 ">
											{!isRecurring && membership.created_on ? (
											<i className='text-[#888888]'>Member since {moment(membership.created_on).format('MMMM YYYY')}</i>
											) : (
											<i className='text-[#888888]'>Active until {getExpirationDate(isRecurring.created_on, isRecurring.included_months, DateDayCondition.deductOneDay)}</i>
											)}
										</span>
									</div>

									}
								</div>

								<div className="grid grid-cols-12 gap-4 flex items-center justify-start">
									<div className="col-span-12 md:col-span-8">
										<span className="body-txtitalic--smallest">
											{!membershipID &&
												<>
													Issued {format(new Date(space.launched_on), 'MMMM d, yyyy')}
												</>
											}
											{/*{membershipID && paymentTransaction &&*/}
											{/*	<>*/}
											{/*		Expired {format(new Date(paymentTransaction.stripe_date_cancellation), 'MMMM d, yyyy')}*/}
											{/*	</>*/}
											{/*}*/}
										</span>
									</div>
									{!membershipID &&
										<div className="col-span-4 ms-5">
											<span className="body-text--smallest">
												<i className={'me-2 ' + space.hot_icon}></i>
												<label>
													{shortenNumber(space.hot_count)} {space.hot_name}
												</label>
											</span>
											<span className="body-txtitalic--smallest">
												<i className="fa-solid fa-ranking-star"></i> #
												{space.hot_ranking} Rank
											</span>
										</div>
									}
								</div>
							</div>

							<div className="col-span-12 md:col-span-4">
								<Link className={`btn btn-tertiary ${isOpen?'focus':''} btn-md `} onClick={() => {
									if (status === 'expired')
										toggleSlide();
									else
										goToSpace();
								}}>
									<i className="fa-light fa-solar-system"></i>

									<span className="w-3/4 text-center">{status === 'expired' ? 'Renew for Access' : 'Access Space' }</span>
									<i className={`fa-light ${status === 'expired' ? 'fa-lock' : 'fa-arrow-up-right-from-square'}`}></i>
								</Link>

								<Link to="#" className="btn btn-tertiary btn-md mt-4">
									<span className='text-[#888888]'>View Transactions</span>
								</Link>
							
								{getSitewideLink('get_support', 'btn btn-tertiary btn-md mt-4')}
							</div>
						</div>
						<div className="bg-overlay"></div>
					</div>

					<div className={`slide-down ${isOpen ? 'open' : ''}`}>
						<RenewPaymentMethod user={user} space={space} membershipID={membershipID} isRecurring={isRecurring} isMembershipBenefit={false}/>
					</div>

					<div className={'membership-holder'}>
						<MembershipBenefits benefits={benefits}
											user={user}
											space={space}
											isRecurring={isRecurring}
											membershipID={membershipID}
											parentOpenState={isOpen}
											parentSetOpenState={setIsOpen}
											status={status}
											activation={activation}
						/>
					</div>

					{ status === 'active' && benefits && (
						<>
							<div className="dashboard-wrapper mt-4">
								<div className="grid grid-cols-12 gap-4 mt-4">
									<div className="col-span-12 md:col-span-3">
										<h5 className="p-1 header-small">Item Benefits</h5>
									</div>
									<div className="col-span-12 md:col-span-9">
										<div className="body-text--small text-end">
											Your Active Item Benefits for&nbsp;
											<label className="body-text--small gradient-color-txt">
												<i className="fa-light fa-solar-system"/>&nbsp;
												{space.name}
											</label>
										</div>
									</div>
								</div>
								<div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding mb-8">
									<div className="col-span-12 md:col-span-2">
										<label className="body-text--small gradient-color-txt">
											+5% Off All Merch
										</label>
									</div>
									<div className="col-span-12 md:col-span-7">
										{/* <p className="body-txtitalic--smallest m-0">
											Unlimited member bonus discount on all NGHTMRE Merch, exclusively at shop.nghtmre.com. Some exceptions may apply.
										</p> */}
									</div>
									<div className="col-span-12 md:col-span-3">
										<Link to="#" className="btn btn-secondary btn-md">
											<i className="fa-light fa-solar-system"></i>
											<span className="w-3/4">Access Benefit</span>
											<i className="fa-light fa-arrow-up-right-from-square"></i>
										</Link>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding mb-8">
									<div className="col-span-12 md:col-span-2">
										<label className="body-text--small gradient-color-txt">
											DRMVRSE Red Rocks Patch
										</label>
									</div>
									<div className="col-span-12 md:col-span-7">
										{/* <p className="body-txtitalic--smallest m-0">
											Get a limited edition patch from the NGHTMRE Red Rocks show at the Merch Booth at any future NGHTMRE show, while supplies last. (1 Redemption)
										</p> */}
									</div>
									<div className="col-span-12 md:col-span-3">
										<Link to="#" className="btn btn-secondary btn-md">
											<i className="fa-light fa-ticket"></i>
											<span className="w-3/4">Redeem Benefit</span>
											<i className="fa-light fa-arrow-up-right-from-square"></i>
										</Link>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 items-center pt-5 wrapper-lr-padding hidden">
									<Link onClick={()=>activation('inactive')} className="btn btn-secondary btn-md">
										{/*<i className="fa-light fa-solar-system"></i>*/}
										<span className="w-3/4">Deactivate Item</span>
									</Link>
								</div>
							</div>
						</>
					)}

					{ membership &&
						<ActivationMembershipModal
							modalOpen={isActivationOpen}
							setModalOpen={setActivationOpen}
							membership={membership}
							space={space}
							user={user}
							activationStatus={activationStatus}
						/>
					}

					<div className="dashboard-wrapper mt-4">
						<div className="grid grid-cols-12 gap-4 mt-4">
							<div className="col-span-12 md:col-span-4 inline-block">
								
								<h5 className="p-1 header-small">
									<i className="fa-light fa-solar-system"></i>{' '}
									{space.name} Collection
								</h5>
							</div>
							<div className='col-span-12 md:col-span-8'>
								
								<p className="body-text--small text-end">
									Items & Membership Keys you’ve collected
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-4 flex items-center pt-5">
							<div className="col-span-12 md:col-span-9  grid md:flex items-center justify-start  overflow-auto">
								{space.items && (
									<ul className="polygon-membership grid grid-flow-col auto-cols-min">
										{space.items.length > 0 &&
											space.items.slice(0, 5).map((item, index) => (
												<li key={index}>
													<Link to="#" className="hex">
														<img alt="member-thumb" className='object-cover' src={item.photo} />
													</Link>
												</li>
											))}
									</ul>
								)}
								{space.items && space.items.length > 10 ? (
									<Link to="#" className="gradient-color-txt body-text--smallest ms-4">
										<i>+{shortenNumber(space.items.length - 9)} more</i>
									</Link>
								) : space.items && space.items.length > 0 ?(
									<Link to="#" className="gradient-color-txt body-text--smallest ms-4">
										<i>{space.items.length} Items owned</i>
									</Link>
								) : (
									<></>
								)}
							</div>
							<div className="col-span-12 md:col-span-3" onClick={() => {goToSpaceCollections()}}>
								<a className={classNames("btn btn-secondary btn-md", space.items.length == 0 ? 'text-[#888888' : '')}>
									<span>View Items</span>
								</a>
							</div>
						</div>
						<div className="grid grid-cols-12 flex items-center pt-5 gap-4">
							<div className="col-span-12 md:col-span-9 flex items-center justify-start">
								{space.memberships && (
									<ul className="polygon-membership">
										{space.memberships.length > 0 &&
											space.memberships.slice(0, 10).map((key, index) => (
												<li key={index}>
													<Link to="#">
														<img
															className="img-thumb-square object-cover"
															alt="member-thumb"
															src={key.photo}
														/>
													</Link>
												</li>
											))}
									</ul>
								)}
								{space.memberships && space.memberships.length > 0 ? (
									<>
										{space.memberships.length > 10 ? (
											<>
												<Link to="#" className="gradient-color-txt body-text--smallest ms-4">
													+{shortenNumber(space.memberships.length - 5)} more Memberships Owned
												</Link>
											</>
										) : (
											<>
												<Link to="#" className="gradient-color-txt body-text--smallest ms-4">
													{shortenNumber(space.memberships.length)} Memberships Owned
												</Link>
											</>
										)}
									</>
								) : (
									<>
										<Link to="#" className="gradient-color-txt body-text--smallest ms-4">
											0 Keys Owned
										</Link>
									</>
								)}
							</div>
							<div className="col-span-12 md:col-span-3"  onClick={()=>handleClick()}>
								<a className="btn btn-secondary btn-md">View Membership</a>
							</div>
						</div>
					</div>

					<div className="dashboard-wrapper mt-4" id='your-memberships' ref={containerRef}>
						<div className="grid grid-cols-12 mt-4">
							<div className="col-span-12 md:col-span-4">
								<h5 className="p-1 header-small" >Your Memberships</h5>
							</div>
							<div className="col-span-12 md:col-span-8">
								<p className="body-text--small text-end">
									Manage and activate your Memberships
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 flex items-center pt-5 gap-4">
							<div className="col-span-12 md:col-span-9 flex items-center justify-start">
								{membership && (
									<>
										{membershipAssetType === 'video' ? (
											<>
												<img
													className="img-thumb-square me-4"
													alt="member-thumb"
													src={membership.photo}
												/>
											</>
										) : (
											<>
												<img
													className="img-thumb-square me-4"
													alt="member-thumb"
													src={membership.photo}
												/>
											</>
										)}
									</>
								)}
								{membership && (
									<div>
										<h6 className="header-title">{membership.name}</h6>
										<p className="body-text--smallest m-0">{membership.listing_text}</p>
										<p className="body-txtitalic--smallest m-0">
											<span className='capitalize '>{status}</span>
											{/* {isRecurring ? 'Active until ' + membership.expiration_on ? moment(membership.expiration_on).format('LL') : 'No Access Expiration'} */}
											{status === 'active' && isRecurring ? (
												<></>
											) : (
												<></>
											)}
										</p>
									</div>
								)}
							</div>
							<div className="col-span-12 md:col-span-3">
								<a className="btn btn-secondary btn-md">Manage Membership</a>
							</div>
						</div>
						{/*<div className="row items-center pt-5">*/}
						{/*	<div className="col-9 flex items-center justify-content-start">*/}
						{/*		<img*/}
						{/*			className="img-thumb-square me-4"*/}
						{/*			alt="member-thumb"*/}
						{/*			src={sample_thumb}*/}
						{/*		/>*/}
						{/*		<div>*/}
						{/*			<h6 className="header-title">DRMVRSE Membership 2023 </h6>*/}
						{/*			<p className="body-text--smallest m-0">DM23 Edition #1</p>*/}
						{/*			<p className="body-txtitalic--smallest m-0">*/}
						{/*				Issued January 2, 2023, No Access Expiration*/}
						{/*			</p>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div className="col-3">*/}
						{/*		<a className="btn btn-secondary btn-md">View Membership Key</a>*/}
						{/*	</div>*/}
						{/*</div>*/}
						{/*<div className="row items-center pt-5">*/}
						{/*	<div className="col-9 flex items-center justify-content-start">*/}
						{/*		<img*/}
						{/*			className="img-thumb-square me-4"*/}
						{/*			alt="member-thumb"*/}
						{/*			src={sample_thumb}*/}
						{/*		/>*/}
						{/*		<div>*/}
						{/*			<p className="body-txtitalic--smallest m-0">*/}
						{/*				1 other Membership Key owned*/}
						{/*			</p>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div className="col-3">*/}
						{/*		<a className="btn btn-secondary btn-md btn-warning">Change Membership</a>*/}
						{/*	</div>*/}
						{/*</div>*/}
					</div>
				</>
			)}
		</>
	);
};

export default memo(ManageSpaceMembership);
