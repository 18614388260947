import React from 'react';

import AppLayout from './containers/AppLayout';

// This is the main component of the application
const App = () => {
	// Render the AppLayout component, which contains the layout of the app
	return <AppLayout />;
};

export default App;