import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Pagination from 'react-bootstrap/Pagination';

import {
	MEMBERSHIP_FILTER,
	MEMBERSHIP_SORT,
	MEMBERSHIP_TYPES,
	UPDATES_VISIBILITY
} from '../../../constants/space';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import routes from '../../../routes';
import { getSpaceItems } from '../../../services/space';

const Items = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [selectedFilter, setSelectedFilter] = useState('default');
	const [selectedSort, setSelectedSort] = useState('default');
	const [filterArr, setFilterArr] = useState(null);
	const [sortArr, setSortArr] = useState(null);
	const [itemsFetching, setItemsFetching] = useState(false);
	const [itemsList, setItemsList] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [totalItemsCount, setTotalItemsCount] = useState(0);
	const [totalSoldItemsCount, setTotalSoldItemsCount] = useState(0);
	const [averageSoldPerMember, setAverageSoldPerMember] = useState(0);
	const [totalSoldItemsThisMonthCount, setTotalSoldItemsThisMonthCount] = useState(0);
	const [mostPopularItem, setMostPopularItem] = useState('SW001');
	const [totalActiveItemsValue, setTotalActiveItemsValue] = useState(0);
	const [averagePerMemberValue, setAveragePerMemberValue] = useState(0);
	const [salesThisMonthValue, setSalesThisMonthValue] = useState(0);
	const [recurringRevenueValue, setRecurringRevenueValue] = useState(0);

	const [itemsPagination, setItemsPagination] = useState([]);
	const [itemsCurrentPage, setItemsCurrentPage] = useState(1);
	const [clickedTableColumnOrder, setClickedTableColumnOrder] = useState(null);
	const [nameSortOrder, setNameSortOrder] = useState(null);
	const [priceSortOrder, setPriceSortOrder] = useState(null);
	const [benefitsSortOrder, setBenefitsSortOrder] = useState(null);
	const [typeSortOrder, setTypeSortOrder] = useState(null);
	const [searchInputTimer, setSearchInputTimer] = useState(null);
	const [searchText, setSearchText] = useState('');

	const [updatedItemsFilterList, setUpdatedItemsFilterList] = useState(
		JSON.parse(JSON.stringify(MEMBERSHIP_FILTER))
	);

	function changeDelay(change) {
		if (searchInputTimer) {
			clearTimeout(searchInputTimer);
			setSearchInputTimer(null);
		}
		setSearchInputTimer(
			setTimeout(() => {
				getItemsRequest().then((r) => {});
			}, 3000)
		);
	}

	const filterDropdownChange = useCallback(
		async (value) => {
			const priceTypes = ['free', 'paid', 'subscription'];

			const data = {
				filter_value: value
			};

			if (priceTypes.includes(value)) {
				data['filter_type'] = 'price';
			}
			if (UPDATES_VISIBILITY.findIndex((x) => x.value === value) >= 0) {
				data['filter_type'] = 'visibility';
			}

			setFilterArr(data);
			setSelectedFilter(value);
		},
		[authUserInfo, spaceInfo, itemsCurrentPage]
	);

	const sortDropdownChange = useCallback(
		async (value) => {
			setNameSortOrder(null);
			setPriceSortOrder(null);
			setBenefitsSortOrder(null);
			setTypeSortOrder(null);

			const data = {
				sort_by: value
			};

			setSortArr(data);
			setSelectedSort(value);
		},
		[authUserInfo, spaceInfo, itemsCurrentPage]
	);

	const handleTableSorts = useCallback(
		async (column) => {
			setClickedTableColumnOrder(column);

			if (column === 'name') {
				setPriceSortOrder(null);
				setBenefitsSortOrder(null);
				setTypeSortOrder(null);

				if (nameSortOrder === 'asc') {
					setNameSortOrder(null);
				} else if (nameSortOrder === 'desc') {
					setNameSortOrder('asc');
				} else {
					setNameSortOrder('desc');
				}
			}
			if (column === 'price') {
				setNameSortOrder(null);
				setBenefitsSortOrder(null);
				setTypeSortOrder(null);

				if (priceSortOrder === 'asc') {
					setPriceSortOrder(null);
				} else if (priceSortOrder === 'desc') {
					setPriceSortOrder('asc');
				} else {
					setPriceSortOrder('desc');
				}
			}
			if (column === 'benefits') {
				setNameSortOrder(null);
				setPriceSortOrder(null);
				setTypeSortOrder(null);

				if (benefitsSortOrder === 'asc') {
					setBenefitsSortOrder(null);
				} else if (benefitsSortOrder === 'desc') {
					setBenefitsSortOrder('asc');
				} else {
					setBenefitsSortOrder('desc');
				}
			}
			if (column === 'type') {
				setNameSortOrder(null);
				setPriceSortOrder(null);
				setBenefitsSortOrder(null);

				if (typeSortOrder === 'asc') {
					setTypeSortOrder(null);
				} else if (typeSortOrder === 'desc') {
					setTypeSortOrder('asc');
				} else {
					setTypeSortOrder('desc');
				}
			}
		},
		[nameSortOrder, priceSortOrder, benefitsSortOrder, typeSortOrder]
	);

	const handleOnClickPagination = useCallback(async (event, type) => {
		const itemClicked = event.target.text;
		if (itemClicked) {
			const number = parseInt(itemClicked);
			setItemsCurrentPage(number);
			await getItemsRequest();
		}
	}, []);

	const buildPagination = (current_page, max_page) => {
		const items = [];
		if (max_page) {
			for (let number = 1; number <= max_page; number++) {
				items.push(
					<Pagination.Item key={number} active={number === current_page}>
						{number}
					</Pagination.Item>
				);
			}
		}

		setItemsPagination(items);
	};

	const updateItemsFilterList = useCallback(
		async (group, data) => {
			let label = null;
			const list = updatedItemsFilterList;
			if (group === 'visibility') {
				label = 'By Visibility';
			}

			const index = list.findIndex((x) => x.label === label);
			if (index >= 0) {
				let arr = [];
				data.forEach((item) => {
					if (item.value !== 'default') {
						arr.push(item);
					}
				});
				list[index]['value'] = arr;
			}

			setUpdatedItemsFilterList(list);
		},
		[updatedItemsFilterList]
	);

	const getItemsRequest = useCallback(async () => {
		try {
			if (authUserInfo && spaceInfo) {
				const data = {
					viewing_user_id: authUserInfo.id,
					space_id: spaceInfo.id,
					list_count: 10,
					page: itemsCurrentPage
				};

				if (selectedFilter !== 'default') {
					data['filter_type'] = filterArr['filter_type'];
					data['filter_value'] = filterArr['filter_value'];
				}

				if (selectedSort !== 'default') {
					data['sort_by'] = sortArr['sort_by'];
				}

				if (searchText && searchText !== '') {
					data['search'] = searchText;
				}

				if (clickedTableColumnOrder === 'name') {
					if (nameSortOrder) {
						data['sort_by'] = 'name';
						data['sort_order'] = nameSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'price') {
					if (priceSortOrder) {
						data['sort_by'] = 'price';
						data['sort_order'] = priceSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'benefits') {
					if (benefitsSortOrder) {
						data['sort_by'] = 'benefits';
						data['sort_order'] = benefitsSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'type') {
					if (typeSortOrder) {
						data['sort_by'] = 'type';
						data['sort_order'] = typeSortOrder;
					}
				}

				setItemsFetching(true);

				const response = await getSpaceItems(data);
				if (response && response.result) {
					if (response.data) {
						setItemsList(response.data);
						setTotalItemsCount(response.total_items_count);
						buildPagination(parseInt(response.page), parseInt(response.page_range));
					} else {
						setItemsList(null);
					}
				}

				setItemsFetching(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [
		authUserInfo,
		spaceInfo,
		itemsCurrentPage,
		selectedFilter,
		selectedSort,
		filterArr,
		sortArr,
		clickedTableColumnOrder,
		nameSortOrder,
		priceSortOrder,
		benefitsSortOrder,
		typeSortOrder,
		searchText
	]);

	const redirectSelectItemCreateTypeLink = useCallback(() => {
		if (spaceInfo) {
			const route = routes.itemType();
			return route.replace(':space_username', '@' + spaceInfo.username);
		}
		return '';
	}, [spaceInfo]);

	const redirectToItemLink = useCallback(
		(route, item_id) => {
			if (spaceInfo) {
				let url = route.replace(':space_username', '@' + spaceInfo.username);
				return url.replace(':item_id', item_id);
			}
			return '';
		},
		[spaceInfo]
	);

	const ItemRow = (data) => {
		return (
			<div className='min-w-[768px]'>
				<div className="row-view-wrapper mt-4">
					<div className="grid grid-cols-12">
						<div className="col-span-3 flex items-center ">
							<img className="rounded-circle me-4" src={data.photo_thumb} />
							<label className="header-small">{data.name}</label>
						</div>
						<div className="col-span-2 flex items-center ">
							<i className="fa-light fa-calendar"></i>
							<label className="body-text--smallest ms-1">
								{data.monthly_price ? parseFloat(data.monthly_price) : 'Free'}
							</label>
						</div>
						<div className="col-span-2  flex items-center ">
							<i className="fa-light fa-gift"></i>
							<label className="body-text--smallest ms-1">
								{data.benefits_count}
							</label>
						</div>
						<div className="col-span-2  flex items-center  ">
							<i className="fa-light fa-hexagon-image"></i>
							<label className="body-text--smallest ms-1">
								{data.type === MEMBERSHIP_TYPES.NFT_COLLECTION
									? 'Collectible'
									: 'Community'}
							</label>
						</div>
						<div className="col-span-2 flex items-center">
							<Link className="btn btn-md btn-secondary" to="">
								<i className="fa-light fa-arrow-up-right-from-square"></i>
							</Link>
							<Link
								className="btn btn-md btn-secondary ms-4"
								to={redirectToItemLink(routes.manageItem(), data.id)}
							>
								<i className="fa-light fa-file-magnifying-glass"></i>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (!initialLoad) {
			getItemsRequest().then((r) => {});
		}

		if (authUserInfo && spaceInfoLoading && spaceInfoLoading === 'done') {
			if (initialLoad) {
				getItemsRequest().then((r) => {});
			}

			setInitialLoad(false);
		}

		updateItemsFilterList('visibility', UPDATES_VISIBILITY).then((r) => {});
	}, [
		authUserInfo,
		spaceInfoLoading,
		spaceInfo,
		itemsCurrentPage,
		selectedFilter,
		filterArr,
		sortArr,
		clickedTableColumnOrder,
		nameSortOrder,
		priceSortOrder,
		benefitsSortOrder,
		typeSortOrder,
		updatedItemsFilterList
	]);

	return (
		<>
			<div className="grid grid-cols-12 gap-2 md:gap-8">
				<div className="col-span-12 md:col-span-6">
					<div className="dashboard-wrapper">
						<h5 className="header-small text-center">{totalSoldItemsCount}</h5>
						<p className="body-text--smallest text-center">Item Sold Today</p>
						<div className="grid grid-cols-1 md:grid-cols-3 justify-evenly mt-5">
							<div className="text-center">
								<p className="body-text--smallest gradient-color-txt mb-2">
									{averageSoldPerMember}
								</p>
								<p className="body-text--tiny m-0">Average Per Member</p>
							</div>
							<div className="text-center">
								<p className="body-text--smallest gradient-color-txt mb-2">
									{totalSoldItemsThisMonthCount}
								</p>
								<p className="body-text--tiny m-0">Sold This Month</p>
							</div>
							<div className="text-center">
								<p className="body-text--smallest gradient-color-txt mb-2">
									{mostPopularItem}
								</p>
								<p className="body-text--tiny m-0">Most Popular</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-span-12 md:col-span-6">
					<div className="dashboard-wrapper">
						<h5 className="header-small text-center">${totalActiveItemsValue} USD</h5>
						<p className="body-text--smallest text-center">Recently Active</p>
						<div className="grid grid-cols-1 md:grid-cols-3 justify-evenly mt-5">
							<div className="text-center">
								<p className="body-text--smallest gradient-color-txt mb-2">
									${averagePerMemberValue}
								</p>
								<p className="body-text--tiny m-0">Average Per Member</p>
							</div>
							<div className="text-center">
								<p className="body-text--smallest gradient-color-txt mb-2">
									${salesThisMonthValue}
								</p>
								<p className="body-text--tiny m-0">Sales This Month</p>
							</div>
							<div className="text-center">
								<p className="body-text--smallest gradient-color-txt mb-2">
									${recurringRevenueValue}
								</p>
								<p className="body-text--tiny m-0">Recurring Revenue</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4">
				<div className="col-span-12">
					<div className="dashboard-wrapper">
						<div className="grid grid-cols-12 grid-rows-2 gap-[12px] sm:gap-[24px]">
							<div className="col-span-12 md:col-span-6 row-span-2 lg:row-span-1  flex items-center justify-between">
								<h3 className="header-medium">Manage Items</h3>
								<span className="body-txtitalic--reg color-light">
									{totalItemsCount} Total
								</span>
							</div>
							<div className="md:col-span-6 lg:col-span-3 col-span-12 md:col-span-3 gap-4">
								<div className="input-group-com input-with-btn">
									<input
										className="md color-light ps-4 body-txtitalic--smallest !pl-2	"
										type="text"
										placeholder="Enter Text"
										onChange={(e) => {
											setSearchText(e.target.value);
											changeDelay();
										}}
										value={searchText}
										onBlur={() => getItemsRequest()}
									/>
									<Button
										className={'btn btn-secondary btn-md w-50'}
										onClick={() => {
											clearTimeout(searchInputTimer);
											setSearchInputTimer(null);
											getItemsRequest().then((r) => {});
										}}
									>
										<i className="fa-light fa-magnifying-glass"></i>
									</Button>
								</div>
							</div>
							<div className="md:col-span-6 lg:col-span-3 col-span-12 md:col-span-3 gap-4">
								<Link
									className="btn btn-secondary btn-md"
									to={redirectSelectItemCreateTypeLink()}
								>
									Create New
								</Link>
							</div>
						</div>
						<div className="grid grid-cols-1 sm:grid-cols-2 mt-5 gap-[24px]">
							<div >
								<DropdownWithItems
									title="Items Filter"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary"
									icon="fa-light fa-filter"
									selectedValue={selectedFilter}
									listItems={updatedItemsFilterList}
									onDropdownChange={(value) => filterDropdownChange(value)}
								/>
							</div>
							<div >
								<DropdownWithItems
									title="Items Sort"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary"
									icon="fa-light fa-bars-sort"
									selectedValue={selectedSort}
									listItems={MEMBERSHIP_SORT}
									onDropdownChange={(value) => sortDropdownChange(value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row-view">
				<div className="row-view-wrapper mt-4 overflow-auto ">
				<div className=' min-w-[760px] w-full overflow-auto relative'>
					<div className="grid grid-cols-12  header-tiny table-header color-light flex items-center">
						<div className="col-span-3 pl-[30px]">
							<Link onClick={() => handleTableSorts('name')}>
								Name
								{nameSortOrder ? (
									<>
										{nameSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<div className="col-span-2">
							<Link onClick={() => handleTableSorts('price')}>
								Price
								{priceSortOrder ? (
									<>
										{priceSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<div className="col-span-2">
							<Link onClick={() => handleTableSorts('benefits')}>
								Benefits
								{benefitsSortOrder ? (
									<>
										{benefitsSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<div className="col-span-2">
							<Link onClick={() => handleTableSorts('type')}>
								Type
								{typeSortOrder ? (
									<>
										{typeSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<div className="col-span-2">Actions</div>
					</div>
				</div>
				{itemsList && (
					<>
						{itemsList.map((data, index) => (
							<div key={index}>{ItemRow(data)}</div>
						))}
					</>
				)}
				</div>
			</div>
			{itemsList &&  itemsList.length > 10 && (
			<div className="grid grid-cols-12 gap-8 mt-3 items-center">
				<Pagination size="sm" onClick={(event) => handleOnClickPagination(event)}>
					{itemsPagination}
				</Pagination>
			</div>
			)}
		</>
	);
};

export default memo(Items);
