import { memo, useEffect } from 'react';
import DashboardContent from './DashboardContent';

import './Dashboard.scss';
import {useSelector} from "react-redux";

const Dashboard = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);

	useEffect(() => {
		document.title = 'Commonspace Dashboard';
	}, []);

	return (
		<main id="dashboard" className="  flex ">
			<div className="container mx-auto min-h-screen md:min-h-[540px]">
				{authUserInfo && (
					<>
						<DashboardContent />
					</>
				)}
			</div>
		</main>
	);
};

export default memo(Dashboard);
