import { memo, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import routes from '../../../routes';
import ProfileContent from '../../Profile/ProfileContent';
import SettingsContent from '../../Settings/SettingsContent';
import Manage2FA from '../../Settings/SettingsContent/Manage2FA';
import ManageNotificationPreferences from '../../Settings/SettingsContent/ManageNotificationPreferences';
import ManagePaymentMethods from '../../Settings/SettingsContent/ManagePaymentMethods';
import ManageMagicWallet from '../../Settings/SettingsContent/ManageWallets';
import SpacesContent from '../../Spaces/SpacesContent';
import ManageSpaceMembership from '../../Spaces/SpacesContent/ManageSpaceMembership';
import CollectionsContent from '../../Collections/CollectionsContent';
import ViewCollection from '../../Collections/CollectionsContent/ViewCollection';
import ViewCollectionItem from '../../Collections/CollectionsContent/ViewItem';
import DashboardHome from '../../Dashboard/DashboardHome';

import default_space_image from '../../../assets/images/profile_spaces_img.png';
import sample_thumb from '../../../assets/images/sample_thumb.png';
import default_space_bg from '../../../assets/images/profile_spaces_bg.png';
import '../Dashboard.scss';
import ViewTransactions from '../../Settings/SettingsContent/ViewTransactions';
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";

const DashboardContent = () => {
	const { space_username } = useParams();
	const currentLocation = useLocation();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [isManageSpaceUrl, setIsManageSpaceUrl] = useState(false);
	const [isViewCollectionUrl, setIsViewCollectionUrl] = useState(false);
	const [isViewCollectionItemUrl, setIsViewCollectionItemUrl] = useState(false);
	const [membershipID, setMembershipID] = useState();
	const dashboardUrls = [routes.dashboard()];
	const spacesUrls = [routes.spaces(), routes.manageSpaceMembership(), routes.manageSpaceMembershipExpired()];
	const collectionsUrls = [routes.collections(), routes.viewCollection()];
	const profileUrls = [
		routes.profile(),
		routes.profileEdit(),
		routes.profileView(),
		routes.profileEditAboutSection(),
		routes.profileManageFollowers(),
		routes.profileManageContact(),
		routes.profileManageVisibility()
	];
	const settingsUrls = [
		routes.settings(),
		routes.settings2FA(),
		routes.settingsNotificationPreferences(),
		routes.settingsPaymentMethods(),
		routes.settingsMagicWallet(),
		routes.settingsTransactions()
	];
	const secondaryProfileUrls = [
		routes.profileEdit(),
		routes.profileView(),
		routes.profileEditAboutSection(),
		routes.profileManageFollowers(),
		routes.profileManageContact(),
		routes.profileManageVisibility()
	];
	const secondarySettingsUrls = [
		routes.settings2FA(),
		routes.settingsNotificationPreferences(),
		routes.settingsPaymentMethods(),
		routes.settingsMagicWallet(),
		routes.settingsTransactions()
	];
	const [selectedTab, setSelectedTab] = useState(routes.dashboard());

	const setActiveLink = (links, boolean = false) => {
		if (links.includes(location.pathname)) {
			if (boolean) {
				return true;
			}
			return 'active';
		}
		if (boolean) {
			return false;
		}
		return '';
	};

	useEffect(() => {
		setSelectedTab(currentLocation.pathname);

		const currentPath = currentLocation.pathname;
		// let membershipID = '';
		if (currentPath.includes('/manage/active/membership') || currentPath.includes('/manage/inactive/membership')) {
			const pathParts = currentPath.split('/');
			// membershipID = pathParts.pop();
			setMembershipID(pathParts.pop())
		}

		const manageSpaceRoute = routes.manageSpaceMembership();
		const finManageSpaceRoute = manageSpaceRoute.replace(':space_username', space_username)
			.replace(':membership_id', membershipID);

		const manageSpaceMembershipRoute = routes.manageSpaceMembershipExpired();
		const finManageSpaceMembershipRoute = manageSpaceMembershipRoute.replace(':space_username', space_username)
			.replace(':membership_id', membershipID);

		const collectionsViewRoute = routes.viewCollection();
		const finCollectionsViewRoute = collectionsViewRoute.replace(
			':space_username',
			space_username
		);

		const collectionsViewItemRoute = routes.viewCollectionItem();
		const finCollectionsViewItemRoute = collectionsViewItemRoute.replace(
			':space_username',
			space_username
		);

		setIsManageSpaceUrl(false);
		if (currentLocation.pathname === finManageSpaceRoute
			|| currentLocation.pathname === finManageSpaceMembershipRoute
		) {
			setIsManageSpaceUrl(true);
		}

		setIsViewCollectionUrl(false);
		if (currentLocation.pathname === finCollectionsViewRoute) {
			setIsViewCollectionUrl(true);
		}

		setIsViewCollectionItemUrl(false);
		if (currentLocation.pathname === finCollectionsViewItemRoute) {
			setIsViewCollectionItemUrl(true);
		}

	}, [currentLocation.pathname, space_username, authUserInfo, spaceInfo]);

	useEffect(() => {
		if (authUserInfo) {
			dispatch(getSpaceInfo(space_username, authUserInfo.id));
		}
	}, [space_username, authUserInfo])

	return (
		<>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb h-[72px] mt-8 mb-8 bock md:flex items-center ">
							<li className="breadcrumb-item">
								<Link to="#">
									{authUserInfo && authUserInfo.profile && (
										<>
											{authUserInfo.profile.username
												? '@' + authUserInfo.profile.username
												: authUserInfo.profile.first_name}
										</>
									)}
								</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								{setActiveLink(dashboardUrls, true) && (
									<Link to={routes.dashboard()}>Dashboard</Link>
								)}
								{(setActiveLink(spacesUrls, true) || isManageSpaceUrl) && (
									<Link to={routes.spaces()}>Spaces</Link>
								)}
								{(setActiveLink(collectionsUrls, true) ||
									isViewCollectionUrl ||
									isViewCollectionItemUrl) && (
									<Link to={routes.collections()}>Collections</Link>
								)}
								{setActiveLink(profileUrls, true) && (
									<Link to={routes.profile()}>Profile</Link>
								)}
								{setActiveLink(settingsUrls, true) && (
									<Link to={routes.settings()}>Account Settings</Link>
								)}
							</li>
							{setActiveLink(secondaryProfileUrls, true) && (
								<li className="breadcrumb-item active" aria-current="page">
									{selectedTab === routes.profileEdit() && (
										<Link to={routes.profileEdit()}>Edit</Link>
									)}
									{selectedTab === routes.profileView() && (
										<Link to={routes.profileView()}>View</Link>
									)}
									{selectedTab === routes.profileEditAboutSection() && (
										<Link to={routes.profileEditAboutSection()}>
											Edit About Section
										</Link>
									)}
									{selectedTab === routes.profileManageFollowers() && (
										<Link to={routes.profileManageFollowers()}>
											Manage Followers
										</Link>
									)}
									{selectedTab === routes.profileManageContact() && (
										<Link to={routes.profileManageContact()}>
											Manage Contact
										</Link>
									)}
									{selectedTab === routes.profileManageVisibility() && (
										<Link to={routes.profileManageVisibility()}>
											Manage Visibility
										</Link>
									)}
								</li>
							)}
							{setActiveLink(secondarySettingsUrls, true) && (
								<li className="breadcrumb-item active" aria-current="page">
									{selectedTab === routes.settings2FA() && (
										<Link to={routes.settings2FA()}>
											2 Factor Authentication
										</Link>
									)}
									{selectedTab === routes.settingsNotificationPreferences() && (
										<Link to={routes.settingsNotificationPreferences()}>
											Notification
										</Link>
									)}
									{selectedTab === routes.settingsPaymentMethods() && (
										<Link to={routes.settingsPaymentMethods()}>
											Payment Methods
										</Link>
									)}
									{selectedTab === routes.settingsMagicWallet() && (
										<Link to={routes.settingsMagicWallet()}>Magic Wallet</Link>
									)}
									{selectedTab === routes.settingsTransactions() && (
										<Link to={routes.settingsTransactions()}>Transactions</Link>
									)}
								</li>
							)}
							{isManageSpaceUrl && (
								<li className="breadcrumb-item active" aria-current="page">
									<Link to="#">{space_username}</Link>
								</li>
							)}
							{isViewCollectionUrl && (
								<li className="breadcrumb-item active" aria-current="page">
									<Link to="#">{space_username}</Link>
								</li>
							)}

							{isViewCollectionItemUrl && (
								<>
									<li className="breadcrumb-item" aria-current="page">
										<Link to="#">{space_username}</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										<Link to="#">Item Name Here</Link>
									</li>
								</>
							)}
						</ol>
					</nav>
			<div className="grid grid-flow-row grid-cols-12 lg:gap-8 mb-8">
				<div className="hidden col-span-3 min-[1160px]:col-span-2 lg:block">
					<ul className="menu min-w-[200px]">
						<li className='h-[40px] flex items-center'>
							<Link to={routes.dashboard()} className={setActiveLink(dashboardUrls)}>
								<i className="fa-regular fa-chart-tree-map"/><span>Dashboard</span>
							</Link>
						</li>
						<li  className='h-[40px] flex items-center'>
							<Link
								to={routes.spaces()}
								className={isManageSpaceUrl ? 'active' : setActiveLink(spacesUrls)}
							>
								<i className="fa-solid fa-solar-system"/><span>Spaces</span>
							</Link>
						</li>
						<li  className='h-[40px] flex items-center'>
							<Link
								to={routes.collections()}
								className={
									isViewCollectionUrl || isViewCollectionItemUrl
										? 'active'
										: setActiveLink(collectionsUrls)
								}
							>
								<i className="fa-regular fa-grid"/><span>Collections</span>
							</Link>
						</li>
						<li  className='h-[40px] flex items-center'>
							<Link to={routes.profile()} className={setActiveLink(profileUrls)}>
								<i className="fa-regular fa-id-badge"/><span>Profile</span>
							</Link>
						</li>
						<li className='h-[40px] flex items-center'>
							<Link to={routes.settings()} className={setActiveLink(settingsUrls)}>
								<i className="fa-regular fa-gear"/><span>Settings</span>
							</Link>
						</li>
					</ul>
				</div>
				<div className="col-span-full min-[960px]:col-span-9 min-[1160px]:col-span-10">
					{profileUrls.includes(location.pathname) && <ProfileContent />}
					{selectedTab === routes.settings() && <SettingsContent />}
					{selectedTab === routes.settings2FA() && <Manage2FA />}
					{selectedTab === routes.settingsNotificationPreferences() && (
						<ManageNotificationPreferences />
					)}
					{selectedTab === routes.settingsPaymentMethods() && <ManagePaymentMethods />}
					{selectedTab === routes.settingsMagicWallet() && <ManageMagicWallet />}
					{selectedTab === routes.settingsTransactions() && <ViewTransactions />}
					{selectedTab === routes.spaces() && <SpacesContent />}
					{isManageSpaceUrl && <ManageSpaceMembership
						user={authUserInfo}
						space={spaceInfo}
						membershipID={membershipID}/>}
					{selectedTab === routes.collections() && <CollectionsContent />}
					{isViewCollectionUrl && <ViewCollection />}
					{isViewCollectionItemUrl && <ViewCollectionItem />}
					{selectedTab === routes.dashboard() && <DashboardHome />}
				</div>
			</div>
		</>
	);
};

export default memo(DashboardContent);
