import React, {memo, useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import RenewPaymentMethod from "../RenewPaymentMethod";
import {Button} from "antd";
import {getRedemptionSubscription} from "../../../../../services/payment_method";
import routes from "../../../../../routes";

const MembershipBenefits = (parentsProps) => {
    const { user, space, membershipID, isRecurring, status, parentOpenState, parentSetOpenState, activation } = parentsProps;
    const [isSubmitted, setSubmitted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [redemptionCount, setRedemptionCount] = useState(0);
    const [isLoaded, setLoaded] = useState(false);

    const toggleSlide = () => {
        setIsOpen(!isOpen);
        parentSetOpenState(false);
    };
    

    const goToSpaceUpdate = useCallback(async () => {
		const url = routes.viewUserSpace() + space.username +'/update';
		window.location.replace(url);
	}, [space]);

    const getSubscriptionTransaction = useCallback(async () => {
        const data = {
            space_id: space.id,
            user_id: user.id,
            model: 'membership',
            model_id: membershipID,
        }
        const getSubsTransactionResponse = await getRedemptionSubscription(data);
        if (getSubsTransactionResponse && getSubsTransactionResponse.result) {
            if (getSubsTransactionResponse.subscription_redemption != null) {
                const count = getSubsTransactionResponse.subscription_redemption.count || 0;
                setRedemptionCount(count)
            }
        }
        setLoaded(true);
    },[membershipID, user, space])

    useEffect(()=>{
        if (parentOpenState) {
            setIsOpen(false);
        }
        if (!isLoaded) {
            getSubscriptionTransaction().then(r => {});
        }

    },[isSubmitted, parentOpenState, parentSetOpenState, isOpen, redemptionCount, isLoaded])

    return (
        <>
            <div className="dashboard-wrapper mt-4">
                <div className="grid grid-cols-12 gap-4 mt-4 wrapper-lr-padding">
                    <div className="col-span-12 md:col-span-4">
                        <h5 className="p-1 header-small">Membership Benefits</h5>
                    </div>
                    <div className="col-span-12 md:col-span-8">
                        <div className="body-text--small text-end">
                            { status === 'active' && !isRecurring ?
                                <>
                                    <div className="body-text--small text-end">
                                        Your Active Membership Benefits for&nbsp;
                                        <label className="body-text--small gradient-color-txt">
                                            <i className="fa-light fa-solar-system"/>&nbsp;
                                            {space.name}
                                        </label>
                                    </div>
                                </>
                                :
                                <>
                                    Access your Benefits in the {space.name} Space
                                </>
                            }

                        </div>
                    </div>
                </div>

                { status === 'active' && !isRecurring ? (
                   <>
                       <div className="grid grid-cols-12 gap-4 items-center pt-5 wrapper-lr-padding mb-8">
                           <div className="col-span-12 md:col-span-2">
                               <label className="body-text--small gradient-color-txt">
                                   Member Access
                               </label>
                           </div>
                           <div className="col-span-12 md:col-span-7">
                               <p className="body-txtitalic--smallest m-0">
                                   Exclusive Members Only Updates on announcements, BTS, and more!
                               </p>
                           </div>
                           <div className="col-span-12 md:col-span-3">
                               <Link to="#" onClick={() => {goToSpaceUpdate()}} className="btn btn-secondary btn-md">
                                   <i className="fa-light fa-solar-system"></i>
                                   <span className="w-3/4">View Updates</span>
                                   <i className="fa-light fa-arrow-up-right-from-square"></i>
                               </Link>
                           </div>
                       </div>

                       <div className="grid grid-cols-12 gap-4 items-center pt-5 wrapper-lr-padding mb-8">
                           <div className="col-span-12 md:col-span-2">
                               <label className="body-text--small gradient-color-txt">
                                   Pre-Sale Ticket Access
                               </label>
                           </div>
                           <div className="col-span-12 md:col-span-7">
                               {/* <p className="body-txtitalic--smallest m-0">
                                   Get access to tickets before they are available to the public with Members Only pricing.
                               </p> */}
                           </div>
                           <div className="col-span-12 md:col-span-3">
                               <Link to="#" className="btn btn-secondary btn-md">
                                   <i className="fa-light fa-solar-system"></i>
                                   <span className="w-3/4">Access Benefit</span>
                                   <i className="fa-light fa-arrow-up-right-from-square"></i>
                               </Link>
                           </div>
                       </div>

                       <div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding mb-8">
                           <div className="col-span-12 md:col-span-2">
                               <label className="body-text--small gradient-color-txt">
                                   20% Off All Merch
                               </label>
                           </div>
                           <div className="col-span-12 md:col-span-7">
                               {/* <p className="body-txtitalic--smallest m-0">
                                   Unlimited member discount on all NGHTMRE Merch, exclusively at shop.nghtmre.com. Some exceptions may apply.
                               </p> */}
                           </div>
                           <div className="col-span-12 md:col-span-3">
                               <Link to="#" className="btn btn-secondary btn-md">
                                   <i className="fa-light fa-solar-system"></i>
                                   <span className="w-3/4">Access Benefit</span>
                                   <i className="fa-light fa-arrow-up-right-from-square"></i>
                               </Link>
                           </div>
                       </div>

                       <div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding mb-8">
                           <div className="col-span-12 md:col-span-2">
                               <label className="body-text--small gradient-color-txt">
                                   Monthly Livestream Set
                               </label>
                           </div>
                           <div className="col-span-12 md:col-span-7">
                               {/* <p className="body-txtitalic--smallest m-0">
                                   Party live with NGHTMRE each month on a Members Only livestream set with special guests.
                               </p> */}
                           </div>
                           <div className="col-span-12 md:col-span-3">
                               <Link to="#" className="btn btn-secondary btn-md">
                                   <i className="fa-brands fa-chromecast"></i>
                                   <span className="w-3/4">Access Streams</span>
                                   <i className="fa-light fa-arrow-up-right-from-square"></i>
                               </Link>
                           </div>
                       </div>

                       <div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding mb-8">
                           <div className="col-span-12 md:col-span-2">
                               <label className="body-text--small gradient-color-txt">
                                   Exclusive Discord Privileges
                               </label>
                           </div>
                           <div className="col-span-12 md:col-span-7">
                               {/* <p className="body-txtitalic--smallest m-0">
                                   Join the NGHTMRE Discord and get special access to Members Only channels, giveaways, and more.
                               </p> */}
                           </div>
                           <div className="col-span-12 md:col-span-3">
                               <Link to="#" className="btn btn-secondary btn-md">
                                   <i className="fa-brands fa-discord"></i>
                                   <span className="w-3/4">Access Discord</span>
                                   <i className="fa-light fa-arrow-up-right-from-square"></i>
                               </Link>
                           </div>
                       </div>

                       <div className="grid grid-cols-12 gap-4 items-center pt-5 wrapper-lr-padding mb-8">
                           <div className="col-span-12 md:col-span-2">
                               <label className="body-text--small gradient-color-txt">
                                   Lossless NGHTMRE Tracks
                               </label>
                           </div>
                           <div className="col-span-12 md:col-span-7">
                               {/* <p className="body-txtitalic--smallest m-0">
                                   Download 12 exclusive NGHTMRE tracks of your choice in high fidelity, lossless formats. (12 of 12 remaining)
                               </p> */}
                           </div>
                           <div className="col-span-12 md:col-span-3">
                               <Link to="#" className="btn btn-secondary btn-md">
                                   <i className="fa-light fa-ticket"></i>
                                   <span className="w-3/4">Redeem Benefit</span>
                                   <i className="fa-light fa-arrow-up-right-from-square"></i>
                               </Link>
                           </div>
                       </div>

                       <div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding mb-8">
                           <div className="col-span-12 md:col-span-2">
                               <label className="body-text--small gradient-color-txt">
                                   DRMVRSE Partner Perks
                               </label>
                           </div>
                           <div className="col-span-12 md:col-span-7">
                               {/* <p className="body-txtitalic--smallest m-0">
                                   Get ongoing and limited time perks from DRMVRSE partners, collabs, and more.
                               </p> */}
                           </div>
                           <div className="col-span-12 md:col-span-3">
                               <Link to="#" className="btn btn-secondary btn-md">
                                   <i className="fa-light fa-solar-system"></i>
                                   <span className="w-3/4">Access Benefit</span>
                                   <i className="fa-light fa-arrow-up-right-from-square"></i>
                               </Link>
                           </div>
                       </div>


                       <div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding hidden mb-8">
                           <Link to="#" className="btn btn-secondary btn-md">
                               {/*<i className="fa-light fa-solar-system"></i>*/}
                               <span className="w-3/4">Show Unlockable Benefits</span>
                           </Link>
                       </div>
                   </>
                ) : (
                    <>
                        <div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding mb-8">
                            <div className="col-span-12 md:col-span-2">
                                <label className="body-text--small gradient-color-txt">
                                    Monthly Access
                                </label>
                            </div>
                            <div className="col-span-12 md:col-span-7">
                                {/* <p className="body-txtitalic--smallest m-0">
                                    The Chef’s Premium Membership is a paid monthly membership. ({redemptionCount} Months Remaining)
                                </p> */}
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                { status === 'active' &&
                                    <Link onClick={toggleSlide} className={`btn btn-secondary ${isOpen?'focus':''} btn-md `}>
                                        <span className="w-full">Manage Renewal</span>
                                    </Link>
                                }
                                { status === 'expired' &&
                                    <Link onClick={toggleSlide} className={`btn btn-secondary ${isOpen?'focus':''} btn-md `}>
                                        <span className="w-full">Renew Membership</span>
                                    </Link>
                                }
                                { status === 'inactive' &&
                                    <Button onClick={()=>activation('active')}
                                            disabled={
                                                isSubmitted
                                            }
                                            className={`btn btn-secondary ${isOpen?'focus':''} btn-md `}>

                                        {isSubmitted && (
                                            <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                        )}
                                        <span className="w-full">Activate Membership</span>
                                    </Button>
                                }
                            </div>
                        </div>

                        <div className={`grid grid-cols-12 items-center ${isOpen ? 'slide-margin-top' : 'remove-slide-margin-top' }`}>
                            <div className={`slide-down ${isOpen ? 'open' : ''}`}>
                                <RenewPaymentMethod user={user} space={space} membershipID={membershipID} isMembershipBenefit={true} status={status}/>
                            </div>
                        </div>


                        <div className="grid grid-cols-12 gap-4 flex items-center pt-5 wrapper-lr-padding mb-8">
                            <div className="col-span-12 md:col-span-2">
                                <label className="body-text--small gradient-color-txt">
                                    Exclusive Recipes
                                </label>
                            </div>
                            <div className="col-span-12 md:col-span-7">
                                {/* <p className="body-txtitalic--smallest m-0">
                                    Exclusive Recipes from our Executive Chef, straight from our kitchen to yours. Each includes June Oven Presets via WiFi.
                                </p> */}
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                <Link to={routes.viewUserSpace()} className="btn btn-secondary btn-md">
                                    <i className="fa-light fa-solar-system"></i>
                                    <span className="w-3/4">View Updates</span>
                                    <i className="fa-light fa-arrow-up-right-from-square"></i>
                                </Link>
                            </div>
                        </div>


                        <div className="grid grid-cols-12 items-center pt-5 wrapper-lr-padding gap-4">
                            <div className="col-span-12 md:col-span-2">
                                <label className="body-text--small gradient-color-txt">
                                    30% Off June Accessories
                                </label>
                            </div>
                            <div className="col-span-12 md:col-span-7">
                                {/* <p className="body-txtitalic--smallest m-0">
                                    Unlimited use discount on all June Oven accessories at juneoven.com. Some exceptions may apply.
                                </p> */}
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                <Link to="#" className="btn btn-secondary btn-md">
                                    <i className="fa-light fa-solar-system"></i>
                                    <span className="w-3/4">Access Benefit</span>
                                    <i className="fa-light fa-arrow-up-right-from-square"></i>
                                </Link>
                            </div>
                        </div>
                    </>
                )}



                {/*<div className="row items-center pt-5">*/}
                {/*	<div className="col-2">*/}
                {/*		<label className="body-text--small gradient-color-txt">*/}
                {/*			Exclusive Updates*/}
                {/*		</label>*/}
                {/*	</div>*/}
                {/*	<div className="col-7">*/}
                {/*		<p className="body-txtitalic--smallest m-0">*/}
                {/*			Exclusive Members Only Updates on announcements, BTS, and more!*/}
                {/*		</p>*/}
                {/*	</div>*/}
                {/*	<div className="col-3">*/}
                {/*		<Link to="#" className="btn btn-secondary btn-md">*/}
                {/*			<i className="fa-light fa-solar-system"></i>*/}
                {/*			<span className="w-3/4">View Updates</span>*/}
                {/*			<i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*		</Link>*/}
                {/*	</div>*/}
                {/*</div>*/}
                {/*<div className="row items-center pt-5">*/}
                {/*	<div className="col-2">*/}
                {/*		<label className="body-text--small gradient-color-txt">*/}
                {/*			Pre-Sale Ticket Access*/}
                {/*		</label>*/}
                {/*	</div>*/}
                {/*	<div className="col-7">*/}
                {/*		<p className="body-txtitalic--smallest m-0">*/}
                {/*			Get access to tickets before they are available to the public with*/}
                {/*			Members Only pricing.*/}
                {/*		</p>*/}
                {/*	</div>*/}
                {/*	<div className="col-3">*/}
                {/*		<Link to="#" className="btn btn-secondary btn-md">*/}
                {/*			<i className="fa-light fa-solar-system"></i>*/}
                {/*			<span className="w-3/4">Access Benefit</span>*/}
                {/*			<i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*		</Link>*/}
                {/*	</div>*/}
                {/*</div>*/}
                {/*<div className="row items-center pt-5">*/}
                {/*	<div className="col-2">*/}
                {/*		<label className="body-text--small gradient-color-txt">*/}
                {/*			20% Off All Merch*/}
                {/*		</label>*/}
                {/*	</div>*/}
                {/*	<div className="col-7">*/}
                {/*		<p className="body-txtitalic--smallest m-0">*/}
                {/*			Unlimited member discount on all NGHTMRE Merch, exclusively at*/}
                {/*			shop.nghtmre.com. Some exceptions may apply.*/}
                {/*		</p>*/}
                {/*	</div>*/}
                {/*	<div className="col-3">*/}
                {/*		<Link to="#" className="btn btn-secondary btn-md">*/}
                {/*			<i className="fa-light fa-solar-system"></i>*/}
                {/*			<span className="w-3/4">Access Benefit</span>*/}
                {/*			<i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*		</Link>*/}
                {/*	</div>*/}
                {/*</div>*/}
                {/*<div className="row items-center pt-5">*/}
                {/*	<div className="col-2">*/}
                {/*		<label className="body-text--small gradient-color-txt">*/}
                {/*			Monthly Livestream Set*/}
                {/*		</label>*/}
                {/*	</div>*/}
                {/*	<div className="col-7">*/}
                {/*		<p className="body-txtitalic--smallest m-0">*/}
                {/*			Party live with NGHTMRE each month on a Members Only livestream set with*/}
                {/*			special guests.*/}
                {/*		</p>*/}
                {/*	</div>*/}
                {/*	<div className="col-3">*/}
                {/*		<Link to="#" className="btn btn-secondary btn-md">*/}
                {/*			<i className="fa-light fa-screencast"></i>*/}
                {/*			<span className="w-3/4">Access Streams</span>*/}
                {/*			<i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*		</Link>*/}
                {/*	</div>*/}
                {/*</div>*/}
                {/*<div className="row items-center pt-5">*/}
                {/*	<div className="col-2">*/}
                {/*		<label className="body-text--small gradient-color-txt">*/}
                {/*			Exclusive Discord Privileges*/}
                {/*		</label>*/}
                {/*	</div>*/}
                {/*	<div className="col-7">*/}
                {/*		<p className="body-txtitalic--smallest m-0">*/}
                {/*			Join the NGHTMRE Discord and get special access to Members Only*/}
                {/*			channels, giveaways, and more.*/}
                {/*		</p>*/}
                {/*	</div>*/}
                {/*	<div className="col-3">*/}
                {/*		<Link to="#" className="btn btn-secondary btn-md">*/}
                {/*			<i className="fa-brands fa-discord"></i>*/}
                {/*			<span className="w-3/4">Access Discord</span>*/}
                {/*			<i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*		</Link>*/}
                {/*	</div>*/}
                {/*</div>*/}
                {/*<div className="row items-center pt-5">*/}
                {/*	<div className="col-2">*/}
                {/*		<label className="body-text--small gradient-color-txt">*/}
                {/*			Lossless NGHTMRE Tracks*/}
                {/*		</label>*/}
                {/*	</div>*/}
                {/*	<div className="col-7">*/}
                {/*		<p className="body-txtitalic--smallest m-0">*/}
                {/*			Download 12 exclusive NGHTMRE tracks of your choice in high fidelity,*/}
                {/*			lossless formats. (12 of 12 remaining)*/}
                {/*		</p>*/}
                {/*	</div>*/}
                {/*	<div className="col-3">*/}
                {/*		<Link to="#" className="btn btn-secondary btn-md">*/}
                {/*			<i className="fa-light fa-ticket"></i>*/}
                {/*			<span className="w-3/4">Redeem Benefit</span>*/}
                {/*			<i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*		</Link>*/}
                {/*	</div>*/}
                {/*</div>*/}
                {/*<div className="row items-center pt-5">*/}
                {/*	<div className="col-2">*/}
                {/*		<label className="body-text--small gradient-color-txt">*/}
                {/*			DRMVRSE Partner Perks*/}
                {/*		</label>*/}
                {/*	</div>*/}
                {/*	<div className="col-7">*/}
                {/*		<p className="body-txtitalic--smallest m-0">*/}
                {/*			Get ongoing and limited time perks from DRMVRSE partners, collabs, and*/}
                {/*			more.*/}
                {/*		</p>*/}
                {/*	</div>*/}
                {/*	<div className="col-3">*/}
                {/*		<Link to="#" className="btn btn-secondary btn-md">*/}
                {/*			<i className="fa-light fa-solar-system"></i>*/}
                {/*			<span className="w-3/4">Access Benefit</span>*/}
                {/*			<i className="fa-light fa-arrow-up-right-from-square"></i>*/}
                {/*		</Link>*/}
                {/*	</div>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default memo(MembershipBenefits);