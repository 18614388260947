import { memo, useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';

import WalletConnectedSetAccount from './RegisterContent/WalletConnectedSetAccount';
import EmailConfirmedAddAccountDetails from './RegisterContent/EmailConfirmedAddAccountDetails';

import { MAGIC_CONNECT_KEY, MAGIC_CONNECT_NETWORK } from '../../constants/common';
import { registerMagic } from '../../services/user';
import {getSpaceItemData, getSpaceMembership} from '../../services/space';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import { getAuthUserInfo } from '../../states/general/actions';
import { setAccessToken } from '../../utils/common';
import routes from '../../routes';

import './Register.scss';

import default_items_img from '../../assets/images/dj_party.png';
import {getSitewideLink} from "../../utils/sitewideLinks";

const RegisterForm = (parentProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const {
		emailVerificationToken,
		verified,
		email,
		verificationMessage,
		verifying,
		verifyEmailSpaceUsername,
		verifyEmailSpaceMembershipId,
		verifyEmailSpaceItemId
	} = parentProps.props;
	const [isMagicLink, setIsMagicLink] = useState(false);
	const [alert, setAlert] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [proceedDisplay, setProceedDisplay] = useState(false);
	const [membershipDetails, setMembershipDetails] = useState(null);
	const [itemDetails, setItemDetails] = useState(null);
	const { size } = useSelector((state) => state.classSize);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const web3 = new Web3(magic.rpcProvider);

	const registerMagicUser = async (values) => {
		if (authUserInfo) {
			values.email = authUserInfo.email;
		}

		const result = await registerMagic(values);

		if (result) {
			if (result.result) {
				dispatch(getAuthUserInfo());

				if (location.pathname === routes.connectWallet()) {
					navigate(routes.dashboard());
					return;
				}
				if (result.access_token) {
					setAccessToken(result);
				}

				form.resetFields();
				setAlert({
					variant: 'success',
					message:
						'Registered successfully! Please complete your details on the next page.'
				});
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong'
					});
				}
			}
		}
	};

	const getWalletInfo = (values) => {
		magic.connect.getWalletInfo().then((wallet_info) => {
			values.type = wallet_info.walletType;
			registerMagicUser(values);
		});
	};

	const getUserInfo = (values) => {
		magic.connect.requestUserInfo({ isResponseRequired: true }).then((user_account) => {
			values.wallet_email = user_account.email;
			getWalletInfo(values);
		});
	};

	// Web 3 Login
	const web3Login = async () => {
		web3.eth
			.getAccounts()
			.then((accounts) => {
				let values = {
					user_public_address: accounts?.[0]
				};
				getUserInfo(values);
			})
			.catch((error) => {
				setSubmitted(false);
				setIsMagicLink(false);
				// console.log(error);
			});
	};

	const magicLogin = async () => {
		setSubmitted(true);
		setIsMagicLink(true);
		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});
		await web3Login();
	};

	const skipForNow = () => {
		navigate(routes.dashboard());
	};

	const getMembership = useCallback(async (space_id, membership_id) => {
		const response = await getSpaceMembership({
			space_id: space_id,
			membership_id: membership_id
		});
		if (response && response.result) {
			const membershipData = response.membership;
			setMembershipDetails(membershipData);
		}
	}, []);

	const getItem = useCallback(async (space_id, item_id) => {
		const response = await getSpaceItemData({
			space_id: space_id,
			item_id: item_id
		});
		if (response && response.result) {
			const itemData = response.item;
			setItemDetails(itemData);
		}
	}, []);

	useEffect(() => {
		if (verifyEmailSpaceUsername) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					if (verifyEmailSpaceMembershipId) {
						getMembership(spaceInfo.id, verifyEmailSpaceMembershipId).then((r) => {});
					}
					if (verifyEmailSpaceItemId) {
						getItem(spaceInfo.id, verifyEmailSpaceItemId).then((r) => {});
					}
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(verifyEmailSpaceUsername));
				}
			}
		}

		if (loading && loading === 'done') {
			if (authUserInfo) {
				if (location.pathname === routes.walletSetAccount() && authUserInfo.email) {
					form.setFieldsValue({
						email: authUserInfo.email
					});
				} else {
					if (
						location.pathname !== routes.connectWallet() &&
						location.pathname !== routes.emailVerificationWalletSetPassword() &&
						(!verifyEmailSpaceUsername || (!verifyEmailSpaceMembershipId && !verifyEmailSpaceItemId))
					) {
						if (
							authUserInfo.profile &&
							!authUserInfo.profile.username &&
							!authUserInfo.profile.display_name &&
							!authUserInfo.profile.city
						) {
							window.location.replace(routes.registerMemberProfile());
						}
					}
				}
				setProceedDisplay(true);
			} else {
				if (location.pathname === routes.connectWallet()) {
					navigate(routes.login());
				}
			}
		}
	}, [loading, authUserInfo, spaceInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId]);

	return (
		<main id="sign-up" className=" flex !z-0  min-h-[86vh]">
			<div className="page-left-image col-span-6 hidden lg:block h-full">
				{membershipDetails && membershipDetails.video === 'video' ? (
					<video autoPlay muted loop className="membership-video">
						<source src={membershipDetails.video} type="video/mp4" />
					</video>
				) : (
					<img
						className="w-full object-cover"
						src={membershipDetails ? membershipDetails.photo : default_items_img}
					/>
				)}
			</div>
			<div className="container col-span-12 lg:col-span-6 pb-40 py-20 min-h-[560px]">
				<div className="grid grid-cols-12 justify-start flex">
					<div className="col-span-12 lg:col-span-6">
						{proceedDisplay &&
						authUserInfo &&
						location.pathname !== routes.emailVerificationWalletSetPassword() &&
						(!verifyEmailSpaceUsername || (!verifyEmailSpaceMembershipId && !verifyEmailSpaceItemId)) ? (
							<>
								{location.pathname === routes.walletSetAccount() ? (
									<WalletConnectedSetAccount authUserInfo={authUserInfo} />
								) : (
									<>
										<h1 className="mb-8 header-large">Connect a Wallet</h1>
										<h5 className="mb-8">
											If you have an ethereum-based wallet such as MetaMask or
											Coinbase Wallet, you can connect it here.
										</h5>
										<Button
											className={'btn  border-filled mb-8 btn-' + size}
											onClick={magicLogin}
											htmlType="submit"
											disabled={submitted}
										>
											<i
												className={
													isMagicLink
														? 'fa-solid fa-spinner fa-spin mr-[15px]'
														: 'fa-solid fa-wallet mr-[15px]'
												}
											></i>{' '}
											Connect Existing Wallet
										</Button>
										<Button
											className={'btn border-filled mb-8 btn-' + size}
											onClick={magicLogin}
											htmlType="submit"
											disabled={submitted}
										>
											<i
												className={
													isMagicLink
														? 'fa-regular fa-spinner fa-spin mr-[15px]'
														: 'fa-regular fa-wallet mr-[15px]'
												}
											></i>{' '}
											Create with Email
										</Button>
										<Button
											className={'btn btn-secondary  px-[2px] py-[2px]  mb-8 btn-' + size}
											onClick={skipForNow}
											htmlType="submit"
											disabled={submitted}
										>
											<span >Skip</span>
										</Button>
									</>
								)}
							</>
						) : (
							<>
								{emailVerificationToken && (
									<>
										{verifying && (
											<>
												<h1 className="mb-4 mt-5">Verifying</h1>
												<h5>Please wait...</h5>
											</>
										)}
										{!verifying && !verified && (
											<>
												<h1 className="mb-4 mt-5">Oops!</h1>
												<h5>{verificationMessage}</h5>
											</>
										)}
										{!verifying && verified && (
											<EmailConfirmedAddAccountDetails
												email={email}
												authUserInfo={authUserInfo}
												verifyEmailSpaceUsername={verifyEmailSpaceUsername}
												verifyEmailSpaceMembershipId={
													verifyEmailSpaceMembershipId
												}
												verifyEmailSpaceItemId={
													verifyEmailSpaceItemId
												}
											/>
										)}
									</>
								)}
							</>
						)}

						{authUserInfo ? (
							<h5 className=" fst-italic">
								Not sure what this is?
								<Link to="#" className="gradient-color-txt ms-1">
									Learn more.
								</Link>
							</h5>
						) : (
							<>
								{emailVerificationToken && (
									<>
										{location.pathname ===
											routes.emailVerificationWalletSetPassword() ||
										(verifyEmailSpaceUsername &&
											verifyEmailSpaceMembershipId) ? (
											<>
												<h5 className="mt-5 fst-italic">
													Need help?
													{/*<Link*/}
													{/*	to="#"*/}
													{/*	className="gradient-color-txt ms-1"*/}
													{/*>*/}
													{/*	Get Support.*/}
													{/*</Link>*/}
													{getSitewideLink('get_support', 'gradient-color-txt ms-1')}.
												</h5>
											</>
										) : (
											
											<>
											{verifying && !verified && (
												<h5 className="mt-5 fst-italic">
													Already have an account?
													<Link
														to={routes.login()}
														className="gradient-color-txt ms-1"
													>
														Login here.
													</Link>
												</h5>
											)}
											</>
										)}
									</>
								)}
							</>
						)}
					</div>
					<div className="col-span-12 lg:col-span-6  hidden lg:block">
						<div className="left-image-holder"></div>
					</div>
				</div>
			</div>
		</main>
	);
};

const Register = (parentProps) => {
	return <RegisterForm props={parentProps} />;
};

export default memo(Register);
