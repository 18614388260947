import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Form, Input } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { VALIDATE_MESSAGES } from '../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import {
	SPACE_PRIMARY_LANG,
	SPACE_PRIMARY_CURRENCY,
	SPACE_BUSINESS_INDUSTRY,
	SPACE_BUSINESS_TYPE,
	SPACE_BUSINESS_SIZE
} from '../../../constants/space';
import DropdownWithCountries from '../../../components/commons/DropdownWithCountryRegion/DropdownWithCountries';
import DropdownWithRegions from '../../../components/commons/DropdownWithCountryRegion/DropdownWithRegions';
import {
	createSpaceAddDetails, getSpaceBusinessInformation,
	setSpaceBusinessInformation,
	setSpaceInformation
} from '../../../services/space';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import FormFieldStacked from '../../../components/commons/FormFieldStacked';

const AddBusinessDetails = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [businessInformation, setBusinessInformation] = useState();
	const [initialLoad, setInitialLoad] = useState(true);
	const [addSpaceBusinessDetailsForm] = Form.useForm();
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [selectedPrimaryLang, setSelectedPrimaryLang] = useState('en');
	const [selectedPrimaryCurrency, setSelectedPrimaryCurrency] = useState('usd');
	const [selectedIndustry, setSelectedIndustry] = useState('default');
	const [selectedType, setSelectedType] = useState('default');
	const [selectedSize, setSelectedSize] = useState('default');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [verifyAuthorizedChecked, setVerifyAuthorizedChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [alert, setAlert] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [isBusinessInfoLoaded, setBusinessInfoLoaded] = useState(false);
	const [isLoadOnlyOnce, setLoadOnlyOnce] = useState(false);
	const [isDataLoaded, setDataLoaded] = useState(false);

	const [legalName, setLegalName] = useState('');
	const [streetAddress, setStreetAddress] = useState('');
	const [aptSteBldg,setAptSteBldg] = useState('');
	const [city,setCity] = useState('');
	const [zipCode,setZipCode] = useState('');
	const [contactPhone,setContactPhone] = useState('');
	const [authorized,setAuthorized] = useState('');
	const [contactEmail,setContactEmail] = useState('');
	const [primaryCity,setPrimaryCity] = useState('');

	console.log(selectedPrimaryLang)
	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);

			const data = values;
			if (authUserInfo && spaceInfo) {
				try {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					data.legal_name = addSpaceBusinessDetailsForm.getFieldValue('legal_name');
					data.country = selectedCountry;
					data.street_address =
						addSpaceBusinessDetailsForm.getFieldValue('street_address');
					data.apt_ste_bldg = addSpaceBusinessDetailsForm.getFieldValue('apt_ste_bldg');
					data.city = addSpaceBusinessDetailsForm.getFieldValue('city');
					data.state = selectedRegion;
					data.zip_code = addSpaceBusinessDetailsForm.getFieldValue('zip_code');
					data.contact_phone = addSpaceBusinessDetailsForm.getFieldValue('contact_phone');
					data.contact_email = addSpaceBusinessDetailsForm.getFieldValue('contact_email');
					data.primary_city = addSpaceBusinessDetailsForm.getFieldValue('primary_city');
					data.primary_language = selectedPrimaryLang;
					data.primary_currency = selectedPrimaryCurrency;

					data.industry = selectedIndustry;
					data.type = selectedType;
					data.size = selectedSize;
					data.is_authorized = verifyAuthorizedChecked;

					if (selectedRegion === 'default' || selectedRegion === ''){
						setFormIsOnFocus(true);
						handleFormOnBlur();
						return
					}

					if (selectedIndustry === 'default' || selectedIndustry === ''){
						setFormIsOnFocus(true);
						handleFormOnBlur();
						return
					}

					if (selectedSize === 'default' || selectedSize === ''){
						setFormIsOnFocus(true);
						handleFormOnBlur();
						return
					}

					if (verifyAuthorizedChecked === 'default' || verifyAuthorizedChecked === ''){
						setFormIsOnFocus(true);
						handleFormOnBlur();
						return
					}

					setIsLoading(true);

					const response = await setSpaceBusinessInformation(data);
					if (response && response.result) {
						setIsLoading(false);
						//move to next page after saving
						const url = routes.createSpaceAddPages();
						navigate(url.replace(':space_username', space_username));
					} else {
						setIsLoading(false);
						setAlert({
							variant: 'danger',
							message: response.message
						});
						window.scrollTo(0, 0);
					}
				} catch (error) {
					// setIsLoading(false);
					setIsLoading(false);
					console.log(error);
				}
			} else {
				setIsLoading(false);
			}
		},
		[
			selectedCountry,
			selectedRegion,
			selectedPrimaryLang,
			selectedPrimaryCurrency,
			selectedIndustry,
			selectedType,
			selectedSize,
			verifyAuthorizedChecked,
			inputValue
		]
	);

	const fetchBusinessInfo = useCallback(
		async (values) => {
			setAlert(null);
			if (authUserInfo && spaceInfo) {
				try {

					const data = {
						// user_id: authUserInfo.id,
						space_id: spaceInfo.id
					};
					const response = await getSpaceBusinessInformation(data);

					if (response && response.result) {
						setLoadOnlyOnce(true);
						setDataLoaded(true);
						const businessInformation = response.business_info;

						setLegalName(businessInformation.legal_name);
						setSelectedCountry(businessInformation.country);
						setStreetAddress(businessInformation.street_address);
						setAptSteBldg(businessInformation.apt_ste_bldg);
						setCity(businessInformation.city);
						setSelectedRegion(businessInformation.state);
						setZipCode(businessInformation.zip_code);
						setContactPhone(businessInformation.contact_phone);
						setCity(businessInformation.primary_city);
						setSelectedPrimaryLang(businessInformation.primary_language);
						setSelectedPrimaryCurrency(businessInformation.primary_currency);
						setSelectedIndustry(businessInformation.industry);
						setSelectedType(businessInformation.type);
						setSelectedSize(businessInformation.size);
						setAuthorized(businessInformation.is_authorized);
						setPrimaryCity(businessInformation.primary_city);
						setContactEmail(businessInformation.contact_email);


						setBusinessInfoLoaded(true);
						setIsLoading(false);
					} else {
						setBusinessInfoLoaded(false);
						setIsLoading(false);
						// setAlert({
						// 	variant: 'danger',
						// 	message: response.message
						// });
						// window.scrollTo(0, 0);0
					}
				} catch (error) {
					setBusinessInfoLoaded(false);
					// setIsLoading(false);
					setIsLoading(false);
					console.log(error);
				}
			} else {
				setBusinessInfoLoaded(false);
				setIsLoading(false);
			}
		},
	);

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(() => {
		if (!isBusinessInfoLoaded && !isLoadOnlyOnce) {
			fetchBusinessInfo();
		}

		if (isDataLoaded){
			setDataLoaded(false);
			addSpaceBusinessDetailsForm.setFieldValue("legal_name", legalName);
			addSpaceBusinessDetailsForm.setFieldValue("street_address", streetAddress);
			addSpaceBusinessDetailsForm.setFieldValue("apt_ste_bldg", aptSteBldg);
			addSpaceBusinessDetailsForm.setFieldValue("city", city);
			addSpaceBusinessDetailsForm.setFieldValue("zip_code", zipCode);
			addSpaceBusinessDetailsForm.setFieldValue("contact_phone", contactPhone);
			addSpaceBusinessDetailsForm.setFieldValue("contact_email", contactEmail);
			addSpaceBusinessDetailsForm.setFieldValue("primary_city", primaryCity);
			setVerifyAuthorizedChecked(authorized)
		}
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}
		document.title = 'Commonspace Spaces';
	}, [
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		selectedCountry,
		setSelectedRegion,
		setSelectedPrimaryLang,
		setSelectedPrimaryCurrency,
		setSelectedIndustry,
		setSelectedType,
		setSelectedSize,
		verifyAuthorizedChecked,
		inputValue,
		formIsOnFocus,
		legalName,
		streetAddress,
		aptSteBldg,
		city,
		zipCode,
		contactPhone,
		authorized,
		contactEmail,
		primaryCity
	]);

	const onChangeVerifyChecked = (e) => {
		setVerifyAuthorizedChecked(e.target.checked);
	};


	const handleFormChanges = useCallback(async () => {
		// setWelcomeTitle(addSpaceBusinessDetailsForm.getFieldValue('welcome_title'));
		// setWelcomeMessage(addSpaceBusinessDetailsForm.getFieldValue('welcome_message'));
		// setWelcomeYoutubeVideo(addSpaceBusinessDetailsForm.getFieldValue('welcome_youtube_video'));
		// setLinksTitleFirst(addSpaceBusinessDetailsForm.getFieldValue('links_title_first'));
		// setLinksTitleSecond(addSpaceBusinessDetailsForm.getFieldValue('links_title_second'));
		//
		// // TODO: change this implementation to be dynamic
		// setAddLinkLinkTitle1(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_title_0'));
		// setAddLinkLinkDescription1(
		// 	addSpaceBusinessDetailsForm.getFieldValue('add_link_link_description_0')
		// );
		// setAddLinkLinkUrl1(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_url_0'));
		//
		// setAddLinkLinkTitle2(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_title_1'));
		// setAddLinkLinkDescription2(
		// 	addSpaceBusinessDetailsForm.getFieldValue('add_link_link_description_1')
		// );
		// setAddLinkLinkUrl2(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_url_1'));
		//
		// setAddLinkLinkTitle3(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_title_2'));
		// setAddLinkLinkDescription3(
		// 	addSpaceBusinessDetailsForm.getFieldValue('add_link_link_description_2')
		// );
		// setAddLinkLinkUrl3(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_url_2'));

		await addSpaceBusinessDetailsForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await addSpaceBusinessDetailsForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
				setDisplayDropDownError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
				setDisplayDropDownError(true);
			});
	}, []);

	const handlePhoneInput = (e) => {
		// this is where we'll call our future formatPhoneNumber function that we haven't written yet.
		const formattedPhoneNumber = formatPhoneNumber(e.target.value);
		// we'll set the input value using our setInputValue
		setInputValue(formattedPhoneNumber);
		addSpaceBusinessDetailsForm.setFieldValue('contact_phone', formattedPhoneNumber);
	};

	function formatPhoneNumber(value) {
		// if input value is falsy eg if the user deletes the input, then just return
		if (!value) return value;

		// clean the input for any non-digit values.
		const phoneNumber = value.replace(/[^\d]/g, '');

		// phoneNumberLength is used to know when to apply our formatting for the phone number
		const phoneNumberLength = phoneNumber.length;

		// we need to return the value with no formatting if its less then four digits
		// this is to avoid weird behavior that occurs if you  format the area code to early

		if (phoneNumberLength < 4) return phoneNumber;

		// if phoneNumberLength is greater than 4 and less the 7 we start to return
		// the formatted number
		if (phoneNumberLength < 7) {
			return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
		}

		// finally, if the phoneNumberLength is greater then seven, we add the last
		// bit of formatting and return it.
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
			6,
			10
		)}`;
	}

	return (
		<>
			<main className="dashboard-wrapper flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12">
						<div className="col-span-12 md:col-span-8 flex items-center">
							<h1 className="mb-4 mt-5 header-large">Your Business Details</h1>
						</div>
						<div className="col-span-12 md:col-span-4 flex items-center justify-center md:justify-end">
							<p className="font-['din2014Bold'] text-2xl">Step 1 of 5</p>
						</div>
						<div className="col-span-12">
							<h5 className="body-text--reg">
								Similar to an online e-commerce store, your Space is a website where
								your community can become members, interact, and transact. It is
								your{' '}
								{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	owned community*/}
								{/*</Link>*/}
								{getSitewideLink(
									'owned_community',
									'gradient-color-txt body-txtitalic--reg'
								)}{' '}
								and you will have access to transactions, activity data, and opt-in
								contact information. For this reason, certain information is needed
								to comply with privacy laws and to provide services.
							</h5>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5">
						<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
							<h5 className="header-medium leading-10">Set Space Visibility</h5>
							<span className="body-text--reg">Required</span>
						</div>
						<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">
							{/*<button type="submit" className="btn btn-lg btn-secondary">*/}
							{/*	View Guide*/}
							{/*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
							{/*</button>*/}
							{getSitewideLink(
								'view_guide_business_details',
								'btn btn-lg btn-secondary'
							)}
						</div>
					</div>

					<Form
						form={addSpaceBusinessDetailsForm}
						name="addSpaceBusinessDetails"
						id="addSpaceBusinessDetails"
						validateMessages={VALIDATE_MESSAGES}
						onFinish={handleSubmit}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocus(false);
								handleFormOnBlur();
							}, 200);
						}}
						onChange={handleFormChanges}
						className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<>
							{alert && (
								<div className="row mt-5">
									<div className="col-8">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								</div>
							)}
						</>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Legal Name*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Legal Name"
										name="legal_name"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<input
											type="text"
											className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
											placeholder="Enter the legal name for the person, group, business, or other legal entity."
											value={legalName}
											/>
											
									</Form.Item>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									Your Legal Name should be the person, business, or other legal
									entity that is responsible for operating the your Space.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 md: gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Country*
								</label>
								<div className="relative rounded-none text-gray-900">
								<DropdownWithCountries
										title="Country"
										displayError={
											displayDropDownError &&
											(!selectedCountry || selectedCountry === '')
										}
										value={selectedCountry}
										onDropdownChange={setSelectedCountry}
									></DropdownWithCountries>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									Enter the legal address for the person or business. This is kept
									private and will be only used by Commonspace.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Street Address*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Street Address"
										name="street_address"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<input
											type="text"
											className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
											placeholder="Enter the primary contact email for the business."
											value={streetAddress}
											/>
									</Form.Item>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									Enter the legal address for the person or business. This is kept
									private and will be only used by Commonspace.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									APT/STE/BLDG*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="APT/STE/BLDG*"
										name="apt_ste_bldg"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<input
											type="text"
											className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
											placeholder="Enter the primary contact phone number for the business."
											value={aptSteBldg}
											/>
									</Form.Item>
								</div>							
							</div>							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									Enter the legal address for the person or business. This is kept
									private and will be only used by Commonspace.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-8 items-center">
							<div className="md:col-span-3 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									City*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="City"
										name="city"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<input
											type="text"
											className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
											placeholder="Enter City"
											value={city}
											/>
									</Form.Item>
								</div>
								
							</div>
							<div className="md:col-span-3 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									State*
								</label>
								<div className="relative rounded-none ">
									<DropdownWithRegions
										title="State"
										displayError={
											selectedRegion === 'default' || selectedRegion === ''
											// displayDropDownError &&
											// (!selectedRegion || selectedRegion === '' || selectedRegion === 'default')
										}
										country={selectedCountry}
										value={selectedRegion}
										onDropdownChange={setSelectedRegion}
									></DropdownWithRegions>
								</div>
								
							</div>
							<div className="md:col-span-2 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								ZIP*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="ZIP"
										name="zip_code"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										
									{/* <FormFieldStacked
									formType='input'
									type="text"	
									placeholder="5 digits "
									></FormFieldStacked> */}
									<input
											type="text"
											className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
											placeholder="5 digits "
											value={city}
											/>
									</Form.Item>
								</div>
								
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Contact Phone*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Contact Phone"
										name="contact_phone"
										onChange={(e) => handlePhoneInput(e)}
										value={inputValue}
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<input
											type="text"
											className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
											value={inputValue}
											placeholder="Enter the primary contact phone number for the business."
											/>
									</Form.Item>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									This phone number is kept private and will be only used by
									Commonspace.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Contact Email*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Contact Email"
										name="contact_email"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true, type: 'email' }]}
									>
										<input
											type="email"
											placeholder="Enter the primary contact email for the business."
											className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
											value={contactEmail}
											/>
									</Form.Item>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									This email is kept private and will be only used by Commonspace.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Primary City*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="Primary City"
										name="primary_city"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<input
											type="text"
											placeholder="Enter your primary city"
											className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
											value={primaryCity}
											/>
									</Form.Item>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									Enter the primary or main location of the person or business.
									This can be different from the Legal Address. This city may be
									visible in certain areas.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Primary Language*
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										
										title="Image Position"
										selectedValue={selectedPrimaryLang}
										listItems={SPACE_PRIMARY_LANG}
										onDropdownChange={(value) => setSelectedPrimaryLang(value)}
									/>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									This is used to set the default language for your Space and to
									provide services. Note that some features are only available in{' '}
									{/*<a className="gradient-color-txt">*/}
									{/*	officially supported languages.*/}
									{/*</a>*/}
									{getSitewideLink('supported_language')}
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Primary Currency*
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										
										title="Image Position"
										selectedValue={selectedPrimaryCurrency}
										listItems={SPACE_PRIMARY_CURRENCY}
										onDropdownChange={(value) =>
											setSelectedPrimaryCurrency(value)
										}
									/>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									This is used to set the default currency for your Space and to
									provide services. Note that some features are only available in{' '}
									{/*<a className="gradient-color-txt">*/}
									{/*	officially supported currencies and cryptocurrencies.*/}
									{/*</a>*/}
									{getSitewideLink('supported_currency_crypto')}
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Industry*
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										displayError={selectedIndustry === 'default'
										}
										title="Industry"
										selectedValue={selectedIndustry}
										listItems={SPACE_BUSINESS_INDUSTRY}
										onDropdownChange={(value) => setSelectedIndustry(value)}
									/>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Select an industry that best applies. This will be used to
									suggest features and provide services.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center ">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Type*
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] text-ellipsis overflow-hidden !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										displayError={
											selectedType === 'default'
										}
										title="Type"
										selectedValue={selectedType}
										listItems={SPACE_BUSINESS_TYPE}
										onDropdownChange={(value) => setSelectedType(value)}
									/>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg ">
									Select a type that best applies to your organization. This will
									be used to suggest features and provide services.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Size*
								</label>
								<div className="relative rounded-none ">
									<DropdownWithItems
										customButtonClass="h-[40px] !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
										displayError={
											selectedSize === 'default'
										}
										title="Size"
										selectedValue={selectedSize}
										listItems={SPACE_BUSINESS_SIZE}
										onDropdownChange={(value) => setSelectedSize(value)}
									/>
								</div>
								
							</div>
							
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Select the size of your organization. This will be used to
									suggest features and provide services.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 mdgrid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5">
							<div className="md:col-span-8 !order-last md:!order-first">
								<div className="form-check">
									<label className="form-check-label body-text--small">
										<Checkbox
											checked={verifyAuthorizedChecked}
											onChange={onChangeVerifyChecked}
											className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
										></Checkbox>
										I verify that I am an authorized representative of the
										organization of the Legal Name entered above and have the
										right to act on its behalf in the creation and management of
										this Space. The organization and I agree to{' '}
										{/*<a className="gradient-color-txt body-txtitalic--small">*/}
										{/*	the terms for using and operating Spaces.*/}
										{/*</a>*/}
										{getSitewideLink(
											'terms_for_using',
											'gradient-color-txt body-txtitalic--small'
										)}
									</label>
								</div>
							</div>
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Please certify that the responsible parties have read and agree
									to the terms, policies, agreements, and guidelines governing the
									use and management of Spaces.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-12 mt-5">
							<div className="md:col-span-8 flex items-end">
								<Button className="btn btn-lg btn-primary" htmlType="submit"
								disabled={displayDropDownError || !verifyAuthorizedChecked}>
									{isLoading && <i className="fa-solid fa-spinner fa-spin"></i>}
									Save & Continue
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</main>
		</>
	);
};

export default memo(AddBusinessDetails);
