import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';
import { Network, Alchemy } from 'alchemy-sdk';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import TextareaAutosize from 'react-textarea-autosize';
import { ethers } from 'ethers';

import {
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	NODE_ENV,
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_NETWORK
} from '../../../../constants/common';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	ADD_CREATE_BENEFIT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	MEMBERSHIP_TYPES,
	UPDATES_VISIBILITY
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import {
	checkItemShortCodeAvailability,
	createSpaceItem,
	updateSpaceItem
} from '../../../../services/space';
import { getListIcon } from '../../../../utils/list';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import ExplicitContent from '../../../../components/commons/Modals/ExplicitContent';
import { CustomValidators } from '../../../../utils/CustomValidators';

const EditItemNFT = (props) => {
	const { itemData, benefitData } = props;
	const [updateItemNFT] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [selectedVisibility, setSelectedVisibility] = useState('members');
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [addCreateBenefit, setAddCreateBenefit] = useState('default');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocusItem, setFormIsOnFocusItem] = useState(false);
	const [formHasErrorItem, setFormHasErrorItem] = useState(true);
	const [contractAddress, setContractAddress] = useState(null);
	const [contractAddressDetails, setContractAddressDetails] = useState(null);
	const [itemImageError, setItemImageError] = useState(null);
	const [itemImage, setItemImage] = useState(null);
	const [itemImageBlobPreview, setItemImageBlobPreview] = useState(null);
	const [itemVideo, setItemVideo] = useState(null);
	const [itemVideoBlobPreview, setItemVideoBlobPreview] = useState(null);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [alert, setAlert] = useState(null);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);

	// Optional Config object, but defaults to demo api-key and eth-mainnet.
	const settings = {
		apiKey: ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
		network: Network.ETH_MAINNET // Replace with your network.
	};

	const alchemy = new Alchemy(settings);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});

	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);

	const getCollectionOwner = async (collection_address) => {
		try {
			const contractABI = ['function owner() view returns (address)'];
			const provider = new ethers.providers.AlchemyProvider('mainnet', ALCHEMY_API_KEY);
			const myContract = new ethers.Contract(collection_address, contractABI, provider);
			const owner = await myContract.owner();
			return owner;
		} catch (error) {
			console.error(`Failed to get owner: ${error}`);
		}
	};

	const getCollections = async (collection_address, proceed_verify_wallet_address = false) => {
		const checkOwnerRes = await getCollectionOwner(collection_address);

		setContractAddressDetails(null);

		await alchemy.nft
			.getContractMetadata(collection_address)
			.then((result) => {
				// The contract owner
				// Other vars: address, contractDeployer, name, description
				if (result) {
					const contractAddressData = {
						collection_name: result.openSea.collectionName ?? 'No Name',
						description: result.openSea.description ?? 'No Description',
						creator_wallet_address: checkOwnerRes ?? 'No Address',
						wallet_name: result.tokenType
					};

					setContractAddressDetails(contractAddressData);
				}
			})
			.catch((error) => {
				setContractAddressDetails(null);
			});
	};

	const handleItemFormOnBlur = useCallback(async () => {
		await updateItemNFT
			.validateFields()
			.then(async () => {
				setFormHasErrorItem(false);
			})
			.catch((errors) => {
				setFormHasErrorItem(true);
			});
	}, []);

	const handleFormItemChanges = useCallback(async () => {
		await updateItemNFT
			.validateFields()
			.then(async () => {
				setFormHasErrorItem(false);
			})
			.catch((errors) => {
				setFormHasErrorItem(true);
			});
	}, []);

	const handleItemImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setItemImage(file);
		setItemImageBlobPreview(blobPreview);
	};

	const handleItemVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
		}

		setItemVideo(file);
		setItemVideoBlobPreview(blobPreview);
	};

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setDisplayDropDownError(true);
			setItemImageError(null);

			let hasError = false;

			if (selectedContentType === 'default' || selectedAccessType === 'default') {
				hasError = true;
			}

			if (!itemImageBlobPreview) {
				setItemImageError('Item Image is required.');
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = values;
			data.item_id = itemData.id;
			data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
			data.collection_contract_address = contractAddress;
			data.expiration_subscription_enabled = enableExpirationChecked;
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.visibility = selectedVisibility;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (itemImage) {
				data.item_image = itemImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (itemVideo) {
				data.item_video = itemVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					setSubmitted(true);

					const response = await updateSpaceItem(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});

						setDisplayDropDownError(false);
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
					}

					setSubmitted(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			itemData,
			contractAddress,
			enableExpirationChecked,
			itemImage,
			itemVideo,
			selectedContentType,
			selectedAccessType,
			selectedVisibility,
			photoIsRemoved,
			videoIsRemoved,
			selectedExplicitAccess,
			selectedExplicitContent,
			itemImageBlobPreview
		]
	);

	const setFormSpaceData = async (data) => {
		updateItemNFT.setFieldsValue({
			name: data.name,
			listing_text: data.listing_text,
			summary: data.summary,
			sku_shortcode: data.sku_shortcode,
			max_per_account: data.max_per_account
		});

		if (data.visibility) {
			setSelectedVisibility(data.visibility);
		} else {
			setSelectedVisibility('custom');
		}

		let content_type = data.content_type;
		if (!data.content_type) {
			content_type = 'default';
		}
		setSelectedContentType(content_type);

		let content_access = data.content_access;
		if (!data.content_access) {
			content_access = 'default';
		}
		setSelectedAccessType(content_access);

		if (data.explicit_content !== null) {
			setSelectedExplicitContent(JSON.parse(data.explicit_content));
		}
		if (data.explicit_access !== null) {
			setSelectedExplicitAccess(JSON.parse(data.explicit_access));
		}

		setExpirationChecked(data.enable_expiration_and_subscription);
		if (data.photo) {
			setItemImageBlobPreview(data.photo);
		}
		if (data.video) {
			setItemVideoBlobPreview(data.video);
		}
		handleFormItemChanges();

		setContractAddress(data.collection_contract_address);
		if (data.collection_contract_address) {
			await getCollections(data.collection_contract_address, true);
		}
	};

	const redirectToItemUpdateBenefitLink = useCallback(
		(item) => {
			if (spaceInfo) {
				if (item.no_first_benefit) {
					const route = routes.addItemMonthBenefit();
					const url = route.replace(':space_username', '@' + spaceInfo.username);
					return url.replace(':item_id', item.id);
				} else {
					const route = routes.editItemMonthBenefit();
					let url = route.replace(':space_username', '@' + spaceInfo.username);
					url = url.replace(':benefit_id', item.first_benefit.id);
					return url.replace(':item_id', item.id);
				}
			}
			return '';
		},
		[spaceInfo]
	);

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	useEffect(() => {}, [itemImageError]);

	useEffect(() => {
		if (itemData) {
			setFormSpaceData(itemData).then((r) => {});
		}
	}, [authUserInfo, spaceInfo, spaceInfoLoading, itemData, benefitData]);

	return (
		<>
			<div className="memberships">
				<div className="dashboard-wrapper">
					<div className="grid grid-cols-12 gap-8">
						<div className="col-span-12">
							<h3 className="header-medium">Editing {itemData.name}</h3>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-8 mt-5">
						<div className="col-span-12">
							<p className="body-text--small">
								You can edit your Item Details here, however most changes may
								only apply to new sales and certain details may not be modifiable
								depending on your Item Type and status.{' '}
								<a className="body-txtitalic--small gradient-color-txt">
									Learn more about Item Types and editing limits.
								</a>
							</p>
						</div>
					</div>
				</div>

				<Form
					form={updateItemNFT}
					name="updateItemNFT"
					id="updateItemNFT"
					validateMessages={VALIDATE_MESSAGES}
					onFocus={() => {
						setFormIsOnFocusItem(true);
					}}
					onBlur={() => {
						setTimeout(() => {
							setFormIsOnFocusItem(false);
							handleItemFormOnBlur();
						}, 200);
					}}
					onChange={handleFormItemChanges}
					onFinish={handleSubmit}
					className={formIsOnFocusItem ? 'hide-antd-error-messages' : ''}
				>
					<div className="dashboard-wrapper mt-5">
						<div className="grid grid-cols-12 gap-8">
							<div className="col-span-6">
								<h3 className="header-small">Item Details</h3>
							</div>
							<div className="col-span-6">
								<p className="body-txtitalic--small gradient-color-txt text-end">
									View Guide
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-5">
							<div className="col-span-4">
								<div className="upload-rec">
									<UploadDragger
										passedError={itemImageError}
										cropperAspectRatio={4 / 3}
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={IMAGE_FILETYPES.toString()}
										uploadedFilePreview={itemImageBlobPreview}
										onUploadFileChange={handleItemImageUploadFileChange}
										minHeight={IMAGE_STANDARD_MIN_HEIGHT}
										minWidth={IMAGE_STANDARD_MIN_WIDTH}
										maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
										maxWidth={IMAGE_STANDARD_MAX_WIDTH}
										uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
										disableCropper={true}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Item Image*
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 3840px square or wide. <br />
									JPEG, PNG, or GIF
								</p>
							</div>
							<div className="col-span-4">
								<div className="upload-rec">
									<UploadDragger
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={VIDEO_FILETYPES.toString()}
										uploadedFilePreview={itemVideoBlobPreview}
										onUploadFileChange={handleItemVideoUploadFileChange}
										minHeight={VIDEO_STANDARD_MIN_HEIGHT}
										minWidth={VIDEO_STANDARD_MIN_WIDTH}
										maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
										maxWidth={VIDEO_STANDARD_MAX_WIDTH}
										uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Item Video
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 1920px/1080px, square or wide. <br />
									MOV or MP4
								</p>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									Your Item Image (up to 8MB) should be as high resolution as
									possible, up to 3840px height and width. This will also be used
									for any thumbnails and previews.
								</p>
								<p className="body-txtitalic--smallest">
									Your Item Image (up to 8MB) should be as high resolution as
									possible, up to 3840px height and width. This will also be used
									for any thumbnails and previews.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Name
									</label>
									<Form.Item
										label="Name"
										name="name"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<Input
											placeholder="Up to 32 characters, including spaces."
											className="input-md body-text--small color-reg"
											maxLength={32}
											disabled={submitted}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									This is the displayed Name of the Item and is what people will
									see when browsing your Space and viewing their purchased Item,
									as well as the Collection Name when viewing the artwork.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Sku Short Code
									</label>
									<Form.Item
										label="Sku Short Code"
										name="sku_shortcode"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												min: 2,
												message: 'Must be minimum 2 characters.'
											},
											{
												max: 8,
												message: 'Must not exceed 8 characters.'
											},
											({ getFieldValue }) => ({
												async validator(_, value) {
													if (value) {
														const data = {
															space_id: spaceInfo.id,
															shortcode: value
														};

														if (itemData) {
															data['item_id'] = itemData.id;
														}

														let available = false;
														const response =
															await checkItemShortCodeAvailability(
																data
															);
														if (response && response.result) {
															available = response.availability;
														}

														if (!available) {
															return Promise.reject(
																new Error(
																	'Shortcode not available.'
																)
															);
														}
													}

													if (
														(value && value.match(/\ /g)) ||
														/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
															value
														)
													) {
														return Promise.reject(
															new Error('Invalid Input')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
										<Input
											placeholder="2 to 8 characters. Letters and numbers only, no spaces."
											className="input-md body-text--small color-reg"
											disabled={submitted}
											maxLength={8}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									Choose a unique SKU Shortcode based the Item Name. This is must
									be unique within your Space and is used in transactions,
									support, and other areas.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-50">
										Max Per Account
									</label>
									<Form.Item
										label="Max Per Account"
										name="max_per_account"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{ validator: CustomValidators.maxPerCount }
										]}
									>
										<Input
											placeholder="Enter “0” for unlimited."
											className="input-md body-text--small color-reg"
											disabled={submitted}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									You can put a limit how many Items can be purchased by a single
									account. Enter “0” to set this to unlimited.
								</p>
							</div>
						</div>

						{/*<div className="grid grid-cols-12 gap-8 mt-4  flex items-center">*/}
						{/*	<div className="col-span-8">*/}
						{/*		<div className="form-check ms-5">*/}
						{/*			<label className="form-check-label body-text--small gradient-color-txt">*/}
						{/*				<Checkbox*/}
						{/*					checked={enableExpirationChecked}*/}
						{/*					className="form-check-input"*/}
						{/*					onChange={onChangeEnableExpiration}*/}
						{/*				></Checkbox>*/}
						{/*				Enable Expiration Date and add Monthly Subscription for*/}
						{/*				continued access*/}
						{/*			</label>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div className="col-span-4">*/}
						{/*		<p className="body-txtitalic--smallest">*/}
						{/*			Items have a one-time upfront Price. This option adds an*/}
						{/*			Expiration after a set number of months. After that, Members*/}
						{/*			must pay a Monthly Subscription to continue access. Details are*/}
						{/*			added in the next step.*/}
						{/*		</p>*/}
						{/*	</div>*/}
						{/*</div>*/}

						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Summary
									</label>
									<Form.Item
										label="Summary"
										name="summary"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<TextareaAutosize
											minRows={3}
											placeholder="Up to 128 characters, including spaces."
											className="input-md body-text--small color-reg"
											maxLength={128}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									This Summary shows when Members view their Item. It should be 1
									to 2 sentences summarizing the Item and its details.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">
							<div className="col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-1/4">
										Listing Text
									</label>
									<Form.Item
										label="Listing Text"
										name="listing_text"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<TextareaAutosize
											minRows={3}
											placeholder="Up to 128 characters, including spaces."
											className="input-md body-text--small color-reg"
											disabled={submitted}
											maxLength={128}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									The Listing Text is used when listing your Item for purchase on
									your Space. It should be 1 to 2 sentences summarizing the Item
									and why people should join.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<DropdownWithItems
									title="Select Content Type"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2"
									displayError={
										displayDropDownError && selectedContentType === 'default'
									}
									icon={getListIcon(
										CONTENT_TYPES_LIST_WITH_DEFAULT,
										selectedContentType
									)}
									selectedValue={selectedContentType}
									listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openContentTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitContent}
									setModalOpen={setModalOpenExplicitContent}
									selectedItems={selectedExplicitContent}
									isExplicitContent={true}
									onSelectedItems={(value) => setSelectedExplicitContent(value)}
								/>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									Change this setting if your Membership Details, Image, or Video
									contains explicit or sensitive content. If it does, viewers may
									need to confirm their age before accessing content. View our
									Content Guidelines.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<DropdownWithItems
									title="Select Access Type"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2"
									displayError={
										displayDropDownError && selectedAccessType === 'default'
									}
									icon={getListIcon(
										ACCESS_TYPES_LIST_WITH_DEFAULT,
										selectedAccessType
									)}
									selectedValue={selectedAccessType}
									listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openAccessTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitAccess}
									setModalOpen={setModalOpenExplicitAccess}
									selectedItems={selectedExplicitAccess}
									isExplicitContent={false}
									onSelectedItems={(value) => setSelectedExplicitAccess(value)}
								/>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									Change this setting if this Membership will provide Members with
									access to explicit or sensitive content, either directly on your
									Space or via an external site or other source. View our Content
									Guidelines.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<DropdownWithItems
									title="Update Visibility"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2"
									displayError={
										displayDropDownError && selectedVisibility === 'default'
									}
									selectedValue={selectedVisibility}
									listItems={UPDATES_VISIBILITY}
									onDropdownChange={(value) => setSelectedVisibility(value)}
								/>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									You can set the Visibility of this Item to viewable to the
									Public, Members Only (any item), or Custom Access (based on
									owning a specific Membership or Item).
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								{contractAddressDetails && (
									<>
										<div className="grid grid-cols-12 gap-8 flex items-center">
											<div className="col-span-4">
												<strong className="body-text--small color-reg">
													Collection Name:
												</strong>
											</div>
											<div className="col-span-8">
												<p className="body-text--small gradient-color-txt">
													{contractAddressDetails.collection_name}
												</p>
											</div>
										</div>
										<div className="grid grid-cols-12 gap-8 flex items-center">
											<div className="col-span-4">
												<strong className="body-text--small color-reg">
													Contract Address:
												</strong>
											</div>
											<div className="col-span-8">
												<p className="body-text--small gradient-color-txt">
													{contractAddress}
												</p>
											</div>
										</div>
									</>
								)}
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									The Membership can be claimed and accessed based on the
									ownership of an NFT from this collection. Each one claimed is
									unique and specific to each individual NFT.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-12">
								<div className="dialog-box-secondary">
									<div className="content">
										<div className="grid grid-cols-12 gap-8">
											<div className="col-span-2">
												<p className="header-small-title color-lightest">
													IMPORTANT NOTICE BASED ON YOUR CHANGES.
													<br />
													<br />
													PLEASE REVIEW BEFORE SAVING.
												</p>
											</div>
											<div className="col-span-10">
												<ul>
													<li className="body-text--small color-lightest">
														Your Image and/or Video change will only
														apply to the Membership display on your
														Space.
													</li>
													<li className="body-text--small color-lightest">
														Your Name will only apply to the Membership
														as displayed on your Space and in your
														Member’s views.
													</li>
													<li className="body-text--small color-lightest">
														Your Monthly Subscription change will apply
														to ALL Existing AND New Memberships sales.
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<>
								{alert && (
									<div className="col-span-12">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								)}
							</>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-12">
								<Form.Item className="mb-0">
									<Button
										className="btn btn-primary btn-md"
										htmlType="submit"
										disabled={submitted || formHasErrorItem}
									>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin me-2"></i>
										)}
										Save
									</Button>
								</Form.Item>
							</div>
						</div>
					</div>
				</Form>
			</div>
			{/*<div className="dashboard-wrapper mt-5">*/}
			{/*	<div className="grid grid-cols-12 gap-8">*/}
			{/*		<div className="col-span-12">*/}
			{/*			<h3 className="header-small">Membership Benefits</h3>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*		<div className="col-span-4">*/}
			{/*			<p className="body-text--small gradient-color-txt">Member Access</p>*/}
			{/*		</div>*/}
			{/*		<div className="col-span-4">*/}
			{/*			<p className="body-txtitalic--smallest">*/}
			{/*				The Member Access Benefit provides basic “Members Only” access to*/}
			{/*				Updates and Members sections in your Space. This is included in every*/}
			{/*				Membership you create and cannot be edited.*/}
			{/*			</p>*/}
			{/*		</div>*/}
			{/*		<div className="col-span-4">*/}
			{/*			<p className="body-text--small">Included Benefit</p>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	{enableExpirationChecked && (*/}
			{/*		<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*			<div className="col-span-4">*/}
			{/*				<p className="body-text--small gradient-color-txt">*/}
			{/*					Monthly Subscription*/}
			{/*				</p>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-4">*/}
			{/*				<p className="body-txtitalic--smallest">*/}
			{/*					The Monthly Subscription Benefit is required when the Expiration*/}
			{/*					Date option is enabled.*/}
			{/*				</p>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-4 flexjustify-between">*/}
			{/*				<Link className="btn btn-secondary btn-md m-2">*/}
			{/*					<i className="fa-light fa-arrow-up-right-from-square"></i>*/}
			{/*				</Link>*/}
			{/*				<Link*/}
			{/*					className="btn btn-secondary btn-md m-2"*/}
			{/*					to={redirectToItemUpdateBenefitLink(itemData)}*/}
			{/*				>*/}
			{/*					<i className="fa-light fa-pen-to-square"></i>*/}
			{/*				</Link>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	)}*/}
			{/*	<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*		<div className="col-span-12">*/}
			{/*			<DropdownWithItems*/}
			{/*				title="Add or Create Benefit"*/}
			{/*				dropDownClass="dropdown-bg"*/}
			{/*				customButtonClass="btn-md btn-secondary mt-2"*/}
			{/*				icon="fa-solid fa-gifts"*/}
			{/*				selectedValue={addCreateBenefit}*/}
			{/*				listItems={ADD_CREATE_BENEFIT}*/}
			{/*				onDropdownChange={(value) => setAddCreateBenefit(value)}*/}
			{/*			/>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*		<div className="col-span-12">*/}
			{/*			<Button className="btn btn-primary btn-md">Save</Button>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
};

export default memo(EditItemNFT);
