import React, {memo, useCallback, useEffect, useState} from "react";
import {Button, Form} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {
    getSpaceItemData,
    postUserSpaceItemClaimAndSetupSharing,
} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import routes from "../../../routes";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import {STORAGE_KEYS, VALIDATE_MESSAGES} from "../../../constants/common";
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {MEMBERSHIP_DATA_SHARING} from "../../../constants/space";
import {getLocalStorage} from "../../../utils/storage";
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {getAuthUserInfo} from "../../../states/general/actions";
import {getUserSpaceItemClaim} from "../../../services/user";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";


const ItemPaymentCompleted = () => {
    const [itemPaymentCompleted] = Form.useForm();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [isItemLoaded, setItemLoaded] = useState(false);
    const [itemDetails, setItemDetails] = useState(null);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [alert, setAlert] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const [loadItemOnce, setLoadItemOnce] = useState(false);
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
    const currentLocation = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const itemId = searchParams.get('id');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initialLoad, setInitialLoad] = useState(true);
    const [isItemClaimLoaded, setItemClaimLoaded] = useState(false);
    const [selectedFirstName, setSelectedFirstName] = useState('share');
    const [selectedBirthDate, setSelectedBirthDate] = useState('share');
    const [selectedCity, setSelectedCity] = useState('share');
    const [selectedEmail, setSelectedEmail] = useState('share');

    let space_username = null;

    const path = currentLocation.pathname;
    if (path && path.startsWith('/@') && path.length > 3) {
        const clean_path = path.replace('/@', '');
        const path_exp = clean_path.split('/');
        space_username = path_exp[0];
    }

    const getItem = useCallback(async (space_id, item_id) => {
        if (!item_id) {
            return;
        }

        const data = {
            space_id: space_id,
            item_id: item_id
        }

        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }

        const response = await getSpaceItemData(data);

        if (response && response.result) {
            const itemData = response.item;
            setIsPaid(itemData.is_paid);
            setItemDetails(itemData);
            setItemLoaded(true);
            if (itemData.photo || itemData.video) {
                if (itemData.photo) {
                    const bg = {
                        backgroundImage: 'url(' + itemData.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '32px',
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (itemData.video) {
                    const bg = {
                        backgroundImage: 'none',
                    };
                    setCollectionAssetBGImage(bg)
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '32px',
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [itemDetails]);


    const handleFormOnBlur = useCallback(async () => {
        await itemPaymentCompleted
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSubmit = useCallback(
        async (values) => {
            setAlert(null);
            setSubmitted(true);

            if (!selectedFirstName) {
                return;
            }

            if (!selectedBirthDate) {
                return;
            }

            if (!selectedCity) {
                return;
            }

            if (!selectedEmail) {
                return;
            }

            const data = values;
            data.space_id = spaceInfo.id;
            data.item_id = itemId;
            data.share_name = selectedFirstName === 'share';
            data.share_birthdate = selectedBirthDate === 'share';
            data.share_city = selectedCity === 'share';
            data.share_email = selectedEmail === 'share';

            if (values.birthdate) {
                data.birthdate = values.birthdate.format('YYYY-MM-DD');
            }

            data.wallet_contract_address = getLocalStorage(
                'temp_membership_wallet_owner_contract_address'
            );

            // const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
            // const isSubscription = itemDetails.enable_expiration_and_subscription;

            if (authUserInfo) {
                data.user_id = authUserInfo.id;
                const response = await postUserSpaceItemClaimAndSetupSharing(data);
                console.log('ItemClaimAndSetupSharing-response: '+JSON.stringify(response));
                if (response) {
                    if (response.result) {
                        // removeLocalStorage('temp_membership_nft_is_owned');
                        // removeLocalStorage('temp_membership_space_username');
                        // removeLocalStorage('temp_membership_wallet_owner_contract_address');
                        // removeLocalStorage('temp_membership_wallet_owner_email');

                        let url = routes.spaceItemBuy();

                        if (itemDetails.collection_type === 'item') {
                            url = routes.spaceItemActivate()
                        }
                        const fin_url =
                            url.replace(':space_username', '@' + space_username) +
                            '?id=' +
                            itemId;
                        navigate(fin_url);
                    } else {
                        if (response.message === 'Already claimed item.') {
                            const url = routes.spaceItemActivate()
                            const fin_url =
                                url.replace(':space_username', '@' + space_username) +
                                '?id=' +
                                itemId;
                            navigate(fin_url);
                        }
                    }
                } else {
                    console.log('ItemClaimAndSetupSharing-response: '+JSON.stringify(response));
                }
            }

            setSubmitted(false);
        },
        [
            authUserInfo,
            spaceInfo,
            selectedFirstName,
            selectedBirthDate,
            selectedCity,
            selectedEmail,
            itemId,
            itemDetails
        ]
    );

    const getItemClaim = useCallback(async (space_id, item_id) => {
        if (!item_id) return;
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await getUserSpaceItemClaim(data);
        if (response) {
            if (response.result) {
                if (response.is_exist) {
                    let url = routes.spaceItemActivate();
                    const fin_url =
                        url.replace(':space_username', '@' + space_username) +
                        '?id=' +
                        item_id;
                    navigate(fin_url);
                }
            }
            setItemClaimLoaded(true);
        }
    }, [itemDetails, authUserInfo]);


    useEffect(() => {
        if (itemDetails != null) {
            setItemLoaded(false);
        }
        if (authUserInfo===null) {
            dispatch(getAuthUserInfo());
        }
        if (space_username && !isItemLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getItem(spaceInfo.id, itemId).then((r) => {
                    });
                    // setSpaceName(spaceInfo.name);
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(space_username));
                }
                if (spaceInfoLoading === 'failed') {
                    navigate(routes.createSpace());
                }
            }
        }

        if (itemDetails != null) {
            if (authUserInfo != null && !loadItemOnce) {
                setLoadItemOnce(true);
                getItem(spaceInfo.id, itemDetails).then((r) => {});
            }

            //Redirect to details if already claim or paid
            if (authUserInfo != null) {
                getItemClaim(spaceInfo.id, itemId).then(r =>{});
            }
        }

        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        space_username,
        loadItemOnce,
        // initialLoad,
        itemId,
        itemDetails,
        isItemClaimLoaded
    ]);

    return (
        <>
            <main id="membership-buy" className="flex">
                {!spaceInfo || !authUserInfo || !isItemClaimLoaded ? (
                    <div className="loading-items w-full mt-8 text-center">
                        <i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
                    </div>
                ) : (
                    <>
                        <div className="margin-left-15 xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
                            <div className="grid gap-8 margin-right-0">
                                {/*{!membershipFound ? (*/}
                                {/*    <div className="col-6">Membership not found.</div>*/}
                                {/*) : (*/}
                                <div className="grid grid-cols-2 pr-0">
                                    {/*new UI Structure*/}
                                    <div className="col-span-2 grid grid-cols-2 ps-0 gap-4">
                                        <div className="lg:col-span-1 xsm:col-span-2">
                                            <h1 className="mb-4 mt-5 header-large">
                                                Payment Completed.<br/>
                                                Your Item is ready!
                                            </h1>
                                            <p className="body-text--reg">
                                                You’ve successfully purchased {' '}
                                                <a href={itemDetails?.type === "nft_collection" ?
                                                    routes.spaceMembersProductsItem().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + itemId :
                                                    routes.spaceItemBuy().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + itemId} className="gradient-color-txt">
                                                    {itemDetails ? itemDetails.name : ''}
                                                </a>
                                                {' '}for{' '}
                                                <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                </a>
                                                {' '}. To activate it, complete the details below:
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-span-2 grid lg:grid-cols-2 md:grid-cols-1 xsm:gap-10 lg:gap-30 ">

                                        <div className="lg:hidden md:flex">
                                            {membershipItemImage(collectionAssetType, itemDetails)}
                                        </div>

                                        <div className="lg:col-span-1">
                                            <h3 className="mb-4 mt-5 header-medium">
                                                Data Sharing with <i className="fa-solid fa-solar-system"/> {spaceInfo ? spaceInfo.name : ''}
                                            </h3>
                                            <p className="body-text--reg">
                                                Your Item for {' '}
                                                <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                </a>
                                                {' '}includes access, updates,
                                                benefits, and more. To get full access to
                                                everything {' '}<i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                {' '}offers, it’s recommended you share your Account Details with their
                                                management team. This information is never shared publicly.
                                            </p>
                                            <Form
                                                form={itemPaymentCompleted}
                                                name="itemPaymentCompleted"
                                                id="itemPaymentCompleted"
                                                validateMessages={VALIDATE_MESSAGES}
                                                onFocus={() => {
                                                    setFormIsOnFocus(true);
                                                    setAlert(null);
                                                }}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        setFormIsOnFocus(false);
                                                        handleFormOnBlur();
                                                    }, 200);
                                                }}
                                                autoComplete="off"
                                                onFinish={handleSubmit}
                                                className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
                                            >

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            First & Last Name
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        <div className="input-group-com">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                title="First & Last Name"
                                                                selectedValue={selectedFirstName}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedFirstName(value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            Birthdate
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        <div className="input-group-com">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                title="Birthdate"
                                                                selectedValue={selectedBirthDate}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedBirthDate(value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            City
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        <div className="input-group-com">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                title="City"
                                                                selectedValue={selectedCity}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedCity(value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            Email Address
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        <div className="input-group-com">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                title="Email"
                                                                selectedValue={selectedEmail}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedEmail(value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="input-group-com my-5">
                                                    <Form.Item className="mb-0">
                                                        <Button
                                                            className="btn btn-primary btn-lg"
                                                            htmlType="submit"
                                                            disabled={submitted}
                                                        >
                                                            {submitted && (
                                                                <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                            )}
                                                            Save & Activate
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="lg:inline xsm:hidden p-0">
                                            {membershipItemImage(collectionAssetType, itemDetails)}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-span-6">
                                    <div className="left-image-holder"></div>
                                </div> */}
                            </div>
                        </div>
                    </>
                    )
                }
            </main>
        </>
    );
}

export default memo(ItemPaymentCompleted);