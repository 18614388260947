import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd';
import {useLocation} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { VALIDATE_MESSAGES } from '../../../../constants/common';
import {
	SPACE_CHANNEL_ACCESS, SPACE_CHANNEL_COMMENTING, SPACE_CHANNEL_POSTING, SPACE_CHANNEL_VISIBILITY,
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';

import './../../../SpaceCreate/AddDetails/Links/Links.scss';
import {
	createSpaceChannel,
	enableDisableChannels,
	getSpaceChannels,
	updateSpaceChannel
} from "../../../../services/space";
import {getSpaceInfo} from "../../../../states/spaceGlobal/actions";
import {CUSTOM_REGEX} from "../../../../constants/custom_regex";
import Alert from "react-bootstrap/Alert";

const ManageChannelsPage = (props) => {
	const currentLocation = useLocation();
	const { authUserInfo, spaceInfo } = props;
	const dispatch = useDispatch();
	const [createChannelForm] = Form.useForm();
	const [disableChannelForm] = Form.useForm();
	const [isChannelsEnable, setIsChannelsEnable] = useState(false);
	const [openChannelForm, setOpenChannelForm] = useState(false);
	const [selectedChannel, setSelectedChannel] = useState();
	const [selectedVisibility, setSelectedVisibility] = useState('default');
	const [selectedAccess, setSelectedAccess] = useState('default');
	const [selectedPosting, setSelectedPosting] = useState('default');
	const [selectedCommenting, setSelectedCommenting] = useState('default');
	const [defaultChecked, setDefaultChecked] = useState(false);
	const [channels, setChannels] = useState([]);
	const [isEnableChannelLoading, setIsEnableChannelLoading] = useState(false);
	const [isDisableChannelLoading, setIsDisableChannelLoading] = useState(false);
	const [isCreateChannelLoading, setIsCreateChannelLoading] = useState(false);
	const [spaceUsername, setSpaceUsername] = useState('');
	const [password, setPassword] = useState('');
	const channelFormRef = useRef(null);
	const [alert, setAlert] = useState(null);

	const getChannels = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					const channel_param = {}
						channel_param.space_id = spaceInfo.id;

					const responseGetChannels = await getSpaceChannels(channel_param);
					if (responseGetChannels && responseGetChannels.result) {
						setChannels(responseGetChannels.space_channels);
					}
					setOpenChannelForm(false);
					setSelectedChannel(undefined);
					setIsCreateChannelLoading(false);
				}
			} catch (error) {
			}
		},
		[spaceInfo]
	);

	const handleEnableChannels = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					setIsEnableChannelLoading(true);
					const data = {
						space_id: spaceInfo.id,
						channels_enable: true
					};
					const responseEnableDisableChannels = await enableDisableChannels(data);
					if (responseEnableDisableChannels && responseEnableDisableChannels.result) {
						setIsChannelsEnable(true);
						if (channels && channels.length < 1 ) {
							setOpenChannelForm(true);
							setSelectedChannel(undefined);
						}
						setIsEnableChannelLoading(false);
					} else {
						setIsEnableChannelLoading(false);
					}
				}
			} catch (error) {
			}
		},
		[spaceInfo, channels, openChannelForm, selectedChannel, isChannelsEnable, isEnableChannelLoading]
	);

	const handleCreateNewChannel = useCallback(
		() => {
			setOpenChannelForm(true);
			setSelectedChannel(undefined);
		},
		[openChannelForm, selectedChannel]
	);

	const handleSaveNewChannel = useCallback(
		async (values) => {
			try {
				if (spaceInfo) {
					setIsCreateChannelLoading(true);

					if (selectedChannel) {
						const data = {
							space_id: spaceInfo.id,
							user_id: authUserInfo.id,
							name: values.channel_name,
							visibility: selectedVisibility,
							access: selectedAccess,
							posting: selectedPosting,
							commenting: selectedCommenting,
							default: defaultChecked,
							channel_id: selectedChannel.id
						};
						const responseUpdateSpaceChannel = await updateSpaceChannel(data);
						if (responseUpdateSpaceChannel && responseUpdateSpaceChannel.result) {
							// setOpenChannelForm(false);
							// setSelectedChannel(undefined);
							// setIsCreateChannelLoading(false);
							getChannels().then(r => {});
						} else {
							setIsCreateChannelLoading(false);
						}
					} else {
						const data = {
							space_id: spaceInfo.id,
							user_id: authUserInfo.id,
							name: values.channel_name,
							visibility: selectedVisibility,
							access: selectedAccess,
							posting: selectedPosting,
							commenting: selectedCommenting,
							default: defaultChecked,
						};
						const responseCreateSpaceChannel = await createSpaceChannel(data);
						if (responseCreateSpaceChannel && responseCreateSpaceChannel.result) {
							// setOpenChannelForm(false);
							// setSelectedChannel(undefined);
							// setIsCreateChannelLoading(false);
							getChannels().then(r => {});
						} else {
							setIsCreateChannelLoading(false);
						}
					}
					if (spaceUsername && spaceUsername !== '')
						dispatch(getSpaceInfo(spaceUsername, null, true));
				}
			} catch (error) {
			}
		},
		[authUserInfo, spaceInfo, openChannelForm, selectedChannel, selectedVisibility, selectedAccess, selectedPosting, selectedCommenting, defaultChecked, isCreateChannelLoading]
	);

	const handleEditChannel = useCallback(
		(channel) => {
			setSelectedChannel(channel);
			// createChannelForm.setFieldsValue({
			// 	channel_name: channel.name
			// })
			setOpenChannelForm(true);
			setTimeout(() => {
				if (channelFormRef.current) {
					channelFormRef.current.scrollIntoView({ behavior: 'smooth' });
				}
			}, 300);
		},
		[createChannelForm, openChannelForm, channelFormRef]
	);

	const handleDisableChannels = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					setIsDisableChannelLoading(true);
					const data = {
						space_id: spaceInfo.id,
						channels_enable: false,
						user_id: authUserInfo.id,
						password: password
					};
					const responseEnableDisableChannels = await enableDisableChannels(data);
					if (responseEnableDisableChannels && responseEnableDisableChannels.result) {
						setIsChannelsEnable(false);
						setOpenChannelForm(false);
						setIsDisableChannelLoading(false);
						setSelectedChannel(undefined);
						disableChannelForm.resetFields();
					} else {
						setIsDisableChannelLoading(false);
						setAlert({ variant: 'danger', message: responseEnableDisableChannels.message });
					}
				}
			} catch (error) {
			}
		},
		[authUserInfo, spaceInfo, password, openChannelForm, selectedChannel, isChannelsEnable, isEnableChannelLoading]
	);

	const checkValidChannelData = useCallback(
		() => {
			if (selectedVisibility === 'default' || selectedAccess === 'default' || selectedPosting === 'default' || selectedCommenting === 'default') {
				return false;
			} else {
				return true
			}
		},
		[createChannelForm, selectedVisibility, selectedAccess, selectedPosting, selectedCommenting]
	);

	const handleRedirectToChannel = (guid) => {
		window.location.replace('/@' + spaceUsername + '/channel/' +guid);
	}

	useEffect(() => {
		createChannelForm.setFieldsValue({
			channel_name: ''
		})
		setSelectedVisibility('default');
		setSelectedAccess('default');
		setSelectedPosting('default');
		setSelectedCommenting('default');
		setDefaultChecked(false)
		if (selectedChannel) {
			createChannelForm.setFieldsValue({
				channel_name: selectedChannel.name
			})
			setSelectedVisibility(selectedChannel.visibility);
			setSelectedAccess(selectedChannel.access);
			setSelectedPosting(selectedChannel.posting);
			setSelectedCommenting(selectedChannel.commenting);
			setDefaultChecked(spaceInfo.default_channel_id.toString() === selectedChannel.id.toString())
		}
	}, [selectedChannel]);

	useEffect(() => {
		if (spaceInfo) {
			setIsChannelsEnable(spaceInfo.channels_enable);
			setSpaceUsername(spaceInfo.username);
			getChannels().then(r => {});
		}
	}, [authUserInfo, spaceInfo, getChannels]);

	return (
		<>
			<div className="manage-space grid grid-cols-1 gap-y-8">
				{/*Manage Channels Header Code Block*/}
				<div className="dashboard-wrapper grid grid-cols-1 gap-y-6 !rounded-3xl !p-6">
					<div className="grid grid-cols-1 gap-y-2">
						<div className="">
							<h3 className="header-medium">Manage Channels</h3>
						</div>
						<div className="">
							<p className="font-notoSansRegular text-lg">
								Create and manage access to your Channels here. Each Channel is a group feed where anyone with access can post and comment. All Members can view the Channels section, and each individual Channel has its own access settings. Channels will display in the order displayed below, which you can drag and drop to re-order.
							</p>
						</div>
					</div>
					{isChannelsEnable ? (
						<Button
							className="btn btn-secondary btn-md"
							// htmlType="submit"
							disabled={
								false
							}
							onClick={() => {
								handleCreateNewChannel();
							}}
						>
							{/*{welcomeSectionFormSubmitted && (*/}
							{/*    <i className="fa-solid fa-spinner fa-spin"></i>*/}
							{/*)}*/}
							Create New Channel
						</Button>
					) : (
						<Button
							className="btn btn-primary btn-md"
							htmlType="submit"
							disabled={
								false
							}
							onClick={() => {
								handleEnableChannels().then(r => {});
							}}
						>
							{/*{welcomeSectionFormSubmitted && (*/}
							{/*    <i className="fa-solid fa-spinner fa-spin"></i>*/}
							{/*)}*/}
							{isEnableChannelLoading && (
								<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
							)}
							Enable Channels
						</Button>
					)}
				</div>

				{/*Create Channel Code Block*/}
				{openChannelForm && (
					<Form
						form={createChannelForm}
						name="createChannelForm"
						validateMessages={VALIDATE_MESSAGES}
						onFinish={handleSaveNewChannel}
					>
						<div
							ref={channelFormRef}
							className="dashboard-wrapper grid grid-cols-1 gap-y-6 !rounded-3xl !p-6"
						>
							<div className="grid grid-cols-1 gap-y-6">
								{selectedChannel && (
									<div className="grid grid-cols-2 gap-8 items-center">
										<div className="col-span-1 grid grid-cols-2 gap-8">
											<div className="col-span-1 flex items-center">
												<h3 className="!font-din2014Bold !text-xl header-medium">
													{'#' + selectedChannel.name}
												</h3>
												<p className="ml-4 !font-notoSansRegular !text-base !text-[#888888] italic">
													{(spaceInfo.default_channel_id.toString() === selectedChannel.id.toString() ? "Default" : "")}
												</p>
											</div>
											<div className="col-span-1">
												<p className="font-notoSansRegular text-base">{selectedChannel.posts_count + " Posts"}</p>
											</div>
										</div>
										<div className="col-span-1 grid grid-cols-5 gap-8 items-center">
											<div className="col-span-2 flex gap-6 text-xl text-center leading-10 justify-between">
												{(selectedChannel.visibility === 'visible' || selectedChannel.visibility === 'default') ? (<i className="fa-regular fa-eye"></i>) : (<i className="fa-regular fa-eye-slash"></i>)}
												{(selectedChannel.access === 'all_members' || selectedChannel.access === 'default') ? (<i className="fa-regular fa-unlock"></i>) : (<i className="fa-regular fa-lock"></i>)}
												{(selectedChannel.posting === 'allow' || selectedChannel.posting === 'default') ? (<i className="fa-regular fa-subtitles"></i>) : (<i className="fa-regular fa-subtitles-slash"></i>)}
												{(selectedChannel.commenting === 'allow' || selectedChannel.commenting === 'default') ? (<i className="fa-regular fa-comment"></i>) : (<i className="fa-regular fa-comment-slash"></i>)}
											</div>
											<div className="col-span-1 text-xl leading-10">
												<div
													className="btn btn-md text-center btn-warning cursor-pointer"
													onClick={() => {
														handleRedirectToChannel(channel.guid);
													}}
												>
													<i className="fa-light fa-arrow-up-right-from-square"></i>
												</div>
											</div>
											<div className="col-span-1 text-xl leading-10">
												<div
													className="rounded-[50px] gradient-color-bg text-center text-white"
												>
													<i className="fa-light fa-money-check-pen"></i>
												</div>
											</div>
											<div className="col-span-1 text-[40px] leading-10">
												<div className="text-[#e7e7e7] w-full text-center">
													<i className="fa-light fa-grip-lines"></i>
												</div>
											</div>
										</div>
									</div>
								)}
								<div className="grid grid-cols-2 gap-8 items-center">
									<div className="header-medium !font-din2014Bold">
										{selectedChannel ? (
											<h3 className="!text-xl">Edit Channel</h3>
										) : (
											<h3 className="!text-2xl">Create New Channel</h3>
										)}
									</div>
									<div>
										<h3 className="font-notoSansRegular text-lg text-right">Channels allow your Members to interact with each other</h3>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-8 items-center">
									<div className="col-span-1">
										<div className="input-group-com w-full">
											<label className="body-text--small gradient-color-txt w-1/4 !text-lg !leading-10">
												Name
											</label>
											<Form.Item
												label="Name"
												name="channel_name"
												className="mb-0 hidden-label z-0"
												validateTrigger="onBlur"
												onChange={(e) => {
												}}
												rules={[
													{ required: true },
													{
														max: 24,
														message: 'Must not exceed 24 characters.'
													},
													{
														pattern: CUSTOM_REGEX.CHANNEL_NAME_VALIDATION,
														message: 'Invalid channel name.'
													}
												]}
											>
												<Input
													placeholder="Up to 24 characters, no spaces."
													className="input-md body-text--small color-reg"
													maxLength={24}
													disabled={false}
												/>
											</Form.Item>
										</div>
									</div>
									<div className="col-span-1">
										<p className="font-notoSansRegular text-base italic text-[#888888]">
											Your Channel name can contain letters, numbers, hyphens, and underscores, and must be 24 characters or less with no spaces.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-8 items-center">
									<div>
										<h3 className="header-medium !font-din2014Bold !text-xl">Channel Permissions</h3>
									</div>
									<div>
										<h3 className="font-notoSansRegular text-lg text-right">Permissions control access and actions to the Channel</h3>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-8 items-center">
									<div className="col-span-1">
										<DropdownWithItems
											title="Channel Visibility"
											dropDownClass=""
											customButtonClass="payout"
											icon="fa-light fa-eye"
											selectedValue={selectedVisibility}
											listItems={SPACE_CHANNEL_VISIBILITY}
											onDropdownChange={(value) => {
												setSelectedVisibility(value)
											}}
										/>
									</div>
									<div className="col-span-1">
										<p className="font-notoSansRegular text-base italic text-[#888888]">
											Visible Channels can be seen by all Members in the Channel List. Hidden only allows Members with Access to see the Channel Name.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-8 items-center">
									<div className="col-span-1">
										<DropdownWithItems
											title="Channel Access"
											dropDownClass=""
											customButtonClass="payout"
											icon="fa-light fa-unlock"
											selectedValue={selectedAccess}
											listItems={SPACE_CHANNEL_ACCESS}
											onDropdownChange={(value) => {
												setSelectedAccess(value)
											}}
										/>
									</div>
									<div className="col-span-1">
										<p className="font-notoSansRegular text-base italic text-[#888888]">
											All Members Access allows anyone with any Membership to have access. Custom Access locks the Channel to specific Memberships only.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-8 items-center">
									<div className="col-span-1">
										<DropdownWithItems
											title="Channel Posting"
											dropDownClass=""
											customButtonClass="payout"
											icon="fa-light fa-subtitles"
											selectedValue={selectedPosting}
											listItems={SPACE_CHANNEL_POSTING}
											onDropdownChange={(value) => {
												setSelectedPosting(value)
											}}
										/>
									</div>
									<div className="col-span-1">
										<p className="font-notoSansRegular text-base italic text-[#888888]">
											Allow Posting lets any Member with Access post to the Channel. Disallow restricts Members from posting and only allows your Team to post.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-8 items-center">
									<div className="col-span-1">
										<DropdownWithItems
											title="Channel Commenting"
											dropDownClass=""
											customButtonClass="payout"
											icon="fa-light fa-comment"
											selectedValue={selectedCommenting}
											listItems={SPACE_CHANNEL_COMMENTING}
											onDropdownChange={(value) => {
												setSelectedCommenting(value)
											}}
										/>
									</div>
									<div className="col-span-1">
										<p className="font-notoSansRegular text-base italic text-[#888888]">
											Allow Commenting lets any Member with Access comment on a Post. Disallow restricts Members from commenting on Channel Posts.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-8 items-center">
									<div className="col-span-1">
										<Checkbox
											checked={defaultChecked}
											className="inline-flex form-check-input items-center ml-2 mr-10 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={(e)=>{
												setDefaultChecked(e.target.checked);
											}}
										></Checkbox>
										<label className="form-check-label body-text--small !font-notoSansRegular !text-base">
											Set as Default Channel
										</label>
									</div>
									<div className="col-span-1">
										<p className="font-notoSansRegular text-base italic text-[#888888]">
											Your Default Channel is where all Members automatically start when visiting the Channels section, and must have Access set to All Members.
										</p>
									</div>
								</div>
							</div>
							<Form.Item>
								{selectedChannel ? (
									<div className="grid grid-cols-2 gap-8 items-center">
										<div className="col-span-1">
											<Button
												className="btn btn-primary btn-md"
												htmlType="submit"
												disabled={
													isCreateChannelLoading
												}
											>
												{isCreateChannelLoading && (
													<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
												)}
												Save Channel
											</Button>
										</div>
										<div className="col-span-1">
											<Button
												className="btn btn-secondary btn-md"
												onClick={() => {
													setSelectedChannel(undefined);
													setOpenChannelForm(false);
												}}
												disabled={
													isCreateChannelLoading
												}
											>
												{/*{isCreateChannelLoading && (*/}
												{/*	<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>*/}
												{/*)}*/}
												Cancel
											</Button>
										</div>
									</div>
								) : (
									<Button
										className="btn btn-primary btn-md"
										htmlType="submit"
										disabled={
											isCreateChannelLoading || !checkValidChannelData()
										}
									>
										{isCreateChannelLoading && (
											<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
										)}
										Save New Channel
									</Button>
								)}
							</Form.Item>
						</div>
					</Form>
				)}

				{/*Channel lists code block*/}
				{(channels && channels.length > 0) && (
					<>
						{channels.map((channel, index) => (
							(channel.id !== ((selectedChannel && selectedChannel?.id) ? selectedChannel.id : null)) && (
								<div
									className="dashboard-wrapper grid grid-cols-1 gap-y-6 !rounded-3xl !p-6"
									key={index}
								>
									<div className="grid grid-cols-1 gap-y-6">
										<div className={"grid grid-cols-2 gap-8 items-center" + (isChannelsEnable ? "" : " !text-[#e7e7e7]")}>
											<div className="col-span-1 grid grid-cols-2 gap-8">
												<div className="col-span-1 flex items-center">
													<h3 className={"!font-din2014Bold !text-xl" + (isChannelsEnable ? " header-medium" : " !text-[#e7e7e7]")}>
														{'#' + channel.name}
													</h3>
													<p className={"ml-4 !font-notoSansRegular !text-base italic" + (isChannelsEnable ? " !text-[#888888]" : " !text-[#e7e7e7]")}>
														{(spaceInfo.default_channel_id.toString() === channel.id.toString() ? "Default" : "")}
													</p>
												</div>
												<div className="col-span-1">
													<p className="font-notoSansRegular text-base">{channel.posts_count + " Posts"}</p>
												</div>
											</div>
											<div className="col-span-1 grid grid-cols-5 gap-8 items-center">
												<div className="col-span-2 flex gap-6 text-xl text-center leading-10 justify-between">
													{(channel.visibility === 'visible' || channel.visibility === 'default') ? (<i className="fa-regular fa-eye"></i>) : (<i className="fa-regular fa-eye-slash"></i>)}
													{(channel.access === 'all_members' || channel.access === 'default') ? (<i className="fa-regular fa-unlock"></i>) : (<i className="fa-regular fa-lock"></i>)}
													{(channel.posting === 'allow' || channel.posting === 'default') ? (<i className="fa-regular fa-subtitles"></i>) : (<i className="fa-regular fa-subtitles-slash"></i>)}
													{(channel.commenting === 'allow' || channel.commenting === 'default') ? (<i className="fa-regular fa-comment"></i>) : (<i className="fa-regular fa-comment-slash"></i>)}
												</div>
												<div className="col-span-1 text-xl leading-10">
													<div
														className={"btn btn-md text-center cursor-pointer" + (isChannelsEnable ? " btn-warning" : " btn-warning-disable")}
														onClick={() => {
															handleRedirectToChannel(channel.guid);
														}}
													>
														<i className="fa-light fa-arrow-up-right-from-square"></i>
													</div>
												</div>
												<div className="col-span-1 text-xl leading-10">
													<div
														className={"btn btn-md text-center cursor-pointer" + (isChannelsEnable ? " btn-warning" : " btn-warning-disable")}
														onClick={() => {
															handleEditChannel(channel);
														}}
													>
														<i className="fa-light fa-money-check-pen"></i>
													</div>
												</div>
												<div className="col-span-1 text-[40px] leading-10">
													<div className="text-[#e7e7e7] w-full text-center">
														<i className="fa-light fa-grip-lines"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						))}
					</>
				)}

				{/*Disable Channels Code Block*/}
				{(isChannelsEnable && channels && channels.length > 0) && (
					<>
						<Form
							form={disableChannelForm}
							name="disableChannelForm"
							validateMessages={VALIDATE_MESSAGES}
							onFinish={handleDisableChannels}
						>
							<div className="dashboard-wrapper grid grid-cols-1 gap-y-6 !rounded-3xl !p-6">
								<div className="grid grid-cols-1 gap-y-6">
									<div className="grid grid-cols-2 gap-8 items-center">
										<div>
											<h3 className="header-medium !font-din2014Bold !text-2xl">Disable Channels</h3>
										</div>
										<div>
											<h3 className="font-notoSansRegular text-lg text-right">Disabling the Channels section will not delete any content</h3>
										</div>
									</div>
									<div className="grid grid-cols-2 gap-8">
										<div className="col-span-1">
											<div className="input-group-com w-full">
												<label className="body-text--small gradient-color-txt w-1/4 !text-lg !leading-10">
													Password
												</label>
												<Form.Item
													label="Password"
													name="channel_password"
													className="mb-0 hidden-label z-0"
													validateTrigger="onBlur"
													onChange={(e) => {
														setPassword(e.target.value);
														setAlert(null);
													}}
													rules={[
														{ required: true }
													]}
												>
													<Input
														type="password"
														placeholder="Enter password to disable"
														className="input-md body-text--small color-reg"
														disabled={false}
													/>
												</Form.Item>
											</div>
										</div>
										<div className="col-span-1">
											{(password !== "") ? (
												<Button
													className="btn btn-primary btn-md"
													htmlType="submit"
													disabled={
														false
													}
												>
													Disable Channels
												</Button>
											) : (
												<div
													className="btn btn-warning btn-md text-center"
													onClick={() => {
														// handleDisableChannels();
													}}
												>
													{isDisableChannelLoading && (
														<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
													)}
													Disable Channels
												</div>
											)}
											{alert && (
												<Alert
													key={alert.variant}
													variant={alert.variant}
													className={
														'rounded-full custom-alert ' +
														(alert.variant === 'danger'
															? 'custom-alert-danger'
															: 'custom-alert-success')
													}
												>
													{alert.message}
												</Alert>
											)}
										</div>
									</div>
								</div>
							</div>
						</Form>
					</>
				)}
			</div>
		</>
	);
};

export default memo(ManageChannelsPage);
