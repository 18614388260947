import React, {memo, useState} from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";

const StripeOnboarding = (props) => {

    const {userID, spaceID, setIsOnBoardingSubmitted } = props;

    // We use `useState` to ensure the Connect instance is only initialized once
    const [stripeConnectInstance] = useState(() => {
        const formData = new FormData();
        // TODO: change to use selected user
        formData.append('user_id', userID);
        formData.append('space_id', spaceID);

        const fetchClientSecret = async () => {
            // Fetch the AccountSession client secret
            const response = await fetch(
                `${process.env.REACT_APP_API_DOMAIN}/api/payment_gateway/account_session/create`,
                { method: 'POST', body: formData }
            );
            // console.log(response);
            if (!response.ok) {
                // Handle errors on the client side here
                const { error } = await response.json();
                // console.error('An error occurred: ', error);
                document.querySelector('#error').removeAttribute('hidden');
                return undefined;
            } else {
                const { client_secret: clientSecret } = await response.json();
                // document.querySelector('#error').setAttribute('hidden', '');
                // console.log(clientSecret);
                return clientSecret;
            }
        };

        return loadConnectAndInitialize({
            // This is your test publishable API key.
            publishableKey:
                'pk_test_51LPByuBJiyP9BsbcFadFpBIwMzseG8IkSMAlCVO6etcxhUC9X4TDtjFMdK3GlokwqeEdmwaKpMfr6Aq8DD3R1hMz00gTsLnzoT',
            fetchClientSecret: fetchClientSecret
        });
    });

    return (
        <>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                <ConnectAccountOnboarding
                    onExit={() => {
                        console.log('The account has exited onboarding');
                        setIsOnBoardingSubmitted(true);
                    }}
                    // Optional: make sure to follow our policy instructions above
                    // fullTermsOfServiceUrl="{{URL}}"
                    // recipientTermsOfServiceUrl="{{URL}}"
                    // privacyPolicyUrl="{{URL}}"
                    // skipTermsOfServiceCollection={false}
                    // collectionOptions={{
                    //   fields: 'eventually_due',
                    //   futureRequirements: 'include',
                    // }}
                />
            </ConnectComponentsProvider>
        </>
    );
}

export default memo(StripeOnboarding);