import { memo, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import { getMyCollections } from '../../../services/user';
import routes from '../../../routes';
import CollectionItem from './CollectionItem';
import {getSitewideLink} from "../../../utils/sitewideLinks";

const CollectionsContent = () => {
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [initialLoad, setInitialLoad] = useState(true);
	const [selectedTab, setSelectedTab] = useState(routes.spaces());
	const [activeTab, setActiveTab] = useState('collections');
	const [collections, setCollections] = useState(null);
	const [collectionsCount, setCollectionsCount] = useState(0);
	const [walletsCount, setWalletsCount] = useState(0);
	const [customsCount, setCustomsCount] = useState(0);

	const renderCollectionViewUrl = (space) => {
		const url = routes.viewCollection();
		return url.replace(':space_username', space.username);
	};

	const getCollections = useCallback(async () => {
		try {
			const params = {
				user_id: authUserInfo.id
			};

			const response = await getMyCollections(params);
			if (response && response.result) {
				setCollectionsCount(response.collections_count);
				setWalletsCount(response.wallets_count);
				setCustomsCount(response.custom_count);
				setCollections(response.collections);
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo]);

	useEffect(() => {
		if (authUserInfo && initialLoad) {
			getCollections().then((r) => {});
			setInitialLoad(false);
		}

		setSelectedTab(currentLocation.pathname);
		document.title = 'Commonspace Spaces';
	}, [authUserInfo, currentLocation.pathname, initialLoad]);

	return (
		<>
			<div className="dashboard-wrapper">
				<div className="grid grid-cols-12  md:gap-8">
					<div className="col-span-10 flex items-center h-[75px] md:h-[48px]">
						<h3 className="header-medium">Your Collections</h3>
					</div>
					<div className="col-span-2  w-[42px] md:w-[80px] grid  items-center justify-self-end ">
						<a className="btn btn-secondary btn-md">
							<i className="fa-light fa-plus"></i>
							
						</a>
					</div>
				</div>
				<p className="pt-3 body-txtitalic--small">
					Collections are groups of your Items and Membership Keys. You can create your
					own custom Collections and you also have automatically created Collections for
					your Spaces and any Wallets you have connected.{' '}
					{getSitewideLink('learn_more_collections')}.
					{/*<a className="gradient-color-txt" href="">*/}
					{/*	Learn more about Collections, Items, and Membership Keys.*/}
					{/*</a>*/}
				</p>
				<ul className="nav nav-pills mt-5 grid grid-flow-row grid-rows-3 grid-cols-1 md:grid-cols-3 md:grid-rows-1">
					<li className="nav-item !p-0 col-span-1" role="presentation">
						<Button
							className={'!w-full nav-link ' + (activeTab === 'collections' ? 'active' : '')}
							onClick={() => {
								setActiveTab('collections');
							}}
						>
							{collectionsCount} Collections
						</Button>
					</li>
					<li className="nav-item !p-0 col-span-1" role="presentation">
						<Button
							className={'!w-full nav-link ' + (activeTab === 'wallets' ? 'active' : '')}
							onClick={() => {
								setActiveTab('wallets');
							}}
						>
							{walletsCount} Spaces & Wallets Only
						</Button>
					</li>
					<li className="nav-item !p-0 col-span-1" role="presentation">
						<Button
							className={'!w-full nav-link ' + (activeTab === 'custom' ? 'active' : '')}
							onClick={() => {
								setActiveTab('custom');
							}}
						>
							{customsCount} Custom Only
						</Button>
					</li>
				</ul>
			</div>
			<div>
				{authUserInfo && (
					<>
						{activeTab === 'collections' && (
							<>
								{collections &&
									collections.map((data, index) => (
										<CollectionItem
											key={index}
											data={data}
											type={'active'}
											urlRedirect={renderCollectionViewUrl(data)}
											currentUser={authUserInfo}
										></CollectionItem>
									))}
							</>
						)}
						{/*{activeTab === 'expired' && (*/}
						{/*	<>*/}
						{/*		{expiredSpaces &&*/}
						{/*			expiredSpaces.map((data, index) => (*/}
						{/*				<SpaceItem*/}
						{/*					key={index}*/}
						{/*					data={data}*/}
						{/*					type={'expired'}*/}
						{/*					currentUser={authUserInfo}*/}
						{/*				></SpaceItem>*/}
						{/*			))}*/}
						{/*	</>*/}
						{/*)}*/}
						{/*{activeTab === 'manage' && (*/}
						{/*	<>*/}
						{/*		{ownSpaces &&*/}
						{/*			ownSpaces.map((data, index) => (*/}
						{/*				<SpaceItem*/}
						{/*					key={index}*/}
						{/*					data={data}*/}
						{/*					type={'manage'}*/}
						{/*					currentUser={authUserInfo}*/}
						{/*				></SpaceItem>*/}
						{/*			))}*/}
						{/*	</>*/}
						{/*)}*/}
					</>
				)}
			</div>
		</>
	);
};

export default memo(CollectionsContent);
