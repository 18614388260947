import { IMAGE_FILETYPES, VIDEO_FILETYPES } from '../constants/file';

export const fileToBase64 = (file, cb) => {
	let reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		cb(reader.result);
	};
	reader.onerror = function (error) {
		console.log('Error: ', error);
	};
};

export const getBackgroundFileTypeFromUrl = (url) => {
	const type = url.split(/[#?]/)[0].split('.').pop().trim();
	const type_with_dot = '.' + type;
	if (IMAGE_FILETYPES.includes(type_with_dot)) {
		return 'image';
	}
	if (VIDEO_FILETYPES.includes(type_with_dot)) {
		return 'video';
	}
};

export const formatFileSize = (bytes, decimalPoint) => {
	if (bytes === 0) return '0 Bytes';
	let k = 1000,
		dm = decimalPoint || 2,
		sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const convertUrlToFile = async (url) => {
	console.log(url)
	const filename = 'image.jpg'; // replace with your desired filename
	const mimeType = 'image/jpeg'; // replace with the correct MIME type of your image
	const res = await fetch(url);
	const blob = await res.blob();
	const file = new File([blob], filename, {type: mimeType});
	console.log('file: ' + file);
	return file;
};
