import React, {memo, useCallback, useEffect, useState} from "react";
import {Button, Form} from "antd";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {MEMBERSHIP_DATA_SHARING} from "../../../constants/space";
import {useDispatch, useSelector} from "react-redux";
import '../SpaceBuyMembership.scss'
import {
    getSpaceMembership,
    postUserSpaceMembershipClaimAndSetupSharing,
} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import routes from "../../../routes";
import {getLocalStorage} from "../../../utils/storage";
import {getAuthUserInfo} from "../../../states/general/actions";
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {getUserSpaceMembershipClaim} from "../../../services/user";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";

const MembershipPaymentCompleted = () => {
    const [membershipPaymentCompleted] = Form.useForm();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [isMembershipLoaded, setMembershipLoaded] = useState(false);
    const [membershipDetails, setMembershipDetails] = useState(null);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isPaid, setIsPaid] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
    const currentLocation = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const membershipId = searchParams.get('id');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initialLoad, setInitialLoad] = useState(true);
    const [selectedFirstName, setSelectedFirstName] = useState('share');
    const [selectedBirthDate, setSelectedBirthDate] = useState('share');
    const [selectedCity, setSelectedCity] = useState('share');
    const [selectedEmail, setSelectedEmail] = useState('share');
    const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
    const [isMembershipClaimLoaded, setMembershipClaimLoaded] = useState(false);

    let space_username = null;

    const path = currentLocation.pathname;
    if (path && path.startsWith('/@') && path.length > 3) {
        const clean_path = path.replace('/@', '');
        const path_exp = clean_path.split('/');
        space_username = path_exp[0];
    }

    const getMembership = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id
        }

        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }

        const response = await getSpaceMembership(data);
        if (response && response.result) {
            const membershipData = response.membership;
            setMembershipDetails(membershipData);
            setMembershipLoaded(true);
            setIsPaid(membershipData.is_paid);
            if (membershipData.photo || membershipData.video) {
                if (membershipData.photo) {
                    const bg = {
                        backgroundImage: 'url(' + membershipData.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '32px',
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (membershipData.video) {
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '32px',
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [membershipDetails, authUserInfo]);

    const getMembershipClaim = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id
        }

        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }

        const response = await getUserSpaceMembershipClaim(data);
        if (response) {
            if (response.result) {
                if (response.is_exist) {
                    let url = routes.spaceMembershipActivate();
                    const fin_url =
                        url.replace(':space_username', '@' + space_username) +
                        '?id=' +
                        membershipId;
                    // navigate(fin_url);
                }
            }
            setMembershipClaimLoaded(true);
        }
    }, [membershipDetails, authUserInfo]);



    const handleFormOnBlur = useCallback(async () => {
        await membershipPaymentCompleted
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSubmit = useCallback(
        async (values) => {
            setAlert(null);
            setSubmitted(true);

            if (!selectedFirstName) {
                return;
            }

            if (!selectedBirthDate) {
                return;
            }

            if (!selectedCity) {
                return;
            }

            if (!selectedEmail) {
                return;
            }

            const data = values;
            data.space_id = spaceInfo.id;
            data.membership_id = membershipId;
            data.share_name = selectedFirstName === 'share';
            data.share_birthdate = selectedBirthDate === 'share';
            data.share_city = selectedCity === 'share';
            data.share_email = selectedEmail === 'share';

            if (values.birthdate) {
                data.birthdate = values.birthdate.format('YYYY-MM-DD');
            }

            data.wallet_contract_address = getLocalStorage(
                'temp_membership_wallet_owner_contract_address'
            );

            // const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
            // const isSubscription = membershipDetails.enable_expiration_and_subscription;

            if (authUserInfo) {
                data.user_id = authUserInfo.id;
                const response = await postUserSpaceMembershipClaimAndSetupSharing(data);

                if (response) {
                    if (response.result) {

                        let url = routes.spaceMembershipActivate();
                        // if (membershipDetails.collection_type === 'membership') {
                        //     url = routes.spaceMembershipActivate()
                        // }
                        const fin_url =
                            url.replace(':space_username', '@' + space_username) +
                            '?id=' +
                            membershipId;
                        navigate(fin_url);
                    } else {
                        if (response.message === 'Already joined membership.') {
                            const url = routes.spaceMembershipActivate()
                            const fin_url =
                                url.replace(':space_username', '@' + space_username) +
                                '?id=' +
                                membershipId;
                            navigate(fin_url);
                        }
                    }
                } else {
                    console.log('MembershipClaimAndSetupSharing-response: '+JSON.stringify(response));
                }
            }
            setSubmitted(false);
        },
        [
            authUserInfo,
            spaceInfo,
            selectedFirstName,
            selectedBirthDate,
            selectedCity,
            selectedEmail,
            membershipId,
            membershipDetails
        ]
    );


    useEffect(() => {
        if (membershipDetails != null) {
            setMembershipLoaded(false);
        }
        if (authUserInfo===null) {
            dispatch(getAuthUserInfo());
        }
        if (space_username && !isMembershipLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getMembership(spaceInfo.id, membershipId).then((r) => {
                    });
                    // setSpaceName(spaceInfo.name);
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(space_username));
                }
                if (spaceInfoLoading === 'failed') {
                    navigate(routes.createSpace());
                }
            }
        }

        if (membershipDetails != null) {
            if (authUserInfo != null && !loadMembershipOnce) {
                setLoadMembershipOnce(true);
                getMembership(spaceInfo.id, membershipId).then((r) => {});
            }

            // Redirect to details if already claim or paid
            if (authUserInfo != null) {
                getMembershipClaim(spaceInfo.id, membershipId).then(r =>{});
            }
        }

        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        space_username,
        membershipId,
        membershipDetails,
        isMembershipClaimLoaded
    ]);

    return (
        <>
            <main id="membership-buy" className="flex">
                {!spaceInfo || !authUserInfo || !isMembershipClaimLoaded ? (
                    <div className="loading-items w-full items-center mt-8">
                        <i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
                    </div>
                ) : (
                    <>
                        <div className="margin-left-15 xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
                            <div className="grid margin-right-0">
                                <div className="grid grid-cols-2 pr-0">
                                    {/*new UI structure*/}
                                    <div className="col-span-2 grid grid-cols-2 ps-0 gap-4">
                                        <div className="lg:col-span-1 xsm:col-span-2">
                                            <h1 className="mb-4 mt-5 header-large">
                                                Payment Completed.<br/>
                                                Your Membership is ready!
                                            </h1>
                                            <p className="body-text--reg">
                                                You’ve successfully purchased {' '}
                                                <a href={membershipDetails?.type === "nft_collection" ?
                                                    routes.spaceMembersProductsMembership().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + membershipId :
                                                    routes.spaceMembershipBuy().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + membershipId} className="gradient-color-txt">
                                                    {membershipDetails ? membershipDetails.name : ''}
                                                </a>
                                                {' '}for{' '}
                                                <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                </a>
                                                {' '}. To activate it, complete the details below:
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-span-2 grid lg:grid-cols-2 md:grid-cols-1 xsm:gap-10 lg:gap-30 ">

                                        <div className="lg:hidden md:flex">
                                            {membershipItemImage(collectionAssetType, membershipDetails)}
                                        </div>

                                        <div className="lg:col-span-1">
                                            <h3 className="mb-4 mt-5 header-medium">
                                                Data Sharing with <i className="fa-solid fa-solar-system"/> {spaceInfo ? spaceInfo.name : ''}
                                            </h3>
                                            <p className="body-text--reg">
                                                Your membership for {' '}
                                                <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                </a>
                                                {' '}includes access, updates,
                                                benefits, and more. To get full access to
                                                everything {' '}<i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                {' '}offers, it’s recommended you share your Account Details with their
                                                management team. This information is never shared publicly.
                                            </p>
                                            <Form
                                                form={membershipPaymentCompleted}
                                                name="membershipPaymentCompleted"
                                                id="membershipPaymentCompleted"
                                                validateMessages={VALIDATE_MESSAGES}
                                                onFocus={() => {
                                                    setFormIsOnFocus(true);
                                                    setAlert(null);
                                                }}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        setFormIsOnFocus(false);
                                                        handleFormOnBlur();
                                                    }, 200);
                                                }}
                                                autoComplete="off"
                                                onFinish={handleSubmit}
                                                className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
                                            >

                                                <div className="grid grid-cols-12 mt-5 items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            First & Last Name
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary "
                                                                title="First & Last Name"
                                                                selectedValue={selectedFirstName}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedFirstName(value)
                                                                }
                                                            />
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            Birthdate
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary"
                                                                title="Birthdate"
                                                                selectedValue={selectedBirthDate}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedBirthDate(value)
                                                                }
                                                            />
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            City
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary"
                                                                title="City"
                                                                selectedValue={selectedCity}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedCity(value)
                                                                }
                                                            />
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            Email Address
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary"
                                                                title="Email"
                                                                selectedValue={selectedEmail}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedEmail(value)
                                                                }
                                                            />
                                                    </div>
                                                </div>

                                                <div className="input-group-com my-5">
                                                    <Form.Item className="mb-0">
                                                        <Button
                                                            className="btn btn-primary btn-lg"
                                                            htmlType="submit"
                                                            disabled={submitted}
                                                        >
                                                            {submitted && (
                                                                <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                            )}
                                                            Save & Activate
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="lg:inline xsm:hidden p-0">
                                            {membershipItemImage(collectionAssetType, membershipDetails)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="left-image-holder"></div>
                                </div>
                            </div>
                        </div>
                    </>
                    )
                }
            </main>
        </>
    );
}

export default memo(MembershipPaymentCompleted);