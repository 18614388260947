import { memo, useEffect } from 'react';
import DashboardContent from '../Dashboard/DashboardContent';

const Spaces = () => {
	useEffect(() => {
		document.title = 'Commonspace Spaces';
	}, []);

	return (
		<main id="dashboard" className=" flex">
			<div className="container mx-auto min-h-screen md:min-h-[540px]">
				<DashboardContent />
			</div>
		</main>
	);
};

export default memo(Spaces);
