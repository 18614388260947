import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'antd';

import routes from '../../../routes';
import { createSpaceLaunchStatus } from '../../../services/space';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';

const SpaceLaunch = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [alert, setAlert] = useState(null);

	const proceedToNextStep = useCallback(() => {
		const url = routes.createSpaceLaunchCheckList();
		navigate(url.replace(':space_username', space_username));
	}, [space_username, authUserInfo, spaceInfo]);

	const goToSpace = useCallback(() => {
		const url = routes.spaceDashboard();
		navigate(url.replace(':space_username', '@' + space_username));
	}, [space_username]);

	// const handleSubmit = useCallback(async () => {
	// 	try {
	// 		if (authUserInfo && spaceInfo) {
	// 			const data = {
	// 				user_id: authUserInfo.id,
	// 				space_id: spaceInfo.id
	// 			};
	//
	// 			const response = await createSpaceLaunchStatus(data);
	// 			if (response && response.result) {
	// 				const url = routes.createSpaceAddBusiness();
	// 				navigate(url.replace(':space_username', space_username));
	// 			} else {
	// 				setAlert({
	// 					variant: 'danger',
	// 					message: response.message
	// 				});
	// 			}
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }, [authUserInfo, spaceInfo]);

	useEffect(() => {
		document.title = 'Commonspace Spaces';

		if (!spaceInfo && initialLoad) {
			setInitialLoad(false);
			dispatch(getSpaceInfo(space_username));
		}
	}, [space_username, authUserInfo, spaceInfo, spaceInfoLoading]);

	return (
		<>
			<main id="member-profile" className="flex">
				<div className="container !py-20">
					<div className="grid grid-cols-1">
						<div className="col-span-1 flex items-center">
							<h1 className="mb-4 mt-5 header-large">
								Your Space is ready for launch!
							</h1>
						</div>
						<div className="col-span-1">
							<p className="body-text--reg">
								Now that you’ve created your Space and set up your first memberships
								and content, your Space is ready to launch! You can further
								customize and add Memberships, Items, Updates, and Benefits from
								your Dashboard, or you can complete the Launch Sequence steps to
								finalize and launch your Space now.
							</p>
						</div>
					</div>

					<div className="grid grid-cols-12 gap-8">
						<div className="col-span-12">
							<>
								{alert && (
									<Alert
										key={alert.variant}
										variant={alert.variant}
										className={
											'custom-alert ' +
											(alert.variant === 'danger'
												? 'custom-alert-danger'
												: 'custom-alert-success')
										}
									>
										{alert.message}
									</Alert>
								)}
							</>
						</div>
					</div>

					<div className={'grid grid-cols-1 md:grid-cols-2 gap-3 ' + (!alert ? 'mt-5' : 'mt-2')}>
						<div className="md:col-span-1">
							<Button
								className="btn btn-lg btn-secondary"
								onClick={() => goToSpace()}
							>
								Visit Dashboard
							</Button>
						</div>
						<div className="md:col-span-1 flex items-end">
							<Button
								className="btn btn-lg btn-primary"
								onClick={() => proceedToNextStep()}
							>
								Begin Launch Sequence
							</Button>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(SpaceLaunch);
