import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Button, Form, Input} from 'antd';
import Alert from 'react-bootstrap/Alert';

import {VALIDATE_MESSAGES} from '../../constants/common';
import default_bg_img from '../../assets/images/dj_party.png';
import dj_bg_img from '../../assets/images/dj-party-v2.png';
import routes from '../../routes';
import {emailForgotPassword, getForgotEmailVerification} from "../../services/user";
import ResetPassword from "./ResetPassword";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [alert, setAlert] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const {size} = useSelector((state) => state.classSize);
    const [margin, setMargin] = useState();
    const [params] = useSearchParams();
    const [email, setEmail] = useState();
    const [resultMessage, setResultMessage] = useState('');
    const token = params.get('token');

    const [verifying, setVerifying] = useState(false);
    const [verified, setVerified] = useState(false);
    const [verifiedError, setVerifiedError] = useState(false);
    const navigate = useNavigate();
    const statusTypes = {
        forgot: 'forgot',
        reset: 'reset',
        link: 'link',
        expired: 'expired'
    };

    const [statusType, setStatusType] = useState(statusTypes.forgot); //forgot, reset, link

    const handleFormOnBlur = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleFormChanges = useCallback(async () => {
        setAlert(null);

        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSubmit = useCallback(async (values) => {
        try {
            setSubmitted(true);
            values.email = values.email.toLowerCase();
            const response = await emailForgotPassword(values);
            if (response && response.result) {
                setStatusType(statusTypes.link);
                setSubmitted(false);
            } else {
                setSubmitted(false);
                setAlert({ variant: 'danger', message: response.message });
            }
        } catch (error) {
            setSubmitted(false);
            console.log(error);
        }
    }, []);

    const verifyEmail = useCallback(
        async (token) => {
            try {
                setVerified(false);
                await getForgotEmailVerification({ token }).then((result) => {
                    if (result && result.result) {
                        setVerified(true);
                        setEmail(result.email);
                        setResultMessage(result.message);
                    } else {
                        if (result.is_expired)
                            setStatusType(statusTypes.expired);
                        else {
                            setVerifiedError(true)
                            setAlert({ variant: 'danger', message: result.message });
                        }
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        [setEmail]
    );

    const goBackToRequestPassword = () => {
        navigate(routes.forgotPassword())
        setStatusType(statusTypes.forgot);
    }

    useEffect(() => {
        document.title = 'Commonspace Forgot Password';
        setMargin(() => {
            if (size !== 'lg') {
                return '4';
            } else {
                return '5';
            }
        });

        if (token && token !== '' && !verifying && !email) {
            setStatusType(statusTypes.reset)
            setVerifying(true);
            if (token) {
                setTimeout(() => {
                    verifyEmail(token).then();
                }, 1000);
            } else {
                setResultMessage('Email verification token is required.');

            }
        }

    }, [token, verifyEmail, email, verifying, verified, statusType]);

    return (
        <div className=" my-16 h-full items-center">
			<div className='grid grid-cols-12 gap-4'>
				<div className='col-span-12 lg:col-span-6  lg:ml-10 '>
					<div className=' h-full flex justify-center lg:justify-end'>
						<div className='w-full px-10 md:px-0 md:w-[664px] flex items-center'>
							<div className='content  '>
							<div className="grid grid-cols-1 font-['din2014Bold'] mb-2">
								 <h1 className="header-large">Forgot Password?</h1>
							</div>
							<div className='grid grid-cols-1'>
                            {statusType === statusTypes.reset && verified && (
                                <h5 className='text-xl'>
                                    Enter a new password below to change your password.
                                </h5>
                            )}

                            {statusType === statusTypes.reset && !verified && (
                                <>
                                    <h1 className="mb-4 mt-8">Verifying</h1>
                                    <h5 className='text-xl'>Please wait...</h5>
                                </>
                            )}
                            {statusType === statusTypes.reset && !verified && verifiedError && (
                                <>
                                    <h1 className="mb-4 mt-8">Oops!</h1>
                                    <h5>{resultMessage}</h5>
                                </>
                            )}

                            {statusType !== statusTypes.reset && (
                                <h5 className='text-xl font-[notoSansRegular] mb-8'>
                                    If you have an existing Commonspace Account or an existing Account on a
                                    community site powered by Commonspace, then you can reset your password here.
                                </h5>
                            )}

                            {statusType === statusTypes.link  && (
                                <div className="dialog-box mt-8">
                                    <h3 className="text-center font-[din2014Bold]">
                                        Password Reset Requested!
                                    </h3>
                                    <p className="text-center font-[notoSansRegular]">
                                        If your email has an account associated with it, you will receive an email to reset your password.{' '}
                                        <span className="fst-italic">
                                            If you do not see it within 5 minutes, please check your spam folder.
                                        </span>
                                    </p>
                                </div>
                            )}

                            {statusType === statusTypes.expired && (
                                <div className="dialog-box mt-8">
                                    <h3 className="text-center">
                                        Error!
                                    </h3>
                                    <p className="text-center">
                                        Sorry, the password reset link has expired.
                                    </p>
                                </div>
                            )}
							</div>
							<div className='grid grid-cols-1'>
							{statusType === statusTypes.forgot && (
                            <Form
                            form={form}
                            name="loginForm"
                            validateMessages={VALIDATE_MESSAGES}
                            onFocus={() => {
                                setFormIsOnFocus(true);
                            }}
                            onBlur={() => {
                                setFormIsOnFocus(false);
                                handleFormOnBlur();
                            }}
                            autoComplete="off"
                            onChange={handleFormChanges}
                            onFinish={handleSubmit}
                            className={formIsOnFocus ? 'hide-antd-error-messages  !z-0' : '!z-0 '}
                        >
                            {alert && (
                                <>
                                    <Alert
                                        key={alert.variant}
                                        variant={alert.variant}
                                        className={
                                            'custom-alert ' +
                                            (alert.variant === 'danger'
                                                ? 'custom-alert-danger'
                                                : 'custom-alert-success')
                                        }
                                    >
                                        {alert.message}
                                    </Alert>
                                </>
                            )}

                            <div
                                className={
                                    'input-group-com input-with-btn ' +
                                    (alert ? 'mt-4' : 'mt-8') +
                                    ' mb-8'
                                }
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    className="mb-0 hidden-label email-input !z-0"
                                    validateTrigger="onBlur"
                                    rules={[{ required: true, type: 'email' }]}
                                >
                                    <Input
                                        className={'gradient-placeholder py-1.5 px-3 !z-0 input-' + size}
                                        disabled={submitted}
                                        placeholder="Enter email"
                                    />
                                </Form.Item>

                                <Form.Item className="mb-0 hidden lg:block flex !justify-end !w-[200px]">
                                    <Button
                                        className={'btn btn-primary w-[200px] btn-' + size}
                                        htmlType="submit"
                                        disabled={submitted || formHasError}
                                    >
                                        {submitted && (
                                            <i className="fa-solid fa-spinner fa-spin"></i>
                                        )}
                                        Reset Password
                                    </Button>
                                </Form.Item>
                            </div>
                            <Form.Item className="mb-8 block lg:hidden">
                                    <Button
                                        className={'btn btn-primary btn-' + size}
                                        htmlType="submit"
                                        disabled={submitted || formHasError}
                                    >
                                        {submitted && (
                                            <i className="fa-solid fa-spinner fa-spin"></i>
                                        )}
                                        Reset Password
                                    </Button>
                                </Form.Item>


                            <Button
                                className={'btn btn-secondary font-[notoSansRegular] btn-' + size}
                            >
                                Get Support
                            </Button>
                        </Form>
							
						)}
							</div>
							
							<div className='grid grid-cols-1'>
                                <h5 className={'fst-italic font-[notoSansRegular] text-lg cursor_pointer mt-8'}>
                                    Know your password?
                                    <span onClick={()=>navigate(routes.login())} className="gradient-color-txt ms-1">
                                        Login here.
                                    </span>
                                </h5>
                                {statusType === statusTypes.link || statusType === statusTypes.expired && (
                                <h5 className={'fst-italic text-xl cursor_pointer mt-8'}>
                                    No email?
                                    <span onClick={()=> goBackToRequestPassword()} className="gradient-color-txt ms-1">
                                        Click here to go back and re-enter your email address.
                                    </span>
                                </h5>
                            )}


                            {statusType === statusTypes.reset && verified && (
                                <ResetPassword email={email} token={token}/>
                            )}
							</div>
						</div>
							
						</div>
					</div>
				</div>
				<div className='col-span-6 hidden lg:grid pl-[116px]   h-full'>
					
                <img className='w-full rounded-l-3xl  object-cover max-h-[488px] min-h-[365px]' src={dj_bg_img} />
				</div>
			</div>
		</div>
        // <>
        //     <main id="sign-up" className="main-background flex min-h-[86vh] md:min-h-[68vh]">
        //         <div className="page-left-image col-span-12 hidden lg:block h-full">
        //             <img className="img-fluid" src={default_bg_img}/>
        //         </div>
        //         <div className="container py-[80px] min-h-[560px] col-span-12">
        //             <div className="sm:grid grid-cols-12 gap-8 justify-content-start">
        //                 <div className='col-span-12 lg:col-span-6'>

        //                     <h1 className="mb-2 mt-[64px] header-large">Forgot Password?</h1>

        //                     {statusType === statusTypes.reset && verified && (
        //                         <h5 className='text-xl'>
        //                             Enter a new password below to change your password.
        //                         </h5>
        //                     )}

        //                     {statusType === statusTypes.reset && !verified && (
        //                         <>
        //                             <h1 className="mb-4 mt-8">Verifying</h1>
        //                             <h5 className='text-xl'>Please wait...</h5>
        //                         </>
        //                     )}
        //                     {statusType === statusTypes.reset && !verified && verifiedError && (
        //                         <>
        //                             <h1 className="mb-4 mt-8">Oops!</h1>
        //                             <h5>{resultMessage}</h5>
        //                         </>
        //                     )}

        //                     {statusType !== statusTypes.reset && (
        //                         <h5 className='text-xl font-[notoSansRegular] mb-8'>
        //                             If you have an existing Commonspace Account or an existing Account on a
        //                             community site powered by Commonspace, then you can reset your password here.
        //                         </h5>
        //                     )}

        //                     {statusType === statusTypes.link  && (
        //                         <div className="dialog-box mt-8">
        //                             <h3 className="text-center font-[din2014Bold]">
        //                                 Password Reset Requested!
        //                             </h3>
        //                             <p className="text-center font-[notoSansRegular]">
        //                                 If your email has an account associated with it, you will receive an email to reset your password.{' '}
        //                                 <span className="fst-italic">
        //                                     If you do not see it within 5 minutes, please check your spam folder.
        //                                 </span>
        //                             </p>
        //                         </div>
        //                     )}

        //                     {statusType === statusTypes.expired && (
        //                         <div className="dialog-box mt-8">
        //                             <h3 className="text-center">
        //                                 Error!
        //                             </h3>
        //                             <p className="text-center">
        //                                 Sorry, the password reset link has expired.
        //                             </p>
        //                         </div>
        //                     )}

        //                     {statusType === statusTypes.forgot && (
        //                         <>
        //                             <Form
        //                                 form={form}
        //                                 name="loginForm"
        //                                 validateMessages={VALIDATE_MESSAGES}
        //                                 onFocus={() => {
        //                                     setFormIsOnFocus(true);
        //                                 }}
        //                                 onBlur={() => {
        //                                     setFormIsOnFocus(false);
        //                                     handleFormOnBlur();
        //                                 }}
        //                                 autoComplete="off"
        //                                 onChange={handleFormChanges}
        //                                 onFinish={handleSubmit}
        //                                 className={formIsOnFocus ? 'hide-antd-error-messages  !z-0' : '!z-0 '}
        //                             >
        //                                 {alert && (
        //                                     <>
        //                                         <Alert
        //                                             key={alert.variant}
        //                                             variant={alert.variant}
        //                                             className={
        //                                                 'custom-alert ' +
        //                                                 (alert.variant === 'danger'
        //                                                     ? 'custom-alert-danger'
        //                                                     : 'custom-alert-success')
        //                                             }
        //                                         >
        //                                             {alert.message}
        //                                         </Alert>
        //                                     </>
        //                                 )}

        //                                 <div
        //                                     className={
        //                                         'input-group-com input-with-btn ' +
        //                                         (alert ? 'mt-4' : 'mt-8') +
        //                                         ' mb-8'
        //                                     }
        //                                 >
        //                                     <Form.Item
        //                                         label="Email"
        //                                         name="email"
        //                                         className="mb-0 hidden-label email-input !z-0"
        //                                         validateTrigger="onBlur"
        //                                         rules={[{ required: true, type: 'email' }]}
        //                                     >
        //                                         <Input
        //                                             className={'gradient-placeholder py-1.5 px-3 !z-0 input-' + size}
        //                                             disabled={submitted}
        //                                             placeholder="Enter email"
        //                                         />
        //                                     </Form.Item>

        //                                     <Form.Item className="mb-0 hidden lg:block flex !justify-end !w-[200px]">
        //                                         <Button
        //                                             className={'btn btn-primary w-[200px] btn-' + size}
        //                                             htmlType="submit"
        //                                             disabled={submitted || formHasError}
        //                                         >
        //                                             {submitted && (
        //                                                 <i className="fa-solid fa-spinner fa-spin"></i>
        //                                             )}
        //                                             Reset Password
        //                                         </Button>
        //                                     </Form.Item>
        //                                 </div>
        //                                 <Form.Item className="mb-8 block lg:hidden">
        //                                         <Button
        //                                             className={'btn btn-primary btn-' + size}
        //                                             htmlType="submit"
        //                                             disabled={submitted || formHasError}
        //                                         >
        //                                             {submitted && (
        //                                                 <i className="fa-solid fa-spinner fa-spin"></i>
        //                                             )}
        //                                             Reset Password
        //                                         </Button>
        //                                     </Form.Item>


        //                                 <Button
        //                                     className={'btn btn-secondary font-[notoSansRegular] btn-' + size}
        //                                 >
        //                                     Get Support
        //                                 </Button>
        //                             </Form>

        //                             <h5 className={'fst-italic font-[notoSansRegular] text-lg cursor_pointer mt-8'}>
        //                                 Know your password?
        //                                 <span onClick={()=>navigate(routes.login())} className="gradient-color-txt ms-1">
        //                                     Login here.
        //                                 </span>
        //                             </h5>

        //                         </>
        //                     )}

        //                     {statusType === statusTypes.link || statusType === statusTypes.expired && (
        //                         <h5 className={'fst-italic text-xl cursor_pointer mt-8'}>
        //                             No email?
        //                             <span onClick={()=> goBackToRequestPassword()} className="gradient-color-txt ms-1">
        //                                 Click here to go back and re-enter your email address.
        //                             </span>
        //                         </h5>
        //                     )}


        //                     {statusType === statusTypes.reset && verified && (
        //                         <ResetPassword email={email} token={token}/>
        //                     )}

        //                 </div>
        //                 <div className='col-span-12 lg:col-span-5'>
        //                     <div className="left-image-holder"></div>
        //                 </div>
        //             </div>
        //         </div>
        //     </main>
        // </>
    );
};
export default memo(ForgotPassword);
