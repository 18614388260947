import { memo, useEffect, useCallback, useState, useRef } from 'react';
import { Form, Input, Checkbox, Button, InputNumber } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Import everything
import { ethers } from 'ethers';

import Alert from 'react-bootstrap/Alert';

import { Network, Alchemy } from 'alchemy-sdk';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import FormFieldStacked from '../../../components/commons/FormFieldStacked';

import {
	VALIDATE_MESSAGES,
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	NODE_ENV,
	MAGIC_CONNECT_NETWORK
} from '../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE
} from '../../../constants/file';
import {
	checkCollectionsContractAddressAvailability,
	checkMembershipShortCodeAvailability,
	createSpaceAddMembership
} from '../../../services/space';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { hideTooltip, showTooltip } from '../../../utils/input';
import { getListIcon } from '../../../utils/list';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	MEMBERSHIP_TYPES
} from '../../../constants/space';
import routes from '../../../routes';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import UploadDragger from '../../../components/commons/UploadDragger';

import './../SpaceCreate.scss';
import TextareaAutosize from 'react-textarea-autosize';
import ExplicitContent from '../../../components/commons/Modals/ExplicitContent';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import { CustomValidators } from '../../../utils/CustomValidators';

const SpaceCreateAddMembership = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createSpaceCollectionDetailsForm] = Form.useForm();
	const [createSpaceAddMembershipForm] = Form.useForm();
	const [submittedCollectionDetails, setSubmittedCollectionDetails] = useState(false);
	const [submittedVerification, setSubmittedVerification] = useState(false);

	const [formIsOnFocusCollection, setFormIsOnFocusCollection] = useState(false);
	const [formHasErrorCollection, setFormHasErrorCollection] = useState(true);
	const [formIsOnFocusMembership, setFormIsOnFocusMembership] = useState(false);
	const [formHasErrorMembership, setFormHasErrorMembership] = useState(true);

	const [collectionAlert, setCollectionAlert] = useState(null);
	const [verifyAlert, setVerifyAlert] = useState(null);
	const [alert, setAlert] = useState(null);
	const [space, setSpace] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [collectionContractFound, setCollectionContractFound] = useState(false);
	const [contractAddress, setContractAddress] = useState(null);
	const [contractAddressDetails, setContractAddressDetails] = useState(null);
	const [contractAddressVerified, setContractAddressVerified] = useState(false);
	const [membershipID, setMembershipID] = useState(null);
	const [membershipName, setMembershipName] = useState(null);
	const [membershipListingText, setMembershipListingText] = useState(null);
	const [membershipSummary, setMembershipSummary] = useState(null);
	const [membershipSKUShortcode, setMembershipSKUShortcode] = useState(null);
	const [membershipMaxPerAccount, setMembershipMaxPerAccount] = useState(null);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [membershipImageError, setMembershipImageError] = useState(null);
	const [membershipImage, setMembershipImage] = useState(null);
	const [membershipImageBlobPreview, setMembershipImageBlobPreview] = useState(null);
	const [membershipVideo, setMembershipVideo] = useState(null);
	const [membershipVideoBlobPreview, setMembershipVideoBlobPreview] = useState(null);
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);

	const [walletAccounts, setWalletAccounts] = useState(null);
	const contractAddressRef = useRef(null);
	const membershipNameRef = useRef(null);
	const membershipListingTextRef = useRef(null);
	const membershipSummaryRef = useRef(null);
	const membershipSKUShortcodeRef = useRef(null);
	const membershipMaxPerAccountRef = useRef(null);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);
	const [membershipType, setMembershipType] = useState(null);

	// Optional Config object, but defaults to demo api-key and eth-mainnet.
	const settings = {
		apiKey: ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
		network: Network.ETH_MAINNET // Replace with your network.
	};

	const alchemy = new Alchemy(settings);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});

	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);

	const handleCollectionDetailsFormOnChange = useCallback(async () => {
		if (spaceInfo) {
			const data = {
				space_id: spaceInfo.id,
				contract_address: createSpaceCollectionDetailsForm.getFieldValue('contract_address')
			};

			const contract_address_check_result = await checkCollectionsContractAddressAvailability(
				data
			);
			if (contract_address_check_result) {
				if (contract_address_check_result.availability) {
					await createSpaceCollectionDetailsForm
						.validateFields()
						.then(async () => {
							setFormHasErrorCollection(false);
						})
						.catch((errors) => {
							setFormHasErrorCollection(true);
						});
				} else {
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection contract address already exists.'
					});
				}
			}
		}
	}, [spaceInfo]);

	const handleCollectionDetailsFormOnBlur = useCallback(async () => {
		if (spaceInfo) {
			const data = {
				space_id: spaceInfo.id,
				contract_address: createSpaceCollectionDetailsForm.getFieldValue('contract_address')
			};

			const contract_address_check_result = await checkCollectionsContractAddressAvailability(
				data
			);
			if (contract_address_check_result) {
				if (contract_address_check_result.availability) {
					await createSpaceCollectionDetailsForm
						.validateFields()
						.then(async () => {
							setContractAddress(
								createSpaceCollectionDetailsForm.getFieldValue('contract_address')
							);
							await getCollections(
								createSpaceCollectionDetailsForm.getFieldValue('contract_address')
							);
							setFormHasErrorCollection(false);
						})
						.catch((errors) => {
							setFormHasErrorCollection(true);
						});
				} else {
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection contract address already exists.'
					});
				}
			}
		}
	}, [spaceInfo]);

	const handleFormMembershipChanges = useCallback(async () => {
		setMembershipName(createSpaceAddMembershipForm.getFieldValue('name'));
		setMembershipListingText(createSpaceAddMembershipForm.getFieldValue('listing_text'));
		setMembershipSummary(createSpaceAddMembershipForm.getFieldValue('summary'));
		setMembershipSKUShortcode(createSpaceAddMembershipForm.getFieldValue('sku_shortcode'));
		setMembershipMaxPerAccount(createSpaceAddMembershipForm.getFieldValue('max_per_account'));
	}, []);

	const handleMembershipFormOnBlur = useCallback(async () => {
		await createSpaceAddMembershipForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				setFormHasErrorMembership(true);
			});
	}, []);

	const getCollectionOwner = async (collection_address) => {
		try {
			const contractABI = ['function owner() view returns (address)'];
			const provider = new ethers.providers.AlchemyProvider('mainnet', ALCHEMY_API_KEY);
			const myContract = new ethers.Contract(collection_address, contractABI, provider);
			const owner = await myContract.owner();
			return owner;
		} catch (error) {
			console.error(`Failed to get owner: ${error}`);
		}
	};

	const getCollections = async (collection_address, proceed_verify_wallet_address = false) => {
		setVerifyAlert(null);
		setCollectionAlert(null);
		setCollectionContractFound(null);
		// Print NFT metadata returned in the response:

		const checkOwnerRes = await getCollectionOwner(collection_address);
		// console.log('Owner Address: ' + checkOwnerRes);

		setSubmittedCollectionDetails(true);

		await alchemy.nft
			.getContractMetadata(collection_address)
			.then((result) => {
				// The contract owner
				// Other vars: address, contractDeployer, name, description
				// console.log(result);
				if (result) {
					const contractAddressData = {
						collection_name: result.openSea.collectionName ?? 'No Name',
						description: result.openSea.description ?? 'No Description',
						creator_wallet_address: checkOwnerRes ?? 'No Address',
						wallet_name: result.tokenType
					};
					setContractAddressDetails(contractAddressData);
					setCollectionContractFound(true);

					if (proceed_verify_wallet_address) {
						verifyWalletAddress(contractAddressData);
					}
				} else {
					setContractAddressDetails(null);
					setCollectionContractFound(false);
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection not found.'
					});
				}
				setSubmittedCollectionDetails(false);
			})
			.catch((error) => {
				console.log('error')
				console.log(error)
				const invalid_address_error = 'contractAddress should be a valid address';
				if (error && error.message && error.message.includes(invalid_address_error)) {
					setCollectionAlert({
						variant: 'danger',
						message: 'Invalid Contract Address.'
					});
					setSubmittedCollectionDetails(false);
				}
			});

		setSubmittedCollectionDetails(false);
	};

	const handleCollectionDetailsSubmit = useCallback(
		async (values) => {
			setContractAddressDetails(null);
			await getCollections(values.contract_address);
		},
		[authUserInfo]
	);

	const verifyWalletAddress = useCallback(
		async (passedContractAddressDetails) => {
			setSubmittedVerification(true);
			await magic.connect.disconnect().catch((e) => {
				// console.log(e);
			});
			setVerifyAlert(null);
			const createdSpace = spaceInfo;
			let passed_creator_wallet_address = null;
			if (passedContractAddressDetails) {
				passed_creator_wallet_address = passedContractAddressDetails.creator_wallet_address;
			}
			if (contractAddressDetails) {
				passed_creator_wallet_address = contractAddressDetails.creator_wallet_address;
			}

			web3.eth
				.getAccounts()
				.then((accounts) => {
					setWalletAccounts(accounts?.[0]);
					if (passed_creator_wallet_address) {
						console.log('User Wallet Address: ' + accounts?.[0]);
						console.log('Collection Owner Address: ' + passed_creator_wallet_address);

						let isCorrectWallet = accounts?.[0] == passed_creator_wallet_address;

						//SET correct is true on dev environment for testing
						if (NODE_ENV === 'development') isCorrectWallet = true;

						setContractAddressVerified(isCorrectWallet);

						if (!isCorrectWallet) {
							setVerifyAlert({
								variant: 'danger',
								message: 'Collection Contract validation failed.'
							});
						}

						if (createdSpace) {
							const spaceMemberships = createdSpace.memberships;
							if (
								createdSpace &&
								spaceMemberships &&
								spaceMemberships.length > 0
							) {
								const firstMembership = spaceMemberships[0];
								createSpaceAddMembershipForm.setFieldsValue({
									name: firstMembership.name,
									listing_text: firstMembership.listing_text,
									summary: firstMembership.summary,
									sku_shortcode: firstMembership.sku_shortcode,
									max_per_account: firstMembership.max_per_account
								});
								setMembershipID(firstMembership.id);
								setMembershipName(firstMembership.name);
								setMembershipListingText(firstMembership.listing_text);
								setMembershipSummary(firstMembership.summary);
								setMembershipSKUShortcode(firstMembership.sku_shortcode);
								setMembershipMaxPerAccount(firstMembership.max_per_account);
								setExpirationChecked(
									firstMembership.enable_expiration_and_subscription
								);
								setSelectedContentType(firstMembership.content_type);
								setSelectedAccessType(firstMembership.content_access);
								if (firstMembership.photo) {
									setMembershipImageBlobPreview(firstMembership.photo);
								}
								if (firstMembership.video) {
									setMembershipVideoBlobPreview(firstMembership.video);
								}

								setTimeout(() => {
									createSpaceAddMembershipForm
										.validateFields()
										.then(async () => {
											setFormHasErrorMembership(false);
										})
										.catch((errors) => {
											setFormHasErrorMembership(true);
										});
								}, 1000);
							}
						}
					} else {
						setContractAddressVerified(
							accounts?.[0] === contractAddressDetails.creator_wallet_address
						);
					}
					setSubmittedVerification(false);
				})
				.catch((error) => {
					setSubmittedVerification(false);
				});
		},
		[contractAddressDetails, spaceInfo]
	);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setDisplayDropDownError(true);
			setMembershipImageError(null);

			let hasError = false;

			if (selectedContentType === 'default' || selectedAccessType === 'default') {
				hasError = true;
			}

			if (!membershipImageBlobPreview) {
				setMembershipImageError('Membership Image is required.');
				hasError = true;
			}

			if (!contractAddress) {
				setCollectionAlert({
					variant: 'danger',
					message: 'Collection contract address not found.'
				});
				hasError = true;
			}

			if (hasError) {
				return;
			}

			const data = values;
			data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
			data.collection_contract_address = contractAddress;
			data.expiration_subscription_enabled = enableExpirationChecked;
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (membershipImage) {
				data.membership_image = membershipImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (membershipVideo) {
				data.membership_video = membershipVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					if (membershipID) {
						data.membership_id = membershipID;
					}

					setSubmitted(true);
					const response = await createSpaceAddMembership(data);
					if (response && response.result) {
						dispatch(getSpaceInfo(space_username));
						const url = routes.createSpaceAddMembershipTraitsPage();
						navigate(url.replace(':space_username', space_username));
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
					}
					setSubmitted(false);
					setDisplayDropDownError(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			space_username,
			authUserInfo,
			spaceInfo,
			contractAddress,
			enableExpirationChecked,
			membershipID,
			membershipImage,
			membershipVideo,
			selectedContentType,
			selectedAccessType,
			photoIsRemoved,
			videoIsRemoved,
			selectedExplicitAccess,
			selectedExplicitContent,
			membershipImageBlobPreview
		]
	);

	const saveAndGoBack = useCallback(async () => {
		setMembershipImageError(null);

		let hasError = false;

		if (selectedContentType === 'default' || selectedAccessType === 'default') {
			hasError = true;
		}

		if (!membershipImageBlobPreview) {
			setMembershipImageError('Membership Image is required.');
			hasError = true;
		}

		if (hasError) {
			return;
		}

		const data = {};
		data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
		data.collection_contract_address = contractAddress;
		data.name = membershipName;
		data.listing_text = membershipListingText;
		data.summary = membershipSummary;
		data.sku_shortcode = membershipSKUShortcode;
		data.max_per_account = membershipMaxPerAccount;
		data.expiration_subscription_enabled = enableExpirationChecked;
		data.content_type = selectedContentType;
		data.content_access = selectedAccessType;
		data.explicit_content = JSON.stringify(selectedExplicitContent);
		data.explicit_access = JSON.stringify(selectedExplicitAccess);

		if (membershipImage) {
			data.membership_image = membershipImage;
		} else {
			if (photoIsRemoved) {
				data.photo_is_empty = true;
			}
		}

		if (membershipVideo) {
			data.membership_video = membershipVideo;
		} else {
			if (videoIsRemoved) {
				data.video_is_empty = true;
			}
		}

		try {
			if (authUserInfo) {
				data.user_id = authUserInfo.id;
				data.space_id = spaceInfo.id;

				if (membershipID) {
					data.membership_id = membershipID;
				}

				setAlert(null);
				setDisplayDropDownError(true);
				setSubmitted(true);
				const response = await createSpaceAddMembership(data);
				if (response && response.result) {
					dispatch(getSpaceInfo(space_username));
					const url = routes.createSpaceAddDetailsPage();
					navigate(url.replace(':space_username', space_username));
				} else {
					setAlert({
						variant: 'danger',
						message: response.message
					});
					window.scrollTo(0, 0);
				}
				setSubmitted(false);
				setDisplayDropDownError(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [
		space_username,
		spaceInfo,
		authUserInfo,
		contractAddress,
		membershipID,
		membershipName,
		membershipListingText,
		membershipSummary,
		membershipSKUShortcode,
		membershipMaxPerAccount,
		enableExpirationChecked,
		membershipImage,
		membershipVideo,
		selectedContentType,
		selectedAccessType,
		photoIsRemoved,
		videoIsRemoved,
		selectedExplicitAccess,
		selectedExplicitContent,
		membershipImageBlobPreview
	]);

	const handleMembershipImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setMembershipImage(file);
		setMembershipImageBlobPreview(blobPreview);
	};

	const handleMembershipVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
		}

		setMembershipVideo(file);
		setMembershipVideoBlobPreview(blobPreview);
	};

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};

	const tooltipFocused = (input) => {
		if (input === 'contract_address') {
			contractAddressRef.current.focus();
		}
		if (input === 'name') {
			membershipNameRef.current.focus();
		}
		if (input === 'listing_text') {
			membershipListingTextRef.current.focus();
		}
		if (input === 'summary') {
			membershipSummaryRef.current.focus();
		}
		if (input === 'sku_shortcode') {
			membershipSKUShortcodeRef.current.focus();
		}
		if (input === 'max_per_account') {
			membershipMaxPerAccountRef.current.focus();
		}
	};

	const setFormSpaceData = async (createdSpace) => {
		const spaceMemberships = createdSpace.memberships;
		if (createdSpace && spaceMemberships && spaceMemberships.length > 0) {
			const firstMembership = spaceMemberships[0];

			if (firstMembership) {
				setMembershipType(firstMembership.type);
				createSpaceCollectionDetailsForm.setFieldsValue({
					contract_address: firstMembership.collection_contract_address
				});
				setContractAddress(firstMembership.collection_contract_address);
				if (
					firstMembership.type === 'nft_collection' &&
					firstMembership.collection_contract_address
				) {
					await getCollections(firstMembership.collection_contract_address, true);
				}

				if (firstMembership.explicit_content) {
					setSelectedExplicitContent(JSON.parse(firstMembership.explicit_content));
				}

				if (firstMembership.explicit_access) {
					setSelectedExplicitAccess(JSON.parse(firstMembership.explicit_access));
				}
			}
		}
	};

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	const gotoAddCommunityMembership = useCallback(async () => {
		if (membershipType && membershipType === 'nft_collection') {
			return;
		}

		dispatch(getSpaceInfo(space_username));
		const url = routes.createSpaceAddCommunityPage();
		navigate(url.replace(':space_username', space_username));
	}, [membershipType]);

	useEffect(() => {
		if (membershipType && membershipType === 'community') {
			gotoAddCommunityMembership().then((r) => {});
		}
	}, [membershipType]);

	useEffect(() => {}, [membershipID, membershipImageError]);

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main className="dashboard-wrapper flex add-membership">
				<div className="container !py-20">
					<div className="grid grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12 md:col-span-8 flex items-center">
							<h1 className=" header-large">Create Your First Membership</h1>
						</div>
						<div className="col-span-12 md:col-span-4 flex items-enter justify-center md:justify-end">
							<p className="body-txtitalic--large mt-4">Step 3 of 5</p>
						</div>
						<div className="col-span-12 mt-4">
							<h5 className="body-text--reg">
								{/*<a className="gradient-color-txt">Memberships</a>*/}
								{getSitewideLink('memberships_create_space_3')} are how people join
								your Space and become a{' '}
								{/*<a className="gradient-color-txt">Member</a>*/}
								{getSitewideLink('member_create_space_3')}. They can be free or
								paid, and determine which access and benefits a Member gets. You can
								create more than one to offer free, paid, and premium memberships
								which anyone can get with an email and payment method. Members can
								own multiple Memberships, but only 1 can be active at a time. Create
								your first Membership here!
							</h5>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
							<h5 className="header-medium leading-10">Choose a Membership Type</h5>
							<span className="body-text--reg">Required</span>
						</div>
						<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">
							{/*<button type="submit" className="btn btn-lg btn-secondary">*/}
							{/*	View Guide*/}
							{/*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
							{/*</button>*/}
							{getSitewideLink('view_guide_membership', 'btn btn-lg btn-secondary')}
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-4 flex items-center !order-2 md:!order-1">
							<div
								className={
									'selectable-cards-not-selected ' +
									(membershipType && membershipType === 'nft_collection'
										? 'disabled'
										: '')
								}
								onClick={gotoAddCommunityMembership}
							>
								<div className="cards-bg">
									<p className="text-center">
										<i className="gradient-color-txt fa-light fa-cubes"></i>
									</p>
									<h5 className="gradient-color-txt text-center">
										Community Membership
									</h5>
									<p className="body-text--smallest">
										Create a free or paid membership that can be sold for a
										one-time cost or have a monthly renewable price, be setup as
										Member-bound or tradable, and have many other configurable
										options.
									</p>
									<p className="body-txtitalic--smallest text-center">
										Created with Polygon
									</p>
								</div>
							</div>
						</div>
						<div className="md:col-span-4 flex items-center !order-3 md:!order-3">
							<div className="selectable-cards-selected">
								<div className="cards-bg">
									<p className="text-center">
										<i className="gradient-color-txt fa-brands fa-ethereum"></i>
									</p>
									<h5 className="gradient-color-txt text-center">
										NFT Collection
									</h5>
									<p className="body-text--smallest">
										You can use an existing NFT Collection on Ethereum to create
										a Membership. Any holder will be able to connect their
										wallet and claim a Membership for free. To use an NFT, you
										must authenticate the Creator Wallet. Learn more.
									</p>
									<p className="body-txtitalic--smallest text-center">
										Ethereum Blockchain
									</p>
								</div>
							</div>
						</div>
						<div className="md:col-span-4 flex items-center !order-1 md:!order-3">
							<p className="body-txtitalic--smallest color-reg">
								Memberships allow anyone to join your Space and controls their
								access to Updates, Benefits, Members Areas, and any Apps you
								integrate with, such as Shopify, Discord, Zoom, and more. You can
								also use an existing NFT collection that you’ve created as the basis
								for a Membership.
								<br />
								<br />
								There are additional Membership Types with different features which
								can be added after you have launched your Space.{' '}
								{/*<a className="gradient-color-txt"> Learn more about Memberships.</a>*/}
								{getSitewideLink('learn_more_memberships')}
							</p>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
							<h5 className="header-medium leading-normal">
								Verify Existing Ethereum NFT Collection
							</h5>
							<span className="body-text--reg">Required</span>
						</div>
						<div className="md:col-span-4 flex items-center !order-first md:!order-last">
							<p className="body-txtitalic--smallest color-reg">
								You can use an existing Ethereum NFT collection as the basis of a
								Membership, allowing Holders to claim and access the Membership as
								long as they own the NFT.
							</p>
						</div>
					</div>

					<Form
						form={createSpaceCollectionDetailsForm}
						name="createSpaceCollectionDetailsForm"
						id="createSpaceCollectionDetailsForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusCollection(true);
						}}
						onBlur={() => {
							setFormIsOnFocusCollection(false);
							handleCollectionDetailsFormOnBlur();
						}}
						onChange={handleCollectionDetailsFormOnChange}
						onFinish={handleCollectionDetailsSubmit}
						className={formIsOnFocusCollection ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<>
								{collectionAlert && (
									<div className="md:col-span-8">
										<Alert
											key={collectionAlert.variant}
											variant={collectionAlert.variant}
											className={
												'custom-alert ' +
												(collectionAlert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{collectionAlert.message}
										</Alert>
									</div>
								)}
							</>
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Collection Contract Address*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Contract Address"
										name="contract_address"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										
									<FormFieldStacked
									formType='input'
									type="text"	
									placeholder="42 characters starting with an “0x”."
									disabled={submitted}
									></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 flex items-end !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Specify the Contract Address of the Collection you would like to
									use as the basis of your Membership.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 !order-last md:!order-first">
								<Form.Item>
									<Button
										className="btn btn-lg btn-secondary btn-submit"
										htmlType="submit"
										disabled={
											submittedCollectionDetails ||
											formHasErrorCollection ||
											collectionContractFound
										}
									>
										{submittedCollectionDetails && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										{contractAddressDetails
											? 'Collection Details Found'
											: 'Check Collection Contract'}
									</Button>
								</Form.Item>
							</div>
							<div className="md:col-span-4 flex items-center !order-first md:!order-last ">
								<p className="body-txtitalic--smallest color-reg">
									Click here to load the Collection Contract details.
								</p>
							</div>
						</div>
					</Form>

					{contractAddressDetails && (
						<>
							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="md:col-span-2 !order-2 md:!order-1">
									<h5 className="header-small">Contract Address:</h5>
								</div>
								<div className="md:col-span-6 !order-3 md:!order-2">
									<h5 className="header-small reg-color break-words">{contractAddress}</h5>
								</div>
								<div className="md:col-span-4 flex items-center !order-1 md:!order-3">
									<p className="body-txtitalic--smallest color-reg">
										Please check that this is the correct Collection Contract
										Address and Name (if any).
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-2">
									<h5 className="header-small">Collection Name:</h5>
								</div>
								<div className="md:col-span-6">
									<h5 className="header-small reg-color">
										{contractAddressDetails.collection_name}
									</h5>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-2 flex items-center !order-2 md:!order-1">
									<h5 className="header-small">Overview:</h5>
								</div>
								<div className="md:col-span-6 flex items-center !order-3 md:!order-2">
									<p className="body-text--small">
										{contractAddressDetails.description}
									</p>
								</div>
								<div className="md:col-span-4 flex items-center !order-1 md:!order-3">
									<p className="body-txtitalic--smallest color-reg">
										Please check that this is the correct Collection Overview
										information (if any).
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-2">
									<h5 className="header-small">Creator Wallet:</h5>
								</div>
								<div className="md:col-span-6">
									<h5 className="header-small reg-color break-words">
										{contractAddressDetails.creator_wallet_address}
									</h5>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-2 !order-2 md:!order-1">
									<h5 className="header-small">Wallet Name:</h5>
								</div>
								<div className="md:col-span-6 !order-3 md:!order-2">
									<h5 className="header-small reg-color">
										{contractAddressDetails.wallet_name}
									</h5>
								</div>
								<div className="md:col-span-4 flex items-center !order-1 md:!order-3">
									<p className="body-txtitalic--smallest color-reg">
										Please check that this is the correct Collection Contract
										Creator Wallet Address and Name (if any).
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<>
									{verifyAlert && (
										<div className="md:col-span-8 mb-3">
											<Alert
												key={verifyAlert.variant}
												variant={verifyAlert.variant}
												className={
													'custom-alert ' +
													(verifyAlert.variant === 'danger'
														? 'custom-alert-danger'
														: 'custom-alert-success')
												}
											>
												{verifyAlert.message}
											</Alert>
										</div>
									)}
								</>
								<div className="md:col-span-8 !order-last md:!order-first">
									<Button
										type="button"
										className="btn btn-lg btn-secondary btn-submit break-all"
										disabled={submittedVerification}
										onClick={verifyWalletAddress}
									>
										{submittedVerification && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										{contractAddressVerified
											? 'Collection Verified'
											: 'Connect Creator Wallet to Verify & Authorize'}
									</Button>
								</div>
								<div className="md:col-span-4 flex items-center !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										Verify and authorized use by connecting the{' '}
										{getSitewideLink('membership_owner_wallet')} for the
										Collection.
									</p>
								</div>
							</div>

							{contractAddressVerified && (
								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="md:col-span-8 flex items-center justify-center !order-last md:!order-first">
										<p className="body-txtitalic--reg text-center gradient-color-txt">
											Confirmed! Your Existing NFT Collection has been
											verified
											<br /> and the relevant metadata has been imported
											below.
										</p>
									</div>
									<div className="md:col-span-4 flex items-center !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											Continue to the next step below.
										</p>
									</div>
								</div>
							)}
						</>
					)}

					{contractAddressVerified && (
						<Form
							form={createSpaceAddMembershipForm}
							name="createSpaceAddMembershipForm"
							id="createSpaceAddMembershipForm"
							validateMessages={VALIDATE_MESSAGES}
							onFocus={() => {
								setFormIsOnFocusMembership(true);
							}}
							onBlur={() => {
								setTimeout(() => {
									setFormIsOnFocusMembership(false);
									handleMembershipFormOnBlur();
								}, 200);
							}}
							onChange={handleFormMembershipChanges}
							onFinish={handleSubmit}
							className={formIsOnFocusMembership ? 'hide-antd-error-messages' : 'antd-error-messages-15'}
						>
							<>
								{alert && (
									<div className="grid grid-cols-1 md:grid-cols-12">
										<div className="md:col-span-8">
											<Alert
												key={alert.variant}
												variant={alert.variant}
												className={
													'custom-alert ' +
													(alert.variant === 'danger'
														? 'custom-alert-danger'
														: 'custom-alert-success')
												}
											>
												{alert.message}
											</Alert>
										</div>
									</div>
								)}
							</>
							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">Name*</label>
										<Form.Item
											label="Name"
											name="name"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 32,
													message: 'Must not exceed 32 characters.'
												}
											]}
										>
											<Input
												placeholder="Up to 32 characters, including spaces."
												className="input-lg body-text--small"
												maxLength={32}
												disabled={submitted}
												ref={membershipNameRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										This is the displayed Name of the Membership and is what
										people will see when browsing your Space and viewing their
										purchased Membership. This is usually similar to the NFT
										Collection name.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">
											Listing Text*
										</label>
										<Form.Item
											label="Listing Text"
											name="listing_text"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 128,
													message: 'Must not exceed 128 characters.'
												}
											]}
										>
											<TextareaAutosize
												placeholder="Up to 128 characters, including spaces."
												className="input-lg body-text--small p-0"
												disabled={submitted}
												maxLength={128}
												ref={membershipListingTextRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										The Listing Text is used when listing your Membership on
										your Space for Holders to claim. It should be 1 to 2
										sentences summarizing the Membership and who can claim it.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">
											Summary*
										</label>
										<Form.Item
											label="Summary"
											name="summary"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 128,
													message: 'Must not exceed 128 characters.'
												}
											]}
										>
											<TextareaAutosize
												placeholder="Up to 128 characters, including spaces."
												className="input-lg body-text--small p-0"
												disabled={submitted}
												maxLength={128}
												ref={membershipSummaryRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										This Summary shows when Members view their Membership. It
										should be 1 to 2 sentences summarizing the Membership and
										its details.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">
											SKU Shortcode*
										</label>
										<Form.Item
											label="SKU Shortcode"
											name="sku_shortcode"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													min: 2,
													message: 'Must be minimum 2 characters.'
												},
												{
													max: 8,
													message: 'Must not exceed 8 characters.'
												},
												({ getFieldValue }) => ({
													async validator(_, value) {
														if (value) {
															const data = {
																shortcode: value
															};

															if (spaceInfo) {
																data['space_id'] = spaceInfo.id;
															}

															if (membershipID) {
																data['membership_id'] =
																	membershipID;
															}

															let available = false;
															const response =
																await checkMembershipShortCodeAvailability(
																	data
																);
															if (response && response.result) {
																available = response.availability;
															}

															if (!available) {
																return Promise.reject(
																	new Error(
																		'Shortcode not available.'
																	)
																);
															}
														}

														if (
															(value && value.match(/\ /g)) ||
															/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
																value
															)
														) {
															return Promise.reject(
																new Error('Invalid Input')
															);
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<Input
												placeholder="2 to 8 characters. Letters and numbers only, no spaces."
												className="input-lg body-text--small"
												disabled={submitted}
												maxLength={8}
												ref={membershipSKUShortcodeRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										Choose a unique SKU Shortcode based the Membership Name.
										This is must be unique within your Space and is used in
										transactions, support, and other areas.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">
											Max Per Account*
										</label>
										<Form.Item
											label="Max Per Account"
											name="max_per_account"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{ validator: CustomValidators.maxPerCount }
											]}
										>
											<Input
												controls={false}
												placeholder="Enter “0” for unlimited."
												className="input-lg body-text--small"
												disabled={submitted}
												ref={membershipMaxPerAccountRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										You can put a limit how many Memberships can be claimed by a
										single account at one time. Enter “0” to set this to
										unlimited.
									</p>
								</div>
							</div>

							{/*<div className="row mt-4">*/}
							{/*	<div className="col-8 flex items-center">*/}
							{/*		<div className="form-check ms-5">*/}
							{/*			<label className="form-check-label body-text--small gradient-color-txt">*/}
							{/*				<Checkbox*/}
							{/*					checked={enableExpirationChecked}*/}
							{/*					className="form-check-input"*/}
							{/*					onChange={onChangeEnableExpiration}*/}
							{/*				></Checkbox>*/}
							{/*				Enable Expiration Date and add Monthly Subscription for*/}
							{/*				continued access*/}
							{/*			</label>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*	<div className="col-4">*/}
							{/*		<p className="body-txtitalic--smallest color-reg">*/}
							{/*			Memberships have a one-time upfront Price. This option adds*/}
							{/*			an Expiration after a set number of months. After that,*/}
							{/*			Members must pay a Monthly Subscription to continue access.*/}
							{/*			Details are set up in the Add Benefits step.*/}
							{/*		</p>*/}
							{/*	</div>*/}
							{/*</div>*/}

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4">
								<div className="md:hidden">
									<p className="body-txtitalic--smallest color-reg">
										Your Membership Image (up to 8MB) should be as high
										resolution as possible, up to 3840px height and width. This
										will also be used for any thumbnails and previews.
									</p>
								</div>
								<div className="md:col-span-4">
									<div className="upload-rec no-bg">
										<UploadDragger
											passedError={membershipImageError}
											cropperAspectRatio={4 / 3}
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={IMAGE_FILETYPES.toString()}
											uploadedFilePreview={membershipImageBlobPreview}
											onUploadFileChange={
												handleMembershipImageUploadFileChange
											}
											minHeight={IMAGE_LARGE_MIN_HEIGHT}
											minWidth={IMAGE_LARGE_MIN_WIDTH}
											maxHeight={IMAGE_LARGE_MAX_HEIGHT}
											maxWidth={IMAGE_LARGE_MAX_WIDTH}
											uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
											disableCropper={true}
										/>
										<i className="fa-regular fa-plus"></i>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Membership Image*
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 3840px, square preferred. JPEG, PNG, SVG, or GIF
									</p>
								</div>
								<div className="md:hidden">
									<p className="mt-8 body-txtitalic--smallest color-reg">
										You can add a Membership Video (up to 12MB). This will be
										viewable by Members when viewing their purchased Membership.
										Optional.
									</p>
								</div>
								<div className="md:col-span-4">
									<div className="upload-rec no-bg">
										<UploadDragger
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={VIDEO_FILETYPES.toString()}
											uploadedFilePreview={membershipVideoBlobPreview}
											onUploadFileChange={
												handleMembershipVideoUploadFileChange
											}
											minHeight={VIDEO_STANDARD_MIN_HEIGHT}
											minWidth={VIDEO_STANDARD_MIN_WIDTH}
											maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
											maxWidth={VIDEO_STANDARD_MAX_WIDTH}
											uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
										/>
										<i className="fa-regular fa-plus"></i>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Membership Video
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 1920px/1080p, square preferred. MP4 or WEBM.
									</p>
								</div>
								<div className="hidden md:grid md:col-span-4">
									<p className="body-txtitalic--smallest color-reg">
										Your Membership Image (up to 8MB) should be as high
										resolution as possible, up to 3840px height and width. This
										will also be used for any thumbnails and previews.
									</p>
									<p className="mt-8 body-txtitalic--smallest color-reg">
										You can add a Membership Video (up to 12MB). This will be
										viewable by Members when viewing their purchased Membership.
										Optional.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="md:col-span-4 items-center !order-2 md:!order-1">
									<DropdownWithItems
										title="Select Content Type"
										customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"
										displayError={
											displayDropDownError &&
											selectedContentType === 'default'
										}
										icon={getListIcon(
											CONTENT_TYPES_LIST_WITH_DEFAULT,
											selectedContentType
										)}
										selectedValue={selectedContentType}
										listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
										onDropdownChange={(value) => openContentTypeModal(value)}
									/>
									<ExplicitContent
										modalOpen={modalOpenExplicitContent}
										setModalOpen={setModalOpenExplicitContent}
										selectedItems={selectedExplicitContent}
										isExplicitContent={true}
										onSelectedItems={(value) =>
											setSelectedExplicitContent(value)
										}
									/>
								</div>
								<div className="md:col-span-4 items-center !order-3 md:!order-2">
									<DropdownWithItems
										title="Select Access Type"
										customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"
										displayError={
											displayDropDownError && selectedAccessType === 'default'
										}
										icon={getListIcon(
											ACCESS_TYPES_LIST_WITH_DEFAULT,
											selectedAccessType
										)}
										selectedValue={selectedAccessType}
										listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
										onDropdownChange={(value) => openAccessTypeModal(value)}
									/>
									<ExplicitContent
										modalOpen={modalOpenExplicitAccess}
										setModalOpen={setModalOpenExplicitAccess}
										selectedItems={selectedExplicitAccess}
										isExplicitContent={false}
										onSelectedItems={(value) =>
											setSelectedExplicitAccess(value)
										}
									/>
								</div>

								<div className="md:col-span-4 flex items-end !order-1 md:!order-3">
									<p className="body-txtitalic--smallest color-reg">
										Change the Content setting if your Details, Image, or Video
										contains explicit or sensitive content. Change the Access
										setting if your Space will provide access to explicit or
										sensitive content.{' '}
										{getSitewideLink('view_content_guideline_memberships')}.
									</p>
								</div>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="md:col-span-8">
									<Form.Item>
										<Button
											className="btn btn-lg btn-primary whitespace-pre-wrap"
											htmlType="submit"
											disabled={submitted || formHasErrorMembership}
										>
											{submitted && (
												<i className="fa-solid fa-spinner fa-spin"></i>
											)}
											Save Membership & Continue
										</Button>
									</Form.Item>
								</div>
								<div className="md:col-span-4">
									<Button
										className="btn btn-lg btn-warning bg-none"
										disabled={submitted || formHasErrorMembership}
										onClick={saveAndGoBack}
									>
										Save & Go Back
									</Button>
								</div>
							</div>
						</Form>
					)}
				</div>
			</main>
		</>
	);
};

export default memo(SpaceCreateAddMembership);
