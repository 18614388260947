import React, {memo} from "react";
import ReactModal from "react-modal";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import toast from "bootstrap/js/src/toast";
import {Button} from "antd";
import {useLocation} from "react-router-dom";


const ChannelSharePost = (props) => {
    const {
        modalOpen,
        setModalOpen,
        post,
    } = props;

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            minWidth:'50%',
            maxWidth: '80%',
            borderRadius: 32
        },
        overlay: { background: 'rgba(0, 0, 0, 0.6)' }
    };
    const currentLocation = useLocation();

    const getObjectURL = (post) => {
        const winLocHref = window.location.href;
        // console.log(winLocHref)
        return winLocHref;
        // return process.env.NEXT_PUBLIC_URL + (object?.type === 'work' ? ('/works/' + object.guid) : ('/insights/' + object.guid));
        // return 'https://seersite.rnng.dev' + (object?.type === 'work' ? ('/works/' + object.guid) : ('/insights/' + object.guid));
    }


    const copyObjectURL = (object) => {
        navigator.clipboard
            .writeText(getObjectURL(object))
            .then(() => {
                // console.log(getObjectURL(object));
                toast.success('Link copied! ' + getObjectURL(object));
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const shareWindowClosed = () => {
        console.log("Closed");
    }

    const handleOnClose = () => {
        setModalOpen(false);
    }

    return (
        <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={customStyles}
        >
            <div className="relative bg-white p-6 text-left grid gap-6 w-full max-w-[896px]">
                <div className="">
                    <p className="text-xl font-secondary">
                        Share this link via
                    </p>
                    <div className="grid grid-cols-3 gap-6 mt-4 mb-4 px-8">
                        <TwitterShareButton
                            url={getObjectURL(post)}
                            className="bg-none flex items-center justify-center w-10 h-10 justify-self-center"
                            onShareWindowClose={shareWindowClosed}
                        >
                            <div className="flex text-2xl text-color-key w-10 h-10 rounded-full border border-color-key items-center justify-center justify-self-center">
                                <i className="fa-brands fa-twitter"/>
                            </div>
                        </TwitterShareButton>
                        <FacebookShareButton
                            url={getObjectURL(post)}
                            className="bg-none flex items-center justify-center w-10 h-10 justify-self-center"
                            onShareWindowClose={shareWindowClosed}
                        >
                            <div className="flex text-2xl text-color-key w-10 h-10 rounded-full border border-color-key items-center justify-center justify-self-center">
                                <i className="fa-brands fa-facebook-f"/>
                            </div>
                        </FacebookShareButton>
                        <LinkedinShareButton
                            url={getObjectURL(post)}
                            className="bg-none flex items-center justify-center w-10 h-10 justify-self-center"
                            onShareWindowClose={shareWindowClosed}
                        >
                            <div className="flex text-2xl text-color-key w-10 h-10 rounded-full border border-color-key items-center justify-center justify-self-center">
                                <i className="fa-brands fa-linkedin"/>
                            </div>
                        </LinkedinShareButton>
                        {/*<div className="flex text-2xl text-color-key w-10 h-10 rounded-full border border-color-key items-center justify-center justify-self-center">*/}
                        {/*    <i className="fa-brands fa-instagram"/>*/}
                        {/*</div>*/}
                    </div>
                    <p className="text-xl font-secondary mb-4">
                        Or copy link
                    </p>
                    <div className="flex items-center gap-2 p-2 border border-color-key rounded mb-4">
                        <i className="flex-none fa-solid fa-link  w-14"/>
                        <p className="grow w-full text-xl font-secondary">
                            {getObjectURL(post)}
                        </p>
                        <Button className="flex-none btn btn-md btn-secondary text-center px-2  w-14"
                                onClick={()=>copyObjectURL(post)}
                        >
                            Copy
                        </Button>
                    </div>
                    <Button className="btn btn-md btn-primary w-full text-xl text-center"
                          onClick={handleOnClose}
                    >
                        Close
                    </Button>
                </div>
            </div>

        </ReactModal>
    );
}

export default memo(ChannelSharePost);