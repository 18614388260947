import {memo, useEffect, useCallback, useState, useRef} from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';


import { VALIDATE_MESSAGES } from '../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE
} from '../../constants/file';
import {
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	ACCESS_TYPES_LIST_WITH_DEFAULT
} from '../../constants/space';
import { getListIcon } from '../../utils/list';
import { checkShortCodeAvailability, createSpace, getSpace } from '../../services/space';
import { hideTooltip, showTooltip } from '../../utils/input';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import routes from '../../routes';
import UploadDragger from '../../components/commons/UploadDragger';
import DropdownWithItems from '../../components/commons/DropdownWithItems';
import TextareaAutosize from 'react-textarea-autosize';
import { CUSTOM_REGEX } from '../../constants/custom_regex';
import './SpaceCreate.scss';
import { checkUsernameAvailability } from '../../services/general';
import ExplicitContent from '../../components/commons/Modals/ExplicitContent';
import { getSitewideLink } from '../../utils/sitewideLinks';
import FormFieldStacked from '../../components/commons/FormFieldStacked/FormFieldStacked';

const SpaceCreate = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [createSpaceForm] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [pageProcess, setPageProcess] = useState('create');
	const [alert, setAlert] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [displayName, setDisplayName] = useState(null);
	const [username, setUsername] = useState(null);
	const [spaceShortCode, setSpaceShortCode] = useState(null);
	const [location, setLocation] = useState(null);
	const [about, setAbout] = useState(null);
	const [website, setWebsite] = useState(null);
	const [profileImageError, setProfileImageError] = useState(null);
	const [backgroundImageError, setBackgroundImageError] = useState(null);
	const [profileImage, setProfileImage] = useState(null);
	const [profileImageBlobPreview, setProfileImageBlobPreview] = useState(null);
	const [profileVideo, setProfileVideo] = useState(null);
	const [profileVideoBlobPreview, setProfileVideoBlobPreview] = useState(null);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [backgroundImageBlobPreview, setBackgroundImageBlobPreview] = useState(null);
	const [backgroundVideo, setBackgroundVideo] = useState(null);
	const [backgroundVideoBlobPreview, setBackgroundVideoBlobPreview] = useState(null);
	const [agreementChecked, setAgreementChecked] = useState(false);
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [photoBGIsRemoved, setPhotoBGIsRemoved] = useState(false);
	const [videoBGIsRemoved, setVideoBGIsRemoved] = useState(false);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);
	const timeoutRef = useRef(null);

	const handleFormOnBlur = useCallback(async () => {
		await createSpaceForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setDisplayName(createSpaceForm.getFieldValue('display_name'));
		setUsername(createSpaceForm.getFieldValue('username'));
		setSpaceShortCode(createSpaceForm.getFieldValue('shortcode'));
		setLocation(createSpaceForm.getFieldValue('location'));
		setAbout(createSpaceForm.getFieldValue('about'));
		setWebsite(createSpaceForm.getFieldValue('website'));

		await createSpaceForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const websiteOnBlur = (event) => {
		showTooltip(event);
		if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(event.target.value)) {
			event.target.value = 'https://' + event.target.value;
			createSpaceForm.setFieldValue('website', event.target.value);
		}
	};

	const handleSubmit = useCallback(
		async (values) => {
			setDisplayDropDownError(true);
			setAlert(null);
			setProfileImageError(null);
			setBackgroundImageError(null);

			let hasError = false;

			if (selectedContentType === 'default' || selectedAccessType === 'default') {
				hasError = true;
			}

			if (!profileImageBlobPreview) {
				setProfileImageError('Space Profile Image is required.');
				hasError = true;
			}

			if (!backgroundImageBlobPreview) {
				setBackgroundImageError('Space Profile Background Image is required.');
				hasError = true;
			}
			// console.log('hasError: '+hasError)

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = values;

			//include url protocol when url protocol is missing
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.website)) {
				data.website = 'https://' + data.website;
			}

			if (pageProcess === 'update') {
				data.space_id = spaceInfo.id;
			}

			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (profileImage) {
				data.photo = profileImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (profileVideo) {
				data.video = profileVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			if (backgroundImage) {
				data.background_photo = backgroundImage;
			} else {
				if (photoBGIsRemoved) {
					data.background_photo_is_empty = true;
				}
			}

			if (backgroundVideo) {
				data.background_video = backgroundVideo;
			} else {
				if (videoBGIsRemoved) {
					data.background_video_is_empty = true;
				}
			}

			if (data.about === '') {
				data.about = null;
			}

			try {
				if (authUserInfo) {
					setSubmitted(true);
					data.user_id = authUserInfo.id;
					const response = await createSpace(data);
					// console.log('response: ' + response);
					if (response && response.result) {

						dispatch(getSpaceInfo(response.space.username));
						const url = routes.createSpaceAddDetailsPage();
						navigate(url.replace(':space_username', response.space.username));
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						window.scrollTo(0, 0);
					}
					setPhotoIsRemoved(false);
					setVideoIsRemoved(false);
					setPhotoBGIsRemoved(false);
					setVideoBGIsRemoved(false);
					setSubmitted(false);
					setDisplayDropDownError(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			pageProcess,
			profileImage,
			profileVideo,
			backgroundImage,
			backgroundVideo,
			selectedContentType,
			selectedAccessType,
			photoIsRemoved,
			videoIsRemoved,
			photoBGIsRemoved,
			videoBGIsRemoved,
			selectedExplicitAccess,
			selectedExplicitContent,
			profileImageBlobPreview,
			backgroundImageBlobPreview
		]
	);

	const printValue = (value) => {
		console.log(value)
	}

	const handleProfileImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setProfileImage(file);
		setProfileImageBlobPreview(blobPreview);
	};

	const handleProfileVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
		}

		setProfileVideo(file);
		setProfileVideoBlobPreview(blobPreview);
	};

	const handleBackgroundImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoBGIsRemoved(true);
		} else {
			setPhotoBGIsRemoved(false);
		}

		setBackgroundImage(file);
		setBackgroundImageBlobPreview(blobPreview);
	};

	const handleBackgroundVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setVideoBGIsRemoved(true);
		} else {
			setVideoBGIsRemoved(false);
		}

		setBackgroundVideo(file);
		setBackgroundVideoBlobPreview(blobPreview);
	};

	const onChangeAgreement = (e) => {
		setAgreementChecked(e.target.checked);
	};

	const setFormSpaceData = useCallback(
		
		async (createdSpace) => {
			console.log("here");
			let textAreaAbout = createdSpace.about ? createdSpace.about : '';
			createSpaceForm.setFieldsValue({
				display_name: createdSpace.name,
				username: createdSpace.username,
				shortcode: createdSpace.shortcode,
				location: createdSpace.location,
				about: textAreaAbout,
				website: createdSpace.website
			});
			setDisplayName(createdSpace.name);
			setUsername(createdSpace.username);
			setSpaceShortCode(createdSpace.shortcode);
			setLocation(createdSpace.location);
			setAbout(textAreaAbout);
			setWebsite(createdSpace.website);
			setSelectedContentType(createdSpace.content_type);
			setSelectedAccessType(createdSpace.content_access);
			setSelectedExplicitContent(JSON.parse(createdSpace.explicit_content));
			setSelectedExplicitAccess(JSON.parse(createdSpace.explicit_access));
			setAgreementChecked(true);
			if (createdSpace.photo_large) {
				if (!photoIsRemoved) {
					setProfileImageBlobPreview(createdSpace.photo_large);
				}
			}
			if (createdSpace.video) {
				if (!videoIsRemoved) {
					setProfileVideoBlobPreview(createdSpace.video);
				}
			}
			if (createdSpace.background_photo) {
				if (!photoBGIsRemoved) {
					setBackgroundImageBlobPreview(createdSpace.background_photo);
				}
			}
			if (createdSpace.background_video) {
				if (!videoBGIsRemoved) {
					setBackgroundVideoBlobPreview(createdSpace.background_video);
				}
			}

			await createSpaceForm
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		},
		[photoIsRemoved, videoIsRemoved, photoBGIsRemoved, videoBGIsRemoved]
	);

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	useEffect(() => {
		if (space_username) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					setFormSpaceData(spaceInfo);
					setPageProcess('update');
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(space_username));
				}
				if (spaceInfoLoading === 'failed') {
					navigate(routes.createSpace());
				}
			}
		}

		document.title = 'Commonspace Space';
	}, [
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		profileImageError,
		backgroundImageError
	]);

	const debounce = (func, wait) => {
		let timeout;
		return function(...args) {
			if (timeout) {
				clearTimeout(timeout);
			}
			return new Promise((resolve, reject) => {
				timeout = setTimeout(() => {
					func(...args).then(resolve).catch(reject);
				}, wait);
			});
		};
	};

	return (
		<>
			<main className="dashboard-wrapper flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12 ">
						<div className="col-span-12 md:col-span-6 flex items-center">
							<h1 className="header-large">
								{pageProcess === 'update' ? 'Update' : 'Creating'} your Space
							</h1>
						</div>
						<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">
							<p className="font-['din2014Bold'] text-2xl">Step 1 of 5</p>
						</div>
						<div className="col-span-12 mt-8 md:mt-0">
							<h5 className="body-text--reg">
								<span className='gradient-colot-txt'>Spaces</span> are community sites created on the Commonspace Platform. You
								can make one for any community or business you like.{' '}
								{/*<a className="gradient-color-txt">*/}
								{/*	Spaces are free for up to 100 monthly active members*/}
								{/*</a>*/}
								{getSitewideLink('100_monthly_active_members')}, and you can upgrade
								to connect your own domain, customize design, and unlock more
								features.
							</h5>
						</div>
					</div>

					<div className="mb-4 mt-8 grid grid-cols-12 md:gap-8">
						<div className="col-span-12 md:col-span-8 grid grid-cols-2 ">
							<h5 className="header-small flex items-center">Step 1: Add Space Details</h5>
							<span className='flex justify-end items-center text-xl italic leading-7'>Required</span>
						</div>
						<div className="col-span-12 md:col-span-4 mt-8 md:mt-0 flex items-center justify-center">
							{/*<button type="submit" className="btn btn-lg btn-secondary">*/}
							{/*	View Guide*/}
							{/*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
							{/*</button>*/}
							{getSitewideLink('view_guide_create_space', 'btn btn-lg btn-secondary')}
						</div>
					</div>

					<Form
						form={createSpaceForm}
						name="createSpacesForm"
						id="createSpacesForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocus(false);
								handleFormOnBlur();
							}, 200);
						}}
						
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-5'}
					>
						<>
							{alert && (
								<div className="grid grid-cols-12 gap-8">
									<div className="col-span-8">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								</div>
							)}
						</>
						<div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-12 ">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">
									Space Name*
								</label>
								<div className="relative  rounded-none ">
									<Form.Item
											label="Space Name"
											name="display_name"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													min: 2,
													message: 'Must be minimum 2 characters.'
												},
												{
													max: 32,
													message: 'Must not more than 32 characters.'
												}
											]}
										>
										
										<FormFieldStacked
										formType='input'
										type="text"											
										placeholder="2 Up to 32 characters, including spaces."
										disabled={submitted}></FormFieldStacked>
										</Form.Item>
								</div>
								
							</div>
							<div className="md:col-span-4 self-end my-auto !order-first md:!order-last flex !items-end">
								<p className="body-txtitalic--smallest color-reg">
									This is the displayed name of your Space. Usually it’s the name
									of your business, group, or community.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-12">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label  className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Username*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Username"
										name="username"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												min: 2,
												message: 'Must be minimum 2 of characters.'
											},
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											},
											() => ({
												validator: async (_, value) => {

													if (value) {
														const data = {
															shortcode: value
														};

														if (spaceInfo) {
															data['space_id'] = spaceInfo.id;
														}

														let available = false;
														const response =
															await checkUsernameAvailability(data);
														if (response && response.result) {
															available = response.availability;
														}

														if (!available) {
															return Promise.reject(
																new Error(
																	'Username not available.'
																)
															);
														}
													}

													if (
														(value && value.match(/\ /g)) ||
														/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(
															value
														)
													) {
														return Promise.reject(
															new Error('Invalid Input')
														);
													}

													return Promise.resolve();

													// if (!value) {
													// 	return Promise.resolve();
													// }
													// const data = { username: value };
													// const debouncedCheck = debounce(checkUsernameAvailability, 1500);
													// return debouncedCheck(data).then((response) => {
													// 	let available = false;
													// 	if (response && response.result) {
													// 		available = response.availability;
													// 	}
													// 	if (!available) {
													// 		return Promise.reject(new Error('Username not available.'));
													// 	}
													// 	if ((value && value.match(/\ /g)) || /[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(value)) {
													// 		return Promise.reject(new Error('Invalid Input.'));
													// 	}
													// 	return Promise.resolve();
													// });
												}
											})
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"											
											placeholder="2 to 32 characters. Letters, numbers, dashes, periods, and underscores only."											
											disabled={submitted}></FormFieldStacked>																					
									</Form.Item>
									
								</div>
								
							</div>
							<div className="md:col-span-4 self-end my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Your Space has a unique @username for tagging and mentions. This
									is the same type as Members’ usernames and cannot be the same as
									another Space or Member.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-12 ">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Space Shortcode*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Space Shortcode"
										name="shortcode"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												min: 2,
												message: 'Must be minimum 2 characters.'
											},
											{
												max: 8,
												message: 'Must not exceed 8 characters.'
											},
											() => ({
												async validator(_, value) {
													// TODO: find way to trigger shortcode API check on form submit to avoid flood requests
													if (value) {
														const data = {
															shortcode: value
														};

														if (spaceInfo) {
															data['space_id'] = spaceInfo.id;
														}

														let available = false;
														const response =
															await checkShortCodeAvailability(data);
														if (response && response.result) {
															available = response.availability;
														}

														if (!available) {
															return Promise.reject(
																new Error(
																	'Shortcode not available.'
																)
															);
														}
													}

													if (
														(value && value.match(/\ /g)) ||
														/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(
															value
														)
													) {
														return Promise.reject(
															new Error('Invalid Input')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
									
										<FormFieldStacked
										formType='input'
										type="text"											
										placeholder="2 to 8 characters. Letters and numbers only, no spaces."
										disabled={submitted}></FormFieldStacked>	
									</Form.Item>
								</div>
								
							</div>							
							</div>
							<div className="md:col-span-4 self-end my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Choose a unique short Space Shortcode based on your Space’s
									name. This is used in transaction details, support requests, and
									other areas. {/*<a className="gradient-color-txt">*/}
									{/*	Suggest an available Space Shortcode.*/}
									{/*</a>*/}
									{getSitewideLink('suggest_space_shortcode')}
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-12">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Location
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Location"
										name="location"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											},
										]}
									>
										
										<FormFieldStacked
										formType='input'
										type="text"											
										placeholder="Up to 32 characters, including spaces."
										disabled={submitted}></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>							
							<div className="md:col-span-4 self-end my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									This is the displayed location of your community, such as a
									city, state, region, country, place, or coverage area. Optional.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-12">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label  className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									About
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="About"
										name="about"
										className="mb-0 hidden-label"
										rules={[
											{
												max: 255,
												message: 'Must not exceed 255 characters.'
											}
										]}
									>
										<FormFieldStacked
										formType='textarea'
										type="text"	
										maxLength={255}										
										placeholder="Up to 255 characters, including spaces."
										disabled={submitted}></FormFieldStacked>
										
									</Form.Item>
								</div>
								
							</div>
							
							
							<div className="md:col-span-4 self-end my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Share some details about your business, group, or community.
									Recommended.{' '}
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-12">
						<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Website
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Website"
										name="website"
										className="mb-0 hidden-label"
										rules={[
											{
												// type: 'url',
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please Enter a valid URL'
											}
										]}
									>										
										<FormFieldStacked
										formType='input'
										type="text"		
										placeholder="www.domain.com"									
										className="block h-10 bg-[#ffffff80] w-full rounded-none border-0 py-1.5 px-3 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
										disabled={submitted}></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 self-end my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Display your primary domain name for your business, group, or
									community by adding it here. Optional.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-12">
						<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Socials
								</label>
								<div className="relative rounded-none ">
								<ul className="list-socials">
										<li className='mr-6'>
											{/* <a href=""> */}
											<i className="fa-brands fa-twitter"></i>
											{/* </a> */}
										</li>
										<li className='mr-6'>
											{/* <a href=""> */}
											<i className="fa-brands fa-instagram"></i>
											{/* </a> */}
										</li>
										<li className='mr-6'>
											{/* <a href=""> */}
											<i className="fa-brands fa-facebook"></i>
											{/* </a> */}
										</li>
										<li className='mr-6'>
											{/* <a href=""> */}
											<i className="fa-brands fa-tiktok"></i>
											{/* </a> */}
										</li>
										<li className='mr-6'>
											{/* <a href=""> */}
											<i className="fa-brands fa-youtube"></i>
											{/* </a> */}
										</li>
										<li className='mr-6'>
											{/* <a href=""> */}
											<i className="fa-brands fa-linkedin"></i>
											{/* </a> */}
										</li>
										<li className='mr-6' >
											{/* <a href=""> */}
											<i className="fa-brands fa-discord"></i>
											{/* </a> */}
										</li>
										<li>
											{/* <a href=""> */}
											<i className="fa-brands fa-twitch"></i>
											{/* </a> */}
										</li>
									</ul>
								</div>
								
							</div>
							
							<div className="md:col-span-4 self-end my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Recommended. Link your social media accounts here so Members can
									find them.
								</p>
							</div>
						</div>

						<div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-12">
							{/*hide component for screen 768 and above*/}
							<div className="md:hidden">
								<p className="body-txtitalic--smallest color-reg">
									The Profile Image for your Space is displayed on the standard
									homepage and is used in various areas. Recommended.
								</p>
							</div>
							<div className="mt-2.5 md:mt-0 md:col-span-4">
								<div className="upload-circ no-bg">
									<UploadDragger
										passedError={profileImageError}
										cropperAspectRatio={1 / 1}
										circular="true"
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={IMAGE_FILETYPES.toString()}
										uploadedFilePreview={profileImageBlobPreview}
										onUploadFileChange={handleProfileImageUploadFileChange}
										minHeight={IMAGE_STANDARD_MIN_HEIGHT}
										minWidth={IMAGE_STANDARD_MIN_WIDTH}
										maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
										maxWidth={IMAGE_STANDARD_MAX_WIDTH}
										uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
										disableCropper={true}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Space Profile Image*
								</p>
								<p className="text-center body-txtitalic--smallest color-light">
									1920px square preferred. JPEG, PNG, or GIF
								</p>
							</div>
							{/*hide component for screen 768 and above*/}
							<div className="md:hidden">
								<p className="mt-8 body-txtitalic--smallest color-reg">
									The Profile Video for your Space is displayed on the standard
									homepage and some areas on limited devices. Optional.
								</p>
							</div>
							<div className="md:col-span-4">
								<div className="upload-circ no-bg">
									<UploadDragger
										circular="true"
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={VIDEO_FILETYPES.toString()}
										uploadedFilePreview={profileVideoBlobPreview}
										onUploadFileChange={handleProfileVideoUploadFileChange}
										minHeight={VIDEO_STANDARD_MIN_HEIGHT}
										minWidth={VIDEO_STANDARD_MIN_WIDTH}
										maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
										maxWidth={VIDEO_STANDARD_MAX_WIDTH}
										uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Space Profile Video
								</p>
								<p className="text-center body-txtitalic--smallest color-light">
									Up to 1920px/1080p, square preferred. MOV or MP4
								</p>
							</div>
							{/*display component for screen 768 and above*/}
							<div className="hidden md:block md:col-span-4">
								<p className="body-txtitalic--smallest color-reg">
									The Profile Image for your Space is displayed on the standard
									homepage and is used in various areas. Recommended.
								</p>
								<p className="mt-8 body-txtitalic--smallest color-reg">
									The Profile Video for your Space is displayed on the standard
									homepage and some areas on limited devices. Optional.
								</p>
							</div>
						</div>

						<div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-12">
							{/*hide component for screen 768 and above*/}
							<div className="md:hidden">
								<p className="body-txtitalic--smallest color-reg">
									The Background Image for your Space is displayed on the standard
									homepage and is used in various areas. Recommended.{' '}
								</p>
							</div>
							<div className="mt-2.5 md:mt-0 md:col-span-4">
								<div className="upload-rec no-bg">
									<UploadDragger
										passedError={backgroundImageError}
										cropperAspectRatio={16 / 9}
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={IMAGE_FILETYPES.toString()}
										uploadedFilePreview={backgroundImageBlobPreview}
										onUploadFileChange={handleBackgroundImageUploadFileChange}
										minHeight={IMAGE_LARGE_MIN_HEIGHT}
										minWidth={IMAGE_LARGE_MIN_WIDTH}
										maxHeight={IMAGE_LARGE_MAX_HEIGHT}
										maxWidth={IMAGE_LARGE_MAX_WIDTH}
										uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
										disableCropper={true}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Space Profile Background Image*
								</p>
								<p className="text-center body-txtitalic--smallest color-light">
									Up to 3840px square or wide. JPEG, PNG, or GIF
								</p>
							</div>
							{/*hide component for screen 768 and above*/}
							<div className="md:hidden">
								<p className="mt-8 body-txtitalic--smallest color-reg">
									The Background Video for your Space is displayed on the standard
									homepage and some areas on limited devices. Optional.
								</p>
							</div>
							<div className="md:col-span-4">
								<div className="upload-rec no-bg">
									<UploadDragger
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={VIDEO_FILETYPES.toString()}
										uploadedFilePreview={backgroundVideoBlobPreview}
										onUploadFileChange={handleBackgroundVideoUploadFileChange}
										minHeight={VIDEO_STANDARD_MIN_HEIGHT}
										minWidth={VIDEO_STANDARD_MIN_WIDTH}
										maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
										maxWidth={VIDEO_STANDARD_MAX_WIDTH}
										uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Space Profile Background Video
								</p>
								<p className="text-center body-txtitalic--smallest color-light">
									Up to 1920px/1080p, square or wide. MOV or MP4
								</p>
							</div>
							{/*display component for screen 768 and above*/}
							<div className="hidden md:block md:col-span-4">
								<p className="body-txtitalic--smallest color-reg">
									The Background Image for your Space is displayed on the standard
									homepage and is used in various areas. Recommended.{' '}
								</p>
								<p className="mt-8 body-txtitalic--smallest color-reg">
									The Background Video for your Space is displayed on the standard
									homepage and some areas on limited devices. Optional.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-8 gap-y-5 mt-8 items-center md:grid-cols-12 md:mt-0">
							<div className="md:col-span-4 my-auto !order-2 md:!order-1">
								<DropdownWithItems
									title="Select Content Type"
									customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"
									displayError={
										displayDropDownError && selectedContentType === 'default'
									}
									icon={getListIcon(
										CONTENT_TYPES_LIST_WITH_DEFAULT,
										selectedContentType
									)}
									selectedValue={selectedContentType}
									listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openContentTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitContent}
									setModalOpen={setModalOpenExplicitContent}
									selectedItems={selectedExplicitContent}
									isExplicitContent={true}
									onSelectedItems={(value) => setSelectedExplicitContent(value)}
								/>
							</div>
							<div className="md:col-span-4 my-auto !order-3 md:!order-2">
								<DropdownWithItems
									title="Select Access Type"
									customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"
									displayError={
										displayDropDownError && selectedAccessType === 'default'
									}
									icon={getListIcon(
										ACCESS_TYPES_LIST_WITH_DEFAULT,
										selectedAccessType
									)}
									selectedValue={selectedAccessType}
									listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openAccessTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitAccess}
									setModalOpen={setModalOpenExplicitAccess}
									selectedItems={selectedExplicitAccess}
									isExplicitContent={false}
									onSelectedItems={(value) => setSelectedExplicitAccess(value)}
								/>
							</div>
							<div className="md:col-span-4 my-auto !order-1 md:!order-3">
								<p className="body-txtitalic--smallest color-reg m-0">
									Change the Content setting if your Details, Image, or Video
									contains explicit or sensitive content. Change the Access
									setting if your Space will provide access to explicit or
									sensitive content. {/*<a className="gradient-color-txt">*/}
									{/*	View our Content Guidelines.*/}
									{/*</a>*/}
									{getSitewideLink('view_content_guidelines')}
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-12 md:mt-0">
							<div className="md:col-span-8 ">
								<div className="form-check flex items-center">
										<Checkbox
											checked={agreementChecked}
											className="inline-flex form-check-input items-center mr-8 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={onChangeAgreement}
										></Checkbox>
									<label className="form-check-label body-text--small">
										
										
										I certify that I am at least 18 years old (or of legal age
										in my country) and that I agree to the{' '}
										{/*<a className="gradient-color-txt body-txtitalic--small">*/}
										{/*	Terms of Service, Privacy Policy, Spaces Owner Agreement*/}
										{/*</a>*/}
										{getSitewideLink(
											'terms_of_service_space_create',
											'gradient-color-txt body-txtitalic--small'
										)}
										,{' '}
										{getSitewideLink(
											'privacy_policy_space_create',
											'gradient-color-txt body-txtitalic--small'
										)}
										,{' '}
										{getSitewideLink(
											'spaces_owner_agreement',
											'gradient-color-txt body-txtitalic--small'
										)}
										, and{' '}
										{/*<a className="gradient-color-txt gradient-color-txt body-txtitalic--small">*/}
										{/*	Community Guidelines*/}
										{/*</a>*/}
										{getSitewideLink(
											'community_guidelines_space_create',
											'gradient-color-txt body-txtitalic--small'
										)}
										.
									</label>
								</div>
							</div>
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									You must read and agree to the terms, policies, agreements, and
									guidelines governing the Commonspace Platform and Services to
									continue.
								</p>
							</div>
						</div>
						<div className="mt-8 grid grid-cols-1 md:grid-cols-12">
							<div className="md:col-span-8">
								<Form.Item>
									<Button
										className="btn btn-lg btn-primary"
										htmlType="submit"
										disabled={submitted || formHasError || !agreementChecked}
									>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										Save & Continue
									</Button>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
			</main>
		</>
	);
};

export default memo(SpaceCreate);
