import { memo, useEffect } from 'react';

const Activity = () => {
	return (
		<>
			<div className="dashboard-wrapper h-full mt-4 flex items-center justify-center">
				<h1 className="header-large">Activity</h1>
			</div>
		</>
	);
};

export default memo(Activity);
