import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dialog, Popover, Listbox } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import co_logo from '../../../assets/images/co_logo.svg';
import routes from '../../../routes';
import './Header.scss';
import { useNavigate } from 'react-router-dom';
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }
  

const Header = (props) => {
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const navigate = useNavigate();
	const path = currentLocation.pathname;
	const [spaceUsername, setSpaceUsername] = useState(null);
	const [spacePath, setSpacePath] = useState(null);
	const [isDashboardView, setIsDashboardView] = useState(false);

	const goHome = useCallback(async () => {
		const path = currentLocation.pathname;

		if (authUserInfo) {
			window.location.replace(routes.dashboard());
		} else {
			if (path !== routes.login()) {
				window.location.replace(routes.login());
			}
		}
	}, [currentLocation.pathname, authUserInfo]);

	const login = async () => {
		navigate(routes.login())
	}
	const signup = async () => {
		console.log(routes.signUp())
		navigate(routes.signUp())
	}
	useEffect(() => {
		if (authUserInfo && authUserInfo.profile) {
			setProfileAssetType(null);
			if (authUserInfo.profile.photo_large) {
				setProfileAssetType('image');
			}
			if (authUserInfo.profile.video) {
				setProfileAssetType('video');
			}
		}
	}, [authUserInfo, loading, profileAssetType]);

	const redirectToSpaceLink = (route, spaceUsername) => {
		if (spaceUsername) {
			return route.replace(':space_username', '@' + spaceUsername);
		}
		return '';
	};

	useEffect(() => {
		const path = currentLocation.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.replace('/@', '');
			if (username.includes('/')) {
				const usernameExp = username.split('/');
				username = usernameExp[0] ? usernameExp[0] : '';
				setSpacePath(usernameExp[1])
				setIsDashboardView(path.startsWith('/@') && usernameExp[1] !== 'about');
				setSpaceUsername(username);
			}else{
				setIsDashboardView(false);
			}
		}})

	return (		
		<header className='w-full h-[72px] py-3 mx-0'>
			<nav
				className="mx-auto flex items-center justify-center md:justify-between px-4"
				aria-label="Global"
			>
				<div className="flex md:flex-1 lg:w-[392px] md:w-auto " onClick={goHome}>
					<Link href="#" >
						<span className="sr-only">commonspace</span>
						<img
							// className="h-10 xl:w-[316px] md:w-[206px] pt-2"
							className="h-10 w-[316px] pt-2"
							src={co_logo}
							alt="commonspace"
						/>
					</Link>
				</div>
				
				<Popover.Group className="hidden md:flex gap-[24px] max-[890px]:gap-[11px] mx-[13px] font-[notoSansSemiBold]">
					<Link to="#" className="font-normal leading-10 text-color-key header-links ">
		 				Explore
		 			</Link>
		 			<Link to="#" className="font-normal leading-10 text-color-key header-links ">
		 				Create
		 			</Link>
					<div className="relative inline-flex w-fit">
					<div className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full gradient-fill px-2.5 py-1 text-center align-baseline text-xs font-bold leading-none text-white">
						New
					</div>
					
					<Link to="#" className="font-normal leading-10 text-color-key header-links ">
						Collab
		 			</Link>
					</div>
					<Link to="#" className="font-normal leading-10 text-color-key header-links ">
		 				Learn
		 			</Link>
					 <Link to="#" className="font-normal leading-10 text-color-key header-links ">
		 				About
		 			</Link>
				</Popover.Group>
		 			{authUserInfo && (
						<div className="hidden md:flex md:flex-1 md:flex-row-reverse text-black gap-8">
							<div className="absolute">
								{/*<ul className="nav-login relative top-[-30px] min-[1300px]:top-[-40px]">*/}
								<ul className={classNames("nav-login relative",
									authUserInfo.profile && authUserInfo.profile.video || authUserInfo.profile && authUserInfo.profile.photo_thumb ? 'top-[-30px]' : 'top-[-21px]'
								)}>
									<li className='inline-block mr-8'>
										<Link to="#">
											<i className="far fa-magnifying-glass !text-xl"></i>
										</Link>
									</li>
									<li className='inline-block mr-8'>
										<Link to="#">
											<i className="far fa-bell"></i>
										</Link>
									</li>
									<li className='inline-block mr-8'>
										<Link to="#">
											<i className="far fa-wallet"></i>
										</Link>
									</li>
									<li className={classNames(profileAssetType, 'inline-block w-[48px]')}>
										<div className="dropdown h-10 w-10 relative -top-[2px]">
										<Listbox >
      										<Listbox.Button className={'rounded-full'}>
											  {profileAssetType &&
												profileAssetType === 'video' ? (
													<video autoPlay muted loop className='rounded-full object-cover h-10'>
														<source
														
															src={authUserInfo.profile && authUserInfo.profile.video ? authUserInfo.profile.video : ''}
															type="video/mp4"
														/>
													</video>
												) : (
													<>
														{profileAssetType === 'image' ? (
															<img
															className='rounded-full h-10 w-10 object-cover'
																src={
																	authUserInfo.profile && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : ''
																}
															/>
														) : (
															<div className="blank-avatar rounded-full h-10 w-10 flex justify-center text-xl items-center">
																<i className="fa-solid fa-user"></i>
															</div>
														)}
													</>
												)}
											</Listbox.Button>
											<Listbox.Options className={'absolute dropdown-menu dropdown-menu-end dropdown-menu-login z-50 border border-solid border-transparent gradient-color-border'}>
												{ !isDashboardView && (
													  <Listbox.Option>
														<Link
															to={routes.dashboard()}
															className="dropdown-item gradient-color-txt"
														>
															<i className="fa-regular fa-chart-tree-map"></i>{' '}
															Dashboard
														</Link>
													</Listbox.Option>													
												)}	
												{ !isDashboardView && (
													<Listbox.Option>
														<Link
														to={routes.collections()}
														className="dropdown-item"
													>
														<i className="fa-regular fa-grid"></i>{' '}
														Collections
														</Link>
													</Listbox.Option>	
						
												)}
												{ isDashboardView && (
													<Listbox.Option>
														<Link
														to={redirectToSpaceLink(
															routes.spaceDashboard(),
															spaceUsername
														)}
														className="dropdown-item"
														>
															<i className="fa-regular fa-chart-tree-map"></i>{' '}
															Dashboard
														</Link>
													</Listbox.Option>	
						
												)}	
												{ isDashboardView && (
													<Listbox.Option>
														<Link
														to={redirectToSpaceLink(
															routes.spaceActivity(),
															spaceUsername
														)}
														className="dropdown-item "
														>
														<i className="fa-regular fa-list-ul"></i>
														<span>Activity</span>
														</Link>
													</Listbox.Option>	
						
												)}	
												{ isDashboardView && (
													<Listbox.Option>
														<Link
														to={redirectToSpaceLink(
															routes.spaceMembers(),
															spaceUsername
														)}
														className="dropdown-item"
														>
															<i className="fa-regular fa-users"></i>
															<span>Members</span>
														</Link>
													</Listbox.Option>	
						
												)}
												{ isDashboardView && (
													<Listbox.Option>
														<Link
														to={redirectToSpaceLink(
															routes.spaceUpdates(),
															spaceUsername
														)}
														className="dropdown-item"
														>
															<i className="fa-regular fa-memo"></i>
															<span>Updates</span>
														</Link>
													</Listbox.Option>	
						
												)}
												{ isDashboardView && (
													<Listbox.Option>
														<Link
														to={redirectToSpaceLink(
															routes.spaceBenefits(),
															spaceUsername
														)}
														className="dropdown-item "
														>
															<i className="fa-regular fa-gift"></i>
															<span>Benefits</span>
														</Link>
													</Listbox.Option>	
						
												)}
												{ isDashboardView && (
													<Listbox.Option>
														<Link
														to={redirectToSpaceLink(routes.spaceItems(), spaceUsername)}
														className="dropdown-item"
														>
															<i className="fa-regular fa-hexagon-image"></i>
															<span>Items</span>
														</Link>
													</Listbox.Option>	
						
												)}
												{ isDashboardView && (
													// <Listbox.Option>
													// 	<Link
													// 	to={redirectToSpaceLink(
													// 		routes.spaceMemberships(),
													// 		spaceUsername
													// 	)}
													// 	className="dropdown-item "
													// 	>
													// 		<i className="fa-regular fa-id-card"></i>
													// 		<span>Memberships</span>
													// 	</Link>
													// </Listbox.Option>	
													<Listbox.Option>
													<Link
													to={redirectToSpaceLink(routes.spaceItems(), spaceUsername)}
													className="dropdown-item"
													>
														<i className="fa-regular fa-id-card"></i>
														<span>Memberships</span>
													</Link>
												</Listbox.Option>	
						
												)}
												{ isDashboardView && (
													<Listbox.Option>
														<Link
														to={redirectToSpaceLink(
															routes.spaceTransactions(),
															spaceUsername
														)}
														className="dropdown-item"
														>
															<i className="fa-regular fa-cash-register"></i>
															<span>Transactions</span>
														</Link>
													</Listbox.Option>	
						
												)}
												{ !isDashboardView && (
													<Listbox.Option>
														<Link
														to={routes.spaces()}
														className="dropdown-item"
														>
															<i className="fa-solid fa-solar-system"></i>{' '}
															Spaces
														</Link>
													</Listbox.Option>	
						
												)}
												{ isDashboardView && (
													<Listbox.Option>
														<Link
														to={redirectToSpaceLink(
															routes.spaceManageSpace(),
															spaceUsername
														)}
														className="dropdown-item "
														>
															<i className="fa-solid fa-solar-system"></i>{' '}
															Spaces
														</Link>
													</Listbox.Option>							
												)}
												{ !isDashboardView && (
													<Listbox.Option>
														<Link
														to={routes.profile()}
														className="dropdown-item"
														>
														<i className="fa-regular fa-id-badge"></i>{' '}
														Profile
													</Link>
													</Listbox.Option>							
												)}
												{ !isDashboardView && (
													<Listbox.Option>
														<Link
															to={routes.settings()}
															className="dropdown-item "
														>
														<i className="fa-regular fa-gear"></i>
														<span>Settings</span>
													</Link>
													</Listbox.Option>							
												)}
												{ isDashboardView && (
													<Listbox.Option>
														<Link
															to={redirectToSpaceLink(
																routes.spaceSettings(),
																spaceUsername
															)}
															className="dropdown-item "
															>
															<i className="fa-regular fa-gear"></i>
															<span>Settings</span>
														</Link>
													</Listbox.Option>							
												)}
												<Listbox.Option>
													<Link
														to={routes.logout()}
														className="dropdown-item"
													>
														<i className="fa-solid fa-arrow-right-from-bracket"></i>{' '}
														Logout
													</Link>
												</Listbox.Option>	
											</Listbox.Options>
											
										</Listbox>
											{/* 
												
												
												
												
												
												
												
												
												{ !isDashboardView && (
												<li>
													
												</li>
												)}
												{ !isDashboardView && (
													<li>
													
												</li>
												)}
												{ isDashboardView && (
												<li>
													
												</li>
												)}
												
												
											
												<li>
													
												</li>
											</ul> */}
										</div>
									</li>
								</ul>
							</div>
						</div>
					)}
					
				{!authUserInfo && (
				<div className="hidden md:flex md:flex-1 md:flex-row-reverse text-black gap-8">
					<div>
						<div  onClick={signup} className='border-filled md:w-[5rem] lg:w-32 xl:w-[148px]  lg:right-[-110px] h-[40px] rounded-[6rem] cursor-pointer  z-50  text-white flex justify-center mx-auto'>
							<span className='block  h-[32px] flex items-center font-[notoSansSemiBold] non-italic font-sans text-lg m-auto'>Sign Up</span>
							</div>
						</div>
					<div>
					<div  onClick={login} className='border-gradient md:w-[5rem] lg:w-32  xl:w-[148px]  lg:right-[-110px] h-[40px] rounded-[6rem] cursor-pointer  z-50  text-white flex justify-center mx-auto'>
						<span className='block h-[32px] flex items-center font-[notoSansSemiBold] non-italic font-sans text-lg m-auto'>
						<p className='gradient-color-txt' > Login </p>
						</span>
					</div>
					</div>
					
				</div>
				)}
			</nav>
			<Dialog
				as="div"
				className="lg:hidden"
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}
			>
				<div className="fixed inset-0 z-60" />
				<Dialog.Panel className="fixed inset-y-0 right-0 !z-[999] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
					<div className="flex items-center justify-between">
						<a href="#" className="-m-1.5 p-1.5">
							<span className="sr-only">commonspace</span>
							<img className="h-8 w-auto" src={co_logo} alt="" />
						</a>
						<button
							type="button"
							className="-m-2.5 rounded-md p-2.5 text-gray-700"
							onClick={() => setMobileMenuOpen(false)}
						>
							<span className="sr-only">Close menu</span>
							<XMarkIcon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					<div className="mt-6 flow-root">
						<div className="-my-6 divide-y divide-gray-500/10">
							<div className="py-6">
								<section className='-mx-3 block px-3 py-2.5'>
									<Link to="#">
										<i className="fa-solid fa-magnifying-glass gradient-color-txt"></i>
									</Link>
								</section>
								<section className='-mx-3 block px-3 py-2.5'>
									<Link to="#">
										<i className="fa-solid fa-bell gradient-color-txt"></i>
									</Link>
								</section>
								<section className='-mx-3 block px-3 py-2.5'>
									<Link to="#">
										<i className="fa-solid fa-wallet gradient-color-txt"></i>
									</Link>
								</section>
							</div>
						</div>
						<div className="-my-6 divide-y divide-gray-500/10">
							<div className="space-y-2 py-6">
								<section className='-mx-3 block px-3 py-2.5'>
									<Link
											to="#"
											className="dropdown-item"
										>
											<span className='gradient-color-txt'>Explore</span>
									</Link>
								</section>
								<section className='-mx-3 block px-3 py-2.5'>
									<Link
											to="#"
											className="dropdown-item"
										>
											<span className='gradient-color-txt'>Create</span>
									</Link>
								</section>
								<section className='-mx-3 block px-3 py-2.5'>
									<Link
											to="#"
											className="dropdown-item"
										>
											<span className='gradient-color-txt'>Collab</span>
									</Link>
								</section>
								<section className='-mx-3 block px-3 py-2.5'>
									<Link
											to="#"
											className="dropdown-item"
										>
											<span className='gradient-color-txt'>Learn</span>
									</Link>
								</section>
								<section className='-mx-3 block px-3 py-2.5'>
									<Link
											to="#"
											className="dropdown-item"
										>
											<span className='gradient-color-txt'>About</span>
									</Link>
								</section>
							</div>
							{!authUserInfo && (
							<div className="py-6">
								<a
									href="/login"
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									<p className='gradient-color-txt' >Log in</p>
								</a>
								<a
									href="/register"
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									<p className='gradient-color-txt' >Sign up</p>
								</a>
							</div>
							)} 
							{authUserInfo && (
								<div className="py-6">
									<section className='-mx-3 block px-3 py-2.5'>
										<Link
												to={routes.dashboard()}
												className="dropdown-item"
											>
												<i className="fa-regular fa-chart-tree-map gradient-color-txt"></i>{' '}
												<span className='gradient-color-txt'>Dashboard</span>
										</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
										<Link
											to={routes.spaces()}
											className="dropdown-item"
										>
											<i className="fa-solid fa-solar-system gradient-color-txt"></i>{' '}
											<span className='gradient-color-txt'>Spaces</span>
										</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
									<Link
										to={routes.profile()}
										className="dropdown-item"
									>
										<i className="fa-regular fa-id-badge gradient-color-txt"></i>{' '}
										<span className='gradient-color-txt'>Profile</span>
									</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
									<Link
										to={routes.collections()}
										className="dropdown-item"
									>
										<i className="fa-regular fa-grid"/>
										<span className='gradient-color-txt'>Collections</span>
									</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
											<Link
												to={routes.settings()}
												className="dropdown-item"
											>
												<i className="fa-regular fa-gear gradient-color-txt"></i>{' '}
												<span className='gradient-color-txt'>Settings</span>
											</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
											<Link
												to={routes.logout()}
												className="dropdown-item"
											>
												<i className="fa-regular fa-gear gradient-color-txt"></i>{' '}
												<span className='gradient-color-txt'>Logout</span>
											</Link>
									</section>
									
								</div>
							)}
							
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</header>
	);
};
export default memo(Header);
