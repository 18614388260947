import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Checkbox, DatePicker, Form} from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {getSitewideLink} from "../../../utils/sitewideLinks";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import FormFieldStacked from "../../../components/commons/FormFieldStacked";
import {showTooltip} from "../../../utils/input";
import DropdownWithCountries from "../../../components/commons/DropdownWithCountryRegion/DropdownWithCountries";
import DropdownWithRegions from "../../../components/commons/DropdownWithCountryRegion/DropdownWithRegions";
import {getCode} from "country-list";
import {createPaymentMethod} from "../../../services/payment_method";
import {getAuthUserInfo} from "../../../states/general/actions";
import {createNewCreditCard} from "../../../services/space";

const AddBillingInformation = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [addCardForm] = Form.useForm();
	const [submittedAddCardForm, setSubmittedAddCardForm] = useState(false);
	const [cardNumber, setCardNumber] = useState(null);
	const [expiry, setExpiry] = useState(null);
	const [cvc, setCvc] = useState(null);
	const [fullName, setFullName] = useState(null);
	const [streetAddress, setStreetAddress] = useState(null);
	const [city, setCity] = useState(null);
	const [address, setAddress] = useState(null);
	const [zip, setZip] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [selectedIdToUpdate, setSelectedIdToUpdate] = useState(null);
	const [submittedUpdateCardForm, setSubmittedUpdateCardForm] = useState(false);
	const { getCode, getName } = require('country-list');
	const [alert, setAlert] = useState(null);
	const [agreementChecked, setAgreementChecked] = useState(false);

	const handleFormChanges = useCallback(async () => {
		setCardNumber(addCardForm.getFieldValue('card_number'));
		setExpiry(addCardForm.getFieldValue('expiry'));
		setCvc(addCardForm.getFieldValue('cvc'));
		setFullName(addCardForm.getFieldValue('full_name'));
		setStreetAddress(addCardForm.getFieldValue('street_address'));
		setCity(addCardForm.getFieldValue('city'));
		setAddress(addCardForm.getFieldValue('address'));
		setZip(addCardForm.getFieldValue('zip'));
		setPhoneNumber(addCardForm.getFieldValue('phone_number'));
	}, []);

	const submitAddCardForm = useCallback(
		// async (values) => {
		// 	try {
		// 		const data = values;
		// 		setDisplayDropDownError(true);
		//
		// 		if (!selectedCountry || selectedCountry === '') {
		// 			return;
		// 		}
		// 		if (!selectedRegion || selectedRegion === '') {
		// 			return;
		// 		}
		// 		//TODO: change package this is only a temporary fix
		// 		let countryCode = getCode(selectedCountry);
		// 		if (!countryCode) {
		// 			if (selectedCountry === 'United States') {
		// 				countryCode = getCode('United States of America');
		// 			}
		// 		}
		//
		// 		data.country = countryCode;
		// 		data.state = selectedRegion;
		// 		data.expiry_mm = values.expiry.format('MM');
		// 		data.expiry_yy = values.expiry.format('YYYY');
		//
		// 		delete data.expiry;
		//
		// 		if (authUserInfo) {
		// 			setSubmittedAddCardForm(true);
		// 			data.user_id = authUserInfo.id;
		//
		// 			const response = await createPaymentMethod(data);
		// 			if (response && response.result) {
		// 				setShowAddCardForm(false);
		// 				dispatch(getAuthUserInfo());
		// 			}
		// 			setSubmittedAddCardForm(false);
		// 		}
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// },
		// [authUserInfo, selectedCountry, selectedRegion]
	);

	const submitAddCardInformation = useCallback(
		async (values) => {
			try {
				const data = values;

				setDisplayDropDownError(true);

				if (!selectedCountry || selectedCountry === '') {
					return;
				}
				if (!selectedRegion || selectedRegion === '') {
					return;
				}
				//TODO: change package this is only a temporary fix
				let countryCode = getCode(selectedCountry);
				if (!countryCode) {
					if (selectedCountry === 'United States') {
						countryCode = getCode('United States of America');
					}
				}

				data.country = countryCode;
				data.state = selectedRegion;
				data.expiry_mm = values.expiry.format('MM');
				data.expiry_yy = values.expiry.format('YYYY');
				data.address = values.street_address;
				data.cvc = values.cvc;
				data.card_number = values.card_number;
				data.full_name = values.full_name;
				data.street_address = values.street_address;
				data.city = values.city;
				data.phone_number = '0123456789';
				data.zip = values.zip;

				delete data.expiry;

				if (authUserInfo) {
					setSubmittedAddCardForm(true);
					data.user_id = authUserInfo.id;

					const response = await createNewCreditCard(data);
					if (response && response.result) {
						//setShowAddCardForm(false);
						dispatch(getAuthUserInfo());
						saveContinue();
					}
					setSubmittedAddCardForm(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo, selectedCountry, selectedRegion]
	);

	const saveContinue = () => {
		const url = routes.createSpacePrevLaunch();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createSpacePayment();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	const onChangeAgreement = (e) => {
		setAgreementChecked(e.target.checked);
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main className="dashboard-wrapper flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
						<div className="col-span-12 md:col-span-8 flex items-center">
							<h1 className="header-large !text-5xl">Add Billing Information</h1>
						</div>
						<div className="col-span-12 md:col-span-4 flex items-center justify-center  md:justify-end">
							<p className="body-txtitalic--large">Step 4 of 5</p>
						</div>
						<div className="col-span-12 mt-4">
							<h5 className="body-text--reg">
								Before your free trial period is over, you will need to add a credit
								card and billing information for your chosen plan. This ensures
								there is no interruption to your service. We will send email
								reminders with a link to get set up ahead of time to remind you so
								you don’t have any issues. {' '}
								{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	Learn more about Commonspace Plans and Pricing.*/}
								{/*</Link>*/}
								{getSitewideLink('learn_more_plans_pricing', 'gradient-color-txt body-txtitalic--reg')}
							</h5>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-y-4 mt-8">
						<div className="col-span-12 flex items-center">
							<h1 className="font-din2014Bold text-2xl gradient-color-txt">All Spaces are free for 30 days and Pro is free for 90 days!*</h1>
						</div>
						<div className="col-span-12 grid grid-cols-12 gap-x-8 font-notoSansRegular text-xl">
							<div className="col-span-4">
								<span className="font-semibold">
									{'Pro Spaces '}
								</span>
								get all monthly costs waived for 90 days
							</div>
							<div className="col-span-4">
								<span className="font-semibold">
									{'Basic Spaces with 100+ active members '}
								</span>
								get all monthly costs waived for 30 days
							</div>
							<div className="col-span-4">
								<span className="font-semibold">
									{'Basic Spaces with <100 active members '}
								</span>
								never have any monthly costs
							</div>
						</div>
						<div className="col-span-12 font-notoSansRegular text-sm italic text-[#888888]">
							* All Spaces have transactions fees for On-Platform Sales based on the Sales Rate of your chosen Plan and Payout Method.
						</div>
					</div>
					<Form
						form={addCardForm}
						name="addCardForm"
						validateMessages={VALIDATE_MESSAGES}
						onChange={handleFormChanges}
						onFinish={submitAddCardInformation}
					>
						<div className="grid gap-y-8 mt-8">
							<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
								<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
									<h5 className="header-medium leading-10 !font-bold">Add a Credit or Debit Card</h5>
									<span className="body-text--reg !italic">Optional</span>
								</div>
								<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">
									<button type="submit" className="btn btn-lg btn-secondary">
										Basic vs Pro Plan Comparison
										<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
									</button>
									{/*{getSitewideLink(*/}
									{/*	'view_guide_business_details',*/}
									{/*	'btn btn-lg btn-secondary'*/}
									{/*)}*/}
								</div>
							</div>
							<div className="grid gap-y-8">
								<div className='grid grid-cols-12 gap2 md:gap-8 items-end'>
									<div className="col-span-8 grid grid-cols-12 gap-x-8">
										<div className="col-span-12 md:col-span-6 !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												Card Number
											</label>
											<div className="relative rounded-none ">
												<Form.Item
													label="Card Number"
													name="card_number"
													className="mb-0 hidden-label"
													rules={[
														{
															required: true
														}
													]}
												>
													<FormFieldStacked
														formType='input'
														type="text"
														disabled={submittedAddCardForm}
														placeholder='15 or 16 digits'
													></FormFieldStacked>
												</Form.Item>
											</div>
										</div>
										<div className="col-span-12 md:col-span-3 !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												Expiry
											</label>
											<div className="relative rounded-none h-[40px] w-full text-gray-900 flex items-center ">
												<Form.Item
													label="Expiry"
													name="expiry"
													className="mb-0 hidden-label h-[40px] w-full "
													rules={[
														{
															type: 'object',
															required: true
														}
													]}
												>
													<DatePicker
														onBlur={showTooltip}
														className="body-text--small !bg-[#ffffff80] !text-lg h-[40px] w-full"
														picker="month"
														format="MM/YY"
														placeholder="MM/YY"
														disabled={submittedAddCardForm}
													/>
												</Form.Item>
											</div>
										</div>
										<div className="col-span-12 md:col-span-3 !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												CVV/CVC
											</label>
											<div className="relative rounded-none ">
												<Form.Item
													label="CVV/CVC"
													name="cvc"
													className="mb-0 hidden-label"
													rules={[
														{
															required: true
														}
													]}
												>

													<FormFieldStacked
														formType='input'
														type="number"
														disabled={submittedAddCardForm}
														placeholder='3 or 4 digits'>
													</FormFieldStacked>
												</Form.Item>
											</div>
										</div>
									</div>
									<div className="col-span-4 font-notoSansRegular !text-sm !italic !text-black pt-6 self-center">
										All major debit cards, credit cards, Apple Pay, and Google Pay are accepted. Your full card number is not visible to Commonspace.
									</div>
								</div>
								<div className='grid grid-cols-12 gap2 md:gap-8 items-end'>
									<div className="col-span-8 grid grid-cols-12 gap-x-8">
										<div className="col-span-12 my-auto !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												Full Name
											</label>
											<div className="relative rounded-none ">
												<Form.Item
													label="Full Name"
													name="full_name"
													className="mb-0 hidden-label"
													rules={[
														{
															required: true
														}
													]}
												>

													<FormFieldStacked
														formType='input'
														type="text"
														disabled={submittedAddCardForm}
														placeholder='Name on card'
													></FormFieldStacked>
												</Form.Item>
											</div>
										</div>
									</div>
									<div className="col-span-4 font-notoSansRegular !text-sm !italic !text-black pt-6 self-center">
										This should match the name on your card.
									</div>
								</div>
								<div className='grid grid-cols-12 gap2 md:gap-8 items-end'>
									<div className="col-span-8 grid grid-cols-12 gap-x-8">
										<div className="col-span-12 my-auto !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												Country
											</label>
											<div className="relative rounded-none border-0 ">
												<DropdownWithCountries
													title="Country"
													displayError={
														displayDropDownError &&
														(!selectedCountry || selectedCountry === '')
													}
													value={selectedCountry}
													onDropdownChange={setSelectedCountry}
												></DropdownWithCountries>
											</div>
										</div>
									</div>
									<div className="col-span-4 font-notoSansRegular !text-sm !italic !text-black pt-6 self-center">
										Select the country for the billing address for your card.
									</div>
								</div>
								<div className='grid grid-cols-12 gap2 md:gap-8 items-end'>
									<div className="col-span-8 grid grid-cols-12 gap-x-8">
										<div className="col-span-12 my-auto !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												Street Address
											</label>
											<div className="relative rounded-none ">
												<Form.Item
													label="Street Address"
													name="street_address"
													className="mb-0 hidden-label"
													rules={[
														{
															required: true
														}
													]}
												>

													<FormFieldStacked
														formType='input'
														type="text"
														disabled={submittedAddCardForm}
														placeholder='Number and Street'
													></FormFieldStacked>
												</Form.Item>
											</div>
										</div>
									</div>
									<div className="col-span-4 font-notoSansRegular !text-sm !italic !text-black pt-6 self-center">
										This should match the billing address for your card.
									</div>
								</div>
								<div className='grid grid-cols-12 gap2 md:gap-8 items-end'>
									<div className="col-span-8 grid grid-cols-12 gap-x-8">
										<div className="col-span-12 my-auto !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												APT/STE/BLDG
											</label>
											<div className="relative rounded-none ">
												<Form.Item
													label="APT/STE/BLDG"
													name="address"
													className="mb-0 hidden-label"
												>

													<FormFieldStacked
														formType='input'
														type="text"
														disabled={submittedAddCardForm}
														placeholder='If applicable'
													></FormFieldStacked>
												</Form.Item>
											</div>
										</div>
									</div>
									<div className="col-span-4 font-notoSansRegular !text-sm !italic !text-black pt-6 self-center">
										Optional
									</div>
								</div>
								<div className='grid grid-cols-12 gap2 md:gap-8 items-end'>
									<div className="col-span-8 grid grid-cols-12 gap-x-8">
										<div className="col-span-12 md:col-span-6 !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												City
											</label>
											<div className="relative rounded-none ">
												<Form.Item
													label="City"
													name="city"
													className="mb-0 hidden-label"
													rules={[
														{
															required: true
														}
													]}
												>

													<FormFieldStacked
														formType='input'
														type="text"
														disabled={submittedAddCardForm}
														placeholder=''
													></FormFieldStacked>
												</Form.Item>
											</div>
										</div>
										<div className="col-span-12 md:col-span-3 !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												State
											</label>
											<div className="relative rounded-none border-0 ">
												<DropdownWithRegions
													title="State"
													displayError={
														displayDropDownError &&
														(!selectedRegion || selectedRegion === '')
													}
													country={selectedCountry}
													value={selectedRegion}
													onDropdownChange={setSelectedRegion}
												></DropdownWithRegions>
											</div>
										</div>
										<div className="col-span-12 md:col-span-3 my-auto !order-last md:!order-first">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												Zip
											</label>
											<div className="relative rounded-none ">
												<Form.Item
													label="Zip"
													name="zip"
													className="mb-0 hidden-label"
													rules={[
														{
															required: true
														}
													]}
												>
													<FormFieldStacked
														formType='input'
														type="number"
														disabled={submittedAddCardForm}
														placeholder='5 digits'>
													</FormFieldStacked>
												</Form.Item>
											</div>
										</div>
									</div>
									<div className="col-span-4 font-notoSansRegular !text-sm !italic !text-black pt-6 self-center">
										This should match the billing address for your card.
									</div>
								</div>
								<div className="grid grid-cols-12 gap-x-8">
									<div className="col-span-8 form-check flex items-center">
										<Checkbox
											checked={agreementChecked}
											className="inline-flex form-check-input items-center ml-2 mr-10 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={onChangeAgreement}
										></Checkbox>
										<label className="form-check-label body-text--small">
											{'I certify that I am the cardholder specified above and that authorize Commonspace to charge my card in accordance with the '}
											<a className="gradient-color-txt body-txtitalic--small">
												{'Commonspace Plans and Pricing, '}
											</a>
											{getSitewideLink(
												'terms_of_service_space_create',
												'gradient-color-txt body-txtitalic--small'
											)}
											, and{' '}
											{/*<a className="gradient-color-txt gradient-color-txt body-txtitalic--small">*/}
											{/*	Community Guidelines*/}
											{/*</a>*/}
											{getSitewideLink(
												'spaces_owner_agreement',
												'gradient-color-txt body-txtitalic--small'
											)}
											{' which I have agreed to.'}
										</label>
									</div>
									<div className="col-span-4 font-notoSansRegular !text-sm !italic gradient-color-txt self-center">
										All payments are processed by Stripe using AES-256 Encryption for your security.
									</div>
								</div>
							</div>
							<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 grid-flow-row">
								<div className="col-span-8 !order-first md:!order-last h-10">
									<Form.Item>
										<Button
											className="btn btn-primary btn-md !mb-0"
											htmlType="submit"
											disabled={submittedAddCardForm || !agreementChecked}
										>
											{submittedAddCardForm && (
												<>
													<i className="fa-solid fa-spin fa-spinner"></i>
												</>
											)}
											Authorize Credit Card & Continue
										</Button>
									</Form.Item>
								</div>
							</div>
						</div>
					</Form>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8">
						<div className="md:col-span-8">
							<Button
								onClick={saveContinue}
								className="btn btn-lg btn-secondary"
								htmlType="submit"
							>
								Skip
							</Button>
						</div>
						{/*<div className="md:col-span-4 flex items-end">*/}
						{/*	<Button*/}
						{/*		onClick={saveBack}*/}
						{/*		className="btn btn-lg btn-warning bg-none"*/}
						{/*		htmlType="submit"*/}
						{/*	>*/}
						{/*		Go Back*/}
						{/*	</Button>*/}
						{/*</div>*/}
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(AddBillingInformation);
