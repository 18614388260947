import { memo, useEffect } from 'react';

const DashboardHome = () => {
	return (
		<>
			<div className="dashboard-wrapper h-full flex items-center justify-center">
				<h1 className="header-large">Coming Soon</h1>
			</div>
		</>
	);
};

export default memo(DashboardHome);
