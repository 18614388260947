import React, { memo, useEffect, useCallback, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Button, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import { VALIDATE_MESSAGES } from '../../../constants/common';
import { hideTooltip, showTooltip } from '../../../utils/input';
import { getSpaceUpdates, subscribeNewsletter } from '../../../services/space';
import Communities from '../Communities';
import LatestUpdates from '../LatestUpdates';
import MemberBenefits from '../MemberBenefits';
import FeaturedLinks from '../FeaturedLinks';
import Benefits from '../Tabs/Benefits';
import HeaderProMenu from './HeaderPro/HeaderProMenu';
import Channel from '../../Channel';
import BenefitsPro from './Tabs/BenefitsPro';
import UpdatesPro from './Tabs/UpdatesPro';
import SpaceClaimMembership from '../../SpaceClaimMembership';
import SpaceClaimItem from '../../SpaceClaimItem';

import './SpaceViewPro.scss';
import default_profile_card_bg from '../../../assets/images/profile_header.png';
import UpdatesContent from '../LatestUpdates/UpdatesContent';

import SpaceBuyMembership from '../../SpaceBuyMembership';
import SpaceBuyItem from '../../SpaceBuyItem';
import SpaceInformationPages from '../SpaceInformationPages';
import routes from '../../../routes';
import { CustomValidators } from '../../../utils/CustomValidators';

const SpaceViewPro = (props) => {
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const urlTab = searchParams.get('tab');
	const { spaceData, globalActiveTab, updateGlobalActiveTab } = props;
	const pro_styles = spaceData.pro_style;
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [subscriptionForm] = Form.useForm();
	const [submittedSubscription, setSubmittedSubscription] = useState(false);
	const [alert, setAlert] = useState(null);
	const [spaceUpdates, setSpaceUpdates] = useState(null);
	const [spaceUpdatesIsFetching, setSpaceUpdatesIsFetching] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [spaceUsername, setSpaceUsername] = useState(null);
	const [updatesViewingUserIsMember, setUpdatesViewingUserIsMember] = useState(false);
	const [playVideo, setPlayVideo] = useState(false);
	const [linksViewAll, setLinksViewAll] = useState(false);

	let selectedWelcomeAssetType = 'image';
	if (spaceData.welcome_youtube_url) {
		selectedWelcomeAssetType = 'youtube';
	} else {
		if (spaceData.welcome_video) {
			selectedWelcomeAssetType = 'video';
		}
	}

	let header_background_image =
		spaceData && spaceData.background_photo
			? spaceData.background_photo
			: default_profile_card_bg;
	header_background_image =
		pro_styles && pro_styles.header_background_image
			? pro_styles.header_background_image
			: header_background_image;

	let header_background_video =
		spaceData && pro_styles && pro_styles.header_background_video
			? pro_styles.header_background_video
			: spaceData.background_video;

	let hide_default_video = false;
	if (
		spaceData &&
		spaceData.background_video &&
		pro_styles &&
		pro_styles.header_background_image
	) {
		// override space default video
		hide_default_video = true;
	}

	//google fonts
	const fontTitle = spaceData && pro_styles ? pro_styles.title_font : 'Josefin Sans'; //change value from database
	const fontBody = spaceData && pro_styles ? pro_styles.body_font : 'Bungee Inline'; //change value from database
	const googleFontUrl = 'https://fonts.googleapis.com/css?family=';

	if (fontTitle) {
		const proFontTitle = document.createElement('link');
		proFontTitle.rel = 'stylesheet';
		proFontTitle.href = googleFontUrl + fontTitle.replace(' ', '+');
		document.head.appendChild(proFontTitle);
	}

	if (fontBody) {
		const proFontBody = document.createElement('link');
		proFontBody.rel = 'stylesheet';
		proFontBody.href = googleFontUrl + fontBody.replace(' ', '+');
		document.head.appendChild(proFontBody);
	}

	//Logo & Key Color
	const root = document.documentElement;

	//Key Color and Fonts
	root.style.setProperty('--key-color', pro_styles.key_color);
	root.style.setProperty(
		'--key-color-gradient',
		pro_styles.has_gradient ? pro_styles.gradient_color : pro_styles.key_color
	);
	root.style.setProperty('--title-font', fontTitle);
	root.style.setProperty('--body-font', fontBody);

	//Section Colors & Backgrounds
	//Light Section Text & Background Colors
	root.style.setProperty('--light-section-text-color', pro_styles.light_text_color);
	root.style.setProperty('--light-section-bg-color', pro_styles.light_background_color);
	if (!pro_styles.light_background_video) {
		root.style.setProperty(
			'--light-section-bg-image',
			'url("' + pro_styles.light_background_image + '")'
		);
	}
	root.style.setProperty('--light-section-image-pos', pro_styles.light_image_position);
	root.style.setProperty('--light-section-video-pos', pro_styles.light_video_position);

	//Dark Section Text & Background Colors
	root.style.setProperty('--dark-section-text-color', pro_styles.dark_text_color);
	root.style.setProperty('--dark-section-bg-color', pro_styles.dark_background_color);
	if (!pro_styles.dark_background_video) {
		root.style.setProperty(
			'--dark-section-bg-image',
			'url("' + pro_styles.dark_background_image + '")'
		);
	}
	root.style.setProperty('--dark-section-image-pos', pro_styles.dark_image_position);
	root.style.setProperty('--dark-section-video-pos', pro_styles.dark_video_position);

	const getUpdates = useCallback(
		async (data) => {
			if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0)) {
				setSpaceUpdatesIsFetching(true);
			}

			try {
				const publish_statuses = ['immediately', 'published', 'publishing'];
				const params = {
					space_id: data.id,
					in_space_view: true,
					publish_statuses: JSON.stringify(publish_statuses)
				};

				if (authUserInfo) {
					params.viewing_user_id = authUserInfo.id;
				}

				const result = await getSpaceUpdates(params);
				if (result && result.result) {
					if (result.data) {
						setSpaceUpdates(result.data);
						setUpdatesViewingUserIsMember(result.viewing_user_is_member);
					} else {
						setSpaceUpdates(null);
					}
				}

				setSpaceUpdatesIsFetching(false);
			} catch (error) {
				console.log(error);
				setSpaceUpdatesIsFetching(false);
			}
		},
		[authUserInfo, spaceUpdates]
	);

	let latestUpdatesCount = 0;
	const latestUpdates = [];
	if (spaceData.updates) {
		latestUpdatesCount = spaceData.updates.length;
		spaceData.updates.forEach((item, index) => {
			if (index <= 2) {
				latestUpdates.push(item);
			}
		});
	}

	const featuredLinks = [];
	const limitedFeaturedLinks = [];
	if (spaceData.links) {
		spaceData.links.forEach((item, index) => {
			if (index <= 2) {
				limitedFeaturedLinks.push(item);
			}

			featuredLinks.push(item);
		});
	}

	let firstMembership = null;
	const spaceMembershipsAndItems = [];
	if (spaceData.memberships) {
		spaceData.memberships.forEach((item, index) => {
			// Don't add subscription item/membership if benefits doesn't exist
			{
				item.enable_expiration_and_subscription && item.benefits.length < 1
					? null
					: spaceMembershipsAndItems.push(item);
			}
			if (index === 0) {
				firstMembership = item;
			}
		});
	}
	if (spaceData.items) {
		spaceData.items.forEach((item, index) => {
			// Don't add subscription item/membership if benefits doesn't exist
			{
				item.enable_expiration_and_subscription && item.benefits.length < 1
					? null
					: spaceMembershipsAndItems.push(item);
			}
		});
	}

	if (spaceData && spaceMembershipsAndItems && spaceMembershipsAndItems.length < 3) {
		const membershipCount = spaceMembershipsAndItems.length;
		let remainingMembershipCount = 3 - membershipCount;
		while (remainingMembershipCount > 0) {
			spaceMembershipsAndItems.push({});
			remainingMembershipCount--;
		}
	}

	const onlyThreeSpaceMembershipsAndItems = [];
	if (spaceMembershipsAndItems.length > 0) {
		spaceMembershipsAndItems.forEach((item, index) => {
			if (index <= 2) {
				// Don't add subscription item/membership if benefits doesn't exist
				{
					item.enable_expiration_and_subscription && item.benefits.length < 1
						? null
						: onlyThreeSpaceMembershipsAndItems.push(item);
				}
			}
		});
	}

	let benefitsCount = 0;
	const benefits = [];
	if (spaceData.benefits) {
		benefitsCount = spaceData.benefits.length;
		spaceData.benefits.forEach((item, index) => {
			if (index <= 2) {
				benefits.push(item);
			}
		});
	}

	const handleSubscriptionFormSubmit = useCallback(async (values) => {
		if (values.email) {
			setSubmittedSubscription(true);
			setAlert(null);

			const data = {
				space_id: spaceData.id,
				email: values.email
			};

			const response = await subscribeNewsletter(data);
			if (response && response.result) {
				setAlert({
					variant: 'success',
					message: response.message
				});
			} else {
				setAlert({
					variant: 'danger',
					message: response.message
				});
			}
			subscriptionForm.resetFields();
			setSubmittedSubscription(false);
		}
	}, []);

	let foreground_position = 'center';

	if (pro_styles.foreground_position === 'top' && !pro_styles.foreground_text) {
		foreground_position = 'start';
	} else if (pro_styles.foreground_position === 'bottom' && !pro_styles.foreground_text) {
		foreground_position = 'end';
	}

	let lightVideoStyle = {
		objectFit: 'cover',
		height: '100%'
	};

	if (pro_styles.light_video_position === 'top') {
		lightVideoStyle = {
			top: '0'
		};
	} else if (pro_styles.light_video_position === 'center') {
		lightVideoStyle = {
			top: '50%',
			left: '50%',
			transform: 'translateX(-50%) translateY(-50%)'
		};
	} else if (pro_styles.light_video_position === 'bottom') {
		lightVideoStyle = {
			bottom: '0'
		};
	}

	let darkVideoStyle = {
		objectFit: 'cover',
		height: '100%'
	};

	if (pro_styles.dark_video_position === 'top') {
		darkVideoStyle = {
			top: '0'
		};
	} else if (pro_styles.dark_video_position === 'center') {
		darkVideoStyle = {
			top: '50%',
			left: '50%',
			transform: 'translateX(-50%) translateY(-50%)'
		};
	} else if (pro_styles.dark_video_position === 'bottom') {
		darkVideoStyle = {
			bottom: '0'
		};
	}

	const setActiveTabChange = (item) => {
		updateGlobalActiveTab(item);

		if (item === 'home' || item === 'members') {
			// force page url navigate to reset location path when going back to home
			navigate(routes.viewUserSpace() + spaceData.username, { replace: true });
		}
	};

	const setVideoPlay = () => {
		if (globalActiveTab !== 'home') {
			setPlayVideo(false);
		}
	};

	const setLinksViewAllChange = useCallback(async () => {
		if (linksViewAll) {
			setLinksViewAll(false);
		} else {
			setLinksViewAll(true);
		}
	}, [linksViewAll]);

	useEffect(() => {
		const path = window.location.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}

		if (spaceData) {
			setUpdatesViewingUserIsMember(spaceData.viewing_user_is_member);
		}

		if (spaceUsername != null) {
			if (globalActiveTab === 'updates') {
				getUpdates(spaceData).then();
				if (!path.includes('update')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/update');
				}
			} else if (globalActiveTab === 'home') {
				if (!path.includes('home')) {
					window.history.replaceState(null, null, '/@' + spaceUsername);
				}
			} else if (globalActiveTab === 'benefits') {
				if (!path.includes('benefit')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/benefit');
				}
			} else if (globalActiveTab === 'members') {
				if (!path.includes('member')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/member');
				}
			} else if (globalActiveTab === 'support') {
				if (!path.includes('support')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/support');
				}
			} else if (globalActiveTab === 'about') {
				if (!path.includes('about')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/about');
				}
			}
		}

		const availableTabs = ['home', 'updates', 'benefits', 'members'];
		if (initialLoad) {
			setInitialLoad(false);

			if (availableTabs.includes(urlTab)) {
				setActiveTabChange(urlTab);
				searchParams.delete('tab');
				setSearchParams(searchParams);
			}
		}

		if (globalActiveTab === 'update_content') {
			if (availableTabs.includes(globalActiveTab)) {
				let baseUrl = '/@' + spaceUsername;
				if (globalActiveTab === 'home') {
					baseUrl = baseUrl;
				}
				if (globalActiveTab === 'updates') {
					baseUrl = baseUrl + '/update';
				}
				if (globalActiveTab === 'benefits') {
					baseUrl = baseUrl + '/benefit';
				}
				if (globalActiveTab === 'members') {
					baseUrl = baseUrl + '/member';
				}
				if (globalActiveTab === 'support') {
					baseUrl = baseUrl + '/support';
				}
				if (globalActiveTab === 'about') {
					baseUrl = baseUrl + '/about';
				}
				window.history.replaceState(null, null, baseUrl);
				navigate(baseUrl);
			}
		}

		setVideoPlay();
	}, [spaceData, initialLoad, urlTab, globalActiveTab, spaceUsername, playVideo]);
	return (
		<>
			<main
				id="profile"
				className={
					'space-view-pro ' + (pro_styles.has_gradient ? 'has-gradient' : 'no-gradient')
				}
			>
				<div
					className="w-full profile-banner "
					style={{
						backgroundImage: `url(${header_background_image})`
					}}
				>
					{!hide_default_video && (
						<>
							<video autoPlay muted loop id="">
								<source src={header_background_video} type="video/mp4" />
							</video>
						</>
					)}
					<div
						className={`container sv-h flex justify-center align-items-${foreground_position}`}
					>
						{pro_styles.header_foreground_image && !pro_styles.foreground_text && (
							<img
								className="fore-ground-img"
								src={pro_styles.header_foreground_image}
							/>
						)}
						{pro_styles.foreground_text && (
							<h3 className="header-medium">{pro_styles.foreground_text}</h3>
						)}
					</div>
				</div>
				{pro_styles.header_layout === 'split' && (
					<div className="container-fluid split-header flex items-center">
						<div className="container p-0">
							<HeaderProMenu
								spaceData={spaceData}
								activeTab={globalActiveTab}
								onSelectedTabChange={setActiveTabChange}
							/>
						</div>
					</div>
				)}
				<div className="w-full">
					<div className="p-0 space-view-pro-container !bg-transparent">
						<div
							className="tab-content space-profile-url"
							id="pills-tabContent"
						>
							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'home' ? 'show active' : 'hidden')
								}
							>
								<div className="light-section">
									<video autoPlay muted loop style={lightVideoStyle}>
										<source
											src={pro_styles.light_background_video}
											type="video/mp4"
										/>
									</video>
									<div className="container">
										<div className="grid grid-cols-12 gap-x-0 lg:gap-x-8 pt-5 ">
											<div className="col-span-12 lg:col-span-7 flex justify-center lg:items-start">
												<div className="img-vid-container w-full">
													{selectedWelcomeAssetType ===
														'image' && (
															<img
																className="img-fluid"
																src={
																	spaceData.welcome_photo_medium
																}
															/>
														)}
													{selectedWelcomeAssetType ===
														'video' && (
															<ReactPlayer
																onPlay={() =>
																	setPlayVideo(true)
																}
																onStart={() =>
																	setPlayVideo(true)
																}
																playing={playVideo}
																controls={true}
																width="100%"
																height="100%"
																className="videoPreview"
																url={spaceData.welcome_video}
															/>
														)}
													{selectedWelcomeAssetType ===
														'youtube' && (
															<ReactPlayer
																onPlay={() =>
																	setPlayVideo(true)
																}
																onStart={() =>
																	setPlayVideo(true)
																}
																playing={playVideo}
																controls={true}
																width="100%"
																height="100%"
																className="youtubeVideoPreview"
																url={
																	spaceData.welcome_youtube_url
																}
															/>
														)}
												</div>
											</div>
											<div className="col-span-12 lg:col-span-5 lg:max-w-560 grid items-center content-center">
												<div className="mt-8 lg:mt-0 w-full">
													<h4 className="header-medium ">
														{spaceData.welcome_title}
													</h4>
												</div>
												<div className="mt-8 w-full">
													<p className="body-text--large ">
														{spaceData.welcome_message}
													</p>
												</div>
												<div className="mt-8 w-full">
													{spaceData.has_newsletters_signup && (
														<>
															<Form
																form={subscriptionForm}
																name="subscriptionForm"
																id="subscriptionForm"
																validateMessages={
																	VALIDATE_MESSAGES
																}
																onFinish={
																	handleSubscriptionFormSubmit
																}
																onFocus={() => {
																	setAlert(null);
																}}
																onBlur={() => {
																	setAlert(null);
																}}
																className="flex-shrink"
															>
																<Button
																	className="btn btn-md btn-primary"
																	onClick={(event) =>
																		setActiveTabChange(
																			'members'
																		)
																	}
																>
																	Join the Community
																</Button>
																<>
																	{alert && (
																		<div className="mt-4">
																			<div className="col-span-8">
																				<Alert
																					key={
																						alert.variant
																					}
																					variant={
																						alert.variant
																					}
																					className={
																						'custom-alert ' +
																						(alert.variant ===
																						'danger'
																							? 'custom-alert-danger'
																							: 'custom-alert-success')
																					}
																				>
																					{
																						alert.message
																					}
																				</Alert>
																			</div>
																		</div>
																	)}
																</>
																<div className="input-group-com input-with-btn-subscribe mt-8 mb-8">
																	<Form.Item
																		label="Email"
																		name="email"
																		className="mb-0 hidden-label"
																		rules={[
																			{ required: false },
																			{
																				validator:
																				CustomValidators.email
																			}
																		]}
																	>
																		<Input
																			onFocus={
																				hideTooltip
																			}
																			onBlur={showTooltip}
																			placeholder="Enter email to subscribe to newsletter."
																			className="input-md body-txtitalic--smallest color-light"
																			disabled={
																				submittedSubscription
																			}
																		/>
																	</Form.Item>
																	<Form.Item>
																		<Button
																			className="btn btn-secondary-dd btn-md btn-subscribe"
																			htmlType="submit"
																			disabled={
																				submittedSubscription
																			}
																		>
																			{submittedSubscription && (
																				<i className="fa-solid fa-spinner fa-spin me-2"></i>
																			)}
																			Subscribe
																		</Button>
																	</Form.Item>
																</div>
															</Form>
														</>
													)}
													<ul className="list-socials mt-0">
														<li>
															{/* <a href=""> */}
															<i className="fa-brands fa-twitter	"></i>
															{/* </a> */}
														</li>
														<li>
															{/* <a href=""> */}
															<i className="fa-brands fa-instagram"></i>
															{/* </a> */}
														</li>
														<li>
															{/* <a href=""> */}
															<i className="fa-brands fa-facebook"></i>
															{/* </a> */}
														</li>
														<li>
															{/* <a href=""> */}
															<i className="fa-brands fa-tiktok"></i>
															{/* </a> */}
														</li>
														<li>
															{/* <a href=""> */}
															<i className="fa-brands fa-youtube "></i>
															{/* </a> */}
														</li>
														<li>
															{/* <a href=""> */}
															<i className="fa-brands fa-linkedin "></i>
															{/* </a> */}
														</li>
														<li>
															{/* <a href=""> */}
															<i className="fa-brands fa-discord "></i>
															{/* </a> */}
														</li>
														<li>
															{/* <a href=""> */}
															<i className="fa-brands fa-twitch "></i>
															{/* </a> */}
														</li>
														<li>
															{/* <a href=""> */}
															<i className="fa-regular fa-globe "></i>
															{/* </a> */}
														</li>
													</ul>
												</div>
											</div>
										</div>

										{spaceData && onlyThreeSpaceMembershipsAndItems && onlyThreeSpaceMembershipsAndItems.length > 0 && (
											<div className="mt-5">
												<div className="w-full grid grid-cols-12 items-center ">
													<h4 className="leading-tight header-large col-span-12 md:col-span-10 flex justify-self-center md:justify-self-start">
														Join Community
													</h4>
													{spaceData &&
														spaceMembershipsAndItems &&
														spaceMembershipsAndItems.length > 3 && (
															<>
																<Link
																	className="header-title gradient-color-txt
																	md:mt-0 col-span-12 md:col-span-2
																	flex justify-self-center md:justify-self-end"
																	onClick={(event) =>
																		setActiveTabChange(
																			'members'
																		)
																	}
																>
																	View All
																</Link>
															</>
														)
													}
												</div>
												<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5 ">
													{onlyThreeSpaceMembershipsAndItems.slice(0, 2).map((data, index) => (
														<div key={index} className="row-span-5 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
															<Communities
																data={data}
																user={authUserInfo}
																spaceData={spaceData}
																updateGlobalActiveTab={
																	updateGlobalActiveTab
																}
															></Communities>
														</div>
													))}
													{onlyThreeSpaceMembershipsAndItems.map((data, index) =>
														<div key={index} className="row-span-5 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0 mb-8">
															<Communities
																key={index}
																data={data}
																user={authUserInfo}
																spaceData={spaceData}
																updateGlobalActiveTab={
																	updateGlobalActiveTab
																}
															></Communities>
														</div>

														)}
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="dark-section">
									<div className="pb-10">
										<video autoPlay muted loop style={darkVideoStyle}>
											<source
												src={pro_styles.dark_background_video}
												type="video/mp4"
											/>
										</video>
										<div className="container">
											{spaceData && latestUpdates.length > 0 && (
												<div className="pt-5">
													<div className="w-full grid grid-cols-12 items-center">
														<h4 className="leading-tight header-large col-span-12 md:col-span-10 flex justify-self-center md:justify-self-start">
															Latest Updates
														</h4>
														{spaceData && latestUpdatesCount > 3 && (
															<Link
																className="header-title gradient-color-txt
																	md:mt-0 col-span-12 md:col-span-2
																	flex justify-self-center md:justify-self-end"
																onClick={(event) =>
																	setActiveTabChange('updates')
																}
															>
																View All
															</Link>
														)}
													</div>
													<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5 ">
														{latestUpdates.slice(0, 2).map((data, index) => (
															<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
																<LatestUpdates
																	data={data}
																	user={authUserInfo}
																	spaceData={spaceData}
																	updatesViewingUserIsMember={
																		updatesViewingUserIsMember
																	}
																	updateGlobalActiveTab={
																		updateGlobalActiveTab
																	}
																></LatestUpdates>
															</div>
														))}
														{latestUpdates.map((data, index) =>
															<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0">
																<LatestUpdates
																	data={data}
																	user={authUserInfo}
																	spaceData={spaceData}
																	updatesViewingUserIsMember={
																		updatesViewingUserIsMember
																	}
																	updateGlobalActiveTab={
																		updateGlobalActiveTab
																	}
																></LatestUpdates>
															</div>

														)}
													</div>
												</div>
											)}

											{spaceData && benefits && benefits.length > 0 && (
												<div className="mt-5">
													<div className="w-full grid grid-cols-12 items-center">
														<h4 className="leading-tight header-large col-span-12 md:col-span-10 flex justify-self-center md:justify-self-start">
															Member Benefits
														</h4>
														{spaceData && benefitsCount > 3 && (
															<Link
																className="header-title gradient-color-txt
																	md:mt-0col-span-12 md:col-span-2
																	flex justify-self-center md:justify-self-end"
																href=""
																onClick={(event) =>
																	setActiveTabChange('benefits')
																}
															>
																View All
															</Link>
														)}
													</div>
													<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5  ">
														{benefits.slice(0, 2).map((data, index) => (
															<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
																<MemberBenefits
																	data={data}
																	user={authUserInfo}
																	spaceData={spaceData}
																></MemberBenefits>
															</div>
														))}

														{benefits.map(
															(data, index) =>
																<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0">
																	<MemberBenefits
																		key={index}
																		data={data}
																		user={authUserInfo}
																		spaceData={spaceData}
																	></MemberBenefits>
																</div>

														)}
													</div>
												</div>
											)}

											{spaceData && featuredLinks.length > 0 && (
												<div className="mt-5">
													<div className="w-full grid grid-cols-12 items-center">
														<h4 className="leading-tight text-[28px] header-large  md:text-[48px] col-span-12 md:col-span-10 flex  md:justify-self-start">
															{spaceData &&
															spaceData.link_title_first ? (
																<>
																	{spaceData.link_title_first}{' '}
																	{spaceData.link_title_second}
																</>
															) : (
																<>Featured Links</>
															)}
														</h4>
														{spaceData &&
															spaceData.plan === 'pro' &&
															featuredLinks.length > 3 && (
																<Link
																	className="header-title gradient-color-txt
																	md:mt-0 col-span-12 md:col-span-2
																	flex justify-self-center md:justify-self-end"
																	onClick={(event) =>
																		setLinksViewAllChange()
																	}
																>
																	{linksViewAll
																		? 'View Less'
																		: 'View All'}
																</Link>
															)
														}
													</div>
													<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5 ">
														{linksViewAll ? (
															<>
																{featuredLinks.slice(0, 2).map((data, index) => (
																	<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
																		<FeaturedLinks
																			data={data}
																			index={index}
																		></FeaturedLinks>
																	</div>
																))}
																{featuredLinks.map(
																	(data, index) =>
																		<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0">
																			<FeaturedLinks
																				data={data}
																				index={index}
																			></FeaturedLinks>
																		</div>

																)}
															</>
														) : (
															<>
																{limitedFeaturedLinks.slice(0, 2).map((data, index) => (
																	<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
																		<FeaturedLinks
																			data={data}
																			index={index}
																		></FeaturedLinks>
																	</div>
																))}
																{limitedFeaturedLinks.map(
																	(data, index) =>
																		<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0">
																			<FeaturedLinks
																				data={data}
																				index={index}
																			></FeaturedLinks>
																		</div>

																)}
															</>
														)}
													</div>
												</div>
											)}

										</div>
									</div>

								</div>
							</div>
							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'updates' ? 'show active' : 'hidden')
								}
							>
								<div className="dark-section">
									<video autoPlay muted loop style={darkVideoStyle}>
										<source
											src={pro_styles.dark_background_video}
											type="video/mp4"
										/>
									</video>
									<div className="container">
										<div className="pt-5">
											<div className="grid grid-cols-12 items-center">
												<h4 className="leading-tight header-large col-span-12 lg:col-span-7 flex justify-center lg:justify-start">
													Latest Updates
												</h4>
												<div className="col-span-12 lg:col-span-5 lg:justify-end grid grid-cols-12 place-content-center lg:place-content-end gap-8 lg:gap-0 xsm:mt-5 lg:mt-0">
													<Link
														className="header-title gradient-color-txt col-span-12 lg:col-span-9 flex items-center justify-center lg:justify-end"
														to={'#'}
													>
														All Categories
														<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
													</Link>
													<Link
														className="header-title gradient-color-txt col-span-12 lg:col-span-3 flex items-center justify-center lg:justify-end"
														to={'#'}
													>
														Newest
														<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
													</Link>
												</div>
											</div>
											{spaceUpdatesIsFetching ? (
												<div className="loading-items">
													<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
												</div>
											) : (
												<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5  ">
													{spaceData &&
														spaceUpdates &&
														spaceUpdates.length > 0 && (
															<>
																{spaceUpdates.map(
																	(data, index) => (
																		<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 p-0">
																			<LatestUpdates
																				data={data}
																				user={
																					authUserInfo
																				}
																				updatesViewingUserIsMember={
																					updatesViewingUserIsMember
																				}
																				updateGlobalActiveTab={
																					updateGlobalActiveTab
																				}
																			></LatestUpdates>
																		</div>
																	)
																)}
															</>
														)}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'benefits' ? 'show active' : 'hidden')
								}
							>
								<div className="dark-section">
									<video autoPlay muted loop style={darkVideoStyle}>
										<source
											// src={pro_styles.dark_background_video}
											type="video/mp4"
										/>
									</video>
									<div className="container">
										<div className="pt-5">
											<div className="grid grid-cols-12 items-center">
												<h4 className="leading-tight header-large col-span-12 lg:col-span-5 flex justify-center lg:justify-start">
													Member Benefits
												</h4>
												<div className="col-span-12 lg:col-span-7 lg:justify-end grid grid-cols-12 place-content-center gap-8 lg:mt-0 lg:flex lg:justify-items-end xsm:mt-5">
													<a
														className="header-title gradient-color-txt col-span-12 lg:col-span-3 flex items-center justify-center lg:justify-end"
														href=""
													>
														All Membership
														<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
													</a>
													<a
														className="header-title gradient-color-txt col-span-12 lg:col-span-3 flex items-center justify-center lg:justify-end"
														href=""
													>
														All Categories
														<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
													</a>
													<a
														className="header-title gradient-color-txt col-span-12 lg:col-span-2 flex items-center justify-center lg:justify-end"
														href=""
													>
														Newest
														<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
													</a>
												</div>
											</div>
											{/*{spaceData &&*/}
											{/*	benefits &&*/}
											{/*	benefits.length > 0 && (*/}
											{/*		<>*/}
											{/*			{benefits.map((data, index) => (*/}
											{/*				<Benefits*/}
											{/*					key={index}*/}
											{/*					data={data}*/}
											{/*					user={authUserInfo}*/}
											{/*				></Benefits>*/}
											{/*			))}*/}
											{/*		</>*/}
											{/*	)}*/}
											<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5  ">
												{spaceData &&
													benefits &&
													benefits.length > 0 && (
														<>
															{benefits.map((data, index) => (
																<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 p-0">
																	<MemberBenefits
																		data={data}
																		user={authUserInfo}
																		spaceData={spaceData}
																	></MemberBenefits>
																</div>
															))}
														</>
													)
												}
											</div>
										</div>
									</div>
								</div>
							</div>
							{/*Members Tab*/}
							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'members' ? 'show active' : 'hidden')
								}
							>
								<div className="dark-section">
									<div className="container">
										<div className="pt-5">
											<div className="grid grid-cols-12 items-center">
												<h4 className="leading-tight header-large col-span-12 md:col-span-10 flex justify-self-center md:justify-self-start">
													Join Community
												</h4>
												{/*<div>*/}
												{/*	{spaceData &&*/}
												{/*		spaceMembershipsAndItems &&*/}
												{/*		spaceMembershipsAndItems.length >*/}
												{/*			3 && (*/}
												{/*			<>*/}
												{/*				<Link*/}
												{/*					className="header-title gradient-color-txt"*/}
												{/*					onClick={(event) =>*/}
												{/*						setActiveTabChange('members')*/}
												{/*					}*/}
												{/*				>*/}
												{/*					View All*/}
												{/*				</Link>*/}
												{/*			</>*/}
												{/*		)}*/}
												{/*</div>*/}
											</div>
											<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5 ">
												{spaceData &&
													spaceMembershipsAndItems &&
													spaceMembershipsAndItems.length > 0 && (
														<>
															{spaceMembershipsAndItems.map(
																(data, index) => (
																	<div key={index} className="row-span-5 col-span-12 md:col-span-6 lg:col-span-4 p-0">
																		<Communities
																			data={data}
																			user={authUserInfo}
																			spaceData={spaceData}
																			updateGlobalActiveTab={
																				updateGlobalActiveTab
																			}
																		></Communities>
																	</div>
																)
															)}
														</>
													)
												}
											</div>
										</div>
									</div>
								</div>
							</div>
							{/*<div*/}
							{/*    className={*/}
							{/*        'tab-pane ' +*/}
							{/*        (globalActiveTab === 'support' ? 'show active' : '')*/}
							{/*    }*/}
							{/*>*/}
							{/*    {globalActiveTab === 'support' && (*/}
							{/*        <div className="dark-section">*/}
							{/*            <div className={'container'}>*/}
							{/*                <SpaceSupport*/}
							{/*                    spaceData={spaceData}*/}
							{/*                    isPro={true}*/}
							{/*                />*/}
							{/*            </div>*/}
							{/*        </div>*/}
							{/*    )}*/}
							{/*</div>*/}
							{/*<div*/}
							{/*    className={*/}
							{/*        'tab-pane ' +*/}
							{/*        (globalActiveTab === 'about' ? 'show active' : '')*/}
							{/*    }*/}
							{/*>*/}
							{/*    {globalActiveTab === 'about' && (*/}
							{/*        <div className="dark-section">*/}
							{/*            <div className={'container'}>*/}
							{/*                <SpaceAbout*/}
							{/*                    spaceData={spaceData}*/}
							{/*                    isPro={true}*/}
							{/*                />*/}
							{/*            </div>*/}
							{/*        </div>*/}
							{/*    )}*/}
							{/*</div>*/}
							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'faq' ||
									globalActiveTab === 'about' ||
									globalActiveTab === 'about#contact' ||
									globalActiveTab === 'support' ||
									globalActiveTab === 'privacy-policy' ||
									globalActiveTab === 'terms-services'
										? 'show active'
										: '')
								}
							>
								{(globalActiveTab === 'faq' ||
									globalActiveTab === 'about' ||
									globalActiveTab === 'about#contact' ||
									globalActiveTab === 'support' ||
									globalActiveTab === 'privacy-policy' ||
									globalActiveTab === 'terms-services') && (
									<div className="dark-section">
										<div className={'container'}>
											<SpaceInformationPages
												spaceData={spaceData}
												isPro={true}
												activeTab={globalActiveTab}
											/>
										</div>
									</div>
								)}
							</div>

							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'channel'									
										? 'show active'
										: '')
								}
							>
								{(globalActiveTab === 'channel' ) && (
									<div className="bg-transparent">
										<div className={'container'}>
											<Channel spaceData={spaceData}/>
										</div>
									</div>
								)}
							</div>

							{/*<div*/}
							{/*    className={*/}
							{/*        'tab-pane ' +*/}
							{/*        (globalActiveTab === 'terms-services'*/}
							{/*            ? 'show active'*/}
							{/*            : '')*/}
							{/*    }*/}
							{/*>*/}
							{/*    {globalActiveTab === 'terms-services' && (*/}
							{/*        <div className="dark-section">*/}
							{/*            <div className={'container'}>*/}
							{/*                <SpaceTermsServices*/}
							{/*                    spaceData={spaceData}*/}
							{/*                    isPro={true}*/}
							{/*                />*/}
							{/*            </div>*/}
							{/*        </div>*/}
							{/*    )}*/}
							{/*</div>*/}

							{/*<div*/}
							{/*    className={*/}
							{/*        'tab-pane ' +*/}
							{/*        (globalActiveTab === 'privacy-policy'*/}
							{/*            ? 'show active'*/}
							{/*            : '')*/}
							{/*    }*/}
							{/*>*/}
							{/*    {globalActiveTab === 'privacy-policy' && (*/}
							{/*        <div className="dark-section">*/}
							{/*            <div className={'container'}>*/}
							{/*                <SpacePrivacyPolicy*/}
							{/*                    spaceData={spaceData}*/}
							{/*                    isPro={true}*/}
							{/*                />*/}
							{/*            </div>*/}
							{/*        </div>*/}
							{/*    )}*/}
							{/*</div>*/}

							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'claim_membership'
										? 'show active'
										: '')
								}
							>
								<div className="dark-section">
									<video autoPlay muted loop style={darkVideoStyle}>
										<source
											src={pro_styles.dark_background_video}
											type="video/mp4"
										/>
									</video>
									{globalActiveTab === 'claim_membership' && (
										<SpaceClaimMembership
											hasGradient={pro_styles.has_gradient}
										/>
									)}
								</div>
							</div>
							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'buy_membership'
										? 'show active'
										: '')
								}
							>
								<div className="dark-section">
									<video autoPlay muted loop style={darkVideoStyle}>
										<source
											src={pro_styles.dark_background_video}
											type="video/mp4"
										/>
									</video>
									{globalActiveTab === 'buy_membership' && (
										<SpaceBuyMembership
											hasGradient={pro_styles.has_gradient}
										></SpaceBuyMembership>
									)}
								</div>
							</div>
							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'buy_item' ? 'show active' : '')
								}
							>
								<div className="dark-section">
									<video autoPlay muted loop style={darkVideoStyle}>
										<source
											src={pro_styles.dark_background_video}
											type="video/mp4"
										/>
									</video>
									{globalActiveTab === 'buy_item' && (
										<SpaceBuyItem
											hasGradient={pro_styles.has_gradient}
										/>
									)}
								</div>
							</div>

							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'claim_item' ? 'show active' : '')
								}
							>
								<div className="dark-section">
									<video autoPlay muted loop style={darkVideoStyle}>
										<source
											src={pro_styles.dark_background_video}
											type="video/mp4"
										/>
									</video>
									{globalActiveTab === 'claim_item' && (
										<SpaceClaimItem
											hasGradient={pro_styles.has_gradient}
										/>
									)}
								</div>
							</div>

							<div
								className={
									'tab-pane ' +
									(globalActiveTab === 'update_content'
										? 'show active'
										: '')
								}
							>
								{globalActiveTab === 'update_content' && (
									<>
										<UpdatesContent
											updatesViewingUserIsMember={
												updatesViewingUserIsMember
											}
										></UpdatesContent>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(SpaceViewPro);
