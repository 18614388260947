import { getLocalStorage } from './storage';
import {REDIRECT_CHECK_HOSTNAME, REDIRECT_LANDING_PAGE, STORAGE_KEYS} from '../constants/common';
import routes from '../routes';

const MANAGE_SPACE_ITEM_ROUTES = [
	routes.manageItem(),
	routes.itemType(),
	routes.createItemCommunity(),
	routes.createItemNFT(),
	routes.editItem(),
	routes.addItemMonthBenefit(),
	routes.editItemMonthBenefit()
];

const MANAGE_SPACE_MEMBERSHIP_ROUTES = [
	routes.manageMembership(),
	routes.membershipType(),
	routes.createMembershipCommunity(),
	routes.createMembershipNFT(),
	routes.editMembership(),
	routes.addMemMonthBenefit(),
	routes.editMemMonthBenefit()
];

const MANAGE_SPACE_MEMBER_ROUTES = [routes.manageMember()];

const MANAGE_SPACE_UPDATE_ROUTES = [routes.editUpdate()];

export const NEW_CREATE_SPACE_ROUTES = [routes.createSpace()];

export const OWNER_CREATE_SPACE_ROUTES = [
	routes.createSpace(),
	routes.updateSpace(),
	routes.createSpaceAddDetailsPage(),
	routes.createSpaceAddMembershipPage(),
	routes.createSpaceAddMembershipTraitsPage(),
	routes.createSpaceAddMembershipBenefitPage(),
	routes.createSpaceBasicCompletePage(),
	routes.createSpaceAddFirstItemPage(),
	routes.createSpacePostUpdate(),
	routes.createCommunityFeatures(),
	routes.createSpaceInviteTeam(),
	routes.createSpaceUpgradePlan(),
	routes.createCustomizeProSpace(),
	routes.createCustomizeProNav(),
	routes.createSpaceLaunch(),
	routes.createSpaceLaunchCheckList(),
	routes.createSpaceAddBusiness(),
	routes.createSpaceAddPages(),
	routes.createSpacePayment(),
	routes.createSpaceBilling(),
	routes.createSpacePrevLaunch()
];

export const MANAGE_SPACE_DASHBOARD_ROUTES = [
	routes.spaceDashboard(),
	routes.spaceActivity(),
	routes.spaceMembers(),
	routes.spaceUpdates(),
	routes.createUpdate(),
	routes.spaceBenefits(),
	routes.spaceItems(),
	routes.spaceMemberships(),
	routes.spaceTransactions(),
	routes.spaceManageSpace(),
	routes.spaceSettings(),
	routes.spaceManageCustomizeSpace(),
	routes.spaceManageCustomizeNav(),
	routes.spaceManageSpace(),
	routes.spaceManageProfile(),
	routes.spaceManageHomePage(),
	routes.spaceManageInfoPages(),
	routes.spaceManageCustomizeSpace(),
	routes.spaceManageCustomizeNav()
];

export const redirectUserOutside = (user = null) => {
	if ((window.location.hostname === REDIRECT_CHECK_HOSTNAME) || (window.location.hostname === ('www.' + REDIRECT_CHECK_HOSTNAME))) {
		window.location.href = REDIRECT_LANDING_PAGE;
	} else {
		if (user) {
			window.location.replace(routes.dashboard());
		} else {
			window.location.replace(routes.login());
		}
	}
};

export const checkSpaceOwner = (user, space) => {
	if (space && user) {
		if (space.owner === user.id) {
			// display
		} else {
			redirectUserOutside(user);
		}
	} else {
		if (!space) {
			if (user) {
				redirectUserOutside(user);
			} else {
				redirectUserOutside();
			}
		}
	}
};

export const checkPagePermissionRedirect = (currentPath, currentUser, space) => {
	const accessToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)));
	const refreshToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN)));

	let ignore = false;
	if (accessToken && refreshToken) {
		if (!currentUser) {
			// allow get current profile data to fetch first if tokens are available
			ignore = true;
		}
	}

	if (!ignore) {
		if (currentPath && currentPath.startsWith('/@') && currentPath.length > 3) {
			let username = currentPath.replace('/@', '');
			if (username.includes('/')) {
				const usernameExp = username.split('/');
				username = usernameExp[0] ? usernameExp[0] : '';

				const spaceDashboardRoutesWithSpaceUsername = [];
				MANAGE_SPACE_DASHBOARD_ROUTES.forEach((route) => {
					spaceDashboardRoutesWithSpaceUsername.push(
						route.replace(':space_username', '@' + username)
					);
				});

				if (spaceDashboardRoutesWithSpaceUsername.includes(currentPath)) {
					if (!currentUser) {
						redirectUserOutside();
					} else {
						checkSpaceOwner(currentUser, space);
					}
				} else {
					if (space) {
						const revertedUrl = getRevertedUrl(currentPath, space.username);

						if (
							MANAGE_SPACE_ITEM_ROUTES.includes(revertedUrl) ||
							MANAGE_SPACE_MEMBERSHIP_ROUTES.includes(revertedUrl) ||
							MANAGE_SPACE_MEMBER_ROUTES.includes(revertedUrl) ||
							MANAGE_SPACE_UPDATE_ROUTES.includes(revertedUrl)
						) {
							if (!currentUser) {
								redirectUserOutside();
							} else {
								checkSpaceOwner(currentUser, space);
							}
						}
					}
				}
			}
		} else {
			if (space && currentUser) {
				const spaceCreateRoutesWithSpaceUsername = [];
				OWNER_CREATE_SPACE_ROUTES.forEach((route) => {
					spaceCreateRoutesWithSpaceUsername.push(
						route.replace(':space_username', space.username)
					);
				});

				if (spaceCreateRoutesWithSpaceUsername.includes(currentPath)) {
					if (!currentUser) {
						redirectUserOutside();
					} else {
						checkSpaceOwner(currentUser, space);
					}
				}
			}
		}
	}
};

export const getRevertedUrl = (route, username) => {
	let routeWithSpaceUsername = route.replace('@' + username, ':space_username');

	if (routeWithSpaceUsername.includes('/:space_username/members/')) {
		routeWithSpaceUsername = revertManageDataRoute(routeWithSpaceUsername, 'members');
	}
	if (routeWithSpaceUsername.includes('/:space_username/updates/')) {
		routeWithSpaceUsername = revertManageDataRoute(routeWithSpaceUsername, 'updates');
	}
	if (routeWithSpaceUsername.includes('/:space_username/items/')) {
		if (
			routeWithSpaceUsername.includes('/benefit') &&
			routeWithSpaceUsername.includes('/edit')
		) {
			routeWithSpaceUsername = revertManageDataRoute(routeWithSpaceUsername, 'benefit');
			const expEndPath = routeWithSpaceUsername.split('/benefit/');
			if (expEndPath.length > 1) {
				const parentId = expEndPath[0].replace('/:space_username/items/', '');
				routeWithSpaceUsername = routeWithSpaceUsername.replace(parentId, ':item_id');
			}
		} else {
			routeWithSpaceUsername = revertManageDataRoute(routeWithSpaceUsername, 'items');
		}
	}
	if (routeWithSpaceUsername.includes('/:space_username/memberships/')) {
		if (
			routeWithSpaceUsername.includes('/benefit') &&
			routeWithSpaceUsername.includes('/edit')
		) {
			routeWithSpaceUsername = revertManageDataRoute(routeWithSpaceUsername, 'benefit');
			const expEndPath = routeWithSpaceUsername.split('/benefit/');
			if (expEndPath.length > 1) {
				const parentId = expEndPath[0].replace('/:space_username/memberships/', '');
				routeWithSpaceUsername = routeWithSpaceUsername.replace(parentId, ':membership_id');
			}
		} else {
			routeWithSpaceUsername = revertManageDataRoute(routeWithSpaceUsername, 'memberships');
		}
	}

	return routeWithSpaceUsername;
};

export const revertManageDataRoute = (route, path = null, hasId = false) => {
	const routeCopy = route;
	if (path) {
		const endPath = routeCopy.replace('/:space_username/' + path + '/', '');
		let idKey = ':id';
		if (path === 'members') {
			idKey = ':member_id';
		}
		if (path === 'updates') {
			idKey = ':update_id';
		}
		if (path === 'items') {
			idKey = ':item_id';
		}
		if (path === 'memberships') {
			idKey = ':membership_id';
		}
		if (path === 'memberships') {
			idKey = ':membership_id';
		}
		if (path === 'benefit') {
			idKey = ':benefit_id';
		}

		if (endPath.includes('/benefit/')) {
			if (endPath.includes('/benefit/add')) {
				const dataId = endPath.replace('/benefit/add', '');
				return '/:space_username/' + path + '/' + endPath.replace(dataId, idKey);
			}
			if (endPath.includes('/benefit') && endPath.includes('/edit')) {
				const expEndPath = endPath.split('/benefit/');
				if (expEndPath.length > 1) {
					const dataId = expEndPath[1].replace('/edit', '');
					const expEndPathRevert = endPath.split('/benefit/');
					const expEndPathRevertA = expEndPathRevert[0];
					const expEndPathRevertB = expEndPathRevert[1].replace(dataId, idKey);
					return expEndPathRevertA + '/' + path + '/' + expEndPathRevertB;
				}
			}
		} else {
			if (endPath.includes('/manage')) {
				const dataId = endPath.replace('/manage', '');
				return '/:space_username/' + path + '/' + endPath.replace(dataId, idKey);
			}
			if (endPath.includes('/edit')) {
				const dataId = endPath.replace('/edit', '');
				return '/:space_username/' + path + '/' + endPath.replace(dataId, idKey);
			}
		}
	}
	return routeCopy;
};
