import { memo, useEffect } from 'react';
import DashboardContent from './DashboardContent';

import './SpaceDashboard.scss';

const SpaceDashboard = () => {
	useEffect(() => {
		document.title = 'Commonspace Space Dashboard';
	}, []);

	return (
		<main id="dashboard" className="flex min-h-[83vh]">
			<div className="container mx-auto min-h-screen md:min-h-[540px]">
				<DashboardContent />
			</div>
		</main>
	);
};

export default memo(SpaceDashboard);
