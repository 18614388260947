import { memo } from 'react';
import { showTooltip } from '../../../utils/input';
import { CUSTOM_REGEX } from '../../../constants/custom_regex';

const FeaturedLinks = (parentProps) => {
	const { data, index } = parentProps;
	const link_bg = {
		backgroundImage:
			'url(' + (data.video != null && data.video !== '' ? data.video : data.photo) + ')'
	};

	const gotoFeaturedLink = (url) => {
		window.open(url, '_blank');
	};

	return (
		<>
			<div
				className={
					'items-center justify-center ' +
					(index > 2 ? 'mt-4' : '')
				}
			>
				<div
					className="card-links"
					style={link_bg}
					onClick={() => gotoFeaturedLink(data.url)}
				>
					{data.video != null && data.video !== '' && (
						<video autoPlay muted loop className="card-links-video">
							<source src={data.video} type="video/mp4" />
						</video>
					)}
					<div className="details">
						<h5 className="header-title color-lightest">{data.title}</h5>
						<p className="body-txtitalic--smallest color-lightest">
							{data.description}
						</p>
					</div>
					<div className="bg-overlay"></div>
				</div>
			</div>
		</>
	);
};

export default memo(FeaturedLinks);
