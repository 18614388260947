import { memo } from 'react';

import { shortenNumber } from '../../../../utils/number';

const Top8 = (parentProps) => {
	const { data } = parentProps;
	const name = data.type === 'user' ? '@' + data.username : data.space_name;

	return (
		<>
			<div className="mt-4 flex items-center justify-start">
				<img
					className={data.type === 'user' ? 'img-thumb-circle' : 'img-thumb-square'}
					src={data.photo}
				/>
				<div className="w-full ms-3">
					<h6 className="header-title">{name}</h6>
					<div className="flex justify-between">
						{data.type === 'user' ? (
							<>
								<p className="body-text--small m-0">
									<i className="fa-solid fa-user-group me-2"></i>
									<label className="body-text--smallest">
										{shortenNumber(data.followings)}
									</label>
								</p>
								<p className="body-text--small m-0">
									<i className="fa-light fa-user-group me-2"></i>
									<label className="body-text--smallest">
										{shortenNumber(data.followers)}
									</label>
								</p>
							</>
						) : (
							<>
								<p className="body-text--small m-0">
									<i className="fa-light fa-users me-2"></i>
									<label className="body-text--smallest">
										{shortenNumber(data.members)} Members
									</label>
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(Top8);
