import { memo, useEffect } from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const EllipsisDropdown = ({handleDropdownSelection, data, type, is_pinned, isShowPinOnly = false}) => {
	return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="bg-none gradient-color-txt">
                <i className="fa-solid fa-ellipsis gradient-color-txt text-lg"/>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {type !== 'comment' && type !== 'sub-comments' && type !== 'sub-replies' &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a onClick={() => handleDropdownSelection('pin', data, type)}
                                       className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                                    >
                                        {is_pinned ? 'Unpin' : 'Pin'}
                                    </a>
                                )}
                            </Menu.Item>
                        }
                        {!isShowPinOnly &&
                            <>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a onClick={() => handleDropdownSelection('edit', data, type)}
                                           className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                                        >
                                            Edit
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a onClick={() => handleDropdownSelection('delete', data, type)}
                                           className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                                        >
                                            Delete
                                        </a>
                                    )}
                                </Menu.Item>
                            </>
                        }

                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
	);
};

export default memo(EllipsisDropdown);
