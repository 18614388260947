import {forwardRef, memo, useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import co_logo from '../../../../assets/images/co_logo.svg';
import { getSitewideLink } from '../../../../utils/sitewideLinks';
import Alert from 'react-bootstrap/Alert';
import routes from '../../../../routes';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { LANGUAGE, LOCATION } from '../../../../constants/profile';
import useIsInViewport from "../../../../utils/checkPageScrollPosition";
import {useDispatch} from "react-redux";
import {setIsFooterInViewPort} from "../../../../states/footerInViewSlice";

const FooterProV2 = (props) => {
    const { spaceData, activeTab, onSelectedTabChange } = props;
	const pro_styles = spaceData && spaceData.pro_style ? spaceData.pro_style : null;
    const languageList = LANGUAGE;
	const locationList = LOCATION;
    const [selectedLanguage, setSelectedLanguage] = useState('English');
	const [selectedLocation, setSelectedLocation] = useState('United States');

	const ref = useRef(null);
	const isInViewport = useIsInViewport(ref);
	const dispatch = useDispatch();


	useEffect(() => {
		dispatch(setIsFooterInViewPort(isInViewport));
	}, [isInViewport]);

	let left_logo = co_logo;
	if (spaceData && spaceData.plan === 'pro') {
		left_logo = spaceData && spaceData.photo_thumb ? spaceData.photo_thumb : co_logo;
		left_logo = pro_styles && pro_styles.logo ? pro_styles.logo : left_logo;
	}

	const footer_navs = [];
	if (spaceData && spaceData.pro_navigations) {
		spaceData.pro_navigations.forEach((item) => {
			if (item.section === 'footer') {
				footer_navs.push(item);
			}
		});
	}

	const setActiveTabChange = (item) => {
		onSelectedTabChange(item);
	};

	useEffect(() => {}, [spaceData, activeTab]);
    return (
        <footer ref={ref}
			className={`container px-10 mx-auto grid grid-cols-12 gap-4 lg:gap-8 h-[430px] !max-w-[1440px] ${
				spaceData && spaceData.pro_style.has_gradient ? 'has-gradient' : 'no-gradient'
			}`}
		>
            <div className="col-span-4 mt-16">
                <div className="flex items-end mb-4">
                    <Link
						to={routes.viewUserSpace() + spaceData?.username}
						onClick={(event) => setActiveTabChange('home')}
					>
						<img alt="home" className="footer-logo h-[40px] w-[156px]" src={left_logo} />
					</Link>
                </div>
                <div className="min-h-10 text-base font-secondary font-[notoSansMedium] text-color-bg font-normal leading-6 mb-4">
                    {spaceData && spaceData.about && (
						<>
							<div
								className="footer-about"
								dangerouslySetInnerHTML={{ __html: spaceData.about }}
							></div>
						</>
					)}
                </div>
                <div className=" h-10 	mb-8">
                    <div className="flex flex-wrap grid grid-cols-8 gap-6 social-links list-unstyled">
                        <div className='text-center'>
                        {getSitewideLink('footer_twitter_icon', "social-links list-unstyled", true)}
                        </div>
                        <div  className='text-center'>
                        {getSitewideLink('footer_instagram_icon', "social-links list-unstyled", true)}
                        </div>
                        <div  className='text-center'>
                        {getSitewideLink('footer_facebook_icon', "social-links list-unstyled", true)}
                        </div>
                        <div className='text-center' >
                        {getSitewideLink('footer_youtube_icon', "social-links list-unstyled", true)}
                        </div>
                        <div className='text-center' >
                        {getSitewideLink('footer_tiktok_icon', "social-links list-unstyled", true)}
                        </div>
                        <div className='text-center' >
                        {getSitewideLink('footer_discord_icon', "social-links list-unstyled", true)}
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-8 social-links list-unstyled h-[112px] xl:h-[40px] mb-8">
						<DropdownWithItems
									title="Location"
									customButtonClass="btn-md btn-secondary w-full xl:w-[200px] "
									icon="fa-regular fa-globe"
									selectedValue={selectedLocation}
									listItems={locationList}
									onDropdownChange={setSelectedLocation}
								/>
						<DropdownWithItems
									title="Language"
									customButtonClass="btn-md btn-secondary w-full xl:w-[200px]"
									icon="fa-regular fa-language"
									selectedValue={selectedLanguage}
									listItems={languageList}
									onDropdownChange={setSelectedLanguage}
								/>
                    </div>
            </div>
            <div className="col-span-2 min-w-max relative -left-5 lg:left-0 col-start-6 min-[1140px]:col-start-7 mt-16 mb-8 text-ellipsis overflow-hidden">
					<span className='h-10 flex items-center mb-4'>
						<h4 className="list-heading ">Community</h4>
					</span>
					
					<ul className="footer-links list-unstyled text-xl">
								<li>
									<Link
										to={routes.viewUserSpace() + spaceData?.username}
										onClick={(event) => setActiveTabChange('home')}
									>
										Home
									</Link>
									{/*{getSitewideLink('footer_pro_community_home', ' ')}*/}
								</li>
								<li>
									<Link
										to={
											routes.viewUserSpace() + spaceData?.username + '/update'
										}
										onClick={(event) => setActiveTabChange('updates')}
									>
										Updates
									</Link>
									{/*{getSitewideLink('footer_pro_community_updates', ' ')}*/}
								</li>
								<li>
									<Link
										to={
											routes.viewUserSpace() +
											spaceData?.username +
											'/benefit'
										}
										onClick={(event) => setActiveTabChange('benefits')}
									>
										Benefits
									</Link>
									{/*{getSitewideLink('footer_pro_community_benefits', ' ')}*/}
								</li>
								<li>
									<Link
										to={
											routes.viewUserSpace() + spaceData?.username + '/member'
										}
										onClick={(event) => setActiveTabChange('members')}
									>
										Members
									</Link>
									{/*{getSitewideLink('footer_pro_community_members', ' ')}*/}
								</li>
								<li>
									<a
										href={
											routes.viewUserSpace() +
											spaceData?.username +
											'/privacy-policy'
										}
									>
										Privacy Policy
									</a>
									{/*<Link to="#">Privacy Policy</Link>*/}
									{/*{getSitewideLink('footer_pro_community_privacy_policy', ' ')}*/}
								</li>
								<li>
									<a
										href={
											routes.viewUserSpace() +
											spaceData?.username +
											'/terms-services'
										}
									>
										Terms of Use
									</a>
									{/*<Link to="#">Terms of Use</Link>*/}
									{/*{getSitewideLink('footer_pro_community_terms_of_use', ' ')}*/}
								</li>
							</ul>
				</div>
				<div className="col-span-2 col-start-8 min-[1140px]:col-start-9  mt-16 mb-8 w-[200px]">
					<span className='h-10 flex items-center mb-4'>
						<h4 className="list-heading ">
                            {spaceData.pro_style.navigation_list_title
											? spaceData.pro_style.navigation_list_title
											: 'Resources'}
                        </h4>
					</span>
					<ul className="footer-links list-unstyled text-xl">
                        {footer_navs.map((data, index) => (
                            <li key={index}>
                                <a
                                    href={data.url}
                                    target={
                                        data.open_type === 'open_new_window'
                                            ? '_blank'
                                            : ''
                                    }
                                >
                                    {data.label}
                                </a>
                            </li>
                        ))}
                    </ul>
				</div>
				<div className="col-span-2 col-start-11 mt-16 mb-8 min-w-max">
					<span className='h-10 flex items-center mb-4'>
						<h4 className="list-heading ">Help</h4>
					</span>
					<ul className="footer-links list-unstyled text-xl">
                        <li>
                            <a
                                href={
                                    routes.viewUserSpace() + spaceData?.username + '/about'
                                }
                            >
                                About
                            </a>
                            {/*<Link to="#">About</Link>*/}
                            {/*{getSitewideLink('footer_pro_help_about', ' ')}*/}
                        </li>
                        <li>
                            <a href={routes.viewUserSpace() + spaceData?.username + '/support#faq'}>
                                FAQ
                            </a>
                            {/*<Link to="#">FAQ</Link>*/}
                            {/*{getSitewideLink('footer_pro_help_FAQ', ' ')}*/}
                        </li>
                        <li>
                            <a
                                href={
                                    routes.viewUserSpace() + spaceData?.username + '/about#contacts'
                                }
                            >
                                Contacts
                            </a>
                            {/*<Link to="#">Contact</Link>*/}
                            {/*<Link*/}
                            {/*	to={*/}
                            {/*		routes.viewUserSpace() + spaceData?.username + '/about#contacts'*/}
                            {/*	}*/}
                            {/*	onClick={(event) => setActiveTabChange('about#contact')}*/}
                            {/*>*/}
                            {/*	Contact*/}
                            {/*</Link>*/}
                            {/*{getSitewideLink('footer_pro_help_contact', ' ')}*/}
                        </li>
                        <li>
                            <a
                                href={
                                    routes.viewUserSpace() +
                                    spaceData?.username +
                                    '/support'
                                }
                            >
                                Support
                            </a>
                            {/*<Link to="#">SpaceSupport</Link>*/}
                            {/*{getSitewideLink('footer_pro_help_support', ' ')}*/}
                        </li>
                    </ul>	
				</div>
        </footer>
    )
}
export default memo(FooterProV2);