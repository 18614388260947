import { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import routes from '../../../routes';
import { displayDataContent } from '../../../utils/spaceUpdateDataView';

const LatestUpdates = (parentProps) => {
	const { data, user, updatesViewingUserIsMember, updateGlobalActiveTab, spaceData } =
		parentProps;
	const [spaceUsername, setSpaceUsername] = useState(null);

	const handleActiveTabChange = () => {
		updateGlobalActiveTab('update_content');
	};

	function redirectLink(spaceUsername, data) {
		return routes
			.spaceViewSpaceUpdate()
			.replace(':space_username', '@' + spaceUsername)
			.replace(':update_id', data.id);
	}

	const CategoryRow = (item) => {
		return (
			<>
				<p className="update-tag color-lightest">{item.category.name}</p>
			</>
		);
	};

	useEffect(() => {
		const path = window.location.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}
	}, [spaceUsername, data, updatesViewingUserIsMember]);

	return (
		<>
			{data && data.id ? (
				<div className="card-updates">
					{displayDataContent(data, updatesViewingUserIsMember, 'cover', true)}
					<div className="flex justify-between items-center mt-6">
						<p className="body-text--small">
							{moment(data.created_on).format('LL')}
						</p>
						{data.categories &&
							data.categories.map((category, index) => (
								<CategoryRow key={index} category={category}></CategoryRow>
							))}
					</div>
					<h5 className="font-[notoSansBold] text-xl mt-6">
						{displayDataContent(data, updatesViewingUserIsMember, 'title')}
					</h5>
					<p className="body-text--smallest mt-1">
						{displayDataContent(data, updatesViewingUserIsMember, 'preview_text')}
					</p>
					{!user ? (
						<>
							<Link className="btn btn-secondary btn-md mt-5" to={routes.login()}>
								Login to&nbsp;
								<i className="fa-solid fa-unlock"></i>
								&nbsp;Unlock
							</Link>
						</>
					) : (
						<>
							{data.visibility === 'members' ? (
								<>
									{updatesViewingUserIsMember ? (
										<Link
											className="btn btn-secondary btn-md mt-6 text-lg"
											onClick={() => {
												handleActiveTabChange();
											}}
											to={redirectLink(spaceUsername, data)}
										>
											Read More
										</Link>
									) : (
										<Link
											className="btn btn-secondary btn-md mt-5 disabled"
											to={'#'}
										>
											<i className="fa-solid fa-lock"></i>
											&nbsp;Members Only
										</Link>
									)}
								</>
							) : (
								<Link
									className="btn btn-secondary btn-md mt-5 text-lg"
									onClick={() => {
										handleActiveTabChange();
									}}
									to={redirectLink(spaceUsername, data)}
								>
									Read More
								</Link>
							)}
						</>
					)}
				</div>
			) : (
				<>
					{spaceData.viewing_user_is_owner && (
						<div className="card-updates space-placeholder flex items-center justify-center">
							<h6 className="header-title text-center">
								Create Latest Updates <br />
								from your Dashboard
							</h6>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default memo(LatestUpdates);
