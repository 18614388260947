import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { tr } from 'date-fns/locale';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import {enableDisableChannels} from "../../../services/space";

const CommunityFeatures = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [isSaveBtn, setSaveBtn] = useState(false);
	const [isChannelsEnable, setIsChannelsEnable] = useState(false);
	const [isEnableDisableChannelLoading, setIsEnableDisableChannelLoading] = useState(false);

	const handleEnableChannels = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					setIsEnableDisableChannelLoading(true);
					const data = {
						space_id: spaceInfo.id,
						channels_enable: !isChannelsEnable
					};
					const responseEnableDisableChannels = await enableDisableChannels(data);
					if (responseEnableDisableChannels && responseEnableDisableChannels.result) {
						setIsEnableDisableChannelLoading(false);
						setIsChannelsEnable(!isChannelsEnable);
					} else {
						setIsEnableDisableChannelLoading(false);
					}
				}
			} catch (error) {
			}
		},
		[spaceInfo, isChannelsEnable, isEnableDisableChannelLoading]
	);

	const saveAndGoBackPage = () => {
		const url = routes.createSpacePostUpdate();
		navigate(url.replace(':space_username', space_username));
	};

	const goBackPage = () => {
		const url = routes.createSpacePostUpdate();
		navigate(url.replace(':space_username', space_username));
	};

	const skipNextPage = () => {
		const url = routes.createSpaceInviteTeam();
		navigate(url.replace(':space_username', space_username));
	};

	const handleSubmit = useCallback(
		async (values) => {
			setSaveBtn(true);
			const url = routes.createSpaceInviteTeam();
			navigate(url.replace(':space_username', space_username));
			setSaveBtn(false);
		},
		[space_username, authUserInfo, spaceInfo]
	);

	const setFormSpaceData = async () => {
		// set space data to forms here
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	useEffect(() => {
		if (spaceInfo)
			setIsChannelsEnable(spaceInfo.channels_enable);
	}, [spaceInfo]);

	return (
		<>
			<main id="member-profile" className="flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12 md:col-span-6 flex items-center">
							<h1 className="mb-4 mt-8 header-large">Enable Community Features</h1>
						</div>
						<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">
							<p className="body-txtitalic--large mt-8">Step 3 of 5</p>
						</div>
						<div className="col-span-12">
							<p className="body-text--reg">
								{/*<a className="gradient-color-txt">Community Features</a>*/}
								{getSitewideLink('community_features')} are different areas,
								integrations, and experiences which you can add to your Members
								Section of your Space. Each Feature adds functionality to your Space
								and some can be themed if you have a Pro Space. New Features will be
								added regularly and some Plugins can also enable new Features for
								your Space. {/*<a className="gradient-color-txt">*/}
								{/*	Learn more about Community Features and current availability.*/}
								{/*</a>*/}
								{getSitewideLink('learn_more_community_features')}
							</p>
						</div>
					</div>

					<div className="mt-8">
						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8">
							<div className="md:col-span-8 flex items-center justify-between  !order-last md:!order-first">
								<h5 className="header-medium">Select Your Community Features</h5>
								<span className="body-text--reg">Optional</span>
							</div>
							<div className="md:col-span-4 flex justify-center items-center !order-first md:!order-last">
								{/*<button type="submit" className="btn btn-lg btn-secondary">*/}
								{/*	View Guide*/}
								{/*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
								{/*</button>*/}
								{getSitewideLink(
									'view_guide_community_feature',
									'btn btn-lg btn-secondary'
								)}
							</div>
						</div>
						<div className="w-full">
							<p className="body-text--small mt-8">
								The core Community Features below will be available for all Spaces
								in Q4 2023. Check them out then{' '}
								<a className="gradient-color-txt">scroll down to continue</a>. Once
								they are available, you will be able to manage them from your
								Dashboard. Each one will increase Member engagement and add or
								change dynamics within your community. You can use them in different
								ways depending on the purpose of your Space.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
						<div className="md:col-span-8 !order-last md:!order-first">
							<div className="grid grid-cols-12 gradient-border-box px-8 pt-8 pb-[40px] items-center">
								<div className="col-span-12 md:col-span-6">
									<h5 className="header-small flex items-center">
										<i className="fa-regular fa-comments me-2"></i>Community
										Channels
									</h5>
								</div>
								<div className="col-span-12 md:col-span-6">
									<p className="body-text--smallest color-reg">
										Community Channels is a place where Members can post and
										chat with each other. It’s similar to a Facebook Group but
										with customizable topic channels like Discord or Slack.
									</p>
								</div>
								<button
									type="submit"
									className="z-10 col-span-12 btn btn-lg btn-secondary mt-[40px] min-w-[200px] cursor-pointer"
									onClick={() => {
										handleEnableChannels().then(r => {});
									}}
								>
									{isEnableDisableChannelLoading && (
										<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
									)}
									{isChannelsEnable ? "Disable Channels" : "Enable Channels"}
								</button>
							</div>
						</div>
						<div className="md:col-span-4 !order-first md:!order-last">
							<p className="body-txtitalic--smallest color-reg m-0">
								Once you Enable{' '}
								{/*<a className="gradient-color-txt">Community Channels</a>*/}
								{getSitewideLink('community_channel')}, a default #general channel
								will be created. You can create and manage individual Channels from
								your Dashboard.
							</p>
							<p className="body-txtitalic--smallest color-reg mt-3">
								You can configure who is allowed to access, post, and comment in
								each Channel. You can also allow and pin Hashtag Feeds where posts
								across selected Channels can be viewed as a feed if they contain a
								specific #tag.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
						<div className="md:col-span-8 !order-last md:!order-first">
							<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 gradient-border-box p-4 flex items-center">
								<div className="col-span-12 md:col-span-6">
									<h5 className="header-small flex items-center">
										<i className="fa-regular fa-star me-2"></i>Community Points
									</h5>
								</div>
								<div className="col-span-12 md:col-span-6">
									<p className="body-text--smallest color-reg">
										Community Points reward Members based on their activity in
										your Space. Once earned, you can create ways for Members to
										spend them to unlock Benefits or get Items.
									</p>
								</div>
								<button type="submit" className="col-span-12 btn btn-lg btn-secondary mt-[40px] min-w-[200px]">
									Coming Soon!
								</button>
							</div>
						</div>
						<div className="md:col-span-4 !order-first md:!order-last">
							<p className="body-txtitalic--smallest color-reg m-0">
								Once you Enable{' '}
								{/*<a className="gradient-color-txt">Community Points</a>*/}
								{getSitewideLink('community_points')}, a default set of point values
								for Member Activity is set up. You can edit the point amounts from
								your Dashboard.
							</p>
							<p className="body-txtitalic--smallest color-reg mt-3">
								You can create Custom Benefits that award points or require points
								to unlock, or also give Items an optional Point Price. This can be
								used to add different gamification mechanics to your community.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">
						<div className="md:col-span-8 !order-last md:!order-first">
							<div className="grid grid-cols-12 gradient-border-box p-4 flex items-center">
								<div className="col-span-12 md:col-span-6">
									<h5 className="header-small flex items-center">
										<i className="fa-regular fa-ranking-star me-2"></i>Member
										Leaderboards
									</h5>
								</div>
								<div className="col-span-12 md:col-span-6">
									<p className="body-text--smallest color-reg">
										Member Leaderboards are a ranking of every Member based on
										their combined total value of activated Memberships and
										Items, as well as lifetime earned Community Points.{' '}
									</p>
								</div>
								<button type="submit" className="col-span-12 btn btn-lg btn-secondary mt-[40px] min-w-[200px]">
									Coming Soon!
								</button>
							</div>
						</div>
						<div className="md:col-span-4 !order-first md:!order-last">
							<p className="body-txtitalic--smallest color-reg m-0">
								Once you Enable{' '}
								{/*<a className="gradient-color-txt">Member Leaderboards</a>*/}
								{getSitewideLink('member_leaderboards')}, a Top Members Ranking will
								display on your Members page which will display the Top 3 Members
								publicly, and have a link for Members to see the full Leaderboard.
								Members will also see their Member Rank on their Profile.
							</p>
							<p className="body-txtitalic--smallest color-reg mt-3">
								You can create Custom Benefits that award a Rank Bonus or require
								certain Ranking to unlock. This can be used to add different
								gamification mechanics to your community.
							</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0 md:gap-8  mt-8">
						<div className="md:col-span-8">
							<Button
								htmlType="submit"
								className="btn btn-lg btn-primary"
								onClick={handleSubmit}
							>
								{isSaveBtn && <i className="fa-solid fa-spinner fa-spin"></i>}
								Save & Continue
							</Button>
						</div>
						<div className="md:col-span-4 flex items-end">
							<Button
								htmlType="submit"
								className="btn btn-lg btn-secondary"
								onClick={saveAndGoBackPage}
							>
								Save & Go Back
							</Button>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12   gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8">
							<Button
								htmlType="submit"
								className="btn btn-lg btn-secondary"
								onClick={skipNextPage}
							>
								Skip Without Saving
							</Button>
						</div>
						<div className="md:col-span-4 flex items-end">
							<Button
								htmlType="submit"
								className="btn btn-lg btn-secondary whitespace-pre-wrap"
								onClick={goBackPage}
							>
								Go Back Without Saving
							</Button>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(CommunityFeatures);
