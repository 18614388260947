import React, {memo, useCallback, useEffect, useRef} from 'react';
import EllipsisDropdown from './EllipsisDropdown';
import './Channel.scss';
import moment from "moment/moment";
import ReactPlayer from 'react-player/lazy';
import { Button, Form } from 'antd';
import { useState } from 'react';
import './Channel.scss';
import {deleteSpaceComment, getSpaceChannel, getSpaceChannels, getSpaceDefaultChannel, getSpaceFirstChannel, searchInputTags} from "../../services/space";
import {useSelector} from "react-redux";
import {getDataByUsername} from "../../services/general";
import { VALIDATE_MESSAGES } from '../../constants/common';
import {
    createSpaceModelComment
} from '../../services/space';

import{
    getSpaceChannelPosts,
    updateSpacePost,
    userHeartPost,
    userUnheartPost,
    deleteSpacePost,
    userPinPost,
    userUnpinPost
} from '../../services/post'
import {Link, useParams} from "react-router-dom";
import default_user_profile from "../../assets/images/default_user_profile.png";
import ChannelSharePost from "../../components/commons/Modals/ChannelSharePost";
import ChannelPostForm from "../../components/commons/Modals/ChannelPostForm";
import {Mention, MentionsInput} from "react-mentions";
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import TextareaAutosize from "react-textarea-autosize";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
const Channel = (props) => {

    const { guid } = useParams();

    const [ spaceData, setSpaceData ] = useState(null);
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [spaceUpdates, setSpaceUpdates] = useState(null);
    const [spaceUsername, setSpaceUsername] = useState(null);
    const [spaceChannel, setSpaceChannel] = useState(null);
    const [channelPostsIsFetching, setChannelPostsIsFetching] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [updatesViewingUserIsMember, setUpdatesViewingUserIsMember] = useState(false);
    const [showCommentReplyField, setShowCommentReplyField] = useState(false);
    const [showSubCommentField, setShowSubCommentField] = useState(false);
    const [showSubCommentSubReplyField, setShowSubCommentSubReplyField] = useState(false);
    const [commentId, setCommentId] = useState(null);
    const [subCommentReplyId, setSubCommentReplyId] = useState(null);
    const [subCommentSubReplyId, setSubCommentSubReplyId] = useState(null);
    const [updateId, setUpdateId] = useState(null);
    const [subReplyId, setSubReplyId] = useState(null);
    const [commentReplyId, setCommentReplyId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isBtnPostEnabled, setIsBtnPostEnabled] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isHeartSubmitting, setIsHeartSubmitting] = useState(false);
    const last = useRef(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const [updateForm] = Form.useForm();
    const [commentForm] = Form.useForm();
    const [commentReplyForm] = Form.useForm();
    const [searchForm] = Form.useForm();

    const [isAudioUpload, setIsAudioUpload] = useState(false);
    const [isActivationOpen, setActivationOpen] = useState(false);
    const [isOpenAudioModal, setIsOpenAudioModal] = useState(false);
    const [isPostEditOpen, setIsPostEditOpen] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [posts, setPosts] = useState([]);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const postIdToDelete = useRef(null);
    const commentIdToDelete = useRef(null);
    const subCommentIdToDelete = useRef(null);
    const subReplyIdToDelete = useRef(null);
    const itemElRef = useRef(new Array())
    const hasPinnedItem = itemElRef.current.some(item => item.is_pinned === true);

    const [backgroundBG, setBackgroundBG] = useState(null);
    const [cardBackgroundType, setCardBackgroundType] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [spaceChannels, setSpaceChannels] = useState([]);
    const [postValue, setPostValue] = useState('');
    const [tags, setTags] = useState([
        { id: 1, display: 'sports' },
        { id: 2, display: 'technology' },
        { id: 3, display: 'science' },
    ]);

    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [previousPaginationPage, setPreviousPaginationPage] = useState(0);
    const [previousFetchItemsCount, setPreviousFetchItemsCount] = useState(0);
    const [maxPages, setMaxPages] = useState(0);
    const pageListCount = 5; // number of items per page
    const isFooterInViewPort = useSelector((state) => state.footerInView.isFooterInViewPort);

    const postRedux = useSelector((state) => state.channel.post);
    const editPostRedux = useSelector((state) => state.channel.editPost);
    const commentRedux = useSelector((state) => state.channel.comment);
    const editCommentRedux = useSelector((state) => state.channel.editComment);
    const subCommentRedux = useSelector((state) => state.channel.subComment);
    const editSubCommentRedux = useSelector((state) => state.channel.editSubComment);
    const subCommentReplyRedux = useSelector((state) => state.channel.subCommentReply);
    const editSubCommentReply = useSelector((state) => state.channel.editSubCommentReply);
    const subCommentSubReplyRedux = useSelector((state) => state.channel.subCommentSubReply);


    const setUserBackground = (user) => {
		if (user.background_photo || user.background_video) {
			if (user.background_photo) {
				const space_bg = {
					backgroundImage: 'url(' + user.background_photo + ')'
				};
				setBackgroundBG(space_bg);
				setCardBackgroundType('image');
			}
			if (user.background_video) {
				setProfileBackgroundVideoBlobPreview(user.background_video);
				setCardBackgroundType('video');
			}
		} else {
			const space_bg = {
				backgroundImage: 'url(' + default_profile_card_bg + ')'
			};
			setBackgroundBG(space_bg);
			setCardBackgroundType('image');
		}
	};

    const showCommentReply = (status, id) => {
        setShowCommentReplyField(status);
        showCommentReply ? setCommentId(id) : setCommentId(null);
    }

    const showSubCommentReply = (status, id) => {
        setShowSubCommentField(status);
        showSubCommentReply ? setSubCommentReplyId(id) : setSubCommentReplyId(null);
    }

    const showSubCommentSubReply = (status, id) => {
        setShowSubCommentSubReplyField(status);
        showSubCommentSubReply ? setSubCommentSubReplyId(id) : setSubCommentSubReplyId(null);
    }

    const fetchViewData = useCallback(
        async (username) => {
            if (username) {
                try {
                    const data = {
                        username: username
                    };

                    if (authUserInfo) {
                        data['viewing_user_id'] = authUserInfo.id;
                    }
                    // console.log('getDataByUsername')

                    const response = await getDataByUsername(data);
                    // console.log(response)
                    if (response.space) {
                        setSpaceData(response.space);
                        setUserBackground(response.space);
                    }
                    
                } catch (error) {
                    console.log(error);
                }
            }
    },
    [authUserInfo]);

    const handleNewData = (new_post) => {
        if(selectedAction === 'edit'){
            let post_index = itemElRef.current.findIndex(x => x.id == new_post.post.id);
            itemElRef.current[post_index] = new_post.post;
        }else{
            itemElRef.current.unshift(new_post.post)
        }
    }

    const handleChangeChannel = (username = null, guid) => {
        window.location.replace('/@' + (username ? username : spaceUsername) + '/channel/'+guid);
        // window.history.replaceState(null, null, '/@' + spaceUsername + '/channel/'+guid);
    }

    const fetchChannels = useCallback(async (spaceId) => {
        const channel_param = {}
        if (spaceId) {
            channel_param.space_id = spaceId;
        }
        const response = await getSpaceChannels(channel_param);
        if(response && response.result) {
            const channels = response.space_channels;
            if (channels) {
                setSpaceChannels(channels);
            }
        }
    })

    const handleSearchPosts = useCallback(async (searchText) => {
            setIsSubmitting(true);
            const channel_param = {
            }
            if (spaceChannel) {
                channel_param.channel_id = spaceChannel.id;
            }
            if(searchForm.getFieldValue('searchText') !== undefined && searchForm.getFieldValue('searchText').trim() !== ''){
                channel_param.search = searchForm.getFieldValue('searchText') ;
            }
            channel_param.viewing_user_id = 1;
            channel_param.page = currentPage;
            // const result = await getSpaceUpdates(params);
            const channel_posts = await getSpaceChannelPosts(channel_param);
            if(channel_posts && channel_posts.result){
                itemElRef.current = channel_posts.posts;
                setIsSubmitting(false);
            }
        }, [spaceChannel, itemElRef, currentPage]
    )

    const fetchDefaultChannelData = useCallback(async (spaceData) => {
            if (spaceData) {
                try {
                    const data = {
                        space_id: spaceData.id
                    };
                    const response = await getSpaceDefaultChannel(data);
                    // console.log('fetchDefaultChannelData')
                    // console.log(response)
                    if (response && response.result) {
                        const channel = response.space_channel;
                        setSpaceChannel(channel);
                        handleChangeChannel(spaceData.username, channel.guid);
                    } else {
                        window.location.replace('/@' + spaceData.username + '/manage/manage-channels');
                    }
                    // setSpaceChannel(response.space_channel);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        [spaceData])

//     const fetchFirstChannelData = useCallback(async (space_id) => {
//             if (space_id) {
//                 try {
//                     const data = {
//                         space_id: space_id
//                     };
//                     const response = await getSpaceFirstChannel(data);
//                     if (response && response.result) {
//                         setSpaceChannel(response.space_channel);
//                     }
//                     // setSpaceChannel(response.space_channel);
//                 } catch (error) {
//                     console.log(error);
//                 }
//             }
//         },
// [])

    const fetchGuidChannelData = useCallback(async () => {
            if (guid) {
                try {
                    const data = {
                        guid: guid,
                    };
                    const response = await getSpaceChannel(data);
                    if (response && response.result) {
                        setSpaceChannel(response.space_channel);
                    }
                    // setSpaceChannel(response.space_channel);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        [guid])


    const getChannelPosts = useCallback(async (channel, space, pageNumber = 1) => {
        // console.log('getupdates')
        if (!channel) {
            return;
        }
        // if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0) || isActivationOpen) {
        //     setChannelPostsIsFetching(true);
        // }
        try {
            setChannelPostsIsFetching(true);
            const params = {
                space_id: space.id,
                channel_id: channel.id,
                page: pageNumber,
                listCount: pageListCount,
            };
            const response = await getSpaceChannelPosts(params);
            const channelPost = response.posts;
            if (!spaceUpdates && response && channelPost && response.result) {
                setMaxPages(response.page_range);
                setPreviousFetchItemsCount(channelPost.length);
                channelPost.map(key => {
                    if(!posts.find( obj2 => obj2.id===key.id)){
                        posts.push(key)
                        itemElRef.current.push(key)
                    }
                })
            }
            setCurrentPaginationPage(pageNumber);
            setPreviousPaginationPage(currentPaginationPage);
            // if (response.result) {
            //     setSpaceUpdates(response.result);
            //     // setUpdatesViewingUserIsMember(result.viewing_user_is_member);
            // } else {
            //     setSpaceUpdates(null);
            // }
            setIsLoadMore(false);
            setTimeout(() => {
                setChannelPostsIsFetching(false);
            }, 1000);

        } catch (error) {
            console.log(error);
            setChannelPostsIsFetching(false);
        }
        },
        [
            authUserInfo,
            spaceUpdates,
            currentPage,
            itemElRef,
            posts,
            currentPaginationPage,
            previousPaginationPage,
            channelPostsIsFetching,
        ]
    );

    const getPostDate = (time) => {
        return moment(time).fromNow();
    }

    const handleSubmit = useCallback(async (values) => {
            setIsSubmitting(true);
            const update = {};
            update.model = 'post';
            update.channel_id = spaceChannel.id;
            update.title = values.comment;
            update.content = '';
            update.attachments_type = '';
            update.attachements = null
            try {
				if (authUserInfo) {
					update.user_id = authUserInfo.id;
					update.space_id = spaceData.id;

                    const response = await updateSpacePost(update);
					if (response && response.result) {
                        posts.push(response.post)
                        itemElRef.current.unshift(response.post)
                        setSpaceUpdates(null)
                        updateForm.setFieldValue('comment','');
                        setIsSubmitting(false);
                        setIsBtnPostEnabled(false);
                        processPostsCount(true, spaceChannel);
					}
				}
			} catch (error) {
				console.log(error);
			}
        },
       [ spaceChannel, authUserInfo, spaceData, itemElRef]
    );

    const handleChanges = useCallback(() => {})

    const handleCommentChanges = useCallback(() => {})

    const handlePostTitleChanges = useCallback((event) => {
        setIsBtnPostEnabled(event.target.value.length > 0);
    })

    const handleDropdownSelection = (selection, data, type) => {
        setSelectedAction(selection);
        if (selection === 'delete'){
            setDataToDelete(data.id, type).then();
            // const response = await deleteSpacePost(data);
        }else if (selection === 'edit'){
            // setIsPostEditOpen(true);
            setActivationOpen(true);
            setDataToEdit(data)
        }else{
            setDataToPin(data).then();
        }
    }

    const setIdsForDeletion = (postId, commentId=null, subCommentId=null, subreplyId = null) => {
        if (postId){
            postIdToDelete.current = postId;
        }
        if (commentId){
            commentIdToDelete.current = commentId; 
        }
        if (subCommentId){
            subCommentIdToDelete.current = subCommentId;
        }
    }

    const setDataToPin = useCallback(async (data) => {
        const params = {
            user_id: authUserInfo.id,
            post_id: data.id
        };

        let post_index = itemElRef.current.findIndex(x => x.id === data.id);
        setIsSubmitting(true);
        let post = {...data};
        if(data.is_pinned){
            const response = await userUnpinPost(params);
            post.is_pinned = false;
            itemElRef.current[post_index] = post;
            setIsSubmitting(false);
        }else{
            const response = await userPinPost(params);
            post.is_pinned = true;
            itemElRef.current[post_index] = post;
            setIsSubmitting(false);
        }

        }, [authUserInfo, itemElRef]
    )

    const processPostsCount = (isIncreaseCount, spaceChannel) => {
        setSpaceChannels(prev => {
            return prev.map((channel) => {
                if (channel.id === spaceChannel.id) {
                    return {...channel, posts_count: isIncreaseCount ? (channel.posts_count + 1) : channel.posts_count - 1};
                } else {
                    return channel;
                }
            });
        });
    }

    const setDataToDelete = useCallback(
        async (values, type) => {
        const data = {};
        setIsSubmitting(true);
        if(type === "post"){
            data.post_id = values;            
            const response = await deleteSpacePost(data);
            if (response && response.result) {
                let post_index = itemElRef.current.findIndex(x => x.id == values);
                itemElRef.current.splice(post_index, 1);
                processPostsCount(false, spaceChannel);
            }
           
        }else if(type === "comment"){
            data.comment_id = values;
            const response = await deleteSpaceComment(data);
            if (response && response.result) {
                let post_index = itemElRef.current.findIndex(x => x.id == postIdToDelete.current);
                let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id == commentIdToDelete.current);
                itemElRef.current[post_index].comments.splice(comment_index, 1);

            }
        }else if(type === "sub-comments"){
            data.comment_id = values;
            const response = await deleteSpaceComment(data);
            if (response && response.result) {
                let post_index = itemElRef.current.findIndex(x => x.id == postIdToDelete.current);
                let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id == commentIdToDelete.current);
                let sub_comment_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id == subCommentIdToDelete.current);
                itemElRef.current[post_index].comments[comment_index].replies.splice(sub_comment_index, 1);

            }
        }
        else if(type === "sub-replies"){
            data.comment_id = values;
            const response = await deleteSpaceComment(data);
            if (response && response.result) {
                let post_index = itemElRef.current.findIndex(x => x.id == postIdToDelete.current);
                let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id == commentIdToDelete.current);
                let sub_comment_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id == subCommentIdToDelete.current);
                let sub_reply_index = itemElRef.current[post_index].comments[comment_index].replies[sub_comment_index].sub_replies.findIndex(x => x.id == subReplyIdToDelete.current);
                itemElRef.current[post_index].comments[comment_index].replies[sub_comment_index].sub_replies.splice(sub_reply_index, 1);

            }
        }
        setIsSubmitting(false);

    }, [itemElRef, postIdToDelete, commentIdToDelete, subCommentIdToDelete, subReplyIdToDelete, spaceChannel, spaceChannels])
    
    const handleSubmitSubComment = useCallback(async (values) => {
			let okToSubmit = (commentReplyForm.getFieldValue('reply') !== undefined && commentReplyForm.getFieldValue('reply').trim() !== '');
            setIsSubmitting(false)
			if (okToSubmit) {
				setSubmitted(true);
				const data = {
					space_id: spaceChannel.id,
					model: 'post',
					model_id: updateId,
					comment: values.reply,
					reply_to_id: commentReplyId
				};
                // data['reply_to_id'] = 17;
                // data['reply_to_sub_id'] = 17;
				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}
				const response = await createSpaceModelComment(data);
				if (response && response.result) {
					// updateCommentIdToReply(null);
					// fetchUpdatedComments();
                    let post_index = itemElRef.current.findIndex(x => x.id == updateId);
                    let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id == commentReplyId);
                    if(itemElRef.current[post_index].comments[comment_index].replies){
                        itemElRef.current[post_index].comments[comment_index].replies.unshift(response.comment);
                    }else{
                        itemElRef.current[post_index].comments[comment_index].replies = new Array();
                        itemElRef.current[post_index].comments[comment_index].replies.unshift(response.comment);
                    }
                    commentReplyForm.resetFields();
                    setShowCommentReplyField(false);
                    setShowSubCommentField(false);
                    setSpaceUpdates(null)
                    setIsSubmitting(false);
                    setCommentId(null);
				}
				setSubmitted(false);
				// setDisableReplyButton(true);
			}
		},
		[authUserInfo, spaceChannel, commentReplyId, updateId, itemElRef.current]
	);

    const handleSubmitSubReply = useCallback(async (values) => {
			let okToSubmit = (commentReplyForm.getFieldValue('reply') !== undefined && commentReplyForm.getFieldValue('reply').trim() !== '');
            setIsSubmitting(true)
			if (okToSubmit) {
				setSubmitted(true);
				const data = {
					space_id: spaceChannel.id,
					model: 'post',
					model_id: updateId,
					comment: values.reply,
					reply_to_id: commentReplyId
				};
                data['reply_to_id'] = subReplyId;
                data['reply_to_sub_id'] = commentReplyId;
				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}
				const response = await createSpaceModelComment(data);
				if (response && response.result) {
					
                    let post_index = itemElRef.current.findIndex(x => x.id == updateId);
                    let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id == commentReplyId);
                    let reply_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id == subReplyId);
                    if(itemElRef.current[post_index].comments[comment_index].replies[reply_index].sub_replies){
                        itemElRef.current[post_index].comments[comment_index].replies[reply_index].sub_replies.unshift(response.comment);
                    }
                    else{
                        // itemElRef.current[post_index].comments[comment_index].replies[reply_index].sub_replies = new Array();
                        let reply = {...itemElRef.current[post_index].comments[comment_index].replies[reply_index]};
                        reply.sub_replies = [];
                        itemElRef.current[post_index].comments[comment_index].replies[reply_index] = reply;
                        itemElRef.current[post_index].comments[comment_index].replies[reply_index].sub_replies.unshift(response.comment);
                    }
                    commentReplyForm.resetFields();
                    setShowCommentReplyField(false);
                    setShowSubCommentSubReplyField(false);
                    setShowSubCommentField(false);
                    setIsSubmitting(false);
                    setSpaceUpdates(null);
                    setSubCommentReplyId(null);
                    setSubCommentSubReplyId(null);
				}
				setSubmitted(false);
				// setDisableReplyButton(true);
			}
		},
		[authUserInfo, spaceChannel, commentReplyId, updateId, subReplyId]
	);

    const handleSubmitComment = useCallback(async (values) => {
			let okToSubmit = (commentForm.getFieldValue('comment') !== undefined && commentForm.getFieldValue('comment').trim() !== '');
			if (okToSubmit) {
                setIsSubmitting(true)
				setSubmitted(true);
				const data = {
					space_id: spaceData.id,
					model: 'post',
					model_id: updateId,
                    channel_id: spaceChannel.id,
					comment: values.comment
				};
				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}
				const response = await createSpaceModelComment(data);
                commentForm.resetFields();
				if (response && response.comment) {
                    let index = itemElRef.current.findIndex(x => x.id == updateId);
                    // if(index != -1){
                        if(itemElRef.current[index].comments.length === 0){
                            itemElRef.current[index].comments.push(response.comment);
                        }else{
                            itemElRef.current[index].comments.unshift(response.comment);
                        }
                    // }else{
                    //     itemElRef.current.comments.unshift(response.comment)
                    // }

                    setSpaceUpdates(null)
                    setIsSubmitting(false);
                    setUpdateId(null);
				}
				setSubmitted(false);
			}
		},
		[authUserInfo, spaceData, updateId, itemElRef.current]
	);


    const searchInputTagsRequest = useCallback(async (search) => {
            if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0) || isActivationOpen) {
                setChannelPostsIsFetching(true);
            }
            try {
                const params = {
                    search: search
                };
                // const channel_param = {
                // }
                // if (channel) {
                //     params.channel_id = channel.id;
                //     channel_param.channel_id = channel.id;
                // }
                // channel_param.viewing_user_id = 1;
                // channel_param.page = currentPage;
                // const result = await getSpaceUpdates(params);
                const response = await searchInputTags(params);
                if (response && response.result) {
                    const tags = response.tags;
                    tags.filter((tag)=>{
                        return {id: tag.id, display: tag.name}
                    })
                    setTags(tags);
                }
                setIsLoadMore(false);
                setChannelPostsIsFetching(false);
            } catch (error) {
                console.log(error);
                setChannelPostsIsFetching(false);
            }
        },
        [authUserInfo, spaceUpdates, currentPage, itemElRef]
    );
   
    const setModelId = (id) => {
        setUpdateId(id);
    }
    const setReplyModelId = (id, replyId) => {
        setUpdateId(id);
        setCommentReplyId(replyId);
    }

    const setSubReplyModelId = (id, replyId, subReplyId) => {
        setUpdateId(id);
        setSubReplyId(subReplyId);
        setCommentReplyId(replyId);
    }

    // const handleScroll = (event) => {
    //     if(document.documentElement.clientHeight + window.scrollY >=
    //         (document.documentElement.scrollHeight ||
    //             document.documentElement.clientHeight)){
    //                 setCurrentPage(currentPage + 1)
    //             }
    // };
   
    const handleSeeMorePosts = () => {
        setIsLoadMore(true);
        setSpaceUpdates(null)
        setCurrentPage(currentPage + 1);
    }

    const toggleHeart = useCallback(async (values, state) => {
        if(!state){
            state = false;
        }
        if (authUserInfo) {
            const data = {
                user_id: authUserInfo.id,
                post_id: values
            };
            setIsHeartSubmitting(true);
            let response = {}
            if(state){
                response = await userUnheartPost(data);
            } else{
                response = await userHeartPost(data);
            }
            if(response && response.result){
                setIsHeartSubmitting(false);
                let post = response.post;
                if (post) {
                    let post_index = itemElRef.current.findIndex(x => x.id === post.id);

                    let newState = JSON.parse(JSON.stringify(itemElRef.current));
                    newState[post_index].hearted_by_users = post.hearted_by_users;
                    newState[post_index].hearts_count = post.hearts_count;
                    newState[post_index].is_hearted = !state;
                    // Update the state with the modified copy
                    itemElRef.current = newState;
                }
            } else {
                setIsHeartSubmitting(false);
            }
        }
    }, [itemElRef, authUserInfo])

    const handlePostKeypress = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            commentReplyForm.submit();
        }
    };

    useEffect(() => {
        const nextPage = currentPaginationPage + 1;
        // only trigger next page fetch when:
        // - footer is in view port(scrolled to bottom)
        // - specified items count per page not be below last fetched data list
        const hasMorePages = currentPaginationPage < maxPages;
        if (!channelPostsIsFetching && isFooterInViewPort && (pageListCount === previousFetchItemsCount || hasMorePages)) {
            setChannelPostsIsFetching(true);
            getChannelPosts(spaceChannel, spaceData, nextPage).then((r) => {});
        }
    }, [
        // userWorkPostList,
        // fetchingPosts,
        spaceChannel,
        spaceData,
        isFooterInViewPort,
        currentPaginationPage,
        previousPaginationPage,
        previousFetchItemsCount,
        maxPages,
        // profile
    ]);

    useEffect(()=> {
        if (postRedux) {
            itemElRef.current.unshift(postRedux)
        }
    },[postRedux])

    useEffect(()=> {
        if (editPostRedux) {
            let post_index = itemElRef.current.findIndex(x => x.id == editPostRedux.id);
            // Create a copy of the current state
            // prevent Cannot assign to read only property '0' of object error
            let newState = JSON.parse(JSON.stringify(itemElRef.current));
            newState[post_index] = editPostRedux;
            // Update the state with the modified copy
            itemElRef.current = newState;
        }
    },[editPostRedux])

    useEffect(()=> {
        // console.log('commentRedux')
        // console.log(commentRedux)
        // if (commentRedux) {
        //
        // }
    },[commentRedux])

    useEffect(()=> {
        if (editCommentRedux) {
            let post_index = itemElRef.current.findIndex(x => x.id === editCommentRedux.model_id);
            let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === editCommentRedux.id);
            // Create a copy of the current state
            // prevent Cannot assign to read only property '0' of object error
            let newState = JSON.parse(JSON.stringify(itemElRef.current));
            newState[post_index].comments[comment_index] = editCommentRedux;
            // Update the state with the modified copy
            itemElRef.current = newState;
        }
    },[editCommentRedux])

    useEffect(()=> {
        // console.log('subCommentRedux')
        // console.log(subCommentRedux)
        // if (subCommentRedux) {
        //
        // }
    },[subCommentRedux])

    useEffect(()=> {
        // console.log('editSubCommentRedux')
        // console.log(editSubCommentRedux)
        if (editSubCommentRedux) {
            let post_index = itemElRef.current.findIndex(x => x.id === editSubCommentRedux.model_id);
            let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === editSubCommentRedux.reply_to_id);
            let sub_comment_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id === editSubCommentRedux.id);
            // Create a copy of the current state
            // prevent Cannot assign to read only property '0' of object error
            let newState = JSON.parse(JSON.stringify(itemElRef.current));
            newState[post_index].comments[comment_index].replies[sub_comment_index] = editSubCommentRedux;
            // Update the state with the modified copy
            itemElRef.current = newState;
        }
    },[editSubCommentRedux])

    useEffect(()=> {
        // console.log('editSubCommentReply')
        // console.log(editSubCommentReply)
        if (editSubCommentReply) {
            let post_index = itemElRef.current.findIndex(x => x.id === editSubCommentReply.model_id);
            let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === editSubCommentReply.reply_to_sub_id);
            let sub_comment_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id === editSubCommentReply.reply_to_id);
            let reply_index = itemElRef.current[post_index].comments[comment_index].replies[sub_comment_index].sub_replies.findIndex(x => x.id === editSubCommentReply.id);
            // Create a copy of the current state
            // prevent Cannot assign to read only property '0' of object error
            let newState = JSON.parse(JSON.stringify(itemElRef.current));
            newState[post_index].comments[comment_index].replies[sub_comment_index].sub_replies[reply_index] = editSubCommentReply;
            // Update the state with the modified copy
            itemElRef.current = newState;
        }
    },[editSubCommentReply])

    useEffect(() => {
        // console.log('spaceData')
        // console.log(spaceData)
        if (spaceData) {
            if(!isActivationOpen) {
                setChannelPostsIsFetching(true);
            }
            getChannelPosts(spaceChannel, spaceData).then();
            if(currentPage > 1){
                const element = document.getElementById('last');
                if(element && isLoadMore){
                    element.scrollIntoView({ behavior: 'smooth' });
                }

            }
        }
    }, [spaceChannel,  currentPage], spaceData);

    useEffect(() => {
        if(!isActivationOpen) {
            setDataToEdit(null);
        }
    },[isActivationOpen])

    useEffect(() => {
        if (spaceData) {
            setUpdatesViewingUserIsMember(spaceData.viewing_user_is_member);
            fetchChannels(spaceData.id).then();

            if (!spaceData.channels_enable) {
                window.location.replace('/@' + spaceData.username + '/manage/manage-channels');
            } else {
                if (guid) {
                    fetchGuidChannelData().then();
                } else {
                    fetchDefaultChannelData(spaceData).then();
                    // fetchFirstChannelData(spaceData.id).then();
                }
            }

        }
    }, [spaceData, guid]);

    useEffect(() => {
        const path = window.location.pathname;
        // console.log('path')
        // console.log(path)
        if (path && path.startsWith('/@') && path.length > 3) {
            let directory = path.replace('/@', '');
            const split = directory.split('/');
            const username = split[0];
            // console.log('username')
            // console.log(username)
            setSpaceUsername(username);
        }
    }, []);

    useEffect(() => {
        // console.log('spaceUsername')
        // console.log(spaceUsername)
        // console.log(spaceUsername)
        if (spaceUsername) {
            fetchViewData(spaceUsername).then()
        }
    }, [spaceUsername])

    useEffect(() => {
        setCommentReplyId(commentReplyId);
        setUpdateId(updateId);
        setSubReplyId(subReplyId);
    }, [updateId, commentReplyId, subReplyId])

    useEffect(() => {
    }, [
        spaceChannels,
        spaceChannel,
        channelPostsIsFetching,
        isSubmitting,
        dataToEdit,
        selectedAction,
        itemElRef,
        isSubmitting,
        commentId,
        subCommentSubReplyId,
        showSubCommentSubReplyField,
        showCommentReplyField,
        spaceUpdates,
        postIdToDelete,
        commentIdToDelete,
        subCommentIdToDelete,
    ]);


    const handleMentionChanges = (event, newValue, newPlainTextValue, mentions) => {
        // console.log('event');
        // console.log(event);
        // console.log('newValue');
        // console.log(newValue);
        // console.log('newPlainTextValue');
        // console.log(newPlainTextValue);
        // console.log('mentions');
        // console.log(mentions);
        let finalValue = newValue;

        // Add "@" and "#" symbols to the mentions
        for (let mention of mentions) {
            if (mention.display) {
                const oldMention = mention.display;
                const newMention = mention.trigger + mention.display;
                finalValue = finalValue.replace(oldMention, newMention);
            }
        }
        setPostValue(newValue);
    }

    const renderMentionChanges = (entry, search, highlightedDisplay, index, focused) => {
        return <div>@{highlightedDisplay}</div>;
    }

    const renderTagsChanges = (entry, search, highlightedDisplay, index, focused) => {
        return <div>#{highlightedDisplay}</div>;
    };

    const users = [
        { id: 1, display: 'James' },
        { id: 2, display: 'Jill' },
        { id: 3, display: 'John' },
        // Add more users here
    ];


    return (
        <>
            {(spaceData && spaceChannel) ?
                <main id="dashboard" >
                    <ChannelPostForm
                        itemElRef={itemElRef}
                        dataToEdit={dataToEdit}
                        setDataToEdit={setDataToEdit}
                        isAudioUpload={isAudioUpload}
                        setIsAudioUpload={setIsAudioUpload}
                        modalOpen={isActivationOpen}
                        setModalOpen={setActivationOpen}
                        handleNewData={handleNewData}
                        user={authUserInfo}
                        spaceChannel={spaceChannel}
                        spaceData={spaceData}
                        activationStatus={'active'}
                    />
                    <ChannelSharePost
                        post={selectedPost}
                        modalOpen={isShareModalOpen}
                        setModalOpen={setIsShareModalOpen}
                    />
                    {/*<ChannelPostEditModal*/}
                    {/*    dataToEdit={dataToEdit}*/}
                    {/*    modalOpen={isPostEditOpen}*/}
                    {/*    setModalOpen={setIsPostEditOpen}*/}
                    {/*    handleNewData={handleNewData}*/}
                    {/*    authUserInfo={authUserInfo}*/}
                    {/*    spaceChannel={spaceChannel}*/}
                    {/*    spaceData={spaceData}*/}
                    {/*    activationStatus={'active'}*/}
                    {/*/>*/}
                    <div className='container grid grid-cols-12 col-span-12 gap-y-8 md:gap-y-0 md:gap-8' >
                        <div className="col-span-12 md:col-span-8 mx-auto min-h-screen md:min-h-[540px]" >
                            <div className='mt-16  grid grid-cols-12 gap-y-4 md:gap-y-0 md:gap-4'>
                                <div className='col-span-12 md:col-span-6 '>
                                    <div className="font-['din2014Bold'] gradient-color-txt text-4xl">#{spaceChannel && spaceChannel.name ? spaceChannel.name : ''}</div>
                                    <div className="font-['notoSansRegular'] text-base">
                                        {itemElRef && itemElRef.current ? itemElRef.current.length : 0}
                                        &nbsp;New
                                        &nbsp;{itemElRef && itemElRef.current && itemElRef.current.length > 1 ? 'Posts' : 'Post'}
                                    </div>
                                </div>
                                <div className='col-span-12 md:col-span-6 '>
                                    <div className="isolate flex justify-end -space-x-2 overflow-hidden">
                                        <ul className="channel-heart-user grid grid-flow-col auto-cols-min ">
                                            {spaceData && spaceData.memberships && spaceData.memberships.length > 0 && spaceData.memberships.slice(0, 10).map((user, index) => (
                                                <li key={index} style={{zIndex: (spaceData.memberships.length > 10 ? 10 : spaceData.memberships.length ) - (index + 1)}}
                                                    // className={`z-${(spaceData.memberships.length > 10 ? 10 : spaceData.memberships.length ) - (index + 1)}`}
                                                >
                                                    <div className="relative inline-block">
                                                        <Link to="#" className="hex">
                                                            <img alt="member-thumb" className={`object-cover h-8 w-8 `} src={user.photo_thumb ? user.photo_thumb : default_user_profile} />
                                                        </Link>
                                                        <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        {/*{spaceData && spaceData.memberships && spaceData.memberships.map((data, index) => (*/}
                                        {/*<img*/}
                                        {/*    key={index}*/}
                                        {/*    className="relative z-30 inline-block h-10 w-10 rounded-full ring-2 ring-white object-cover"*/}
                                        {/*    src={data.photo_thumb}*/}
                                        {/*    alt=""*/}
                                        {/*    />*/}
                                        {/*))}*/}
                                    </div>
                                    <div className="flex justify-end font-['notoSansItalic'] text-base">
                                        {spaceData && spaceData.memberships ? spaceData.memberships.length : 0}
                                        &nbsp;{spaceData && spaceData.memberships && spaceData.memberships.length > 1 ? 'Members' : 'Member'}
                                        &nbsp;Active
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-span-12  w-full">*/}
                            {/*    <MentionsInput*/}
                            {/*        value={postValue}*/}
                            {/*        placeholder="Say something!"*/}
                            {/*        onChange={handleMentionChanges}*/}
                            {/*        displayTransform={(id, display) => `@${display}`}*/}
                            {/*    >*/}
                            {/*        <Mention*/}
                            {/*            trigger="@"*/}
                            {/*            data={users}*/}
                            {/*            // markup="@__display__"*/}
                            {/*            renderSuggestion={renderMentionChanges}*/}
                            {/*            style={{*/}
                            {/*                color: 'blue'*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*        <Mention*/}
                            {/*            trigger="#"*/}
                            {/*            data={tags}*/}
                            {/*            // markup="#__display__"*/}
                            {/*            style={{*/}
                            {/*                color: 'blue'*/}
                            {/*            }}*/}
                            {/*            // renderSuggestion={renderTagsChanges}*/}
                            {/*        />*/}
                            {/*    </MentionsInput>*/}
                            {/*</div>*/}
                            <Form className="col-span-12 md:col-span-7 flex items-center font-['notoSansItalic'] text-lg text-[#888] w-full"
                                  form={updateForm}
                                  name="updateForm"
                                  id="updateForm"
                                  onFinish={handleSubmit}
                                  onChange={handleChanges}
                                  validateMessages={VALIDATE_MESSAGES}
                            >
                                <div className='mt-8 grid grid-cols-12 md:grid-cols-8 gap-y-4 md:gap-y-0 md:gap-4 md:min-h-[196px] bg-white p-6 shadow-[0_2px_3px_0_rgba(0,0,0,0.25)] rounded-[24px]'>
                                    <div className='col-span-12 md:col-span-1 flex justify-center items-center'>
                                        <span className="relative inline-block">
                                            {authUserInfo && authUserInfo.profile && (
                                                <>
                                                    <img className="h-16 w-16 rounded-full object-cover italic"
                                                         src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                         alt=""
                                                    />
                                                    <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>
                                                </>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-span-12 md:col-span-7 flex items-center font-['notoSansItalic'] text-lg text-[#888] w-full h-full">
                                        <Form.Item
                                            name="comment"
                                            className="w-full"
                                            // validateTrigger="onBlur"
                                            // rules={[{ required: true }]}
                                        >
                                            {/*<textarea*/}
                                            {/*    name="comment"*/}
                                            {/*    id="comment"*/}
                                            {/*    className="block px-4 flex focus:outline-none resize-none items-center rounded-md py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 w-full"*/}
                                            {/*    defaultValue={''}*/}
                                            {/*    onChange={handlePostTitleChanges}*/}
                                            {/*    placeholder='Say something!'*/}
                                            {/*/>*/}

                                            <TextareaAutosize
                                                placeholder="Say something!"
                                                maxRows={4}
                                                className="mt-8 focus:outline-none text-xl resize-none items-center rounded-md placeholder:text-gray-400 sm:leading-6 w-full no-scrollbar"
                                                onChange={handlePostTitleChanges}
                                                onKeyPress={handlePostKeypress}
                                            />
                                        </Form.Item>
                                    </div>


                                    <div className='col-span-12 grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 md:col-span-7 mt-4 space-x-4 md:col-start-2 flex items-center text-gray-400'>
                                        <div className='col-span-3 md:col-span-1 pr-4 cursor-pointer relative'>
                                            <i className="fa-light fa-photo-film text-xl"
                                               onClick={() => {
                                                   setActivationOpen(true);
                                                   setIsAudioUpload(false);
                                               }}
                                            />
                                        </div>
                                        {/*<div className='col-span-3 md:col-span-1 pr-4 cursor-pointer'>*/}
                                        {/*    <i className="fa-light fa-link text-xl"></i>*/}
                                        {/*</div>*/}
                                        <div className='col-span-3 md:col-span-1 pr-4 cursor-pointer'>
                                            <i className="fa-light fa-music text-xl"
                                               onClick={() => {
                                                   setActivationOpen(true);
                                                   setIsAudioUpload(true);
                                               }}
                                            />
                                        </div>
                                        {/*<div className='col-span-3 md:col-span-1 cursor-pointer'>*/}
                                        {/*    <i className="fa-light fa-poll-people text-xl"></i>*/}
                                        {/*</div>*/}
                                        <div className='col-span-12 md:col-span-6  md:col-start-6 flex justify-center md:justify-start w-full max-w-[376px]'>
                                            <Form.Item className='w-full my-auto'>
                                                <Button className="btn btn-md btn-primary w-full"
                                                        disabled={isSubmitting || !isBtnPostEnabled}
                                                        htmlType="submit"
                                                >
                                                    Post
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {/* */}
                                </div>
                            </Form>
                            <div className='mt-8  grid grid-cols-12 md:grid-cols-8 gap-y-4 md:gap-y-0 md:gap-4'>
                                <div className="col-span-8 text-2xl gradient-color-txt font-['din2014Bold']">
                                    Newest Active Posts
                                </div>
                            </div>
                            {/* {channelPostsIsFetching && (
                   <section className='bg-white mb-8 rounded-[24px] animate-pulse h-[300px] shadow-[0_2px_3px_0_rgba(0,0,0,0.25)] mt-8'>
                   </section>
                )} */}
                            {spaceChannel && itemElRef.current && itemElRef.current.map((data, index) => (
                                    <section key={data.id} className={classNames('bg-white mb-8 rounded-[24px] shadow-[0_2px_3px_0_rgba(0,0,0,0.25)]')} id={index === (posts.length - 1) ? 'last': ''} >
                                        <div className='mt-8 grid grid-cols-12 md:grid-cols-8 gap-y-4 md:gap-y-0 md:gap-4 pl-6 pr-6 pb-0 pt-6'>
                                            <div className='col-span-12 md:col-span-1 flex justify-center items-center '>
                                            <span className="relative inline-block">
                                                <img className="h-16 w-16 rounded-full object-cover"
                                                     src={data.author && data.author.profile && data.author.profile.photo_thumb
                                                         ? data.author.profile.photo_thumb : default_user_profile}
                                                     alt=""
                                                />
                                                <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>
                                            </span>
                                            </div>
                                            <div className='col-span-12 lg:col-span-7 col-start-2 '>
                                                <div className="text-2xl  flex justify-between">
                                                    <span className="gradient-color-txt font-['din2014Bold']">
                                                        @{data?.author?.profile?.username ? data?.author?.profile?.username : data?.author?.profile?.full_name}
                                                    </span>
                                                    {authUserInfo && authUserInfo.id === data.user_id &&
                                                        <EllipsisDropdown
                                                            handleDropdownSelection={handleDropdownSelection}
                                                            data={data}
                                                            type={"post"}
                                                            is_pinned={data.is_pinned}
                                                            className="text-black"/>
                                                    }
                                                </div>
                                                <div className='text-base'>
                                                    {getPostDate(data?.created_on)}
                                                </div>
                                            </div>
                                            <p className='col-span-12 md:col-span-8 mt-8 text-xl comment-text'>
                                                {data && (
                                                    parse(data.title)
                                                )}
                                            </p>
                                        </div>
                                        <div className='mt-8 grid grid-cols-12 grid-rows-12 gap-y-4 md:gap-y-0 md:gap-4 bg-white'>
                                            <div className='col-span-12 row-span-6 flex justify-center '>
                                                {(data && data.attachments) &&
                                                    <>
                                                        {(data.attachments.videos && data.attachments.videos.length > 0) ? (
                                                                <ReactPlayer
                                                                    playing={true}
                                                                    loop={true}
                                                                    controls={true}
                                                                    muted={true}
                                                                    className="videoPreview !w-full"
                                                                    url={data.attachments.videos[0].video}
                                                                />
                                                            )
                                                            :
                                                            <>
                                                                {(data.attachments.images && data.attachments.images.length > 0) ? (
                                                                        <img
                                                                            src={data.attachments.images[0].image_large}
                                                                            className="object-cover object-center group-hover:opacity-75 h-[592px] aspect-square w-full "
                                                                        />
                                                                    )
                                                                    :
                                                                    <>
                                                                        {(data.attachments.audios && data.attachments.audios.length > 0) &&
                                                                            <audio controls>
                                                                                <source src={data.attachments.audios[0].audio} type="audio/mpeg" />
                                                                            </audio>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        <section className='pl-6 pr-6 pb-6 pt-0'>
                                            <div className='mt-6 grid grid-cols-12'>
                                                <div className='col-span-12 flex justify-between'>
                                                    <i className={classNames("p-1 fa-regular fa-heart text-xl gradient-color-txt cursor-pointer", data.is_hearted ? 'fa-solid' : 'fa-regular')}
                                                       onClick={() => toggleHeart(data.id, data.is_hearted)}
                                                    />
                                                    {/* <ArrowUturnRightIcon className='h-6 w-6 gradient-color-txt'></ArrowUturnRightIcon> */}
                                                    <i className="fa-regular fa-share text-xl gradient-color-txt pr-2 cursor-pointer"
                                                       onClick={()=>setIsShareModalOpen(true)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-4 grid grid-cols-12 items-center gap-4">
                                                <div className="col-span-10 grid md:flex items-center justify-start overflow-hidden ">
                                                    <ul className="channel-heart-user grid grid-flow-col auto-cols-min ">
                                                        {data && data.hearted_by_users && data.hearted_by_users.length > 0 && data.hearted_by_users.slice(0, 3).map((user, index) => (
                                                            <li key={index} className={`${index === 0 ? 'z-30' : index === 1 ? 'z-20' : 'z-10'}`}>
                                                                <div className="relative inline-block">
                                                                    <Link to="#" className="hex">
                                                                        <img alt="member-thumb" className={`object-cover h-8 w-8 `} src={user.photo_thumb ? user.photo_thumb : default_user_profile} />
                                                                    </Link>
                                                                    <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <div className='flex ml-2 col-span-12 md:col-span-5 text-lg leading-7 font-normal'>
                                                        {data && data.hearted_by_users && data.hearted_by_users.length > 0 && (
                                                            <span>
                                                                Loved by
                                                            </span>
                                                        )}
                                                        <div className="flex">
                                                            {data && data.hearted_by_users && data.hearts_count > 0 && data.hearts_count > 3 ?
                                                                <>
                                                                    {data.hearted_by_users.slice(0,3).map((user, index) => (
                                                                        <div key={index} >
                                                                            <span key={user.id} className='gradient-color-txt italic overflow-hidden ...'>&nbsp;@{user.username ? user.username : user.full_name}</span>,
                                                                        </div>
                                                                    ))}
                                                                </>
                                                                :
                                                                <>
                                                                    {data && data.hearted_by_users && data.hearts_count > 0 && data.hearted_by_users.map((user, index) => (
                                                                        <div key={index} >
                                                                            {data.hearts_count === 2 &&
                                                                                <>
                                                                                    &nbsp;{1 === index ? ' and ' : ''}
                                                                                </>
                                                                            }
                                                                            <span key={user.id} className='gradient-color-txt italic overflow-hidden ...'>&nbsp;@{user.username ? user.username : user.full_name}</span>
                                                                            {data.hearts_count === 3 &&
                                                                                <>
                                                                                    &nbsp;{0 === index ? ', ' :  1 === index ? ', and' : ''}
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            }
                                                        </div>
                                                        {(data && data.hearted_by_users && data.hearts_count > 0 && data.hearts_count > 3) &&
                                                            <span className="text-lg">
                                                                &nbsp;and {data.hearts_count - 3} {(data.hearts_count - 3) > 1 ? 'others' : 'other'}
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-span-2 text-lg">
                                                    {(data && data.comments) ? data.comments.length : '' } {(data && data.comments && data.comments.length > 1) ? 'Comments' : 'Comment' }
                                                </div>
                                            </div>
                                            <div className='mt-4 grid grid-cols-12 gap-y-4 '>
                                                <div className='col-span-1'>
                                                    <div className="relative h-10 w-10">
                                                        <img
                                                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white object-cover"
                                                            src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                            alt=""
                                                        />
                                                        <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-transparent px-0.5 py-px">
                                                            <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <Form className='col-span-11'
                                                      form={commentForm}
                                                      name="commentForm"
                                                      id="commentForm"
                                                      onFinish={handleSubmitComment}
                                                      onChange={handleCommentChanges}
                                                      validateMessages={VALIDATE_MESSAGES}
                                                >
                                                    <Form.Item name="comment">
                                                        <div className="input-group-com input-with-btn !pl-2 ">
                                                            <TextareaAutosize
                                                                name="comment"
                                                                placeholder="Add a comment..."
                                                                maxRows={4}
                                                                className="ml-4 input-md body-text--small color-reg bring-front placeholder:text-gray-400 no-scrollbar"
                                                                // onChange={handlePostTitleChanges}
                                                                onClick={() => setModelId(data.id)}
                                                                // onKeyPress={handlePostKeypress}
                                                            />
                                                            {/*<input*/}
                                                            {/*    className="md color-light ps-4 body-txtitalic--smallest !pl-2"*/}
                                                            {/*    type="text"*/}
                                                            {/*    name="comment"*/}
                                                            {/*    placeholder="Add a comment..."*/}
                                                            {/*    onClick={() => setModelId(data.id)}*/}
                                                            {/*/>*/}
                                                            <Button
                                                                disabled={isSubmitting}
                                                                htmlType="submit"
                                                                className={'btn btn-secondary btn-md w-1/6 font-bold'}
                                                            >
                                                                Post
                                                            </Button>
                                                        </div>
                                                    </Form.Item>
                                                </Form>
                                            </div>

                                            {data && (
                                                <div className='grid grid-cols-12'>
                                                    <div className='col-span-12'>
                                                        <div className="flow-root">
                                                            <ul role="list">
                                                                {data.comments && data.comments.map((comment) => (
                                                                    <li key={comment?.id}>
                                                                        <div className="relative pb-8">
                                                                            {/*{activityItemIdx !== data.comments?.length - 1 ? (*/}
                                                                            {/*    <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />*/}
                                                                            {/*) : null}*/}
                                                                            <div className="relative flex items-start space-x-3">
                                                                                <>
                                                                                    <div className="relative">
                                                                                        <img
                                                                                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white object-cover"
                                                                                            src={comment?.commenter?.photo_thumb ? comment.commenter.photo_thumb : default_user_profile}
                                                                                            alt=""
                                                                                        />
                                                                                        <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-transparent px-0.5 py-px">
                                                                                            <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="min-w-0 flex-1">
                                                                                        <div className='flex items-center h-10 gap-x-2'>
                                                                                            <div className="flex-none text-lg ">
                                                                                                <a href={comment?.commenter?.url} className="gradient-color-txt font-bold">
                                                                                                    @{comment?.commenter?.username ? comment?.commenter?.username : (comment?.commenter?.full_name ? comment?.commenter?.full_name : '')}
                                                                                                </a>
                                                                                            </div>
                                                                                            <span className="flex-grow text-sm">
                                                                                                {getPostDate(comment?.created_on)}
                                                                                            </span>
                                                                                            {authUserInfo && authUserInfo.id === comment.user_id &&
                                                                                                <span className='flex-none flex justify-end' onClick={() => setIdsForDeletion(data.id, comment.id)}>
                                                                                                     <EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={comment} type={"comment"}/>
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="text-sm text-gray-700 ">
                                                                                            <p className='col-span-12 md:col-span-8  mt-8 text-xl comment-text'>
                                                                                                {comment && (
                                                                                                    comment.comment
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                        {commentId !== comment?.id && (
                                                                                            <div className="mt-4 text-gray-700 flex justify-end cursor-pointer" onClick={() => showCommentReply(true, comment?.id)}>
                                                                                                <i className="fa-regular fa-reply text-lg gradient-color-txt pr-2"></i>
                                                                                                <p className='text-lg gradient-color-txt'>Reply</p>
                                                                                            </div>
                                                                                        )}
                                                                                        {showCommentReplyField && commentId === comment?.id && (
                                                                                            <Form
                                                                                                className='mt-4 col-span-12 md:col-span-7 col-start-2'
                                                                                                form={commentReplyForm}
                                                                                                name="commentReplyForm"
                                                                                                id="commentReplyForm"
                                                                                                onFinish={handleSubmitSubComment}
                                                                                                // onChange={handleCommentChanges()}
                                                                                                validateMessages={VALIDATE_MESSAGES}
                                                                                            >
                                                                                                <div className='grid grid-cols-12 gap-4 '>
                                                                                                    <Form.Item name="reply" className='col-span-12'>
                                                                                                        <div className="input-group-com col-span-12 input-with-btn !pl-2">
                                                                                                            <TextareaAutosize
                                                                                                                name="reply"
                                                                                                                placeholder="Reply comment..."
                                                                                                                maxRows={4}
                                                                                                                className="w-full ml-4 input-md body-text--small color-reg bring-front no-scrollbar"
                                                                                                                onClick={() => setReplyModelId(comment?.model_id, comment?.id)}
                                                                                                            />
                                                                                                            {/*<input*/}
                                                                                                            {/*    onClick={() => setReplyModelId(comment?.model_id, comment?.id)}*/}
                                                                                                            {/*    className="md color-light ps-4 body-txtitalic--smallest !pl-2"*/}
                                                                                                            {/*    type="text"*/}
                                                                                                            {/*    name="reply"*/}
                                                                                                            {/*    placeholder="Reply comment..."*/}
                                                                                                            {/*/>*/}
                                                                                                            <Button disabled={isSubmitting}
                                                                                                                    htmlType="submit"
                                                                                                                    onClick={() => handleSubmitSubComment}
                                                                                                                    className={'btn btn-secondary btn-md w-1/6 font-bold'}
                                                                                                            >
                                                                                                                Posts
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </Form.Item>
                                                                                                </div>
                                                                                            </Form>
                                                                                        )}

                                                                                        {/* loop */}
                                                                                        {comment && comment.replies && comment.replies.map((reply) => (
                                                                                            <div key={reply.id} className='mt-6 relative md:flex items-start space-x-3'>
                                                                                                <div className="relative">
                                                                                                    <img
                                                                                                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white object-cover"
                                                                                                        src={reply?.commenter?.photo_thumb ? reply.commenter.photo_thumb : default_user_profile}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-transparent px-0.5 py-px">
                                                                                                        <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="min-w-0 flex-1">
                                                                                                    <div className='grid grid-cols-12'>
                                                                                                        <div className='col-span-12 items-center flex h-10 gap-x-2'>
                                                                                                            <div className="flex-none text-lg">
                                                                                                                <a href={reply?.commenter?.url} className="gradient-color-txt font-bold">
                                                                                                                    @{reply?.commenter?.username ? reply?.commenter?.username :
                                                                                                                    (reply?.commenter?.full_name ? reply?.commenter?.full_name : '')}
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <span className="flex-grow text-sm">
                                                                                                                {getPostDate(reply?.created_on)}
                                                                                                            </span>
                                                                                                            <span className='flex-none flex justify-end'
                                                                                                                // onClick={() => setIdsForDeletion(data.id, reply.id)}
                                                                                                            >
                                                                                             {authUserInfo && authUserInfo.id === reply.user_id &&
                                                                                                 <span className='flex-none flex justify-end' onClick={() => setIdsForDeletion(data.id, comment.id)}>
                                                                                                        <EllipsisDropdown handleDropdownSelection={handleDropdownSelection}  data={reply} type={"sub-comments"}/>
                                                                                                    </span>
                                                                                             }
                                                                                            </span>
                                                                                                        </div>

                                                                                                        <div className="col-span-12 text-sm text-gray-700 ">
                                                                                                            {/*<p className='text-lg'>{reply?.comment}</p>*/}
                                                                                                            <p className='col-span-12 md:col-span-8  mt-8 text-xl comment-text'>
                                                                                                                {reply && (
                                                                                                                    reply.comment
                                                                                                                )}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        {commentId !== reply?.id && (
                                                                                                            <div className="col-span-12 mt-2 text-gray-700 flex justify-end cursor-pointer" onClick={() => showSubCommentReply(true, reply?.id)}>
                                                                                                                <i className="fa-regular fa-reply text-lg gradient-color-txt pr-2"></i>
                                                                                                                <p className='text-lg gradient-color-txt'>Reply</p>
                                                                                                            </div>
                                                                                                        )}

                                                                                                        <div className="mt-2 text-sm text-gray-700 col-span-12">
                                                                                                            {showSubCommentField && subCommentReplyId === reply?.id && (
                                                                                                                <Form className='col-span-12 md:col-span-7 col-start-2'
                                                                                                                      form={commentReplyForm}
                                                                                                                      name="commentReplyForm"
                                                                                                                      id="commentReplyForm"
                                                                                                                      onFinish={handleSubmitSubReply}
                                                                                                                    // onChange={handleCommentChanges()}
                                                                                                                      validateMessages={VALIDATE_MESSAGES}
                                                                                                                >
                                                                                                                    <div className='grid grid-cols-12 gap-4 flex items-center pt-6'>
                                                                                                                        <Form.Item name="reply" className='col-span-12'>
                                                                                                                            <div className="input-group-com col-span-12 input-with-btn !pl-2">
                                                                                                                                <TextareaAutosize
                                                                                                                                    // name="reply"
                                                                                                                                    placeholder="Reply comment..."
                                                                                                                                    maxRows={4}
                                                                                                                                    className="w-full ml-4 input-md body-text--small color-reg bring-front no-scrollbar"
                                                                                                                                    onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply.id)}
                                                                                                                                />
                                                                                                                                {/*<input*/}
                                                                                                                                {/*    onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply.id)}*/}
                                                                                                                                {/*    className="md color-light ps-4 body-txtitalic--smallest !pl-2"*/}
                                                                                                                                {/*    type="text"*/}
                                                                                                                                {/*    placeholder="Reply comment..."*/}
                                                                                                                                {/*    // value={searchText}*/}
                                                                                                                                {/*    // onBlur={() => getMembersRequest()}*/}
                                                                                                                                {/*/>*/}
                                                                                                                                <Button
                                                                                                                                    disabled={isSubmitting}
                                                                                                                                    htmlType="submit"
                                                                                                                                    onClick={() => handleSubmitSubComment}
                                                                                                                                    className={'btn btn-secondary btn-md w-1/6'}
                                                                                                                                    // onClick={() => {
                                                                                                                                    // 	clearTimeout(searchInputTimer);
                                                                                                                                    // 	setSearchInputTimer(null);
                                                                                                                                    // 	getMembersRequest().then((r) => {});
                                                                                                                                    // }}
                                                                                                                                >
                                                                                                                                    Posts
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Form.Item>
                                                                                                                    </div>
                                                                                                                </Form>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {reply && reply.sub_replies && reply.sub_replies.map((sub_reply, commentId) => (
                                                                                                        <div key={sub_reply?.id} className='relative md:flex items-start space-x-3 mt-2'>
                                                                                                            <div className="relative">
                                                                                                                <img
                                                                                                                    className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white object-cover"
                                                                                                                    src={sub_reply?.commenter?.photo_thumb ? sub_reply?.commenter?.photo_thumb : default_user_profile}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                                <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-transparent px-0.5 py-px">
                                                                                                                    <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="min-w-0 flex-1">
                                                                                                                <div className='grid grid-cols-12'>
                                                                                                                    <div className='col-span-12 items-center flex h-10 gap-x-2'>
                                                                                                                        <div className="flex-none text-lg">
                                                                                                                            <a href={sub_reply?.commenter?.url} className="gradient-color-txt font-bold">
                                                                                                                                @{sub_reply?.commenter?.username ? sub_reply?.commenter?.username :
                                                                                                                                (sub_reply?.commenter?.full_name ? sub_reply?.commenter?.full_name : '')}
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                        <span className="flex-grow text-sm">
                                                                                                                            {getPostDate(sub_reply?.created_on)}
                                                                                                                        </span>
                                                                                                                        {authUserInfo && authUserInfo.id === sub_reply.user_id &&
                                                                                                                            <span className='flex-none flex justify-end' onClick={() => setIdsForDeletion(data?.id, comment?.id, reply?.id, sub_reply?.id)}>
                                                                                                                                <EllipsisDropdown handleDropdownSelection={handleDropdownSelection}  data={sub_reply} type={"sub-replies"}/>
                                                                                                                            </span>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="text-sm text-gray-700 col-span-12">
                                                                                                                        {/*<p className='text-lg'>{sub_reply?.comment} </p>*/}
                                                                                                                        <p className='col-span-12 md:col-span-8 text-xl comment-text'>
                                                                                                                            {sub_reply && (
                                                                                                                                sub_reply.comment
                                                                                                                            )}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    {commentId !== reply?.id && (
                                                                                                                        <div className="col-span-12 mt-2 text-gray-700 flex justify-end cursor-pointer" onClick={() => showSubCommentSubReply(true, sub_reply?.id)}>
                                                                                                                            <i className="fa-regular fa-reply text-lg gradient-color-txt pr-2"></i>
                                                                                                                            <p className='text-lg gradient-color-txt'>Reply</p>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                    <div className="mt-2 text-sm text-gray-700 col-span-12">
                                                                                                                        {showSubCommentSubReplyField && subCommentSubReplyId === sub_reply?.id && (
                                                                                                                            <Form className='col-span-12 md:col-span-7 col-start-2'
                                                                                                                                  form={commentReplyForm}
                                                                                                                                  name="commentReplyForm"
                                                                                                                                  id="commentReplyForm"
                                                                                                                                  onFinish={handleSubmitSubReply}
                                                                                                                                // onChange={handleCommentChanges()}
                                                                                                                                  validateMessages={VALIDATE_MESSAGES}
                                                                                                                            >
                                                                                                                                <div className='grid grid-cols-12 gap-4 flex items-center pt-6'>
                                                                                                                                    <Form.Item name="reply" className='col-span-12'>
                                                                                                                                        <div className="input-group-com col-span-12 input-with-btn !pl-2">
                                                                                                                                            <TextareaAutosize
                                                                                                                                                // name="reply"
                                                                                                                                                placeholder="Reply comment..."
                                                                                                                                                maxRows={4}
                                                                                                                                                className="w-full ml-4 input-md body-text--small color-reg bring-front no-scrollbar"
                                                                                                                                                onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply.id)}
                                                                                                                                            />
                                                                                                                                            {/*<input*/}
                                                                                                                                            {/*    onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply.id)}*/}
                                                                                                                                            {/*    className="md color-light ps-4 body-txtitalic--smallest !pl-2"*/}
                                                                                                                                            {/*    type="text"*/}
                                                                                                                                            {/*    placeholder="Reply comment..."*/}
                                                                                                                                            {/*    // value={searchText}*/}
                                                                                                                                            {/*    // onBlur={() => getMembersRequest()}*/}
                                                                                                                                            {/*/>*/}
                                                                                                                                            <Button
                                                                                                                                                disabled={isSubmitting}
                                                                                                                                                htmlType="submit"
                                                                                                                                                onClick={() => handleSubmitSubComment}
                                                                                                                                                className={'btn btn-secondary btn-md w-1/6'}
                                                                                                                                                // onClick={() => {
                                                                                                                                                // 	clearTimeout(searchInputTimer);
                                                                                                                                                // 	setSearchInputTimer(null);
                                                                                                                                                // 	getMembersRequest().then((r) => {});
                                                                                                                                                // }}
                                                                                                                                            >
                                                                                                                                                Posts
                                                                                                                                            </Button>
                                                                                                                                        </div>
                                                                                                                                    </Form.Item>
                                                                                                                                </div>
                                                                                                                            </Form>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>

                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </section>
                                    </section>
                                )
                            )}
                            {channelPostsIsFetching &&
                                <section className='flex justify-center w-full  mb-8 cursor-pointer text-2xl'
                                    // onClick={() => handleSeeMorePosts()}
                                >
                                    <i className="fa-solid fa-spinner fa-spin gradient-color-txt"></i>
                                    {/*<i className="fa fa-refresh fa-spin" aria-hidden="true"></i>*/}
                                </section>
                            }
                        </div>
                        <div className='col-span-12 md:col-span-4 gap-y-4 md:gap-y-0 md:gap-4 mt-16'>
                            {/* <Form className=''
                            form={searchForm}
                            name="searchForm"
                            id="searchForm"
                            onFinish={handleSearchPosts}
                            // onChange={handleCommentChanges()}
                            validateMessages={VALIDATE_MESSAGES}
                            > */}
                            <Form
                                className=' grid grid-cols-12 gap-y-4 md:gap-y-0 md:gap-4'
                                form={searchForm}
                                name="searchForm"
                                id="searchForm"
                                onFinish={handleSearchPosts}
                                validateMessages={VALIDATE_MESSAGES}
                            >
                                <div className="input-group-com input-with-btn !pl-2 col-span-12 h-[40px] flex items-baseline">
                                    <Form.Item name="searchText" className=''>
                                        <input
                                            className="md color-light  body-txtitalic--smallest !pl-2 !bg-transparent"
                                            type="text"
                                            placeholder="Search posts.."
                                            name="searchText"
                                            // value={searchText}
                                            // onBlur={() => getMembersRequest()}
                                        />
                                    </Form.Item>
                                    <Button
                                        htmlType="submit"
                                        className={'btn btn-secondary btn-md w-1/3 md:w-[432px]  '}
                                        // onClick={() => {
                                        // 	clearTimeout(searchInputTimer);
                                        // 	setSearchInputTimer(null);
                                        // 	getMembersRequest().then((r) => {});
                                        // }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </Form>

                            <div className="gap-y-8 mt-16">
                                {spaceChannels && spaceChannels.length > 0 && spaceChannels.map((channel, index) => (
                                    <div key={index}
                                         className={`right-nav w-full ${channel.guid === guid ? ' border-nav-color ':' cursor-pointer '} flex  text-lg font-semibold text-gray-400 leading-10 mb-4 `}
                                         onClick={()=>{
                                             if (channel.guid !== guid) {
                                                 handleChangeChannel(spaceData.username, channel.guid)
                                             }
                                         }
                                         }
                                    >
                                        <span className='flex-grow ellipsis truncate ...'>{channel.name}</span>
                                        <span className='flex-none flex justify-end'>+{channel.posts_count}</span>
                                    </div>
                                ))}
                            </div>

                            {hasPinnedItem &&
                                <div className='mt-14  grid grid-cols-12 md:grid-cols-8 gap-y-4 md:gap-y-0 md:gap-4'>
                                    <div className="col-span-8 text-2xl gradient-color-txt font-['din2014Bold']">
                                        Pinned
                                    </div>
                                </div>
                            }

                            {spaceChannel && itemElRef && itemElRef.current && itemElRef.current.map((data, index) => (
                                <div key={index}>
                                    {data.is_pinned && (
                                        <section className='bg-white mb-8 rounded-[24px] shadow-[0_2px_3px_0_rgba(0,0,0,0.25)]'>
                                            <div className='mt-8 grid grid-cols-12 md:grid-cols-7 gap-y-4 md:gap-y-0 md:gap-2 pt-6'>
                                                <div className='col-span-12 md:col-span-2 flex justify-center items-center '>
                                                    <span className="relative inline-block">
                                                        <img
                                                            className="h-16 w-16 rounded-full object-cover"
                                                            src={data?.author && data?.author?.profile?.photo_thumb
                                                                ? data?.author.profile?.photo_thumb
                                                                : default_user_profile}
                                                            alt=""
                                                        />
                                                        <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"></i>
                                                    </span>
                                                </div>
                                                <div className='col-span-12 md:col-span-4 col-start-2 '>
                                                    <div className="text-2xl gradient-color-txt font-['din2014Bold']">
                                                        @{data?.author?.profile?.username ? data?.author?.profile?.username :
                                                        (data?.author?.profile?.full_name ? data?.author?.profile?.full_name : '')}</div>

                                                    <div className='text-base'>{getPostDate(data?.created_on)}</div>
                                                </div>
                                                <div className='col-span-12 md:col-span-1 col-start-4 justify-self-end pr-4'>
                                                    {authUserInfo && authUserInfo.id === data.user_id &&
                                                        <EllipsisDropdown
                                                            handleDropdownSelection={handleDropdownSelection}
                                                            data={data}
                                                            type={"post"}
                                                            is_pinned={data.is_pinned}
                                                            isShowPinOnly={true}
                                                            className="text-black"/>
                                                    }
                                                </div>
                                            </div>
                                            <div className='mt-8  grid grid-cols-12 md:grid-cols-8 grid-rows-12 gap-y-4 md:gap-y-0 md:gap-4'>
                                                <div className='col-span-12 md:col-span-8 row-span-6 flex justify-center'>
                                                    {(data && data.attachments) &&
                                                        <>
                                                            {(data.attachments.videos && data.attachments.videos.length > 0) ? (
                                                                    <ReactPlayer
                                                                        playing={true}
                                                                        loop={true}
                                                                        controls={true}
                                                                        muted={true}
                                                                        className="videoPreview !w-full h-[300px]"
                                                                        url={data.attachments.videos[0].video}
                                                                    />
                                                                )
                                                                :
                                                                <>
                                                                    {(data.attachments.images && data.attachments.images.length > 0) ? (
                                                                            <img
                                                                                src={data.attachments.images[0].image_large}
                                                                                className="object-cover object-center group-hover:opacity-75 h-[300px] aspect-square w-full "
                                                                            />
                                                                        )
                                                                        :
                                                                        <>
                                                                            {(data.attachments.audios && data.attachments.audios.length > 0) &&
                                                                                <audio controls>
                                                                                    <source src={data.attachments.audios[0].audio} type="audio/mpeg" />
                                                                                </audio>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    {/*{data?.attachments?.videos?.length > 0 ? (*/}
                                                    {/*        <ReactPlayer*/}
                                                    {/*        playing={true}*/}
                                                    {/*        loop={true}*/}
                                                    {/*        controls={true}*/}
                                                    {/*        muted={true}*/}
                                                    {/*        className="videoPreview !w-full"*/}
                                                    {/*        url={data?.attachments?.videos[0]?.video}*/}
                                                    {/*    />*/}

                                                    {/*    ): data?.attachments?.images?.length ? (*/}
                                                    {/*        <img*/}
                                                    {/*        src={data?.attachments?.images[0]?.image_large}*/}
                                                    {/*        className="object-cover object-center group-hover:opacity-75 h-[592px] aspect-square w-full "*/}
                                                    {/*        />*/}
                                                    {/*    ) : (*/}
                                                    {/*        <></>*/}
                                                    {/*    )*/}
                                                    {/*}*/}
                                                </div>
                                                <p className='col-span-8 text-xl p-6 comment-text'>
                                                    {data?.title}
                                                </p>
                                            </div>
                                        </section>
                                    )}
                                </div>
                            ))}
                            {/*<div className='mt-8  grid grid-cols-12 md:grid-cols-8 gap-y-4 md:gap-y-0 md:gap-4'>*/}
                            {/*    <div className="col-span-8 text-2xl gradient-color-txt font-['din2014Bold']">*/}
                            {/*        Trending Tags*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className='mt-8  grid grid-cols-12 gap-y-4 md:gap-4 '>*/}
                            {/*    <div className="col-span-12 md:col-span-2 border gradient-border-not-rounded  flex justify-center items-center py-1 px-2 text-xs font-semibold leading-4">*/}
                            {/*        Tag*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-12 md:col-span-2 border gradient-border-not-rounded  flex justify-center items-center py-1 px-2 text-xs font-semibold leading-4">*/}
                            {/*        Tag*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-12 md:col-span-2 border gradient-border-not-rounded  flex justify-center items-center py-1 px-2 text-xs font-semibold leading-4">*/}
                            {/*        Tag*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-12 md:col-span-2 border gradient-border-not-rounded  flex justify-center items-center py-1 px-2 text-xs font-semibold leading-4">*/}
                            {/*        Tag*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-12 md:col-span-2 border gradient-border-not-rounded  flex justify-center items-center py-1 px-2 text-xs font-semibold leading-4">*/}
                            {/*        Tag*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-12 md:col-span-2 border gradient-border-not-rounded  flex justify-center items-center py-1 px-2 text-xs font-semibold leading-4">*/}
                            {/*        Tag*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-12 md:col-span-2 border gradient-border-not-rounded  flex justify-center items-center py-1 px-2 text-xs font-semibold leading-4">*/}
                            {/*        Tag*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-12 md:col-span-2 border gradient-border-not-rounded  flex justify-center items-center py-1 px-2 text-xs font-semibold leading-4">*/}
                            {/*        Tag*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </main>
                :
                <></>
            }
        </>
	);
};

export default memo(Channel);