import { memo, useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Register from '../Register';
import { getEmailVerification } from '../../../services/user';
import './EmailVerification.scss';

const EmailVerification = (props) => {
	const { verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId } = props;
	const [params] = useSearchParams();
	const token = params.get('token');
	const [resultMessage, setResultMessage] = useState('');
	const [email, setEmail] = useState(null);
	const [verifying, setVerifying] = useState(true);
	const [verified, setVerified] = useState(false);

	const verifyEmail = useCallback(
		async (token) => {
			try {
				setVerified(false);
				await getEmailVerification({ token }).then((result) => {
					if (result) {
						if (result.result) {
							setVerified(true);
							setEmail(result.email);
						}

						setResultMessage(result.message);
						setTimeout(() => {
							setVerifying(false);
						}, 200);
					}
				});
			} catch (error) {
				console.log(error);
			}
		},
		[setEmail]
	);

	useEffect(() => {
		document.title = 'Commonspace Registration';
		if (verifying && !email) {
			if (token) {
				setTimeout(() => {
					// added timeout delay to allow grecaptcha to render first
					verifyEmail(token).then();
				}, 1000);
			} else {
				setResultMessage('Email verification token is required.');
				setVerifying(false);
			}
		}
	}, [verifyEmail, token, email, verifying, verified]);

	return (
		<Register
			verifyEmailSpaceUsername={verifyEmailSpaceUsername}
			verifyEmailSpaceMembershipId={verifyEmailSpaceMembershipId}
			verifyEmailSpaceItemId={verifyEmailSpaceItemId}
			emailVerificationToken={token}
			verified={verified}
			email={email}
			verificationMessage={resultMessage}
			verifying={verifying}
		/>
	);
};

export default memo(EmailVerification);
