import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';

import routes from '../../../routes';

import space_ex1 from '../../../../src/assets/images/space_ex1.png';
import space_ex2 from '../../../../src/assets/images/space_ex2.png';
import space_ex3 from '../../../../src/assets/images/space_ex3.png';
import space_ex4 from '../../../../src/assets/images/space_ex4.png';
import space_ex5 from '../../../../src/assets/images/space_ex5.png';
import space_ex6 from '../../../../src/assets/images/space_ex6.png';
import space_ex7 from '../../../../src/assets/images/space_ex7.png';
import {getSitewideLink} from "../../../utils/sitewideLinks";

const GettingStarted = () => {
	return (
		<>
			<main id="member-profile" className="flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12 md:gap-8 gap-y-4">
						<div className="col-span-12">
							<h1 className="header-large !text-5xl">Getting Started</h1>
						</div>
						<div className="col-span-12 grid grid-cols-1 gap-5 md:grid-cols-12">
							<div className="md:col-span-7 grid max-[767px]:order-last">
								<p className="body-text--reg mt-4">
									With Commonspace, you can build a community site for yourself, your
									business, a group, or anything else you like. We call these{' '}
									{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
									{/*	Spaces*/}
									{/*</a>*/}
									{getSitewideLink('spaces_create_space', 'gradient-color-txt body-txtitalic--reg')}
									{' '}and are similar to an ecommerce website, except instead of adding
									different products to sell, you’re adding different{' '}
									{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
									{/*	Memberships*/}
									{/*</a>*/}
									{getSitewideLink('memberships_create_space', 'gradient-color-txt body-txtitalic--reg')}
									. You also have an{' '}
									{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
									{/*	Updates*/}
									{/*</a>*/}
									{getSitewideLink('updates_create_space', 'gradient-color-txt body-txtitalic--reg')}
									{' '}blog area,{' '}
									{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
									{/*	Benefits*/}
									{/*</a>*/}
									{getSitewideLink('benefits_create_space', 'gradient-color-txt body-txtitalic--reg')}
									{' '}area, and{' '}
									{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
									{/*	Members*/}
									{/*</a>*/}
									{getSitewideLink('members_create_space', 'gradient-color-txt body-txtitalic--reg')}
									{' '}area. You can customize all of these and enable other features to
									integrate your Space into your business or group.
								</p>
								<h3 className="header-medium mt-8">How It Works</h3>
								<p className="body-text--reg mt-2">
									For your first time creating on Commonspace, you’ll be guided
									through each step to create and launch your Space. Here are the{' '}
									{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
									{/*	steps to create your first Space*/}
									{/*</a>*/}
									{getSitewideLink('steps_to_create_space', 'gradient-color-txt body-txtitalic--reg')}
									:
								</p>
								<ol className="mt-8 list-decimal list-outside pl-5" type="1">
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Create Your Space*/}
										{/*</a>*/}
										<div className='flex'>
											<div >{getSitewideLink('create_your_space', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Get your Space set up with basic details, logo, and profile
												information.
											</div>
										</div>
										{/* {getSitewideLink('create_your_space', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Get your Space set up with basic details, logo, and profile
										information. */}
									</li>
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Add Sections*/}
										{/*</a>*/}
										<div className='flex'>
											<div >{getSitewideLink('add_sections', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Customize your Welcome Section and other areas for your
												homepage.
											</div>
										</div>
										{/* {getSitewideLink('add_sections', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Customize your Welcome Section and other areas for your
										homepage. */}
									</li>
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Create Your First Membership*/}
										{/*</a>*/}
										<div className='flex'>
											<div >{getSitewideLink('create_membership', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Offer a free or paid Membership so that people can signup and
												join your Space.
											</div>
										</div>
										{/* {getSitewideLink('create_membership', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Offer a free or paid Membership so that people can signup and
										join your Space. */}
									</li>
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Add Membership Traits*/}
										{/*</a>*/}
										<div className='flex'>
											<div >{getSitewideLink('add_membership_traits', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Customize your first Membership with optional information and
												additional details.
											</div>
										</div>
										{/* {getSitewideLink('add_membership_traits', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Customize your first Membership with optional information and
										additional details. */}
									</li>
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Create Your First Benefits*/}
										{/*</a>*/}
										<div className='flex'>
											<div >{getSitewideLink('create_benefits', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Create and add Benefits that will be provided to Members with
												your new Membership.
											</div>
										</div>
										{/* {getSitewideLink('create_benefits', 'gradient-color-txt body-txtitalic--reg')}
										{' '}Create and add Benefits that will be provided to Members with
										your new Membership. */}
									</li>
								</ol>
								<p className="body-text--reg mt-5">
									Once you’ve completed these steps, you’ll be able to preview and
									launch your Space! You will also have the chance to further
									customize your Space by completing these optional guided steps:
								</p>
								<ul className="mt-5 list-disc list-outside pl-5">
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Create Your First Item*/}
										{/*</a>*/}
										<div className='flex'>
											
											<div >{getSitewideLink('create_item', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Offer a free or paid digital Item that gives Members additional
												Benefits or upgraded access.
											</div>
										</div>
										
									</li>
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Post Your First Update*/}
										{/*</a>*/}
										<div className='flex'>
											
											<div >													
												{getSitewideLink('post_update', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Welcome visitors with a public post or make a Members Only post
												to guide your first Members.
											</div>
										</div>
										
									</li>
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Enable Community Features*/}
										{/*</a>*/}
										<div className='flex'>
											
											<div >													
												{getSitewideLink('enable_community_features', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Set up and customize your Community Channels, Points System, and
												Leaderboard.
											</div>
										</div>
										
									</li>
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Upgrade to Pro*/}
										{/*</a>*/}
										<div className='flex'>
											
											<div >													
												{getSitewideLink('upgrade_pro', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Connect your domain, get custom branding, and other Pro features
												by upgrading your Space.
											</div>
										</div>
										
									</li>
									<li className="body-text--reg">
										{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
										{/*	Invite Your Team*/}
										{/*</a>*/}
										<div className='flex'>
											
											<div >													
												{getSitewideLink('invite_team', 'gradient-color-txt body-txtitalic--reg')}
												{' '}Give your team special access to your Space to manage, moderate,
												and post.
											</div>
										</div>										
									</li>
								</ul>
								<p className="body-text--reg mt-5 font-['notoSansItalic']">
									These additional steps are optional and you can always do them later
									from your Dashboard.{' '}
								</p>
								<h4 className="header-small mt-5">
									Ready to get started and create your Space?
								</h4>
								<Link
									// to="#"
									to={routes.createSpace()}
									target="_self"
									className="btn btn-lg btn-primary mt-5"
								>
									Let's go! <i class="fa-solid fa-rocket ml-4"></i>
								</Link>

								<p className="body-text--reg mt-5 italic">
									Need help?{' '}
									{/*<a href="" className="gradient-color-txt body-txtitalic--reg">*/}
									{/*	Get support*/}
									{/*</a>*/}
									{getSitewideLink('view_guide_create_space', 'gradient-color-txt body-txtitalic--reg')}
								</p>
							</div>
							<div className="md:col-span-5 max-[767px]:order-first">
								<div className="grid grid-cols-12 md:gap-8">
									<div className="col-span-6">
										<img className="img-fluid mx-auto" src={space_ex1} />
									</div>
									<div className="col-span-6 text-end">
										<img className="img-fluid mx-auto" src={space_ex2} />
										<img className="img-fluid mx-auto mt-5" src={space_ex3} />
									</div>
								</div>
								<div className="grid grid-cols-12 md:gap-8">
									<div className="col-span-12">
										<img className="img-fluid mx-auto mt-5" src={space_ex4} />
									</div>
									<div className="col-span-6 mt-5">
										<img className="img-fluid mx-auto" src={space_ex5} />
										<img className="img-fluid mx-auto mt-5" src={space_ex6} />
									</div>
									<div className="col-span-6 mt-5 text-end">
										<img className="img-fluid mx-auto" src={space_ex7} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(GettingStarted);
