import { memo } from 'react';
import Card from '../../ProfileContent/ManageFollowers/Card';
import {followUser, unfollowUser} from "../../../../services/follow";

const Following = (props) => {
	const { data, authUserInfo, viewUserId, refetch } = props;

	const handleFollowUnfollow = async (list, event, id) => {
		if (authUserInfo) {
			const data = {
				user_id: authUserInfo.id,
				follow_id: id
			};

			console.log(data);
			refetch(true);

			// if (event === 'follow') {
			// 	await followUser(data);
			// } else {
			// 	await unfollowUser(data);
			// }
		}
	};

	return (
		<>
			<div className="user-view col-span-12">
				<div className="grid grid-cols-12 gap-8 mt-4 card-view">
					<div className="col-span-12 mt-4">
						<div className="grid grid-cols-12  gap-4">
							{data && data.length > 0 ? (
								<>
									{data.map((item, index) => (
										<Card
											col="col-span-12 sm:col-span-6 lg:col-span-4"
											key={index}
											type="followings"
											data={item}
											onEventClick={handleFollowUnfollow}
											displayButtons={false}
										></Card>
									))}
								</>
							) : (
								<>
									{!data && authUserInfo && authUserInfo.id === viewUserId ? (
										<div className='col-span-12'>
											<h3 className="header-medium text-center mt-5">
												No Following Yet.
											</h3>
											<p className="header-small color-reg mt-5 text-center">
												Profiles that you follow will show here.
											</p>
										</div>
									) : (
										<div className='col-span-12'>
											<h3 className="header-medium text-center mt-5">
												This profile does not follow anyone.
											</h3>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(Following);
