import { memo, useState } from 'react';
import { TRANSACTIONS_CURRENCIES, TRANSACTIONS_DAYS } from '../../../../constants/user';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';

const ViewTransactions = () => {
	const [selectedTransactionDays, setSelectedTransactionDays] = useState('30');
	const [selectedTransactionCurrency, setSelectedTransactionCurrency] = useState('usd');

	return (
		<>
			<div className="dashboard-wrapper mt-4">
				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
					<div className="col-span-12 md:col-span-6">
						<h3 className="header-medium">Transactions</h3>
					</div>
					<div className="col-span-12 md:col-span-6">
						<>
							<p className="body-txtitalic--large text-end">2 Recent</p>
						</>
					</div>
				</div>
				<p className="pt-3 body-txtitalic--small">
					You can browse and manage all your transactions that have been processed by
					Commonspace and from all Spaces sites below. This includes transactions paid
					with any Payment Method or Cryptocurrency Wallet.{' '}
					<a className="gradient-color-txt" href="">
						Learn more about viewing and managing your transactions.
					</a>
				</p>
				<div className="grid grid-cols-12 gap-3 md:grid-cols-3 md:gap-x-3 mt-3">
					<div className="col-span-12 md:col-span-4">
						<DropdownWithItems
							title="Days"
							dropDownClass="dropdown-bg"
							customButtonClass="btn-md btn-secondary"
							icon="fa-light fa-calendar-days"
							selectedValue={selectedTransactionDays}
							listItems={TRANSACTIONS_DAYS}
							onDropdownChange={(value) => setSelectedTransactionDays(value)}
						/>
					</div>
					<div className="col-span-12 md:col-span-4">
						<DropdownWithItems
							title="Currency"
							dropDownClass="dropdown-bg"
							customButtonClass="btn-md btn-secondary"
							icon="fa-light fa-coins"
							selectedValue={selectedTransactionCurrency}
							listItems={TRANSACTIONS_CURRENCIES}
							onDropdownChange={(value) => setSelectedTransactionCurrency(value)}
						/>
					</div>
					<div className="col-span-12 md:col-span-4">
						<div className="input-group-com input-with-btn ">
							<input
								className="md color-light ps-4 body-txtitalic--smallest !pl-2"
								type="email"
								placeholder="Search"
							/>
							<button className="btn btn-secondary btn-md w-1/4">
								<i className="fa-light fa-magnifying-glass"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(ViewTransactions);
