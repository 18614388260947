import { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import routes from '../../../../../routes';

const HeaderProMenuSidebar = (props) => {
	const { spaceData, activeTab, onSelectedTabChange } = props;
	const navigate = useNavigate();
	const tabs = ['home', 'updates', 'benefits', 'members'];
	const [totalNavItems, setTotalNavItems] = useState(0);

	const setActiveTabChange = (item) => {
		onSelectedTabChange(item);

		if (item === 'home' || item === 'members') {
			// force page url navigate to reset location path when going back to home
			navigate(routes.viewUserSpace() + spaceData.username, { replace: true });
		}
	};

	const header_navs = [];
	if (spaceData && spaceData.pro_navigations) {
		spaceData.pro_navigations.forEach((item) => {
			if (item.section === 'header') {
				header_navs.push(item);
			}
		});
	}

	useEffect(() => {
		setTotalNavItems(tabs.length + header_navs.length);
	}, [activeTab, totalNavItems]);

	useEffect(() => {

	}, [activeTab, totalNavItems]);

	useEffect(() => {}, [activeTab]);

	return (
		<section>
			<div className='grid grid-cols-4 gap-8 text-secondary items-center'>
				{tabs.map(tab => (
					<Link
						key={tab}
						className="dropdown-item text-xl mt-[24px] text-center py-[4px]"
					>										
						<span className="text-xl font-[notoSansSemiBold] label">										
						{tab.charAt(0).toUpperCase() + tab.slice(1)}
						</span>
					</Link>
					))}								
			</div>
			<div className={`grid grid-cols-4 gap-8 text-secondary items-center`}>
				{header_navs.map((data, index) => (
					<Link
						to={data.url}
						className="dropdown-item text-xl text-center mt-[24px] py-[4px]"
					>										
						<span className="text-xl font-[notoSansSemiBold] label">										
						{data.label}
						</span>
					</Link>
				))}
			</div>
		
		</section>
		// <ul
		// 	className="nav-login "
		// 	id="pills-tab"
		// 	role="tablist"
		// >
		// 	{tabs.map(tab => (
		// 		<li  role="presentation " className='py-2.5' key={tab}>
		// 			{/* <Button
		// 				className={'w-full' + (activeTab === tab ? 'active' : '')}
		// 				onClick={() => setActiveTabChange(tab)}
		// 			>
						
		// 			</Button> */}
		// 			<span className='label' onClick={() => setActiveTabChange(tab)}>{tab.charAt(0).toUpperCase() + tab.slice(1)}</span>
		// 		</li>
		// 	))}
		// 	{header_navs.map((data, index) => (
		// 		<li className="flex py-2.5" key={index}>
		// 			<a
		// 				className="w-full label"
		// 				target={data.open_type === 'open_new_window' ? '_blank' : ''}
		// 				href={data.url}
		// 			>
		// 				{data.label}
		// 			</a>
		// 			{/* <Link
		// 				className="nav-link"
		// 				to={data.url}
		// 				target={data.open_type === 'open_new_window' ? '_blank' : ''}
		// 			>
		// 				{data.label}
		// 			</Link> */}
		// 		</li>
		// 	))}
		// </ul>
	);
};
export default memo(HeaderProMenuSidebar);
