import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { addSpaceContact, sendEmailSpaceContact } from '../../../../services/space';
import Alert from 'react-bootstrap/Alert';

const SpaceContact = (props) => {
	const [contactForm] = Form.useForm();
	const [isContactSubmit, setContactSubmit] = useState(false);
	const [alert, setAlert] = useState(null);
	const { spaceData, space_info_slug } = props;

	const handleContactSubmit = useCallback(
		async (values) => {
			const data = values;
			setContactSubmit(true);
			data.space_id = spaceData.id;
			data.space_info_slug = space_info_slug;

			try {
				const response = await addSpaceContact(data);
				if (response && response.result) {
					await sendEmail(data);
				} else {
					setAlert({
						variant: 'danger',
						message: response.message
					});
				}
				setContactSubmit(false);
			} catch (error) {
				setContactSubmit(false);
				setAlert({
					variant: 'danger',
					message: error
				});
				console.log(error);
			}

			setTimeout(function () {
				setAlert(false);
			}, 2000);
		},
		[spaceData, isContactSubmit, alert]
	);

	const sendEmail = async (values) => {
		try {
			const result = await sendEmailSpaceContact(values);
			if (result && result.result) {
				contactForm.resetFields();
				setAlert({
					variant: 'success',
					message: result.message
				});
			} else {
				setAlert({
					variant: 'danger',
					message: result.message
				});
			}
			setContactSubmit(false);
		} catch (error) {
			setContactSubmit(false);
			setAlert({
				variant: 'danger',
				message: error
			});
			console.log(error);
		}
	};

	return (
		<>
			<section id="contact" className={'p-3'}>
				<h3 className="leading-tight header-large">Contact</h3>

				<Form
					form={contactForm}
					name={'contactForm'}
					id={'contactForm'}
					validateMessages={VALIDATE_MESSAGES}
					onFinish={handleContactSubmit}
					className={''}
				>
					<div className={'grid grid-cols-12 gap-2 md:gap-8 '}>
						<div className={'col-span-12 md:col-span-6'}>
							<div className={'input-group-com input-flex-label mt-8 h-[40px]'}>
								<label className={'body-text--small'}>Name*</label>
								<Form.Item
									name={'name'}
									onFocus={hideTooltip}
									onBlur={showTooltip}
									rules={[{ required: true, type: 'string' }]}
								>
									<Input className={'input-md'} disabled={isContactSubmit} />
								</Form.Item>
							</div>
						</div>

						<div className={'col-span-12 md:col-span-6'}>
							<div className={'input-group-com input-flex-label mt-8 h-[40px]'}>
								<label className={'body-text--small'}>Email*</label>
								<Form.Item
									name={'email'}
									onFocus={hideTooltip}
									onBlur={showTooltip}
									rules={[{ required: true, type: 'email' }]}
								>
									<Input className={'input-md'} disabled={isContactSubmit} />
								</Form.Item>
							</div>
						</div>
					</div>

					<div className={'grid grid-cols-12 gap-2 md:gap-8 '}>
						<div className={'col-span-12'}>
							<div className={'input-group-com input-flex-label mt-8 h-[40px]'}>
								<label className={'body-text--small'}>Subject*</label>
								<Form.Item
									name={'subject'}
									onFocus={hideTooltip}
									onBlur={showTooltip}
									rules={[{ required: true, type: 'string' }]}
								>
									<Input className={'input-md '} disabled={isContactSubmit} />
								</Form.Item>
							</div>
						</div>
					</div>

					<div className={'grid grid-cols-12 gap-2 md:gap-8'}>
						<div className={'col-span-12'}>
							<div className={'input-group-com input-flex-label mt-8 h-[40px]'}>
								<label className={'body-text--small'}>Message*</label>
								<Form.Item
									name={'message'}
									onFocus={hideTooltip}
									onBlur={showTooltip}
									rules={[{ required: true, type: 'string' }]}
								>
									<Input className={'input-md'} disabled={isContactSubmit} />
								</Form.Item>
							</div>
						</div>
					</div>
					<div className={'grid grid-cols-12 gap-2 md:gap-8 mt-8'}>
						<div className={'col-span-12'}>
							{alert && (
								<div className="mb-1">
									<Alert
										key={alert.variant}
										variant={alert.variant}
										className={
											'custom-alert ' +
											(alert.variant === 'danger'
												? 'custom-alert-danger'
												: 'custom-alert-success')
										}
									>
										{alert.message}
									</Alert>
								</div>
							)}

							<Form.Item>
								<Button
									className={'btn btn-secondary btn-lg'}
									htmlType={'submit'}
									disabled={isContactSubmit}
								>
									{isContactSubmit && (
										<i className={'fa-solid fa-spinner fa-spin'} />
									)}
									Submit
								</Button>
							</Form.Item>
						</div>
					</div>
				</Form>
			</section>
		</>
	);
};

export default memo(SpaceContact);
