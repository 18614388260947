import { memo, useEffect, useCallback, useState, useRef } from 'react';
import { Form, Input, Checkbox, Button, InputNumber } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormFieldStacked from '../../../components/commons/FormFieldStacked';

import Alert from 'react-bootstrap/Alert';

import {
	VALIDATE_MESSAGES,
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	NODE_ENV,
	MAGIC_CONNECT_NETWORK
} from '../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE
} from '../../../constants/file';
import {
	checkMembershipShortCodeAvailability,
	createSpaceAddMembership
} from '../../../services/space';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { getListIcon } from '../../../utils/list';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	MEMBERSHIP_TYPES
} from '../../../constants/space';
import routes from '../../../routes';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import UploadDragger from '../../../components/commons/UploadDragger';

import './../SpaceCreate.scss';
import TextareaAutosize from 'react-textarea-autosize';
import ExplicitContent from '../../../components/commons/Modals/ExplicitContent';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import { CustomValidators } from '../../../utils/CustomValidators';
import { CurrencyType } from '../../../utils/MembershipItemUtils';

const SpaceCreateCommunity = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createSpaceAddMembershipForm] = Form.useForm();
	const [formIsOnFocusMembership, setFormIsOnFocusMembership] = useState(false);
	const [formHasErrorMembership, setFormHasErrorMembership] = useState(true);
	const [alert, setAlert] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [membershipID, setMembershipID] = useState(null);
	const [membershipName, setMembershipName] = useState(null);
	const [membershipListingText, setMembershipListingText] = useState(null);
	const [membershipSummary, setMembershipSummary] = useState(null);
	const [membershipSKUShortcode, setMembershipSKUShortcode] = useState(null);
	const [membershipMaxPerAccount, setMembershipMaxPerAccount] = useState(null);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [numericCount, setNumericCount] = useState(false);
	const [enableGiftAccounts, setEnableGiftAccounts] = useState(false);
	const [membershipImageError, setMembershipImageError] = useState(null);
	const [membershipImage, setMembershipImage] = useState(null);
	const [membershipImageBlobPreview, setMembershipImageBlobPreview] = useState(null);
	const [membershipVideo, setMembershipVideo] = useState(null);
	const [membershipVideoBlobPreview, setMembershipVideoBlobPreview] = useState(null);
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const membershipNameRef = useRef(null);
	const membershipListingTextRef = useRef(null);
	const membershipSKUShortcodeRef = useRef(null);
	const membershipMaxPerAccountRef = useRef(null);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);
	const [membershipType, setMembershipType] = useState(null);

	const handleFormMembershipChanges = useCallback(async () => {
		setMembershipName(createSpaceAddMembershipForm.getFieldValue('name'));
		setMembershipListingText(createSpaceAddMembershipForm.getFieldValue('listing_text'));
		setMembershipSummary(createSpaceAddMembershipForm.getFieldValue('summary'));
		setMembershipSKUShortcode(createSpaceAddMembershipForm.getFieldValue('sku_shortcode'));
		setMembershipMaxPerAccount(createSpaceAddMembershipForm.getFieldValue('max_per_account'));
	}, []);

	const handleMembershipFormOnBlur = useCallback(async () => {
		await createSpaceAddMembershipForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				setFormHasErrorMembership(true);
			});
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setDisplayDropDownError(true);
			setMembershipImageError(null);

			let hasError = false;

			if (selectedContentType === 'default' || selectedAccessType === 'default') {
				hasError = true;
			}

			if (!membershipImageBlobPreview) {
				setMembershipImageError('Membership Image is required.');
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = values;
			data.currency = 'USD';
			data.type = MEMBERSHIP_TYPES.COMMUNITY;
			data.expiration_subscription_enabled = enableExpirationChecked;
			data.enable_add_numeric_count = numericCount;
			data.enable_gifting = enableGiftAccounts;
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			// if (data.royalty_percentage.includes('%')) {
			// 	data.royalty_percentage = data.royalty_percentage.replace('%','');
			// }

			if (membershipImage) {
				data.membership_image = membershipImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (membershipVideo) {
				data.membership_video = membershipVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					if (membershipID) {
						data.membership_id = membershipID;
					}

					setSubmitted(true);
					const response = await createSpaceAddMembership(data);
					if (response && response.result) {
						dispatch(getSpaceInfo(space_username));
						const url = routes.createSpaceAddMembershipTraitsPage();
						navigate(url.replace(':space_username', space_username));
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
					}
					setSubmitted(false);
					setDisplayDropDownError(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			space_username,
			authUserInfo,
			spaceInfo,
			enableExpirationChecked,
			membershipID,
			membershipImage,
			membershipVideo,
			selectedContentType,
			selectedAccessType,
			photoIsRemoved,
			videoIsRemoved,
			selectedExplicitAccess,
			selectedExplicitContent,
			membershipImageBlobPreview
		]
	);

	const saveAndGoBack = useCallback(async () => {
		setMembershipImageError(null);

		let hasError = false;

		if (selectedContentType === 'default' || selectedAccessType === 'default') {
			hasError = true;
		}

		if (!membershipImageBlobPreview) {
			setMembershipImageError('Membership Image is required.');
			hasError = true;
		}

		if (hasError) {
			window.scrollTo(0, 0);
			return;
		}

		const data = {};
		data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
		data.name = membershipName;
		data.listing_text = membershipListingText;
		data.summary = membershipSummary;
		data.sku_shortcode = membershipSKUShortcode;
		data.max_per_account = membershipMaxPerAccount;
		data.expiration_subscription_enabled = enableExpirationChecked;
		data.content_type = selectedContentType;
		data.content_access = selectedAccessType;

		if (data.explicit_content) {
			setSelectedExplicitContent(JSON.parse(data.explicit_content));
		}
		if (data.explicit_access) {
			setSelectedExplicitAccess(JSON.parse(data.explicit_access));
		}

		if (membershipImage) {
			data.membership_image = membershipImage;
		} else {
			if (photoIsRemoved) {
				data.photo_is_empty = true;
			}
		}

		if (membershipVideo) {
			data.membership_video = membershipVideo;
		} else {
			if (videoIsRemoved) {
				data.video_is_empty = true;
			}
		}

		try {
			if (authUserInfo) {
				data.user_id = authUserInfo.id;
				data.space_id = spaceInfo.id;

				if (membershipID) {
					data.membership_id = membershipID;
				}

				setAlert(null);
				setDisplayDropDownError(true);
				setSubmitted(true);
				const response = await createSpaceAddMembership(data);
				if (response && response.result) {
					dispatch(getSpaceInfo(space_username));
					const url = routes.createSpaceAddDetailsPage();
					navigate(url.replace(':space_username', space_username));
				} else {
					setAlert({
						variant: 'danger',
						message: response.message
					});
					window.scrollTo(0, 0);
				}
				setSubmitted(false);
				setDisplayDropDownError(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [
		space_username,
		spaceInfo,
		authUserInfo,
		membershipID,
		membershipName,
		membershipListingText,
		membershipSummary,
		membershipSKUShortcode,
		membershipMaxPerAccount,
		enableExpirationChecked,
		membershipImage,
		membershipVideo,
		selectedContentType,
		selectedAccessType,
		photoIsRemoved,
		videoIsRemoved,
		selectedExplicitAccess,
		selectedExplicitContent,
		membershipImageBlobPreview
	]);

	const handleMembershipImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setMembershipImage(file);
		setMembershipImageBlobPreview(blobPreview);
	};

	const handleMembershipVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
		}

		setMembershipVideo(file);
		setMembershipVideoBlobPreview(blobPreview);
	};

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};

	const onChangeNumericCount = (e) => {
		setNumericCount(e.target.checked);
	};

	const onChangeEnableGiftAccounts = (e) => {
		setEnableGiftAccounts(e.target.checked);
	};

	const setFormSpaceData = useCallback(
		async (createdSpace) => {
			const spaceMemberships = createdSpace.memberships;
			if (createdSpace && spaceMemberships && spaceMemberships.length > 0) {
				const firstMembership = spaceMemberships[0];
				setMembershipID(firstMembership.id);
				setMembershipType(firstMembership.type);
				createSpaceAddMembershipForm.setFieldsValue({
					name: firstMembership.name,
					listing_text: firstMembership.listing_text,
					summary: firstMembership.summary,
					sku_shortcode: firstMembership.sku_shortcode,
					individual_title: firstMembership.individual_title,
					description: firstMembership.description,
					// price: firstMembership.benefits.length > 0 ? firstMembership.benefits[0].price : null,
					price: firstMembership.price,
					max_supply: firstMembership.max_supply,
					max_per_account: firstMembership.max_per_account,
					royalty_percentage: firstMembership.royalty_percentage
				});
				setMembershipName(firstMembership.name);
				setMembershipListingText(
					firstMembership.listing_text ? firstMembership.listing_text : ''
				);
				setMembershipSummary(firstMembership.summary ? firstMembership.summary : '');
				setMembershipSKUShortcode(firstMembership.sku_shortcode);
				setMembershipMaxPerAccount(firstMembership.max_per_account);
				setExpirationChecked(firstMembership.enable_expiration_and_subscription);
				setNumericCount(firstMembership.enable_add_numeric_count);

				setSelectedExplicitContent(JSON.parse(firstMembership.explicit_content));
				setSelectedExplicitAccess(JSON.parse(firstMembership.explicit_access));

				if (firstMembership.photo) {
					if (!photoIsRemoved) {
						setMembershipImageBlobPreview(firstMembership.photo);
					}
				}
				if (firstMembership.video) {
					if (!videoIsRemoved) {
						setMembershipVideoBlobPreview(firstMembership.video);
					}
				}

				await createSpaceAddMembershipForm
					.validateFields()
					.then(async () => {
						setFormHasErrorMembership(false);
					})
					.catch((errors) => {
						setFormHasErrorMembership(true);
					});
			}
		},
		[photoIsRemoved, videoIsRemoved]
	);

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	const gotoAddNftMembership = useCallback(async () => {
		if (membershipType && membershipType === 'community') {
			return;
		}

		dispatch(getSpaceInfo(space_username));
		const url = routes.createSpaceAddMembershipPage();
		navigate(url.replace(':space_username', space_username));
	}, [membershipType]);

	useEffect(() => {
		if (membershipType && membershipType === 'nft_collection') {
			gotoAddNftMembership().then((r) => {});
		}
	}, [membershipType]);

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		membershipID,
		membershipImageError
	]);

	return (
		<>
			<main className="dashboard-wrapper flex add-membership">
				<div className="container !py-20">
					<div className="grid grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12 md:col-span-6 flex items-center">
							<h1 className="mb-4 mt-8 header-large">Create Your First Membership</h1>
						</div>
						<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">
							<p className="body-txtitalic--large mt-8">Step 3 of 5</p>
						</div>
						<div className="col-span-12">
							<h5 className="body-text--reg">
								{/*<a className="gradient-color-txt">Memberships</a>*/}
								{getSitewideLink('memberships_create_space_3')} are how people join
								your Space and become a{' '}
								{/*<a className="gradient-color-txt">Member</a>*/}
								{getSitewideLink('member_create_space_3')}. They can be free or
								paid, and determine which access and benefits a Member gets. You can
								create more than one to offer free, paid, and premium memberships
								which anyone can get with an email and payment method. Members can
								own multiple Memberships, but only 1 can be active at a time. Create
								your first Membership here!
							</h5>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4">
						<div className="md:col-span-8 flex justify-between !order-last md:!order-first">
							<h5 className="header-medium leading-10">Choose a Membership Type</h5>
							<span className="body-text--reg">Required</span>
						</div>
						<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">
							{/*<button type="submit" className="btn btn-lg btn-secondary">*/}
							{/*	View Guide*/}
							{/*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
							{/*</button>*/}
							{getSitewideLink('view_guide_community', 'btn btn-lg btn-secondary')}
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-4 flex my-auto !order-2 md:!order-1">
							<div className="selectable-cards-selected">
								<div className="cards-bg">
									<p className="text-center">
										<i className="gradient-color-txt fa-light fa-cubes"></i>
									</p>
									<h5 className="gradient-color-txt text-center">
										Community Membership
									</h5>
									<p className="body-text--smallest">
										Create a free or paid membership that can be sold for a
										one-time cost or have a monthly renewable price, be setup as
										Member-bound or tradable, and have many other configurable
										options.
									</p>
									<p className="body-txtitalic--smallest text-center">
										Created with Polygon
									</p>
								</div>
							</div>
						</div>
						<div className="md:col-span-4 flex my-auto !order-3 md:!order-2">
							<div
								className={
									'selectable-cards-not-selected ' +
									(membershipType && membershipType === 'community'
										? 'disabled'
										: '')
								}
								onClick={gotoAddNftMembership}
							>
								<div className="cards-bg">
									<p className="text-center">
										<i className="gradient-color-txt fa-brands fa-ethereum"></i>
									</p>
									<h5 className="gradient-color-txt text-center">
										NFT Collection
									</h5>
									<p className="body-text--smallest">
										You can use an existing NFT Collection on Ethereum to create
										a Membership. Any holder will be able to connect their
										wallet and claim a Membership for free. To use an NFT, you
										must authenticate the Creator Wallet. Learn more.
									</p>
									<p className="body-txtitalic--smallest text-center">
										Ethereum Blockchain
									</p>
								</div>
							</div>
						</div>
						<div className="md:col-span-4 flex my-auto !order-1 md:!order-3">
							<p className="body-txtitalic--smallest color-reg">
								Memberships allow anyone to join your Space and controls their
								access to Updates, Benefits, Members Areas, and any Apps you
								integrate with, such as Shopify, Discord, Zoom, and more. You can
								also use an existing NFT collection that you’ve created as the basis
								for a Membership.
								<br />
								<br />
								There are additional Membership Types with different features which
								can be added after you have launched your Space.{' '}
								{/*<a className="gradient-color-txt"> Learn more about Memberships.</a>*/}
								{getSitewideLink('learn_more_memberships')}
							</p>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 my-auto flex justify-between !order-last md:!order-first">
							<h5 className="header-medium leading-10">Add Membership Details</h5>
							<span className="body-text--reg">Community Type Selected</span>
						</div>
						<div className="md:col-span-4 my-auto !order-first md:!order-last">
							<p className="body-txtitalic--smallest color-reg">
								You can change Membership Details as needed, however most changes
								will only be applied to new sales of the Membership.
							</p>
						</div>
					</div>
					<Form
						form={createSpaceAddMembershipForm}
						name="createSpaceAddMembershipForm"
						id="createSpaceAddMembershipForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusMembership(true);
						}}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocusMembership(false);
								handleMembershipFormOnBlur();
							}, 200);
						}}
						onChange={handleFormMembershipChanges}
						onFinish={handleSubmit}
						className={formIsOnFocusMembership ? 'hide-antd-error-messages' : 'antd-error-messages-15'}
					>
						<>
							{alert && (
								<div className="grid grid-cols-12">
									<div className="col-span-8">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								</div>
							)}
						</>
						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Name*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Name"
										name="name"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										
											<FormFieldStacked
											formType='input'
											type="text"											
											placeholder="Up to 32 characters, including spaces."
											disabled={submitted}
											ref={membershipNameRef}></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 flex my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									This is the displayed Name of the Membership and is what people
									will see when browsing your Space and viewing their purchased
									Membership, as well as the Collection Name when viewing the artwork.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Listing Text*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Listing Text"
										name="listing_text"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										
										<FormFieldStacked
											formType='input'
											type="text"											
											placeholder="Up to 128 characters, including spaces."
											disabled={submitted}
											ref={membershipListingTextRef}></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									The Listing Text is used when listing your Membership on your
									Space for Holders to claim. It should be 1 to 2 sentences
									summarizing the Membership and why people should join.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Summary*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Summary"
										name="summary"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										
										<FormFieldStacked
											formType='textarea'
											type="text"	
											maxLength={128}										
											placeholder="Up to 128 characters, including spaces."
											disabled={submitted}
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									This Summary shows when Members view their Membership. It should
									be 1 to 2 sentences summarizing the Membership and its details.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<	div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									SKU Shortcode*
								</label>
								<div className="relative rounded-none ">
									<Form.Item
										label="SKU Shortcode"
										name="sku_shortcode"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												min: 2,
												message: 'Must be minimum 2 characters.'
											},
											{
												max: 8,
												message: 'Must not exceed 8 characters.'
											},
											({ getFieldValue }) => ({
												async validator(_, value) {
													if (value) {
														const data = {
															shortcode: value
														};

														if (spaceInfo) {
															data['space_id'] = spaceInfo.id;
														}

														if (membershipID) {
															data['membership_id'] = membershipID;
														}

														let available = false;
														const response =
															await checkMembershipShortCodeAvailability(
																data
															);
														if (response && response.result) {
															available = response.availability;
														}

														if (!available) {
															return Promise.reject(
																new Error(
																	'Shortcode not available.'
																)
															);
														}
													}

													if (
														(value && value.match(/\ /g)) ||
														/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
															value
														)
													) {
														return Promise.reject(
															new Error('Invalid Input')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
										
											<FormFieldStacked
											formType='input'
											type="text"	
											placeholder="2 to 8 characters. Letters and numbers only, no spaces."
											disabled={submitted}
											ref={membershipSKUShortcodeRef}
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Choose a unique SKU Shortcode based the Membership Name. This is
									must be unique within your Space and is used in transactions,
									support, and other areas.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Individual Title*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Individual Title"
										name="individual_title"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										
											<FormFieldStacked
											formType='input'
											type="text"	
											placeholder="Up to 32 characters, including spaces. Usually this is the same or similar to the Name."
											disabled={submitted}
											ref={membershipNameRef}
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									This is the Title given to an individual Membership when
									purchased by someone. For example, if your Membership Name is
									“Club All Access Membership” then you could make this “Club
									Membership”.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<div className="form-check flex items-center">
									<Checkbox
											checked={numericCount}
											className="inline-flex form-check-input items-center mr-8 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={onChangeNumericCount}
									></Checkbox>
									<label className="form-check-label body-text--small gradient-color-txt">
										
										Add Numeric Count to the Individual Title of each purchased
										Membership
									</label>
								</div>
							</div>
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									This adds a numeric count to the Individual Title of each
									Membership sold. For example, the first Membership sold would
									have the Title of “Club Membership #1”, and the second would be
									“Club Membership #2”, and so on.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Description*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Description"
										name="description"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 512,
												message: 'Must not exceed 512 characters.'
											}
										]}
									>
										
										<FormFieldStacked
											formType='textarea'
											type="text"	
											maxLength={512}
											placeholder="Up to 512 characters, including spaces."
											disabled={submitted}
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									This is a full Description of your Membership. You can use{' '}
									{getSitewideLink('markdown_syntax')} to add basic formatting.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:hidden md:col-span-4 my-auto">
								<p className="body-txtitalic--smallest color-reg">
									Your Membership Image (up to 8MB) should be as high resolution
									as possible, up to 3840px height and width. This will also be
									used for any thumbnails and previews.
								</p>
							</div>
							<div className="md:col-span-4 my-auto">
								<div className="upload-rec no-bg">
									<UploadDragger
										passedError={membershipImageError}
										cropperAspectRatio={4 / 3}
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={IMAGE_FILETYPES.toString()}
										uploadedFilePreview={membershipImageBlobPreview}
										onUploadFileChange={handleMembershipImageUploadFileChange}
										minHeight={IMAGE_LARGE_MIN_HEIGHT}
										minWidth={IMAGE_LARGE_MIN_WIDTH}
										maxHeight={IMAGE_LARGE_MAX_HEIGHT}
										maxWidth={IMAGE_LARGE_MAX_WIDTH}
										uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
										disableCropper={true}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Membership Image*
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 3840px, square preferred. JPEG, PNG, SVG, or GIF
								</p>
							</div>
							<div className="md:hidden md:col-span-4 my-auto">
								<p className="mt-8 body-txtitalic--smallest color-reg">
									You can add a Membership Video (up to 12MB). This will be
									viewable by Members when viewing their purchased Membership.
									Optional.
								</p>
							</div>
							<div className="md:col-span-4 my-auto">
								<div className="upload-rec no-bg">
									<UploadDragger
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={VIDEO_FILETYPES.toString()}
										uploadedFilePreview={membershipVideoBlobPreview}
										onUploadFileChange={handleMembershipVideoUploadFileChange}
										minHeight={VIDEO_STANDARD_MIN_HEIGHT}
										minWidth={VIDEO_STANDARD_MIN_WIDTH}
										maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
										maxWidth={VIDEO_STANDARD_MAX_WIDTH}
										uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Membership Video
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 1920px/1080p, square preferred. MP4 or WEBM.
								</p>
							</div>
							<div className="hidden md:block md:col-span-4 my-auto">
								<p className="body-txtitalic--smallest color-reg">
									Your Membership Image (up to 8MB) should be as high resolution
									as possible, up to 3840px height and width. This will also be
									used for any thumbnails and previews.
								</p>
								<p className="mt-8 body-txtitalic--smallest color-reg">
									You can add a Membership Video (up to 12MB). This will be
									viewable by Members when viewing their purchased Membership.
									Optional.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Currency*
								</label>
								<div className="relative rounded-none ">
						
									<input
										type="text"
										className="block h-10 bg-[#ffffff80] w-full rounded-none border-0 py-1.5 px-3 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
										value="USD"
										disabled
										/>
										
								</div>
								
							</div>	
							
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Set the Currency for your Membership Price. Any purchases in a
									different currency will be converted based on market pricing at
									the time of purchase.{' '}
									{getSitewideLink('community_see_official_currency')}.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Price*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Price"
										name="price"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												validator: CustomValidators.priceMinmax(
													CurrencyType.USD
												)
											}
										]}
									>
										
											<FormFieldStacked
											formType='input'
											type="number"	
											placeholder="Enter “0” if free. "
											disabled={submitted}
											ref={membershipNameRef}
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									You can offer your Membership for free or sell it at any price
									you choose. Denominated in your selected Currency. Enter “0” to
									set the Price as free.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<div className="form-check  flex items-center">
									<Checkbox
											checked={enableExpirationChecked}
											className="inline-flex form-check-input items-center mr-8 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={onChangeEnableExpiration}
										></Checkbox>
									<label className="form-check-label body-text--small gradient-color-txt">
										
										Enable Expiration Date and add Monthly Subscription for
										continued access
									</label>
								</div>
							</div>
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Memberships have a one-time upfront Price. This option adds an
									Expiration after a set number of months. After that, Members
									must pay a Monthly Subscription to continue access. Details are
									set up in the Add Benefits step.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
								Max Supply*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Max Supply"
										name="max_supply"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (typeof value !== 'string' && value) {
														value = value.toString();
													}
													if (
														isNaN(value) ||
														value < 0 ||
														(value && value.match(/\./g))
													) {
														return Promise.reject(
															new Error('Invalid value.')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
											<FormFieldStacked
											formType='input'
											type="number"	
											placeholder="Enter “0” for unlimited."
											disabled={submitted}
											></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>								
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Your Membership can have a limited or unlimited Supply. Enter
									“0” to set the Supply to unlimited, “1” to make it a unique
									1-of-1, or any larger number to set a higher limit.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Max Per Account*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Max Per Account"
										name="max_per_account"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{ validator: CustomValidators.maxPerCount }
										]}
									>
										
										<FormFieldStacked
										formType='input'
										type="number"	
										placeholder="Enter “0” for unlimited."
										disabled={submitted}
										ref={membershipMaxPerAccountRef}
										></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
							
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									You can put a limit how many Memberships can be purchased by a
									single account. Enter “0” to set this to unlimited.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<div className="form-check  flex items-center">
									<Checkbox
											checked={enableGiftAccounts}
											className="inline-flex form-check-input items-center mr-8 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={onChangeEnableGiftAccounts}
									></Checkbox>
									<label className="form-check-label body-text--small gradient-color-txt">
										
										Enable gifting to accounts and external transfers on
										marketplaces
									</label>
								</div>
							</div>
							<div className="md:col-span-4 my-auto !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Enabling gifting and transfers allows Members to gift a
									purchased Membership to another account. Free gifting is
									supported within your Space. External transfers and sales can be
									done on marketplaces like OpenSea.{' '}
									{getSitewideLink('learn_more_enable_gifting')}.
								</p>
							</div>
						</div>

						{enableGiftAccounts && (
							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Royalty Percentage
								</label>
								<div className="relative rounded-none ">
									<Form.Item
											label="Royalty Percentage"
											name="royalty_percentage"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{ validator: CustomValidators.royaltyPercentage }
											]}
										>
										
										<FormFieldStacked
										formType='input'
										type="text"	
										placeholder="Whole numbers only, 0% to 20%."
										disabled={submitted}
										></FormFieldStacked>
									</Form.Item>
								</div>
								
							</div>	
								
								<div className="md:col-span-4 my-auto !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										With Gifting and Transfers enabled, when a Membership is sold on
										an external marketplace, you can specify that a percentage of
										the sale price be paid to you as a royalty.{' '}
										{getSitewideLink('learn_more_royalty_percentage')}.
									</p>
								</div>
							</div>
						)}

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-4 my-auto !order-2 md:!order-1">
								<DropdownWithItems
									title="Select Content Type"
									customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"
									displayError={
										displayDropDownError && selectedContentType === 'default'
									}
									icon={getListIcon(
										CONTENT_TYPES_LIST_WITH_DEFAULT,
										selectedContentType
									)}
									selectedValue={selectedContentType}
									listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openContentTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitContent}
									setModalOpen={setModalOpenExplicitContent}
									selectedItems={selectedExplicitContent}
									isExplicitContent={true}
									onSelectedItems={(value) => setSelectedExplicitContent(value)}
								/>
							</div>
							<div className="md:col-span-4 my-auto !order-3 md:!order-2">
								<DropdownWithItems
									title="Select Access Type"
									customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"
									displayError={
										displayDropDownError && selectedAccessType === 'default'
									}
									icon={getListIcon(
										ACCESS_TYPES_LIST_WITH_DEFAULT,
										selectedAccessType
									)}
									selectedValue={selectedAccessType}
									listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openAccessTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitAccess}
									setModalOpen={setModalOpenExplicitAccess}
									selectedItems={selectedExplicitAccess}
									isExplicitContent={false}
									onSelectedItems={(value) => setSelectedExplicitAccess(value)}
								/>
							</div>

							<div className="md:col-span-4 my-auto !order-1 md:!order-3">
								<p className="body-txtitalic--smallest color-reg">
									Change the Content setting if your Details, Image, or Video
									contains explicit or sensitive content. Change the Access
									setting if your Space will provide access to explicit or
									sensitive content.{' '}
									{getSitewideLink('view_content_guideline_memberships')}.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8">
								<Form.Item>
									<Button
										className="btn btn-lg btn-primary whitespace-pre-wrap mb-0"
										htmlType="submit"
										disabled={submitted || formHasErrorMembership}
									>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										Save Membership & Continue
									</Button>
								</Form.Item>
							</div>
							<div className="md:col-span-4">
								<Button
									className="btn btn-lg btn-warning bg-none"
									disabled={submitted || formHasErrorMembership}
									onClick={saveAndGoBack}
								>
									Save & Go Back
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</main>
		</>
	);
};

export default memo(SpaceCreateCommunity);
