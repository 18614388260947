import { memo, useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Form, Input} from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {getSitewideLink} from "../../../utils/sitewideLinks";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import Alert from "react-bootstrap/Alert";
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {SPACE_PAYOUT_METHOD} from "../../../constants/space";
import {
	createStripeAccount,
	createStripePayoutAccount
} from "../../../services/space";
import AddStripeBankAccountModal
	from "../../../components/commons/Modals/AddStripeBankAccountModal/AddStripeBankAccountModal";

const AddPaymentMethod = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [addPayoutMethodForm] = Form.useForm();
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [alert, setAlert] = useState(null);
	const [selectedPayoutMethod, setSelectedPayoutMethod] = useState('default');
	const [submitted, setSubmitted] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [isSetupStripe, setIsSetupStripe] = useState(false);
	const [showStripeModal, setShowStripeModal] = useState(false);
	const [isOnBoardingSubmitted, setIsOnBoardingSubmitted] = useState(false);
	const [isUserHaveStripeAccount, setIsUserHaveStripeAccount] = useState(false);
	const [isUserHaveStripePayoutAccount, setIsUserHaveStripePayoutAccount] = useState(false);

	const saveContinue = () => {
		const url = routes.createSpaceBilling();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createSpaceAddPages();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	const handleFormChanges = useCallback(async () => {
		await addPayoutMethodForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await addPayoutMethodForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const setUpStripe = useCallback(
		async () => {
			setAlert(null);
			setSubmitted(false);
			setIsUserHaveStripeAccount(false);
			setIsUserHaveStripePayoutAccount(false);

			try {
				if (authUserInfo) {
					const data = {
						user_id: authUserInfo.id,
						space_id: spaceInfo.id
					};

					setSubmitted(true);
					const responseCreateStripeAccount = await createStripeAccount(data);
					if (responseCreateStripeAccount && responseCreateStripeAccount.result) {
						setIsUserHaveStripeAccount(true);
					} else {
						if (responseCreateStripeAccount.message === "User already created a payment gateway account.")
							setIsUserHaveStripeAccount(true);
					}
					const responseCreateStripePayoutAccount = await createStripePayoutAccount(data);
					if (responseCreateStripePayoutAccount && responseCreateStripePayoutAccount.result) {
						setIsUserHaveStripePayoutAccount(true);
					} else {
						if (responseCreateStripePayoutAccount.message === "User already created a payment gateway payout account.")
							setIsUserHaveStripePayoutAccount(true);
					}
				}
				setSubmitted(false);
			} catch (error) {
				setAlert({ variant: 'danger', message: error.message });
				setSubmitted(false);
			}
		},
		[authUserInfo, spaceInfo]
	);

	const handleSubmit = useCallback(
		async () => {
			// setAlert(null);
			// setSubmitted(false);
			//
			// try {
			// 	if (authUserInfo) {
			// 		const data = {
			// 			user_id: authUserInfo.id
			// 		};
			//
			// 		setSubmitted(true);
			// 		const responseCreateStripeAccount = await createStripeAccount(data);
			// 		if (responseCreateStripeAccount && responseCreateStripeAccount.result) {
			// 			console.log(responseCreateStripeAccount);
			// 			const responseCreateStripePayoutAccount = await createStripePayoutAccount(data);
			// 			if (responseCreateStripePayoutAccount && responseCreateStripePayoutAccount.result) {
			// 				console.log(responseCreateStripePayoutAccount);
			// 			}
			// 		}
			// 	}
			// 	setSubmitted(false);
			// } catch (error) {
			// 	setAlert({ variant: 'danger', message: error.message });
			// 	setSubmitted(false);
			// }
		},
		[authUserInfo]
	);

	useEffect(() => {
		if (isUserHaveStripeAccount && isUserHaveStripePayoutAccount) {
			//setIsSetupStripe(true);
			setShowStripeModal(true);
		}
	}, [isUserHaveStripeAccount, isUserHaveStripePayoutAccount]);

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main className="dashboard-wrapper flex">
				<div className="container !py-20">
					<div className="grid grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
						<div className="col-span-12 md:col-span-8 flex items-center">
							<h1 className="header-large !text-5xl !font-bold">Set up Payout Methods</h1>
						</div>
						<div className="col-span-12 md:col-span-4 flex items-center justify-center md:justify-end">
							<p className="body-txtitalic--large">Step 3 of 5</p>
						</div>
						<div className="col-span-12 mt-4">
							<h5 className="body-text--reg">
								To begin selling Paid Memberships and Items, accept credit card
								and/or crypto payments, and receive Payouts you will need to setup
								your Payout Methods. You can choose to enable different payment
								methods and currencies using different services.{' '}
								{/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
								{/*	Learn more about Payment and Payout Methods.*/}
								{/*</Link>*/}
								{getSitewideLink('learn_more_payment_payout_method', 'gradient-color-txt body-txtitalic--reg')}
								{' '}
							</h5>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8">
						<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
							<h5 className="header-medium leading-10 !font-bold">Add Payout Method</h5>
							<span className="body-text--reg !italic">Optional</span>
						</div>
						<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">
							<button type="submit" className="btn btn-lg btn-secondary">
								Space Creation Guide
								<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
							</button>
							{/*{getSitewideLink(*/}
							{/*	'space_creation_guide',*/}
							{/*	'btn btn-lg btn-secondary'*/}
							{/*)}*/}
						</div>
					</div>
					<Form
						form={addPayoutMethodForm}
						name="addPayoutMethodForm"
						id="addPayoutMethodForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocus(false);
								handleFormOnBlur();
							}, 100);
						}}
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<>
							{alert && (
								<div className="grid grid-cols-12 gap-8 mt-5">
									<div className="col-span-8">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								</div>
							)}
						</>

						<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
							<div className="md:col-span-8 !order-last md:!order-first">
								<DropdownWithItems
									title="Payout Method"
									dropDownClass=""
									customButtonClass="payout"
									selectedValue={selectedPayoutMethod}
									listItems={SPACE_PAYOUT_METHOD}
									onDropdownChange={(value) => {
										setSelectedPayoutMethod(value);
										setIsSetupStripe(false);
										setIsOnBoardingSubmitted(false);
									}}
								/>
							</div>
							<div className="md:col-span-4 !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg m-0">
									All On-Platform Sales transacted through your Space will be processed and paid out to you via your Payout Methods. The timing, terms, and costs may vary based on the type and provider.
								</p>
							</div>
						</div>

						{(selectedPayoutMethod === 'stripe' && !isOnBoardingSubmitted) && (
							<>
								<div className="grid gap-y-4 mt-8">
									<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
										<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
											<h5 className="header-medium leading-10 !text-2xl !font-bold">
												<div className="flex items-center gap-4">
													<i className="fa-brands fa-cc-stripe gradient-color-txt text-[40px]"/>
													<div>
														Payout Method
													</div>
												</div>
											</h5>
											<span className="body-text--reg !text-lg">Add Payouts to your Bank Account via Stripe</span>
										</div>
										<div className="md:col-span-4 !order-first md:!order-last flex items-center">
											<div className="gradient-color-txt text-sm italic ">
												Learn more about On-Platform Sales.
											</div>
										</div>
									</div>
									<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
										<div className="md:col-span-8 !order-last md:!order-first">
											<span className="font-notoSansRegular text-base">Enable credit card payments for all On-Platform Sales on your Space via Stripe with Payouts automatically deposited to your Bank Account. Stripe is a trusted global leader in secure payment processing used by millions of companies.</span>
										</div>
										<div className="md:col-span-4 !order-first md:!order-last flex items-center">
											<div className="font-notoSansRegular text-sm italic ">
												Your total cost per transaction will be Stripe’s fee of 2.9% + 30¢ for each credit card transaction processed plus your Commonspace Plan’s On-Platform Sales Rate.
											</div>
										</div>
									</div>
									<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
										<div className="md:col-span-8">
											<Button
												onClick={()=>{
													setUpStripe().then(r => {});
												}}
												className="btn btn-lg btn-primary"
												htmlType="submit"
											>
												Set Up Stripe
											</Button>
										</div>
										<div className="md:col-span-4 flex items-end">
											<Button
												onClick={() => {
													setSelectedPayoutMethod('default');
												}}
												className="btn btn-lg btn-secondary"
												htmlType="submit"
											>
												Remove Method
											</Button>
										</div>
									</div>
								</div>
							</>
						)}
						{showStripeModal && (
							<AddStripeBankAccountModal
								modalOpen={showStripeModal}
								spaceID={spaceInfo.id}
								setModalOpen={setShowStripeModal}
								userID={authUserInfo.id}
								setIsOnBoardingSubmitted={setIsOnBoardingSubmitted}
							/>
						)}
						{isOnBoardingSubmitted && (
							<>
								<div className="grid gap-y-4 mt-8">
									<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
										<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
											<h5 className="header-medium leading-10 !text-2xl !font-bold">
												<div className="flex items-center gap-4">
													<i className="fa-brands fa-cc-stripe gradient-color-txt text-[40px]"/>
													<div>
														Payout Method
													</div>
												</div>
											</h5>
											<span className="body-text--reg !text-lg !italic">Active</span>
										</div>
										<div className="md:col-span-4 !order-first md:!order-last flex items-center">
											<button type="submit" className="btn btn-lg btn-secondary">
												View & Edit on Stripe
												<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
											</button>
										</div>
									</div>
									<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
										<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
											<h5 className="header-medium leading-10 !text-xl !font-bold">[NICKNAME] Bank Account Ending in 0939</h5>
											<span className="body-text--reg !text-sm">Added on 08/28</span>
										</div>
									</div>
								</div>
							</>
						)}
					</Form>
					<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8">
						<div className="md:col-span-8">
							<Button
								onClick={saveContinue}
								className={"btn btn-lg " + (isSetupStripe ? 'btn-primary' : 'btn-secondary')}
								htmlType="submit"
							>
								{isSetupStripe ? 'Save & Continue' : 'Skip'}
							</Button>
						</div>
						{/*<div className="md:col-span-4 flex items-end">*/}
						{/*	<Button*/}
						{/*		onClick={saveBack}*/}
						{/*		className="btn btn-lg btn-warning bg-none"*/}
						{/*		htmlType="submit"*/}
						{/*	>*/}
						{/*		Go Back*/}
						{/*	</Button>*/}
						{/*</div>*/}
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(AddPaymentMethod);
