import {memo, useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';

import { MAGIC_OAUTH_KEY, MAGIC_CONNECT_NETWORK } from '../../../constants/common';
import { PRONOUNS } from '../../../constants/profile';
import routes from '../../../routes';
import ProfileEdit from './ProfileEdit';
import ManageContact from './ManageContact';
import ManageVisibility from './ManageVisibility';
import EditAboutSection from './EditAboutSection';
import ManageFollowers from './ManageFollowers';
import { shortenNumber } from '../../../utils/number';
import { getListDisplay } from '../../../utils/list';
import { getBackgroundFileTypeFromUrl } from '../../../utils/file';

const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
	network: MAGIC_CONNECT_NETWORK,
	locale: 'en_US',
	extensions: [new OAuthExtension()]
});

const ProfileContent = (callback, deps) => {
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [selectedTab, setSelectedTab] = useState(routes.dashboard());
	const [profileBackgroundPhoto, setProfileBackgroundPhoto] = useState(null);
	const [profileBackgroundPhotoBlobPreview, setProfileBackgroundPhotoBlobPreview] =
		useState(null);
	const [profileBackgroundVideo, setProfileBackgroundVideo] = useState(null);
	const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] =
		useState(null);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [cardBackground, setCardBackground] = useState(null);
	const [cardBackgroundType, setCardBackgroundType] = useState(null);
	const navigate = useNavigate();

	const getPronounsDisplay = (value) => {
		const res = PRONOUNS.find((x) => x.value === value);
		return res.label;
	};

	const isHomeProfile = () => {};

	const checkLoginRedir = async () => {
		const queryParameters = new URLSearchParams(window.location.search);
		const provider = queryParameters.get('provider') ?? 'No Provider';
		if (provider !== 'No Provider') {
			const result = await magicOauth.oauth.getRedirectResult();
			let profile = result.oauth.userInfo.profile ?? 'No Profile found';
			if (provider === 'tweeter' || provider === 'discord') {
				profile = result.oauth.userInfo.preferredUsername ?? 'No Profile found';
			}
			let email = authUserInfo.email ?? result.oauth.userInfo.email ?? 'No Email found';
			console.log(profile);
			console.log(email);

			let data = {
				email: email,
				social_type: provider,
				social_link: profile
			};
			await social_link(data);
		}
	};

	const linkGoogle = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'google' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkFacebook = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'facebook' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkTweeter = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'tweeter' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkDiscord = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'discord' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const setProfileData = async (data) => {
		if (data.profile.photo_large) {
			setProfileAssetType('image');
		}
		if (data.profile.video) {
			setProfileAssetType('video');
		}
		if (data.profile.background_photo) {
			setProfileBackgroundPhotoBlobPreview(data.profile.background_photo);
			const space_bg = {
				backgroundImage: 'url(' + data.profile.background_photo + ')'
			};
			setCardBackground(space_bg);
			setCardBackgroundType('image');
		}
		if (data.profile.background_video) {
			setProfileBackgroundVideoBlobPreview(data.profile.background_video);
			setCardBackgroundType('video');
		}
	};

	const navigateToProfile = async () => {
		navigate(routes.viewUserSpace() +
			(authUserInfo && authUserInfo.profile
				? authUserInfo.profile.username
				: '') +
			'/about')
	};

	useEffect(() => {
		setSelectedTab(currentLocation.pathname);
		checkLoginRedir();

		if (authUserInfo && authUserInfo.profile) {
			setProfileData(authUserInfo).then();
		}
	}, [authUserInfo, currentLocation.pathname]);

	return (
		<>
			<div
				className={
					selectedTab !== routes.profileManageFollowers()
						? 'dashboard-profile'
						: 'dashboard-profileno-background'
				}
			>
				<div className="grid grid-cols-1">
					{selectedTab === routes.profileEdit() && <ProfileEdit />}
					{selectedTab === routes.profileManageContact() && <ManageContact />}
					{selectedTab === routes.profileManageVisibility() && <ManageVisibility />}
					{selectedTab === routes.profileEditAboutSection() && <EditAboutSection />}
					{selectedTab === routes.profileManageFollowers() && <ManageFollowers />}
					{selectedTab === routes.profile() && (
						<section className='grid grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-y-0 gap-8'>
							<div >
								<div className="card-btn-wrapper">
									<Link
										to={routes.profileEdit()}
										className="btn btn-md btn-secondary mt-2"
									>
										<i className="fa-solid fa-pencil pe-3"/> Edit Profile
									</Link>
									<Link
										to={routes.viewUserSpace() +
											(authUserInfo && authUserInfo.profile
												? authUserInfo.profile.username
												: '') +
											'/about'}
										className="btn btn-md btn-secondary mt-3"
									>
										<i className="fa-solid fa-address-card pe-3"/> View
										Profile
									</Link>
									<Link
										to={routes.profileEditAboutSection()}
										className="btn btn-md btn-secondary mt-3"
									>
										<i className="fa-solid fa-pen-to-square pe-3"/> Edit
										About Section
									</Link>
									<Link
										to={routes.profileManageFollowers()}
										className="btn btn-md btn-secondary mt-3"
									>
										<i className="fa-solid fa-users pe-3"/> Manage Followers
									</Link>
									<Link
										to={routes.profileManageContact()}
										className="btn btn-md btn-secondary mt-3"
									>
										<i className="fa-solid fa-envelope pe-3"/> Manage Contact
									</Link>
									<Link
										to={routes.profileManageVisibility()}
										className="btn btn-md btn-secondary mt-3"
									>
										<i className="fa-solid fa-eye pe-3"/> Manage Visibility
									</Link>
								</div>
							</div>
							<div>
								<div className="profile-card-dashboard pt-4 rounded-b-[8px] lg:rounded-bl-none lg:rounded-tl-none lg:rounded-r-[8px]" style={cardBackground} >
									{cardBackgroundType && cardBackgroundType === 'video' && (
										<video
											autoPlay
											muted
											loop
											className="absolute w-full h-full object-cover left-0 top-0  rounded-b-[8px] lg:rounded-bl-none lg:rounded-tl-none lg:rounded-r-[8px]"
										>
											<source
												src={profileBackgroundVideoBlobPreview}
												type="video/mp4"
											/>
										</video>
									)}
									<div className="z-index-ontop">
										{profileAssetType && profileAssetType === 'video' ? (
											<div className="video-profile-background-container">
												<video
													autoPlay
													muted
													loop
													className="video-profile-avatar"
												>
													<source
														src={authUserInfo.profile.video}
														type="video/mp4"
													/>
												</video>
											</div>
										) : (
											<img
											
												src={
													authUserInfo && authUserInfo.profile.photo_thumb
														? authUserInfo.profile.photo_thumb
														: ''
												}
											/>
										)}
										<h5 className="body-text--reg mt-4">
											{authUserInfo &&
												authUserInfo.profile.username &&
												'@' + authUserInfo.profile.username}
										</h5>
										<h1 className="header-large pb-4 text-5xl">
											{authUserInfo &&
												authUserInfo.profile.display_name &&
												authUserInfo.profile.display_name}
										</h1>
										<p className="body-txtitalic--small">
											{authUserInfo && authUserInfo.profile.pronouns && (
												<>
													{getListDisplay(
														PRONOUNS,
														authUserInfo.profile.pronouns
													)}
												</>
											)}
										</p>
										<p className="py-3 px-5 body-txtitalic--smallest">
											{authUserInfo &&
												authUserInfo.profile.intro &&
												authUserInfo.profile.intro}
										</p>
										<ul className="list-socials pt-4 pb-4 flex justify-around ">
											<li>
												{/* <a href="src/pages/Profile/ProfileContent/index"> */}
												<i
													className="fa-brands fa-twitter isDisabled"
													// onClick={linkTweeter}
												></i>
												{/* </a> */}
											</li>
											{/* <li>
                                            <a href="src/pages/Profile/ProfileContent/index">
                                                <i className="fa-brands fa-instagram"></i>
                                            </a>
                                        </li> */}
											<li>
												{/* <a href="src/pages/Profile/ProfileContent/index"> */}
												<i
													className="fa-brands fa-facebook isDisabled"
													// onClick={linkFacebook}
												></i>
												{/* </a> */}
											</li>
											{/* <li>
                                            <a href="src/pages/Profile/ProfileContent/index">
                                                <i className="fa-brands fa-tiktok"></i>
                                            </a>
                                        </li> */}
											<li>
												{/* <a href="src/pages/Profile/ProfileContent/index"> */}
												<i
													className="fa-brands fa-youtube isDisabled"
													// onClick={linkGoogle}
												></i>
												{/* </a> */}
											</li>
											{/* <li>
                                            <a href="src/pages/Profile/ProfileContent/index">
                                                <i className="fa-brands fa-linkedin"></i>
                                            </a>
                                        </li> */}
											<li>
												{/* <a href="src/pages/Profile/ProfileContent/index"> */}
												<i
													className="fa-brands fa-discord isDisabled"
													// onClick={linkDiscord}
												></i>
												{/* </a> */}
											</li>
											{/* <li>
                                            <a href="src/pages/Profile/ProfileContent/index">
                                                <i className="fa-brands fa-twitch"></i>
                                            </a>
                                        </li> */}
											<li>
												{/* <a href="src/pages/Profile/ProfileContent/index"> */}
												<i className="fa-regular fa-globe isDisabled"></i>
												{/* </a> */}
											</li>
										</ul>
										<div className="grid grid-cols-12 md:gap-8 mt-8 mb-8">
											<div className='col-span-6 md:col-span-3'>
												<p className="body-text--large mb-0">
													{shortenNumber(
														authUserInfo
															? authUserInfo.followers_count
															: 0
													)}
												</p>
												<p className="body-text--smallest">Followers</p>
											</div>
											<div className='col-span-6 md:col-span-3'>
												<p className="body-text--large mb-0">
													{shortenNumber(
														authUserInfo
															? authUserInfo.followings_count
															: 0
													)}
												</p>
												<p className="body-text--smallest">Following</p>
											</div>
											<div className='col-span-6 md:col-span-3'>
												<p className="body-text--large mb-0">
													{shortenNumber(
														authUserInfo ? authUserInfo.spaces_count : 0
													)}
												</p>
												<p className="body-text--smallest">Spaces</p>
											</div>
											<div className='col-span-6 md:col-span-3'>
												<p className="body-text--large mb-0">
													{shortenNumber(
														authUserInfo
															? authUserInfo.collections_count
															: 0
													)}
												</p>
												<p className="body-text--smallest">Items</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					)}
				</div>
			</div>
		</>
	);
};

export default memo(ProfileContent);
