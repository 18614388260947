import React, { memo, useEffect, useCallback, useState } from 'react';
import { Button } from 'antd';
import {useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import routes from '../../../routes';
import { getSpaceItemData } from '../../../services/space';
import '../../SpaceBuyMembership/SpaceBuyMembership.scss'
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {checkMembershipItemStatus, updateMembershipItemStatus} from "../../../services/payment_method";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";

const ItemActivateWalletConnect = (props) => {
    const { verifyEmailSpaceUsername, verifyEmailSpaceItemId } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [initialLoad, setInitialLoad] = useState(true);
    const [itemDetails, setItemDetails] = useState(null);
    const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [collectionAssetType, setCollectionAssetType] = useState('image');

    const skipActivation = useCallback(async () => {
        const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
        const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
        navigate(fin_url);
    }, [verifyEmailSpaceUsername, verifyEmailSpaceItemId]);

    const getItem = useCallback(async (space_id, item_id) => {
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }
        const response = await getSpaceItemData(data);
        if (response && response.result) {
            const itemData = response.item;
            setItemDetails(response.item);
            if (itemData.photo || itemData.video) {
                if (itemData.photo) {
                    setCollectionAssetType('image');
                }
                if (itemData.video) {
                    setCollectionAssetType('video');
                }
            } else {
                setCollectionAssetType('image');
            }
        }
    }, []);

    const checkStatus = useCallback(async (space_id, item_id) => {
        if (!item_id) return;
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await checkMembershipItemStatus(data);
        if (response) {
            if (response.result) {
                const transaction = response.stripe_transaction;
                if (transaction) {
                    if (transaction.status !== 'inactive') {
                        const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
                        const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
                        navigate(fin_url);
                    }
                } else {
                    const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
                    const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
                    navigate(fin_url);
                }
            }
            setCheckStatusLoaded(true);
        }
    },[authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceItemId]);

    const activateSubscription = useCallback(async () => {
        setSubmitted(true);
        if (!verifyEmailSpaceItemId) return;
        const data = {
            space_id: spaceInfo.id,
            item_id: verifyEmailSpaceItemId,
            status: 'active'
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const createStripeResponse = await updateMembershipItemStatus(data);
        if (createStripeResponse) {
            if (createStripeResponse && createStripeResponse.result) {
                const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
                const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceItemId;
                navigate(fin_url);
            }
            setSubmitted(false);
        }
    },[authUserInfo, spaceInfo, verifyEmailSpaceUsername, verifyEmailSpaceItemId])

    useEffect(() => {
        if (verifyEmailSpaceUsername) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getItem(spaceInfo.id, verifyEmailSpaceItemId).then((r) => {});
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(verifyEmailSpaceUsername));
                }
            }
        }

        if (spaceInfo && verifyEmailSpaceItemId && authUserInfo) {
            checkStatus(spaceInfo.id, verifyEmailSpaceItemId).then(r => {});
        }
        // console.log('verifyEmailSpaceItemId: '+verifyEmailSpaceItemId);
        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

    }, [
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        initialLoad,
        verifyEmailSpaceUsername,
        verifyEmailSpaceItemId,
        isSubmitted,
        isCheckStatusLoaded
    ]);

    return (
        <>
            <main id="membership-buy" className="flex">
                {!spaceInfo || !authUserInfo || !isCheckStatusLoaded ? (
                    <div className="loading-items w-full text-center mt-8">
                        <i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
                    </div>
                ) : (
                    <>
                        <div className="margin-left-15 xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
                            <div className="grid margin-right-0">
                                <div className="grid grid-cols-2 pr-0 gap-4">
                                    <div className="lg:col-span-1 xsm:col-span-2 lg:pe-5 grid mb-5 align-left-center">

                                        <div className="lg:hidden md:flex">
                                            {membershipItemImage(collectionAssetType, itemDetails)}
                                        </div>

                                        <div className="lg:pr-0">
                                            <h1 className="mb-4 header-large xsm:mt-5">Your Item is ready!</h1>
                                            <p className="body-text--reg">
                                                You’re ready to access{' '}
                                                {itemDetails ? itemDetails.name : ''}! You can activate
                                                your Item on your Account now or you can activate it later.
                                                You won’t get access to the Item Benefits until you do.
                                            </p>
                                            <Button className="btn btn-primary btn-lg mt-5"
                                                    disabled={
                                                        isSubmitted
                                                    }
                                                    onClick={activateSubscription}>
                                                {isSubmitted && (
                                                    <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                )}
                                                Activate Item & Access Space
                                            </Button>
                                            <Button className="btn btn-secondary btn-lg mt-5" onClick={skipActivation}>
                                                Skip Activation & Continue to Space
                                            </Button>
                                            <p className="body-text--reg mt-5 mb-0">
                                                Need help?{' '}
                                                {/*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*/}
                                                {/*	Get support*/}
                                                {/*</Link>*/}
                                                {getSitewideLink('get_support', 'gradient-color-txt body-txtitalic--reg')}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="lg:inline xsm:hidden p-0">
                                        {membershipItemImage(collectionAssetType, itemDetails)}
                                    </div>

                                </div>

                                <div className="col-span-6">
                                    <div className="left-image-holder"></div>
                                </div>
                            </div>
                        </div>
                    </>
                    )
                }
            </main>
        </>
    );
};

export default memo(ItemActivateWalletConnect);
