import { memo, useEffect } from 'react';
import routes from "../../routes";

const CommunityGuidelines = () => {

    useEffect(() => {
        document.title = 'Commonspace Spaces';
    }, []);

    return (
        <main>
            <div className="container mx-auto min-h-screen body-text--small pt-10 pb-10">
                <p className="body-text--big font-bold mt-5 mb-5">
                    Community and Content Guidelines
                </p>

                <p className="mt-5 mb-5">
                    <p className="body-text--small mt-5 mb-5">
                        Last Updated Date: June 1, 2024
                    </p>
                </p>

                <p>
                    Welcome to Commonspace! Our platform is designed to bring people together over shared interests and provide a safe space for everyone to express themselves. To make this happen, we have Community Guidelines that you must follow. By using Commonspace, you agree to these guidelines and our <a className='font-bold' href={routes.termsOfUse()} target="_blank"> Terms of Use</a>. Failure to follow these guidelines may result in issued warnings, deleted content, disabled accounts, or other restrictions.
                </p>
                <p>
                    To make Commonspace a safe and respectful environment, please avoid engaging in the following behaviors, as well as content that promotes such behaviors:
                </p>
                <ul className="list-disc ml-10">
                    <li>
                        Harassment or any illegal behavior.
                    </li>
                    <li>
                        Discrimination against others.
                    </li>
                    <li>
                        Hate speech, including language, symbols, and actions, or engage in other hateful conduct.
                    </li>
                    <li>
                        Threatening to harm another individual or group of people, or yourself.
                    </li>
                    <li>
                        Organizing, promoting, or supporting terrorism, organized crime, hate groups, or violent extremism.
                    </li>
                    <li>
                        Soliciting or offering sexual services, or the buying and selling of firearms.
                    </li>
                    <li>
                        Making sexually explicit content available to anyone under the age of 18.
                    </li>
                    <li>
                        Sharing sexual content involving minors or threatening to post intimate images of others without their knowledge and consent.
                    </li>
                    <li>
                        Sharing content that violates anyone's intellectual property or other rights.
                    </li>
                    <li>
                        Breaking the law.
                    </li>
                </ul>
                <p className="mt-5">
                    Here are some Content Guidelines to keep in mind:
                </p>
                <ul className="list-disc ml-10">
                    <li>
                        Only share content that you have created or have the right to share with your community. This includes text, photos, videos, and any other type of content.
                    </li>
                    <li>
                        Label your community spaces properly so that your users know what to expect when they join.
                    </li>
                    <li>
                        Post only appropriate content for users in your community space. Content that does not belong in a safe and respectful environment, and promotes behaviors that violates our Community Guidelines, are inappropriate for posting.
                    </li>
                    <li>
                        Be authentic and foster meaningful and genuine interactions with others in your community.
                    </li>
                    <li>
                        Learn more about the community spaces before you join so that you belong to the community you want to be a part of.
                    </li>
                    <li>
                        Respect one another in your community spaces and on Commonspace.
                    </li>
                </ul>
                <p className="mt-5">
                    We also encourage positive behaviors that foster a welcoming and supportive community. Please be sure to:
                </p>
                <ul className="list-disc ml-10">
                    <li>
                        Listen actively and respectfully to others, even if you disagree with them.
                    </li>
                    <li>
                        Encourage healthy discussions and interactions within the community.
                    </li>
                    <li>
                        Treat others the way you want to be treated.
                    </li>
                </ul>
                <p className="mt-5">
                    If you see something that you think may violate our guidelines, please help us by reporting it to us at <a className="text-blue-500 hover:underline" href="mailto:support@common.space">support@common.space</a>. Our team will review these reports and work as quickly as possible to remove content that doesn’t meet our guidelines.
                </p>
                <p className="mt-5">
                    Remember that these guidelines will evolve over time, and we may take action against a user or content that violates the spirit of these guidelines when we encounter a new threat or harm that is not explicitly covered in the current version. We will make our best effort to notify you when we update these guidelines, but it is up to you to periodically review these guidelines and our  <a className='font-bold' href={routes.termsOfUse()} target="_blank">Terms of Use</a> for changes. Thanks for doing your part to keep our community spaces safe and respectful!                </p>
            </div>
        </main>
    );
};

export default memo(CommunityGuidelines);