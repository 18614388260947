import { memo, useState,Fragment, useEffect } from 'react';
import {getListIcon} from "../../../utils/list";
import {CARD_TYPES} from "../../../constants/payment_method";
import {RENEW_PAYMENT_OPTIONS, RENEW_PAYMENT_TYPE_OPTIONS} from "../../../constants/space";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }

const DropdownWithItems = (parentProps) => {
	const {
		title,
		isRenew = false,
		isCharge = false,
		chargeType = RENEW_PAYMENT_TYPE_OPTIONS.ONE_TIME,
		displayError,
		icon,
		selectedValue,
		listItems,
		onDropdownChange,
		customButtonClass,
		dropDownClass,
		isDisable = false
	} = parentProps;

	const handleListClick = (item) => {
		if (!Array.isArray(item.value)) onDropdownChange(!isCharge && isRenew? item : item.value);
	};
	const getSelectedValue = (data) => {
		return data.find((element) => element.value === selectedValue);
	}

	const [selected, setSelected] = useState(getSelectedValue(listItems));

	useEffect(() => {
        setSelected(getSelectedValue(listItems))
    })

	const DropDownItems = (data) => {
		const items = data.props;
		const listItems = items.map((item, key) => (
			<div key={'absolute div-parent-key-' + key}>
				<li
					key={'parent-key-' + key}
					onClick={() => handleListClick(item)}
					className={item.disabled ? 'disabled !w-full' : '!w-full'}
				>
					<span className="dropdown-item">{item?.label}</span>
				</li>
				{Array.isArray(item.value) && (
					<div className='grid grid-flow-row auto-rows-max list-unstyled'>
						{item.value.map((subItem, subKey) => (
						<div key={'sub-key-' + key + '-' + subKey} className='ms-4'
						onClick={() => handleListClick(subItem)}
						>
							<span className="dropdown-item gradient-color-txt text-xl">{subItem?.label}</span>
							</div>
						))}
					</div>
					// <li key={'parent-sub-key-' + key}>
					// 	<ul className="list-unstyled mt-8">
					// 		{item.value.map((subItem, subKey) => (
					// 			<li
					// 				className="ms-4 mt-2"
					// 				key={'sub-key-' + key + '-' + subKey}
					// 				onClick={() => handleListClick(subItem)}
					// 			>
					// 				<span className="dropdown-item">{subItem.label}</span>
					// 			</li>
					// 		))}
					// 	</ul>
					// </li>
				)}
			</div>
		));
		return <>{listItems}</>;
	};

	const DisplaySelected = (data) => {
		const items = data.props;
		let label = '';
		if (items.length) {
			items.forEach((item) => {
				if (item.value === selectedValue) {
					label = item.label;
				}

				if (Array.isArray(item.value)) {
					let subItems = item.value;
					subItems.forEach((subItems) => {
						if (subItems.value === selectedValue) {
							label = subItems.label;
						}
					});
				}
			});
		}

		return (
			<>
				{isRenew &&
				<>
					{!isCharge && <i className={'col-2 pt-1 text-center fa-solid fa-calendars'}/>}
					{isCharge && <i className={'col-2 pt-1 text-center fa-solid '+ (label === RENEW_PAYMENT_OPTIONS[0].label ? 'fa-square-1' : 'fa-repeat')}/>}
				</>
				}
				<span>{label}</span>
			</>
		);
	};	

	return (
		<div className={dropDownClass === "fromBusinessDetails" ? 'dropdown w-3/4 ' : 'dropdown ' + dropDownClass}>
			{displayError && <span className={dropDownClass === "fromBusinessDetails" ? 'ant-form-item-explain-error negative-margin-left' : 'ant-form-item-explain-error'}>{title} is required.</span>}
			{/* <Listbox >
				<Listbox.Button className={'btn dropdown-toggle ' + customButtonClass} >
				<i className={icon}></i>
					<DisplaySelected props={listItems} />{' '}
					<i className="fa-solid fa-caret-down right pr-2"></i>
				</Listbox.Button>
				
				<Listbox.Options>
					
					<Listbox.Option className={classNames("text-start dropdown-menu absolute z-50 gradient-color-border border-solid border rounded-lg px-2  min-h-[133px] overflow-auto md:min-h-fit w-auto border-transparent",
				title ==='Language' || title === 'Location' ? 'invisible': '') }				 
				>
					
					<DropDownItems props={listItems} />
				</Listbox.Option>
				</Listbox.Options>
			</Listbox> */}


			{/* <button
				className={'btn dropdown-toggle ' + customButtonClass}
				type="button"
				data-bs-toggle="dropdown"
				aria-expanded="false"
				disabled={isDisable}
			>
				<i className={icon}></i>
				<DisplaySelected props={listItems} />{' '}
				<i className="fa-solid fa-caret-down right pr-2"></i>
			</button> */}
			{/* <ul className={classNames("dropdown-menu w-fit md:w-full",
			title ==='Language' || title === 'Location' ? 'invisible': '') }>
				<DropDownItems props={listItems} />
			</ul> */}

			<Listbox value={selected} onChange={setSelected}>
			{({ open }) => (
				<>
					<div className="relative">
						<Listbox.Button className={"relative h-10 rounded-[50px] w-full cursor-default btn-secondary text-base font-['notoSansSemiBold'] " + (customButtonClass === 'payout' ? "!text-start !pl-4" : "")}>
							<span className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2'><i className={icon}></i></span>
							<span className={(customButtonClass === "payout" && icon ? "!ml-6 " : "") + "truncate text-center"}>{selected?.label}</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
								<i className="fa-solid fa-caret-down text-gray-400 right pr-2 gradient-color-text"></i>
							</span>
						</Listbox.Button>
						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
							>
							<Listbox.Options className={classNames("text-start dropdown-menu absolute z-[999999] gradient-color-border border-solid border rounded-lg px-2  overflow-auto min-h-fit w-full text-base font-['notoSansSemiBold']  border-transparent",
								title ==='Language' || title === 'Location' ? 'invisible': '') }>
									{listItems.map((item, key) => (
										<Listbox.Option
											key={key}
											disabled={isDisable}
											onClick={() => handleListClick(item)}
											className={({ active }) =>
											classNames(
												active ? 'bg-indigo-600 text-white' : 'text-gray-900',
												'relative cursor-default select-none py-2 pl-3 pr-9 gradient-color-txt ',
												item.disabled ? 'disabled' : ''
											)
											}
											value={item}
										>
											{({ selected, active }) => (
												<>
													<span className={classNames(selected ? 'font-semibold text-fuchsia-700' : 'font-normal', 'block truncate', 'dropdown-item flex items-center')}>
													{item?.label}
													</span>

													{selected ? (
													<span
														className={classNames(
														active ? 'text-white' : 'text-indigo-600',
														'absolute inset-y-0 right-0 flex items-center pr-4'
														)}
													>
													</span>
													) : null}
												</>
											)}
								</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
			</Listbox>


		</div>
	);
};

export default memo(DropdownWithItems);
