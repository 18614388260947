import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { createSpaceCategory } from '../../../../services/space';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import FormFieldStacked from '../../FormFieldStacked';
const AddNewCategoryModal = (props) => {
	const [form] = Form.useForm();
	const {
		modalOpen,
		setModalOpen,
		filteredCategoryList,
		setSelectedCategory,
		spaceID,
		userID,
		setUpdateCategory
	} = props;
	const [isLoading, setIsLoading] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(false);
	const [category, setCategory] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32
		},
		overlay: { background: 'rgba(0, 0, 0, 0.6)' }
	};

	const handleSubmit = useCallback(
		async (values) => {
			const data = values;
			const slug = data.category.toLowerCase().replace(/\s/g, '');

			if (values.category != null) {
				setIsLoading(true);
				if (!filteredCategoryList.some(({ label }) => label === data.category)) {
					try {
						data.user_id = userID;
						data.space_id = spaceID;
						data.name = data.category;
						data.slug = slug;
						const response = await createSpaceCategory(data);
						if (response.result) {
							setIsLoading(false);
							setSelectedCategory(slug);
							form.setFieldValue('category', '');
							setModalOpen(false);
							setUpdateCategory(true);
						} else {
							setTimeout(() => {
								setErrorMessage(response.message);
							}, 500);
							setIsLoading(false);
							setSelectedCategory('create');
						}
					} catch (error) {
						setIsLoading(false);
						console.log(error);
					}
				} else {
					setIsLoading(false);
				}
			}
		},
		[filteredCategoryList, setUpdateCategory]
	);

	useEffect(() => {
		if (!modalOpen) {
			setErrorMessage(null);
			form.setFieldValue('category', '');
		}
	}, [filteredCategoryList, modalOpen, errorMessage]);

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setCategory(form.getFieldValue('category'));
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={customStyles}
		>
			<button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
				<i className="fa-solid fa-xmark"></i>
			</button>
			<h3 className="header-medium mt-2">Add New Category</h3>
			<Form
				form={form}
				name="loginForm"
				validateMessages={VALIDATE_MESSAGES}
				onFocus={() => {
					setFormIsOnFocus(true);
				}}
				onBlur={() => {
					setTimeout(() => {
						setFormIsOnFocus(false);
						handleFormOnBlur();
					}, 200);
				}}
				autoComplete="off"
				onChange={handleFormChanges}
				onFinish={handleSubmit}
				className={formIsOnFocus ? 'hide-antd-error-messages add-category' : 'add-category'}
			>
				{errorMessage && <span className="modal-error-message">{errorMessage}</span>}
				<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Category Name
								</label>
								<div className="relative rounded-none ">
								<Form.Item
						name="category"
						className="mb-0 hidden-label"
						validateTrigger="onBlur"
						rules={[
							{ required: true },
							{
								min: 2,
								message: 'Must be minimum 2 characters.'
							},
							{
								max: 24,
								message: 'Must not exceed 24 characters.'
							},
							() => ({
								async validator(_, value) {
									if (value) {
										setErrorMessage(null);
										if (
											filteredCategoryList.some(({ label }) => label === value)
										) {
											setErrorMessage('Category already exist.');
											setFormHasError(true);
											return Promise.reject(
												new Error('Category already exist.')
											);
										}
									}
									return Promise.resolve();
								}
							})
						]}
					>
										
							<FormFieldStacked
							formType='input'
							type="text"	
							placeholder="Up to 24 characters, including spaces."
							></FormFieldStacked>
						</Form.Item>
					</div>
					
				</div>

				

				<Button
					className="btn btn-md btn-primary mb-2 mt-8"
					htmlType="submit"
					disabled={isLoading || formHasError}
				>
					<i className={isLoading ? 'fa-solid fa-spinner fa-spin' : 'fa-solid'}></i>
					Save
				</Button>
			</Form>
		</ReactModal>
	);
};

export default AddNewCategoryModal;
