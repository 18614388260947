import { memo, useEffect, useCallback, useState } from 'react';
import { Button, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';

import { VALIDATE_MESSAGES } from '../../../../constants/common';
import { hideTooltip, showTooltip } from '../../../../utils/input';
import { requestSpaceEmailInvite, subscribeNewsletter } from '../../../../services/space';
import './../../SpaceView.scss';
import default_profile_card_bg from '../../../../assets/images/profile_header.png';
import routes from '../../../../routes';
import { useNavigate } from 'react-router-dom';

const SpacePasswordProtectedPro = (props) => {
	const navigate = useNavigate();
	const { spaceData } = props;
	const pro_styles = spaceData.pro_style;
	const [emailRequestForm, subscriptionForm] = Form.useForm();
	const [submittedEmailRequest, setSubmittedEmailRequest] = useState(false);
	const [submittedSubscription, setSubmittedSubscription] = useState(false);
	const [alertInvite, setAlertInvite] = useState(null);
	const [alert, setAlert] = useState(null);

	let header_background_image =
		spaceData && spaceData.background_video
			? spaceData.background_video
			: default_profile_card_bg;
	header_background_image =
		pro_styles && pro_styles.header_background_image
			? pro_styles.header_background_image
			: header_background_image;

	//google fonts
	const fontTitle = spaceData && pro_styles ? pro_styles.title_font : 'Josefin Sans'; //change value from database
	const fontBody = spaceData && pro_styles ? pro_styles.body_font : 'Bungee Inline'; //change value from database
	const googleFontUrl = 'https://fonts.googleapis.com/css?family=';

	if (fontTitle) {
		const proFontTitle = document.createElement('link');
		proFontTitle.rel = 'stylesheet';
		proFontTitle.href = googleFontUrl + fontTitle.replace(' ', '+');
		document.head.appendChild(proFontTitle);
	}

	if (fontBody) {
		const proFontBody = document.createElement('link');
		proFontBody.rel = 'stylesheet';
		proFontBody.href = googleFontUrl + fontBody.replace(' ', '+');
		document.head.appendChild(proFontBody);
	}

	//Logo & Key Color
	const root = document.documentElement;

	//Key Color and Fonts
	root.style.setProperty('--key-color', pro_styles.key_color);
	root.style.setProperty('--key-color-gradient', pro_styles.gradient_color);
	root.style.setProperty('--title-font', fontTitle);
	root.style.setProperty('--body-font', fontBody);

	//Section Colors & Backgrounds
	//Light Section Text & Background Colors
	root.style.setProperty('--light-section-text-color', pro_styles.light_text_color);
	root.style.setProperty('--light-section-bg-color', pro_styles.light_background_color);
	if (!pro_styles.light_background_video) {
		root.style.setProperty(
			'--light-section-bg-image',
			'url("' + pro_styles.light_background_image + '")'
		);
	}
	root.style.setProperty('--light-section-image-pos', pro_styles.light_image_position);
	root.style.setProperty('--light-section-video-pos', pro_styles.light_video_position);

	//Dark Section Text & Background Colors
	root.style.setProperty('--dark-section-text-color', pro_styles.dark_text_color);
	root.style.setProperty('--dark-section-bg-color', pro_styles.dark_background_color);
	if (!pro_styles.dark_background_video) {
		root.style.setProperty(
			'--dark-section-bg-image',
			'url("' + pro_styles.dark_background_image + '")'
		);
	}
	root.style.setProperty('--dark-section-image-pos', pro_styles.dark_image_position);
	root.style.setProperty('--dark-section-video-pos', pro_styles.dark_video_position);

	let foreground_position = 'center';

	if (pro_styles.foreground_position === 'top' && !pro_styles.foreground_text) {
		foreground_position = 'start';
	} else if (pro_styles.foreground_position === 'bottom' && !pro_styles.foreground_text) {
		foreground_position = 'end';
	}

	let lightVideoStyle = {
		objectFit: 'cover',
		height: '100%'
	};

	if (pro_styles.light_video_position === 'top') {
		lightVideoStyle = {
			top: '0'
		};
	} else if (pro_styles.light_video_position === 'center') {
		lightVideoStyle = {
			top: '50%',
			left: '50%',
			transform: 'translateX(-50%) translateY(-50%)'
		};
	} else if (pro_styles.light_video_position === 'bottom') {
		lightVideoStyle = {
			bottom: '0'
		};
	}

	let darkVideoStyle = {
		objectFit: 'cover',
		height: '100%'
	};

	if (pro_styles.dark_video_position === 'top') {
		darkVideoStyle = {
			top: '0'
		};
	} else if (pro_styles.dark_video_position === 'center') {
		darkVideoStyle = {
			top: '50%',
			left: '50%',
			transform: 'translateX(-50%) translateY(-50%)'
		};
	} else if (pro_styles.dark_video_position === 'bottom') {
		darkVideoStyle = {
			bottom: '0'
		};
	}

	const handleEmailRequestFormSubmit = useCallback(async (values) => {
		setSubmittedEmailRequest(true);
		setAlertInvite(null);

		const data = {
			space_id: spaceData.id,
			email: values.email
		};

		const response = await requestSpaceEmailInvite(data);
		if (response && response.result) {
			setAlertInvite({
				variant: 'success',
				message: response.message
			});
		} else {
			setAlertInvite({
				variant: 'danger',
				message: response.message
			});
		}

		setSubmittedEmailRequest(false);
	}, []);

	const handleSubscriptionFormSubmit = useCallback(async (values) => {
		setSubmittedSubscription(true);
		setAlert(null);

		const data = {
			space_id: spaceData.id,
			email: values.email
		};

		const response = await subscribeNewsletter(data);
		if (response && response.result) {
			setAlert({
				variant: 'success',
				message: response.message
			});
		} else {
			setAlert({
				variant: 'danger',
				message: response.message
			});
		}

		setSubmittedSubscription(false);
	}, []);

	const redirectToLogin = useCallback(async () => {
		navigate(routes.login());
	}, []);

	return (
		<>
			<main
				id="profile"
				className={
					'space-view-pro ' + (pro_styles.has_gradient ? 'has-gradient' : 'no-gradient')
				}
			>
				<div
					className="w-full profile-banner"
					style={{
						backgroundImage: `url(${header_background_image})`
					}}
				>
					<video autoPlay muted loop id="">
						<source src={pro_styles.header_background_video} type="video/mp4" />
					</video>
					<div
						className={`container sv-h flex justify-center align-items-${foreground_position}`}
					>
						{pro_styles.header_foreground_image && !pro_styles.foreground_text && (
							<img
								className="fore-ground-img"
								src={pro_styles.header_foreground_image}
							/>
						)}
						{pro_styles.foreground_text && (
							<h3 className="header-medium">{pro_styles.foreground_text}</h3>
						)}
					</div>
				</div>
				<div className="w-full light-section">
					<div className="split-header pt-3 pb-3 mb-5">
						<div className="grid grid-cols-12 text-center">
							<div className="col-span-12 nav-welcome-pro">
								Welcome to the {spaceData.name}
							</div>
						</div>
					</div>

					<div className="tab-content space-profile-url " id="pills-tabContent">
						<div className="tab-pane show active">
							<div className="container ">
								<div className="grid grid-cols-12 justify-center">
									<div className="col-span-6 col-start-4 text-center">
										<h1 className="header-medium">Invite only</h1>
										<p className="body-text-black">
											Log in to continue or request an invite.
										</p>

										<Button
											className="btn btn-md btn-primary mt-4"
											onClick={redirectToLogin}
										>
											Login
										</Button>

										<Form
											form={emailRequestForm}
											name="emailRequestForm"
											id="emailRequestForm"
											validateMessages={VALIDATE_MESSAGES}
											onFinish={handleEmailRequestFormSubmit}
											className={'space-settings-error pt-3'}
											onFocus={() => {
												alertInvite(null);
											}}
										>
											<>
												{alertInvite && (
													<div className="grid grid-cols-12 m-0 p-0">
														<div className="col-span-8 m-0 p-0">
															<Alert
																key={alertInvite.variant}
																variant={alertInvite.variant}
																className={
																	'custom-alert ' +
																	(alertInvite.variant ===
																	'danger'
																		? 'custom-alert-danger'
																		: 'custom-alert-success')
																}
															>
																{alertInvite.message}
															</Alert>
														</div>
													</div>
												)}
											</>
											<div className="input-group-com input-with-btn mt-4">
												<Form.Item
													label="Email"
													name="email"
													className="mb-0 hidden-label"
													rules={[{ required: true }]}
												>
													<Input
														onFocus={hideTooltip}
														onBlur={showTooltip}
														placeholder="Enter email to request an invite."
														className="input-md body-txtitalic--smallest color-light"
														disabled={submittedEmailRequest}
													/>
												</Form.Item>
												<Form.Item>
													<Button
														className="btn btn-primary btn-md btn-request"
														htmlType="submit"
														disabled={submittedEmailRequest}
													>
														{submittedEmailRequest && (
															<i className="fa-solid fa-spinner fa-spin"></i>
														)}
														<span className="button-text-white">
															Request
														</span>
													</Button>
												</Form.Item>
											</div>
										</Form>

										<Form
											form={subscriptionForm}
											name="subscriptionForm"
											id="subscriptionForm"
											validateMessages={VALIDATE_MESSAGES}
											onFinish={handleSubscriptionFormSubmit}
											className={'space-settings-error pt-3'}
											onFocus={() => {
												setAlert(null);
											}}
										>
											<>
												{alert && (
													<div className="grid grid-cols-12 m-0 p-0">
														<div className="col-span-8 m-0 p-0">
															<Alert
																key={alert.variant}
																variant={alert.variant}
																className={
																	'custom-alert ' +
																	(alert.variant === 'danger'
																		? 'custom-alert-danger'
																		: 'custom-alert-success')
																}
															>
																{alert.message}
															</Alert>
														</div>
													</div>
												)}
											</>
											<div className="input-group-com input-with-btn mt-4">
												<Form.Item
													label="Email"
													name="email"
													className="mb-0 hidden-label"
													rules={[{ required: true }]}
												>
													<Input
														onFocus={hideTooltip}
														onBlur={showTooltip}
														placeholder="Enter email to subscribe to newsletter."
														className="input-md body-txtitalic--smallest color-light"
														disabled={submittedSubscription}
													/>
												</Form.Item>
												<Form.Item>
													<Button
														className="btn btn-secondary-dd btn-md btn-subscribe"
														htmlType="submit"
														disabled={submittedSubscription}
													>
														{submittedSubscription && (
															<i className="fa-solid fa-spinner fa-spin"></i>
														)}
														Subscribe
													</Button>
												</Form.Item>
											</div>
											<ul className="list-socials pt-2  ">
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-twitter isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-instagram isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-facebook isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-tiktok isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-youtube isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-linkedin isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-discord isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-brands fa-twitch isDisabled"></i>
													{/* </a> */}
												</li>
												<li>
													{/* <a href=""> */}
													<i className="fa-regular fa-globe isDisabled"></i>
													{/* </a> */}
												</li>
											</ul>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default memo(SpacePasswordProtectedPro);
