import React, {memo, useCallback, useEffect, useState} from "react";
import ReactModal from "react-modal";
import {Button} from "antd";
import StripeOnboarding from "./OnBoarding/OnBoarding";


const AddStripeBankAccountModal = (props) => {
    const { modalOpen, setModalOpen, userID, spaceID, setIsOnBoardingSubmitted } = props;
    const [submitted, setSubmitted] = useState(false);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            minWidth: 450,
            width: 'auto',
            maxHeight: 800,
            borderRadius: 32
        },
        overlay: { background: 'rgba(0, 0, 0, 0.6)' }
    };

    useEffect(() => {

    }, []);

    return (
        <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={customStyles}
        >
            <Button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
                <i className="fa-solid fa-xmark"></i>
            </Button>

            <StripeOnboarding
                userID={userID}
                spaceID={spaceID}
                setIsOnBoardingSubmitted={setIsOnBoardingSubmitted}
            />
        </ReactModal>
    );
}

export default memo(AddStripeBankAccountModal);