import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import TextareaAutosize from 'react-textarea-autosize';
import Alert from 'react-bootstrap/Alert';
import { Network, Alchemy } from 'alchemy-sdk';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { useSelector } from 'react-redux';

import {
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	NODE_ENV,
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_NETWORK
} from '../../../../constants/common';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	MEMBERSHIP_TYPES,
	UPDATES_VISIBILITY
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { getListIcon } from '../../../../utils/list';
import {
	checkMembershipShortCodeAvailability,
	checkCollectionsContractAddressAvailability,
	createSpaceMembership
} from '../../../../services/space';
import ExplicitContent from '../../../../components/commons/Modals/ExplicitContent';
import routes from '../../../../routes';
import { CustomValidators } from '../../../../utils/CustomValidators';

const CreateMembershipNFT = () => {
	const [createMembershipNFTVerifyContract] = Form.useForm();
	const [createMembershipNFTForm] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [selectedVisibility, setSelectedVisibility] = useState('members');
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [contractAddressDetails, setContractAddressDetails] = useState(null);
	const [submittedCollectionDetails, setSubmittedCollectionDetails] = useState(false);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocusCollection, setFormIsOnFocusCollection] = useState(false);
	const [formHasErrorCollection, setFormHasErrorCollection] = useState(true);
	const [formIsOnFocusMembership, setFormIsOnFocusMembership] = useState(false);
	const [formHasErrorMembership, setFormHasErrorMembership] = useState(true);
	const [contractAddressVerified, setContractAddressVerified] = useState(false);
	const [membershipImageError, setMembershipImageError] = useState(null);
	const [membershipImage, setMembershipImage] = useState(null);
	const [membershipImageBlobPreview, setMembershipImageBlobPreview] = useState(null);
	const [membershipVideo, setMembershipVideo] = useState(null);
	const [membershipVideoBlobPreview, setMembershipVideoBlobPreview] = useState(null);
	const [alert, setAlert] = useState(null);
	const [collectionAlert, setCollectionAlert] = useState(null);
	const [verifyAlert, setVerifyAlert] = useState(null);
	const [collectionContractFound, setCollectionContractFound] = useState(false);
	const [submittedVerification, setSubmittedVerification] = useState(false);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);

	// Optional Config object, but defaults to demo api-key and eth-mainnet.
	const settings = {
		apiKey: ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
		network: Network.ETH_MAINNET // Replace with your network.
	};

	const alchemy = new Alchemy(settings);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});

	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);

	const getCollectionOwner = async (collection_address) => {
		try {
			const contractABI = ['function owner() view returns (address)'];
			const provider = new ethers.providers.AlchemyProvider('mainnet', ALCHEMY_API_KEY);
			const myContract = new ethers.Contract(collection_address, contractABI, provider);
			const owner = await myContract.owner();
			return owner;
		} catch (error) {
			console.error(`Failed to get owner: ${error}`);
		}
	};

	const getCollections = async (collection_address, proceed_verify_wallet_address = false) => {
		setVerifyAlert(null);
		setCollectionAlert(null);
		setCollectionContractFound(null);

		const checkOwnerRes = await getCollectionOwner(collection_address);

		await alchemy.nft
			.getContractMetadata(collection_address)
			.then((result) => {
				// The contract owner
				// Other vars: address, contractDeployer, name, description
				if (result) {
					const contractAddressData = {
						collection_name: result.openSea.collectionName ?? 'No Name',
						description: result.openSea.description ?? 'No Description',
						creator_wallet_address: checkOwnerRes ?? 'No Address',
						wallet_name: result.tokenType
					};

					setContractAddressDetails(contractAddressData);
					setSubmittedCollectionDetails(false);

					if (proceed_verify_wallet_address) {
						verifyWalletAddress(contractAddressData);
					}
				} else {
					setContractAddressDetails(null);
					setCollectionContractFound(false);
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection not found.'
					});
				}

				setSubmittedCollectionDetails(false);
			})
			.catch((error) => {
				const invalid_address_error = 'contractAddress should be a valid address';
				if (error && error.message && error.message.includes(invalid_address_error)) {
					setCollectionAlert({
						variant: 'danger',
						message: 'Invalid Contract Address.'
					});
					setSubmittedCollectionDetails(false);
				}
			});

		setSubmittedCollectionDetails(false);
	};

	const verifyWalletAddress = useCallback(
		async (passedContractAddressDetails) => {
			setSubmittedVerification(true);

			await magic.connect.disconnect().catch((e) => {
				// console.log(e);
			});

			setVerifyAlert(null);

			let passed_creator_wallet_address = null;
			if (passedContractAddressDetails) {
				passed_creator_wallet_address = passedContractAddressDetails.creator_wallet_address;
			}
			if (contractAddressDetails) {
				passed_creator_wallet_address = contractAddressDetails.creator_wallet_address;
			}

			web3.eth
				.getAccounts()
				.then((accounts) => {
					if (passed_creator_wallet_address) {
						console.log('User Wallet Address: ' + accounts?.[0]);
						console.log('Collection Owner Address: ' + passed_creator_wallet_address);

						let isCorrectWallet = accounts?.[0] === passed_creator_wallet_address;

						//SET correct is true on dev environment for testing
						if (NODE_ENV === 'development') isCorrectWallet = true;

						setContractAddressVerified(isCorrectWallet);

						if (spaceInfo) {
							if (!isCorrectWallet) {
								setVerifyAlert({
									variant: 'danger',
									message: 'Collection Contract validation failed.'
								});
							}
						}
					} else {
						setContractAddressVerified(
							accounts?.[0] === contractAddressDetails.creator_wallet_address
						);
					}

					setSubmittedVerification(false);
				})
				.catch((error) => {
					setSubmittedVerification(false);
				});
		},
		[contractAddressDetails, spaceInfo]
	);

	const handleCollectionDetailsFormOnBlur = useCallback(async () => {
		if (spaceInfo) {
			const data = {
				space_id: spaceInfo.id,
				contract_address:
					createMembershipNFTVerifyContract.getFieldValue('contract_address')
			};

			const contract_address_check_result = await checkCollectionsContractAddressAvailability(
				data
			);
			if (contract_address_check_result) {
				if (contract_address_check_result.availability) {
					await createMembershipNFTVerifyContract
						.validateFields()
						.then(async () => {
							await getCollections(
								createMembershipNFTVerifyContract.getFieldValue('contract_address')
							);
							setFormHasErrorCollection(false);
						})
						.catch((errors) => {
							setFormHasErrorCollection(true);
						});
				} else {
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection contract address already exists.'
					});
				}
			}
		}

		if (createMembershipNFTVerifyContract.getFieldValue('contract_address') === '' || createMembershipNFTVerifyContract.getFieldValue('contract_address') === undefined) {
			setCollectionAlert(null);
		}

	}, [spaceInfo]);

	const handleCollectionDetailsFormOnChange = useCallback(async () => {
		setCollectionAlert(null);

		if (spaceInfo) {
			const data = {
				space_id: spaceInfo.id,
				contract_address:
					createMembershipNFTVerifyContract.getFieldValue('contract_address')
			};

			const contract_address_check_result = await checkCollectionsContractAddressAvailability(
				data
			);
			if (contract_address_check_result) {
				if (contract_address_check_result.availability) {
					await createMembershipNFTVerifyContract
						.validateFields()
						.then(async () => {
							setFormHasErrorCollection(false);
						})
						.catch((errors) => {
							setFormHasErrorCollection(true);
						});
				} else {
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection contract address already exists.'
					});
				}
			}
		}

		if (createMembershipNFTVerifyContract.getFieldValue('contract_address') === '' || createMembershipNFTVerifyContract.getFieldValue('contract_address') === undefined) {
			setCollectionAlert(null);
		}

	}, [spaceInfo]);

	const handleMembershipFormOnBlur = useCallback(async () => {
		await createMembershipNFTForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				setFormHasErrorMembership(true);
			});
	}, []);

	const handleFormMembershipChanges = useCallback(async () => {
		await createMembershipNFTForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				setFormHasErrorMembership(true);
			});
	}, []);

	const handleMembershipImageUploadFileChange = (file, blobPreview) => {
		setMembershipImage(file);
		setMembershipImageBlobPreview(blobPreview);
	};

	const handleMembershipVideoUploadFileChange = (file, blobPreview) => {
		setMembershipVideo(file);
		setMembershipVideoBlobPreview(blobPreview);
	};

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};

	const handleCollectionDetailsSubmit = useCallback(async (values) => {
		setSubmittedCollectionDetails(true);
		await getCollections(values.contract_address);
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			setMembershipImageError(null);

			let hasError = false;

			if (selectedContentType === 'default' || selectedAccessType === 'default') {
				hasError = true;
			}

			if (!membershipImageBlobPreview) {
				setMembershipImageError('Membership Image is required.');
				hasError = true;
			}

			if (contractAddressDetails && !contractAddressDetails.creator_wallet_address) {
				setCollectionAlert({
					variant: 'danger',
					message: 'Collection contract address not set.'
				});
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = values;
			data.collection_contract_address =
				createMembershipNFTVerifyContract.getFieldValue('contract_address');
			data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
			data.expiration_subscription_enabled = enableExpirationChecked;
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.visibility = selectedVisibility;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (membershipImage) {
				data.membership_image = membershipImage;
			}

			if (membershipVideo) {
				data.membership_video = membershipVideo;
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					setSubmitted(true);

					const response = await createSpaceMembership(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});

						setDisplayDropDownError(false);

						setTimeout(() => {
							if (enableExpirationChecked) {
								const route = routes.addMemMonthBenefit();
								const url = route.replace(
									':space_username',
									'@' + spaceInfo.username
								);
								const fin_url = url.replace(
									':membership_id',
									response.membership.id
								);
								window.location.replace(fin_url);
							} else {
								const route = routes.manageMembership();
								const url = route.replace(
									':space_username',
									'@' + spaceInfo.username
								);
								const fin_url = url.replace(
									':membership_id',
									response.membership.id
								);
								window.location.replace(fin_url);
							}
						}, 1800);
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
					}

					setSubmitted(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			contractAddressDetails,
			enableExpirationChecked,
			selectedContentType,
			selectedAccessType,
			selectedVisibility,
			membershipImage,
			membershipVideo,
			selectedExplicitAccess,
			selectedExplicitContent,
			membershipImageBlobPreview
		]
	);

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value == 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	useEffect(() => {}, [authUserInfo, spaceInfo, spaceInfoLoading, membershipImageError]);

	return (
		<>
			<div className="memberships">
				<div className="dashboard-wrapper">
					<div className="grid grid-cols-12">
						<div className="col-span-12">
							<h3 className="header-medium">Create NFT Collection Membership</h3>
						</div>
					</div>
					<div className="grid grid-cols-12 mt-5">
						<div className="col-span-12">
							<p className="body-text--small">
								Any existing NFT Collection can be authorized to be used as a
								Membership. This enables Holders to claim and access the Membership
								as long as they own the correct NFT in their wallet.{' '}
								<a className="body-txtitalic--small gradient-color-txt">
									Learn more about the NFT Collection type.
								</a>
							</p>
						</div>
					</div>
				</div>
				<Form
					form={createMembershipNFTVerifyContract}
					name="createMembershipNFTVerifyContract"
					id="createMembershipNFTVerifyContract"
					validateMessages={VALIDATE_MESSAGES}
					onFocus={() => {
						setFormIsOnFocusCollection(true);
					}}
					onBlur={() => {
						setFormIsOnFocusCollection(false);
						handleCollectionDetailsFormOnBlur();
					}}
					onChange={handleCollectionDetailsFormOnChange}
					onFinish={handleCollectionDetailsSubmit}
					className={formIsOnFocusCollection ? 'hide-antd-error-messages' : ''}
				>
					<div className="dashboard-wrapper mt-5">
						<div className="grid grid-cols-12">
							<div className="col-span-6">
								<h3 className="header-small">
									Verify Existing Ethereum NFT Collection
								</h3>
							</div>
							<div className="col-span-6">
								<p className="body-txtitalic--small gradient-color-txt text-end">
									Learn More
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 mt-4 flex items-center">
							<>
								{collectionAlert && (
									<div className="col-span-8">
										<Alert
											key={collectionAlert.variant}
											variant={collectionAlert.variant}
											className={
												'custom-alert ' +
												(collectionAlert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{collectionAlert.message}
										</Alert>
									</div>
								)}
							</>
							<div className="col-span-8">
								<div className="input-group-com w-full mt-2">
									<label className="body-text--small gradient-color-txt w-50 two-rows">
										Collection Contract
										<br />
										Address*
									</label>
									<Form.Item
										label="Collection Contract"
										name="contract_address"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<Input
											placeholder="42 characters starting with an “0x”."
											className="input-md body-text--small color-reg"
											disabled={submittedCollectionDetails}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									Specify the Contract Address of the Collection you would like to
									use as the basis of your Membership.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-12 mt-4 flex items-center">
							<div className="col-span-8">
								<Form.Item>
									<Button
										className="btn btn-primary btn-md"
										htmlType="submit"
										disabled={
											submittedCollectionDetails ||
											formHasErrorCollection ||
											collectionContractFound
										}
									>
										{submittedCollectionDetails && (
											<i className="fa-solid fa-spinner fa-spin me-2"></i>
										)}
										{contractAddressDetails
											? 'Collection Details Found'
											: 'Check Collection Contract'}
									</Button>
								</Form.Item>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest">
									Click here to load the Collection Contract details.
								</p>
							</div>
						</div>

						{contractAddressDetails && (
							<>
								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-2">
										<p className="body-text--small color-reg">
											Collection Name:
										</p>
										<p className="body-text--small color-reg">
											Contract Address:
										</p>
									</div>
									<div className="col-span-6">
										<p className="body-text--small gradient-color-txt">
											{contractAddressDetails.collection_name}
										</p>
										<p className="body-text--small color-reg">
											{contractAddressDetails.creator_wallet_address}
										</p>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Please check that this is the correct Collection
											Contract Address and Name (if any).
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-2">
										<p className="body-text--small color-reg">Overview:</p>
									</div>
									<div className="col-span-6">
										<p className="body-text--small color-reg">
											{contractAddressDetails.description}
										</p>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Please check that this is the correct Collection
											Overview information (if any).
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-2">
										<p className="body-text--small color-reg">Owner Wallet:</p>
										<p className="body-text--small color-reg">
											Wallet Address:
										</p>
									</div>
									<div className="col-span-6">
										<p className="body-text--small gradient-color-txt">
											{contractAddressDetails.wallet_name}
										</p>
										<p className="body-text--small color-reg">
											{contractAddressDetails.creator_wallet_address}
										</p>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Please check that this is the correct Collection
											Contract Owner Wallet Address and Name (if any).
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-4 flex items-center">
									<>
										{verifyAlert && (
											<div className="col-span-8 mb-3">
												<Alert
													key={verifyAlert.variant}
													variant={verifyAlert.variant}
													className={
														'custom-alert ' +
														(verifyAlert.variant === 'danger'
															? 'custom-alert-danger'
															: 'custom-alert-success')
													}
												>
													{verifyAlert.message}
												</Alert>
											</div>
										)}
									</>
									<div className="col-span-8">
										<Button
											className="btn btn-primary btn-md"
											disabled={submittedVerification}
											onClick={verifyWalletAddress}
										>
											{submittedVerification && (
												<i className="fa-solid fa-spinner fa-spin me-2"></i>
											)}
											{contractAddressVerified
												? 'Collection Verified'
												: 'Connect Owner Wallet to Verify & Authorize - Read Only'}
										</Button>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Verify and authorized use by connecting the Owner Wallet
											for the Collection.
										</p>
									</div>
								</div>
							</>
						)}
					</div>
				</Form>
				{contractAddressVerified && (
					<Form
						form={createMembershipNFTForm}
						name="createMembershipNFTForm"
						id="createMembershipNFTForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusMembership(true);
						}}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocusMembership(false);
								handleMembershipFormOnBlur();
							}, 200);
						}}
						onChange={handleFormMembershipChanges}
						onFinish={handleSubmit}
						className={formIsOnFocusMembership ? 'hide-antd-error-messages' : ''}
					>
						<fieldset disabled={submitted}>
							<div className="dashboard-wrapper mt-5">
								<div className="grid grid-cols-12">
									<div className="col-span-6">
										<h3 className="header-small">Membership Details</h3>
									</div>
									<div className="col-span-6">
										<p className="body-txtitalic--small gradient-color-txt text-end">
											View Guide
										</p>
									</div>
								</div>
								<div className="grid grid-cols-12 mt-5">
									<div className="col-span-4">
										<div className="upload-rec">
											<UploadDragger
												passedError={membershipImageError}
												cropperAspectRatio={4 / 3}
												hideDragIcon="true"
												hideText="true"
												uploadFileTypes={IMAGE_FILETYPES.toString()}
												uploadedFilePreview={membershipImageBlobPreview}
												onUploadFileChange={
													handleMembershipImageUploadFileChange
												}
												minHeight={IMAGE_STANDARD_MIN_HEIGHT}
												minWidth={IMAGE_STANDARD_MIN_WIDTH}
												maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
												maxWidth={IMAGE_STANDARD_MAX_WIDTH}
												uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
												disableCropper={true}
											/>
											<i className="fa-regular fa-plus"></i>
										</div>
										<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
											Membership Image*
										</p>
										<p className="text-center body-txtitalic--smallest">
											Up to 3840px square or wide. <br />
											JPEG, PNG, or GIF
										</p>
									</div>
									<div className="col-span-4">
										<div className="upload-rec">
											<UploadDragger
												hideDragIcon="true"
												hideText="true"
												uploadFileTypes={VIDEO_FILETYPES.toString()}
												uploadedFilePreview={membershipVideoBlobPreview}
												onUploadFileChange={
													handleMembershipVideoUploadFileChange
												}
												minHeight={VIDEO_STANDARD_MIN_HEIGHT}
												minWidth={VIDEO_STANDARD_MIN_WIDTH}
												maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
												maxWidth={VIDEO_STANDARD_MAX_WIDTH}
												uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
											/>
											<i className="fa-regular fa-plus"></i>
										</div>
										<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
											Membership Video
										</p>
										<p className="text-center body-txtitalic--smallest">
											Up to 1920px/1080px, square or wide. <br />
											MOV or MP4
										</p>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Your Membership Image (up to 8MB) should be as high
											resolution as possible, up to 3840px height and width.
											This will also be used for any thumbnails and previews.
										</p>
										<p className="body-txtitalic--smallest">
											Your Membership Image (up to 8MB) should be as high
											resolution as possible, up to 3840px height and width.
											This will also be used for any thumbnails and previews.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-8">
										<div className="input-group-com w-full mt-2">
											<label className="body-text--small gradient-color-txt w-1/4">
												Name
											</label>
											<Form.Item
												label="Name"
												name="name"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
													{
														max: 32,
														message: 'Must not exceed 32 characters.'
													}
												]}
											>
												<Input
													placeholder="Up to 32 characters, including spaces."
													className="input-md body-text--small color-reg"
													maxLength={32}
													disabled={submitted}
												/>
											</Form.Item>
										</div>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											This is the displayed Name of the Membership and is what
											people will see when browsing your Space and viewing
											their purchased Membership, as well as the Collection
											Name when viewing the artwork.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-8">
										<div className="input-group-com w-full mt-2">
											<label className="body-text--small gradient-color-txt w-1/4">
												Sku Short Code
											</label>
											<Form.Item
												label="Sku Short Code"
												name="sku_shortcode"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
													{
														min: 2,
														message: 'Must be minimum 2 characters.'
													},
													{
														max: 8,
														message: 'Must not exceed 8 characters.'
													},
													({ getFieldValue }) => ({
														async validator(_, value) {
															if (value) {
																const data = {
																	shortcode: value
																};

																if (spaceInfo) {
																	data['space_id'] = spaceInfo.id;
																}

																let available = false;
																const response =
																	await checkMembershipShortCodeAvailability(
																		data
																	);
																if (response && response.result) {
																	available =
																		response.availability;
																}

																if (!available) {
																	return Promise.reject(
																		new Error(
																			'Shortcode not available.'
																		)
																	);
																}
															}

															if (
																(value && value.match(/\ /g)) ||
																/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
																	value
																)
															) {
																return Promise.reject(
																	new Error('Invalid Input')
																);
															}

															return Promise.resolve();
														}
													})
												]}
											>
												<Input
													placeholder="2 to 8 characters. Letters and numbers only, no spaces."
													className="input-md body-text--small color-reg"
													disabled={submitted}
													maxLength={8}
												/>
											</Form.Item>
										</div>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Choose a unique SKU Shortcode based the Membership Name.
											This is must be unique within your Space and is used in
											transactions, support, and other areas.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-8">
										<div className="input-group-com w-full mt-2">
											<label className="body-text--small gradient-color-txt w-50">
												Max Per Account
											</label>
											<Form.Item
												label="Max Per Account"
												name="max_per_account"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
													{ validator: CustomValidators.maxPerCount }
												]}
											>
												<Input
													placeholder="Enter “0” for unlimited."
													className="input-md body-text--small color-reg"
													disabled={submitted}
												/>
											</Form.Item>
										</div>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											You can put a limit how many Memberships can be
											purchased by a single account. Enter “0” to set this to
											unlimited.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-4  flex items-center">
									<div className="col-span-8">
										<div className="form-check ms-5">
											<label className="form-check-label body-text--small gradient-color-txt">
												<Checkbox
													className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
													checked={enableExpirationChecked}
													onChange={onChangeEnableExpiration}
												></Checkbox>
												Enable Expiration Date and add Monthly Subscription
												for continued access
											</label>
										</div>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Memberships have a one-time upfront Price. This option
											adds an Expiration after a set number of months. After
											that, Members must pay a Monthly Subscription to
											continue access. Details are added in the next step.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-8">
										<div className="input-group-com w-full mt-2">
											<label className="body-text--small gradient-color-txt w-1/4">
												Summary
											</label>
											<Form.Item
												label="Summary"
												name="summary"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[{ required: true }]}
											>
												<TextareaAutosize
													minRows={3}
													placeholder="Up to 128 characters, including spaces."
													className="input-md body-text--small color-reg"
													maxLength={128}
												/>
											</Form.Item>
										</div>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											This Summary shows when Members view their Membership.
											It should be 1 to 2 sentences summarizing the Membership
											and its details.
										</p>
									</div>
								</div>

								<div className="grid grid-cols-12 mt-5 flex items-center">
									<div className="col-span-8">
										<div className="input-group-com w-full mt-2">
											<label className="body-text--small gradient-color-txt w-1/4">
												Listing Text
											</label>
											<Form.Item
												label="Listing Text"
												name="listing_text"
												className="mb-0 hidden-label"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
													{
														max: 128,
														message: 'Must not exceed 128 characters.'
													}
												]}
											>
												<TextareaAutosize
													minRows={3}
													placeholder="Up to 128 characters, including spaces."
													className="input-md body-text--small color-reg"
													maxLength={128}
												/>
											</Form.Item>
										</div>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											The Listing Text is used when listing your Membership
											for purchase on your Space. It should be 1 to 2
											sentences summarizing the Membership and why people
											should join.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-8">
										<DropdownWithItems
											title="Select Content Type"
											dropDownClass="dropdown-bg"
											customButtonClass="btn-md btn-secondary mt-2"
											displayError={
												displayDropDownError &&
												selectedContentType === 'default'
											}
											icon={getListIcon(
												CONTENT_TYPES_LIST_WITH_DEFAULT,
												selectedContentType
											)}
											selectedValue={selectedContentType}
											listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
											onDropdownChange={(value) =>
												openContentTypeModal(value)
											}
										/>
										<ExplicitContent
											modalOpen={modalOpenExplicitContent}
											setModalOpen={setModalOpenExplicitContent}
											selectedItems={selectedExplicitContent}
											isExplicitContent={true}
											onSelectedItems={(value) =>
												setSelectedExplicitContent(value)
											}
										/>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Change this setting if your Membership Details, Image,
											or Video contains explicit or sensitive content. If it
											does, viewers may need to confirm their age before
											accessing content. View our Content Guidelines.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-8">
										<DropdownWithItems
											title="Select Access Type"
											dropDownClass="dropdown-bg"
											customButtonClass="btn-md btn-secondary mt-2"
											displayError={
												displayDropDownError &&
												selectedAccessType === 'default'
											}
											icon={getListIcon(
												ACCESS_TYPES_LIST_WITH_DEFAULT,
												selectedAccessType
											)}
											selectedValue={selectedAccessType}
											listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
											onDropdownChange={(value) => openAccessTypeModal(value)}
										/>
										<ExplicitContent
											modalOpen={modalOpenExplicitAccess}
											setModalOpen={setModalOpenExplicitAccess}
											selectedItems={selectedExplicitAccess}
											isExplicitContent={false}
											onSelectedItems={(value) =>
												setSelectedExplicitAccess(value)
											}
										/>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											Change this setting if this Membership will provide
											Members with access to explicit or sensitive content,
											either directly on your Space or via an external site or
											other source. View our Content Guidelines.
										</p>
									</div>
								</div>
								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-8">
										<DropdownWithItems
											title="Update Visibility"
											dropDownClass="dropdown-bg"
											customButtonClass="btn-md btn-secondary mt-2"
											displayError={
												displayDropDownError &&
												selectedVisibility === 'default'
											}
											selectedValue={selectedVisibility}
											listItems={UPDATES_VISIBILITY}
											onDropdownChange={(value) =>
												setSelectedVisibility(value)
											}
										/>
									</div>
									<div className="col-span-4">
										<p className="body-txtitalic--smallest">
											You can set the Visibility of this Membership to
											viewable to the Public, Members Only (any membership),
											or Custom Access (based on owning a specific Membership
											or Item).
										</p>
									</div>
								</div>
								<div className="grid grid-cols-12 mt-4 flex items-center">
									<>
										{alert && (
											<div className="col-span-12">
												<Alert
													key={alert.variant}
													variant={alert.variant}
													className={
														'custom-alert ' +
														(alert.variant === 'danger'
															? 'custom-alert-danger'
															: 'custom-alert-success')
													}
												>
													{alert.message}
												</Alert>
											</div>
										)}
									</>
								</div>
								<div className="grid grid-cols-12 mt-4 flex items-center">
									<div className="col-span-12">
										<Form.Item className="mb-0">
											<Button
												className="btn btn-primary btn-md"
												htmlType="submit"
												disabled={submitted || formHasErrorMembership}
											>
												{submitted ? (
													<>
														<i className="fa-solid fa-spinner fa-spin me-2"></i>
														Saving
													</>
												) : (
													<>Save</>
												)}
											</Button>
										</Form.Item>
									</div>
								</div>
							</div>
						</fieldset>
					</Form>
				)}
			</div>
		</>
	);
};

export default memo(CreateMembershipNFT);
