import React, { memo, useCallback, useEffect, useState } from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import Alert from 'react-bootstrap/Alert';
import { useSearchParams, useLocation, useNavigate, Link } from 'react-router-dom';

import Communities from './Communities';
import FeaturedLinks from './FeaturedLinks';
import LatestUpdates from './LatestUpdates';
import MemberBenefits from './MemberBenefits';
import Benefits from './Tabs/Benefits';
import SpaceClaimMembership from '../SpaceClaimMembership';
import Channel from '../Channel';
import SpaceClaimItem from '../SpaceClaimItem';
import { VALIDATE_MESSAGES } from '../../constants/common';
import { hideTooltip, showTooltip } from '../../utils/input';
import { subscribeNewsletter, getSpaceUpdates } from '../../services/space';

import './SpaceView.scss';
import default_profile_card_bg from '../../assets/images/profile_header.png';
import UpdatesContent from './LatestUpdates/UpdatesContent';
import SpaceSupport from './SpaceSupport';
import SpaceAbout from './SpaceAbout';
import SpaceFAQ from './SpaceFAQ';
import SpaceTermsServices from './SpaceTermsServices';
import SpacePrivacyPolicy from './SpacePrivacyPolicy';
import SpaceContact from './CommonLayouts/SpaceContact/SpaceContact';
import SpaceBuyMembership from '../SpaceBuyMembership';
import SpaceBuyItem from '../SpaceBuyItem';
import SpaceInformationPages from './SpaceInformationPages';
import routes from '../../routes';
import { CustomValidators } from '../../utils/CustomValidators';
import {getListDisplay} from "../../utils/list";
import {PRONOUNS} from "../../constants/profile";

const SpaceView = (props) => {
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const urlTab = searchParams.get('tab');
	const { spaceData, globalActiveTab, updateGlobalActiveTab } = props;
	const [subscriptionForm] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [submittedSubscription, setSubmittedSubscription] = useState(false);
	const [alert, setAlert] = useState(null);
	const [spaceUpdates, setSpaceUpdates] = useState(null);
	const [spaceUpdatesIsFetching, setSpaceUpdatesIsFetching] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [spaceUsername, setSpaceUsername] = useState(null);
	const { size } = useSelector((state) => state.classSize);
	const [updatesViewingUserIsMember, setUpdatesViewingUserIsMember] = useState(false);
	const [playVideo, setPlayVideo] = useState(false);

	let profileAssetType = 'image';
	if (spaceData.video) {
		profileAssetType = 'video';
	}

	let space_bg = null;
	let cardBackgroundType = 'image';
	if (spaceData.background_photo || spaceData.background_video) {
		if (spaceData.background_photo) {
			space_bg = {
				backgroundImage: 'url(' + spaceData.background_photo + ')'
			};
			cardBackgroundType = 'image';
		}
		if (spaceData.background_video) {
			cardBackgroundType = 'video';
		}
	} else {
		space_bg = {
			backgroundImage: 'url(' + default_profile_card_bg + ')'
		};
		cardBackgroundType = 'image';
	}

	let selectedWelcomeAssetType = 'image';
	if (spaceData.welcome_youtube_url) {
		selectedWelcomeAssetType = 'youtube';
	} else {
		if (spaceData.welcome_video) {
			selectedWelcomeAssetType = 'video';
		}
	}

	const getUpdates = useCallback(
		async (data) => {
			if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0)) {
				setSpaceUpdatesIsFetching(true);
			}

			try {
				const publish_statuses = ['immediately', 'published', 'publishing'];
				const params = {
					space_id: data.id,
					in_space_view: true,
					publish_statuses: JSON.stringify(publish_statuses)
				};

				if (authUserInfo) {
					params.viewing_user_id = authUserInfo.id;
				}

				const result = await getSpaceUpdates(params);
				if (result && result.result) {
					if (result.data) {
						setSpaceUpdates(result.data);
						setUpdatesViewingUserIsMember(result.viewing_user_is_member);
					} else {
						setSpaceUpdates(null);
					}
				}

				setSpaceUpdatesIsFetching(false);
			} catch (error) {
				console.log(error);
				setSpaceUpdatesIsFetching(false);
			}
		},
		[authUserInfo, spaceUpdates]
	);

	let latestUpdatesCount = 0;
	const latestUpdates = [];
	if (spaceData.updates) {
		latestUpdatesCount = spaceData.updates.length;
		spaceData.updates.forEach((item, index) => {
			if (index <= 2) {
				latestUpdates.push(item);
			}
		});
	}

	let featuredLinksCount = 0;
	const featuredLinks = [];
	if (spaceData.links) {
		featuredLinksCount = spaceData.links.length;
		spaceData.links.forEach((item, index) => {
			if (index <= 2) {
				featuredLinks.push(item);
			}
		});
	}

	let firstMembership = null;
	const spaceMembershipsAndItems = [];
	if (spaceData.memberships) {
		spaceData.memberships.forEach((item, index) => {
			// Don't add subscription item/membership if benefits doesn't exist
			{
				item.enable_expiration_and_subscription && item.benefits.length < 1
					? null
					: spaceMembershipsAndItems.push(item);
			}
			if (index === 0) {
				firstMembership = item;
			}
		});
	}
	if (spaceData.items) {
		spaceData.items.forEach((item, index) => {
			// Don't add subscription item/membership if benefits doesn't exist
			{
				item.enable_expiration_and_subscription && item.benefits.length < 1
					? null
					: spaceMembershipsAndItems.push(item);
			}
		});
	}

	if (spaceData && spaceMembershipsAndItems && spaceMembershipsAndItems.length < 3) {
		const membershipCount = spaceMembershipsAndItems.length;
		let remainingMembershipCount = 3 - membershipCount;
		while (remainingMembershipCount > 0) {
			spaceMembershipsAndItems.push({});
			remainingMembershipCount--;
		}
	}

	const onlyThreeSpaceMembershipsAndItems = [];
	if (spaceMembershipsAndItems.length > 0) {
		spaceMembershipsAndItems.forEach((item, index) => {
			if (index <= 2) {
				// Don't add subscription item/membership if benefits doesn't exist
				{
					item.enable_expiration_and_subscription && item.benefits.length < 1
						? null
						: onlyThreeSpaceMembershipsAndItems.push(item);
				}
			}
		});
	}

	let benefitsCount = 0;
	const benefits = [];
	if (spaceData.benefits) {
		benefitsCount = spaceData.benefits.length;
		spaceData.benefits.forEach((item, index) => {
			if (index <= 2) {
				benefits.push(item);
			}
		});
	}

	const handleSubscriptionFormSubmit = useCallback(async (values) => {
		if (values.email) {
			setSubmittedSubscription(true);
			setAlert(null);

			const data = {
				space_id: spaceData.id,
				email: values.email
			};

			const response = await subscribeNewsletter(data);
			if (response && response.result) {
				setAlert({
					variant: 'success',
					message: response.message
				});
			} else {
				setAlert({
					variant: 'danger',
					message: response.message
				});
			}
			subscriptionForm.resetFields();
			setSubmittedSubscription(false);
		}
	}, []);

	const setActiveTabChange = (item) => {
		updateGlobalActiveTab(item);

		if (item === 'home' || item === 'members') {
			// force page url navigate to reset location path when going back to home
			navigate(routes.viewUserSpace() + spaceData.username, { replace: true });
		}
	};

	const setVideoPlay = () => {
		if (globalActiveTab !== 'home') {
			setPlayVideo(false);
		}
	};

	const tabs = [
		{ id: 'home', active: ['home'] },
		{ id: 'updates', active: ['updates'] },
		{ id: 'benefits', active: ['benefits'] },
		{ id: 'members', active: ['members'] },
		{ id: 'support', active: ['support'] },
		{ id: 'about', active: ['about', 'about#contact'] },
	];

	useEffect(() => {
		const path = window.location.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}

		if (spaceData) {
			setUpdatesViewingUserIsMember(spaceData.viewing_user_is_member);
		}

		if (spaceUsername != null) {
			if (globalActiveTab === 'updates') {
				getUpdates(spaceData).then();
				if (!path.includes('update')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/update');
				}
			} else if (globalActiveTab === 'home') {
				if (!path.includes('home')) {
					window.history.replaceState(null, null, '/@' + spaceUsername);
				}
			} else if (globalActiveTab === 'benefits') {
				if (!path.includes('benefit')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/benefit');
				}
			} else if (globalActiveTab === 'members') {
				if (!path.includes('member')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/member');
				}
			} else if (globalActiveTab === 'support') {
				if (!path.includes('support')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/support');
				}
			} else if (globalActiveTab === 'about') {
				if (!path.includes('about')) {
					window.history.replaceState(null, null, '/@' + spaceUsername + '/about');
				}
			} else if (globalActiveTab === 'about#contact') {
				if (!path.includes('about#contact')) {
					window.history.replaceState(
						null,
						null,
						'/@' + spaceUsername + '/about#contact'
					);
				}
			}
		}

		const availableTabs = ['home', 'updates', 'benefits', 'members'];
		if (initialLoad) {
			setInitialLoad(false);

			if (availableTabs.includes(urlTab)) {
				setActiveTabChange(urlTab);
				searchParams.delete('tab');
				setSearchParams(searchParams);
			}

			if (urlTab != null) {
				urlTab.map((tab) => console.log('tab: ' + tab));
			}
		}

		if (globalActiveTab === 'update_content') {
			if (availableTabs.includes(globalActiveTab)) {
				let baseUrl = '/@' + spaceUsername;
				if (globalActiveTab === 'home') {
					baseUrl = baseUrl;
				}
				if (globalActiveTab === 'updates') {
					baseUrl = baseUrl + '/update';
				}
				if (globalActiveTab === 'benefits') {
					baseUrl = baseUrl + '/benefit';
				}
				if (globalActiveTab === 'members') {
					baseUrl = baseUrl + '/member';
				}
				if (globalActiveTab === 'support') {
					baseUrl = baseUrl + '/support';
				}
				if (globalActiveTab === 'about') {
					baseUrl = baseUrl + '/about';
				}
				if (globalActiveTab === 'about#contact') {
					baseUrl = baseUrl + '/about#contact';
				}
				window.history.replaceState(null, null, baseUrl);
				navigate(baseUrl);
			}
		}

		// if (document.location.hash === '#contact') {
		// 	setTimeout(() => {
		// 		document
		// 			.querySelector('#contact')
		// 			.scrollIntoView({ behavior: 'smooth', block: 'start' });
		// 	}, 300);
		// }

		setVideoPlay();
	}, [spaceData, initialLoad, urlTab, globalActiveTab, spaceUsername, playVideo]);

	return (
		<>
			<main id="profile">
				<div className="w-full profile-banner py-8 min-h-[810px] lg:min-h-[400px]" style={space_bg}>
					{cardBackgroundType && cardBackgroundType === 'video' && (
						<video autoPlay muted loop className="user-view-video-profile-background">
							<source src={spaceData.background_video} type="video/mp4" />
						</video>
					)}
					<div
						className={
							'container ' + (cardBackgroundType === 'video' ? 'z-index-ontop' : '')
						}
					>
						<div className="grid grid-cols-12 lg:gap-8">
							<div className="flex justify-center col-span-12 lg:col-span-3">
								{profileAssetType && profileAssetType === 'video' ? (
									<div className="user-view-video-profile-container">
										<video
											autoPlay
											muted
											loop
											className="user-view-video-profile-avatar"
										>
											<source src={spaceData.video} type="video/mp4" />
										</video>
									</div>
								) : (
									<>
										{/*{spaceData.photo_large ? (*/}
										{/*	<img*/}
										{/*		className="profile-image"*/}
										{/*		src={spaceData.photo_large}*/}
										{/*	/>*/}
										{/*) : (*/}
										{/*	<div className="profile-image no-user-profile-photo flex justify-center">*/}
										{/*		<i className="fa-solid fa-user"></i>*/}
										{/*	</div>*/}
										{/*)}*/}
									</>
								)}
							</div>
							<div className="col-span-12 lg:col-span-6 md:mt-2 max-w-[100%] lg:max-w-[548px] ">
								<h5 className="col-span-12 body-text-reg">
									@{spaceData.username}
								</h5>

								<div className="grid grid-cols-12 gap-6 mt-2">
									<div className="col-span-12">
										<div className="block lg:hidden items-center">
											<h1 className="cols-span-12 header-large display-name">
												{spaceData.name}
											</h1>

											<h1 className="cols-span-12 body-txtitalic--small">
												{spaceData.location}
											</h1>
										</div>
										<div className="hidden lg:block ">
											<div className="col-span-12 header-large flex items-baseline">
												<h1 className="cols-span-12 display-name flex-item-left sm:overflow-auto">
													{spaceData.name}
												</h1>
												<h1 className="cols-span-12 body-txtitalic--small ">
													{spaceData.location}
												</h1>
											</div>
										</div>
											
										<div className="col-span-12 mt-4">
											<p className="body-txtitalic--smallest">
												{spaceData.about}
											</p>
										</div>
									</div>
								</div>

								<div className="col-span-12 mt-6">
									{/*display space social links if available*/}
									{(spaceData.social_link || spaceData.website) && (
										<div className="mt-2">
											<ul className="list-socials pt-2">
												{spaceData.social_link && (
													<li>
														{/* <a href=""> */}
														<i className="fa-brands fa-twitter isDisabled-link"></i>
														{/* </a> */}
													</li>
												)}
												{spaceData.social_link && (
													<li>
														{/* <a href=""> */}
														<i className="fa-brands fa-instagram isDisabled-link"></i>
														{/* </a> */}
													</li>
												)}
												{spaceData.social_link && (
													<li>
														{/* <a href=""> */}
														<i className="fa-brands fa-facebook isDisabled-link"></i>
														{/* </a> */}
													</li>
												)}
												{spaceData.social_link && (
													<li>
														{/* <a href=""> */}
														<i className="fa-brands fa-tiktok isDisabled-link"></i>
														{/* </a> */}
													</li>
												)}
												{spaceData.social_link && (
													<li>
														{/* <a href=""> */}
														<i className="fa-brands fa-youtube isDisabled-link"></i>
														{/* </a> */}
													</li>
												)}
												{spaceData.social_link && (
													<li>
														{/* <a href=""> */}
														<i className="fa-brands fa-linkedin isDisabled-link"></i>
														{/* </a> */}
													</li>
												)}
												{spaceData.social_link && (
													<li>
														{/* <a href=""> */}
														<i className="fa-brands fa-discord isDisabled-link"></i>
														{/* </a> */}
													</li>
												)}
												{spaceData.social_link && (
													<li>
														{/* <a href=""> */}
														<i className="fa-brands fa-twitch isDisabled-link"></i>
														{/* </a> */}
													</li>
												)}
												{spaceData.website && (
													<li>
														<a href={spaceData.website} target="_blank">
															<i
																className={
																	spaceData.website
																		? 'fa-regular fa-globe isEnabled'
																		: 'fa-regular fa-globe isDisabled-link'
																}
															></i>
														</a>
													</li>
												)}
											</ul>
										</div>
									)}
								</div>

								<div className="col-span-12 grid grid-cols-12 lg:max-w-[432px] gap-8 mt-6">
									<div className="col-span-6 sm:col-span-3 max-w-[84px]">
										<p className="body-text--large mb-0">
											{spaceData ? spaceData.members_count : 0}
										</p>
										<p className="body-text--smallest">Members</p>
									</div>
									<div className="col-span-6 sm:col-span-3 max-w-[84px]">
										<p className="body-text--large mb-0">
											{spaceData ? spaceData.updates_count : 0}
										</p>
										<p className="body-text--smallest">Updates</p>
									</div>
									<div className="col-span-6 sm:col-span-3 max-w-[84px]">
										<p className="body-text--large mb-0">
											{spaceData ? spaceData.benefits_count : 0}
										</p>
										<p className="body-text--smallest">Benefits</p>
									</div>
								</div>

							</div>
							<div className="col-span-12 lg:col-span-3 lg:max-w-[316px] mt-8">
								<a href="" className="btn btn-primary btn-md">
									Become a Member
								</a>
								<a href="" className="btn btn-secondary btn-md mt-4">
									Learn More
								</a>
								<Link
									onClick={(event) => {
										// window.location.href =
										// 	window.location.origin +
										// 	'/@' +
										// 	spaceUsername +
										// 	'/about#contact';

										setActiveTabChange('about#contact');
									}}
									className="btn btn-secondary btn-md mt-4"
								>
									Contact
								</Link>
								<a href="" className="btn btn-secondary btn-md mt-4">
									Share
								</a>
							</div>
						</div>
					</div>
					<div className="bg-overlay"></div>
				</div>
				<div className="w-full ">
					<div className="container">
						<ul className="nav nav-pills grid lg:gap-8 lg:grid-flow-col lg:justify-stretch pt-5" id="pills-tab" role="tablist">
							{tabs.map((tab) => (
								<li className="nav-item col-span-12 lg:col-span-2 flex justify-center max-w-[100%] lg:max-w-[200px] " role="presentation">
									<Button
										className={'w-full nav-link ' + (tab.active.includes(globalActiveTab) ? 'active' : 'hidden')}
										onClick={() => setActiveTabChange(tab.id)}
									>
										{tab.id.charAt(0).toUpperCase() + tab.id.slice(1)}
									</Button>
								</li>
							))}
						</ul>
						{/*<div className="row">*/}
						{/*	<div className="col-span-12">*/}
						{/*		<ul*/}
						{/*			className="nav nav-pills mt-5 flex justify-content-evenly"*/}
						{/*			id="pills-tab"*/}
						{/*			role="tablist"*/}
						{/*		>*/}
						{/*			<li className="nav-item" role="presentation">*/}
						{/*				<Button*/}
						{/*					className={*/}
						{/*						'nav-link ' +*/}
						{/*						(globalActiveTab === 'home' ? 'active' : '')*/}
						{/*					}*/}
						{/*					onClick={(event) => setActiveTabChange('home')}*/}
						{/*				>*/}
						{/*					Home*/}
						{/*				</Button>*/}
						{/*			</li>*/}
						{/*			<li className="nav-item" role="presentation">*/}
						{/*				<Button*/}
						{/*					className={*/}
						{/*						'nav-link ' +*/}
						{/*						(globalActiveTab === 'updates' ? 'active' : '')*/}
						{/*					}*/}
						{/*					onClick={(event) => {*/}
						{/*						setActiveTabChange('updates');*/}
						{/*					}}*/}
						{/*				>*/}
						{/*					Updates*/}
						{/*				</Button>*/}
						{/*			</li>*/}
						{/*			<li className="nav-item" role="presentation">*/}
						{/*				<Button*/}
						{/*					className={*/}
						{/*						'nav-link ' +*/}
						{/*						(globalActiveTab === 'benefits' ? 'active' : '')*/}
						{/*					}*/}
						{/*					onClick={(event) => setActiveTabChange('benefits')}*/}
						{/*				>*/}
						{/*					Benefits*/}
						{/*				</Button>*/}
						{/*			</li>*/}
						{/*			<li className="nav-item" role="presentation">*/}
						{/*				<Button*/}
						{/*					className={*/}
						{/*						'nav-link ' +*/}
						{/*						(globalActiveTab === 'members' ? 'active' : '')*/}
						{/*					}*/}
						{/*					onClick={(event) => setActiveTabChange('members')}*/}
						{/*				>*/}
						{/*					Members*/}
						{/*				</Button>*/}
						{/*			</li>*/}
						{/*			<li className="nav-item" role="presentation">*/}
						{/*				<Button*/}
						{/*					className={*/}
						{/*						'nav-link ' +*/}
						{/*						(globalActiveTab === 'support' ? 'active' : '')*/}
						{/*					}*/}
						{/*					onClick={(event) => setActiveTabChange('support')}*/}
						{/*				>*/}
						{/*					Support*/}
						{/*				</Button>*/}
						{/*			</li>*/}
						{/*			<li className="nav-item" role="presentation">*/}
						{/*				<Button*/}
						{/*					className={*/}
						{/*						'nav-link ' +*/}
						{/*						(globalActiveTab === 'about' ||*/}
						{/*						globalActiveTab == 'about#contact'*/}
						{/*							? 'active'*/}
						{/*							: '')*/}
						{/*					}*/}
						{/*					onClick={(event) => setActiveTabChange('about')}*/}
						{/*				>*/}
						{/*					About*/}
						{/*				</Button>*/}
						{/*			</li>*/}
						{/*		</ul>*/}
						{/*	</div>*/}
						{/*</div>*/}
					</div>

					<div className="tab-content space-profile-url mt-5 pb-5" id="pills-tabContent">
						<div
							className={
								'tab-pane ' + (globalActiveTab === 'home' ? 'show active' : 'hidden')
							}
						>
							<div className="container">
								<div className="grid grid-cols-12 gap-x-0 lg:gap-x-8 pt-5 ">
									<div className="col-span-12 lg:col-span-7 flex justify-center lg:items-start">
										<div className="img-vid-container w-full">
											{selectedWelcomeAssetType === 'image' && (
												<img
													className="img-fluid"
													src={spaceData.welcome_photo_medium}
												/>
											)}
											{selectedWelcomeAssetType === 'video' && (
												<ReactPlayer
													onPlay={() => setPlayVideo(true)}
													onStart={() => setPlayVideo(true)}
													playing={playVideo}
													controls={true}
													width="100%"
													height="100%"
													className="videoPreview"
													url={spaceData.welcome_video}
												/>
											)}
											{selectedWelcomeAssetType === 'youtube' && (
												<ReactPlayer
													onPlay={() => setPlayVideo(true)}
													onStart={() => setPlayVideo(true)}
													playing={playVideo}
													controls={true}
													width="100%"
													height="100%"
													className="youtubeVideoPreview"
													url={spaceData.welcome_youtube_url}
												/>
											)}
										</div>
									</div>
									<div className="col-span-12 lg:col-span-5 lg:max-w-560 grid items-center content-center">
										<div className=" mt-8 lg:mt-0 w-full">
											<h4 className="header-medium ">
												{spaceData.welcome_title}
											</h4>
										</div>
										<div className="mt-8 w-full ">
											<p className="body-text--large ">
												{spaceData.welcome_message}
											</p>
										</div>

										<div className="mt-8 w-full">
											{spaceData.has_newsletters_signup && (
												<>
													<Form
														form={subscriptionForm}
														name="subscriptionForm"
														id="subscriptionForm"
														validateMessages={VALIDATE_MESSAGES}
														onFinish={handleSubscriptionFormSubmit}
														onFocus={() => {
															setAlert(null);
														}}
														onBlur={() => {
															setAlert(null);
														}}
													>
														<Button
															className="btn btn-md btn-primary"
															onClick={(event) =>
																setActiveTabChange('members')
															}
														>
															Join the Community
														</Button>
														<>
															{alert && (
																<div className="mt-4">
																	<div className="col-span-8">
																		<Alert
																			key={alert.variant}
																			variant={alert.variant}
																			className={
																				'custom-alert ' +
																				(alert.variant ===
																				'danger'
																					? 'custom-alert-danger'
																					: 'custom-alert-success')
																			}
																		>
																			{alert.message}
																		</Alert>
																	</div>
																</div>
															)}
														</>
														<div className="input-group-com input-with-btn-subscribe mt-8 mb-8">
															<Form.Item
																label="Email"
																name="email"
																className="mb-0 hidden-label"
																rules={[
																	{ required: false },
																	{
																		validator:
																		CustomValidators.email
																	}
																]}
															>
																<Input
																	onFocus={hideTooltip}
																	onBlur={showTooltip}
																	placeholder="Enter email to subscribe to newsletter."
																	className="input-md body-txtitalic--smallest color-light"
																	disabled={submittedSubscription}
																/>
															</Form.Item>
															<Form.Item>
																<Button
																	className="btn btn-secondary btn-md btn-subscribe"
																	htmlType="submit"
																	disabled={submittedSubscription}
																>
																	{submittedSubscription && (
																		<i className="fa-solid fa-spinner fa-spin me-2"></i>
																	)}
																	Subscribe
																</Button>
															</Form.Item>
														</div>
													</Form>
												</>
											)}
										</div>
									</div>
								</div>
								{spaceData && onlyThreeSpaceMembershipsAndItems && onlyThreeSpaceMembershipsAndItems.length > 0 && (
									<div className="mt-5">
										<div className="w-full grid grid-cols-12 items-center">
											<h4 className="leading-tight header-large  col-span-12 md:col-span-10 flex  justify-self-center md:justify-self-start">
												<span className="color-reg">Join</span>&nbsp;Community
											</h4>
											{spaceData &&
												spaceMembershipsAndItems &&
												spaceMembershipsAndItems.length > 3 && (
													<>
														<Link
															className="header-title gradient-color-txt
																	 md:mt-0  col-span-12 md:col-span-2
																	flex  justify-self-center md:justify-self-end"
															onClick={(event) =>
																setActiveTabChange('members')
															}
														>
															View All
														</Link>
													</>
												)}
										</div>
										<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5">
											<>
												{onlyThreeSpaceMembershipsAndItems.slice(0, 2).map((data, index) => (
													<div className="row-span-5 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
														<Communities
															key={index}
															data={data}
															user={authUserInfo}
															spaceData={spaceData}
															updateGlobalActiveTab={
																updateGlobalActiveTab
															}
														></Communities>
													</div>
												))}

												{onlyThreeSpaceMembershipsAndItems.map((data, index) =>
													<div className="row-span-5 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0 mb-8">
														<Communities
															key={index}
															data={data}
															user={authUserInfo}
															spaceData={spaceData}
															updateGlobalActiveTab={
																updateGlobalActiveTab
															}
														></Communities>
													</div>
												)}
											</>
										</div>
									</div>
								)}

								{spaceData && latestUpdates.length > 0 && (
									<div className="mt-5">
										<div className="w-full grid grid-cols-12 items-center mb-8">
											<h4 className="leading-tight header-large  col-span-12 md:col-span-10 flex  justify-self-center md:justify-self-start">
												<span className="color-reg">Latest</span>&nbsp;Updates
											</h4>
											{spaceData && latestUpdatesCount > 3 && (
												<Link
													className="header-title gradient-color-txt
																	 md:mt-0  col-span-12 md:col-span-2
																	flex  justify-self-center md:justify-self-end"
													onClick={(event) => setActiveTabChange('updates')}
												>
													View All
												</Link>
											)}
										</div>
										<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5">
											{latestUpdates.slice(0, 2).map((data, index) => (
												<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
													<LatestUpdates
														key={index}
														data={data}
														user={authUserInfo}
														spaceData={spaceData}
														updatesViewingUserIsMember={
															updatesViewingUserIsMember
														}
														updateGlobalActiveTab={
															updateGlobalActiveTab
														}
													></LatestUpdates>
												</div>
											))}
											{latestUpdates.map((data, index) =>
												<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0">
													<LatestUpdates
														key={index}
														data={data}
														user={authUserInfo}
														spaceData={spaceData}
														updatesViewingUserIsMember={
															updatesViewingUserIsMember
														}
														updateGlobalActiveTab={
															updateGlobalActiveTab
														}
													></LatestUpdates>
												</div>
											)}
										</div>
									</div>
								)}

								{spaceData && benefits && benefits.length > 0 && (
									<div className="mt-5">
										<div className="w-full grid grid-cols-12 items-center">
											<h4 className="leading-tight header-large  col-span-12 md:col-span-10 flex  justify-self-center md:justify-self-start">
												<span className="color-reg">Member</span>&nbsp;Benefits
											</h4>
											{spaceData && benefitsCount > 3 && (
												<Link
													className="header-title gradient-color-txt
																	 md:mt-0  col-span-12 md:col-span-2
																	flex  justify-self-center md:justify-self-end"
													href=""
													onClick={(event) => setActiveTabChange('benefits')}
												>
													View All
												</Link>
											)}
										</div>
										<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5">
											{benefits.slice(0, 2).map((data, index) => (
												<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
													<MemberBenefits
														key={index}
														data={data}
														user={authUserInfo}
														spaceData={spaceData}
													></MemberBenefits>
												</div>
											))}

											{benefits.map((data, index) =>
												<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0">
													<MemberBenefits
														key={index}
														data={data}
														user={authUserInfo}
														spaceData={spaceData}
													></MemberBenefits>
												</div>

											)}
										</div>
									</div>
								)}

								{spaceData && featuredLinks.length > 0 && (
									<div className="mt-5">
										<div className="w-full grid grid-cols-12 items-center">
											<h4 className="leading-tight header-large  col-span-12 md:col-span-10 flex  justify-self-center md:justify-self-start">
												{spaceData && spaceData.link_title_first ? (
													<>
													<span className="color-reg">
														{spaceData.link_title_first}
													</span>{' '}
														{spaceData.link_title_second}
													</>
												) : (
													<>
														<span className="color-reg">Featured</span>{' '}
														Links
													</>
												)}
											</h4>
										</div>
										<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5  ">
											<>
												{featuredLinks.slice(0, 2).map((data, index) => (
													<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4  lg:hidden xsm:hidden md:inline">
														<FeaturedLinks
															key={index}
															data={data}
															index={index}
														></FeaturedLinks>
													</div>
												))}
												{featuredLinks.map((data, index) =>
													<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 lg:inline md:hidden p-0">
														<FeaturedLinks
															key={index}
															data={data}
															index={index}
														></FeaturedLinks>
													</div>
												)}
											</>
										</div>
									</div>
								)}

							</div>
						</div>
						<div
							className={
								'tab-pane ' + (globalActiveTab === 'updates' ? 'show active' : 'hidden')
							}
						>
							<div className="container">
								<>
									<div className="grid grid-cols-12 items-center">
										<h4 className="leading-tight header-large col-span-12 lg:col-span-7 flex justify-center lg:justify-start">
											<span className="color-reg">Latest</span>&nbsp;Updates
										</h4>
										<div className="col-span-12 lg:col-span-5 lg:justify-end grid grid-cols-12 place-content-center lg:place-content-end gap-8 lg:gap-0 xsm:mt-5 lg:mt-0">
											<Link className="header-title gradient-color-txt col-span-12 lg:col-span-9 flex items-center justify-center lg:justify-end" to={'#'}>
												All Categories
												<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
											</Link>
											<Link className="header-title gradient-color-txt col-span-12 lg:col-span-3 flex items-center justify-center lg:justify-end" to={'#'}>
												Newest
												<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
											</Link>
										</div>
									</div>
									{spaceUpdatesIsFetching ? (
										<div className="loading-items">
											<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
										</div>
									) : (
										<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5 ">
											{spaceData &&
												spaceUpdates &&
												spaceUpdates.length > 0 && (
													<>
														{spaceUpdates.map((data, index) => (
															<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 p-0">
															<LatestUpdates
																key={index}
																data={data}
																user={authUserInfo}
																updatesViewingUserIsMember={
																	updatesViewingUserIsMember
																}
																updateGlobalActiveTab={
																	updateGlobalActiveTab
																}
															></LatestUpdates>
															</div>
														))}
													</>
												)}
										</div>
									)}
								</>
							</div>
						</div>
						<div
							className={
								'tab-pane ' + (globalActiveTab === 'benefits' ? 'show active' : 'hidden')
							}
						>
							<div className="container">
								<>
									<div className="grid grid-cols-12 items-center">
										<h4 className="leading-tight header-large col-span-12 lg:col-span-5 flex justify-center lg:justify-start">
											<span className="color-reg">Member</span>&nbsp;Benefits
										</h4>
										<div className="col-span-12 lg:col-span-7 lg:justify-end grid grid-cols-12 place-content-center gap-8 lg:mt-0 lg:flex lg:justify-items-end xsm:mt-5">
											<a className="header-title gradient-color-txt col-span-12 lg:col-span-3 flex items-center justify-center lg:justify-end" href="">
												All Membership
												<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
											</a>
											<a className="header-title gradient-color-txt col-span-12 lg:col-span-3 flex items-center justify-center lg:justify-end" href="">
												All Categories
												<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
											</a>
											<a className="header-title gradient-color-txt col-span-12 lg:col-span-2 flex items-center justify-center lg:justify-end" href="">
												Newest
												<i className="fa-regular fa-angle-down ms-2 flex items-center"></i>
											</a>
										</div>
									</div>
									<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5 ">
										{spaceData && benefits && benefits.length > 0 && (
											<>
												{benefits.map((data, index) => (
													<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 p-0">
														<MemberBenefits
															key={index}
															data={data}
															user={authUserInfo}
															spaceData={spaceData}
														></MemberBenefits>
													</div>
												))}
											</>
										)}
									</div>
									{/*{spaceData && benefits && benefits.length > 0 && (*/}
									{/*	<>*/}
									{/*		{benefits.map((data, index) => (*/}
									{/*			<Benefits*/}
									{/*				key={index}*/}
									{/*				data={data}*/}
									{/*				user={authUserInfo}*/}
									{/*			></Benefits>*/}
									{/*		))}*/}
									{/*	</>*/}
									{/*)}*/}
								</>
							</div>
						</div>
						{/*Members Tab*/}
						<div
							className={
								'tab-pane ' + (globalActiveTab === 'members' ? 'show active' : 'hidden')
							}
						>
							<div className="container">
								<>
									<div className="grid grid-cols-12 items-center">
										<h4 className="leading-tight header-large  col-span-12 md:col-span-10 flex  justify-self-center md:justify-self-start">
											<span className="color-reg">Join</span>&nbsp;Community
										</h4>
										{/*<div>*/}
										{/*	{spaceData &&*/}
										{/*		spaceMembershipsAndItems &&*/}
										{/*		spaceMembershipsAndItems.length > 3 && (*/}
										{/*			<>*/}
										{/*				<Link*/}
										{/*					className="header-title gradient-color-txt"*/}
										{/*					href=""*/}
										{/*				>*/}
										{/*					View All*/}
										{/*				</Link>*/}
										{/*			</>*/}
										{/*		)}*/}
										{/*</div>*/}
									</div>
									<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5">
										{spaceData &&
											spaceMembershipsAndItems &&
											spaceMembershipsAndItems.length > 0 && (
												<>
													{spaceMembershipsAndItems.map((data, index) => (
														<div className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 p-0">
														<Communities
															key={index}
															data={data}
															user={authUserInfo}
															spaceData={spaceData}
															updateGlobalActiveTab={
																updateGlobalActiveTab
															}
														></Communities>
														</div>
													))}
												</>
											)
										}
									</div>
								</>
							</div>
						</div>
						{/*<div*/}
						{/*    className={*/}
						{/*        'tab-pane ' + (globalActiveTab === 'support' ? 'show active' : '')*/}
						{/*    }*/}
						{/*>*/}
						{/*    {globalActiveTab === 'support' && (*/}
						{/*        <div className="container">*/}
						{/*            <SpaceSupport spaceData={spaceData} isPro={false}/>*/}
						{/*        </div>*/}
						{/*    )}*/}
						{/*</div>*/}

						<div
							className={
								'tab-pane ' +
								(globalActiveTab === 'faq' ||
								globalActiveTab === 'about' ||
								globalActiveTab === 'about#contact' ||
								globalActiveTab === 'support' ||
								globalActiveTab === 'privacy-policy' ||
								globalActiveTab === 'terms-services'
									? 'show active'
									: '')
							}
						>
							{(globalActiveTab === 'faq' ||
								globalActiveTab === 'about' ||
								globalActiveTab === 'about#contact' ||
								globalActiveTab === 'support' ||
								globalActiveTab === 'privacy-policy' ||
								globalActiveTab === 'terms-services') && (
								<div className={'container'}>
									<SpaceInformationPages
										spaceData={spaceData}
										isPro={false}
										activeTab={globalActiveTab}
									/>
								</div>
							)}
						</div>
						{/*<div*/}
						{/*    className={*/}
						{/*        'tab-pane ' + (globalActiveTab === 'about' ? 'show active' : '')*/}
						{/*    }*/}
						{/*>*/}
						{/*    {globalActiveTab === 'about' && (*/}
						{/*        <div className="container">*/}
						{/*            <SpaceAbout spaceData={spaceData} isPro={false}/>*/}
						{/*        </div>*/}
						{/*    )}*/}
						{/*</div>*/}

						{/*<div*/}
						{/*    className={*/}
						{/*        'tab-pane ' + (globalActiveTab === 'faq' ? 'show active' : '')*/}
						{/*    }*/}
						{/*>*/}
						{/*    {globalActiveTab === 'faq' && (*/}
						{/*        <div className="container">*/}
						{/*            <SpaceFAQ spaceData={spaceData} isPro={false}/>*/}
						{/*        </div>*/}
						{/*    )}*/}
						{/*</div>*/}

						{/*<div*/}
						{/*    className={*/}
						{/*        'tab-pane ' +*/}
						{/*        (globalActiveTab === 'terms-services' ? 'show active' : '')*/}
						{/*    }*/}
						{/*>*/}
						{/*    {globalActiveTab === 'terms-services' && (*/}
						{/*        <div className="container">*/}
						{/*            <SpaceTermsServices spaceData={spaceData} isPro={false}/>*/}
						{/*        </div>*/}
						{/*    )}*/}
						{/*</div>*/}

						{/*<div*/}
						{/*    className={*/}
						{/*        'tab-pane ' +*/}
						{/*        (globalActiveTab === 'privacy-policy' ? 'show active' : '')*/}
						{/*    }*/}
						{/*>*/}
						{/*    {globalActiveTab === 'privacy-policy' && (*/}
						{/*        <div className="container">*/}
						{/*            <SpacePrivacyPolicy spaceData={spaceData} isPro={false}/>*/}
						{/*        </div>*/}
						{/*    )}*/}
						{/*</div>*/}
						<div
							className={
								'tab-pane ' +
								(globalActiveTab === 'claim_membership' ? 'show active' : 'hidden')
							}
						>
							{globalActiveTab === 'claim_membership' && (
								<SpaceClaimMembership hasGradient={false} />
							)}
						</div>
						<div
							className={
								'tab-pane ' +
								(globalActiveTab === 'buy_membership' ? 'show active' : 'hidden')
							}
						>
							{globalActiveTab === 'buy_membership' && (
								<SpaceBuyMembership hasGradient={false}></SpaceBuyMembership>
							)}
						</div>
						<div
							className={
								'tab-pane ' + (globalActiveTab === 'buy_item' ? 'show active' : 'hidden')
							}
						>
							{globalActiveTab === 'buy_item' && (
								<SpaceBuyItem hasGradient={false}></SpaceBuyItem>
							)}
						</div>
						<div
							className={
								'tab-pane ' +
								(globalActiveTab === 'claim_item' ? 'show active' : 'hidden')
							}
						>
							{globalActiveTab === 'claim_item' && (
								<SpaceClaimItem hasGradient={false} />
							)}
						</div>
						<div
							className={
								'tab-pane ' +
								(globalActiveTab === 'update_content' ? 'show active' : 'hidden')
							}
						>
							{globalActiveTab === 'update_content' && (
								<>
									<UpdatesContent
										updatesViewingUserIsMember={updatesViewingUserIsMember}
									/>
								</>
							)}
						</div>
					</div>
				</div>
				{(globalActiveTab === 'about' ||
					globalActiveTab === 'about#contact' ||
					globalActiveTab === 'terms-services' ||
					globalActiveTab === 'privacy-policy') && (
						<div className="container">
							<section id="contacts">
								<SpaceContact space_info_slug={'about' + spaceData.id} spaceData={spaceData} />
							</section>
						</div>
				)}
				<div
					className={
						'tab-pane ' +
						(globalActiveTab === 'channel'									
							? 'show active'
							: '')
					}
				>
					{(globalActiveTab === 'channel' ) && (
						<div className="bg-transparent">
							<div className={'container'}>
								<Channel spaceData={spaceData}/>
							</div>
						</div>
					)}
				</div>
			</main>
		</>
	);
};
export default memo(SpaceView);
