export const NOTIFICATION_PREFERENCES = [
	{
		label: 'On',
		value: 'on',
		icon: 'fa-light fa-envelopes'
	},
	{
		label: 'Off',
		value: 'off',
		icon: 'fa-light fa-message-xmark'
	},
	{
		label: 'Default Settings',
		value: 'default_settings',
		icon: 'fa-light fa-cog'
	},
	{
		label: 'Daily Summary',
		value: 'daily_summary',
		icon: 'fa-light fa-envelope'
	},
	{
		label: 'Weekly Summary',
		value: 'weekly_digest',
		icon: 'fa-light fa-rectangle-list'
	}
];

export const NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT = NOTIFICATION_PREFERENCES;
if (!NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT.find((x) => x.value === 'default')) {
	NOTIFICATION_PREFERENCES_LIST_WITH_DEFAULT.unshift({
		label: 'Preference',
		value: 'default',
		icon: 'fa-light fa-bell',
		disabled: true
	});
}

export const TRANSACTIONS_DAYS = [
	{
		label: 'Past 30 Days',
		value: '30'
	},
	{
		label: 'This week',
		value: '7'
	},
	{
		label: 'Today',
		value: '1'
	}
];

export const TRANSACTIONS_CURRENCIES = [
	{
		label: 'USD',
		value: 'usd'
	},
	{
		label: 'YEN',
		value: 'yen'
	},
	{
		label: 'EUR',
		value: 'eur'
	}
];
