import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import { Button, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import ReactQuill from 'react-quill';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	UPDATE_AUTHORS_LIST_WITH_DEFAULT,
	UPDATE_COMMENTS_AVAILABILITY,
	UPDATE_PUBLISH_STATUSES,
	UPDATES_VISIBILITY
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { getListIcon } from '../../../../utils/list';
import {
	createSpaceAddFirstUpdate,
	createSpacePostUpdate,
	getSpaceCategories
} from '../../../../services/space';
import { getSpaceInfo } from '../../../../states/spaceGlobal/actions';
import routes from '../../../../routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './CreateUpdate.scss';
import Alert from 'react-bootstrap/Alert';
import AddNewCategoryModal from '../../../../components/commons/Modals/AddNewCategory/AddNewCategoryModal';
import dayjs from 'dayjs';
import moment from 'moment';
import ExplicitContent from '../../../../components/commons/Modals/ExplicitContent';
import TextareaAutosize from 'react-textarea-autosize';

const CreateUpdate = (spaceID) => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createUpdateForm] = Form.useForm();
	const [content, setContent] = useState('');
	const [selectedAuthor, setSelectedAuthor] = useState('default');
	const [selectedCommentAvailability, setSelectedCommentAvailability] = useState('enabled');
	const [selectedPublishStatus, setSelectedPublishStatus] = useState('published');
	const [selectedUpdatesVisibility, setSelectedUpdatesVisibility] = useState('members');
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [alert, setAlert] = useState(null);
	const [publicCoverAsRequired, setPublicCoverAsRequired] = useState(false);
	const [coverImageError, setCoverImageError] = useState(null);
	const [publicCoverImageError, setPublicCoverImageError] = useState(null);
	const [coverImage, setCoverImage] = useState(null);
	const [coverImageBlobPreview, setCoverImageBlobPreview] = useState(null);
	const [coverVideo, setCoverVideo] = useState(null);
	const [coverVideoBlobPreview, setCoverVideoBlobPreview] = useState(null);
	const [publicCoverImage, setPublicCoverImage] = useState(null);
	const [publicCoverImageBlobPreview, setPublicCoverImageBlobPreview] = useState(null);
	const [publicCoverVideo, setPublicCoverVideo] = useState(null);
	const [publicCoverVideoBlobPreview, setPublicCoverVideoBlobPreview] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [categoryList, setCategoryList] = useState([]);
	const [filteredCategoryList, setFilteredCategoryList] = useState([
		{ label: 'Create New Category', value: 'create' }
	]);
	const [selectedCategory, setSelectedCategory] = useState('create');
	const [selectedCategoryIDS, setSelectedCategoryIDS] = useState([]);
	const [submitType, setSubmitType] = useState('');
	const [isUpdateCategory, setUpdateCategory] = useState(true);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);
	const [showPublicFields, setShowPublicFields] = useState(true);
	const [coverImageIsRemoved, setCoverImageIsRemoved] = useState(true);

	const checkPublicFieldsIfPopulated = () => {
		setPublicCoverAsRequired(false);

		if (
			createUpdateForm.getFieldValue('public_title') ||
			createUpdateForm.getFieldValue('public_preview_text')
		) {
			setPublicCoverAsRequired(true);
		}
	};

	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
		[{ font: [] }],
		[{ header: 1 }, { header: 2 }], // custom button values
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		['blockquote', 'code-block'],
		['link', 'image', 'video', 'formula'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
		[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
		[{ direction: 'rtl' }], // text direction
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],
		['clean'] // remove formatting button
	];

	const handleCoverImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setCoverImageIsRemoved(true);
		} else {
			setCoverImageIsRemoved(false);
		}
		setCoverImage(file);
		setCoverImageBlobPreview(blobPreview);
	};

	const handleCoverVideoUploadFileChange = (file, blobPreview) => {
		setCoverVideo(file);
		setCoverVideoBlobPreview(blobPreview);
	};

	const handlePublicCoverImageUploadFileChange = (file, blobPreview) => {
		setPublicCoverImage(file);
		setPublicCoverImageBlobPreview(blobPreview);
	};

	const handlePublicCoverVideoUploadFileChange = (file, blobPreview) => {
		setPublicCoverVideo(file);
		setPublicCoverVideoBlobPreview(blobPreview);
	};

	const handleFormOnBlur = useCallback(async () => {
		await createUpdateForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});

		checkPublicFieldsIfPopulated();
	}, []);

	const handleFormChanges = useCallback(async () => {
		await createUpdateForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});

		checkPublicFieldsIfPopulated();
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setDisplayDropDownError(true);
			setCoverImageError(null);
			setPublicCoverImageError(null);

			let hasError = false;

			if (
				!content ||
				selectedAuthor === 'default' ||
				selectedContentType === 'default' ||
				selectedAccessType === 'default' ||
				selectedCategory === 'create'
			) {
				hasError = true;
			}

			if (!coverImageBlobPreview) {
				setCoverImageError('Cover Image is required.');
				hasError = true;
			}

			if (
				(values.public_title || values.public_preview_text) &&
				!publicCoverImageBlobPreview
			) {
				setPublicCoverImageError('Public Cover Image is required.');
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = {};
			data.visibility = selectedUpdatesVisibility;
			data.title = values.title;
			data.public_title = values.public_title;
			data.preview_text = values.preview_text;
			data.public_preview_text = values.public_preview_text;
			data.tags = values.tags;
			data.author = selectedAuthor;
			data.comments_availability = selectedCommentAvailability;
			data.publish_status = selectedPublishStatus;
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (content) {
				data.content = content;
			}
			if (coverImage) {
				data.cover_image = coverImage;
			}
			if (coverVideo) {
				data.cover_video = coverVideo;
			}
			if (publicCoverImage) {
				data.public_cover_image = publicCoverImage;
			}
			if (publicCoverVideo) {
				data.public_cover_video = publicCoverVideo;
			}

			// sample payload for adding category to space update
			data.category_ids = JSON.stringify(selectedCategoryIDS);

			if (submitType !== 'save') {
				data.publish_status = submitType;
			}

			if (data.publish_status === 'publishing') {
				// add date with 7 days advanced as sample publish date
				data.publish_date = moment().add(7, 'd').format('YYYY-MM-DD');
			}

			// sample payload for adding category to space update
			data.category_ids = JSON.stringify(selectedCategoryIDS);

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					setSubmitted(true);

					const response = await createSpacePostUpdate(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});
						window.scrollTo(0, 0);
						setSubmitType('');
						setSubmitted(false);
						alertTimeOut();

						setTimeout(() => {
							let urlRoute = routes.spaceUpdates();
							urlRoute = urlRoute.replace(':space_username', space_username);
							navigate(urlRoute);
						}, 1500);
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						window.scrollTo(0, 0);
						setSubmitType('');
						setSubmitted(false);
						alertTimeOut();
					}
					setCoverImageIsRemoved(false);
					setDisplayDropDownError(false);
				}
			} catch (error) {
				console.log(error);
				setSubmitType('');
				setSubmitted(false);
			}
		},
		[
			space_username,
			authUserInfo,
			spaceInfo,
			selectedUpdatesVisibility,
			content,
			coverImage,
			coverVideo,
			publicCoverImage,
			publicCoverVideo,
			selectedAuthor,
			selectedCommentAvailability,
			selectedPublishStatus,
			selectedCategory,
			selectedContentType,
			selectedAccessType,
			submitType,
			selectedExplicitAccess,
			selectedExplicitContent,
			coverImageBlobPreview,
			publicCoverImageBlobPreview,
			coverImageIsRemoved
		]
	);

	const filterDropdownChange = useCallback(
		async (value) => {
			setSelectedCategory(value);
			if (value === 'create') {
				setModalOpen(true);
			} else {
				if (categoryList != null && categoryList.length > 0) {
					const selectedCategory = categoryList.find((category) => category.slug === value);
					if (selectedCategory) {
						setSelectedCategoryIDS([selectedCategory.id]);
					}
					// TODO: once multi categories is implemented use code below
					// const isIdExist = selectedCategoryIDS.some((id) => id === selectedCategory.id);
					// if (!isIdExist) {
					// 	selectedCategoryIDS.push(selectedCategory.id);
					// 	setSelectedCategoryIDS(selectedCategoryIDS);
					// }
				}
			}
		},
		[modalOpen, selectedCategory, selectedCategoryIDS, categoryList]
	);

	const alertTimeOut = () => {
		setTimeout(() => {
			setAlert(null);
		}, 4000);
	};

	// const parseFilteredAndSelectedCategory = async () => {
	// 	if (categoryList != null && loadOnceFilteredCategory) {
	// 		categoryList.map((category) => {
	// 			// console.log('selectedCategory----: '+selectedCategory)
	// 			// console.log('category.name----: '+category.name)
	// 			if (category.name === selectedCategory) {
	// 				// console.log('selectedCategory----: '+selectedCategory)
	// 				setSelectedCategoryIDS([category.id]);
	// 				// const isIdExist = selectedCategoryIDS.some((id) => id === category.id);
	// 				// if (!isIdExist) {
	// 				// 	selectedCategoryIDS.push(category.id);
	// 				// 	setSelectedCategoryIDS(selectedCategoryIDS);
	// 				// }
	// 			}
	// 				if (!filteredCategoryList.some(({ label }) => label === category.name)) {
	// 					filteredCategoryList.push({ label: category.name, value: category.slug });
	// 				}
	// 		});
	// 		setLoadOnceFilteredCategory(false);
	// 		setFilteredCategoryList(filteredCategoryList);
	// 	}
	// };

	const selectUpdateVisibility = (value) => {
		setSelectedUpdatesVisibility(value);

		if (value === 'members') {
			setShowPublicFields(true);
		} else {
			setShowPublicFields(false);
		}
	};

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	const redirectToSpaceLink = (route, spaceUsername) => {
		if (spaceUsername) {
			const urlRoute = routes.spaceUpdates();
			return urlRoute.replace(':space_username', '@' + spaceUsername);
		}
		return '';
	};

	useEffect(() => {
		if (spaceInfo && spaceInfo.id && isUpdateCategory) {
			setUpdateCategory(false);
			getSpaceCategories({ space_id: spaceInfo.id }).then((response) => {
				if (response && response.result) {
					const categories = response.categories;
					setCategoryList(categories);
					if (categories && categories.length > 0) {
						// Get the selected category ID
						const matchCategory = categories.find((category) => category.slug === selectedCategory);
						if (matchCategory) {
							setSelectedCategoryIDS([matchCategory.id]);
						}
						// Filter the categories for the dropdown component
						const filteredCategories = categories.reduce((acc, category) => {
							if (!acc.some(({label}) => label === category.name)) {
								acc.push({label: category.name, value: category.slug});
							}
							return acc;
						}, []);
						filteredCategories.unshift( { label: 'Create New Category', value: 'create' })
						setFilteredCategoryList(filteredCategories);
					}
				}
			});
		}
	}, [
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		modalOpen,
		categoryList,
		filteredCategoryList,
		selectedCategoryIDS,
		selectedCategory,
		isUpdateCategory,
		submitType
	]);

	useEffect(() => {}, [coverImageError, publicCoverImageError, publicCoverAsRequired, categoryList]);

	return (
		<>
			<div className="manage-updates">
				<div className="dashboard-wrapper">
					<div className="grid grid-cols-12 gap-8">
						<div className="col-span-12">
							<h3 className="header-medium">Create New Update</h3>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-8 mt-5">
						<div className="col-span-12">
							<p className="body-text--small">
								To create a new Update post, first{' '}
								<a className="body-txtitalic--small gradient-color-txt">
									Select Visibility
								</a>{' '}
								to enable the right fields. Members only Updates can have an
								alternate Public Title, Preview Text and Cover Image/Video. This
								also improves your{' '}
								<a className="body-txtitalic--small gradient-color-txt">
									SEO and search engine traffic.
								</a>
							</p>
						</div>
					</div>
					<Form
						form={createUpdateForm}
						name="createUpdateForm"
						id="createUpdateForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
							setTimeout(() => {
								setFormIsOnFocus(false);
								handleFormOnBlur();
							}, 200);
						}}
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
					>
						<>
							{alert && (
								<div className="grid grid-cols-12 gap-8 mt-5">
									<div className="col-span-8">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								</div>
							)}
						</>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<label className="body-text--small gradient-color-txt">
									Title*
								</label>
								<div className="input-group-com w-full mt-2">
									<Form.Item
										label="Title"
										name="title"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<Input
											maxLength={32}
											placeholder="Add Title. Up to 32 characters, including spaces"
											className="input-md body-text--small color-reg ps-4"
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-4">
								<h5 className="header-small m-0">Settings</h5>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								{showPublicFields && (
									<>
										<label className="body-text--small gradient-color-txt">
											Public Title
										</label>
										<div className="input-group-com w-full mt-2">
											<Form.Item
												label="Public Title"
												name="public_title"
												className="mb-0 hidden-label"
											>
												<Input
													maxLength={32}
													placeholder="Add alternate title for public viewers (optional) up to 32 characters, including spaces."
													className="input-md body-text--small color-reg ps-4"
												/>
											</Form.Item>
										</div>
									</>
								)}
							</div>
							<div className="col-span-4">
								<label className="body-text--small gradient-color-txt">
									Visibility*
								</label>
								<DropdownWithItems
									title="Update Visibility"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2 btn-size"
									displayError={
										displayDropDownError &&
										selectedUpdatesVisibility === 'default'
									}
									selectedValue={selectedUpdatesVisibility}
									listItems={UPDATES_VISIBILITY}
									onDropdownChange={(value) => selectUpdateVisibility(value)}
								/>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<label className="body-text--small gradient-color-txt">
									Preview Text*
								</label>
								<div className="input-group-com w-full mt-2">
									<Form.Item
										label="Preview Text"
										name="preview_text"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<TextareaAutosize
											minRows={1}
											placeholder="Add preview text, up to 255 characters including spaces."
											className="input-md body-text--small"
											maxLength="255"
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-4">
								<label className="body-text--small gradient-color-txt">
									Publish Status*
								</label>
								<DropdownWithItems
									title="Publish Status"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2 btn-size btn-size"
									displayError={
										displayDropDownError && selectedPublishStatus === 'default'
									}
									selectedValue={selectedPublishStatus}
									listItems={UPDATE_PUBLISH_STATUSES}
									onDropdownChange={(value) => setSelectedPublishStatus(value)}
								/>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								{showPublicFields && (
									<>
										<label className="body-text--small gradient-color-txt">
											Public Preview Text
										</label>
										<div className="input-group-com w-full mt-2">
											<Form.Item
												label="Public Preview Text"
												name="public_preview_text"
												className="mb-0 hidden-label"
											>
												<TextareaAutosize
													placeholder="Add alternate preview text for public viewers (optional)"
													className="input-md body-text--small"
													maxLength="255"
												/>
											</Form.Item>
										</div>
									</>
								)}
							</div>
							<div className="col-span-4">
								<label className="body-text--small gradient-color-txt">
									Author*
								</label>
								<DropdownWithItems
									title="Author"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2 btn-size"
									displayError={
										displayDropDownError && selectedAuthor === 'default'
									}
									selectedValue={selectedAuthor}
									listItems={UPDATE_AUTHORS_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => setSelectedAuthor(value)}
								/>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-5">
							<div className={'col-span-8 text-editor-area'}>
								<Form.Item
									name="content"
									label="content"
									className="mb-0 hidden-label"
									validateTrigger="onBlur"
									rules={[
										{
											required: true,
											message: 'Content is Required.'
										}
									]}
								>
									<ReactQuill
										theme="snow"
										modules={{
											toolbar: toolbarOptions
										}}
										value={content}
										onChange={(e) => {
											if ('<p><br></p>' === e) {
												createUpdateForm.setFieldValue('content', '');
												setContent(null);
											} else {
												setContent(e);
											}

											handleFormChanges();
										}}
									/>
								</Form.Item>
							</div>
							<div className="col-span-4">
								<label className="body-text--small gradient-color-txt">
									Comments
								</label>
								<DropdownWithItems
									title="Comments"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2 mb-4 btn-size"
									selectedValue={selectedCommentAvailability}
									listItems={UPDATE_COMMENTS_AVAILABILITY}
									onDropdownChange={(value) =>
										setSelectedCommentAvailability(value)
									}
								/>
								<h5 className="header-small pt-5 mt-5">Labeling</h5>

								<label className="body-text--small gradient-color-txt mt-5 mb-2 ">
									Category*
								</label>

								{spaceInfo != null && authUserInfo != null && (
									<AddNewCategoryModal
										modalOpen={modalOpen}
										setModalOpen={setModalOpen}
										filteredCategoryList={filteredCategoryList}
										setSelectedCategory={setSelectedCategory}
										spaceID={spaceInfo.id}
										userID={authUserInfo.id}
										setUpdateCategory={setUpdateCategory}
									/>
								)}
								<DropdownWithItems
									title="Category"
									dropDownClass="dropdown-bg"
									customButtonClass="btn-md btn-secondary mt-2 btn-size"
									displayError={
										displayDropDownError &&
										selectedCategory === 'create'
									}
									selectedValue={selectedCategory}
									listItems={filteredCategoryList}
									onDropdownChange={(value) =>
										filterDropdownChange(value)
									}
								/>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-5">
							<>
								{coverImageError && coverImageIsRemoved && (
									<div className="col-span-4">
										<Alert
											// key={alert.variant}
											// variant={alert.variant}
											className='custom-alert custom-alert-danger cover-image-error'
										>
											{coverImageError}
										</Alert>
									</div>
								)}
							</>
							<div className="col-span-4">
								<div className="upload-rec">
									<UploadDragger
										//passedError={coverImageError}
										cropperAspectRatio={16 / 9}
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={IMAGE_FILETYPES.toString()}
										uploadedFilePreview={coverImageBlobPreview}
										onUploadFileChange={handleCoverImageUploadFileChange}
										minHeight={IMAGE_STANDARD_MIN_HEIGHT}
										minWidth={IMAGE_STANDARD_MIN_WIDTH}
										maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
										maxWidth={IMAGE_STANDARD_MAX_WIDTH}
										uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
										disableCropper={true}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Cover Image*
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 3840px square or wide. <br />
									JPEG, PNG, or GIF
								</p>
							</div>
							<div className="col-span-4">
								<div className="upload-rec">
									<UploadDragger
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={VIDEO_FILETYPES.toString()}
										uploadedFilePreview={coverVideoBlobPreview}
										onUploadFileChange={handleCoverVideoUploadFileChange}
										minHeight={VIDEO_STANDARD_MIN_HEIGHT}
										minWidth={VIDEO_STANDARD_MIN_WIDTH}
										maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
										maxWidth={VIDEO_STANDARD_MAX_WIDTH}
										uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Cover Video
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 1920px/1080px, square or wide. <br />
									MOV or MP4
								</p>
							</div>
							<div className="col-span-4">
								<label className="body-text--small gradient-color-txt">Tags</label>
								<div className="input-group-com w-full mt-2">
									<Form.Item
										label="Tags"
										name="tags"
										className="mb-0 hidden-label"
									>
										<Input
											placeholder="Start typing to add or select tags."
											className="input-md body-text--small color-reg ps-4"
										/>
									</Form.Item>
								</div>
								<label className="body-text--small gradient-color-txt mt-5">
									Explicit Content*
								</label>
								<DropdownWithItems
									title="Select Content Type"
									dropDownClass="dropdown-bg-small"
									customButtonClass="btn-md btn-secondary mt-2 btn-size"
									displayError={
										displayDropDownError && selectedContentType === 'default'
									}
									icon={getListIcon(
										CONTENT_TYPES_LIST_WITH_DEFAULT,
										selectedContentType
									)}
									selectedValue={selectedContentType}
									listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openContentTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitContent}
									setModalOpen={setModalOpenExplicitContent}
									selectedItems={selectedExplicitContent}
									isExplicitContent={true}
									onSelectedItems={(value) => setSelectedExplicitContent(value)}
								/>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-5">
							<div className="col-span-4">
								<div className="upload-rec">
									<UploadDragger
										passedError={publicCoverImageError}
										cropperAspectRatio={16 / 9}
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={IMAGE_FILETYPES.toString()}
										uploadedFilePreview={publicCoverImageBlobPreview}
										onUploadFileChange={handlePublicCoverImageUploadFileChange}
										minHeight={IMAGE_LARGE_MIN_HEIGHT}
										minWidth={IMAGE_LARGE_MIN_WIDTH}
										maxHeight={IMAGE_LARGE_MAX_HEIGHT}
										maxWidth={IMAGE_LARGE_MAX_WIDTH}
										uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
										disableCropper={true}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Public Cover Image{publicCoverAsRequired ? '*' : ''}
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 3840px square or wide. <br />
									JPEG, PNG, or GIF
								</p>
							</div>
							<div className="col-span-4">
								<div className="upload-rec">
									<UploadDragger
										hideDragIcon="true"
										hideText="true"
										uploadFileTypes={VIDEO_FILETYPES.toString()}
										uploadedFilePreview={publicCoverVideoBlobPreview}
										onUploadFileChange={handlePublicCoverVideoUploadFileChange}
										minHeight={VIDEO_STANDARD_MIN_HEIGHT}
										minWidth={VIDEO_STANDARD_MIN_WIDTH}
										maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
										maxWidth={VIDEO_STANDARD_MAX_WIDTH}
										uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
									/>
									<i className="fa-regular fa-plus"></i>
								</div>
								<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
									Public Cover Video
								</p>
								<p className="text-center body-txtitalic--smallest">
									Up to 1920px/1080px, square or wide. <br />
									MOV or MP4
								</p>
							</div>
							<div className="col-span-4">
								<label className="body-text--small gradient-color-txt">
									Explicit Access*
								</label>
								<DropdownWithItems
									title="Select Access Type"
									dropDownClass="dropdown-bg-small"
									customButtonClass="btn-md btn-secondary mt-2 btn-size"
									displayError={
										displayDropDownError && selectedAccessType === 'default'
									}
									icon={getListIcon(
										ACCESS_TYPES_LIST_WITH_DEFAULT,
										selectedAccessType
									)}
									selectedValue={selectedAccessType}
									listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => openAccessTypeModal(value)}
								/>
								<ExplicitContent
									modalOpen={modalOpenExplicitAccess}
									setModalOpen={setModalOpenExplicitAccess}
									selectedItems={selectedExplicitAccess}
									isExplicitContent={false}
									onSelectedItems={(value) => setSelectedExplicitAccess(value)}
								/>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<Button
									onClick={() => setSubmitType('draft')}
									htmlType="submit"
									className="btn btn-primary btn-md"
									disabled={submitted || formHasError}
								>
									{submitted && submitType === 'draft' && (
										<i className="fa-solid fa-spinner fa-spin" />
									)}
									&nbsp;Save as Draft
								</Button>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest color-reg">
									Saving as a Draft then previewing your Update before publishing
									is recommended. This will also change the Publish Status setting
									to Draft.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<Button
									onClick={() => setSubmitType('save')}
									htmlType="submit"
									className="btn btn-secondary btn-md"
									disabled={submitted || formHasError}
								>
									{submitted && submitType === 'save' && (
										<i className="fa-solid fa-spinner fa-spin" />
									)}
									&nbsp;Save
								</Button>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest color-reg">
									This will save and publish your Update based on the selected
									Publish Status above. On publishing,{' '}
									<a className="body-txtitalic--small gradient-color-txt">
										Members who match the Visibility setting will be notified
										based on their own individual notification settings.
									</a>
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">
							<div className="col-span-8">
								<Button
									onClick={() => setSubmitType('published')}
									htmlType="submit"
									className="btn btn-secondary btn-md"
									disabled={true}
									// disabled={submitted || formHasError}
								>
									{submitted && submitType === 'published' && (
										<i className="fa-solid fa-spinner fa-spin" />
									)}
									&nbsp;Save & Publish Immediately as an Important Update
								</Button>
							</div>
							<div className="col-span-4">
								<p className="body-txtitalic--smallest color-reg">
									<strong>(Coming soon) </strong>
									This sends an{' '}
									<a className="body-txtitalic--small gradient-color-txt">
										Important Update Email
									</a>{' '}
									to all Members matching the Visibility setting, regardless of
									their own settings. You’ll need to enter your password to
									confirm. (<strong>4 of 4 remaining this month</strong>)
								</p>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
};

export default memo(CreateUpdate);
