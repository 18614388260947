import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import { Alchemy, Network } from 'alchemy-sdk';
import Web3 from 'web3';
import './SpaceClaimMembership.scss';

import { setLocalStorage } from '../../utils/storage';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import {
	getSpaceMembership,
} from '../../services/space';
import {
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	NODE_ENV,
	MAGIC_CONNECT_NETWORK
} from '../../constants/common';
import { getUserSpaceMembershipClaim } from '../../services/user';
import routes from '../../routes';

import default_items_img from '../../assets/images/form_background.png';
import '../SpaceView/SpaceView.scss';
import {getCommunityBtnText, getDetailsGift, getDetailsMonthlyPrice, getDetailsPrice} from "../../components/commons/helpers/MembershipItemHelpers";
import {MembershipItemUtils} from "../../utils/MembershipItemUtils";
import {checkMembershipItemStatus, createStripePaymentIntent} from "../../services/payment_method";

const SpaceClaimMembership = (props) => {
	const { hasGradient } = props;
	const currentLocation = useLocation();
	let space_username = null;
	const [searchParams, setSearchParams] = useSearchParams();
	const membershipId = searchParams.get('id');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [submitted, setSubmitted] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [membershipDetails, setMembershipDetails] = useState(null);
	const [isClaimed, setClaimed] = useState(false);
	const [fetchingCollectionData, setFetchingCollectionData] = useState(false);
	const [collectionData, setCollectionData] = useState(null);
	const [collectionAssetType, setCollectionAssetType] = useState('image');
	const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
	const [spaceName, setSpaceName] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
	const [status, setStatus] = useState('');
	const [isMembershipLoaded, setMembershipLoaded] = useState(false);

	// For testing purpose only //
	const test_public_address = '0x452d40db156034223e8865f93d6a532ae62c4a99';
	const test_contract_address = '0xb0bbbc0f43d611351b3115bd5e338f4c402ae8c2';
	// End testing data //

	const settings = {
		apiKey: ALCHEMY_API_KEY,
		network: Network.ETH_MAINNET
	};

	const alchemy = new Alchemy(settings);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const web3 = new Web3(magic.rpcProvider);

	const path = currentLocation.pathname;
	if (path && path.startsWith('/@') && path.length > 3) {
		const clean_path = path.replace('/@', '');
		const path_exp = clean_path.split('/');
		space_username = path_exp[0];
	}

	const scrollToClick = () => {
		const element = document.getElementById('details-description');
		element.scrollIntoView({ behavior: 'smooth' });
	}

	const getMembership = useCallback(async (space_id, membership_id) => {
		if (!membership_id) {
			return;
		}
		const data = {
			space_id: space_id,
			membership_id: membership_id
		};

		if (authUserInfo) {
			data['viewing_user_id'] = authUserInfo.id;
		}

		const response = await getSpaceMembership(data);
		if (response && response.result) {
			const membershipData = response.membership;
			setMembershipDetails(membershipData);
			setMembershipLoaded(true);

			if (membershipData.photo || membershipData.video) {
				if (membershipData.photo) {
					const bg = {
						backgroundImage: 'url(' + membershipData.photo + ')',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					};
					setCollectionAssetBGImage(bg);
					setCollectionAssetType('image');
				}
				if (membershipData.video) {
					setCollectionAssetType('video');
				}
			} else {
				const bg = {
					backgroundImage: 'url(' + default_items_img + ')',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover'
				};
				setCollectionAssetBGImage(bg);
				setCollectionAssetType('image');
			}

			if (membershipData) {
				//TODO-MJ: Bug will show error when the contract address is not valid
				getNFTCollectionData(membershipData).then();
			}
		}
	}, []);

	const getMembershipClaim = useCallback(async () => {
		if (!membershipId) {
			return;
		}

		const response = await getUserSpaceMembershipClaim({
			user_id: authUserInfo.id,
			space_id: spaceInfo.id,
			membership_id: membershipId
		});
		if (response && response.result) {
			setClaimed(true);
			// setMembershipClaim(response.membership_claim);
		}
	}, [authUserInfo, spaceInfo, membershipId]);

	const checkOwnerIfInCollection = useCallback(
		async (owner_contract_address, owner_email = null) => {
			let collection_contract_address = membershipDetails.collection_contract_address;

			//WE use test data on dev environment
			if (NODE_ENV === 'development') collection_contract_address = test_contract_address;

			alchemy.nft.getNftsForOwner(owner_contract_address).then((response) => {
				if (response) {
					checkAddressIfInCollection(
						owner_contract_address,
						collection_contract_address,
						response.ownedNfts,
						owner_email
					).then();
				}
			});
		},
		[authUserInfo, spaceInfo, membershipId, membershipDetails]
	);

	const checkAddressIfInCollection = useCallback(
		async (owner_contract_address, collection_address, nfts, owner_email = null) => {
			let found = false;
			nfts.forEach((nft) => {
				if (nft.contract && nft.contract.address) {
					const nftContractAddressLowerCase = nft.contract.address.toLowerCase();
					const collectionAddressLowerCase = collection_address.toLowerCase();
					if (nftContractAddressLowerCase === collectionAddressLowerCase) {
						found = true;
					}
				}
			});

			console.log('found: '+found);

			setLocalStorage('temp_membership_nft_is_owned', found);
			setLocalStorage('temp_membership_space_username', space_username);

			if (found) {
				setLocalStorage(
					'temp_membership_wallet_owner_contract_address',
					owner_contract_address
				);
				setLocalStorage('temp_membership_wallet_owner_email', owner_email);

				const isSubscription = membershipDetails.enable_expiration_and_subscription
				if (isSubscription) {
					//Goes to Payment Method layout if subscription is enabled
					let paymentMethodUrl = routes.spaceMembershipPaymentMethod();
					const payment_fin_url =
						paymentMethodUrl.replace(':space_username', '@' + space_username) + '?id=' + membershipId;
					navigate(payment_fin_url);
				} else {
					console.log('spaceMembershipWalletConnect: ');
					//Goes to Payment Wallet connect layout
					// const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
					let url = routes.spaceMembershipWalletConnect();
					//redirect to wallet connect for item when the collection is NFT and no subscription
					// if (membershipDetails.collection_type === 'membership') {
					// 	if (isSpaceMember) {
					// 		// Membership Ready
					// let url = routes.spaceMembershipActivate()
					// 		//TODO-MJ add the error layout when connecting wallet failed
					// 	} else {
					// 		// EXISTING USER/NEW TO SPACE CLAIM NOW FLOW where User
					// 		// must own an existing NFT to claim Membership/Item NO SUBSCRIPTION
					// 		url = routes.spaceMembershipWalletConnect();
					// 	}
					// }
					const fin_url = url.replace(':space_username', '@' + space_username) + '?id=' + membershipId;

					console.log(fin_url)
					navigate(fin_url);

				}
			} else {
				const url = routes.spaceMembershipErrorConnect();
				const fin_url =
					url.replace(':space_username', '@' + space_username) + '?id=' + membershipId;
				window.location.replace(fin_url);
			}
		},
		[space_username, membershipId, membershipDetails, spaceInfo, authUserInfo]
	);

	const claimMembership = useCallback(async () => {
		if (MembershipItemUtils.isOAuth(loading, authUserInfo)){
			if (spaceInfo) {
				setSubmitted(true);

				if (authUserInfo) {
					await web3Login(true);
				} else {
					await web3Login(false);
				}
			}
		} else {
			window.location.replace(routes.login());
		}

	}, [authUserInfo, spaceInfo, membershipId, membershipDetails]);

	const web3Login = async (loggedIn) => {
		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});
		setSubmitted(true);
		web3.eth
			.getAccounts()
			.then((accounts) => {
				magic.connect
					.requestUserInfo({ isResponseRequired: false })
					.then((user_account) => {
						let values = {
							email: user_account.email ?? 'no-email',
							user_public_address: accounts?.[0]
						};

						//WE use test data on dev environment
						if (NODE_ENV === 'development') {
							checkOwnerIfInCollection(test_public_address, values.email).then();
						} else {
							checkOwnerIfInCollection(
								values.user_public_address,
								values.email
							).then();
						}
					});
			})
			.catch((error) => {
				setSubmitted(false);
			});
	};

	// const joinMembership = useCallback(async () => {
	// 	if (spaceInfo) {
	// 		setSubmitted(true);
	//
	// 		if (authUserInfo) {
	// 			// TODO: Do code for join payment here
	//
	// 			if (authUserInfo.user_public_address) {
	// 				const response = await joinSpaceMembership({
	// 					user_id: authUserInfo.id,
	// 					membership_id: membershipId
	// 				});
	// 				if (response && response.result) {
	// 					getMembershipClaim().then();
	// 				}
	// 			} else {
	// 				await web3Login();
	// 			}
	//
	// 			setSubmitted(false);
	// 		} else {
	// 			await web3Login();
	// 		}
	// 	}
	// }, [authUserInfo, spaceInfo, membershipId, membershipDetails]);

	const getNFTCollectionData = useCallback(async (membershipData) => {
		if (membershipData && membershipData.collection_contract_address !== null) {
			setFetchingCollectionData(true);
			alchemy.nft
				.getContractMetadata(membershipData.collection_contract_address)
				.then((response) => {
					if (response) {
						setCollectionData(response);
						setFetchingCollectionData(false);
					}
				});
		}
	}, []);

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + space_username;
		window.location.replace(url);
		// navigate(url);
	}, [space_username]);

	const checkStatus = useCallback(async (space_id, membership_id) => {
		if (!membership_id) return;
		const data = {
			space_id: space_id,
			membership_id: membership_id
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response && response.result) {
			const transaction = response.stripe_transaction;
			if (transaction) {
				setStatus(transaction.status);
				setIsDisabled(transaction.status === 'active' || transaction.status === 'expired');
			}
		}
	},[authUserInfo]);

	useEffect(() => {
		if (space_username && !isMembershipLoaded) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					getMembership(spaceInfo.id, membershipId).then((r) => {});
					setSpaceName(spaceInfo.name);
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(space_username));
				}
				if (spaceInfoLoading === 'failed') {
					navigate(routes.createSpace());
				}
			}
		}

		// if (authUserInfo) {
		// 	getMembershipClaim().then();
		// }

		if (membershipDetails != null) {
			if (authUserInfo != null && !loadMembershipOnce) {
				setLoadMembershipOnce(true);
				getMembership(spaceInfo.id, membershipId).then((r) => {});
				checkStatus(spaceInfo.id, membershipId).then(r =>{} );
			}
			if (authUserInfo != null && !isDisabled) {
				if (membershipDetails.creator === authUserInfo.id) {
					setIsDisabled(true);
				}
			}
		}

		console.log('hasGradient: '+ hasGradient);

	}, [
		currentLocation.pathname,
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		membershipId,
		membershipDetails,
		isMembershipLoaded,
		loadMembershipOnce,
		isClaimed,
		status,
		isDisabled
	]);

	return (
		<>
			<div className="container join-space">
				<>
					{fetchingCollectionData ? (
						<div className="loading-items w-full">
							<i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
						</div>
					) : (
						<>
							{!collectionData ? (
								<div className="text-center pt-5">
									{membershipDetails && membershipDetails.contract_address ? (
										<>
											<span className="collection-display-error">Collection not found.</span>
										</>
									) : (
										<>
											<span className="collection-display-error">No collection contract address set.</span>
										</>
									)}
								</div>
							) : (
								<>
									<div className="grid lg:grid-cols-12 gap-4 py-5">
										<div className="col-span-12 lg:col-span-6 flex justify-center lg:items-start pt-5">
											<div
												className="asset-preview"
												style={collectionAssetBGImage}
											>
												{collectionAssetType &&
													collectionAssetType === 'video' && (
														<video
															autoPlay
															muted
															loop
															className="membership-video"
														>
															<source
																src={membershipDetails.video}
																type="video/mp4"
															/>
														</video>
													)}
											</div>
										</div>
										<div className="membership-details col-span-12 lg:col-span-6 grid items-center content-center">
											<div className="mt-4 lg:mt-0 w-full">
												<h2 className="header-large ">
													{membershipDetails ? membershipDetails.name : ''}
												</h2>
											</div>
											<div className="mt-4 lg:mt-0 w-full">
												<p className="body-text--big">
													{membershipDetails
														? membershipDetails.listing_text
														: ''}
												</p>
											</div>
											<div className="mt-4 lg:mt-0 w-full">
												<i className="body-text--big">
													{membershipDetails ? membershipDetails.summary : ''}
												</i>
											</div>
											<div className="mt-4 lg:mt-0 w-full">
												<div className="grid lg:grid-cols-6 md:grid-cols-1 gap-4">
													<div className="space-buy-details lg:col-span-3">
														<h5 className="header-small">{getDetailsPrice(membershipDetails)}</h5>
														<p className="body-txtitalic--big color-light">
															{getDetailsMonthlyPrice(true, membershipDetails)}
														</p>
														{membershipDetails && (
															<>
																<Button
																	className="btn btn-md btn-primary mt-5"
																	disabled={submitted || isDisabled}
																	onClick={claimMembership}
																>
																	{submitted && (
																		<i className="fa-solid fa-spinner fa-spin me-3"></i>
																	)}
																	{getCommunityBtnText(membershipDetails, true, false, status)}
																	{/*{isClaimed ? 'Claimed' : 'Claim Now'}*/}
																</Button>

																{/*{membershipDetails.enable_expiration_and_subscription ? (*/}
																{/*	<>*/}
																{/*		<Button*/}
																{/*			className="btn btn-md btn-primary"*/}
																{/*			disabled={submitted || membershipClaim}*/}
																{/*			onClick={joinMembership}*/}
																{/*		>*/}
																{/*			{submitted && (*/}
																{/*				<i className="fa-solid fa-spinner fa-spin me-3"></i>*/}
																{/*			)}*/}
																{/*			{membershipClaim ? 'Joined' : 'Join Now'}*/}
																{/*		</Button>*/}
																{/*	</>*/}
																{/*) : (*/}
																{/*	<>*/}
																{/*		<Button*/}
																{/*			className="btn btn-md btn-primary"*/}
																{/*			disabled={submitted || membershipClaim}*/}
																{/*			onClick={claimMembership}*/}
																{/*		>*/}
																{/*			{submitted && (*/}
																{/*				<i className="fa-solid fa-spinner fa-spin me-3"></i>*/}
																{/*			)}*/}
																{/*			{membershipClaim ? 'Claimed' : 'Claim Now'}*/}
																{/*		</Button>*/}
																{/*	</>*/}
																{/*)}*/}
															</>
														)}
													</div>
													<div className="space-buy-details lg:col-span-3">
														<h5 className="header-small">
															<i className="fa-regular fa-cubes"/>{' '}
															{getDetailsGift(true, membershipDetails)}
														</h5>
														<h5 className="header-small">
															<i className="fa-regular fa-gift"/>{' '}
															{membershipDetails.benefits_count} Benefit{membershipDetails.benefits_count > 1? 's': ''}
														</h5>
														<a className="btn btn-md btn-secondary mt-5" onClick={scrollToClick}>
															Membership Benefit
														</a>
													</div>
												</div>
												<ul className="list-socials pt-4">
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-twitter isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-instagram isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-facebook isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-tiktok isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-youtube isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-linkedin isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-discord isDisabled"></i>
														</li>
													)}
													{spaceInfo.social_link && (
														<li>
															<i className="fa-brands fa-twitch isDisabled"></i>
														</li>
													)}
													{spaceInfo.website && (
														<li>
															<a href={spaceInfo.website} target="_blank">
																<i className="fa-regular fa-globe"></i>
															</a>
														</li>
													)}
												</ul>
											</div>
										</div>
									</div>
								</>
							)}
						</>
					)}
				</>
			</div>
			<div className="w-full join-space-details my-5 pt-5">
				<div className="container">
					{!fetchingCollectionData && (
						<>
							<div id="details-description">
								<p className="body-text--reg">
									{collectionData && collectionData.openSea
										? collectionData.openSea.description
										: ''}
									{/*{membershipDetails && membershipDetails.description &&*/}
									{/*	membershipDetails.description*/}
									{/*}*/}
								</p>
							</div>
							<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
								<h3 className="header-medium">Benefits</h3>
								<div className="body-text--small color-reg">
									This is a membership for{' '}
									<Link
										onClick={goToSpace}
										className={
											hasGradient
												? 'gradient-color-txt'
												: 'basic-gradient-color-txt'
										}
									>
										<i className="fa-solid fa-solar-system"></i> {spaceName}
									</Link>
								</div>
							</div>
							<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
								<span
									className={
										'body-text--reg ' +
										(hasGradient
											? 'gradient-color-txt'
											: 'basic-gradient-color-txt')
									}
								>
										Member Access
									</span>
								<p className="body-text--small color-reg">
									Members Only access to{' '}
									<Link
										onClick={goToSpace}
										className={
											hasGradient
												? 'gradient-color-txt'
												: 'basic-gradient-color-txt'
										}
									>
										<i className="fa-solid fa-solar-system"></i> {spaceName}
									</Link>
								</p>
							</div>
							<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
								<span
									className={
										'body-text--reg ' +
										(hasGradient
											? 'gradient-color-txt'
											: 'basic-gradient-color-txt')
									}
								>
										Partner Perks
									</span>
								<p className="body-text--small color-reg">
									Partner discounts from my favorite shops and brands!
								</p>
							</div>
							<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
								<span
									className={
										'body-text--reg ' +
										(hasGradient
											? 'gradient-color-txt'
											: 'basic-gradient-color-txt')
									}
								>
										Partner Giveaways
									</span>
								<p className="body-text--small color-reg">
									Monthly giveaways from partner brands.
								</p>
							</div>
							<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
								<span
									className={
										'body-text--reg ' +
										(hasGradient
											? 'gradient-color-txt'
											: 'basic-gradient-color-txt')
									}
								>
										Community Voting
									</span>
								<p className="body-text--small color-reg">
									Vote on new upcoming video topics in the Community Channel!
								</p>
							</div>
							<div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 mt-5">
								<span
									className={
										'body-text--reg ' +
										(hasGradient
											? 'gradient-color-txt'
											: 'basic-gradient-color-txt')
									}
								>
										1,000 Community Points
									</span>
								<p className="body-text--small color-reg">
									Add 1,000 community points to your account and use them for
									unlockable benefits and limited items!{' '}
									<span className="color-light body-txtitalic--small">
											(1 redemption)
										</span>
								</p>
							</div>
							{/*<div className="row mt-5">*/}
							{/*	<div className="col-6">*/}
							{/*		<span*/}
							{/*			className={*/}
							{/*				'body-text--large ' +*/}
							{/*				(hasGradient*/}
							{/*					? 'gradient-color-txt'*/}
							{/*					: 'basic-gradient-color-txt')*/}
							{/*			}*/}
							{/*		>*/}
							{/*			Traits*/}
							{/*		</span>*/}
							{/*	</div>*/}
							{/*	<div className="col-6">*/}
							{/*		<p className="body-text--small color-reg">*/}
							{/*			This is a Membership for Related Space and platform details*/}
							{/*		</p>*/}
							{/*	</div>*/}
							{/*</div>*/}
							{/*<div className="row mt-5">*/}
							{/*	<div className="col-6">*/}
							{/*		<span*/}
							{/*			className={*/}
							{/*				'body-text--large ' +*/}
							{/*				(hasGradient*/}
							{/*					? 'gradient-color-txt'*/}
							{/*					: 'basic-gradient-color-txt')*/}
							{/*			}*/}
							{/*		>*/}
							{/*			Technical Details*/}
							{/*		</span>*/}
							{/*	</div>*/}
							{/*	<div className="col-6">*/}
							{/*		<p className="body-text--small color-reg">*/}
							{/*			Related Space and platform details*/}
							{/*		</p>*/}
							{/*	</div>*/}
							{/*</div>*/}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default memo(SpaceClaimMembership);
