import { memo, useEffect, useState } from 'react';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import { OAuthExtension } from '@magic-ext/oauth';
import Web3 from 'web3';
import { Network, Alchemy } from 'alchemy-sdk';

import sample_thumb from '../../../../../assets/images/sample_thumb.png';
import meta_mask from '../../../../../assets/images/meta_mask.png';

import {
	MAGIC_CONNECT_KEY,
	MAGIC_OAUTH_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../../../../constants/common';

const Wallet = (parentProps) => {
	const { data, parentCallback } = parentProps;
	const [address, setAddress] = useState('0x');

	// // Optional config object, but defaults to demo api-key and eth-mainnet.
	// const settings = {
	//     apiKey: 'demo', // Replace with your Alchemy API Key.
	//     network: Network.ETH_MAINNET // Replace with your network.
	// };
	// const alchemy = new Alchemy(settings);

	// const hexToDecimal = (hex) => parseInt(hex, 16);

	// const hexBal = alchemy.core.getBalance(data.address, 'latest');
	// const balance = hexToDecimal(hexBal);

	// const getBalance = async (address) => {
	//     alchemy.core.getBalance(data.address, 'latest').then((bal) => {
	//         setBalance(hexToDecimal(bal.result));
	//     });
	// };

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new OAuthExtension()]
	});
	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);

	const showWallet = () => {
		magic.connect.showWallet().catch((e) => {
			console.log(e);
		});
	};

	// const disconnect = async () => {
	//     await magic.connect.disconnect().catch((e) => {});
	// };

	const disconnect = (event) => {
		console.log('Calling Disconnect');
		parentCallback();
		event.preventDefault();
	};

	const middleEllipsis = (str) => {
		return str.substr(0, 5) + '...' + str.substr(str.length - 4, str.length);
	};

	useEffect(() => {
		setTimeout(() => {
			setAddress(middleEllipsis(data.public_address));
		}, 1000);
	}, [address]);

	return (
		<>
			<div className="row-view-wrapper mt-4" onClick={showWallet}>
				<div className="grid grid-cols-12 gap-8">
					<div className="col-span-6 flex items-center">
						<div className="user-img-temp">
							<i className="fa-solid fa-user"></i>
						</div>
						<div>
							<label className="header-small">{data.email}</label>
							<small className="body-text--smallest gradient-color-txt">
								{address}
							</small>
						</div>
					</div>
					{/*<div className="col flex items-centerjustify-end">*/}
					{/*	<i className="fa-brands fa-ethereum"></i>*/}
					{/*	<label className="body-text--smallest">0</label>*/}
					{/*</div>*/}
					{/*<div className="col flex items-center justify-center">*/}
					{/*	<i className="fa-solid fa-dollar-sign"></i>*/}
					{/*	<label className="body-text--smallest">0 USD</label>*/}
					{/*</div>*/}
					{/*<div className="col flex items-centerjustify-end">*/}
					{/*	<img src={meta_mask} />*/}
					{/*</div>*/}
					<div className="col-span-6 flex items-center justify-end">
						<a className="btn-row-view" onClick={disconnect}>
							<i className="fa-thin fa-link-horizontal-slash"></i>
						</a>
						<a className="btn-row-view ms-4" onClick={showWallet}>
							<i className="fa-thin fa-arrow-up-right-from-square"></i>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(Wallet);
