import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	VISIBILITY_LIST_WITH_DEFAULT,
	VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT, VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT
} from '../../../../constants/profile';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { updateProfileVisibility } from '../../../../services/user';
import { getAuthUserInfo } from '../../../../states/general/actions';
import { getListIcon } from '../../../../utils/list';
import {getSitewideLink} from "../../../../utils/sitewideLinks";

const ManageVisibility = () => {
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [selectedVisibilitySettings, setSelectedVisibilitySettings] = useState({
		privacy: 'default',
		basics: 'default',
		follower_lists: 'default',
		spaces_list: 'default',
		collections: 'default',
		activity: 'default',
		about_me: 'default',
		city: 'default',
		badges: 'default',
		top_8: 'default'
	});
	const [classSize, setClassSize] = useState('md');
	const { screenSize } = useSelector((state) => state.classSize);

	const handleChanges = useCallback(
		(field, value) => {
			setSelectedVisibilitySettings((prev) => ({ ...prev, [field]: value }));

			if (authUserInfo && authUserInfo.profile.visibility) {
				const data = {
					user_id: authUserInfo.id,
					field: field,
					value: value
				};

				updateRequest(data).then((r) => {});
			}
		},
		[authUserInfo]
	);

	const updateRequest = async (data) => {
		try {
			const result = await updateProfileVisibility(data);
			if (result && result.result) {
				dispatch(getAuthUserInfo());
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			if (authUserInfo.profile.visibility) {
				setSelectedVisibilitySettings((prev) => ({
					...prev,
					privacy: authUserInfo.profile.visibility.privacy,
					basics: authUserInfo.profile.visibility.basics,
					follower_lists: authUserInfo.profile.visibility.follower_lists,
					spaces_list: authUserInfo.profile.visibility.spaces_list,
					collections: authUserInfo.profile.visibility.collections,
					activity: authUserInfo.profile.visibility.activity,
					about_me: authUserInfo.profile.visibility.about_me,
					city: authUserInfo.profile.visibility.city,
					badges: authUserInfo.profile.visibility.badges,
					top_8: authUserInfo.profile.visibility.top_8
				}));
			}
		}

		setClassSize(screenSize < 1300 ? 'sm' : 'md');

		document.title = 'Commonspace Profile Manage Visibility';
	}, [loading, authUserInfo, screenSize, classSize]);

	return (
		<>
			<div className="col-span-12">
				<div className="dashboard-wrapper mt-4">
					<h3 className="header-medium">Profile Visibility Settings</h3>
					<p className="pt-3 body-txtitalic--small">
						You can control who sees your profile, spaces, collections and more here.
						Your @username, profile images, and number of followers, following, spaces,
						and items are always public.{' '}
						{getSitewideLink('learn_more_profile_visibility')}.
						{/*<a className="gradient-color-txt" href="">*/}
						{/*	Learn more about Profile Visibility Settings.*/}
						{/*</a>*/}
					</p>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Profile Privacy
							</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								This is your overall Privacy Status which can be Public or Private.
								If you select Private, then you will have to approve new follower
								and items below may be automatically set to Followers Only.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Profile Privacy"
								customButtonClass={"btn-primary btn-size btn-"+classSize}
								icon={getListIcon(
									VISIBILITY_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.privacy
								)}
								selectedValue={selectedVisibilitySettings.privacy}
								listItems={VISIBILITY_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('privacy', value)}
							/>
						</div>
					</div>
					<div className="items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Profile Basics
							</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Includes your display name, pronouns, bio, social links, and website
								link.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Profile Basics"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.basics
								)}
								selectedValue={selectedVisibilitySettings.basics}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('basics', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Follower Lists
							</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Your list of who you follow and who follow you.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Follower Lists"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.follower_lists
								)}
								selectedValue={selectedVisibilitySettings.follower_lists}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('follower_lists', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Spaces List
							</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Your featured profiles and spaces that you love the most, as shown
								on your About section.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Spaces List"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.spaces_list
								)}
								selectedValue={selectedVisibilitySettings.spaces_list}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('spaces_list', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">
								Items & Collections
							</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Your featured profiles and spaces that you love the most, as shown
								on your About section.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Items & Collections"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.collections
								)}
								selectedValue={selectedVisibilitySettings.collections}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('collections', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">Activity</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Recently joined spaces, items acquired, badge activity, new
								followers, and following.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Activity"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.activity
								)}
								selectedValue={selectedVisibilitySettings.activity}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('activity', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">About Me</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Your About Me information, as shown on your About section.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="About Me"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.about_me
								)}
								selectedValue={selectedVisibilitySettings.about_me}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('about_me', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">Location</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Your Profile Location, as shown on your About section. This is separate from your Account City, which is private.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="City"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.city
								)}
								selectedValue={selectedVisibilitySettings.city}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('city', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">Badges</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Your earned badges, as shown on your About section.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Badges"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.badges
								)}
								selectedValue={selectedVisibilitySettings.badges}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('badges', value)}
							/>
						</div>
					</div>
					<div className="flex items-center pt-5 grid grid-cols-1 gap-x-3 md:grid-cols-12">
						<div className="md:col-span-2">
							<label className="body-text--small gradient-color-txt">Top 8</label>
						</div>
						<div className="md:col-span-7">
							<p className="body-txtitalic--smallest m-0">
								Your featured profiles and spaces that you love the most, as shown
								on your About section.
							</p>
						</div>
						<div className="md:col-span-3">
							<DropdownWithItems
								title="Top 8"
								customButtonClass={"whitespace-pre-wrap btn-secondary btn-size btn-"+classSize}
								icon={getListIcon(
									authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT,
									selectedVisibilitySettings.top_8
								)}
								selectedValue={selectedVisibilitySettings.top_8}
								listItems={authUserInfo?.profile?.visibility?.privacy === 'private' ? VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT : VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT}
								onDropdownChange={(value) => handleChanges('top_8', value)}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(ManageVisibility);
