import '../../Modals/Modals.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import {hideTooltip, showTooltip} from "../../../../utils/input";
import {CUSTOM_REGEX} from "../../../../constants/custom_regex";
import {inviteProfileEmails} from "../../../../services/space";

ReactModal.setAppElement('#root');

const SpaceProfileInvite = (props) => {
	const { modalOpen, setModalOpen, spaceId, userId } = props;
	const [initialLoad, setInitialLoad] = useState(true);
	const [spaceInviteForm] = Form.useForm();
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: 'white',
			width:'80%',
			borderRadius: 32
		},
		overlay: { background: 'rgba(0, 0, 0, 0.6)' }
	};

	const handleFormChanges = useCallback(async () => {
		await spaceInviteForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await spaceInviteForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleSubmit = useCallback(async (values) => {
		setAlert(null);
		if (values.email != null) {
			const data = values;
			const emails = values.email.split(',');
			const emailArray = [];
			const notEmailArray = [];
			emails.map((email)=>{
				const trimEmail = email.trim();
				if (CUSTOM_REGEX.STRICT_EMAIL_VALIDATION.test(trimEmail)) {
					emailArray.push(trimEmail);
				} else {
					notEmailArray.push(trimEmail);
				}
			})

			if (notEmailArray.length > 0) {
				return;
			}
			if (emailArray.length > 0) {

				try {
					data.user_id = userId;
					data.space_id = spaceId;
					data.email = emailArray;

					const response = await inviteProfileEmails(data);
					if (response && response.result) {
						setAlert({
							variant: 'success',
							message: response.message
						});

						setTimeout(() => {
							setAlert(null);
							spaceInviteForm.setFieldValue('email','');
							setModalOpen(false);
						}, 2000);
					} else {
						new Error(response.message)
						setAlert({
							variant: 'danger',
							message: response.message
						});
					}
				} catch (e) {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong!'
					});
				}
			}

			setSubmitted(false);
		}
	}, []);

	useEffect(() => {}, []);

	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={customStyles}
		>
			<button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
				<i className="fa-solid fa-xmark"></i>
			</button>
			<h3 className="header-medium mt-2">Manage Invite</h3>

			<Form
				form={spaceInviteForm}
				name="spaceInviteForm"
				id="spaceInviteForm"
				validateMessages={VALIDATE_MESSAGES}
				onFocus={() => {
					setFormIsOnFocus(true);
				}}
				onBlur={() => {
					setTimeout(() => {
						setFormIsOnFocus(false);
						handleFormOnBlur();
					}, 200);
				}}
				onChange={handleFormChanges}
				onFinish={handleSubmit}
				className={formIsOnFocus ? 'hide-antd-error-messages add-category' : 'add-category'}
			>
				<>
					{alert && (
						<div className="grid grid-cols-12 mt-5">
							<div className="col-span-12">
								<Alert
									key={alert.variant}
									variant={alert.variant}
									className={
										'custom-alert ' +
										(alert.variant === 'danger'
											? 'custom-alert-danger'
											: 'custom-alert-success')
									}
								>
									{alert.message}
								</Alert>
							</div>
						</div>
					)}
				</>

				<div className="grid grid-cols-12 m-0 ">
					<div className="col-span-12 input-group-com input-label-modal input-with-btn mt-5 ">
						<label className="body-text--small flex items-center">Email(s)</label>
						<Form.Item
							name="email"
							className="mb-0 hidden-label"
							validateTrigger="onBlur"
							rules={[
								{ required: true },

								({ getFieldValue }) => ({
									async validator(_, value) {
										if (value) {
											setAlert(null);

											const emails = value.split(',');
											const emailArray = [];
											const notEmailArray = [];
											emails.map((email)=>{
												const trimEmail = email.trim();
												if (CUSTOM_REGEX.STRICT_EMAIL_VALIDATION.test(trimEmail)) {
													emailArray.push(trimEmail);
												} else {
													notEmailArray.push(trimEmail);
												}
											})

											if (notEmailArray.length > 0) {
												return Promise.reject(
													notEmailArray.length > 1 ?
														(new Error('The following are not emails: ' + notEmailArray.join(', '))) :
														(new Error(notEmailArray+' is not an email'))
												);
											}
										}
										return Promise.resolve();
									}
								})
							]}
						>
							<Input
								type="text"
								onFocus={hideTooltip}
								onBlur={showTooltip}
								className="input-md"
							/>
						</Form.Item>
						{/*{!category && (*/}
						{/*	<span className="tool-tip">Up to 24 characters, including spaces.</span>*/}
						{/*)}*/}
					</div>
					<p className="body-text--small mt-2 col-span-12">
						Separated by comma on multiple emails.
					</p>
				</div>

				<Button
					className="btn btn-md btn-primary mb-2 mt-5"
					htmlType="submit"
					disabled={submitted || formHasError}
				>
					<i className={submitted ? 'fa-solid fa-spinner fa-spin' : 'fa-solid'}></i>
					Save Invite
				</Button>
			</Form>
		</ReactModal>
	);
};

export default SpaceProfileInvite;
