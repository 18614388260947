
/************************************
 *                                  *
 *                                  *
 *        Membership and Item       *
 *                                  *
 *             Utilities            *
 *                                  *
 *                                  *
 ************************************/


import React from "react";
import {getLocalStorage} from "./storage";
import {STORAGE_KEYS} from "../constants/common";
import routes from "../routes";

/** TODO-MJ: Update this enumeration for checking the checkout flow
 * Enumeration of checkout flow
 * @type {{claimNFTWithNoSubscriptionExistingUserMemberToSpace: number,
 * claimNFTWithSubscriptionExistingUserNewToSpace: number,
 * freeWithSubscription: number, paidWithNoSubscription: number,
 * paidWithSubscription: number, freeWithNoSubscription: number,
 * claimNFTFreeNewUser: number, claimNFTWithNoSubscriptionExistingUserNewToSpace: number}}
 */
export const checkoutFlow = {
    paidWithNoSubscription: 0,
    paidWithSubscription: 1,
    freeWithSubscription: 2,
    freeWithNoSubscription: 3,
    claimNFTWithNoSubscriptionExistingUserNewToSpace: 4,
    claimNFTWithNoSubscriptionExistingUserMemberToSpace: 5,
    claimNFTWithSubscriptionExistingUserNewToSpace: 6,
    claimNFTFreeNewUser: 7,
}


/**
 * Enumeration of types of payment
 * @type {{recurring: string, onetime: string}}
 */
export const PaymentType = {
    onetime: 'onetime',
    recurring: 'recurring'
}

/**
 * Enumeration of currencies
 * @type {{USD: string}}
 */
export const CurrencyType = {
    USD : 'USD'
}

/**
 *
 * Membership/Item Utility class
 *
 */
export class MembershipItemUtils extends React.Component {

    /**
     * Redirect user to login page if not authenticated
     * @param {string} loading
     * @param {object} authUserInfo
     */
    static notAuthRedirect = (loading, authUserInfo) => {
        const accessToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)));
        const refreshToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN)));
        if (accessToken && refreshToken) {
            if (loading === 'done' && !authUserInfo) {
                window.location.replace(routes.login());
            }
        } else {
            window.location.replace(routes.login());
        }
    }

    /**
     * Returns true if the user is authenticated
     * @param {string} loading
     * @param {object} authUserInfo
     * @returns {boolean}
     */
    static isOAuth = (loading, authUserInfo) => {
        const accessToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)));
        const refreshToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN)));
        if (accessToken && refreshToken) {
            if (loading === 'done' && !authUserInfo)
                return false;
        } else {
           return false;
        }
        return true;
    }
}


