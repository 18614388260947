import { memo } from 'react';
import { Popover } from 'antd';
import { HexColorPicker, HexColorInput } from 'react-colorful';

const ColorPickerAndInput = (parentProps) => {
	const { color, onColorChange, className } = parentProps;

	const handleColorChange = (item) => {
		onColorChange(item);
	};

	const colorPicker = <HexColorPicker color={color} onChange={handleColorChange} />;

	return (
		<>
			<Popover className={className} placement="bottom" content={colorPicker} trigger="click">
				<button className="color-holder" style={{ backgroundColor: color }}></button>
			</Popover>
			<HexColorInput
				className={'body-txtitalic--small color-light w-full text-uppercase ' + className}
				color={color}
				onChange={handleColorChange}
			/>
		</>
	);
};

export default memo(ColorPickerAndInput);
