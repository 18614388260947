import React, {memo, useCallback, useEffect, useState} from "react";
import ReactModal from "react-modal";
import { PlusIcon } from '@heroicons/react/20/solid'
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
    IMAGE_LARGE_MAX_HEIGHT,
    IMAGE_LARGE_MAX_WIDTH,
    IMAGE_STANDARD_MIN_HEIGHT,
    IMAGE_STANDARD_MIN_WIDTH,
    VIDEO_STANDARD_FILE_UPLOAD_SIZE,
    MIXED_FILETYPES, AUDIO_FILETYPES, UPLOAD_FILE_TYPE
} from '../../../../constants/file';
import { Button, Form } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import{ updateSpacePost } from '../../../../services/post'
import default_user_profile from "../../../../assets/images/default_user_profile.png";
import {updateSpaceModelComment} from "../../../../services/space";
import { useDispatch } from 'react-redux';
import {
    setPost,
    setEditPost,
    setEditComment,
    setSubComment,
    setEditSubComment,
    setSubCommentReply,
    setEditSubCommentReply,
    setSubCommentSubReply,
    setEditSubCommentSubReply
} from '../../../../states/channelSlice';
import TextareaAutosize from "react-textarea-autosize";

const ChannelPostForm = (props) => {
    const dispatch = useDispatch();

    const {
        itemElRef,
        dataToEdit, // if not null, The form is for editing
        setDataToEdit,
        isAudioUpload,
        setIsAudioUpload,
        modalOpen,
        setModalOpen,
        spaceChannel,
        spaceData,
        user,
        handleNewData
    } = props;

    const [submitted, setSubmitted] = useState(false);
    const [coverImageBlobPreview, setCoverImageBlobPreview] = useState(null);
    const [fileAttachment, setFileAttachment] = useState(null);
    const [isAttachmentRemoved, setAttachmentRemoved] = useState(false);
    const [updateForm] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [attachmentType, setAttachmentType] = useState(null);
    const [comment, setComment] = useState('');
    const [isAttachmentExist, setIsAttachmentExist] = useState(false);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            minWidth:'50%',
            maxWidth: '80%',
            borderRadius: 32
        },
        overlay: { background: 'rgba(0, 0, 0, 0.6)' }
    };

    const handleCoverImageUploadFileChange = (file, blobPreview) => {
        // console.log('handleCoverImageUploadFileChange')
        // console.log(file)
		if (!file) {
            // console.log('setAttachmentRemoved true')
            setAttachmentRemoved(true);
            setCoverImageBlobPreview(null);
            setFileAttachment(null);
		}
        if (file) {
            setAttachmentRemoved(true);
            setFileAttachment(file);
            // console.log('file');
            // console.log(file);
            if (file.type === "video/mp4") {
                setAttachmentType(UPLOAD_FILE_TYPE.VIDEO)
            } else if (file.type === "image/jpeg"){
                setAttachmentType(UPLOAD_FILE_TYPE.IMAGE)
            } else {
                setAttachmentType(UPLOAD_FILE_TYPE.AUDIO)
            }
        }
        setCoverImageBlobPreview(blobPreview);
	};

    const handleSubmit = useCallback(async (values) => {
            setIsSubmitting(true);
            if (dataToEdit) {
                if (spaceChannel.id && dataToEdit.id && dataToEdit.model_id == null ) {
                    // Edit Post
                    // console.log('Edit Post');
                    handleSubmitEditPost(values).then(() => {});
                } else if (spaceChannel.id && dataToEdit.model_id && dataToEdit.id && dataToEdit.reply_to_id == null) {
                    // Edit Comment
                    // console.log('Edit Comment');
                    handleSubmitEditComment(values).then(() => {});
                } else if (spaceChannel.id && dataToEdit.model_id && dataToEdit.id && dataToEdit.reply_to_id) {
                    // Edit SubComment
                    // console.log('Edit SubComment');
                    handleSubmitEditSubComment(values).then(() => {});
                }
            } else {
                // Upload Attachments
                // console.log('Upload Attachments');
                const update = {};
                update.model = 'post';
                update.channel_id = spaceChannel.id;
                update.title = values.comment ? values.comment : '';
                update.content = '';
                const attachments = [];
                const attachment = {};
                if (fileAttachment){
                    attachment.id = "null";
                    attachments.push(attachment);

                    if (attachmentType === UPLOAD_FILE_TYPE.IMAGE) {
                        update.attachments_type = 'image'
                    } else if (attachmentType === UPLOAD_FILE_TYPE.VIDEO) {
                        update.attachments_type = 'video'
                    } else if (attachmentType === UPLOAD_FILE_TYPE.AUDIO) {
                        update.attachments_type = 'audio'
                    }

                    update.attachments = JSON.stringify(attachments);
                    update.file_0 = fileAttachment
                }

                try {
                    if (user) {
                        update.user_id = user.id;
                        update.space_id = spaceData.id;
                        // const response = await createSpacePostUpdate(update);
                        const response = await updateSpacePost(update);
                        // console.log(response)
                        dispatch(setPost(response.post));
                        // handleNewData(response)
                        if (response && response.result) {
                            // setAlert({
                            // 	variant: 'success',
                            // 	message: response.message
                            // });
                            window.scrollTo(0, 0);
                            // setIsVideo(false);
                            setIsSubmitting(false);
                            updateForm.setFieldValue('comment','');
                            setCoverImageBlobPreview(null);
                            setFileAttachment(null);
                            // setTimeout(() => {
                            // 	let urlRoute = routes.spaceUpdates();
                            // 	urlRoute = urlRoute.replace(':space_username', space_username);
                            // 	navigate(urlRoute);
                            // }, 1500);
                        } else {
                            window.scrollTo(0, 0);
                        }
                        setModalOpen(false);
                    }
                } catch (error) {
                    console.log(error);
                }
            }

        }, [fileAttachment, spaceChannel, attachmentType, dataToEdit, isAttachmentRemoved, attachmentType]
    );

    const handleSubmitEditPost = useCallback(async (values) => {
        setIsSubmitting(true);
        const update = {};
        update.model = 'post';
        update.post_id = dataToEdit.id
        update.channel_id = spaceChannel.id;
        update.title = values.comment ? values.comment : '';
        update.content = '';

        // console.log('isAttachmentRemoved')
        // console.log(isAttachmentRemoved)
        if (isAttachmentRemoved && dataToEdit.attachments) {
            const attachments = [];
            let attachmentRemoveType = null;
            if (dataToEdit.attachment_type && dataToEdit.attachments.videos.length > 0){
                attachmentRemoveType = UPLOAD_FILE_TYPE.VIDEO;
                attachments.push(dataToEdit.attachments.videos[0].id)
            }
            if(dataToEdit.attachment_type && dataToEdit.attachments.images.length > 0){
                attachmentRemoveType = UPLOAD_FILE_TYPE.IMAGE;
                attachments.push(dataToEdit.attachments.images[0].id)
            }
            if(dataToEdit.attachment_type && dataToEdit.attachments.audios.length > 0){
                attachmentRemoveType = UPLOAD_FILE_TYPE.AUDIO;
                attachments.push(dataToEdit.attachments.audios[0].id)
            }
            if (attachments.length > 0) {
                update.attachments_remove_ids = JSON.stringify(attachments);
            }
            setAttachmentRemoved(false);
            if (attachmentRemoveType) {
                update.attachments_remove_type = attachmentRemoveType
            }
        }

        const attachments = [];
        const attachment = {};

        if (fileAttachment) {
            attachment.id = "null";
            attachments.push(attachment);

            if (attachmentType) {
                update.attachments_type = attachmentType
            }
            update.attachments = JSON.stringify(attachments);
            update.file_0 = fileAttachment
        }
        try {
            if (user) {
                update.user_id = user.id;
                update.space_id = spaceData.id;
                const response = await updateSpacePost(update);
                if (response && response.result) {
                    dispatch(setEditPost(response.post));
                    window.scrollTo(0, 0);
                    setIsSubmitting(false);
                    updateForm.setFieldValue('comment','');
                    setCoverImageBlobPreview(null);
                } else {
                    window.scrollTo(0, 0);
                }
                setModalOpen(false);
            }
        } catch (error) {
            console.log(error);

        }
        },
        [user, spaceChannel, dataToEdit, isAttachmentRemoved]
    );

    const handleSubmitEditComment = useCallback(async (values) => {
            setIsSubmitting(false)
            setSubmitted(true);
            const data = {
                space_id: spaceChannel.id,
                model: 'post',
                model_id: dataToEdit.model_id,
                comment_id: dataToEdit.id,
                comment: values.comment ? values.comment : ''
            };
            if (user) {
                data['user_id'] = user.id;
            }
            const response = await updateSpaceModelComment(data);
            if (response && response.result) {
                dispatch(setEditComment(response.comment));
                updateForm.resetFields();
                setIsSubmitting(false);
            }
            setSubmitted(false);
            setModalOpen(false);
        },
        [user, spaceChannel, dataToEdit]
    );

    const handleSubmitEditSubComment = useCallback(async (values) => {
            setIsSubmitting(false)
            setSubmitted(true);
            const data = {
                space_id: spaceChannel.id,
                model: 'post',
                model_id: dataToEdit.model_id,
                comment_id: dataToEdit.id,
                comment: values.comment ? values.comment : ''
            };
            if (user) {
                data['user_id'] = user.id;
            }
            const response = await updateSpaceModelComment(data);
            if (response && response.result) {
                let comment = response.comment;
                if (comment.reply_to_sub_id) {
                    dispatch(setEditSubCommentReply(response.comment))
                } else {
                    dispatch(setEditSubComment(response.comment));
                }

                updateForm.resetFields();
                setIsSubmitting(false);
            }
            setSubmitted(false);
            setModalOpen(false);
        },
        [user, spaceChannel, dataToEdit, itemElRef.current]
    );

    useEffect(()=>{
        // console.log('dataToEdit')
        // console.log(dataToEdit)
        if (dataToEdit) {
            if (dataToEdit.title) {
                updateForm.setFieldValue('comment', dataToEdit.title)
            } else if (dataToEdit.comment) {
                updateForm.setFieldValue('comment', dataToEdit.comment)
            }
            if (dataToEdit.attachments && dataToEdit.attachment_type === 'image' && dataToEdit.attachments.images.length > 0){
                // setCoverImageBlobPreview(null);
                setAttachmentType(UPLOAD_FILE_TYPE.IMAGE);
                setIsAttachmentExist(true);
                setCoverImageBlobPreview(dataToEdit.attachments.images[0].image_large)
            } else if(dataToEdit.attachments && dataToEdit.attachment_type === 'video' && dataToEdit.attachments.videos.length > 0){
                // setCoverImageBlobPreview(null);
                setAttachmentType(UPLOAD_FILE_TYPE.VIDEO);
                setIsAttachmentExist(true);
                setCoverImageBlobPreview(dataToEdit.attachments.videos[0].video)
            } else if(dataToEdit.attachments && dataToEdit.attachment_type === 'audio' && dataToEdit.attachments.audios.length > 0){
                // setCoverImageBlobPreview(null);
                setAttachmentType(UPLOAD_FILE_TYPE.AUDIO);
                setIsAttachmentExist(true);
                setCoverImageBlobPreview(dataToEdit.attachments.audios[0].audio)
            }

        }
    }, [dataToEdit])


    useEffect(() => {
       // console.log('isAttachmentExist')
       // console.log(isAttachmentExist)
       //  console.log('modalOpen')
       //  console.log(modalOpen)
        if (!modalOpen) {
            setIsAttachmentExist(false);
            setIsSubmitting(false);
            setDataToEdit(null);
            setSubmitted(false);
            setCoverImageBlobPreview(null);
            setFileAttachment(null);
            setAttachmentRemoved(false);
            setAttachmentType(null);
            setComment(null);
            setIsAttachmentExist(false);
            setIsAudioUpload(false);
        }
    },[isAttachmentExist, modalOpen])

    useEffect(()=>{
        // console.log('isAttachmentRemoved')
        // console.log(isAttachmentRemoved)
        // console.log('isAudioUpload');
        // console.log(isAudioUpload);
    },[isAttachmentRemoved, isAudioUpload])

    const handlePostKeypress = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            updateForm.submit();
        }
    };

    return (
        <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={customStyles}
        >
            <Button className="modalClose fa-thin" onClick={() => setModalOpen(false)}>
                <i className="fa-solid fa-xmark"/>
            </Button>
            { user && (
                <>
                    <div className="grid grid-cols-12 mt-8 items-center gap-x-2">
                        <div className='col-span-2 flex justify-center items-center'>
                            <span className="relative inline-block">
                                {user && user.profile && (
                                    <>
                                        <img className="h-16 w-16 rounded-full object-cover italic"
                                             src={user && user.profile.photo_thumb ? user.profile.photo_thumb : default_user_profile}
                                             alt=""
                                        />
                                        <i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>
                                    </>
                                )}
                            </span>
                        </div>
                        <div className="col-span-10 text-2xl gradient-color-txt font-['din2014Bold']">@{user.profile.username ? user.profile.username: user.profile.full_name}</div>
                    </div>
                    <Form className=""
                        form={updateForm}
                        name="updateForm"
                        id="updateForm"
                        onFinish={handleSubmit}
                        validateMessages={VALIDATE_MESSAGES}
                    >
                        <div className="grid grid-cols-12">
                            <Form.Item
                                name="comment"
                                className="w-full col-span-12"
                                // initialValue={comment} // Set the initial value here
                                // validateTrigger="onBlur"
                                // rules={[{ required: true }]}
                            >
                                <TextareaAutosize
                                    placeholder="Say something!"
                                    maxRows={4}
                                    className="mt-8 focus:outline-none text-xl resize-none items-center rounded-md placeholder:text-gray-400 sm:leading-6 w-full no-scrollbar"
                                    // onChange={handlePostTitleChanges}
                                    onKeyPress={handlePostKeypress}
                                />
                                {/*<textarea*/}
                                {/*    name="comment"*/}
                                {/*    id="comment"*/}
                                {/*    maxLength="4000"*/}
                                {/*    className="px-4 py-1.5 mt-8 flex focus:outline-none resize-none items-center rounded-md text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 w-full"*/}
                                {/*    onChange={(e) => {updateForm.setFieldValue('comment', e.target.value)} }*/}
                                {/*    placeholder='Say something!'*/}
                                {/*/>*/}
                            </Form.Item>
                        </div>

                        {/*{ dataToEdit &&*/}
                        {/*    <div className='col-span-12 grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 md:col-span-7 mb-4 space-x-4 md:col-start-2 items-center text-gray-400'>*/}
                        {/*        <div className='col-span-3 md:col-span-1 pr-4 cursor-pointer relative'>*/}
                        {/*            <i className="fa-light fa-photo-film text-xl"*/}
                        {/*               onClick={() => {*/}
                        {/*                   // setActivationOpen(true);*/}
                        {/*                   // setIsAudioUpload(false);*/}
                        {/*               }}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className='col-span-3 md:col-span-1 pr-4 cursor-pointer'>*/}
                        {/*            <i className="fa-light fa-music text-xl"*/}
                        {/*               onClick={() => {*/}
                        {/*                   // setActivationOpen(true);*/}
                        {/*                   // setIsAudioUpload(true);*/}
                        {/*               }}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        { (dataToEdit && dataToEdit.title || !dataToEdit) &&
                            <div className="items-center wrapper-lr-padding border">
                                <div className={`body-text--small relative ${isAudioUpload ? 'h-[150px]': 'h-[500px] flex justify-center'} `}>
                                    <>
                                        <UploadDragger
                                            isAudioUpload={isAudioUpload}
                                            //passedError={coverImageError}
                                            cropperAspectRatio={16 / 9}
                                            hideDragIcon="true"
                                            hideText="true"
                                            uploadFileTypes={isAudioUpload ? AUDIO_FILETYPES.toString() : MIXED_FILETYPES.toString()}
                                            uploadedFilePreview={coverImageBlobPreview}
                                            onUploadFileChange={handleCoverImageUploadFileChange}
                                            minHeight={IMAGE_STANDARD_MIN_HEIGHT}
                                            minWidth={IMAGE_STANDARD_MIN_WIDTH}
                                            maxHeight={IMAGE_LARGE_MAX_HEIGHT}
                                            maxWidth={IMAGE_LARGE_MAX_WIDTH}
                                            uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
                                            disableCropper={true}
                                        />
                                        {!coverImageBlobPreview &&
                                            <div className="text-center my-auto">
                                                <i className={`fa-light ${isAudioUpload ? 'fa-file-audio':'fa-photo-film'} text-xl`}></i>
                                                <h3 className="mt-2 text-sm font-semibold text-gray-900">Add {isAudioUpload ? 'Audio' : 'Photos/Videos'}</h3>
                                                <p className="mt-1 text-sm text-gray-500">or drag and drop</p>
                                                <div className="mt-6">
                                                    <button
                                                        type="button"
                                                        className="inline-flex relative items-center rounded-md bg-indigo-600 px-3 py-2
                                                    text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                                                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                                        Upload
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </>
                                </div>
                            </div>
                        }
                        <Form.Item className='w-full my-auto mt-8'>
                            <Button className="btn btn-md btn-primary w-full"
                                disabled={isSubmitting}
                                htmlType="submit">
                                    {!isSubmitting ? (
                                    <span>Post</span>
                                    ) : (
                                        <>
                                            <i className="fa-solid fa-spinner fa-spin"></i>
                                        </>
                                    )}
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}
        </ReactModal>
    );
}

export default memo(ChannelPostForm);