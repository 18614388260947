import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import { VALIDATE_MESSAGES, GOOGLE_MAP_KEY } from '../../../../constants/common';
import { register, walletSetAccountPassword } from '../../../../services/user';
import { isAlphaNumeric } from '../../../../utils/form';
import { getAuthUserInfo } from '../../../../states/general/actions';
import { setAccessToken } from '../../../../utils/common';
import routes from '../../../../routes';

import Autocomplete from 'react-google-autocomplete';
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import { getSitewideLink } from '../../../../utils/sitewideLinks';

const EmailConfirmedAddAccountDetails = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { email, authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId } = props;
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [emptyPass, setEmptyPass] = useState(true);
	const [emptyCity, setEmptyCity] = useState(true);
	const [inputElement, setInputElement] = useState();
	const { size } = useSelector((state) => state.classSize);
	const [errorSize, setErrorSize] = useState('sm');

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		if (form) {
			let emptyPass = true;
			if (form.getFieldValue('password')) {
				emptyPass = false;
			}
			setEmptyPass(emptyPass);

			let emptyCity = true;
			if (form.getFieldValue('city')) {
				emptyCity = false;
			}
			setEmptyCity(emptyCity);

			await form
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		}
	}, []);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setSubmitted(true);

			try {
				if (
					location.pathname === routes.emailVerificationWalletSetPassword() ||
					(verifyEmailSpaceUsername && (verifyEmailSpaceMembershipId || verifyEmailSpaceItemId))
				) {
					await setWalletAccountPassword(email, values);
				} else {
					await registerUser(email, values);
				}

				setSubmitted(false);
			} catch (error) {
				setAlert({ variant: 'danger', message: error.message });
				setSubmitted(false);
			}
		},
		[email, authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId]
	);

	const setWalletAccountPassword = useCallback(
		async (email, values) => {
			const data = values;
			data.email = email;

			const result = await walletSetAccountPassword(data);
			if (result) {
				if (result.result) {
					dispatch(getAuthUserInfo());

					form.resetFields();
					setAlert({
						variant: 'success',
						message: result.message
					});

					if (verifyEmailSpaceUsername && (verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)) {
						if (verifyEmailSpaceMembershipId) {
							const url = routes.spaceMembershipRegisterMemberProfile();
							const fin_url =
								url.replace(':space_username', '@' + verifyEmailSpaceUsername) +
								'?id=' +
								verifyEmailSpaceMembershipId;
							navigate(fin_url);
						}
						if (verifyEmailSpaceItemId) {
							const url = routes.spaceItemRegisterMemberProfile();
							const fin_url =
								url.replace(':space_username', '@' + verifyEmailSpaceUsername) +
								'?id=' +
								verifyEmailSpaceItemId;
							navigate(fin_url);
						}

					} else {
						navigate(routes.registerMemberProfile());
					}
				} else {
					if (result.message) {
						setAlert({ variant: 'danger', message: result.message });
					} else {
						setAlert({
							variant: 'danger',
							message: 'Something went wrong'
						});
					}
				}
			}

			setSubmitted(false);
		},
		[verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId]
	);

	const registerUser = async (email, values) => {
		const data = values;
		if (values.birthdate) {
			data.birthdate = values.birthdate.format('YYYY-MM-DD');
		}

		if (authUserInfo) {
			data.user_id = authUserInfo.id;
		}

		data.email = email;

		const result = await register(data);
		if (result) {
			if (result.result) {
				if (result.access_token) {
					setAccessToken(result);
				}

				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Registered successfully!'
				});

				window.location.replace(routes.registerMemberProfile());
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong'
					});
				}
			}
		}

		setSubmitted(false);
	};

	useEffect(() => {
		const checkValue = (str, max) => {
			if (str.charAt(0) !== '0' || str === '00') {
				var num = parseInt(str);
				if (isNaN(num) || num <= 0 || num > max) num = 1;
				str =
					num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
						? '0' + num
						: num.toString();
			}
			return str;
		};

		const handleInput = (e) => {
			e.target.type = 'text';
			let input = e.target.value;
			if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
			const values = input.split('/').map((v) => v.replace(/\D/g, ''));
			if (values[0]) values[0] = checkValue(values[0], 12);
			if (values[1]) values[1] = checkValue(values[1], 31);
			const output = values
				.map((v, i) => (v.length === 2 && i < 2 ? v + ' / ' : v))
				.join('')
				.substr(0, 14);
			inputElement.value = output;

			const inputDate = output;
			const dateParts = inputDate.split('/');
			const year = parseInt(dateParts[2], 10);
			const month = parseInt(dateParts[0], 10) - 1;
			const day = parseInt(dateParts[1], 10);
			// const newDate = new Date(year, month, day);

			const isFourDigits = (num) => {
				return /^\d{4}$/.test(num.toString());
			};

			// console.log('year: ' + year + ' month: ' + month + ' day:' + day);
			if (!isNaN(day) && !isNaN(month) && !isNaN(year) && isFourDigits(year)) {
				form.setFieldValue('birthdate', dayjs(new Date(year, month, day)));
			}
		};

		setInputElement(document.getElementById('registerForm_birthdate'));
		if (inputElement) {
			inputElement.addEventListener('input', handleInput);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('input', handleInput);
			}
		};

		// const handleBlur = (e) => {
		// 	e.target.type = 'text';
		// 	let input = e.target.value;
		// 	const values = input.split('/').map((v) => v.replace(/\D/g, ''));
		// 	let output = '';
		// 	if (values.length === 3) {
		// 		const year =
		// 			values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
		// 		const month = parseInt(values[0]) - 1;
		// 		const day = parseInt(values[1]);
		// 		const d = new Date(year, month, day);
		// 		if (!isNaN(d)) {
		// 			document.getElementById('result').innerText = d.toString();
		// 			const dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
		// 			output = dates
		// 				.map((v) => {
		// 					v = v.toString();
		// 					return v.length === 1 ? '0' + v : v;
		// 				})
		// 				.join(' / ');
		// 		}
		// 	}
		// 	setValue(output);
		// };
	}, [inputElement, errorSize]);

	return (
		<>
			<h1 className="mb-8 mt-8 header-large ">Email Confirmed!</h1>
			<h5 className='mb-8'>
				{location.pathname === routes.emailVerificationWalletSetPassword() ||
				(verifyEmailSpaceUsername && (verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)) ? (
					<span className='font-[notoSansRegular]'>
						An email has been added and your primary authentication is with your wallet.
						<i className="ms-2">
							You can also add a password to login using your email.
						</i>
					</span>
				) : (
					<span className='font-[notoSansRegular]'>Continue your account setup by adding your details and choosing a password.</span>
				)}
			</h5>
			<Form
				form={form}
				name="registerForm"
				validateMessages={VALIDATE_MESSAGES}
				onFocus={() => {
					setFormIsOnFocus(true);
				}}
				onBlur={() => {
					setFormIsOnFocus(false);
					handleFormOnBlur();
				}}
				autoComplete="off"
				onChange={handleFormChanges}
				onFinish={handleSubmit}
				className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
			>
				<>
					{alert && (
						<div className="row my-2 ">
							<div className="col">
								<Alert
									key={alert.variant}
									variant={alert.variant}
									className={
										'custom-alert ' +
										(alert.variant === 'danger'
											? 'custom-alert-danger'
											: 'custom-alert-success')
									}
								>
									{alert.message}
								</Alert>
							</div>
						</div>
					)}
				</>

				{location.pathname !== routes.emailVerificationWalletSetPassword() &&
					(!verifyEmailSpaceUsername || (!verifyEmailSpaceMembershipId && !verifyEmailSpaceItemId)) && (
						<>
							<div className={'grid grid-cols-1 md:grid-cols-2 gap-y-0 md:gap-y-8 md:gap-8 mb-8 md:mb-0' + (alert ? 'my-3 ' : 'my-5 ')}>
								<div className="mb-8 md:mb-0">
									<div className="input-group-com flex items-center">
										<label className="text-label body-text--small">
											First Name*
										</label>
										<Form.Item
											label="First Name"
											name="first_name"
											className="mb-0 hidden-label short-error"
											validateTrigger="onBlur"
											rules={[
												{
													required: true
												}
											]}
										>
											<Input
												className={'txt-short input-' + size}
												disabled={submitted}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="">
									<div className="input-group-com flex items-center">
										<label className="text-label body-text--small">
											Last Name*
										</label>
										<Form.Item
											label="Last Name"
											name="last_name"
											className="mb-0 hidden-label short-error"
											validateTrigger="onBlur"
											rules={[
												{
													required: true
												}
											]}
										>
											<Input
												className={'txt-short input-' + size}
												disabled={submitted}
											/>
										</Form.Item>
									</div>
								</div>
							</div>
							<div className={'grid grid-cols-1 md:grid-cols-2 gap-y-0 md:gap-y-8 md:gap-8 mb-8 md:mb-0' + (alert ? 'my-3 ' : 'my-5 ')}>
								<div className="mb-8 md:mb-0">
										<div className="input-group-com flex items-center">
											<label className="text-label body-text--small">
												Birthdate*
											</label>
											<Form.Item
												label="Birthdate"
												name="birthdate"
												className="mb-0 hidden-label short-error"
												validateTrigger="onBlur"
												rules={[
													{
														type: 'object',
														required: true
													}
												]}
											>
												<DatePicker
													placeholder="MM/DD/YYYY"
													className={
														'txt-short input-birthdate placeholder:text-[14px]'
													}
													format="MM/DD/YYYY"
												/>
											</Form.Item>
											
										</div>
								</div>
								<div>
									<div className="col-span-12 md:col-span-6 font-[notoSansRegular] flex justify-center">
										{getSitewideLink(
											'why_do_we_ask_email',
											'gradient-color-txt fst-italic '
										)}
										{/*<h5 className="gradient-color-txt fst-italic mt-3">*/}
										{/*	Why do we ask for this?*/}
										{/*</h5>*/}
									</div>
								</div>
							</div>

							<div className={'grid grid-cols-12 md:grid-cols-6 gap-y-0 md:gap-y-8 mb-8 md:gap-8 ' + (alert ? 'my-3 ' : 'my-5 ')}>
								<div className="col-span-12">
									<div className="input-group-com flex items-center">
										<label className="text-label body-text--small">City*</label>
										<Form.Item
											label="City"
											name="city"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{
													required: true
												}
											]}
										>
											<Autocomplete
												placeholder=""
												autoComplete="off"
												className={'txt-long input-' + size}
												apiKey={GOOGLE_MAP_KEY}
												onPlaceSelected={(place) => {
													form.setFieldValue(
														'city',
														place.formatted_address
													);
												}}
											/>
										</Form.Item>
										{emptyCity && (
											<>
												<span className="tool-tip">
													Start typing your City and select from the list.
													This is not publicly visible
												</span>
											</>
										)}
									</div>
								</div>
							</div>
						</>
					)}
				<div className={'input-group-com flex items-center mb-8 input-error-' + errorSize}>
					<label className="text-label body-text--small hidden sm:block">Create Password*</label>
					<label className="text-label body-text--small block sm:hidden">Create Password*</label>
					<Form.Item
						label="Password"
						name="password"
						className="mb-0 hidden-label"
						validateTrigger="onBlur"
						rules={[
							{ required: true },
							({ getFieldValue }) => ({
								validator(_, value) {
									setErrorSize('sm');
									if (value && value.length < 8) {
										setErrorSize('md');
										return Promise.reject(
											new Error('Password must be 8 characters long or more.')
										);
									}
									if (isAlphaNumeric(value)) {
										return Promise.resolve();
									}
									if (value && value.length > 0) {
										setErrorSize('lg');
										return Promise.reject(
											new Error(
												'Password is case sensitive, must be at least 8 characters, and include a symbol and number.'
											)
										);
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<Input
							type="password"
							className={'txt-long input-' + size}
							disabled={submitted}
							autoComplete="new-password"
						/>
					</Form.Item>
					{emptyPass && (
						<>
							<span className="tool-tip leading-normal">
								Must be a minimum of 8 case-sensitive characters including a symbol
								and a number.
							</span>
						</>
					)}
				</div>
				<div className="input-group-com  flex items-center input-with-btn-confirm mb-8 ">
					<label className="text-label body-text--small">Confirm Password*</label>
					<Form.Item
						name="confirmpassword"
						className="mb-0 hidden-label"
						dependencies={['password']}
						validateTrigger="onBlur"
						rules={[
							{
								required: true,
								message: 'Please confirm your password.'
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Password does not match.'));
								}
							})
						]}
					>
						<Input
							// placeholder="Confirm Password*"
							type="password"
							className={'txt-long input-' + size}
							disabled={submitted}
						/>
					</Form.Item>
					<Form.Item className="mb-0 hidden lg:block">
						<Button
							className={'btn border-filled text-white btn-' + size}
							htmlType="submit"
							disabled={submitted || formHasError}
						>
							{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
							<p className=''> Save & Continue</p>
						</Button>
					</Form.Item>
					
				</div>
				<Form.Item className="mb-0 block lg:hidden">
						<Button
							className={'btn border-filled text-white btn-' + size}
							htmlType="submit"
							disabled={submitted || formHasError}
						>
							{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
							<p className=''> Save & Continue</p>
						</Button>
					</Form.Item>
				{(location.pathname === routes.emailVerificationWalletSetPassword() ||
					verifyEmailSpaceUsername ||
					verifyEmailSpaceMembershipId || verifyEmailSpaceItemId) && (
					<>
						<Link
							className={'btn btn-secondary btn-' + size}
							to={routes.registerMemberProfile()}
						>
							Skip for now
						</Link>
					</>
				)}
			</Form>
		</>
	);
};

export default memo(EmailConfirmedAddAccountDetails);
