import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, DatePicker, Form, Input } from 'antd';
import dayjs from 'dayjs';
import Alert from 'react-bootstrap/Alert';

import { VALIDATE_MESSAGES, GOOGLE_MAP_KEY } from '../../../constants/common';
import {
	updateUserSettingsBasics,
	updateUserSettingsSecurity,
	updateUserSettingsContacts,
	updateUserSettings2FAStatus
} from '../../../services/user';
import { getAuthUserInfo } from '../../../states/general/actions';
import { isAlphaNumeric } from '../../../utils/form';
import routes from '../../../routes';
import Autocomplete from 'react-google-autocomplete';
import './SettingsContent.scss';
import {getSitewideLink} from "../../../utils/sitewideLinks";

const SettingsContent = () => {
	const navigate = useNavigate();
	const [basicsEditForm] = Form.useForm();
	const [securityEditForm] = Form.useForm();
	const [contactNotificationsEditForm] = Form.useForm();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [formIsOnFocusBasic, setFormIsOnFocusBasic] = useState(false);
	const [formHasErrorBasic, setFormHasErrorBasic] = useState(true);
	const [formIsOnFocusSecurity, setFormIsOnFocusSecurity] = useState(false);
	const [formHasErrorSecurity, setFormHasErrorSecurity] = useState(true);
	const [formIsOnFocusContactNotification, setFormIsOnFocusContactNotification] = useState(false);
	const [formHasErrorContactNotification, setFormHasErrorContactNotification] = useState(true);
	const [submitted2FA, setSubmitted2FA] = useState(false);
	const [submittedBasics, setSubmittedBasics] = useState(false);
	const [submittedSecurity, setSubmittedSecurity] = useState(false);
	const [submittedContacts, setSubmittedContacts] = useState(false);
	const [birthdateAlert, setBirthdateAlert] = useState(null);
	const [alertBasic, setAlertBasic] = useState(null);
	const [alertSecurity, setAlertSecurity] = useState(null);
	const [alertContactNotification, setAlertContactNotification] = useState(null);
	const [inputElement, setInputElement] = useState();

	const onClick2FAuthentication = useCallback(async () => {
		if (!authUserInfo.auth_2fa_active) {
			const data = {
				status: true
			};

			try {
				if (authUserInfo) {
					setSubmitted2FA(true);
					data.user_id = authUserInfo.id;
					const result = await updateUserSettings2FAStatus(data);
					if (result) {
						if (result.result) {
							dispatch(getAuthUserInfo());
							navigate(routes.settings2FA());
						}
					}
					setSubmitted2FA(false);
				}
			} catch (error) {
				console.log(error);
				setSubmitted2FA(false);
			}
		} else {
			navigate(routes.settings2FA());
		}
	}, [authUserInfo]);

	const handleBasicFormOnBlur = useCallback(async () => {
		setAlertBasic(null);
		await basicsEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorBasic(false);
			})
			.catch((errors) => {
				setFormHasErrorBasic(true);
			});
	}, []);

	const handleSecurityFormOnBlur = useCallback(async () => {
		setAlertSecurity(null);
		await securityEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorSecurity(false);
			})
			.catch((errors) => {
				setFormHasErrorSecurity(true);
			});
	}, []);

	const handleContactNotificationFormOnBlur = useCallback(async () => {
		setAlertContactNotification(null);
		await contactNotificationsEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorContactNotification(false);
			})
			.catch((errors) => {
				setFormHasErrorContactNotification(true);
			});
	}, []);

	const handleBasicFormChanges = useCallback(async () => {
		setAlertBasic(null);
		await basicsEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorBasic(false);
			})
			.catch((errors) => {
				setFormHasErrorBasic(true);
			});
	}, []);

	const handleSecurityFormChanges = useCallback(async () => {
		setAlertSecurity(null);
		await securityEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorSecurity(false);
			})
			.catch((errors) => {
				setFormHasErrorSecurity(true);
			});
	}, []);

	const handleContactNotificationFormChanges = useCallback(async () => {
		setAlertContactNotification(null);
		await contactNotificationsEditForm
			.validateFields()
			.then(() => {
				setFormHasErrorContactNotification(false);
			})
			.catch((errors) => {
				setFormHasErrorContactNotification(true);
			});
	}, []);

	const submitBasicsEditForm = useCallback(
		async (values) => {
			setAlertBasic(null);
			setBirthdateAlert(null);

			const data = values;
			data.birthdate = values.birthdate.format('YYYY-MM-DD');

			try {
				if (authUserInfo) {
					setSubmittedBasics(true);
					data.user_id = authUserInfo.id;
					const result = await updateUserSettingsBasics(data);
					if (result) {
						if (result.result) {
							setAlertBasic({
								variant: 'success',
								message: 'Updated successfully!'
							});
							dispatch(getAuthUserInfo());
						} else {
							if (result.error_field === 'birthdate') {
								setBirthdateAlert({ variant: 'danger', message: result.message });
							}
						}
					}
					setSubmittedBasics(false);
				}
			} catch (error) {
				console.log(error);
				setSubmittedBasics(false);
			}
		},
		[authUserInfo]
	);

	const submitSecurityEditForm = useCallback(
		async (values) => {
			setAlertSecurity(null);

			try {
				if (authUserInfo) {
					setSubmittedSecurity(true);
					values.user_id = authUserInfo.id;
					const result = await updateUserSettingsSecurity(values);
					if (result) {
						if (result.result) {
							setAlertSecurity({
								variant: 'success',
								message: 'Updated successfully!'
							});
							dispatch(getAuthUserInfo());
						}
					}
					setSubmittedSecurity(false);
				}
			} catch (error) {
				console.log(error);
				setSubmittedSecurity(false);
			}
		},
		[authUserInfo]
	);

	const submitContactNotificationsEditForm = useCallback(
		async (values) => {
			setAlertContactNotification(null);
			try {
				if (authUserInfo) {
					setSubmittedContacts(true);
					values.user_id = authUserInfo.id;
					const result = await updateUserSettingsContacts(values);
					if (result) {
						if (result.result) {
							setAlertContactNotification({
								variant: 'success',
								message: 'Updated successfully!'
							});
							dispatch(getAuthUserInfo());
						} else {
							setAlertContactNotification({
								variant: 'danger',
								message: result.message
							});
						}
					}
					setSubmittedContacts(false);
				}
			} catch (error) {
				console.log(error);
				setSubmittedContacts(false);
			}
		},
		[authUserInfo]
	);

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo) {
			basicsEditForm.setFieldsValue({
				first_name: authUserInfo.profile.first_name,
				last_name: authUserInfo.profile.last_name,
				city: authUserInfo.city,
				birthdate: dayjs(authUserInfo.birthdate)
			});
			contactNotificationsEditForm.setFieldsValue({
				email: authUserInfo.email,
				phone_sms: authUserInfo.phone_sms
			});
		}
		const checkValue = (str, max) => {
			if (str.charAt(0) !== '0' || str === '00') {
				var num = parseInt(str);
				if (isNaN(num) || num <= 0 || num > max) num = 1;
				str =
					num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
						? '0' + num
						: num.toString();
			}
			return str;
		};

		const handleInput = (e) => {
			e.target.type = 'text';
			let input = e.target.value;
			if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
			const values = input.split('/').map((v) => v.replace(/\D/g, ''));
			if (values[0]) values[0] = checkValue(values[0], 12);
			if (values[1]) values[1] = checkValue(values[1], 31);
			const output = values
				.map((v, i) => (v.length === 2 && i < 2 ? v + ' / ' : v))
				.join('')
				.substr(0, 14);
			inputElement.value = output;

			const inputDate = output;
			const dateParts = inputDate.split('/');
			const year = parseInt(dateParts[2], 10);
			const month = parseInt(dateParts[0], 10) - 1;
			const day = parseInt(dateParts[1], 10);
			// const newDate = new Date(year, month, day);

			const isFourDigits = (num) => {
				return /^\d{4}$/.test(num.toString());
			};

			// console.log('year: ' + year + ' month: ' + month + ' day:' + day);
			if (!isNaN(day) && !isNaN(month) && !isNaN(year) && isFourDigits(year)) {
				basicsEditForm.setFieldValue('birthdate', dayjs(new Date(year, month, day)));
			}
		};

		setInputElement(document.getElementById('basicsEditForm_birthdate'));
		if (inputElement) {
			inputElement.addEventListener('input', handleInput);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('input', handleInput);
			}
		};
	}, [loading, authUserInfo, inputElement]);

	return (
		<>
			<div className="col-span-12">
				<div className="dashboard-wrapper pt-4">
					<h3 className="p-1 header-medium">Account Settings</h3>
					<p className="pt-3 body-text--small">
						Your account information and settings are separate from your{' '}
						<a className="gradient-color-txt body-txtitalic--small" href="">
							Profile
						</a>
						. It is kept private and used to manage your account, payments, ownership,
						identity, and benefits across spaces.{' '}
						{/*<a className="gradient-color-txt body-txtitalic--small" href="">*/}
						{/*	Learn more about managing your account*/}
						{/*</a>*/}
						{getSitewideLink('learn_more_account', 'gradient-color-txt body-txtitalic--small')}.
					</p>
				</div>
				<div className="dashboard-wrapper mt-8 pt-4">
					{/* {console.log("city: "+authUserInfo.city != null || undefined ? authUserInfo.city : "test"  )} */}
					<Form
						form={basicsEditForm}
						name="basicsEditForm"
						// initialValues={{ city: authUserInfo.city != null || undefined ? authUserInfo.city : "" }}
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusBasic(true);
						}}
						onBlur={() => {
							setFormIsOnFocusBasic(false);
							handleBasicFormOnBlur();
						}}
						onChange={handleBasicFormChanges}
						onFinish={submitBasicsEditForm}
						className={formIsOnFocusBasic ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Basics</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Your basic account information
								</p>
							</div>
						</div>
						<div className="flex items-center grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1 mt-3">
								<>
									{alertBasic && (
										<Alert
											key={alertBasic.variant}
											variant={alertBasic.variant}
											className={
												'custom-alert ' +
												(alertBasic.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alertBasic.message}
										</Alert>
									)}
								</>
								<div className="input-group-com input-label-full input-settings-margin-basic">
									<label className="body-text--small ps-3 gradient-color-txt">
										First Name
									</label>
									<Form.Item
										label="First Name"
										name="first_name"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										rules={[
											{
												required: true,
												message: 'Please enter at least 1 character.'
											}
										]}
									>
										<Input className="input-md z-0" disabled={submittedBasics} />	
									</Form.Item>
								</div>
							</div>
							<div className="col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Your First Name is private and used for communications, billing,
									and account management only. You may also opt-in to share it
									with the management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_basic_firstname')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="flex items-center grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1 mt-3">
								<div className="input-group-com input-label-full input-settings-margin-basic">
									<label className="body-text--small ps-3 gradient-color-txt">
										Last Name
									</label>
									<Form.Item
										label="Last Name"
										name="last_name"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										rules={[
											{
												required: true,
												message: 'Please enter at least 1 character.'
											}
										]}
									>
										<Input className="input-md z-0" disabled={submittedBasics} />
									</Form.Item>
								</div>
							</div>
							<div className="col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Your Last Name is private and used for communications, billing,
									and account management only. You may also opt-in to share it
									with the management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_basic_lastname')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="flex items-center grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1 mt-3">
								<div className="input-group-com input-label-full input-settings-margin-basic">
									<label className="body-text--small ps-3 gradient-color-txt">
										City
									</label>
									<Form.Item
										label="City"
										name="city"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										rules={[
											{
												required: true,
												message: 'Please enter a valid city.'
											}
										]}
									>
										<Autocomplete
											apiKey={GOOGLE_MAP_KEY}
											placeholder=''
											className={"input-md"}
											// style={{
											// 	height: '48px',
											// 	padding: '15px 30px',
											// 	fontSize: '18px'
											// }}
											onPlaceSelected={(place) => {
												basicsEditForm.setFieldValue('city', place.formatted_address);
											}}
										/>
										{/* <Input className="input-md" disabled={submittedBasics} /> */}
									</Form.Item>
								</div>
							</div>
							<div className="col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Your City is private and used for communications, billing,
									account management, content delivery, and for compliance with
									local regulations. You may also opt-in to share it with the
									management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_basic_city')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="flex items-center grid grid-flow-row grid-cols-1 mt-8 mb-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1 mt-3">
								<>
									{birthdateAlert && (
										<Alert
											key={birthdateAlert.variant}
											variant={birthdateAlert.variant}
											className={
												'custom-alert ' +
												(birthdateAlert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{birthdateAlert.message}
										</Alert>
									)}
								</>
								<div className="input-group-com input-label-full input-settings-margin-basic">
									<label className="body-text--small ps-3 gradient-color-txt">
										Birth Date
									</label>
									<Form.Item
										label="Birthdate"
										name="birthdate"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										rules={[
											{
												type: 'object',
												required: true
											}
										]}
									>
										<DatePicker
											className="input-md input-birthdate-md"
											format="MM/DD/YYYY"
											placeholder="MM/DD/YYYY"
											disabled={submittedBasics}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Your birthdate is private and used for communications, billing,
									account management, age-appropriate content gating, and for
									compliance with local regulations. You may also opt-in to share
									it with the management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_basic_birthdate')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-12">
								<Form.Item className="mb-0 z-0">
									<Button
										className="btn btn-primary btn-md mt-3"
										htmlType="submit"
										disabled={submittedBasics || formHasErrorBasic}
									>
										{submittedBasics && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										Save
									</Button>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
				<div className="dashboard-wrapper mt-8 pt-4">
					<Form
						form={securityEditForm}
						name="securityEditForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusSecurity(true);
						}}
						onBlur={() => {
							setFormIsOnFocusSecurity(false);
							handleSecurityFormOnBlur();
						}}
						onChange={handleSecurityFormChanges}
						onFinish={submitSecurityEditForm}
						className={formIsOnFocusSecurity ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Security</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Two-Factor authentication is strongly recommended.
								</p>
							</div>
						</div>
						<div className="flex items-center grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1 mt-3">
								<>
									{alertSecurity && (
										<Alert
											key={alertSecurity.variant}
											variant={alertSecurity.variant}
											className={
												'custom-alert ' +
												(alertSecurity.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alertSecurity.message}
										</Alert>
									)}
								</>
								<div className="input-group-com input-label-full input-settings-margin-security">
									<label className="body-text--small ps-3 gradient-color-txt">
										Change Password
									</label>
									<Form.Item
										label="Password"
										name="password"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (value && value.length < 8) {
														return Promise.reject(
															new Error(
																'Password is case sensitive and must meet all the requirements.'
															)
														);
													}
													if (isAlphaNumeric(value)) {
														return Promise.resolve();
													}
													if (value && value.length > 0) {
														return Promise.reject(
															new Error(
																'Password is case sensitive and must meet all the requirements.'
															)
														);
													}
													return Promise.resolve();
												}
											})
										]}
									>
										<Input
											type="password"
											className="input-md"
											disabled={submittedSecurity}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Must be a minimum of 8 case-sensitive characters including a
									symbol and a number.
								</p>
							</div>
						</div>
						<div className="flex items-center grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1 mt-3">
								<div className="input-group-com input-label-full input-settings-margin-security">
									<label className="body-text--small ps-3 gradient-color-txt">
										Confirm New Password
									</label>
									<Form.Item
										name="confirmpassword"
										className="mb-0 hidden-label z-0"
										dependencies={['password']}
										rules={[
											{
												required: true,
												message: 'Please confirm your password.'
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														!value ||
														getFieldValue('password') === value
													) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(
															'Your password entries do not match.'
														)
													);
												}
											})
										]}
									>
										<Input
											type="password"
											className="input-md"
											disabled={submittedSecurity}
										/>
									</Form.Item>
								</div>
							</div>
							<div className="col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									When changing your password, you must re-enter your new password
									to confirm that it matches. Be sure to click save.
								</p>
							</div>
						</div>
						<div className="flex items-center grid grid-flow-row grid-cols-1 mt-8 mb-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<Button
									className="btn btn-md btn-secondary whitespace-pre-wrap"
									onClick={onClick2FAuthentication}
									disabled={submitted2FA}
								>
									{submitted2FA ? (
										<>
											<i className="fa-solid fa-spinner fa-spin me-2"></i>
											Enabling 2 Factor Authentication
										</>
									) : (
										<>
											{authUserInfo && authUserInfo.auth_2fa_active
												? 'Manage'
												: 'Enable'}{' '}
											2 Factor Authentication
										</>
									)}
								</Button>
							</div>
							<div className="col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Two-Factor authentication is required if you own or manage a
									Space and is strongly recommended for all accounts.{' '}
									{getSitewideLink('learn_more_security_2fa')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-12">
								<Form.Item className="mb-0 z-0">
									<Button
										className="btn btn-primary btn-md mt-3"
										htmlType="submit"
										disabled={submittedSecurity || formHasErrorSecurity}
									>
										{submittedSecurity && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										Save
									</Button>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
				<div className="dashboard-wrapper mt-8 pt-4">
					<Form
						form={contactNotificationsEditForm}
						name="contactNotificationsEditForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusContactNotification(true);
						}}
						onBlur={() => {
							setFormIsOnFocusContactNotification(false);
							handleContactNotificationFormOnBlur();
						}}
						onChange={handleContactNotificationFormChanges}
						onFinish={submitContactNotificationsEditForm}
						className={
							formIsOnFocusContactNotification ? 'hide-antd-error-messages z-0' : 'z-0'
						}
					>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Contacts & Notifications</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Enable SMS to get the latest updates from your Spaces.
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<>
									{alertContactNotification && (
										<Alert
											key={alertContactNotification.variant}
											variant={alertContactNotification.variant}
											className={
												'custom-alert ' +
												(alertContactNotification.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alertContactNotification.message}
										</Alert>
									)}
								</>
								<div className="input-group-com input-label-full input-settings-margin-contact-notif">
									<label className="body-text--small ps-3 gradient-color-txt">
										Email
									</label>
									<Form.Item
										label="Email"
										name="email"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										rules={[{ required: true, type: 'email' }]}
									>
										<Input className="input-md" disabled={submittedContacts} />
									</Form.Item>
								</div>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Your Email is private and used for communications, billing, and
									account management only. You may also opt-in to share it with
									the management of the Spaces you join.{' '}
									{getSitewideLink('learn_more_cn_email')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<div className="input-group-com input-label-full input-settings-margin-contact-notif">
									<label className="body-text--small ps-3 gradient-color-txt">
										Phone (SMS)
									</label>
									<Form.Item
										label="Phone Number"
										name="phone_sms"
										className="mb-0 hidden-label z-0"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<Input className="input-md" disabled={submittedContacts} />
									</Form.Item>
								</div>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Your phone number is private and will only be used for account
									security, billing, and for any notifications that you have
									enabled.
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1 mb-8">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<Link
									to={routes.settingsNotificationPreferences()}
									className="btn btn-md btn-secondary"
								>
									Manage Notifications
								</Link>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									You can control the email and SMS notifications you receive
									here.
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-12">
								<Form.Item className="mb-0 z-0">
									<Button
										className="btn btn-primary btn-md mt-3"
										htmlType="submit"
										disabled={
											submittedContacts || formHasErrorContactNotification
										}
									>
										{submittedContacts && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										Save
									</Button>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
				<div className="dashboard-wrapper mt-8 pt-4">
					<form>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Payments and Wallets</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Manage and view your payment methods and transactions.
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<Link
									to={routes.settingsPaymentMethods()}
									className="btn btn-md btn-secondary"
								>
									Manage Payment Methods
								</Link>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									You can use credit cards and credit balances to pay for paid
									features and Spaces.{' '}
									{getSitewideLink('learn_more_payment_methods')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more about supported Payment Methods.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3  md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<Link
									to={routes.settingsMagicWallet()}
									className="btn btn-md btn-secondary"
								>
									Manage Wallets
								</Link>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Your connected ETH, MATIC, and USDC Wallets can be used for
									crypto payments and to hold digital collectibles, membership
									keys, and items.{' '}
									{getSitewideLink('learn_more_manage_wallets')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more about supported Wallets.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="flex items-center grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<Link
									className="btn btn-md btn-secondary"
									to={routes.settingsTransactions()}
								>
									View Transactions
								</Link>
							</div>
							<div className="col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									View your transactions and receipts from all the Spaces you are
									part of.{' '}
									{getSitewideLink('learn_more_view_transactions')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Need help with a billing issue? Click here.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
					</form>
				</div>
				<div className="dashboard-wrapper mt-8 pt-4">
					<form>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Data Management</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Control your data and what is shared with your Spaces.
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<a className="btn btn-md btn-secondary">
									Manage Spaces Data Access
								</a>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Managers of the Spaces you’ve joined have limited access to your
									data. You can further limit and manage their access here.{' '}
									{getSitewideLink('learn_more_spaces_data_access')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								<a className="btn btn-md btn-secondary">
									Download Account Data
								</a>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									Managers of the Spaces you’ve joined have limited access to your
									data. You can further limit and manage their access here.{' '}
									{getSitewideLink('learn_more_download_account_data')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
					</form>
				</div>
				<div className="dashboard-wrapper mt-8 pt-4">
					<form>
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-6">
								<h5 className="p-1 header-small">Account Status</h5>
							</div>
							<div className="col-span-6">
								<p className="body-text--small text-end">
									Disable or delete your account here.
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								{/* <a className="btn btn-md btn-secondary disabled">
									Disable Account
								</a> */}
								<Button
									className={'btn text-white border-filled font-[notoSansSemiBold] w-full z-0 btn-lg'}
									disabled
								>
									
									Disable Account
								</Button>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									You can temporarily disable your Account, which will remove you
									from all activity and Spaces. You will only be able to login to
									manage your Account Settings.{' '}
									{getSitewideLink('learn_more_disable_account')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more about disabling your account.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 mt-8 gap-3 md:grid-cols-2 md:grid-rows-1">
							<div className="col-span-1 my-auto !order-2 md:!order-1">
								{/* <a className="btn btn-md btn-secondary">
									Delete Account and Personal Data
								</a> */}
								<Button
									className={'btn text-white border-filled font-[notoSansSemiBold] w-full z-0 btn-lg'}
									disabled
								>
									
									Delete Account and Personal Data
								</Button>
							</div>
							<div className="flex items-center col-span-1 my-auto !order-1 md:!order-2">
								<p className="body-txtitalic--smallest m-0 !leading-4">
									You can PERMANENTLY delete your Account. Once confirmed, your
									account will be immediately disabled and your personal data will
									be scheduled for deletion. Your Wallets and items stored in them
									will not be affected.{' '}
									{getSitewideLink('learn_more_delete_account')}.
									{/*<a className="gradient-color-txt" href="">*/}
									{/*	Learn more about deleting your account.*/}
									{/*</a>*/}
								</p>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default memo(SettingsContent);
