import { memo, useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dialog, Popover, Listbox } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';


import HeaderProMenu from './HeaderProMenu';
import routes from '../../../../routes';

import co_logo from '../../../../assets/images/co_logo.svg';
import HeaderProMenuSidebar from './HeaderProMenuSidebar/HeaderProMenuSidebar';
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }

const HeaderPro = (props) => {
	const { spaceData, activeTab, onSelectedTabChange } = props;
	const pro_styles = spaceData && spaceData.pro_style ? spaceData.pro_style : null;
	const { authUserInfo } = useSelector((state) => state.general);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const navigate = useNavigate();

	let left_logo = co_logo;
	if (spaceData && spaceData.plan === 'pro') {
		left_logo = spaceData && spaceData.photo_thumb ? spaceData.photo_thumb : co_logo;
		left_logo = pro_styles && pro_styles.logo ? pro_styles.logo : left_logo;
	}

	const login = async () => {
		navigate(routes.login())
	}
	const signup = async () => {
		navigate(routes.signUp())
	}

	const setActiveTabChange = (item) => {
		onSelectedTabChange(item);
	};

	useEffect(() => {
		if (authUserInfo) {
			if (authUserInfo.profile.photo_large) {
				setProfileAssetType('image');
			}
			if (authUserInfo.profile.video) {
				setProfileAssetType('video');
			}
		}
	}, [authUserInfo, activeTab]);

	return (
		<div
			id={pro_styles.header_layout === 'split' ? 'space-pro' : 'space-pro-basic'}
			className={pro_styles.has_gradient ? 'has-gradient' : 'no-gradient'}
		>
			
			<header className={(pro_styles.header_layout === 'split' ? '' : 'standard-header')}>
			<nav className="mx-auto flex items-center justify-between md:px-10 px-4" aria-label="Global"
			>
				<div className="flex flex-1 w-[200px] md:w-auto justify-center lg:justify-start">
				<Link to={routes.viewUserSpace() + spaceData?.username} className="h-auto w-auto" onClick={(event) => setActiveTabChange('home')}>
					<img alt="home" src={left_logo} className='h-[48px] object-contain'/>
				</Link>
				</div>
				
				<Popover.Group className="hidden lg:flex lg:gap-x-4 xl:gap-x-8 ">
				{pro_styles.header_layout === 'standard' && (
					<HeaderProMenu
						spaceData={spaceData}
						activeTab={activeTab}
						onSelectedTabChange={setActiveTabChange}
					/>
				)}
				</Popover.Group>
		 			{authUserInfo && (
						<div className="hidden lg:flex lg:flex-1 lg:flex-row-reverse text-black gap-8">
							<div className="absolute">
								{/*<ul className="nav-login relative top-[-30px] min-[1300px]:top-[-40px]">*/}
								<ul className={classNames(profileAssetType !== 'image' && profileAssetType !== 'video' ? 'top-[-24px]' : 'top-[-33px]', "nav-login relative " )}>
										<li className='hidden lg:inline-block mr-8'>
											<Link to="#">
												
												<i className="fa-solid fa-magnifying-glass"></i>
											</Link>
										</li>
										<li className='hidden lg:inline-block mr-8'>
											<Link to="#">
												<i className="fa-solid fa-bell"></i>
											</Link>
										</li>
										<li className='hidden lg:inline-block mr-8'>
											<Link to="#">
												<i className="fa-solid fa-wallet"></i>
											</Link>
										</li>
										<li className={classNames(profileAssetType, 'inline-block  w-[40px]')}>
										<div className="dropdown h-10 w-10 relative -top-[2px]">
											<Listbox > 
												<Listbox.Button className={'rounded-full'}>
													{profileAssetType &&
														profileAssetType === 'video' ? (
															<video autoPlay muted loop className='rounded-full object-cover h-10'>
																<source
																
																	src={authUserInfo.profile.video}
																	type="video/mp4"
																/>
															</video>
														) : (
															<>
																{profileAssetType === 'image' ? (
																	<img
																	className='rounded-full h-10 w-10 object-cover'
																		src={
																			authUserInfo.profile.photo_thumb
																		}
																	/>
																) : (
																	<div className="blank-avatar rounded-full h-10 w-10 flex justify-center text-xl items-center">
																		<i className="fa-solid fa-user"></i>
																	</div>
																)}
															</>
														)}
													</Listbox.Button>
												
												<Listbox.Options className={'absolute dropdown-menu dropdown-menu-end dropdown-menu-login z-50'}>
													<Listbox.Option>
															<Link
																to={routes.dashboard()}
																className="dropdown-item"
																>
																<i className="fa-regular fa-chart-tree-map"></i>{' '}
																Dashboard
															</Link>
													</Listbox.Option>	
													<Listbox.Option>
														<Link
															to={routes.spaces()}
															className="dropdown-item"
															>
															<i className="fa-solid fa-solar-system"></i>{' '}
															Spaces
														</Link>
													</Listbox.Option>
													<Listbox.Option>
														<Link
															to={routes.profile()}
															className="dropdown-item"
															>
															<i className="fa-regular fa-id-badge"></i>{' '}
															Profile
														</Link>
													</Listbox.Option>	
													<Listbox.Option>
														<Link
															to={routes.settings()}
															className="dropdown-item"
															>
															<i className="fa-regular fa-gear"></i>{' '}
															Settings
														</Link>
													</Listbox.Option>
													
													<Listbox.Option>
														<Link
																to={routes.logout()}
																className="dropdown-item"
															>
																<i className="fa-solid fa-arrow-right-from-bracket"></i>{' '}
																Logout
															</Link>
													</Listbox.Option>												
												</Listbox.Options>
											</Listbox>
										</div>											
									</li>
								</ul>
							</div>
						</div>
					)}
					
				{!authUserInfo && (
				<div className="hidden lg:flex lg:flex-1 lg:flex-row-reverse text-black gap-8">
					<div>
						<div onClick={signup} className='border-filled w-[148px] lg:right-[-109px] h-[40px] rounded-[6rem] cursor-pointer z-50 text-white flex justify-center items-center mx-auto'>
							<span className='block font-semibold non-italic font-sans text-lg'>Sign Up</span>
							</div>
						</div>
					<div>
					<div  onClick={login} className='border-gradient w-[148px] lg:right-[-109px] h-[40px] rounded-[6rem] cursor-pointer z-50 text-white flex justify-center items-center mx-auto'>
						<span className='block  font-semibold non-italic font-sans text-lg'>
						<p className='gradient-color-txt' > Login </p>
						</span>
					</div>
					</div>
					
				</div>
				)}
			</nav>
			<Dialog
				as="div"
				className="lg:hidden "
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}
			>
				<div className="fixed inset-0 z-50" />
				<Dialog.Panel className="fixed inset-y-0 right-0 !z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
					<div className='text-right mb-4 text-[32px]'><i class="fa fa-times" aria-hidden="true" onClick={() => setMobileMenuOpen(false)}></i></div>
					<div className="flex items-center justify-between">
						<a href="#" className="-m-1.5 p-1.5">
							<span className="sr-only">commonspace</span>
							<img className="h-8 w-auto" src={co_logo} alt="" />
						</a>
						
						{/* <button
							type="button"
							className="-m-2.5 rounded-md p-2.5 text-gray-700"
							onClick={() => setMobileMenuOpen(false)}
						>
							<span className="sr-only">Close menu</span>
							<XMarkIcon className="h-6 w-6" aria-hidden="true" />
						</button> */}
					</div>
					<div className="mt-6 flow-root">
						<div className="-my-6 divide-y divide-gray-500/10">
							<div className="py-6">
								<section className='-mx-3 block px-3 py-2.5'>
									<Link to="#">
										<i className="fa-solid fa-magnifying-glass gradient-color-txt"></i>
									</Link>
								</section>
								<section className='-mx-3 block px-3 py-2.5'>
									<Link to="#">
										<i className="fa-solid fa-bell gradient-color-txt"></i>
									</Link>
								</section>
								<section className='-mx-3 block px-3 py-2.5'>
									<Link to="#">
										<i className="fa-solid fa-wallet gradient-color-txt"></i>
									</Link>
								</section>
							</div>
						</div>
						<div className="-my-6 divide-y divide-gray-500/10">
							{pro_styles.header_layout === 'standard' && (
								<HeaderProMenuSidebar
									spaceData={spaceData}
									activeTab={activeTab}
									onSelectedTabChange={setActiveTabChange}
								/>
								
							)}
							{!authUserInfo && (
							<div className="py-6">
								<a
									href="/login"
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									<p className='gradient-color-txt' >Log in</p>
								</a>
								<a
									href="/register"
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									<p className='gradient-color-txt' >Sign up</p>
								</a>
							</div>
							)} 
							{authUserInfo && (
								<div className="py-6">
									<section className='-mx-3 block px-3 py-2.5'>
										<Link
												to={routes.dashboard()}
												className="dropdown-item"
											>
												<i className="fa-regular fa-chart-tree-map gradient-color-txt"></i>{' '}
												<span className='gradient-color-txt'>Dashboard</span>
										</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
										<Link
											to={routes.spaces()}
											className="dropdown-item"
										>
											<i className="fa-solid fa-solar-system gradient-color-txt"></i>{' '}
											<span className='gradient-color-txt'>Spaces</span>
										</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
									<Link
										to={routes.profile()}
										className="dropdown-item"
									>
										<i className="fa-regular fa-id-badge gradient-color-txt"></i>{' '}
										<span className='gradient-color-txt'>Profile</span>
									</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
											<Link
												to={routes.settings()}
												className="dropdown-item"
											>
												<i className="fa-regular fa-gear gradient-color-txt"></i>{' '}
												<span className='gradient-color-txt'>Settings</span>
											</Link>
									</section>
									<section className='-mx-3 block px-3 py-2.5'>
											<Link
												to={routes.logout()}
												className="dropdown-item"
											>
												<i className="fa-regular fa-gear gradient-color-txt"></i>{' '}
												<span className='gradient-color-txt'>Logout</span>
											</Link>
									</section>
									
								</div>
							)}
							
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</header>
		</div>
	);
};
export default memo(HeaderPro);
