import { memo } from 'react';
import { CountryDropdown } from 'react-country-region-selector';

import './../DropdownWithCountryRegion.scss';

const DropdownWithCountries = (parentProps) => {
	const { title, value, onDropdownChange, displayError } = parentProps;

	return (
		<>
			<div className="country-region-dropdown rounded-none ">
				{displayError && <span className="ant-form-item-explain-error">{title} is required.</span>}
				<CountryDropdown
					className="mt-2 block w-full rounded-none !bg-[#ffffff80] border-none  py-1.5 pl-3 pr-10 !text-gray-900 ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
        
					value={value}
					onChange={(val) => onDropdownChange(val)}
				/>
			</div>
		</>
	);
};

export default memo(DropdownWithCountries);
